import {
  Transaction,
  TransactionStatus,
  SurveyTransaction,
  TransactionDataType,
  MemoTransaction,
  Memo,
  AnswerItem,
} from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { chunk } from "lodash";
import { v4 } from "uuid";

// eslint-disable-next-line import/no-cycle
import {
  ITransactionRepository,
  ISurveyTransactionRepository,
  IMemoTransactionRepository,
} from "../../service/repository/iTransactionRepository";

import { firestore } from "~/config/firebase";
import {
  convertToMemoTransaction,
  convertToSurveyTransaction,
} from "~/infrastructure/api/utils/transactionConverter";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

export type MemoDB = {
  id: string;
  dataType: TransactionDataType.ADD_MEMO;
  contents: Memo;
  tenantId: string;
  employeeId: string;
  deleted: false;
  status: TransactionStatus.DONE;
  sendAt: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "transactions";

const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10;

export class TransactionRepository implements ITransactionRepository {
  async findById(id: string): Promise<Transaction> {
    const [doc] = (await getDocs(query(this.collection(), where("id", "==", id)))).docs;
    if (!doc) {
      throw new Error("no transaction found");
    }
    return this.convertToTransaction(doc.data());
  }
  async whereByDataTypes({
    employeeId,
    dataTypes,
  }: {
    employeeId: string;
    dataTypes: (keyof typeof TransactionDataType)[];
  }): Promise<(SurveyTransaction | MemoTransaction | Transaction)[]> {
    const { docs } = await getDocs(
      query(
        this.collection(),
        where("employeeId", "==", employeeId),
        where("dataType", "in", dataTypes)
      )
    );

    return docs.map((doc) => {
      const data = doc.data();
      const dataType = data.dataType as TransactionDataType;

      switch (dataType) {
        case TransactionDataType.SURVEY: {
          return convertToSurveyTransaction(data);
        }
        case TransactionDataType.ADD_MEMO: {
          return convertToMemoTransaction(data as MemoDB);
        }

        default: {
          return this.convertToTransaction(data);
        }
      }
    });
  }
  async updateJoinDateType(tenantId: string, employeeId: string): Promise<void> {
    const refs = await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("employeeId", "==", employeeId),
        where("dataType", "==", "JOIN_DATE_INPUT")
      )
    );
    if (refs.docs.length === 0) {
      console.error("no join date input transaction found");
    } else {
      const doc = refs.docs[0] as (typeof refs.docs)[number];
      await updateDoc(doc.ref, { status: TransactionStatus.DONE });
    }
  }
  async updateProfileType(tenantId: string, employeeId: string): Promise<void> {
    const refs = await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("employeeId", "==", employeeId),
        where("dataType", "==", "PROFILE_COMMENT")
      )
    );

    if (refs.docs.length === 0) {
      console.error("no profile input transaction found");
    } else {
      const doc = refs.docs[0] as (typeof refs.docs)[number];
      await updateDoc(doc.ref, { status: TransactionStatus.DONE });
    }
  }

  private convertToTransaction = (data: DocumentData): Transaction => {
    return {
      id: data.id,
      contents: data.contents,
      dataType: data.dataType,
      remindDate: data.remindDate,
      resultContents: data.resultContents,
      sendAt: convertTimestampToDate(data.sendAt),
      sendTo: data.sendTo,
      status: data.status,
      templateId: data.templateId,
      tenantId: data.tenantId,
      employeeId: data.employeeId,
    } as Transaction;
  };

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}

export class SurveyTransactionRepository implements ISurveyTransactionRepository {
  async find(id: string): Promise<SurveyTransaction> {
    return await getDoc(this.doc(id)).then(async (surveyTranDoc) => {
      return convertToSurveyTransaction(surveyTranDoc.data());
    });
  }

  async updateAnswer(transactionId: string, answerItems: AnswerItem[]): Promise<void> {
    const ref = this.doc(transactionId);
    await updateDoc(ref, {
      resultContents: {
        answers: answerItems,
      },
      status: "DONE",
      answeredAt: convertDateToTimestamp(new Date()),
    });
  }

  async whereBySendAt(sendAt: string, tenantId: string): Promise<SurveyTransaction[]> {
    return await getDocs(
      query(
        this.collection(),
        where("sendAt", "==", sendAt),
        where("dataType", "==", "SURVEY"),
        where("tenantId", "==", tenantId)
      )
    ).then(async (surveyTranSnapshot) => {
      return Promise.all(
        surveyTranSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          return convertToSurveyTransaction(data);
        })
      );
    });
  }

  async whereByStatusAndTenantIdAndSortedTimestamp(
    status: TransactionStatus,
    tenantId: string,
    FieldOrderBy: "sendAt" | "answeredAt"
  ): Promise<SurveyTransaction[]> {
    return await getDocs(
      query(
        this.collection(),
        where("dataType", "==", "SURVEY"),
        where("status", "==", status),
        where("tenantId", "==", tenantId),
        orderBy(FieldOrderBy, "desc")
      )
    ).then(async (surveyTranSnapshot) => {
      return Promise.all(
        surveyTranSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          return convertToSurveyTransaction(data);
        })
      );
    });
  }

  async whereByEmployeeId(employeeId: string): Promise<SurveyTransaction[]> {
    return await getDocs(
      query(
        this.collection(),
        where("dataType", "==", "SURVEY"),
        where("employeeId", "==", employeeId),
        orderBy("sendAt", "desc")
      )
    ).then(async (surveyTranSnapshot) => {
      return Promise.all(
        surveyTranSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          return convertToSurveyTransaction(data);
        })
      );
    });
  }

  async whereByEmployeeIds(employeeIds: string[]): Promise<SurveyTransaction[]> {
    const result: SurveyTransaction[] = [];
    await Promise.all(
      // where in句はで最大10件までしかクエリできないため、予めtransactionIdsを10件ごとに分割し、取得した結果を結合するようにしている
      chunk(employeeIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map(async (chunkedEmployeeIds) => {
        const { docs } = await getDocs(
          query(
            this.collection(),
            where("dataType", "==", "SURVEY"),
            where("employeeId", "in", chunkedEmployeeIds)
          )
        );

        result.push(...docs.map((doc) => convertToSurveyTransaction(doc.data())));
      })
    );

    return result.sort((a, b) => (a.sendAt > b.sendAt ? 1 : -1));
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}

export class MemoTransactionRepository implements IMemoTransactionRepository {
  async findMemo(id: string): Promise<MemoTransaction> {
    return await getDoc(this.doc(id)).then(async (memoTranDoc) => {
      return convertToMemoTransaction(memoTranDoc.data() as MemoDB);
    });
  }

  async createMemo({
    tenantId,
    employeeId,
    title,
    text,
    createdEmployeeId,
  }: {
    tenantId: string;
    employeeId: string;
    title: string;
    text: string;
    createdEmployeeId: string;
  }): Promise<{ memoId: string }> {
    const id = v4();
    const now = convertDateToTimestamp(new Date());
    const memoContent: Memo = {
      title,
      text,
      createdEmployeeId,
    };
    const memo: MemoDB = {
      id,
      dataType: TransactionDataType.ADD_MEMO,
      contents: memoContent,
      tenantId,
      employeeId,
      status: TransactionStatus.DONE,
      sendAt: now,
      createdAt: now,
      updatedAt: now,
      deleted: false,
    };

    const ref = this.doc(id);
    await setDoc(ref, memo);

    return { memoId: id };
  }

  async editMemo(id: string, memoContent: Memo): Promise<void> {
    const memoRef = this.doc(id);
    const updates: Pick<MemoDB, "contents" | "updatedAt"> = {
      contents: {
        title: memoContent.title,
        text: memoContent.text,
        createdEmployeeId: memoContent.createdEmployeeId,
      },
      updatedAt: convertDateToTimestamp(new Date()),
    };
    await updateDoc(memoRef, updates);
  }

  async deleteMemo(memoId: string): Promise<void> {
    const memoRef = this.doc(memoId);
    await updateDoc(memoRef, { deleted: true });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}

export type NewHireRegistrationDB = {
  id: string;
  dataType: TransactionDataType;
  remindDate: string;
  sendAt: Date;
  sendTo: string;
  status: TransactionStatus;
  templateId: string;
  tenantId: string;
  employeeId: string;
};
