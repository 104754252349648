import { Box } from "@material-ui/core";
import React, { useCallback, useState, FC, useMemo } from "react";
import styled from "styled-components";

import { Button, Typography, Modal, Icon } from "~/components/uiParts";
import { CustomOnboardingExperienceTaskTemplate } from "~/hooks/onboardingExperienceTaskTemplate";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  template: CustomOnboardingExperienceTaskTemplate;
};

export const DeleteOnboardingTaskTemplateModal: FC<Props> = ({
  open,
  onSubmit,
  onCancel,
  template,
}) => {
  const icon: JSX.Element = useMemo(() => {
    switch (template.type) {
      case "MESSAGE":
        return <Icon size="md" icon="speechBalloon" color="lightGrey" />;
      case "REGULAR_SURVEY":
        return <Icon size="md" icon="survey" color="lightGrey" />;
      case "IRREGULAR_SURVEY":
        return <Icon size="md" icon="list" color="lightGrey" />;
    }
  }, [template]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await onSubmit()
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [onCancel, onSubmit]);

  const Content = (
    <Box textAlign="center">
      <Typography variant="body1">テンプレートを削除しますか？</Typography>
      <StyledTaskItem display="flex" borderRadius="10px" mt="32px" p="24px" alignItems="center">
        {icon}
        <Box display="inline-block" width="8px" />
        <StyledTypography color="textSecondary" noWrap>
          {template.title}
        </StyledTypography>
      </StyledTaskItem>
      <Box mt="32px" textAlign="center">
        <Typography variant="caption">この操作は取り消すことができません。</Typography>
      </Box>
    </Box>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        削除
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="テンプレートの削除"
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const StyledTaskItem = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
