import { Box, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AnswerItem } from "@onn/common";
import React, { FC } from "react";

import { Typography, LinearProgress } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = {
  answerItems: AnswerItem[];
  questionCount: number;
};

export const AnswerFooter: FC<Props> = ({ answerItems, questionCount }) => {
  const isPc = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      p={isPc ? 5 : 2}
      width="100%"
      height="10%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      position="fixed"
      bottom={0}
      boxShadow={4}
      bgcolor={theme.palette.background.default}
      zIndex={theme.zIndex.appBar}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={11} xs={10}>
          <LinearProgress
            variant="determinate"
            color="primary"
            value={(answerItems.length / questionCount) * 100}
          />
        </Grid>
        <Grid item md={1} xs={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="caption"
              bold
              color="textSecondary"
            >{`${answerItems.length}/${questionCount}`}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
