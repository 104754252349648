export class Reaction {
  id: string;
  employeeId: string;
  transactionId: string;
  comment: string;
  createdAt: Date;
  updatedAt: Date;
  tenantId: string;

  constructor({
    id,
    employeeId,
    transactionId,
    comment,
    createdAt,
    updatedAt,
    tenantId,
  }: {
    id: string;
    employeeId: string;
    transactionId: string;
    comment: string;
    createdAt: Date;
    updatedAt: Date;
    tenantId: string;
  }) {
    this.id = id;
    this.employeeId = employeeId;
    this.transactionId = transactionId;
    this.comment = comment;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.tenantId = tenantId;
  }
}
