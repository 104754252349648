import React, { FC } from "react";

import { Button, Icon } from "~/components/uiParts";

type Props = {
  onClick: () => void;
};

export const OpenInviteMembersModalButton: FC<Props> = ({ onClick }) => {
  return (
    <Button
      color="primary"
      variant="outlined"
      borderRadius="regular"
      startIcon={<Icon icon="add" color="primary" size="md" />}
      onClick={onClick}
    >
      入社者を追加
    </Button>
  );
};
