import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { NotFound } from "../NotFound";

import { Registration } from "./Registration";

import { Loading } from "~/components/uiParts";
import { useAuthenticationNonGuarded } from "~/hooks/context";
import {
  RegistrationInfo,
  useRegistrationInfoByInvitationToken,
} from "~/hooks/employee/useRegistrationInfoByInvitationToken";

export const Account = (): JSX.Element => {
  const { id: invitationToken } = useParams<"id">();

  const { authUser } = useAuthenticationNonGuarded();

  const {
    data: registrationInfoByInvitationToken,
    isLoading: isLoadingRegistrationInfoByInvitationToken,
  } = useRegistrationInfoByInvitationToken(invitationToken || "");

  useEffect(() => {
    if (!authUser || !registrationInfoByInvitationToken) {
      return;
    }
    // TODO: 他テナントでLINE認証済みの時、異なるテナントに違うメールアドレスで登録しようとした時にログアウトする
    // - authUser ではなく currentUser を見る必要がある
    // const isSameEmail = authUser.email === registrationInfoByInvitationToken.employee.email;
    // const shouldLogout = !isSameEmail;
    // if (shouldLogout) {
    //   signOut();
    // }
  }, [
    authUser,
    registrationInfoByInvitationToken,
    registrationInfoByInvitationToken?.employee.email,
  ]);

  const isLoading = isLoadingRegistrationInfoByInvitationToken;
  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!invitationToken || !registrationInfoByInvitationToken) {
    return <NotFound />;
  }

  return <AccountRouter registrationInfoByInvitationToken={registrationInfoByInvitationToken} />;
};

const AccountRouter: FC<{
  registrationInfoByInvitationToken: RegistrationInfo;
}> = ({ registrationInfoByInvitationToken }) => {
  return <Registration registrationInfoByInvitationToken={registrationInfoByInvitationToken} />;
};
