import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { ContextMenu } from "./ContextMenu";

import { Icon, VirtualizedTable, Typography } from "~/components/uiParts";
import {
  useCreateDepartment,
  useDeleteDepartments,
  useShiftLayerDeep,
  useUpdateDepartments,
} from "~/hooks/department";
import { useModal } from "~/hooks/modal";

type Props = {
  departments: Department[];
  onUpdate: () => Promise<Department[] | undefined>;
};

export const DepartmentTable: FC<Props> = ({ departments, onUpdate }) => {
  const { handleModal } = useModal();
  const { isLoading: isCreatingDepartment, createDepartment } = useCreateDepartment();
  const { isLoading: isDeletingDepartment, deleteDepartments } = useDeleteDepartments();
  const { isLoading: isUpdatingDepartment, updateDepartments } = useUpdateDepartments();
  const { shiftLayerDeep } = useShiftLayerDeep();
  const nestedDepartments = Department.nest(departments);

  const handleOpenEdit = useCallback(
    (department: Department) => {
      handleModal({
        name: "editDepartmentModal",
        args: {
          departments,
          selectedDepartment: department,
          mode: "edit",
          onSubmit: async (name, parentDepartment) => {
            const parentDepartmentId = parentDepartment ? parentDepartment.id : undefined;
            const newNameDepartment = Department.create({
              ...department,
              name,
              parentDepartmentId,
            });
            const updatedLayerDepartments = shiftLayerDeep(
              newNameDepartment,
              parentDepartment?.layer
            ).flat();

            await updateDepartments(
              updatedLayerDepartments.map((dep) => ({
                id: dep.id,
                department: Department.create(dep),
              }))
            ).then(() => onUpdate());
          },
        },
      });
    },
    [departments, handleModal, onUpdate, shiftLayerDeep, updateDepartments]
  );

  const handleOpenDelete = useCallback(
    (department: Department) => {
      const targetDepartments = departments.filter((dep) =>
        Department.getChildIds([department.id], departments).includes(dep.id)
      );

      handleModal({
        name: "confirmDeleteDepartmentModal",
        args: {
          departments: targetDepartments,
          onAccept: async (departmentIds) => {
            await deleteDepartments(departmentIds).then(() => onUpdate());
          },
        },
      });
    },
    [deleteDepartments, departments, handleModal, onUpdate]
  );

  const handleOpenAdd = useCallback(
    (department: Department) => {
      handleModal({
        name: "editDepartmentModal",
        args: {
          departments,
          selectedDepartment: department,
          mode: "create",
          onSubmit: async (name, parentDepartment) => {
            await createDepartment(
              Department.create({
                ...department,
                id: undefined,
                name,
                layer: parentDepartment?.layer ? parentDepartment?.layer + 1 : 1,
                parentDepartmentId: parentDepartment ? parentDepartment.id : undefined,
              })
            ).then(() => onUpdate());
          },
        },
      });
    },
    [handleModal, departments, createDepartment, onUpdate]
  );

  const renderChildren = (children: Department[]): JSX.Element[] =>
    children.flatMap((child) =>
      [
        <StyledChildContainer key={child.id} display="flex" alignItems="center" layer={child.layer}>
          <StyledIcon
            icon="dropdownArrow"
            color="grey"
            size="md"
            isLowestLayer={isEmpty(child.children)}
          />
          <StyledTypography variant="body1" color="textSecondary">
            {child.name}
          </StyledTypography>
          <ContextMenu
            id={child.id}
            onClickEdit={() => handleOpenEdit(child)}
            onClickDelete={() => {
              handleOpenDelete(child);
            }}
            onClickAddDepartment={() => handleOpenAdd(child)}
          />
        </StyledChildContainer>,
        child.children ? renderChildren(child.children) : [],
      ].flat()
    );

  return (
    <VirtualizedTable
      hover
      widthOptions={["100%"]}
      isLoading={isUpdatingDepartment || isCreatingDepartment || isDeletingDepartment}
      headers={[
        {
          text: "部署名",
        },
      ]}
      rows={nestedDepartments.flatMap((department) => {
        const contents = [
          <Box display="flex" alignItems="center" key={department.id}>
            <StyledIcon
              icon="dropdownArrow"
              color="grey"
              size="md"
              isLowestLayer={isEmpty(department.children)}
            />
            <StyledTypography variant="body1" color="textSecondary">
              {department.name}
            </StyledTypography>
            <ContextMenu
              id={department.id}
              onClickEdit={() => {
                handleOpenEdit(department);
              }}
              onClickDelete={() => {
                handleOpenDelete(department);
              }}
              onClickAddDepartment={() => handleOpenAdd(department)}
            />
          </Box>,
          department.children ? renderChildren(department.children) : [],
        ].flat();

        return contents.map((content) => {
          return {
            contents: [content],
          };
        });
      })}
    />
  );
};

const StyledIcon = styled(Icon)<{ isLowestLayer: boolean }>`
  visibility: ${(props) => (props.isLowestLayer ? "hidden" : "visible")};
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 20px;
    width: 100%;
  }
`;

const StyledChildContainer = styled(Box)<{ layer: number }>`
  margin-left: ${(props) => 40 * (props.layer - 1)}px;
`;
