import { format } from "date-fns";

import { ItemType, QuestionMode, Survey } from "../domain/Survey";
import {
  SurveyTransaction,
  TransactionDataType,
  SendTargetType,
  TransactionStatus,
} from "../domain/Transaction";

export const createIMockSurveyTransaction = (
  mock: Partial<SurveyTransaction> = {}
): SurveyTransaction => {
  return {
    id: mock.id || "1",
    employeeId: mock.employeeId || "1",
    contents: {
      title: mock.contents?.title || "オンボーディングアンケート 0回目",
      description: mock.contents?.description || "Mock 用のアンケートです。",
      id: mock.contents?.id || "mockId",
      type: mock.contents?.type || "NONE",
      surveyTimings: mock.contents?.surveyTimings || { unit: "WEEK", value: 1 },
      totalSection: 2,
      questions: [
        {
          mode: QuestionMode.DEFAULT,
          name: "question1",
          required: true,
          title: "今週はあなたにとってどんな一週間でしたか？満足度をお知らせください。",
          type: ItemType.MEDIUM_RATING,
          questionItems: [
            {
              title: "充実していなかった",
              name: "1",
              value: 1,
              text: false,
              placeholder: undefined,
            },
            {
              title: "あまり充実していなかった",
              name: "2",
              value: 2,
              text: false,
              placeholder: undefined,
            },
            {
              title: "どちらとも言えない",
              name: "3",
              value: 3,
              text: false,
              placeholder: undefined,
            },
            { title: "充実していた", name: "4", value: 4, text: false, placeholder: undefined },
            {
              title: "とても充実していた",
              name: "5",
              value: 5,
              text: false,
              placeholder: undefined,
            },
          ],
        },
        {
          mode: QuestionMode.DEFAULT,
          name: "question15",
          title:
            "上記の満足度を高めていくために、また満足度が高かったとしても気になっていることや改善したいことはありますか？",
          type: ItemType.CHECK_BOX,
          required: true,
          questionItems: [
            {
              name: "questionItem1",
              title: "仕事のやりがい",
              text: false,
            },
            {
              name: "questionItem2",
              title: "仕事の量",
              text: false,
            },
            {
              name: "questionItem3",
              title: "自分の役割の明確さ",
              text: false,
            },
            {
              name: "questionItem14",
              placeholder: "気になっていることや改善したいことを入力",
              title: "その他【自由回答】",
              text: false,
            },
          ],
        },
        {
          mode: QuestionMode.DEFAULT,
          name: "question16",
          required: false,
          title: "今週あったGoodな出来事を教えてください。(自由回答・任意)",
          type: ItemType.SERIAL_NUMBER_TEXT,
        },
      ],
    },
    dataType: mock.dataType || TransactionDataType.SURVEY,
    sendAt: mock.sendAt || format(new Date("2016-02-23"), "yyyy-MM-dd"),
    remindDate: mock.remindDate || format(new Date("2016-02-23"), "yyyy-MM-dd"),
    resultContents: mock.resultContents || [
      {
        key: "question1",
        value: "3",
      },
      {
        key: "question15",
        value: ["questionItem1", "questionItem3"],
      },
      {
        key: "question16",
        value: "やっと会社の文化に慣れました。",
      },
    ],
    templateId: mock.templateId || "1234",
    tenantId: mock.tenantId || "tenant1",
  } as SurveyTransaction;
};

export const createIMockSurveyTransactionWithHrOnly = (
  mock: Partial<SurveyTransaction> = {}
): SurveyTransaction => {
  return {
    id: mock.id || "1",
    employeeId: mock.employeeId || "1",
    contents: {
      title: mock.contents?.title || "オンボーディングアンケート 0回目",
      description: mock.contents?.description || "Mock 用のアンケートです。",
      id: mock.contents?.id || "mockId",
      type: mock.contents?.type || "NONE",
      surveyTimings: mock.contents?.surveyTimings || { unit: "WEEK", value: 1 },
      totalSection: 2,
      questions: [
        {
          mode: QuestionMode.DEFAULT,
          name: "question1",
          required: true,
          title: "今週はあなたにとってどんな一週間でしたか？満足度をお知らせください。",
          type: ItemType.MEDIUM_RATING,
          questionItems: [
            {
              title: "充実していなかった",
              name: "1",
              value: 1,
              text: false,
              placeholder: undefined,
            },
            {
              title: "あまり充実していなかった",
              name: "2",
              value: 2,
              text: false,
              placeholder: undefined,
            },
            {
              title: "どちらとも言えない",
              name: "3",
              value: 3,
              text: false,
              placeholder: undefined,
            },
            { title: "充実していた", name: "4", value: 4, text: false, placeholder: undefined },
            {
              title: "とても充実していた",
              name: "5",
              value: 5,
              text: false,
              placeholder: undefined,
            },
          ],
        },
        {
          mode: QuestionMode.DEFAULT,
          name: "question15",
          title:
            "上記の満足度を高めていくために、また満足度が高かったとしても気になっていることや改善したいことはありますか？",
          type: ItemType.CHECK_BOX,
          required: true,
          questionItems: [
            {
              name: "questionItem1",
              title: "仕事のやりがい",
            },
            {
              name: "questionItem2",
              title: "仕事の量",
            },
            {
              name: "questionItem3",
              title: "自分の役割の明確さ",
            },
            {
              name: "questionItem14",
              placeholder: "気になっていることや改善したいことを入力",
              title: "その他【自由回答】",
            },
          ],
        },
        {
          mode: QuestionMode.DEFAULT,
          name: "question16",
          required: false,
          title: "今週あったGoodな出来事を教えてください。(自由回答・任意)",
          type: ItemType.SERIAL_NUMBER_TEXT,
        },

        {
          hrOnly: true,
          mode: QuestionMode.DEFAULT,
          name: "question4",
          questionItems: [
            {
              name: "questionItem1",
              title: "上司との人間関係について",
            },
            {
              name: "questionItem2",
              title: "同僚との人間関係について",
            },
            {
              name: "questionItem3",
              title: "今後のキャリアについて",
            },
            {
              name: "questionItem4",
              title: "待遇について",
            },
            {
              name: "questionItem5",
              title: "労働環境、働き方について",
            },
            {
              name: "questionItem6",
              title: "自分の役割、業務内容について",
            },
            {
              name: "questionItem7",
              title: "仕事の進捗、成果について",
            },
            {
              name: "questionItem8",
              title: "その他【自由回答】",
            },
          ],
          required: false,
          section: 2,
          title: "人事に相談したいことや、伝えておきたいことがあればお知らせください（任意）",
          type: ItemType.CHECK_BOX,
        },
        {
          caption:
            "※相談したい相手や時期、内容、プライバシーに関することなど\n※特にない場合は回答不要です",
          hrOnly: true,
          mode: QuestionMode.DEFAULT,
          name: "question5",
          questionItems: [],
          required: false,
          section: 2,
          title: "上記について、具体的な内容を教えてください（任意）",
          type: ItemType.SERIAL_NUMBER_TEXT,
        },
      ],
    },
    dataType: mock.dataType || TransactionDataType.SURVEY,
    sendAt: mock.sendAt || format(new Date("2016-02-23"), "yyyy-MM-dd"),
    remindDate: mock.remindDate || format(new Date("2016-02-23"), "yyyy-MM-dd"),
    resultContents: mock.resultContents || [
      {
        key: "question1",
        value: "3",
      },
      {
        key: "question15",
        value: ["questionItem1", "questionItem3"],
      },
      {
        key: "question16",
        value: "やっと会社の文化に慣れました。",
      },
      {
        key: "question4",
        value: ["questionItem1"],
      },
      {
        key: "question5",
        value: "test",
      },
    ],
    templateId: mock.templateId || "1234",
    tenantId: mock.tenantId || "tenant1",
  } as SurveyTransaction;
};

// インスタンスメソッドを使うために createIMockSurveyTransaction と別に実装している
// TODO: createIMockSurveyTransaction との統合
export const createIMockSurveyTransactionInstance = (
  mock: Partial<SurveyTransaction> = {}
): SurveyTransaction =>
  new SurveyTransaction({
    id: mock.id || "1",
    contents: {
      title: mock.contents?.title || "オンボーディングアンケート 0回目",
      description: mock.contents?.description || "Mock 用のアンケートです。",
      id: mock.contents?.id || "mockId",
      type: mock.contents?.type || "NONE",
      surveyTimings: mock.contents?.surveyTimings || { unit: "WEEK", value: 1 },
      questions: [
        {
          mode: QuestionMode.DEFAULT,
          name: "question1",
          required: true,
          title: "今週はあなたにとってどんな一週間でしたか？満足度をお知らせください。",
          type: ItemType.MEDIUM_RATING,
          questionItems: [
            {
              title: "充実していなかった",
              name: "1",
              value: 1,
              text: false,
              placeholder: undefined,
            },
            {
              title: "あまり充実していなかった",
              name: "2",
              value: 2,
              text: false,
              placeholder: undefined,
            },
            {
              title: "どちらとも言えない",
              name: "3",
              value: 3,
              text: false,
              placeholder: undefined,
            },
            { title: "充実していた", name: "4", value: 4, text: false, placeholder: undefined },
            {
              title: "とても充実していた",
              name: "5",
              value: 5,
              text: false,
              placeholder: undefined,
            },
          ],
        },
        {
          mode: QuestionMode.DEFAULT,
          name: "question15",
          title:
            "上記の満足度を高めていくために、また満足度が高かったとしても気になっていることや改善したいことはありますか？",
          type: ItemType.CHECK_BOX,
          required: true,
          questionItems: [
            {
              name: "questionItem1",
              title: "仕事のやりがい",
            },
            {
              name: "questionItem2",
              title: "仕事の量",
            },
            {
              name: "questionItem3",
              title: "自分の役割の明確さ",
            },
            {
              name: "questionItem14",
              placeholder: "気になっていることや改善したいことを入力",
              title: "その他【自由回答】",
            },
          ],
        },
        {
          mode: QuestionMode.DEFAULT,
          name: "question16",
          required: false,
          title: "今週あったGoodな出来事を教えてください。(自由回答・任意)",
          type: ItemType.SERIAL_NUMBER_TEXT,
        },
      ],
    } as Survey,
    dataType: mock.dataType || TransactionDataType.SURVEY,
    remindDate: mock.remindDate || format(new Date("2016-02-23"), "yyyy-MM-dd"),
    resultContents: mock.resultContents || [
      {
        key: "question1",
        value: "3",
      },
      {
        key: "question15",
        value: ["questionItem1", "questionItem3"],
      },
      {
        key: "question16",
        value: "やっと会社の文化に慣れました。",
      },
    ],
    sendAt: mock.sendAt || new Date("2016-02-23"),
    answeredAt: mock.answeredAt || new Date("2016-02-23"),
    sendTarget: mock.sendTarget || SendTargetType.SLACK,
    sendTo: mock.sendTo || "employee1",
    status: mock.status || TransactionStatus.DONE,
    templateId: mock.templateId || "1234",
    employeeId: mock.employeeId || "employee1",
    tenantId: mock.tenantId || "tenant1",
  });
