import { v4 } from "uuid";

import { Employee } from "../../../Employee";

export class MessageContent {
  readonly id: string; // uuid v4
  readonly requestedEmployeeId?: string; // 記入リクエストの送信先
  readonly name: string; // 初期値はOnnに登録済みの場合、 `${lastName} ${firstName}`、そうでない場合SlackのdisplayName
  readonly iconPath?: string;
  readonly email?: string; // 初期値はOnnに登録済みの場合、そのemail、そうでない場合Slackから取得したemail
  readonly department?: string;
  readonly slackUserId?: string;
  readonly message?: string;
  readonly isCompleted: boolean;
  readonly createdAt: Date; // 依頼した時刻(共有リンクからの記入は記入完了時刻)
  readonly updatedAt: Date; // 記入完了した時刻

  constructor(init: Omit<MessageContent, "complete">) {
    this.id = init.id;
    this.requestedEmployeeId = init.requestedEmployeeId;
    this.name = init.name;
    this.iconPath = init.iconPath;
    this.email = init.email;
    this.department = init.department;
    this.slackUserId = init.slackUserId;
    this.message = init.message;
    this.isCompleted = init.isCompleted;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  public static createByEmployee(employee: Employee) {
    return new MessageContent({
      id: v4(),
      requestedEmployeeId: employee.id,
      slackUserId: employee.slackUserId,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: employee.getName(),
      email: employee.email,
      iconPath: employee.profileIconImageUrl,
      isCompleted: false,
    });
  }

  public static create(params: Optional<ConstructorParameters<typeof MessageContent>[0], "id">) {
    return new MessageContent({
      ...params,
      id: params.id ?? v4(),
    });
  }

  complete(): MessageContent {
    return MessageContent.create({
      ...this,
      isCompleted: true,
      updatedAt: new Date(),
    });
  }
}
