import { Grid, Box, TextField, Divider } from "@material-ui/core";
import { getDayLengthByMonth, Employee, Profile } from "@onn/common";
import React, { useState, useMemo, ComponentProps, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, ImageUploadArea, SelectForm, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useInputIcon } from "~/hooks/file";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { mixin } from "~/util";

type LocationState = {
  userDataFromConfirmPage?: Employee;
  inputIconFromConfirmPage?: Parameters<typeof useInputIcon>[0];
};

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

export const ProfilePage: FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const locationState = useLocation().state as LocationState;
  const currentIcon = currentUser.profileIconImageUrl
    ? fileAPIAdapter.getFileUrl(currentUser.profileIconImageUrl)
    : undefined;

  // confirm 画面から戻ってきた時にuser が set される
  const userDataFromConfirmPage = locationState?.userDataFromConfirmPage ?? null;
  const inputIconFromConfirmPage = locationState?.inputIconFromConfirmPage ?? {
    dataUrl: currentIcon,
    path: currentUser.profileIconImageUrl,
  };

  const [newProfile, setNewProfile] = useState<Profile>(
    userDataFromConfirmPage?.profile ??
      currentUser.profile ??
      new Profile({
        birthMonth: "",
        birthDate: "",
        tags: "",
        career: "",
        free: "",
        nickname: "",
      })
  );

  const [errors, setErrors] = useState<Record<string, boolean>>({
    birthday: false,
    nickname: false,
    tags: false,
    career: false,
    free: false,
  });

  const { inputIcon, handleUploadIcon, handleErrorUploadIcon, allowImageSize } =
    useInputIcon(inputIconFromConfirmPage);

  // 誕生日入力で利用する配列を返す
  const buildSelectFormOptions = (maxNum: number): ComponentProps<typeof SelectForm>["menuItems"] =>
    [...new Array(maxNum)].map((_, i) => ({
      value: i + 1,
      name: String(i + 1),
    }));

  const handleChangeProfile = (newObject: Record<string, string>) => {
    setNewProfile((prev) => new Profile({ ...prev, ...newObject }));

    // newObjectがblankのとき、同名のerrorsの同名のkeyをtrueにする
    setErrors((prev) => {
      const [key, value] = Object.entries(newObject)[0] as [string, string];
      if (!value.trim()) {
        prev[key] = true;
      } else {
        prev[key] = false;
      }
      return prev;
    });
  };

  const handleClickConfirm = async () => {
    navigate("/profile/confirm", {
      state: {
        userDataForUpdate: {
          ...currentUser,
          profile: {
            ...currentUser.profile,
            ...newProfile,
          },
        },
        inputIconForUpdate: inputIcon,
      },
    });
    window.scrollTo(0, 0);
  };

  const isAllValueBlank = useMemo(() => {
    return Object.values(newProfile).some((v) => !v || v.toString().trim() === "");
  }, [newProfile]);

  const isInputIconBlank = useMemo(() => {
    return Object.values(inputIcon).every((v) => !v);
  }, [inputIcon]);

  return (
    <StyledBox>
      <Typography variant="h2">Profile</Typography>
      <StyledCard>
        <Typography variant="body2" color="textSecondary" align="left">
          {`私たちの会社の一員になってくださる${currentUser.getName()}さんのことを、教えてください！\nこの自己紹介を書くと、あなたの入社をサポートするバディ(受け入れサポート担当)や人事に届きます`}
        </Typography>
        <Box mt={4} mb={4}>
          <Divider />
        </Box>
        <Typography variant="body1" bold align="left">
          プロフィール写真
        </Typography>
        <Box mt={2} />
        <Typography variant="body2" align="left">
          入社時に他のメンバーがあなたが誰かわかるように、顔が見える写真の設定を推奨しています
        </Typography>
        <Box mt={3} />
        <ImageUploadArea
          alt="アイコン"
          defaultImage={inputIcon?.path}
          imagePath={inputIcon?.dataUrl || ""}
          allowImageSize={allowImageSize}
          onChange={handleUploadIcon}
          onError={handleErrorUploadIcon}
          size="large"
        />
        <Box mt={4} />
        <Typography variant="body1" bold align="left">
          誕生日
        </Typography>
        <Box mt={4} />
        <Box display="flex" alignItems="center" gridGap="4px">
          <StyledSelectForm
            selected={newProfile.birthMonth || ""}
            onChange={(e) =>
              setNewProfile(
                (prev) => new Profile({ ...prev, ...{ birthMonth: Number(e.target.value) } })
              )
            }
            menuItems={buildSelectFormOptions(12)}
          />
          <Typography variant="body1" color="textSecondary">
            月
          </Typography>
          <Box width="8px" />
          <StyledSelectForm
            selected={newProfile.birthDate || ""}
            onChange={(e) =>
              setNewProfile(
                (prev) => new Profile({ ...prev, ...{ birthDate: Number(e.target.value) } })
              )
            }
            menuItems={
              newProfile.birthMonth
                ? buildSelectFormOptions(getDayLengthByMonth(newProfile.birthMonth))
                : undefined
            }
            disabled={!newProfile.birthMonth}
          />
          <Typography variant="body1" color="textSecondary">
            日
          </Typography>
        </Box>

        <Box mt={8} />
        <Typography variant="body1" bold align="left">
          ニックネーム
        </Typography>
        <Box mt={2} />
        <Typography variant="body2" align="left">
          普段呼ばれているニックネームや略称を書きましょう
        </Typography>
        <Box mt={3} />
        <TextField
          id="outlined-multiline-static"
          error={errors.nickname}
          multiline
          fullWidth
          rows={2}
          variant="outlined"
          name="nickname"
          defaultValue={newProfile.nickname}
          placeholder="太郎"
          helperText={errors.nickname && "ニックネームを入力してください"}
          onChange={(e) => handleChangeProfile({ nickname: e.target.value })}
        />
        <Box mt={4} />
        <Typography variant="body1" bold align="left">
          私を構成するタグ
        </Typography>
        <Box mt={2} />
        <Typography variant="body2" align="left">
          入社後に他の社員との共通点を見つけられるような、趣味や好きなもの、出身地、スキルなど、自分を構成する特徴をタグで3つ以上つけてみましょう
        </Typography>
        <Box mt={3} />
        <TextField
          id="outlined-multiline-static"
          error={errors.tags}
          multiline
          fullWidth
          rows={6}
          defaultValue={newProfile.tags}
          placeholder="#大阪 #野球部 #犬が好き #カレーが好き #お酒好き #キャンプ #海外旅行 #サッカー観戦"
          helperText={errors.tags && "タグを入力してください"}
          variant="outlined"
          name="tags"
          onChange={(e) => handleChangeProfile({ tags: e.target.value })}
        />
        <Box mt={8} />
        <Typography variant="body1" bold align="left">
          経歴サマリー
        </Typography>
        <Box mt={2} />
        <Typography variant="body2" align="left">
          中途入社の方はこれまでの略歴を、新卒入社の方は直近の学歴などを紹介しましょう
        </Typography>
        <Box mt={3} />
        <TextField
          id="outlined-multiline-static"
          multiline
          fullWidth
          rows={12}
          defaultValue={newProfile.career}
          placeholder="新卒でaaa社に入社して法人営業 → bbb社でインサイドセールス → ccc社で営業マネージャー"
          error={errors.career}
          helperText={errors.career && "経歴サマリーを入力してください"}
          variant="outlined"
          name="career"
          onChange={(e) => handleChangeProfile({ career: e.target.value })}
        />
        <Box mt={8} />
        <Typography variant="body1" bold align="left">
          これから一緒に働く仲間に一言！
        </Typography>
        <Box mt={2} />
        <Typography variant="body2" align="left">
          他の社員に入社に対して思っていることや意気込み、メッセージを伝えてみましょう
        </Typography>
        <Box mt={4} />
        <TextField
          id="outlined-multiline-static"
          multiline
          fullWidth
          rows={6}
          defaultValue={newProfile.free}
          error={errors.free}
          helperText={errors.free && "仲間への一言を入力してください"}
          variant="outlined"
          name="free"
          placeholder="皆さんと働けることを楽しみにしています。一緒に最高のサービスを作っていきましょう。よろしくお願いします！"
          onChange={(e) => handleChangeProfile({ free: e.target.value })}
        />
        <Box mt={7} mb={9} display="flex">
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={4} xs={12}>
              <Button
                fullWidth
                borderRadius="circle"
                variant="contained"
                color="primary"
                disabled={
                  Object.values(errors).some((v) => v) || isAllValueBlank || isInputIconBlank
                }
                onClick={handleClickConfirm}
              >
                確認画面へ
              </Button>
            </Grid>
          </Grid>
        </Box>
      </StyledCard>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  width: 100%;
  ${mixin.breakDown.sm`
    background-color: white;
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledSelectForm = styled(SelectForm)`
  width: 80px;
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 28px;
  padding: 80px;
  ${mixin.breakDown.sm`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0px;
    width: 100%;
  `}
`;
