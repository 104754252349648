import { OnboardingTaskWithNewHire } from "@onn/common";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback } from "react";

/**
 * オンボーディングタスクを入社者の入社日でfilterする関数を提供するhooks
 */
export const useFilterTasksByJoinAt = () => {
  /**
   * オンボーディングタスクを入社者の入社日でfilterする
   * @param {OnboardingTaskWithNewHire[]} onboardingTasks
   * @param {DateString[]} dateStrings
   */
  const filterTasksByJoinAt = useCallback(
    (onboardingTasks: OnboardingTaskWithNewHire[], dateStrings: DateString[]) => {
      // employeeIdが選択されていない場合はフィルターしない
      if (isEmpty(dateStrings)) return onboardingTasks;
      return onboardingTasks.filter((task) => {
        if (!task.newHire?.joinAt) return false;
        const dateString = format(new Date(task.newHire.joinAt), "yyyy-MM-dd") as DateString;
        return dateStrings.includes(dateString);
      });
    },
    []
  );

  return { filterTasksByJoinAt };
};
