import React, { FC, memo } from "react";

import { EmojiConfetti } from "~/components/uiParts";
import { useLocalStorage } from "~/hooks/shared";

type Props = {
  isAllTaskCompleted: boolean;
  currentUserId: string;
};

export const EmojiConfettiForTask: FC<Props> = memo(({ isAllTaskCompleted, currentUserId }) => {
  const { retrieveValue, storeValue } = useLocalStorage();
  const key = `hasDisplayedEmojiConfetti_${currentUserId}`;

  const hasDisplayedEmojiConfetti = retrieveValue(key);

  // 進捗100%でないときは何もしない
  if (!isAllTaskCompleted) {
    return null;
  }
  // すでにEmojiConfettiを表示している場合は何もしない
  else if (hasDisplayedEmojiConfetti) {
    return null;
  }
  // 進捗100% かつ EmojiConfetti未表示の場合はlocalStorageに書き込んでEmojiConfettiを表示する
  else {
    storeValue(key, true);

    return (
      <EmojiConfetti
        lifetime={3000}
        emojiOptions={[
          { emoji: "🎉", count: 60 },
          { emoji: "👏", count: 40 },
        ]}
      />
    );
  }
});
