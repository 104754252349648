import { Employee, OnboardingTaskWithNewHire, OnboardingTaskType } from "@onn/common";
import { format, isAfter } from "date-fns";
import { useCallback, useState, useEffect, useMemo } from "react";

import {
  useFilterTasksByStatus,
  useFilterTasksByAssigneeEmployeeIds,
  useSortOnboardingTasks,
  useFilterTasksByNewHireIds,
  useFilterTasksByJoinAt,
  useExtractAssigneeEmployeeIds,
} from "~/components/domains/onboardingTask/hooks";
import {
  useAllEmployees,
  useAllEmployeesWithDeleted,
  useAllNewcomers,
  useCurrentUser,
  useEmployee,
  useEmployees,
  useAdmins,
} from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import {
  useCreateOnboardingTask,
  useDeleteOnboardingTask,
  useUpdateOnboardingTask,
} from "~/hooks/onboardingTask";
import { useOnboardingTasks } from "~/hooks/onboardingTask/useOnboardingTasks";
import { useQuery, useSetQueryString } from "~/hooks/shared";

export const FILTER_STATUSES = {
  ALL: "ALL",
  EXPIRED: "EXPIRED",
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED",
} as const;

type FilterStatusType = (typeof FILTER_STATUSES)[keyof typeof FILTER_STATUSES];
const AllFilterStatusType = Object.values(FILTER_STATUSES);

const displayFilterStatusMap: { [key in FilterStatusType]: string } = {
  ALL: "全て",
  EXPIRED: "期限切れ",
  NOT_STARTED: "未着手",
  COMPLETED: "完了",
};

export type FilterOptionType = {
  title: string;
  totalCount: number;
  status: FilterStatusType;
};

const getSelectableDateArray = (newHires: Employee[]) => {
  // 同日を排除
  const dateArray = Array.from(
    new Set(
      newHires.flatMap((newHire) => {
        if (!newHire.joinAt) return [];
        return format(new Date(newHire.joinAt), "yyyy-MM-dd") as DateString;
      })
    )
  );

  return dateArray.sort((a, b) => (isAfter(new Date(a), new Date(b)) ? -1 : 1));
};

const getOnboardingTaskWithNewHire = (
  onboardingTasks: OnboardingTaskType[],
  newHires: Employee[]
) => {
  const employeeMap = newHires.reduce((acc: Record<string, Employee>, newHire) => {
    return { ...acc, [newHire.id]: newHire };
  }, {});
  return onboardingTasks.map((v: OnboardingTaskWithNewHire) => {
    v.newHire = employeeMap[v.employeeId];
    return v;
  });
};

export const useViewModel = () => {
  const { currentUser } = useCurrentUser();
  const { allEmployees } = useAllEmployees();

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [selectedNewHireIds, setSelectedNewHireIds] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<FilterStatusType>("EXPIRED");
  const [selectedJoinAtStrings, setSelectedJoinAtStrings] = useState<DateString[]>([]);

  const [targetEmployeeIdForSummary, setTargetEmployeeIdForSummary] = useState<string>();
  const [targetTaskForSummary, setTargetTaskForSummary] = useState<OnboardingTaskType>();

  const [filteredByStatusOnboardingTasksMap, setFilteredByStatusOnboardingTasksMap] = useState<{
    [key in FilterStatusType]: OnboardingTaskType[];
  }>({
    ALL: [],
    EXPIRED: [],
    NOT_STARTED: [],
    COMPLETED: [],
  });

  const { data: allEmployeesWithDeleted = [] } = useAllEmployeesWithDeleted(currentUser.tenantId);
  const { data: targetEmployeeForSummary } = useEmployee(targetEmployeeIdForSummary, {
    shouldAutoRevalidate: false,
  });
  const {
    data: onboardingTasks,
    isValidating: isValidatingOnboardingTasks,
    mutate: mutateOnboardingTasks,
  } = useOnboardingTasks(currentUser.tenantId);
  const { data: allNewcomers = [], isValidating: isValidatingAllNewcomers } = useAllNewcomers();
  const { data: newHires, isValidating: isValidatingNewHires } = useEmployees(
    onboardingTasks ? Array.from(new Set(onboardingTasks.map((v) => v.employeeId))) : undefined
  );

  const { data: mentor } = useEmployee(
    targetEmployeeForSummary ? targetEmployeeForSummary.mentorUserId : undefined
  );
  const { data: supportMembers = [] } = useEmployees(
    targetEmployeeForSummary ? targetEmployeeForSummary.supportMemberEmployeeIds : undefined
  );
  const { data: admins = [] } = useAdmins(currentUser.tenantId);
  const { data: specificEmployees = [] } = useEmployees(targetTaskForSummary?.assigneeIds);

  const { handleModal } = useModal();

  const { createOnboardingTask } = useCreateOnboardingTask();
  const { deleteOnboardingTask } = useDeleteOnboardingTask();
  const { updateOnboardingTask } = useUpdateOnboardingTask();

  const { filterTasksByStatus } = useFilterTasksByStatus();
  const { filterTasksByAssigneeEmployeeIds } = useFilterTasksByAssigneeEmployeeIds();
  const { filterTasksByNewHireIds } = useFilterTasksByNewHireIds();
  const { filterTasksByJoinAt } = useFilterTasksByJoinAt();
  const { sortOnboardingTasks } = useSortOnboardingTasks();
  const { extractAssigneeEmployeeIds } = useExtractAssigneeEmployeeIds();

  const { query } = useQuery();
  const { setQueryString } = useSetQueryString();

  useEffect(() => {
    if (!onboardingTasks || !newHires) return;
    const tasks = getOnboardingTaskWithNewHire(onboardingTasks, newHires);

    // タスクのステータスでフィルター
    setFilteredByStatusOnboardingTasksMap({
      ALL: filterTasksByStatus(tasks, "ALL"),
      EXPIRED: filterTasksByStatus(tasks, "EXPIRED"),
      NOT_STARTED: filterTasksByStatus(tasks, "NOT_STARTED"),
      COMPLETED: filterTasksByStatus(tasks, "COMPLETED"),
    });
  }, [filterTasksByStatus, newHires, onboardingTasks]);

  useEffect(() => {
    if (onboardingTasks) {
      const onboardingTaskId = query.get("onboardingTaskId");
      const targetTaskForSummary = onboardingTasks.find((task) => task.id === onboardingTaskId);
      // サマリーを表示していたら更新しない
      setTargetTaskForSummary((prev) => prev || targetTaskForSummary);
      if (targetTaskForSummary) setTargetEmployeeIdForSummary(targetTaskForSummary?.employeeId);
    }
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingTasks]);

  useEffect(() => {
    const selectedStatus = query.get("selectedStatus") as FilterStatusType;
    // 不正な値の場合はフィルターしない
    if (selectedStatus && !AllFilterStatusType.includes(selectedStatus)) {
      return;
    }
    // query で指定されていない場合は期限切れを表示する
    if (!selectedStatus) {
      setQueryString({ selectedStatus: "EXPIRED" });
      setSelectedStatus("EXPIRED");
    } else {
      setSelectedStatus(selectedStatus);
    }
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allEmployeeIds = allEmployees.map((v) => v.id);
    const employeeIds = query.getAll("employeeIds");
    // 不正な値の場合はフィルターしない
    if (employeeIds.some((employeeId) => !allEmployeeIds.includes(employeeId))) {
      return;
    }
    setSelectedEmployeeIds(employeeIds);
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees]);

  useEffect(() => {
    if (isValidatingAllNewcomers) return;
    const allNewcomerIds = allNewcomers.map((v) => v.id);
    const newHireIds = query.getAll("newHireIds");
    // 不正な値の場合はフィルターしない
    if (newHireIds.some((newHireId) => !allNewcomerIds.includes(newHireId))) {
      return;
    }
    setSelectedNewHireIds(newHireIds);
  }, [allNewcomers, isValidatingAllNewcomers, query]);

  useEffect(() => {
    // 非同期で取得されるOnboardingTaskの後にnewHiresが取得されるため、newHiresの有無もチェックする
    if (isValidatingNewHires || !newHires) return;
    const selectableDateArray = getSelectableDateArray(newHires);
    const joinAtStrings = query.getAll("joinAtStrings") as DateString[];

    // 不正な値の場合はフィルターしない
    if (joinAtStrings.some((joinAtString) => !selectableDateArray.includes(joinAtString))) {
      return;
    }
    setSelectedJoinAtStrings(joinAtStrings);
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidatingNewHires, newHires]);

  const handleOpenEditTaskModal = useCallback(
    (task: OnboardingTaskType, employee: Employee) => {
      handleModal({
        name: "editTaskModal",
        args: {
          employee,
          task,
          allEmployeesWithDeleted,
          onSubmit: async (newTask: OnboardingTaskType, emailsWithoutOnnAccount: string[]) => {
            if (task) {
              await updateOnboardingTask(task.id, newTask, emailsWithoutOnnAccount);
            } else {
              await createOnboardingTask(newTask, employee, emailsWithoutOnnAccount);
            }
            mutateOnboardingTasks();
          },
        },
      });
    },
    [
      allEmployeesWithDeleted,
      createOnboardingTask,
      handleModal,
      mutateOnboardingTasks,
      updateOnboardingTask,
    ]
  );

  const handleClickDeleteTaskButton = useCallback(
    (task: OnboardingTaskType, employee: Employee) => {
      handleModal({
        name: "deleteTaskModal",
        args: {
          username: employee.getName(),
          profileIconImageUrl: employee.profileIconImageUrl,
          taskName: task.title,
          onSubmit: async () => {
            await deleteOnboardingTask(task, employee);
            mutateOnboardingTasks();
          },
        },
      });
    },
    [deleteOnboardingTask, handleModal, mutateOnboardingTasks]
  );

  const handleClickChangeStatusButton = useCallback(
    async (task: OnboardingTaskType, _: Employee) => {
      const newTask = await updateOnboardingTask(
        task.id,
        {
          status: task.status === "COMPLETED" ? "NOT_STARTED" : "COMPLETED",
        },
        []
      );
      if (!newTask) return;
      mutateOnboardingTasks();
      // サマリーを表示していた場合は表示を更新する
      setTargetTaskForSummary((prev) => (prev ? newTask : undefined));
    },
    [updateOnboardingTask, mutateOnboardingTasks]
  );

  const getFilteredByStatusAndJoinAt = useCallback(
    (selectedStatus: FilterStatusType, selectedJoinAtStrings: DateString[]) => {
      // タスクのステータスでフィルター
      const filteredTasksByStatus = filteredByStatusOnboardingTasksMap[selectedStatus];

      // 入社日でフィルター
      return filterTasksByJoinAt(filteredTasksByStatus, selectedJoinAtStrings);
    },
    [filterTasksByJoinAt, filteredByStatusOnboardingTasksMap]
  );

  const getFilteredOnboardingTasks = useCallback(
    (
      selectedStatus: FilterStatusType,
      selectedEmployeeIds: string[],
      selectedNewHireIds: string[],
      selectedJoinAtStrings: DateString[]
    ) => {
      // タスクのステータスでフィルター
      const filteredTasksByStatus = filteredByStatusOnboardingTasksMap[selectedStatus];

      // タスクの担当者でフィルター
      const filteredTasksByAssigneeEmployeeIds = filterTasksByAssigneeEmployeeIds(
        filteredTasksByStatus,
        selectedEmployeeIds,
        admins.map((v) => v.id)
      );

      // タスクの入社者でフィルター
      const filteredTasksByAssigneeEmployeeAndNewHireIds = filterTasksByNewHireIds(
        filteredTasksByAssigneeEmployeeIds,
        selectedNewHireIds
      );

      // 入社日でフィルター
      return filterTasksByJoinAt(
        filteredTasksByAssigneeEmployeeAndNewHireIds,
        selectedJoinAtStrings
      );
    },
    [
      admins,
      filterTasksByAssigneeEmployeeIds,
      filterTasksByJoinAt,
      filterTasksByNewHireIds,
      filteredByStatusOnboardingTasksMap,
    ]
  );

  // ステータスと入社日でフィルターした中から担当者のidを取得する
  const selectableEmployeeIds = useMemo(() => {
    return extractAssigneeEmployeeIds(
      getFilteredByStatusAndJoinAt(selectedStatus, selectedJoinAtStrings),
      admins
    );
  }, [
    admins,
    extractAssigneeEmployeeIds,
    getFilteredByStatusAndJoinAt,
    selectedJoinAtStrings,
    selectedStatus,
  ]);

  // ステータスと入社日でフィルターした中から入社者のidを取得する
  const selectableNewHireIds = useMemo(
    () =>
      Array.from(
        new Set(
          getFilteredByStatusAndJoinAt(selectedStatus, selectedJoinAtStrings).map(
            (task) => task.employeeId
          )
        )
      ),
    [getFilteredByStatusAndJoinAt, selectedJoinAtStrings, selectedStatus]
  );

  const filteredOnboardingTasks = useMemo(() => {
    return sortOnboardingTasks(
      getFilteredOnboardingTasks(
        selectedStatus,
        selectedEmployeeIds,
        selectedNewHireIds,
        selectedJoinAtStrings
      )
    );
  }, [
    getFilteredOnboardingTasks,
    selectedEmployeeIds,
    selectedJoinAtStrings,
    selectedNewHireIds,
    selectedStatus,
    sortOnboardingTasks,
  ]);

  const selectableDateArray = useMemo(() => {
    return newHires ? getSelectableDateArray(newHires) : [];
  }, [newHires]);

  const filterOptions: FilterOptionType[] = useMemo(
    () =>
      AllFilterStatusType.map((status) => {
        return {
          title: displayFilterStatusMap[status],
          totalCount: getFilteredOnboardingTasks(
            status,
            selectedEmployeeIds,
            selectedNewHireIds,
            selectedJoinAtStrings
          ).length,
          status: status,
        };
      }),
    [getFilteredOnboardingTasks, selectedEmployeeIds, selectedJoinAtStrings, selectedNewHireIds]
  );

  return {
    currentUser,
    targetTaskForSummary,
    setTargetTaskForSummary,
    targetEmployeeForSummary,
    setTargetEmployeeIdForSummary,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectableEmployeeIds,
    selectedNewHireIds,
    selectableNewHireIds,
    setSelectedNewHireIds,
    selectedStatus,
    setSelectedStatus,
    selectedJoinAtStrings,
    setSelectedJoinAtStrings,
    selectableDateArray,
    onboardingTasks,
    mutateOnboardingTasks,
    filteredOnboardingTasks,
    isValidatingOnboardingTasks,
    isValidatingNewHires,
    mentor,
    supportMembers,
    admins,
    specificEmployees,
    handleOpenEditTaskModal,
    handleClickDeleteTaskButton,
    handleClickChangeStatusButton,
    filterOptions,
  };
};
