import { Employee } from "../../Employee";
import { OnboardingGeneralTask } from "../OnboardingGeneralTask";
import { OnboardingMessageTask } from "../OnboardingMessageTask";
import { OnboardingSimpleTask } from "../OnboardingSimpleTask";

export type OnboardingTaskWithNewHire = OnboardingTaskType & {
  newHire?: Employee;
};

type OnboardingTaskInitType =
  | Optional<ConstructorParameters<typeof OnboardingGeneralTask>[0], "id">
  | Optional<ConstructorParameters<typeof OnboardingMessageTask>[0], "id">
  | Optional<ConstructorParameters<typeof OnboardingSimpleTask>[0], "id">;

export type OnboardingTaskType =
  | OnboardingGeneralTask
  | OnboardingMessageTask
  | OnboardingSimpleTask;

export class OnboardingTaskFactory {
  public static createOnboardingTask(init: OnboardingTaskInitType): OnboardingTaskType {
    switch (init.type) {
      case "GENERAL_TASK": {
        return OnboardingGeneralTask.create(init);
      }
      case "MESSAGE_TASK": {
        return OnboardingMessageTask.create(init);
      }
      case "SIMPLE_TASK": {
        return OnboardingSimpleTask.create(init);
      }
    }
  }
}
