import { useCallback } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";

/**
 * 自己紹介を配信する関数を提供するhooks
 */
export const useNotifyIntroduction = () => {
  /**
   * 自己紹介を配信する関数
   */
  const notifyIntroduction = useCallback(async () => {
    await functionOperator.httpsCallFor2ndGen("notifyintroduction");
  }, []);

  return { notifyIntroduction };
};
