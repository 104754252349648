import { ContactMessage, AllContactRoom, APISchema } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const generateContactMessagesKey = ({
  tenantId,
  contactRoomId,
  isWithAttachedFileSignedUrl,
}: {
  tenantId: string;
  contactRoomId: string;
  isWithAttachedFileSignedUrl?: boolean;
}) => {
  return {
    name: `get_contact_rooms_messages`,
    tenantId,
    contactRoomId,
    isWithAttachedFileSignedUrl,
  } as const;
};
type EndPoint = APISchema["/get_contact_rooms_messages"]["GET"];

export const useContactMessages = ({
  tenantId,
  contactRoomId,
  isWithAttachedFileSignedUrl,
}: {
  tenantId: string;
  contactRoomId: string;
  isWithAttachedFileSignedUrl?: boolean;
}) => {
  return useSWR(
    generateContactMessagesKey({ tenantId, contactRoomId, isWithAttachedFileSignedUrl }),
    async ({ isWithAttachedFileSignedUrl }) => {
      const queryParam: EndPoint["query"] = {
        id: contactRoomId,
        "is-with-attached-file-signed-url": isWithAttachedFileSignedUrl,
      };
      const response = await apiClient.get("/get_contact_rooms_messages", queryParam);
      if (response.data) {
        return {
          contactRoom: response.data.contactRoom as AllContactRoom,
          messages: response.data.messages.map((message) => {
            const contactMessage = message as ContactMessage & {
              creatorName: string;
              creatorImageUrl: string | undefined;
            };
            return Object.assign(ContactMessage.plainToInstance(contactMessage), {
              creatorName: contactMessage.creatorName,
              creatorImageUrl: contactMessage.creatorImageUrl,
            });
          }),
          meta: response.meta,
        };
      }
      return {
        contactRoom: null,
        messages: [],
        meta: {
          fileSignedUrlMapObj: {},
        },
      };
    }
  );
};
