import React, { useState, FC, ChangeEvent, memo } from "react";
import styled from "styled-components";

import { IconButton } from "~/components/uiParts/IconButton";
import { TextField } from "~/components/uiParts/TextField";
import { useDebouncedCallback } from "~/hooks/shared";

type SearchFormProps = {
  placeholder: string;
  variant: "standard" | "outlined";
  fullWidth?: boolean;
  onSearchValue: (value: string) => void;
};

export const SearchForm: FC<SearchFormProps> = memo(
  ({ placeholder, variant, fullWidth, onSearchValue }: SearchFormProps) => {
    const [inputValue, setInputValue] = useState("");
    const handleChange = useDebouncedCallback((callback) => callback(), 200);

    return (
      <StyledTextField
        variant={variant}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          // 画面の描画だけ先に済ませ、検索処理は200ms後に走らせる
          setInputValue(e.target.value);

          handleChange(() => {
            onSearchValue(e.target.value);
          });
        }}
        endAdornment={<IconButton icon="search" size="sm" />}
        $fullWidth={Boolean(fullWidth)}
      />
    );
  }
);

const StyledTextField = styled(TextField)<{ $fullWidth: boolean }>`
  width: ${(props) => (props.$fullWidth ? "100%" : "280px")};

  .MuiInputBase-input {
    font-size: 14px;
  }
`;
