import { Box } from "@material-ui/core";
import React, { useCallback, useState, FC } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  count: number;
};

export const DeleteMultipleTasksModal: FC<Props> = ({ open, onSubmit, onCancel, count }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await onSubmit()
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [onCancel, onSubmit]);

  const Content = (
    <Box textAlign="center">
      <Typography variant="body1">選択した{count}件のタスクを削除しますか？</Typography>
      <Box mt="8px" textAlign="center">
        <Typography variant="caption">この操作は取り消すことができません。</Typography>
      </Box>
    </Box>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        削除
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="タスク一括削除"
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
