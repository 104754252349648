import { Box } from "@material-ui/core";
import { OnboardingTaskType, displayAssigneeRoleMap, Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC } from "react";

import { useGetEmployeesByAssigneeRole } from "../../hooks";

import { Tooltip, Typography, UserIcon, UserIconGroup } from "~/components/uiParts";
import { useEmployee, useEmployees, useAdmins } from "~/hooks/employee";
import { useGetUserIconBorderColorByAssigneeRole } from "~/hooks/onboardingTask";

const notAssignTextMap = {
  ADMIN: "取得中",
  MENTOR: "バディ未設定",
  SUPPORT_MEMBER: "サポートメンバー未設定",
  NEW_HIRE: "取得中",
  BY_NAME: "担当未設定",
  NOT_SET: "担当未設定",
};

type Props = {
  onboardingTask: OnboardingTaskType;
  newHire: Employee;
  onClickEditTask: () => void;
};

export const AssigneeEmployees: FC<Props> = ({ onboardingTask, newHire, onClickEditTask }) => {
  const { data: mentor } = useEmployee(newHire.mentorUserId);
  const { data: supportMembers = [] } = useEmployees(newHire.supportMemberEmployeeIds);
  const { data: admins = [] } = useAdmins(newHire.tenantId);
  const { data: specificEmployees = [] } = useEmployees(onboardingTask.assigneeIds);

  const { getEmployeesByAssigneeRole } = useGetEmployeesByAssigneeRole();
  const { getBorderColorByAssigneeRole } = useGetUserIconBorderColorByAssigneeRole();

  const assigneeEmployees = getEmployeesByAssigneeRole({
    onboardingTask,
    newHire,
    mentor,
    supportMembers,
    specificEmployees,
    admins,
  });

  return (
    <Box display="flex" alignItems="center" gridGap="8px" width="100%">
      {isEmpty(assigneeEmployees) ? (
        <Tooltip title="担当者を設定する" placement="bottom" arrow>
          <Box
            display="flex"
            alignItems="center"
            gridGap="8px"
            width="100%"
            onClick={(e) => {
              e.stopPropagation();
              onClickEditTask();
            }}
          >
            <UserIcon username="" size="small" circular />
            <Typography variant="caption" color="textSecondary" noWrap>
              {notAssignTextMap[onboardingTask.assigneeRole]}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <>
          {assigneeEmployees.length === 1 ? (
            <Tooltip
              arrow
              title={
                onboardingTask.assigneeRole === "NOT_SET"
                  ? ""
                  : displayAssigneeRoleMap[onboardingTask.assigneeRole]
              }
              placement="bottom"
            >
              <Box display="flex" alignItems="center" gridGap="8px" width="100%">
                <UserIcon
                  username={(assigneeEmployees[0] as (typeof assigneeEmployees)[number]).getName()}
                  profileIconImageUrl={
                    (assigneeEmployees[0] as (typeof assigneeEmployees)[number]).profileIconImageUrl
                  }
                  size="small"
                  circular
                  borderColor={getBorderColorByAssigneeRole(onboardingTask.assigneeRole)}
                />
                <Typography variant="caption" color="textSecondary" noWrap>
                  {(assigneeEmployees[0] as (typeof assigneeEmployees)[number]).getName()}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <UserIconGroup
              usersInfo={assigneeEmployees.map((u) => ({
                username: u.getName(),
                profileIconImageUrl: u.profileIconImageUrl,
                borderColor: getBorderColorByAssigneeRole(onboardingTask.assigneeRole),
              }))}
              max={4}
              tooltip
            />
          )}
        </>
      )}
    </Box>
  );
};
