import { Menu, MenuItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback, useState, MouseEvent } from "react";

import { Typography, IconButton } from "~/components/uiParts";
import { useIsMyDepartmentAdmin } from "~/hooks/department/useIsMyDepartmentAdmin";

// ====================
// props
// ====================

type Props = {
  onClickEditTaskButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickManageWelcomeMessageButton: () => void;
  onClickOpenSidebarComments: () => void;
  newHire: Employee;
  currentUser: Employee;
};

// ====================
// main
// ====================

export const ManageOnboardingMessageTaskMenu: FC<Props> = ({
  onClickEditTaskButton,
  onClickDeleteTaskButton,
  onClickManageWelcomeMessageButton,
  onClickOpenSidebarComments,
  newHire,
  currentUser,
}) => {
  // ====================
  // state
  // ====================

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // ====================
  // variable
  // ====================

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const { result: isMyDepartmentAdmin } = useIsMyDepartmentAdmin(newHire, currentUser);
  const canEditWhoSendsMessage = currentUser.isAdmin() || isMyDepartmentAdmin;

  // ====================
  // main component
  // ====================

  return (
    <>
      <IconButton icon="menuVert" onClick={openMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {canEditWhoSendsMessage && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onClickManageWelcomeMessageButton();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">記入依頼設定</Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickEditTaskButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">タスク編集</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickDeleteTaskButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">タスク削除</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickOpenSidebarComments();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">コメント追加</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
