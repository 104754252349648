import { Box } from "@material-ui/core";
import { convertToYYMMDD, Survey, SurveyTransaction } from "@onn/common";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

const replaceName = (description: string, userName: string) => {
  return description.replace(/\[入社者名\]/g, userName);
};

interface Props {
  survey: Survey;
  surveyTransaction: SurveyTransaction;
  userName: string;
  isPreview?: boolean;
}

export const SurveyLanding: FC<Props> = ({
  survey,
  surveyTransaction,
  userName,
  isPreview,
}: Props) => {
  const { t } = useTranslation(["survey"]);

  const navigate = useNavigate();

  return (
    <StyledBox maxWidth="848px">
      <Typography variant="h2" color="textPrimary">
        {survey.title}
      </Typography>
      <StyledCard>
        <Box mb={5}>
          {survey.subtitle && (
            <Box mb={3}>
              <Typography variant="h4" bold color="textPrimary">
                {survey.subtitle}
              </Typography>
            </Box>
          )}
          <StyledDescriptionTypography variant="body2" color="textPrimary">
            {replaceName(survey.description, userName)}
          </StyledDescriptionTypography>
        </Box>
        <StyledButtonContainer>
          <Button
            fullWidth
            type="submit"
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(
                isPreview
                  ? `/preview/surveys/${survey.id}/new`
                  : `/journey/surveys/${survey.id}/new`
              )
            }
          >
            {t("startButton")}
          </Button>
        </StyledButtonContainer>
        <StyledCaptionWrapper textAlign="center">
          <Typography variant="caption" color="primary">
            {t("responseDeadline", {
              date: convertToYYMMDD(surveyTransaction.remindDate),
            })}
          </Typography>
        </StyledCaptionWrapper>
      </StyledCard>
    </StyledBox>
  );
};

const StyledDescriptionTypography = styled(Typography)`
  line-height: 24px;
`;

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;

  ${mixin.breakDown.sm`
    background-color: white;
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 28px;
  padding: 80px;

  ${mixin.breakDown.sm`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0px;
    width: 100%;
  `}
`;

const StyledCaptionWrapper = styled(Box)`
  margin-top: 16px;
  ${mixin.breakDown.sm`
    margin-top: 24px;
  `}
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
