import { useState, useCallback } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { DepartmentUseCase } from "~/service/usecases/departmentUseCase";
import { captureException } from "~/util";

const departmentUseCase = new DepartmentUseCase();

export const useDeleteDepartments = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const deleteDepartments = useCallback(
    async (departmentIds: string[]) => {
      setIsLoading(true);
      await departmentUseCase
        .deleteDepartments(currentUser, departmentIds)
        .then(() => {
          enqueueSnackbar("部署を削除しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useDeleteDepartments:deleteDepartments" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [currentUser, enqueueSnackbar]
  );

  return { isLoading, deleteDepartments };
};
