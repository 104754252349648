import { toNumber } from "lodash";

import { AnswerItem } from "../../Survey/AnswerItem";
import { Survey } from "../../Survey/Survey";
import { SendTargetType, TransactionDataType, TransactionStatus } from "../Transaction/Transaction";

export class SurveyTransaction {
  id: string;
  contents: Survey;
  dataType: TransactionDataType;
  remindDate: string;
  resultContents: AnswerItem[];
  sendAt: Date;
  answeredAt?: Date;
  sendTarget: SendTargetType;
  sendTo: string;
  status: TransactionStatus;
  templateId: string;
  employeeId: string;
  tenantId: string;
  constructor(init: {
    id: string;
    contents: Survey;
    dataType: TransactionDataType;
    remindDate: string;
    resultContents: AnswerItem[];
    sendAt: Date;
    answeredAt?: Date;
    sendTarget: SendTargetType;
    sendTo: string;
    status: TransactionStatus;
    templateId: string;
    employeeId: string;
    tenantId: string;
  }) {
    this.id = init.id;
    this.contents = init.contents;
    this.dataType = init.dataType;
    this.remindDate = init.remindDate;
    this.resultContents = init.resultContents;
    this.sendAt = init.sendAt;
    this.answeredAt = init.answeredAt;
    this.sendTarget = init.sendTarget;
    this.sendTo = init.sendTo;
    this.status = init.status;
    this.templateId = init.templateId;
    this.employeeId = init.employeeId;
    this.tenantId = init.tenantId;
  }

  public eNPSScore(): number | undefined {
    // TODO: question16がkeyのものにNPSが入っている。望ましくない状態なのでアンケートのリファクタリングで修正する
    const eNPSAnswerItem = this.resultContents.find((v) => v.key === "question16");

    if (!eNPSAnswerItem) {
      return undefined;
    }

    return isNaN(toNumber(eNPSAnswerItem.value)) ? undefined : toNumber(eNPSAnswerItem.value);
  }

  public answerExists(): boolean {
    return this.resultContents.length > 0;
  }

  public shouldAlert(): boolean {
    if (this.contents.type !== "JOINED") {
      return false;
    }

    const score = this.onboardingSurveyScore();
    return (score && score < 3) || this.hasAnswerForHR();
  }

  public onboardingSurveyScore(): number | undefined {
    return (
      toNumber(
        // TODO: question1が定量的な問いの回答になっている。望ましくない状態なのでアンケートのリファクタリングで修正する
        this.resultContents.find((v) => v.key === "question1")?.value
      ) || undefined
    );
  }

  public hasAnswerForHR(): boolean {
    // TODO: question4,5が人事向け回答になっている。望ましくない状態なのでアンケートのリファクタリングで修正する
    const question4 = this.resultContents.find((v) => v.key === "question4");
    const question5 = this.resultContents.find((v) => v.key === "question5");

    return !!question4 || !!question5;
  }

  static plainToInstance(init: DeepExcludeMethods<SurveyTransaction>): SurveyTransaction {
    return new SurveyTransaction({
      ...init,
      contents: Survey.plainToInstance(init.contents),
      resultContents: init.resultContents.map((rc) => AnswerItem.plainToInstance(rc)),
    });
  }
}
