import { OnboardingTaskWithNewHire } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback } from "react";

/**
 * オンボーディングタスクを担当者のidでfilterする関数を提供するhooks
 */
export const useFilterTasksByAssigneeEmployeeIds = () => {
  /**
   * オンボーディングタスクを担当者のidでfilterする
   * @param {OnboardingTaskWithNewHire[]} onboardingTasks
   * @param {Status[]} selectedStatuses
   * @param {Employee["joinAt"]} joinAt
   */
  const filterTasksByAssigneeEmployeeIds = useCallback(
    (
      onboardingTasks: OnboardingTaskWithNewHire[],
      selectedEmployeeIds: string[],
      adminIds: string[]
    ) => {
      // employeeIdが選択されていない場合はフィルターしない
      if (isEmpty(selectedEmployeeIds)) return onboardingTasks;
      return onboardingTasks.filter((task) => {
        switch (task.assigneeRole) {
          case "NOT_SET": {
            return false;
          }

          case "NEW_HIRE": {
            return task.newHire && selectedEmployeeIds.includes(task.newHire.id);
          }

          case "BY_NAME": {
            return task.assigneeIds.some((assigneeId) => selectedEmployeeIds.includes(assigneeId));
          }

          case "MENTOR": {
            return (
              task.newHire?.mentorUserId && selectedEmployeeIds.includes(task.newHire.mentorUserId)
            );
          }

          case "SUPPORT_MEMBER": {
            return (
              task.newHire?.supportMemberEmployeeIds &&
              task.newHire.supportMemberEmployeeIds.some((supportMemberEmployeeId) => {
                return selectedEmployeeIds.includes(supportMemberEmployeeId);
              })
            );
          }

          case "ADMIN": {
            return adminIds.some((adminId) => selectedEmployeeIds.includes(adminId));
          }

          default:
            break;
        }
      });
    },
    []
  );

  return { filterTasksByAssigneeEmployeeIds };
};
