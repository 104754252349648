import { Employee } from "@onn/common";
import { isAfter } from "date-fns";
import { useCallback } from "react";

export type OrderType = "asc" | "desc";

export const useSortEmployeesByJoinAt = () => {
  const sortEmployeesByJoinAt = useCallback((employees: Employee[], order: OrderType) => {
    return employees
      .reduce<Employee[]>((previousEmployee, currentEmployee) => {
        if (order === "desc") {
          return !currentEmployee.joinAt
            ? [currentEmployee, ...previousEmployee]
            : [...previousEmployee, currentEmployee];
        }
        if (order === "asc") {
          return !currentEmployee.joinAt
            ? [...previousEmployee, currentEmployee]
            : [currentEmployee, ...previousEmployee];
        }

        return previousEmployee;
      }, [])
      .sort((a, b) => {
        if (!a.joinAt || !b.joinAt) return 0;
        if (order === "desc") return isAfter(new Date(a.joinAt), new Date(b.joinAt)) ? -1 : 1;
        if (order === "asc") return isAfter(new Date(a.joinAt), new Date(b.joinAt)) ? 1 : -1;

        return 0;
      })
      .sort((a, b) => {
        if (a.joinAt || b.joinAt) return 0;
        if (!a.invitedAt || !b.invitedAt) return 0;
        if (order === "desc") return isAfter(a.invitedAt, b.invitedAt) ? -1 : 1;
        if (order === "asc") return isAfter(a.invitedAt, b.invitedAt) ? 1 : -1;

        return 0;
      });
  }, []);

  return { sortEmployeesByJoinAt };
};
