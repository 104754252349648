import { Employee } from "../../Employee";

type TemplateType = "MESSAGE" | "REGULAR_SURVEY" | "IRREGULAR_SURVEY";

export abstract class OnboardingExperienceTaskTemplate {
  id: string;
  tenantId: string;
  abstract type: TemplateType;
  isDefault: boolean;
  title: string;
  description: string;
  isPublic: boolean;
  editableEmployeeIds: string[];
  createdEmployeeId?: string;
  updatedEmployeeId?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    init: Pick<
      OnboardingExperienceTaskTemplate,
      | "id"
      | "tenantId"
      | "isDefault"
      | "title"
      | "description"
      | "isPublic"
      | "editableEmployeeIds"
      | "createdEmployeeId"
      | "updatedEmployeeId"
      | "createdAt"
      | "updatedAt"
    >
  ) {
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.isDefault = init.isDefault;
    this.title = init.title;
    this.description = init.description;
    this.isPublic = init.isPublic;
    this.editableEmployeeIds = init.editableEmployeeIds;
    this.createdEmployeeId = init.createdEmployeeId;
    this.updatedEmployeeId = init.updatedEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  public abstract update(
    employeeId: string,
    params: Pick<OnboardingExperienceTaskTemplate, "title" | "description">
  ): OnboardingExperienceTaskTemplate;

  isEditable(employee: Employee): boolean {
    return employee.isAdmin() || this.editableEmployeeIds.includes(employee.id);
  }
}
