import useSWR, { SWRResponse } from "swr";

import {
  CustomOnboardingExperienceTaskTemplate,
  FindOnboardingExperienceTaskTemplatesUseCase,
} from "~/service/usecases/onboardingExperienceTaskTemplate";
export type { CustomOnboardingExperienceTaskTemplate };

const findOnboardingExperienceTaskTemplatesUseCase =
  new FindOnboardingExperienceTaskTemplatesUseCase();

export const generateOnboardingExperienceTaskTemplatesKeys = (tenantId: string) => {
  return [`onboarding-experience-task-template-${tenantId}`, tenantId];
};

/**
 * OnboardingExperienceTaskTemplate の配列を返すswr
 * @param tenantId tenantのid
 */
export const useOnboardingExperienceTaskTemplates = (
  tenantId: string
): SWRResponse<CustomOnboardingExperienceTaskTemplate[], Error> => {
  const keys = tenantId ? generateOnboardingExperienceTaskTemplatesKeys(tenantId) : null;

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    findOnboardingExperienceTaskTemplatesUseCase.execute(tenantId)
  );
};
