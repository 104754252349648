import { v4 } from "uuid";

import { OnboardingExperienceTaskTemplate } from "../../OnboardingExperienceTaskTemplate";
import { OnboardingTaskMemo } from "../../OnboardingTask";
import { GeneralTaskSettings, ActionId } from "../../TenantSettings";
import { DifferenceDate } from "../../shared";
import {
  OnboardingExperienceTask,
  taskTypes,
} from "../OnboardingExperienceTask/OnboardingExperienceTask";

const MAX_TITLE_NUM = 70;

export class OnboardingExperienceGeneralTask extends OnboardingExperienceTask {
  id: string;
  type = taskTypes.GENERAL_TASK;
  actionId: ActionId;
  filePaths: (File | string)[];
  memos: OnboardingTaskMemo[];

  static MAX_TITLE_NUM = MAX_TITLE_NUM;

  constructor(init: Omit<OnboardingExperienceGeneralTask, "updateForUpdatingTemplate">) {
    super(init);
    this.id = init.id;
    this.actionId = init.actionId;
    this.filePaths = init.filePaths;
    this.memos = init.memos;
  }

  updateForUpdatingTemplate(
    template: OnboardingExperienceTaskTemplate
  ): OnboardingExperienceGeneralTask {
    return new OnboardingExperienceGeneralTask({
      ...this,
      title: template.title,
      body: template.description,
    });
  }

  public static create(
    params: Optional<Omit<OnboardingExperienceGeneralTask, "updateForUpdatingTemplate">, "id">
  ) {
    return new OnboardingExperienceGeneralTask({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static createTasksByActionIds(tenantId: string, generalTaskSettings: GeneralTaskSettings) {
    const setJoinAt = OnboardingExperienceGeneralTask.create({
      assigneeRole: generalTaskSettings.assigneeRole,
      assigneeIds: generalTaskSettings.assigneeIds,
      actionId: "SET_JOIN_AT",
      type: "GENERAL_TASK",
      title: "入社日設定",
      body:
        "入社日が決まったら入社日設定をしましょう。\n\n" +
        "▼手順\n・入社日設定をクリックし、入社日を設定する\n\n" +
        "■完了条件\n・入社日を設定したタイミング(設定すると自動で完了になります)",
      filePaths: [],
      onboardingExperienceId: "mockOnboardingExperienceId",
      deliveryDate: new DifferenceDate({}),
      dueDate: new DifferenceDate({
        unit: "DAY",
        amount: 15,
        direction: "AFTER",
        referenceDateType: "INVITED_AT",
      }),
      isRequired: true,
      index: 0,
      memos: [],
      status: "NOT_STARTED",
      tenantId,
    });

    const assignMentorOrSupportMember = OnboardingExperienceGeneralTask.create({
      assigneeRole: generalTaskSettings.assigneeRole,
      assigneeIds: generalTaskSettings.assigneeIds,
      actionId: "ASSIGN_MENTOR_OR_SUPPORT_MEMBER",
      type: "GENERAL_TASK",
      title: "受け入れチーム設定",
      body:
        "入社者をフォローする受け入れチーム（バディ、サポートメンバー）を設定しましょう。\n\n" +
        "▼手順\n・下記をクリックし、受け入れチーム（バディ、サポートメンバー）を選択する\n\n" +
        "■完了条件\n・受け入れチームを設定したタイミング(設定すると自動で完了になります)",
      filePaths: [],
      onboardingExperienceId: "mockOnboardingExperienceId",
      deliveryDate: new DifferenceDate({}),
      dueDate: new DifferenceDate({
        unit: "DAY",
        amount: 7,
        direction: "BEFORE",
        referenceDateType: "JOIN_AT",
      }),
      isRequired: true,
      index: 1,
      memos: [],
      status: "NOT_STARTED",
      tenantId,
    });

    const createAccount = OnboardingExperienceGeneralTask.create({
      assigneeRole: "NEW_HIRE",
      assigneeIds: [],
      actionId: "CREATE_ACCOUNT",
      type: "GENERAL_TASK",
      title: "Onnアカウント作成",
      body:
        "最高の入社体験をスタートするために、Onnのアカウントを登録しましょう。\n\n" +
        "▼手順\n・下記のボタンをクリックし、アカウントを登録する\n\n" +
        "■完了条件\n・アカウントを登録したタイミング(登録すると自動で完了になります)",
      filePaths: [],
      onboardingExperienceId: "mockOnboardingExperienceId",
      deliveryDate: new DifferenceDate({}),
      dueDate: new DifferenceDate({
        unit: "DAY",
        amount: 7,
        direction: "AFTER",
        referenceDateType: "INVITED_AT",
      }),
      isRequired: true,
      index: 0,
      memos: [],
      status: "NOT_STARTED",
      tenantId,
    });

    const integrateSlack = OnboardingExperienceGeneralTask.create({
      assigneeRole: generalTaskSettings.assigneeRole,
      assigneeIds: generalTaskSettings.assigneeIds,
      actionId: "INTEGRATE_SLACK",
      type: "GENERAL_TASK",
      title: "Slackアカウント連携",
      body:
        "入社者のスムーズな入社体験を実現するために、アカウント連携を完了しましょう。\n\n" +
        "▼手順\n・Slackアカウント連携をクリックし、Slackのアカウントを選択する\n\n" +
        "■完了条件\n・Slackアカウント連携をしたタイミング(連携すると自動で完了になります)",
      filePaths: [],
      onboardingExperienceId: "mockOnboardingExperienceId",
      deliveryDate: new DifferenceDate({}),
      dueDate: new DifferenceDate({
        unit: "BUSINESS_DAY",
        amount: 3,
        direction: "AFTER",
        referenceDateType: "JOIN_AT",
      }),
      isRequired: true,
      index: 2,
      memos: [],
      status: "NOT_STARTED",
      tenantId,
    });

    const registerProfile = OnboardingExperienceGeneralTask.create({
      assigneeRole: "NEW_HIRE",
      assigneeIds: [],
      onboardingExperienceId: "mockOnboardingExperienceId",
      deliveryDate: new DifferenceDate({}),
      actionId: "REGISTER_PROFILE",
      type: "GENERAL_TASK",
      title: "自己紹介記入",
      filePaths: [],
      body:
        "バディやメンバーたちに向けて、簡単な自己紹介を記入しましょう！\n\n" +
        "自分のことを知ってもらうことで、バディやメンバーは受け入れを円滑に進めることができます。\n\n" +
        "自己紹介は以下の「5項目」で完了します！\n・生年月日\n・ニックネーム\n・私を構成するタグ\n・経歴サマリー\n・これから一緒に働く仲間に一言\n\n" +
        "▼手順\n・下記のボタンをクリックし、自己紹介を作成する\n\n" +
        "■完了条件\n・自己紹介を設定したタイミング(設定すると自動で完了になります)",
      dueDate: new DifferenceDate({
        unit: "DAY",
        amount: 7,
        direction: "BEFORE",
        referenceDateType: "JOIN_AT",
      }),
      isRequired: true,
      index: 1,
      memos: [],
      status: "NOT_STARTED",
      tenantId,
    });

    return generalTaskSettings.actionIds.map((actionId) => {
      switch (actionId) {
        case "SET_JOIN_AT":
          return setJoinAt;
        case "ASSIGN_MENTOR_OR_SUPPORT_MEMBER":
          return assignMentorOrSupportMember;
        case "REGISTER_PROFILE":
          return registerProfile;
        case "INTEGRATE_SLACK":
          return integrateSlack;
        case "CREATE_ACCOUNT":
          return createAccount;
      }
    });
  }

  static getNewHireTasks(tenantId: string, generalTaskSettings: GeneralTaskSettings) {
    return this.createTasksByActionIds(tenantId, generalTaskSettings).filter((generalTask) => {
      return generalTask.assigneeRole === "NEW_HIRE";
    });
  }
}
