import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee } from "@onn/common";
import React, { FC, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { useViewModel } from "./useViewModel";

import { ActivityTab } from "~/components/domains/employees/ActivityTab";
import { EmployeeSummary } from "~/components/domains/employees/EmployeeSummary";
import { MemoTab } from "~/components/domains/employees/MemoTab";
import { ProfileTab } from "~/components/domains/employees/ProfileTab";
import { SurveyTab } from "~/components/domains/employees/SurveyTab";
import { TaskTab } from "~/components/domains/employees/TaskTab";

import { Loading, Typography } from "~/components/uiParts";
import { useAccessControl } from "~/hooks/accessControl";
import { useEmployee } from "~/hooks/employee/useEmployee";
import { useQuery } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";

type TabValue = "activity" | "task" | "survey" | "profile" | "memo";

// タスクのみアサインされたユーザーはタスクタブのみ見ることができる
const getIsDisplayAllTabs = (currentUser: Employee, newHire: Employee, isEditable: boolean) => {
  return (
    isEditable ||
    newHire.mentorUserId === currentUser.id ||
    newHire.supportMemberEmployeeIds?.includes(currentUser.id)
  );
};

export const NewHireEmployeeDetail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: employeeId } = useParams<"id">();
  const { query } = useQuery();

  const memoId = query.get("memoId");
  const answerId = query.get("answerId");
  const contentId = query.get("contentId");
  const commentFocused = query.get("commentFocused") === "true";
  const reactionModalOpened = query.get("reactionModal") === "true";
  const manageWelcomeMessageModalOpened = query.get("manageWelcomeMessageModal") === "true";

  const [activeTab, setActiveTab] = useState<TabValue>("activity");

  const { isEditable } = useAccessControl();
  const { data: newHire, isValidating: isValidatingNewHire } = useEmployee(employeeId);

  const {
    transactionsLoading,
    memoAttachedFilesLoading,
    commentsLoading,
    transactionCommentsMap,
    transactionReactionsMap,
    isLoadingTransactionReactionsMap,
    transactions,
    surveyTransactions,
    memos,
    memoAttachedFilesMap,
    currentUser,
    chartPointItems,
    accessibleEmployees,
    handleCreateMemo,
    handleEditMemo,
    handleDeleteMemo,
    handleDownloadMemoAttachedFile,
    handleDeleteMemoAttachedFile,
    loadTransactionComments,
  } = useViewModel(newHire);

  // クエリストリングで指定されているコンテンツによって、初期状態で開くTabを切り分ける
  useEffect(() => {
    if (newHire && getIsDisplayAllTabs(currentUser, newHire, isEditable(newHire))) return;
    if (contentId) {
      setActiveTab("activity");
    } else if (answerId) {
      setActiveTab("survey");
    } else if (memoId) {
      setActiveTab("memo");
    }
  }, [contentId, answerId, memoId, currentUser, newHire, isEditable]);

  useEffect(() => {
    if (newHire && !getIsDisplayAllTabs(currentUser, newHire, isEditable(newHire))) {
      navigate("#task");
      return setActiveTab("task");
    }

    switch (location.hash) {
      case "#activity": {
        setActiveTab("activity");
        break;
      }
      case "#task": {
        setActiveTab("task");
        break;
      }
      case "#survey": {
        setActiveTab("survey");
        break;
      }

      case "#profile": {
        setActiveTab("profile");
        break;
      }
      case "#memo": {
        setActiveTab("memo");
        break;
      }
      default: {
        void 0;
      }
    }
  }, [currentUser, isEditable, location.hash, navigate, newHire]);

  if (isValidatingNewHire && !newHire) {
    return <Loading size="large" fullHeight />;
  }

  if (!newHire) {
    return <NotFound />;
  }

  const handleChangeActiveTab = (_: unknown, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: TabValue }> = getIsDisplayAllTabs(
    currentUser,
    newHire,
    isEditable(newHire)
  )
    ? [
        {
          label: "アクティビティ",
          value: "activity",
        },
        {
          label: "タスク",
          value: "task",
        },
        {
          label: "アンケート",
          value: "survey",
        },
        {
          label: "入社者情報",
          value: "profile",
        },
        {
          label: "メモ",
          value: "memo",
        },
      ]
    : [
        {
          label: "タスク",
          value: "task",
        },
      ];

  return (
    <>
      <StyledLink to="/">
        <Typography variant="body2" color="textSecondary">
          ← オンボーディング｜ステータス
        </Typography>
      </StyledLink>
      {
        <EmployeeSummary
          newHire={newHire}
          currentUser={currentUser}
          manageWelcomeMessageModalOpened={manageWelcomeMessageModalOpened}
        />
      }
      <Box mt={3}>
        <TabContext value={activeTab}>
          <StyledTabList
            onChange={handleChangeActiveTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          {getIsDisplayAllTabs(currentUser, newHire, isEditable(newHire)) && (
            <StyledTabPanel value="activity">
              <ActivityTab
                transactions={transactions}
                isLoadingTransactions={transactionsLoading}
                contentId={contentId}
                employee={newHire}
                memoAttachedFilesMap={memoAttachedFilesMap}
                memoAttachedFilesLoading={memoAttachedFilesLoading}
                suggestedMentionUsers={accessibleEmployees}
                transactionCommentsLoading={commentsLoading}
                currentUserId={currentUser.id}
                transactionCommentsMap={transactionCommentsMap}
                commentFocused={commentFocused}
                reactionModalOpened={reactionModalOpened}
                transactionReactionsMap={transactionReactionsMap}
                isLoadingTransactionReactionsMap={isLoadingTransactionReactionsMap}
                onDownloadMemoAttachedFile={handleDownloadMemoAttachedFile}
                onDeleteMemoAttachedFile={handleDeleteMemoAttachedFile}
                loadTransactionComments={loadTransactionComments}
              />
            </StyledTabPanel>
          )}
          <StyledTabPanel value="task">
            <TaskTab employee={newHire} />
          </StyledTabPanel>
          {getIsDisplayAllTabs(currentUser, newHire, isEditable(newHire)) && (
            <StyledTabPanel value="survey">
              <SurveyTab
                surveyTransactions={surveyTransactions}
                answerId={answerId}
                chartPointItems={chartPointItems}
                employee={newHire}
                suggestedMentionUsers={accessibleEmployees}
                transactionCommentsLoading={commentsLoading}
                currentUserId={currentUser.id}
                transactionCommentsMap={transactionCommentsMap}
                commentFocused={commentFocused}
                transactionReactionsMap={transactionReactionsMap}
                loadTransactionComments={loadTransactionComments}
              />
            </StyledTabPanel>
          )}
          {getIsDisplayAllTabs(currentUser, newHire, isEditable(newHire)) && (
            <StyledTabPanel value="profile">
              <ProfileTab employee={newHire} />
            </StyledTabPanel>
          )}
          {getIsDisplayAllTabs(currentUser, newHire, isEditable(newHire)) && (
            <StyledTabPanel value="memo">
              <MemoTab
                memos={memos}
                attachedFilesMap={memoAttachedFilesMap}
                attachedFilesLoading={memoAttachedFilesLoading}
                loading={transactionsLoading}
                currentUserId={currentUser.id}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onCreateMemo={handleCreateMemo}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onEditMemo={handleEditMemo}
                onDeleteMemo={handleDeleteMemo}
                transactionCommentsMap={transactionCommentsMap}
                commentFocused={commentFocused}
                commentListProps={{
                  suggestedMentionUsers: accessibleEmployees,
                  loadTransactionComments: loadTransactionComments,
                }}
                onDownloadAttachedFile={handleDownloadMemoAttachedFile}
                onDeleteAttachedFile={handleDeleteMemoAttachedFile}
                targetMemoId={memoId}
              />
            </StyledTabPanel>
          )}
        </TabContext>
      </Box>
    </>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
