import { Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { FindOnboardingExperienceUseCase } from "~/service/usecases/onboardingExperience";

const findOnboardingExperienceUseCase = new FindOnboardingExperienceUseCase();

/**
 * idをもとにonboardingExperienceを返すswr
 * @param id onboardingExperienceのid
 */
export const useOnboardingExperience = (
  currentUser: Employee,
  id?: string
): SWRResponse<Awaited<ReturnType<typeof findOnboardingExperienceUseCase.execute>>, Error> => {
  const keys = id ? ["onboardingExperience", id] : null;

  return useSWR(keys, ([_key, id]: [string, string]) =>
    findOnboardingExperienceUseCase.execute(id, currentUser)
  );
};
