import { Box } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";

type IconProps = ComponentProps<typeof Icon>;

type Props = {
  color?: "primary" | "grey" | "darkGrey" | "lightGrey" | "error" | "white";
  size?: "sm" | "md" | "lg";
} & Pick<IconProps, "icon">;

export const IconPanel: FC<Props> = ({ icon, color = "primary", size = "md", ...props }) => {
  return (
    <StyledBox p="10px" borderRadius={8} {...props}>
      <Icon icon={icon} size={size} color={color} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[11]};
  height: 100%;
`;
