import { Employee, OnboardingMessageTask } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "../../../modal";
import { useCancelMessageRequest } from "../useCancelMessageRequest";
import { useHandleAddRequestMessageTaskModal } from "../useHandleAddRequestMessageTaskModal";

import { useGetCopyMessageTaskTextForShareURL } from "~/components/domains/onboardingTask/hooks";
import { useClipboard } from "~/hooks/shared";

export const useHandleManageMessageTaskModal = () => {
  const { handleModal } = useModal();
  const { handleAddRequestMessageTaskModal } = useHandleAddRequestMessageTaskModal();

  const { cancelMessageRequest } = useCancelMessageRequest();

  const { handleCopy } = useClipboard();
  const { getCopyMessageTaskTextForShareURL } = useGetCopyMessageTaskTextForShareURL();

  /**
   * @param {OnboardingMessageTask} onboardingMessageTask タスク
   * @param {Employee} newHire 入社者
   */
  const handleOpenManageMessageTaskModal = useCallback(
    (onboardingMessageTask: OnboardingMessageTask, newHire: Employee) =>
      handleModal({
        name: "manageMessageTaskModal",
        args: {
          newHire,
          onboardingMessageTask,
          onClickAddRequestButton: () => {
            handleAddRequestMessageTaskModal(
              onboardingMessageTask,
              newHire,
              (onboardingMessageTask) =>
                handleOpenManageMessageTaskModal(onboardingMessageTask, newHire)
            );
          },
          onClickCopyLinkButton: () => {
            handleCopy(getCopyMessageTaskTextForShareURL(newHire, onboardingMessageTask), "リンク");
          },
          onClickCancelMessageRequestButton: async (contentId: string) => {
            return await cancelMessageRequest(onboardingMessageTask.id, contentId);
          },
        },
      }),
    [
      cancelMessageRequest,
      getCopyMessageTaskTextForShareURL,
      handleAddRequestMessageTaskModal,
      handleCopy,
      handleModal,
    ]
  );

  return { handleOpenManageMessageTaskModal };
};
