import { MemoTransaction } from "./MemoTransaction";
import { SurveyTransaction } from "./SurveyTransaction";
import { Transaction, TransactionDataType } from "./Transaction/Transaction";

export const transactionLabel = (
  transaction: Transaction | SurveyTransaction | MemoTransaction
) => {
  switch (transaction.dataType) {
    case TransactionDataType.JOIN_DATE_INPUT: {
      return "入社日設定";
    }
    case TransactionDataType.MENTOR_REGISTRATION: {
      return "バディ設定";
    }
    case TransactionDataType.PROFILE_COMMENT: {
      return "自己紹介";
    }
    case TransactionDataType.SURVEY: {
      return (transaction as SurveyTransaction).contents.title;
    }
    case TransactionDataType.WELCOME: {
      return "入社";
    }
    case TransactionDataType.ADD_MEMO: {
      return "メモ";
    }
    default: {
      return "";
    }
  }
};
