import { Box } from "@material-ui/core";
import { Employee, DifferenceDate } from "@onn/common";
import { format, isPast } from "date-fns";
import React, { FC, useCallback } from "react";

import { Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  deliveryDate: DifferenceDate;
  newHire: Employee;
};

// ====================
// main
// ====================

export const DeliveryDateSection: FC<Props> = ({ deliveryDate, newHire }) => {
  // ====================
  // variable
  // ====================

  const getDisplayDeliveryDateTiming = useCallback(
    (joinAt: Employee["joinAt"], deliveryDate: DifferenceDate) => {
      if (!joinAt) return `${deliveryDate.getDisplayTiming()}`;
      const deliveryDateTime = deliveryDate.calculateDate(new Date(joinAt));
      return `${format(deliveryDateTime, "yyyy/MM/dd")}：配信${
        isPast(deliveryDateTime) ? "済み" : "予定"
      }`;
    },
    []
  );

  // ====================
  // main component
  // ====================

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="caption" bold color="textPrimary">
        通知タイミング
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {getDisplayDeliveryDateTiming(newHire.joinAt, deliveryDate)}
      </Typography>
    </Box>
  );
};
