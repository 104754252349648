import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Button } from "~/components/uiParts";

type Props = {
  onClickInviteButton: () => void;
  disabled: boolean;
};

export const Footer: FC<Props> = ({ onClickInviteButton, disabled }) => {
  return (
    <Box display="flex" justifyContent="center" mx="auto" width="50%">
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={onClickInviteButton}
        disabled={disabled}
      >
        招待メールを送信
      </Button>
    </Box>
  );
};
