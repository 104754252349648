import { Box } from "@material-ui/core";
import { SurveyAlert } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { SurveyDetail } from "./parts/SurveyDetail";

import { SurveyDrawer } from "~/components/domains/transactions/survey/SurveyDrawer";
import { Button, Loading, Paper, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSendGAEvent } from "~/hooks/shared";
import {
  CustomOnboardingSurveysForAlert,
  useUncompletedCustomSurveyAlerts,
  useCompleteSurveyAlert,
} from "~/hooks/surveyAlert";

export const SurveyAlertPaper: FC = () => {
  const { currentUser } = useCurrentUser();
  const { sendGAEvent } = useSendGAEvent();
  const { completeSurveyAlert } = useCompleteSurveyAlert();
  const {
    data: onboardingSurveysForAlert = [],
    isValidating,
    mutate,
  } = useUncompletedCustomSurveyAlerts(currentUser.tenantId);

  const [detailMap, setDetailMap] = useState<{
    [answerId: string]: JSX.Element;
  }>({});
  const [answerIdOfOpenedDrawer, setAnswerIdOfOpenedDrawer] = useState<string | null>(null);

  const openDrawer = (surveyTransaction: CustomOnboardingSurveysForAlert): void => {
    setAnswerIdOfOpenedDrawer(surveyTransaction.id);
    if (!detailMap[surveyTransaction.id]) {
      const element = (
        <SurveyDetail newHire={surveyTransaction.newHire} surveyTransaction={surveyTransaction} />
      );
      setDetailMap((prevState) => ({
        ...prevState,
        [surveyTransaction.id]: element,
      }));
    }
  };

  const handleComplete = useCallback(
    async (surveyAlert: SurveyAlert) => await completeSurveyAlert(surveyAlert).then(() => mutate()),
    [completeSurveyAlert, mutate]
  );

  return (
    <>
      <StyledPaper square>
        <Typography align="center" bold>
          🔺 アンケートアラート
        </Typography>
        {isValidating && isEmpty(onboardingSurveysForAlert) ? (
          <Box pt="40px">
            <Loading size="small" />
          </Box>
        ) : (
          <>
            {isEmpty(onboardingSurveysForAlert) ? (
              <Box mt="56px">
                <Typography align="center" variant="h4">
                  該当アンケートなし
                </Typography>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" mt="56px">
                {onboardingSurveysForAlert.map(
                  (onboardingSurvey: CustomOnboardingSurveysForAlert) => {
                    return (
                      <StyledBox
                        display="flex"
                        key={onboardingSurvey.id}
                        alignItems="center"
                        gridGap="16px"
                        padding="16px"
                        onClick={() => {
                          openDrawer(onboardingSurvey);
                          sendGAEvent("click_survey_alert_item", currentUser.id);
                        }}
                      >
                        <UserIcon
                          username={onboardingSurvey.newHire.getName()}
                          profileIconImageUrl={onboardingSurvey.newHire.profileIconImageUrl}
                          size="small"
                        />

                        <StyledNameBox>
                          <Typography variant="caption" color="textPrimary">
                            {onboardingSurvey.newHire.getName()}
                          </Typography>
                          <br />
                          <Typography variant="caption" color="textSecondary">
                            {onboardingSurvey.contents.title}
                          </Typography>
                        </StyledNameBox>
                        <StyledScoreBox>
                          <Typography
                            variant="caption"
                            color={onboardingSurvey.score <= 2 ? "error" : "textPrimary"}
                          >
                            {`スコア：${onboardingSurvey.score}`}
                          </Typography>
                          <br />
                          {onboardingSurvey.hasHrOnly && (
                            <Typography variant="caption" color="error">
                              人事向け回答あり
                            </Typography>
                          )}
                        </StyledScoreBox>
                        <StyledButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleComplete(onboardingSurvey.surveyAlert);
                          }}
                          borderRadius="regular"
                          variant="outlined"
                          color="default"
                        >
                          <Typography variant="caption" noWrap>
                            対応済み
                          </Typography>
                        </StyledButton>
                      </StyledBox>
                    );
                  }
                )}
              </Box>
            )}
          </>
        )}
      </StyledPaper>
      <SurveyDrawer
        isOpen={!!answerIdOfOpenedDrawer && !!detailMap[answerIdOfOpenedDrawer]}
        onClose={() => setAnswerIdOfOpenedDrawer(null)}
        title="アンケート詳細"
        content={(!!answerIdOfOpenedDrawer && detailMap[answerIdOfOpenedDrawer]) || <></>}
      />
    </>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;

const StyledNameBox = styled(Box)`
  width: 50%;
`;

const StyledScoreBox = styled(Box)`
  width: 25%;
`;

const StyledButton = styled(Button)`
  width: 25%;
`;
