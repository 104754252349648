import React, { FC } from "react";

export const Link: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        d="M12,8H8.8V6.48H12a2.48,2.48,0,0,0,0-4.96H8.8V0H12a4,4,0,0,1,0,8ZM7.2,8H4A4,4,0,1,1,4,0H7.2V1.52H4A2.48,2.48,0,1,0,4,6.48H7.2V8Zm4-3.2H4.8V3.2h6.4V4.8Z"
        transform="translate(0 4)"
      />
    </svg>
  );
};
