import { ContactMessageTemplate } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { Key } from "./useContactMessageTemplates";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * コンタクトメッセージテンプレートを更新するAPIを呼び出す関数を返す
 * @param mutateKeys
 */
export const useUpdateContactMessageTemplate = ({
  contactMessageTemplates,
  refetchKey,
}: {
  contactMessageTemplates: ContactMessageTemplate[];
  refetchKey: Key;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  コンタクトメッセージテンプレートを更新する
   */
  const updateContactMessageTemplate = useCallback(
    async (template: ContactMessageTemplate) => {
      try {
        const optimisticData = [...contactMessageTemplates, template];

        await mutate(
          refetchKey,
          async () => {
            await apiClient.patch(`/patch_contact_message_templates`, {
              id: template.id,
              ["title"]: template.title,
              ["text"]: template.text,
            });
            return mutate(refetchKey);
          },
          {
            optimisticData,
          }
        );
        enqueueSnackbar("テンプレートが編集されました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("テンプレートの編集に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateContactMessageTemplate:updateContactMessageTemplate",
          },
        });
      }
    },
    [contactMessageTemplates, enqueueSnackbar, mutate, refetchKey]
  );

  return { updateContactMessageTemplate };
};
