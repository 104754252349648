import { Reaction } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { ReactionUseCase } from "~/service/usecases/reactionUseCase";

const reactionUseCase = new ReactionUseCase();

/**
 * transactionIdsをもとにtransactionに紐づいたreactionsを返すswr
 * @param {string[]} transactionIds
 */
export const useReactionsByTransactionIds = (
  transactionIds?: string[]
): SWRResponse<Reaction[], Error> => {
  const key = transactionIds ? ["reactions", transactionIds] : null;

  return useSWR(key, ([_key, transactionIds]: [string, string[]]) =>
    reactionUseCase.whereByTransactionIds(transactionIds).then((res) => res)
  );
};
