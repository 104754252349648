import { OnboardingMessageTask, Employee } from "@onn/common";

/**
 * メッセージタスクの共有リンクをコピーする用途のメッセージを生成する関数を提供するhooks
 * @returns
 */
export const useGetCopyMessageTaskTextForShareURL = () => {
  /**
   * メッセージタスクの共有リンクをコピーする用途のメッセージを生成する
   * @param newHire 入社者
   * @param messageTask 対象のWelcomeメッセージのid
   * @returns コピーするメッセージ内容
   */
  const getCopyMessageTaskTextForShareURL = (
    newHire: Employee,
    messageTask: OnboardingMessageTask
  ) => {
    return `${newHire.getName()}さんの${
      messageTask.title
    }の記入をお願いします。\n${`${messageTask.dueDate.getDisplayTextByEmployee(
      newHire
    )}に配信されるので、それまでに記入をお願いします。`}\n${location.origin}/messages/${
      messageTask.id
    }/guest`;
  };

  return { getCopyMessageTaskTextForShareURL };
};
