import { format } from "date-fns";
import { v4 } from "uuid";

import { isValidUrl } from "../../utils/isValidUrl";

import { LangType } from "../shared/LangType";

import { OnboardingStatus } from "./OnboardingStatus";
import { Profile } from "./Profile";
import { Role } from "./Role";

const displayRoleMap = {
  [Role.ADMIN]: "管理者",
  [Role.DEPARTMENT_ADMIN]: "部門管理者",
  [Role.MANAGER]: "マネージャー",
  [Role.MEMBER]: "メンバー",
};

export const displayRecruitmentStatusMap = {
  pre_entry: "プレエントリー",
  screening: "選考中",
  job_offer: "内定(承諾待ち)",
  offer_accepted: "入社承諾",
  rejected: "不採用",
  withdrew: "辞退",
} as const;

export type RecruitmentStatus = keyof typeof displayRecruitmentStatusMap;

type iconType = "NONE" | "URL" | "FIRE_STORAGE";

export type selectedAuthenticationType = "email" | "line" | "google";
export type AuthenticationType = "email" | "line" | "google";

export type NewGraduate = Employee & {
  isNewGraduate: true;
  assignedAsNewcomer: true;
  recruitmentStatus: RecruitmentStatus;
  spaceId: string;
};

/*
 * メンバー権限(入社者、バディ)
 */
export class Employee {
  id: string;
  tenantId: string;
  email: string;
  firstName: string;
  lastName: string;
  invitedAt?: Date; // 招待日時、この日時がある場合は招待メール送信済みと扱う
  lastInvitedAt?: Date;
  invitationToken: string; // 招待トークン、このトークンはEmployee生成時に必ず発行される
  onboardingStatus: OnboardingStatus;
  // NOTE: onboardingStatusの履歴を保持するためのプロパティ
  // TODO: 正常に機能していないかつ、扱われている場所も不明なので整理して削除する
  onboardingStatuses?: OnboardingStatus[];
  accountCreateAt: string;
  joinAt?: string;
  role: Role;
  assignedAsNewcomer: boolean; // オンボーディング対象者として割り当てられたかどうか
  uid?: string;
  slackUserId?: string;
  profileIconImageUrl?: string;
  profile?: Profile;
  mentorUserId?: string;
  supportMemberEmployeeIds?: string[]; // サポートメンバーのidの配列
  departmentIds: string[];
  deleted: boolean;
  lastRefreshTime?: Date;
  lastActiveTime?: Date;
  // 以下新卒入社者向けのプロパティ
  isNewGraduate?: boolean;
  lineUserId?: string;
  /** 公式アカウントをフォローしているかどうか(undefined と false は同じように扱える) */
  isFollowedLineOfficialAccount?: boolean;
  memo?: string;
  selectedAuthenticationType: selectedAuthenticationType; // 管理者がアカウントを作成する際にどの認証方法で登録してもらうか指定する
  currentAuthenticationType?: AuthenticationType;
  recruitmentStatus?: keyof typeof displayRecruitmentStatusMap;
  spaceId?: string;
  lang: LangType; // アンケートなどで利用する言語

  readonly deletedAt?: Date;

  static displayRoleMap = displayRoleMap;
  static displayRecruitmentStatusMap = displayRecruitmentStatusMap;
  // 「選考中」「内定」「入社承諾」「不合格・不採用」「辞退」
  constructor({
    id,
    tenantId,
    email,
    firstName,
    lastName,
    invitedAt,
    lastInvitedAt,
    invitationToken,
    onboardingStatus,
    onboardingStatuses,
    accountCreateAt,
    joinAt,
    role,
    assignedAsNewcomer,
    uid,
    slackUserId,
    profileIconImageUrl,
    profile,
    mentorUserId,
    supportMemberEmployeeIds,
    departmentIds,
    deleted,
    lastRefreshTime,
    lastActiveTime,
    isNewGraduate,
    lineUserId,
    memo,
    deletedAt,
    isFollowedLineOfficialAccount,
    recruitmentStatus,
    selectedAuthenticationType,
    currentAuthenticationType,
    spaceId,
    lang,
  }: ExcludeMethods<Employee>) {
    this.id = id;
    this.tenantId = tenantId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.invitationToken = invitationToken;
    this.onboardingStatus = onboardingStatus;
    this.onboardingStatuses = onboardingStatuses;
    this.accountCreateAt = accountCreateAt;
    this.joinAt = joinAt;
    this.role = role;
    this.assignedAsNewcomer = assignedAsNewcomer;
    this.uid = uid;
    this.slackUserId = slackUserId;
    this.profileIconImageUrl = profileIconImageUrl;
    this.profile = profile;
    this.mentorUserId = mentorUserId;
    this.supportMemberEmployeeIds = supportMemberEmployeeIds;
    this.departmentIds = departmentIds;
    this.profile = profile;
    this.deleted = deleted;
    this.invitedAt = typeof invitedAt === "string" ? new Date(invitedAt) : invitedAt;
    this.lastInvitedAt =
      typeof lastInvitedAt === "string" ? new Date(lastInvitedAt) : lastInvitedAt;
    this.lastRefreshTime =
      typeof lastRefreshTime === "string" ? new Date(lastRefreshTime) : lastRefreshTime;
    this.lastActiveTime =
      typeof lastActiveTime === "string" ? new Date(lastActiveTime) : lastActiveTime;
    this.isNewGraduate = isNewGraduate;
    this.lineUserId = lineUserId;
    this.deletedAt = typeof deletedAt === "string" ? new Date(deletedAt) : deletedAt;
    this.memo = memo;
    this.isFollowedLineOfficialAccount = isFollowedLineOfficialAccount;
    this.recruitmentStatus = recruitmentStatus;
    this.selectedAuthenticationType = selectedAuthenticationType;
    this.currentAuthenticationType = currentAuthenticationType;
    this.spaceId = spaceId;
    this.lang = lang;
  }

  public isMember(): boolean {
    return this.role === Role.MEMBER;
  }

  public isManager(): boolean {
    return this.role === Role.MANAGER;
  }

  public isDepartmentAdmin(): boolean {
    return this.role === Role.DEPARTMENT_ADMIN;
  }

  public isAdmin(): boolean {
    return this.role === Role.ADMIN;
  }

  public isNewcomer(): boolean {
    return this.assignedAsNewcomer;
  }

  public hasMentor(): boolean {
    return Boolean(this.mentorUserId);
  }

  public getName(): string {
    return `${this.deleted ? "【削除済み】" : ""}${this.lastName} ${this.firstName}`;
  }

  public getDisplayRole(): string {
    return displayRoleMap[this.role];
  }

  public validateCanRegister() {
    if (this.deleted) {
      throw new Error("削除済みのユーザーです");
    }
    if (this.isRegistered()) {
      throw new Error("既に登録されています");
    }
  }

  isNewGraduateEmployee(): this is NewGraduate {
    return !!this.isNewGraduate;
  }

  doesBelongToCommonDepartment(targetEmployee: Employee): boolean {
    return this.departmentIds.some((departmentId) =>
      targetEmployee.departmentIds.includes(departmentId)
    );
  }

  /**
   * NOTE: 招待メール送信済みの場合は招待日時がある
   * - 共通QRコードを使ってアカウントを生成した場合には招待日時が存在しない登録済みEmployeeが存在することになる
   */
  hasInvitedAt(): this is NonNullable<Employee["invitedAt"]> {
    return !!this.invitedAt;
  }

  /**
   * 一度でもログインしたことがあるかを返す
   */
  isEverLogined(): this is NonNullable<Employee["lastRefreshTime"]> {
    return !!this.lastRefreshTime;
  }

  /**
   * アカウントが登録済みかどうかを返す
   * @returns boolean
   */
  isRegistered(): this is Employee & { uid: string } {
    return !!this.uid;
  }

  /**
   * アカウントに通知を送るかどうかを返す
   * @returns boolean
   */
  isNotifiable(): boolean {
    if (this.deleted) return false;

    // NOTE: 未招待の入社者に通知を送らない
    if (!this.isRegistered() && !this.hasInvitedAt()) return false;

    return true;
  }

  /**
   * ポータルなど、Onnアプリケーションの機能を利用可能かどうかを返す
   * 条件に当てはまらなくても、LINEなどを用いたコンタクト機能などは利用可能な場合があるため注意が必要
   * @returns boolean
   */
  canUseOnn(): boolean {
    const isRejectedOrWithdrew = ["rejected", "withdrew"].includes(this.recruitmentStatus ?? "");

    return this.isRegistered() && !this.deleted && !isRejectedOrWithdrew;
  }

  isPreEntry(): this is Employee & { recruitmentStatus: "pre_entry" } {
    return this.recruitmentStatus === "pre_entry";
  }

  /**
   * LINE通知が可能かどうかを返す
   * NOTE: LINE通知が可能であってもDBに保存されていない場合は通知されない
   * @returns boolean
   */
  public canNotifyWithLine(): this is EmployeeCanNotifyWithLine {
    return !!this.lineUserId && !!this.isFollowedLineOfficialAccount;
  }

  /**
   * バディとサポートメンバーのemployee.idの配列を取得する
   */
  public getMentorAndSupportMemberIds(): string[] {
    return [
      this.mentorUserId,
      ...(this.supportMemberEmployeeIds ? this.supportMemberEmployeeIds : []),
    ].filter((v): v is NonNullable<typeof v> => Boolean(v));
  }

  public isPreboarding(): boolean {
    const joinedStatuses = [
      OnboardingStatus.JOINED,
      OnboardingStatus.ANSWERED,
      OnboardingStatus.ONBOARDING_COMPLETED,
    ];

    // 入社日が未設定の場合は常にpreboardingとする
    if (!this.joinAt) {
      return true;
    }

    // onboardingStatusesとjoinedStatusesに重複がなければtrue
    return !this.onboardingStatuses?.some((s) => joinedStatuses.includes(s));
  }

  public register(uid: string, currentAuthenticationType: AuthenticationType) {
    this.uid = uid;

    this.currentAuthenticationType = currentAuthenticationType;

    // NOTE: ロールに関係なくonboardingStatusesの更新を一応残している
    // TODO: ステータスが見直され frontで扱っているextractLatestStatusが削除されるタイミングでこの処理も削除する。
    this.onboardingStatus = OnboardingStatus.LOGGED_IN;
    const isNotIncludeLoggedIn = !this.onboardingStatuses?.includes(OnboardingStatus.LOGGED_IN);
    if (isNotIncludeLoggedIn) {
      this.onboardingStatuses?.push(OnboardingStatus.LOGGED_IN);
    }
  }

  /**
   * 新卒の通知で使う遷移先のリンクを生成するロジック
   */
  public generateNotificationLinkAboutNewGraduate(link: string, lineLiffId?: string): string {
    const url = new URL(link);

    // 登録されていない場合はアカウント登録画面へのリンクを返す
    if (!this.isRegistered()) {
      url.searchParams.set("dest-path", url.pathname);
      url.pathname = `/account/${this.invitationToken}`;
    }

    if (this.currentAuthenticationType !== "line" || !lineLiffId) {
      return url.toString();
    }

    const liffUrl = new URL(`/${lineLiffId}${url.pathname}${url.search}`, `https://liff.line.me`);
    return liffUrl.toString();
  }

  /**
   * 既に招待されたことがある場合、最終招待日時のみ更新する
   */
  public invite() {
    if (this.hasInvitedAt()) {
      return new Employee({ ...this, lastInvitedAt: new Date() });
    } else {
      return new Employee({ ...this, invitedAt: new Date(), lastInvitedAt: new Date() });
    }
  }

  public updateRecruitmentStatus(recruitmentStatus: RecruitmentStatus) {
    return new Employee({ ...this, recruitmentStatus });
  }

  public getProfileIconImageType(): iconType {
    if (!this.profileIconImageUrl) {
      return "NONE";
    } else if (isValidUrl(this.profileIconImageUrl)) {
      return "URL";
    } else {
      return "FIRE_STORAGE";
    }
  }

  /**
   * 中途入社者を生成するインスタンスメソッド
   * @returns {Employee}
   */
  public static createMidCarrierEmployee({
    firstName,
    lastName,
    email,
    joinAt,
    departmentIds,
    tenantId,
    selectedAuthenticationType,
    currentAuthenticationType,
  }: {
    firstName?: string;
    lastName?: string;
    email: string;
    joinAt?: string;
    departmentIds: string[];
    tenantId: string;
    selectedAuthenticationType: selectedAuthenticationType;
    currentAuthenticationType?: AuthenticationType;
  }): Employee {
    return new Employee({
      id: v4(),
      email: email,
      tenantId: tenantId,
      onboardingStatus: OnboardingStatus.INVITED,
      onboardingStatuses: [OnboardingStatus.INVITED],
      firstName: firstName || "",
      lastName: lastName || email,
      invitedAt: undefined,
      invitationToken: v4(),
      joinAt: joinAt,
      role: Role.MEMBER,
      assignedAsNewcomer: true,
      mentorUserId: "",
      supportMemberEmployeeIds: [],
      slackUserId: "",
      profile: new Profile({
        birthMonth: "",
        birthDate: "",
        tags: "",
        career: "",
        free: "",
        nickname: "",
      }),
      accountCreateAt: format(new Date(), "yyyy-MM-dd"),
      uid: "",
      memo: "",
      departmentIds: departmentIds,
      isNewGraduate: false,
      deleted: false,
      currentAuthenticationType,
      selectedAuthenticationType,
      lang: LangType.JA, // 現時点では `ja` に固定して、必要なユーザーのみ手動で `en` にしている
    });
  }

  public static createNewGraduateEmployee({
    firstName,
    lastName,
    email,
    joinAt,
    departmentIds,
    tenantId,
    isFollowedLineOfficialAccount,
    recruitmentStatus,
    selectedAuthenticationType,
    currentAuthenticationType,
    spaceId,
  }: {
    firstName?: string;
    lastName?: string;
    email: string;
    joinAt?: string;
    departmentIds: string[];
    tenantId: string;
    isFollowedLineOfficialAccount?: boolean;
    recruitmentStatus: NewGraduate["recruitmentStatus"];
    selectedAuthenticationType: selectedAuthenticationType;
    currentAuthenticationType?: AuthenticationType;
    spaceId: string;
  }): NewGraduate {
    return new Employee({
      id: v4(),
      email: email,
      tenantId: tenantId,
      onboardingStatus: OnboardingStatus.STANDBY,
      onboardingStatuses: [OnboardingStatus.STANDBY],
      firstName: firstName || "",
      lastName: lastName || email,
      invitedAt: undefined,
      invitationToken: v4(),
      joinAt,
      role: Role.MEMBER,
      assignedAsNewcomer: true,
      mentorUserId: "",
      supportMemberEmployeeIds: [],
      profileIconImageUrl: undefined,
      slackUserId: "",
      profile: undefined,
      accountCreateAt: format(new Date(), "yyyy-MM-dd"),
      uid: undefined,
      memo: "",
      departmentIds: departmentIds,
      isNewGraduate: true,
      isFollowedLineOfficialAccount,
      recruitmentStatus,
      deleted: false,
      selectedAuthenticationType,
      currentAuthenticationType,
      spaceId,
      lang: LangType.JA, // 現時点では `ja` に固定して、必要なユーザーのみ手動で `en` にしている
    }) as NewGraduate;
  }

  /**
   * 受け入れメンバーを作成する
   * @returns Employee
   */
  public static createAcceptanceMember({
    ...params
  }: {
    tenantId: string;
    email: string;
    lastName: string;
    profileIconImageUrl?: string;
    slackUserId?: string;
    role: Employee["role"];
    departmentIds: string[];
    selectedAuthenticationType: selectedAuthenticationType;
    currentAuthenticationType?: AuthenticationType;
  }): Employee {
    return new Employee({
      id: v4(),
      tenantId: params.tenantId,
      email: params.email,
      firstName: "",
      lastName: params.lastName,
      onboardingStatus: OnboardingStatus.INVITED,
      onboardingStatuses: [OnboardingStatus.INVITED],
      invitedAt: new Date(),
      invitationToken: v4(),
      joinAt: undefined,
      profile: undefined,
      role: params.role,
      assignedAsNewcomer: false,
      profileIconImageUrl: params.profileIconImageUrl,
      slackUserId: params.slackUserId,
      accountCreateAt: format(new Date(), "yyyy-MM-dd"),
      uid: "",
      departmentIds: params.departmentIds,
      deleted: false,
      selectedAuthenticationType: params.selectedAuthenticationType,
      currentAuthenticationType: params.currentAuthenticationType,
      lang: LangType.JA, // 現時点では `ja` に固定して、必要なユーザーのみ手動で `en` にしている
    });
  }

  static plainToInstance(init: ExcludeMethods<Employee>): Employee {
    return new Employee({ ...init, profile: init.profile && new Profile(init.profile) });
  }
}

export type EmployeeCanNotifyWithLine = Employee & {
  lineUserId: string;
  isFollowedLineOfficialAccount: true;
};
