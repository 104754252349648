import { Box } from "@material-ui/core";
import { OnboardingExperience } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

// 小文字のonboardingExperienceと競合してインポートできないため ~/domain/OnboardingExperience/OnboardingExperience からインポートしている
// TODO: onboardingExperience.ts をリネームしたタイミングで ~/domain/OnboardingExperience に書き換える

import { Chip, Divider, Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  onboardingExperiences: OnboardingExperience[];
  inviteEmails: string[];
  selectedIds: string[];
};

export const ConfirmStep: FC<Props> = ({ onboardingExperiences, inviteEmails, selectedIds }) => {
  return (
    <>
      <Box>
        <Typography variant="body1" color="textPrimary">
          内容を確認して入社者へ招待を送信してください。
        </Typography>
        <StyledBox p="16px" mt="32px" display="flex" flexDirection="column">
          <Box display="flex" gridGap="8px" flexWrap="wrap">
            {inviteEmails.map((inviteEmail, index) => {
              return <Chip key={index} label={inviteEmail} isOutlined color="muted" />;
            })}
          </Box>
          {selectedIds.length > 0 && <Divider orientation="horizontal" margin={24} />}
          {onboardingExperiences
            .filter((v) => selectedIds.includes(v.id))
            .map((onboardingExperience) => {
              return (
                <StyledFormControlLabel
                  key={onboardingExperience.id}
                  control={
                    <Checkbox
                      checked={selectedIds.includes(onboardingExperience.id)}
                      disableEffect
                    />
                  }
                  label={
                    <Typography variant="body2" color="textPrimary" noWrap>
                      {onboardingExperience.title}
                    </Typography>
                  }
                  disableEffect
                />
              );
            })}
        </StyledBox>
      </Box>
      <Box mt="32px" textAlign="center" display="flex" flexDirection="column">
        <Typography variant="caption" color="textPrimary">
          送信を押すと、すぐに招待メールが送られます。
        </Typography>
        <Typography variant="caption" color="textPrimary">
          選択したエクスペリエンスは後から変更することはできません。
        </Typography>
      </Box>
    </>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
