import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { UpdateDefaultFollowerIdsUseCase } from "~/service/usecases/tenantSettings";
import { captureException } from "~/util";

const updateDefaultFollowerIdsUseCase = new UpdateDefaultFollowerIdsUseCase();

/**
 * defaultFollowerIdsを更新するための関数を提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns updateDefaultFollowerIds
 */
export const useUpdateDefaultFollowerIds = (): {
  updateDefaultFollowerIds: (
    tenantId: string,
    newFollowerIds: string[],
    emailsWithoutOnnAccount: string[]
  ) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * defaultFollowerIdsを更新するための関数
   * @param tenantId
   * @param newFollowerIds
   * @param selectedSlackUsersWithoutOnnAccount
   */
  const updateDefaultFollowerIds = useCallback(
    async (tenantId: string, newFollowerIds: string[], emailsWithoutOnnAccount: string[]) => {
      return await updateDefaultFollowerIdsUseCase
        .execute(tenantId, newFollowerIds, emailsWithoutOnnAccount)
        .then(() => {
          enqueueSnackbar("フォロワーを更新しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useUpdateDefaultFollowerIds:updateDefaultFollowerIds",
            },
          });
        });
    },
    [enqueueSnackbar]
  );

  return {
    updateDefaultFollowerIds,
  };
};
