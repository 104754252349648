import { createIMockSurveyTransaction } from "@onn/common";
import React, { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { NotFound } from "../../NotFound";

import { AnswerMain } from "~/components/domains/transactions";
import { Loading } from "~/components/uiParts";
import { useAdmins } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSurveyTemplate } from "~/hooks/templates";

export const PreviewSurveysNew: FC = () => {
  const { currentUser } = useCurrentUser();
  const { id: surveyTemplateId } = useParams<"id">();

  const { data: surveyTemplate, isValidating: isValidatingSurvey } = useSurveyTemplate(
    currentUser.tenantId,
    surveyTemplateId
  );

  const { data: admins = [], isValidating: isValidatingAdmins } = useAdmins(currentUser.tenantId);

  const navigate = useNavigate();
  const surveyTransaction = surveyTemplate && createIMockSurveyTransaction(surveyTemplate);

  const handleSubmit = useCallback(async () => {
    navigate("/preview/thanks");
  }, [navigate]);

  if (!surveyTemplateId) {
    return <NotFound />;
  }

  if (isValidatingAdmins || isValidatingSurvey) {
    return <Loading size="large" />;
  }

  if (!surveyTemplate || !surveyTransaction) {
    return <NotFound />;
  }

  return (
    <AnswerMain
      survey={surveyTemplate}
      answerItems={[]}
      admins={admins}
      // アンケートのプレビュー画面のため空で渡す
      supportMembers={[]}
      onSubmit={() => handleSubmit()}
    />
  );
};
