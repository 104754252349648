import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "~/hooks/shared";
import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";

export const useSigninToTargetTenant = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { updateCurrentTenantId } = useCurrentTenantId();

  const signinToTargetTenant = useCallback(
    (tenantId: string) => {
      if (tenantId) updateCurrentTenantId(tenantId);

      const destPath = query.get("dest-path") || "/";
      navigate(destPath);
    },
    [query, navigate, updateCurrentTenantId]
  );

  return { signinToTargetTenant };
};
