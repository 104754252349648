import { Employee } from "../../Employee";
import { OnboardingExperienceGeneralTask } from "../OnboardingExperienceGeneralTask/OnboardingExperienceGeneralTask";
import { OnboardingExperienceMessageTask } from "../OnboardingExperienceMessageTask";
import { OnboardingExperienceSimpleTask } from "../OnboardingExperienceSimpleTask/OnboardingExperienceSimpleTask";

export type OnboardingTaskWithNewHire = OnboardingExperienceTaskType & {
  newHire?: Employee;
};

type OnboardingTaskInitType =
  | Optional<ConstructorParameters<typeof OnboardingExperienceGeneralTask>[0], "id">
  | Optional<ConstructorParameters<typeof OnboardingExperienceMessageTask>[0], "id">
  | Optional<ConstructorParameters<typeof OnboardingExperienceSimpleTask>[0], "id">;

export type OnboardingExperienceTaskType =
  | OnboardingExperienceGeneralTask
  | OnboardingExperienceMessageTask
  | OnboardingExperienceSimpleTask;

export class OnboardingExperienceTaskFactory {
  public static createOnboardingTask(init: OnboardingTaskInitType): OnboardingExperienceTaskType {
    switch (init.type) {
      case "GENERAL_TASK": {
        return OnboardingExperienceGeneralTask.create(init);
      }
      case "MESSAGE_TASK": {
        return OnboardingExperienceMessageTask.create(init);
      }
      case "SIMPLE_TASK": {
        return OnboardingExperienceSimpleTask.create(init);
      }
    }
  }
}
