import { Box } from "@material-ui/core";
import { TenantSettings, HOUR_FOR_SURVEY } from "@onn/common";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { SettingsWrapper } from "../SettingsWrapper";

import { Icon, SelectForm, Typography } from "~/components/uiParts";

type Props = {
  newcomerIntroduction: TenantSettings["newcomerIntroduction"];
  editing: boolean;
  updating: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (newObject: TenantSettings["newcomerIntroduction"]) => void;
};

export const SettingsOptions = {
  SEND_WEEKDAY: "sendWeekday", // 何曜日に配信するか
  SEND_Hour: "sendHour", // 何時に配信するか
} as const;
type SettingsOptions = (typeof SettingsOptions)[keyof typeof SettingsOptions];

export const NewcomerIntroductionSettings: FC<Props> = ({
  newcomerIntroduction,
  editing,
  onSubmit,
  ...rest
}) => {
  const [newNewcomerIntroductionSettings, setNewcomerIntroductionSettings] =
    useState<TenantSettings["newcomerIntroduction"]>(newcomerIntroduction);

  useEffect(() => {
    setNewcomerIntroductionSettings(newcomerIntroduction);
  }, [editing, newcomerIntroduction]);

  const handleChangeSettings = (newObject: Partial<TenantSettings["newcomerIntroduction"]>) => {
    setNewcomerIntroductionSettings((prev) => ({ ...prev, ...newObject }));
  };

  const handleSubmitButton = () => {
    onSubmit(newNewcomerIntroductionSettings);
  };

  return (
    <SettingsWrapper {...rest} editing={editing} onSubmit={handleSubmitButton}>
      {editing ? (
        <>
          <Typography variant="caption">配信時間(単一選択)</Typography>
          <Box height="16px" />
          <StyledSelectForm
            //defaultは9時
            selected={newNewcomerIntroductionSettings.deliveryTime}
            onChange={(e) => handleChangeSettings({ deliveryTime: Number(e.target.value) })}
            menuItems={HOUR_FOR_SURVEY}
          />
        </>
      ) : (
        <StyledDiv>
          <StyledIcon icon="check" size="sm" color="primary" />
          <Typography variant="caption" display="block">
            配信時間：
            {
              HOUR_FOR_SURVEY.find((v) => v.value === newNewcomerIntroductionSettings.deliveryTime)
                ?.name
            }
          </Typography>
        </StyledDiv>
      )}
    </SettingsWrapper>
  );
};

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelectForm = styled(SelectForm)`
  width: 256px;
`;
