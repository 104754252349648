import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { EmojiButtonGroup } from "./EmojiButtonGroup";

import { Button, TextareaAutosize, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onSubmit: (reactionComment: string) => Promise<void>;
  onCancel: () => void;
};

export const SendReactionModal: FC<Props> = ({ open, onSubmit, onCancel }) => {
  const [reactionComment, setReactionComment] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmojiSubmit = useCallback(
    async (emoji: string) => {
      setLoading(true);

      await onSubmit(emoji).finally(() => setLoading(false));
    },
    [onSubmit]
  );

  const handleCommentSubmit = useCallback(async () => {
    setLoading(true);

    await onSubmit(reactionComment).finally(() => setLoading(false));
    onCancel();
  }, [onSubmit, reactionComment, onCancel]);

  return (
    <Modal
      open={open}
      title="リアクション送信"
      onCancel={onCancel}
      content={
        <Box textAlign="center">
          <Typography align="left" variant="body1">
            絵文字でリアクションを送信して、アンケートを見たことを伝えましょう
          </Typography>
          <Box mt="16px" mb="40px">
            <EmojiButtonGroup onClick={handleEmojiSubmit} />
          </Box>
          <Typography align="left" variant="body1" gutterBottom>
            アンケートを見た感想や、1on1で話したいことの頭出しなどを入社者に伝えてみましょう
          </Typography>
          <Box my="8px">
            <TextareaAutosize
              fullWidth
              value={reactionComment}
              onChange={(event) => setReactionComment(event.target.value)}
              rows={3}
              placeholder={`〇〇さんアンケート回答ありがとうございます！\n次の1on1で書いてくれた大変だったところを相談しましょう！`}
            />
          </Box>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleCommentSubmit}
            disabled={!reactionComment.trim() || loading}
          >
            送信
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
