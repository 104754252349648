import { Box } from "@material-ui/core";
import { Employee, OnboardingTaskType } from "@onn/common";
import React, { FC } from "react";

import { OnboardingTaskStatusChip } from "~/components/domains/onboardingTask";
import { Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  newHire: Employee;
  onboardingTask: OnboardingTaskType;
};

// ====================
// main
// ====================

export const StatusSection: FC<Props> = ({ newHire, onboardingTask }) => {
  // ====================
  // main component
  // ====================

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="caption" bold color="textPrimary">
        ステータス
      </Typography>
      <Box>
        <OnboardingTaskStatusChip newHire={newHire} onboardingTask={onboardingTask} />
      </Box>
    </Box>
  );
};
