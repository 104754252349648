import { Employee, OnboardingExperienceTaskTemplate } from "@onn/common";

import { factory } from "~/service/repository/iOnboardingExperienceTaskTemplateRepository";

const onboardingExperienceTaskTemplateRepository =
  factory.onboardingExperienceTaskTemplateRepository();

/**
 * タスクテンプレートを返すUseCase
 */
export class FindOnboardingExperienceTaskTemplateUseCase {
  /**
   * タスクテンプレートを返すUseCase
   * @param {string} id
   * @param {Employee} currentUser
   * @returns OnboardingExperienceTaskTemplate
   */
  async execute(id: string, currentUser: Employee): Promise<OnboardingExperienceTaskTemplate> {
    const result = await onboardingExperienceTaskTemplateRepository.findById(id);
    if (result.tenantId !== currentUser.tenantId) {
      throw new Error("このテンプレートの閲覧権限がありません。");
    }

    return result;
  }
}
