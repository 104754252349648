import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import {
  Typography,
  Modal,
  // Divider,
  Icon,
} from "~/components/uiParts";
import { IconPanel } from "~/components/uiParts/IconPanel";

type Props = {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const SelectTemplateTypeModal: FC<Props> = ({ open, onSubmit, onCancel }) => {
  const handleSubmit = () => {
    // モーダルが閉じないまま画面遷移してしまうため、onCancelでモーダルを閉じる
    onCancel();
    onSubmit();
  };

  return (
    <Modal
      open={open}
      title="テンプレートタイプ選択"
      content={
        <>
          {/* <StyledBox onClick={handleSubmit}>
            <IconPanel icon="survey" />
            <Box ml={2}>
              <Typography color="textSecondary">カスタムアンケート</Typography>
              <StyledTypography variant="body2">
                入社者やバディの定期的な状態把握に適したテンプレート
              </StyledTypography>
              <Box height="4px" />
              <Box>
                <StyledTag variant="caption">定期サーベイ・配信</StyledTag>
                <StyledTag variant="caption">回答へのリアクション</StyledTag>
                <StyledTag variant="caption">結果集計</StyledTag>
              </Box>
            </Box>
            <StyledIcon icon="arrowRight" color="primary" size="sm" />
          </StyledBox>
          <Divider />
          <StyledBox onClick={handleSubmit}>
            <IconPanel icon="list" />
            <Box ml={2}>
              <Typography color="textSecondary">回答フォーム</Typography>
              <StyledTypography variant="body2">
                インスタントな情報回収に適したテンプレート
              </StyledTypography>
              <Box height="4px" />
              <Box>
                <StyledTag variant="caption">一回限りの回答</StyledTag>
                <StyledTag variant="caption">結果集計</StyledTag>
              </Box>
            </Box>
            <StyledIcon icon="arrowRight" color="primary" size="sm" />
          </StyledBox>
          <Divider /> */}
          <StyledBox onClick={handleSubmit}>
            <IconPanel icon="speechBalloon" />
            <Box ml={2}>
              <Typography color="textSecondary">メンバーメッセージ（寄せ書き）</Typography>
              <StyledTypography variant="body2">
                節目のタイミングでメンバーからメッセージを集め配信
              </StyledTypography>
              <Box height="4px" />
              <Box>
                <StyledTag variant="caption">メッセージ収集</StyledTag>
                <StyledTag variant="caption">自動配信</StyledTag>
              </Box>
            </Box>
            <StyledIcon icon="arrowRight" color="primary" size="sm" />
          </StyledBox>
        </>
      }
      onCancel={onCancel}
    />
  );
};

const StyledBox = styled(Box)`
  display: flex;
  margin: 8px 0;
  padding: 8px 8px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.grey[50]};
    border-radius: 8px;
    > svg {
      opacity: 1;
    }
  }
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;

const StyledTag = styled(Typography)`
  padding: 4px 8px;
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 16px;
  &.MuiTypography-root {
    margin-right: 4px;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 4px 0 0 auto;
  opacity: 0;
`;
