import { SurveyAlert } from "@onn/common";

import { SurveyAlertRepository } from "~/infrastructure/api/surveyAlertRepository";

export interface ISurveyAlertRepository {
  create(surveyAlert: SurveyAlert): Promise<void>;
  update(newSurveyAlert: SurveyAlert): Promise<void>;
  whereByIsCompleted(tenantId: string, isCompleted: boolean): Promise<SurveyAlert[]>;
}

export const factory = {
  surveyAlertRepository: (): ISurveyAlertRepository => {
    return new SurveyAlertRepository();
  },
};
