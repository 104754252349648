import { OnboardingTaskType, OnboardingTaskFactory, DifferenceDate } from "@onn/common";
import { useEffect, useMemo, useState } from "react";

import { useOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask";
import { useLocalStorage, useSnackbar } from "~/hooks/shared";

/**
 * トップページで AssigneeRole が NEW_HIRE の onboardingTasks を返す hooks
 * 1. プレビューの場合: ローカルストレージから取得した onboardingTasks を返す
 * 2. プレビューではない場合: onboardingTasks をapiから取得して返す
 *
 * @param currentUserId
 * @param tenantId
 * @param isPreview
 *
 * @return {
 *   onboardingTasks: OnboardingSimpleTask[];
 *   isLoading: boolean
 * }
 */
export const useGetNewHireOnboardingTasks = (
  currentUserId: string,
  tenantId: string,
  isPreview: boolean
): { onboardingTasks: OnboardingTaskType[]; isLoading: boolean } => {
  const { data: onboardingTasks, isLoading: isLoadingOnboardingTasks } =
    useOnboardingTasksByEmployeeId(currentUserId);
  const newHireOnboardingTasks = useMemo(
    () => (onboardingTasks ? onboardingTasks.filter((v) => v.assigneeRole === "NEW_HIRE") : []),
    [onboardingTasks]
  );

  const {
    newHireOnboardingTasks: newHireOnboardingTasksForPreview,
    isLoading: isLoadingOnboardingTasksForPreview,
  } = useGetNewHireOnboardingTasksForPreview(currentUserId, tenantId, isPreview);

  return {
    onboardingTasks: isPreview ? newHireOnboardingTasksForPreview : newHireOnboardingTasks,
    isLoading: isPreview ? isLoadingOnboardingTasksForPreview : isLoadingOnboardingTasks,
  };
};

const useGetNewHireOnboardingTasksForPreview = (
  currentUserId: string,
  tenantId: string,
  isPreview: boolean
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { retrieveValue } = useLocalStorage();
  const [isLoading, setIsLoading] = useState(true);
  const [newHireOnboardingTasks, setNewHireOnboardingTasks] = useState<OnboardingTaskType[]>([]);

  useEffect(() => {
    const setLocalStorageTasks = () => {
      if (!isPreview) {
        setIsLoading(false);
        return [];
      }

      const localStorageTasks =
        retrieveValue<OnboardingTaskType[]>("OnboardingTaskForPreview", {
          func: (tasks) => {
            return tasks.map((task) =>
              OnboardingTaskFactory.createOnboardingTask({
                ...task,
                dueDate: new DifferenceDate(task.dueDate),
              })
            );
          },
          onError: () =>
            enqueueSnackbar("タスクの取得に失敗しました", {
              variant: "error",
            }),
          errorTypeName:
            "useGetNewHireOnboardingTasks.convertToOnboardingSimpleTaskFromExperienceSimpleTask",
        }) || [];

      setNewHireOnboardingTasks(localStorageTasks);

      setIsLoading(false);
    };

    setLocalStorageTasks();
  }, [retrieveValue, currentUserId, tenantId, enqueueSnackbar, isPreview]);

  return {
    newHireOnboardingTasks,
    isLoading,
  };
};
