import React, { useContext, type ReactNode } from "react";

import { AuthorizationContext } from "./AuthorizationProvider";

type Props = {
  children: ReactNode;
};

export const AuthorizationGuard = ({ children }: Props) => {
  const {
    authorizedUsers,
    portalAccessibleUsers,
    guardAuthorizedUsers,
    guardPortalAccessibleUsers,
  } = useContext(AuthorizationContext);

  if (
    !guardAuthorizedUsers(authorizedUsers) ||
    !guardPortalAccessibleUsers(portalAccessibleUsers)
  ) {
    return <></>;
  }

  return <>{children}</>;
};
