import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { SurveyResult } from "~/components/domains/transactions";
import { Typography, Paper, Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSurveyTransactionsByEmployeeId } from "~/hooks/transaction";
import { NotFound } from "~/pages/NotFound";

export const SurveyDetail: FC = () => {
  const { currentUser } = useCurrentUser();
  const { id: surveyTransactionId } = useParams<"id">();
  const { data: surveyTransactions = [], isLoading: isLoadingSurveyTransaction } =
    useSurveyTransactionsByEmployeeId(currentUser.id);
  const surveyTransaction = surveyTransactions?.find(
    (transaction) => transaction.id === surveyTransactionId
  );

  if (isLoadingSurveyTransaction) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  if (!surveyTransaction) return <NotFound />;

  return (
    <Box pt="40px" px="24px" pb="126px">
      <Typography variant="h2" color="textPrimary">
        {surveyTransaction.contents.title}
      </Typography>
      <Box height={40} />
      <Paper>
        <SurveyResult
          surveyTransaction={surveyTransaction}
          currentUser={currentUser}
          newHire={currentUser}
          surveyTransactions={surveyTransactions}
          isShowAdminOnly
        />
      </Paper>
    </Box>
  );
};
