import { Employee, DifferenceDate, displayAssigneeRoleMap, OnboardingTaskType } from "@onn/common";
import { useCallback } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { captureException } from "~/util";

const generateOnboardingSummary = (onboardingTask: OnboardingTaskType, newHire: Employee) => {
  return `\`\`\`タイトル：${onboardingTask.title}\n担当者${"  "}：${
    displayAssigneeRoleMap[onboardingTask.assigneeRole]
  }\n${
    onboardingTask?.deliveryDate
      ? `配信日${" "}：${onboardingTask.deliveryDate.getDisplayTextByEmployee(
          newHire,
          "yyyy/MM/dd"
        )}\n`
      : ""
  }期日${"   "}：${onboardingTask.dueDate.getDisplayTextByEmployee(
    newHire,
    "yyyy/MM/dd"
  )}\n入社者${"  "}：${newHire.getName()} さん (${newHire.tenantId})\`\`\``;
};

/**
 * operationLogの生成関数
 */
const operationLog = {
  updateGeneralTaskSettings: (currentUser: Employee): LogType => {
    return {
      type: "success",
      title: `<${
        location.origin
      }/tools#onboardingExperience|${currentUser.getName()} さんによって共通タスクの設定が更新されました>`,
      text: `テナント：${currentUser.tenantId}`,
      footer: {
        icon: "recycle",
        processName: "共通タスクの設定の更新",
      },
    };
  },
  updateOnboardingBuilder: (currentUser: Employee, onboardingExperienceId: string): LogType => {
    return {
      type: "success",
      title: `<${
        location.origin
      }/tools/onboarding_experiences/${onboardingExperienceId}|${currentUser.getName()} さんによってオンボーディングエクスペリエンスが更新されました>`,
      text: `テナント：${currentUser.tenantId}`,
      footer: {
        icon: "recycle",
        processName: "オンボーディングエクスペリエンスの更新",
      },
    };
  },
  createOnboardingTask: (
    currentUser: Employee,
    onboardingTask: OnboardingTaskType,
    newHire: Employee
  ): LogType => {
    return {
      type: "success",
      title: `<${location.origin}/employee/${
        newHire.id
      }#task|${currentUser.getName()} さんによってオンボーディングタスクが作成されました>`,
      text: `${generateOnboardingSummary(onboardingTask, newHire)}`,
      footer: {
        icon: "pencil",
        processName: "オンボーディングタスク作成",
      },
    };
  },
  completeOnboardingTasks: (currentUser: Employee, count: number): LogType => {
    return {
      type: "success",
      title: `${currentUser.getName()} さんによってオンボーディングタスクが${count}件完了されました`,
      text: "",
      footer: {
        icon: "white_check_mark",
        processName: "オンボーディングタスク一括完了",
      },
    };
  },
  deleteOnboardingTask: (
    currentUser: Employee,
    onboardingTask: OnboardingTaskType,
    newHire: Employee
  ): LogType => {
    return {
      type: "success",
      title: `<${location.origin}/employee/${
        newHire.id
      }#task|${currentUser.getName()} さんによってオンボーディングタスクが削除されました>`,
      text: `${generateOnboardingSummary(onboardingTask, newHire)}`,
      footer: {
        icon: "fire",
        processName: "オンボーディングタスク削除",
      },
    };
  },
  deleteOnboardingTasks: (currentUser: Employee, count: number): LogType => {
    return {
      type: "success",
      title: `${currentUser.getName()} さんによってオンボーディングタスクが${count}件削除されました`,
      text: "",
      footer: {
        icon: "fire",
        processName: "オンボーディングタスク一括削除",
      },
    };
  },
  generateOnboardingTaskFromExperience: (currentUser: Employee, newHires: Employee[]): LogType => {
    return {
      type: "success",
      title: `${currentUser.getName()} さんによってエクスペリエンスからオンボーディングタスクが生成されました`,
      text: `入社者：${newHires
        .map((newHire) => `<${location.origin}/employee/${newHire.id}#task|${newHire.getName()}>`)
        .join("、")}`,
      footer: {
        icon: "pencil",
        processName: "オンボーディングタスク生成",
      },
    };
  },
  updateOnboardingTaskDueDate: (
    currentUser: Employee,
    onboardingTask: OnboardingTaskType,
    newHire: Employee,
    dueDate: DifferenceDate
  ): LogType => {
    return {
      type: "success",
      title: `<${location.origin}/employee/${
        newHire.id
      }#task|${currentUser.getName()} さんによってオンボーディングタスクの期日が ${dueDate.getDisplayTextByEmployee(
        newHire,
        "yyyy/MM/dd"
      )} に更新されました>`,
      text: `${generateOnboardingSummary(onboardingTask, newHire)}`,
      footer: {
        icon: "recycle",
        processName: "オンボーディングタスクの期日更新",
      },
    };
  },
  updateOnboardingTaskStatus: (
    currentUser: Employee,
    onboardingTask: OnboardingTaskType,
    newHire: Employee,
    isCompleted: boolean
  ): LogType => {
    return {
      type: "success",
      title: `<${location.origin}/employee/${
        newHire.id
      }#task|${currentUser.getName()} さんによってオンボーディングタスクのステータスが ${
        isCompleted ? "完了" : "未着手"
      } に更新されました>`,
      text: `${generateOnboardingSummary(onboardingTask, newHire)}`,
      footer: {
        icon: "recycle",
        processName: "オンボーディングタスクのステータス更新",
      },
    };
  },
  notifyCSVUploadError: (currentUser: Employee, errorMessage: string): LogType => {
    return {
      type: "error",
      title: `【${
        currentUser.tenantId
      }】${currentUser.getName()}さんのアップロードしたCSVにエラーを検知しました。`,
      text: `\`\`\`${errorMessage}\`\`\``,
      footer: {
        icon: "warning",
        processName: "入社者のCSVアップロードエラー",
      },
    };
  },
};

export type LogType = {
  type: "success" | "error";
  title: string;
  text: string;
  footer: {
    icon: string;
    processName: string;
  };
};

/**
 * #Onn-logに対するオペレーションログを送信するhooks
 */
export const useNotifyOperationLog = () => {
  const notifyOperationLog = useCallback(async (log: LogType) => {
    try {
      await functionOperator.httpsCall("notifyOperationLog", log);
    } catch (error) {
      // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
      if (error instanceof Error) {
        captureException({ error, tags: { type: "fileAPIAdapter.fetchMetaDataByPaths" } });
      }
    }
  }, []);

  return { operationLog, notifyOperationLog };
};
