import { Menu as MenuUI, MenuItem } from "@material-ui/core";
import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";

import { IconButton, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import {
  CustomOnboardingExperienceTaskTemplate,
  useDeleteOnboardingExperienceTaskTemplate,
} from "~/hooks/onboardingExperienceTaskTemplate";

type Props = {
  template: CustomOnboardingExperienceTaskTemplate;
};

export const Menu: FC<Props> = React.memo(({ template }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { handleModal } = useModal();
  const { deleteOnboardingExperienceTaskTemplate } = useDeleteOnboardingExperienceTaskTemplate();

  const handleClickDeleteButton = useCallback(() => {
    handleModal({
      name: "deleteOnboardingTaskTemplateModal",
      args: {
        onSubmit: async () => deleteOnboardingExperienceTaskTemplate(template.id),
        template,
      },
    });
  }, [deleteOnboardingExperienceTaskTemplate, handleModal, template]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const menuItems = useMemo(
    () => (
      <>
        {!template.isDefault && (
          <MenuItem
            onClick={(e) => {
              handleClickDeleteButton();
              e.stopPropagation();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">テンプレート削除</Typography>
          </MenuItem>
        )}
      </>
    ),
    [handleClickDeleteButton, template]
  );

  return (
    <>
      <IconButton icon="menuVert" onClick={handleOpenMenu} />
      <MenuUI
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems}
      </MenuUI>
    </>
  );
});
