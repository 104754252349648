import { Menu, MenuItem, Box } from "@material-ui/core";
import {
  DEFAULT_MAX_FILE_SIZE_MB,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  Employee,
  ContactMessageDraft,
  ContactMessage,
  AllContactRoom,
  ContactRoom,
} from "@onn/common";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import React, { useState, FC, MouseEvent, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";

import { KeyedMutator } from "swr";

import { NewHireContactMessageItem } from "../../../contactMessage/contactMessageItem";

import { DetailMenu, UserIconWithLabel, ContactMessageTemplateMenu } from "../parts";
import { useContactMessageTemplatesMenuHandling } from "../parts/ContactMessageTemplateMenu/useContactMessageTemplatesMenuHandling";

import { useLatestAlreadyReadUsersInfoV2 } from "~/components/domains/contactRooms/hooks";
import {
  Button,
  Chip,
  Icon,
  Loading,
  Paper,
  TextareaAutosizeAttachableFile,
  Typography,
  UserIconWithLabel as CommonUserIconWithLabel,
  IconButton,
} from "~/components/uiParts";
import { SmallUserIconGroup } from "~/components/uiParts/SmallUserIconGroup";
import { useContactMessages, useOptimisticCreateMessage } from "~/hooks/contactMessage";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useUpdateAssigneeAndFollowers, useUpdateReadLogOfContactRoom } from "~/hooks/contactRoom";
import { useUpdateIsCompleted } from "~/hooks/contactRoom/useUpdateIsCompleted";
import { useDepartmentsByTenantId } from "~/hooks/department";
import { useEmployees, useCurrentUser, useAllNewcomers, useEmployee } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useDebouncedCallback } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { ContactMessageRepository } from "~/infrastructure/api/contactMessageRepository";

import { NotFound } from "~/pages/NotFound";
const contactMessageRepository = new ContactMessageRepository();

type Props = {
  contactRoomId: string;
  contactMessageDraft: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
};

export const NewHirePCContactRoomItem: FC<Props> = (props) => {
  const contactRoomId = props.contactRoomId;
  const { currentUser } = useCurrentUser();
  const {
    data: contactRoomAndMessages = { contactRoom: undefined, messages: [] },
    isLoading: isLoadingContactRoomAndMessages,
    mutate,
  } = useContactMessages({
    tenantId: currentUser.tenantId,
    contactRoomId,
  });

  const {
    contactRoomsWithoutCurrentUser: contactRooms,
    contactRoomsMutate,
    isLoadingContactRooms,
  } = useContactContext();

  // 対象のコンタクトルームのfirestore.contactRoomsの状態を監視し、変更があればmutateする
  useEffect(() => {
    const unsubscribeFunctions = contactMessageRepository.listenNewContactMessage(
      [contactRoomId],
      currentUser.tenantId,
      () => mutate()
    );
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoomId]);

  // getter, setterが抜けていないcontactRoomのインスタンスを作成
  const contactRoom = contactRooms.find((cr) => cr.id === contactRoomId);
  if (isLoadingContactRoomAndMessages || isLoadingContactRooms)
    return <Loading size={"large"} fullHeight />;

  if (!contactRoomAndMessages.contactRoom || !contactRoom) return <NotFound />;
  return (
    <PCContactRoomItem
      {...props}
      contactRoom={contactRoom}
      contactMessages={
        contactRoomAndMessages.messages as (ContactMessage & {
          creatorName: string;
          creatorImageUrl: string | undefined;
        })[]
      }
      contactRoomsMutate={contactRoomsMutate}
    />
  );
};

type PCContactRoomItemProps = {
  contactRoom: AllContactRoom;
  contactMessages: (ContactMessage & {
    creatorName: string;
    creatorImageUrl: string | undefined;
  })[];
  contactMessageDraft: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  contactRoomsMutate: KeyedMutator<AllContactRoom[]>;
};

const PCContactRoomItem: FC<PCContactRoomItemProps> = ({
  contactRoom,
  contactMessages,
  contactMessageDraft,
  saveContactMessageDraft,
  contactRoomsMutate,
}) => {
  const { currentUser } = useCurrentUser();
  const { tenantSettings } = useTenantSettings();

  const { postMessage, isSending, isFileSending, messageId } = useOptimisticCreateMessage({
    tenantId: currentUser.tenantId,
    contactRoomId: contactRoom.id,
  });

  const [newMessage, setNewMessage] = useState("");
  useEffect(() => {
    setNewMessage(contactMessageDraft.text);
  }, [contactMessageDraft]);

  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);

  const [anchorIsCompetedEl, setAnchorIsCompletedEl] = useState<HTMLElement | null>(null);
  const [messageListRef, setMessageListRef] = useState<HTMLDivElement>();

  const { data: followers = [] } = useEmployees(contactRoom.followerIds);
  const { data: assignee } = useEmployee(contactRoom.assigneeId);

  const { isLoading: isDataLoading2, data: allNewComers = [] } = useAllNewcomers();
  const { isLoading: isDataLoading3, data: departments = [] } = useDepartmentsByTenantId(
    currentUser.tenantId
  );

  const { data: newHire, isLoading: isLoading7 } = useEmployee(contactRoom.employeeId);

  const { updateAssigneeAndFollowers } = useUpdateAssigneeAndFollowers();
  const { updateIsCompleted } = useUpdateIsCompleted(async (_) => {
    await contactRoomsMutate();
  });
  const { handleModal } = useModal();

  const isDataLoading = isDataLoading2 || isDataLoading3 || isLoading7;

  const { updateReadLogOfContactRoom } = useUpdateReadLogOfContactRoom();

  const { latestAlreadyReadUsersInfo } = useLatestAlreadyReadUsersInfoV2(
    contactRoom,
    currentUser.id,
    [...followers, ...(assignee ? [assignee] : [])],
    contactMessages.filter((m) => m instanceof ContactMessage) as ContactMessage[]
  );

  const isRoomTargetEmployeeDeleted = contactRoom.employee && contactRoom.employee.deleted;
  useEffect(() => {
    const fn = async () => {
      await updateReadLogOfContactRoom(currentUser.id, contactRoom.id);
    };

    fn();
    // ページアクセス時のみの実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, contactRoom.id]);

  useEffect(() => {
    if (!messageListRef) {
      return;
    }

    messageListRef.scrollTop = messageListRef.scrollHeight;
    // messageに変更があったときに一番したまでスクロールする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListRef, contactMessages]);

  const executePostMessages = useCallback(async () => {
    if (contactMessageDraft) {
      saveContactMessageDraft(new ContactMessageDraft({ ...contactMessageDraft, text: "" }));
    }
    await postMessage({
      newMessageText: newMessage,
      newMessageFiles,
      employee: currentUser,
    });
    setNewMessageFiles([]);
  }, [
    contactMessageDraft,
    currentUser,
    newMessage,
    newMessageFiles,
    postMessage,
    saveContactMessageDraft,
  ]);

  const handleSubmit = useCallback(async () => {
    if (tenantSettings.features.contactInitialDisplay || !isEmpty(contactMessages)) {
      return executePostMessages();
    }
    handleModal({
      name: "confirmPostMessageModal",
      args: {
        onSubmit: async () => {
          return executePostMessages();
        },
      },
    });
  }, [
    contactMessages,
    executePostMessages,
    handleModal,
    tenantSettings.features.contactInitialDisplay,
  ]);

  const handleClickAddMember = useCallback(
    (followers: Employee[]) => {
      handleModal({
        name: "addFollowersModal",
        args: {
          newHire: contactRoom.employee,
          followers,
          onSubmit: async (followerIds: string[], followerEmailsWithoutOnnAccount: string[]) => {
            await updateAssigneeAndFollowers(
              contactRoom.id,
              contactRoom.assigneeId,
              followerIds,
              followerEmailsWithoutOnnAccount,
              undefined
            );
            await contactRoomsMutate();
          },
        },
      });
    },
    [
      contactRoom.assigneeId,
      contactRoom.employee,
      contactRoom.id,
      contactRoomsMutate,
      handleModal,
      updateAssigneeAndFollowers,
    ]
  );

  const handleClickChangeAssignee = useCallback(
    async (assignee?: Employee) => {
      handleModal({
        name: "changeAssigneeModal",
        args: {
          assignee,
          newHire: contactRoom.employee,
          onSubmit: async (assigneeEmailWithoutOnnAccount, newAssignee) => {
            // 担当者をフォロワーから選ぶ場合は、フォロワーから該当ユーザーを落とす
            const followerIds = newAssignee
              ? contactRoom.followerIds.filter((id) => id !== newAssignee.id)
              : contactRoom.followerIds;
            // すでに担当者がいた場合は、そのユーザーをフォロワーに追加する
            const newFollowerIds = [
              ...followerIds,
              ...(contactRoom.assigneeId ? [contactRoom.assigneeId] : []),
            ];

            await updateAssigneeAndFollowers(
              contactRoom.id,
              newAssignee?.id,
              newFollowerIds,
              [],
              assigneeEmailWithoutOnnAccount
            );
            await contactRoomsMutate();
          },
        },
      });
    },
    [
      handleModal,
      contactRoom.employee,
      contactRoom.followerIds,
      contactRoom.assigneeId,
      contactRoom.id,
      updateAssigneeAndFollowers,
      contactRoomsMutate,
    ]
  );

  const handleClickManageButton = useCallback(() => {
    handleModal({
      name: "manageContactTeamModal",
      args: {
        assignee,
        followers,
        onSubmit: async (followerIds: string[]) => {
          await updateAssigneeAndFollowers(contactRoom.id, contactRoom.assigneeId, followerIds, []);
        },
        onClickAddFollower: handleClickAddMember,
        onChangeAssignee: handleClickChangeAssignee,
      },
    });
  }, [
    handleModal,
    assignee,
    followers,
    handleClickAddMember,
    handleClickChangeAssignee,
    updateAssigneeAndFollowers,
    contactRoom.id,
    contactRoom.assigneeId,
  ]);

  const handleOpenIsCompletedMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorIsCompletedEl(event.currentTarget);
  }, []);

  const handleCloseIsCompletedMenu = useCallback(() => {
    setAnchorIsCompletedEl(null);
  }, []);

  const contactMessageTemplatesMenuHandling = useContactMessageTemplatesMenuHandling({
    contactMessageDraft,
    setNewMessage,
    saveContactMessageDraft,
    currentMessage: newMessage,
  });

  const messageListView = useMemo(() => {
    const messageItems = (
      <>
        {contactMessages.map((messageOrLog) => {
          const message: ContactMessage & {
            creatorName: string;
            creatorImageUrl: string | undefined;
          } = messageOrLog;

          const variant = message.isNewHireMessage ? "left" : "right";
          const isSendingItem = messageId === message.id;

          return (
            <NewHireContactMessageItem
              key={message.id}
              message={message}
              variant={variant}
              alreadyReadUsersInfo={latestAlreadyReadUsersInfo[message.id]}
              departments={departments}
              newHire={newHire}
              isFileSending={isSendingItem && isFileSending}
            />
          );
        })}
      </>
    );
    if (isRoomTargetEmployeeDeleted) {
      return (
        <>
          {messageItems}
          <StyledUnavailableMessagePaper elevation={0}>
            <Typography variant="body2">{`この入社者アカウントは削除されています。\nメッセージの送受信はできません。`}</Typography>
          </StyledUnavailableMessagePaper>
        </>
      );
    }

    if (contactRoom.isUnFollowByEmployee) {
      return (
        <>
          {messageItems}
          <StyledUnavailableMessagePaper elevation={0}>
            <Typography variant="body2">{`入社者にブロックされています。\nメッセージの送受信はできません。`}</Typography>
          </StyledUnavailableMessagePaper>
        </>
      );
    }

    if (isEmpty(contactMessages)) {
      return (
        <StyledPaper>
          <Typography variant="caption">
            {`入社前後の連絡や入社者のフォローアップなどのやり取りを行うことができます。\nメッセージを送信すると入社者のメールに通知され、やりとりが開始されます。`}
          </Typography>
        </StyledPaper>
      );
    }

    return messageItems;
  }, [
    contactMessages,
    isRoomTargetEmployeeDeleted,
    contactRoom.isUnFollowByEmployee,
    messageId,
    latestAlreadyReadUsersInfo,
    departments,
    newHire,
    isFileSending,
  ]);

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 500);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewMessage(e.target.value);
      handleDebounceCallback(() => {
        saveContactMessageDraft(
          new ContactMessageDraft({
            id: contactMessageDraft.id,
            contactRoomId: contactMessageDraft.contactRoomId,
            createdUserId: contactMessageDraft.createdUserId,
            text: e.target.value,
            tenantId: contactMessageDraft.tenantId,
          })
        );
      });
    },
    [contactMessageDraft, handleDebounceCallback, saveContactMessageDraft, setNewMessage]
  );

  if (isDataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <StyledHeaderBox
        display="flex"
        p="16px 40px"
        bgcolor="white"
        justifyContent="space-between"
        zIndex={1}
      >
        <UserIconWithLabel
          iconPath={contactRoom.employee?.profileIconImageUrl}
          userName={contactRoom.getRoomName()}
          to={
            // TODO: contactProvider取り外し後、CF側で算出させる
            // allNewComersに存在する入社者がアクセスできるページ
            allNewComers.some((v) => v.id === contactRoom.employeeId)
              ? `/employee/${contactRoom.employeeId}`
              : undefined
          }
          employee={newHire}
          departments={departments}
          // NOTE: 1対1(DM)のやり取りではTooltipを表示しないため、falseで固定する。
          shouldDisplayContactRoomButton={false}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gridGap={10}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gridGap={40}
          >
            {contactRoom.employee && (
              <Box ml="auto" display="flex" flexDirection="column" justifyContent="space-between">
                <StyledTypography variant="caption" color="textSecondary" noWrap>
                  入社日
                </StyledTypography>
                <Typography variant="caption" color="textPrimary" noWrap>
                  {contactRoom.employee.joinAt
                    ? format(new Date(contactRoom.employee.joinAt), "yyyy/MM/dd")
                    : "未設定"}
                </Typography>
              </Box>
            )}
            <StyledBox
              ml="auto"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              onClick={handleClickManageButton}
            >
              <StyledTypography variant="caption" color="textSecondary" noWrap>
                担当者
              </StyledTypography>
              <CommonUserIconWithLabel
                name={assignee?.getName() ?? "未登録"}
                iconCircular
                size="extraSmall"
                textSize="small"
                iconPath={assignee?.profileIconImageUrl}
              />
            </StyledBox>
            <StyledBox
              width={80}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              onClick={handleClickManageButton}
            >
              <StyledTypography variant="caption" color="textSecondary" noWrap>
                フォロワー
              </StyledTypography>
              {isEmpty(followers) ? (
                <Typography variant="caption" color="textPrimary">
                  未設定
                </Typography>
              ) : (
                <SmallUserIconGroup
                  userInfo={followers.map((a) => ({
                    username: a.getName(),
                    profileIconImageUrl: a.profileIconImageUrl,
                    borderColor: undefined,
                  }))}
                />
              )}
            </StyledBox>
            <Box>
              <StyledChip
                color={contactRoom.isCompleted ? "grey" : "secondary"}
                label={contactRoom.isCompleted ? "対応済" : "未対応"}
                bold
                size="medium"
                clickable
                onClick={handleOpenIsCompletedMenu}
                icon={
                  <Icon
                    icon="dropdownArrow"
                    color={contactRoom.isCompleted ? "darkGrey" : "white"}
                    size="sm"
                  />
                }
              />
              <RoomIsCompletedMenu
                anchorIsCompetedEl={anchorIsCompetedEl}
                isCompleted={contactRoom.isCompleted}
                contactRoomId={contactRoom.id}
                handleCloseIsCompletedMenu={handleCloseIsCompletedMenu}
                updateIsCompleted={updateIsCompleted}
              />
            </Box>
          </Box>
          <Box>
            <DetailMenu
              openManageContactTeamModal={handleClickManageButton}
              contactRoom={contactRoom}
            />
          </Box>
        </Box>
      </StyledHeaderBox>
      {/* // ここにonChangeを使うのか、どうするのかわからんが、でバウンスで２秒たったらサーバーに飛ばす、というのを実装する。 */}
      <Box display="flex" height="100%" width="100%" overflow="scroll">
        <Box width="100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            gridGap="32px"
            padding="40px"
            overflow="scroll"
            // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
            // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
            {...{ ref: (node: HTMLDivElement) => setMessageListRef(node) }}
          >
            {messageListView}
          </Box>
          {!isRoomTargetEmployeeDeleted && !contactRoom.isUnFollowByEmployee && (
            <Box mt="auto" p="24px" bgcolor="white">
              <TextareaAutosizeAttachableFile
                ref={contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef}
                value={newMessage}
                fullWidth
                placeholder={`${contactRoom.getHonorificRoomName()}へのメッセージを入力してください`}
                onChange={onChange}
                minRows={1}
                maxRows={5}
                maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
                accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
                onChangeFiles={(newFiles: (File | Pick<File, "name">)[]) => {
                  setNewMessageFiles(
                    newFiles.filter((v): v is File => {
                      return v instanceof File;
                    })
                  );
                }}
                attachedFiles={newMessageFiles}
                isFileSending={isFileSending}
                footerLeftActions={
                  <IconButton
                    icon="document"
                    size="sm"
                    color="grey"
                    borderRadius="regular"
                    onClick={contactMessageTemplatesMenuHandling.handleOpen}
                  />
                }
                footerButtons={[
                  <Button
                    key="submit"
                    onClick={() => handleSubmit()}
                    borderRadius="regular"
                    variant="contained"
                    color="primary"
                    disabled={!newMessage.trim() || isSending}
                  >
                    送信
                  </Button>,
                ]}
              />
            </Box>
          )}
        </Box>
      </Box>
      <ContactMessageTemplateMenu
        menuStartAnchorEl={contactMessageTemplatesMenuHandling.anchorEl}
        closeMenu={contactMessageTemplatesMenuHandling.handleClose}
        reflectMessage={contactMessageTemplatesMenuHandling.handleReflectMessage}
      />
    </Box>
  );
};

/**
 * 対応・未対応のメニュー
 */
const RoomIsCompletedMenu = ({
  anchorIsCompetedEl,
  isCompleted,
  contactRoomId,
  handleCloseIsCompletedMenu,
  updateIsCompleted,
}: {
  anchorIsCompetedEl: HTMLElement | null;
  isCompleted: boolean;
  contactRoomId: string;
  handleCloseIsCompletedMenu: () => void;
  updateIsCompleted: (contactRoomId: string, isCompleted: boolean) => Promise<void | ContactRoom>;
}) => {
  return (
    <Menu
      anchorEl={anchorIsCompetedEl}
      keepMounted
      open={Boolean(anchorIsCompetedEl)}
      onClose={handleCloseIsCompletedMenu}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {isCompleted ? (
        <MenuItem
          onClick={() => {
            updateIsCompleted(contactRoomId, false);
            handleCloseIsCompletedMenu();
          }}
        >
          <Typography variant="body2">未対応</Typography>
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            updateIsCompleted(contactRoomId, true);
            handleCloseIsCompletedMenu();
          }}
        >
          <Typography variant="body2">対応済み</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;

const StyledPaper = styled(Paper)`
  text-align: center;
  padding: 16px;
  max-width: 480px;
  margin: 0 auto;
`;

const StyledBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledUnavailableMessagePaper = styled(Paper)`
  width: 80%;
  text-align: center;
  padding: 16px;
  margin: 8px auto 0 auto;
  &.MuiPaper-root {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &.MuiPaper-radius {
    border-radius: 10px;
  }
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    > .MuiChip-icon {
      order: 1; // アイコンと文字列の表示順を入れ替える
      margin-left: -12px;
      margin-right: 4px;
    }
  }
`;

const StyledHeaderBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;
