import { Employee, SurveyAlert } from "@onn/common";

import { factory } from "~/service/repository/iSurveyAlertRepository";

const surveyAlertRepository = factory.surveyAlertRepository();

export class CompleteSurveyAlertUseCase {
  async execute(currentUser: Employee, surveyAlert: SurveyAlert) {
    if (!currentUser.isAdmin()) {
      throw new Error("アンケートアラートを完了する権限がありません。");
    }

    const now = new Date();
    const newSurveyAlert = surveyAlert.done(currentUser.id, now);

    await surveyAlertRepository.update(newSurveyAlert);
  }
}
