import { Box } from "@material-ui/core";
import { DEFAULT_MAX_FILE_SIZE_MB, DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { TextareaAutosizeAttachableFile, Typography } from "~/components/uiParts";

type Props = {
  title: string;
  caption: string;
  body: string;
  filePaths: (string | File)[];
  placeholder?: string;
  readOnly?: boolean;
  setBody: (value: string) => void;
  setFiles: (newFiles: (File | Pick<File, "name">)[]) => void;
};
export const BodyForm: FC<Props> = ({
  title,
  caption,
  body,
  filePaths,
  placeholder,
  readOnly = false,
  setBody,
  setFiles,
}) => {
  return (
    <>
      <Box mt="36px" display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          {title}
        </Typography>
      </Box>
      <Box mt="16px">
        <StyledMutedTypography variant="caption">{caption}</StyledMutedTypography>
      </Box>
      <Box mt="16px">
        <TextareaAutosizeAttachableFile
          value={body}
          fullWidth
          placeholder={placeholder}
          onChange={(e) => setBody(e.target.value)}
          minRows={4}
          maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
          accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
          onChangeFiles={setFiles}
          attachedFiles={
            filePaths ? filePaths.map((v) => (typeof v === "string" ? { name: v } : v)) : []
          }
          readOnly={readOnly}
        />
      </Box>
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
