import { ContactRoom, ReadLog, removeUndefinedFromObject } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  where,
  query,
  getDocs,
  Timestamp,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IContactRoomRepository } from "~/service/repository/iContactRoomRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type ReadLogForDB = Omit<ReadLog, "readAt" | "update"> & {
  readAt: Timestamp;
};

type ContactRoomForDB = Omit<
  ExcludeMethods<ContactRoom>,
  "createdAt" | "updatedAt" | "readLogs" | "incompleteSince"
> & {
  readLogs: ReadLogForDB[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
  incompleteSince: Timestamp | null;
};

const COLLECTION_NAME = "contactRooms";

export class ContactRoomRepository implements IContactRoomRepository {
  async findById(contactRoomId: string): Promise<ContactRoom> {
    const doc = await getDoc(this.doc(contactRoomId));

    if (!doc.data()) {
      throw new Error("対象のコンタクトルームが見つかりませんでした。");
    }

    return this.dbToObject(doc.data() as ContactRoomForDB);
  }

  /**
   * 受け入れメンバーのみ使用可能
   * NOTE: 入社者でも使用したい場合、セキュリティルールの評価に tenantId に加えて employeeId を使用するため、where句を増やす必要がある
   */
  async findByTargetIdAndTenantId(targetId: string, tenantId: string): Promise<ContactRoom | null> {
    return await getDocs(
      query(this.collection(), where("targetId", "==", targetId), where("tenantId", "==", tenantId))
    ).then(async (snapshot) => {
      if (snapshot.docs.length === 0) {
        return null;
      }

      return this.dbToObject(
        (snapshot.docs[0] as (typeof snapshot.docs)[number]).data() as ContactRoomForDB
      );
    });
  }

  /**
   * 受け入れメンバーのみ使用可能
   * NOTE: 入社者でも使用したい場合、セキュリティルールの評価に tenantId に加えて employeeId を使用するため、where句を増やす必要がある
   */
  async findUnFollowedContactRooms(tenantId: string): Promise<ContactRoom[]> {
    return await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("isUnFollowByEmployee", "==", true)
      )
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => {
        return this.dbToObject(doc.data() as ContactRoomForDB);
      });
    });
  }

  async update(newContactRoom: ContactRoom): Promise<void> {
    const ref = this.doc(newContactRoom.id);

    await updateDoc(ref, this.convertToPlainObject(newContactRoom));
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private convertToPlainObject(data: ContactRoom): ContactRoomForDB {
    return removeUndefinedFromObject({
      ...data,
      readLogs: data.readLogs.map((readLog) => {
        return { ...readLog, readAt: convertDateToTimestamp(readLog.readAt) };
      }),
      createdAt: convertDateToTimestamp(data.createdAt),
      updatedAt: convertDateToTimestamp(data.updatedAt),
      incompleteSince:
        data.incompleteSince != null ? convertDateToTimestamp(data.incompleteSince) : null,
    });
  }

  private dbToObject({
    createdAt,
    updatedAt,
    readLogs,
    incompleteSince,
    ...rest
  }: ContactRoomForDB): ContactRoom {
    return new ContactRoom({
      ...rest,
      readLogs: readLogs.map(
        (readLog) => new ReadLog({ ...readLog, readAt: convertTimestampToDate(readLog.readAt) })
      ),
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
      incompleteSince: incompleteSince != null ? convertTimestampToDate(incompleteSince) : null,
    });
  }
}
