import { Employee, Role, OnboardingTaskType, OnboardingTaskFactory } from "@onn/common";
import { isEmpty } from "lodash";

import { EmployeeRepository } from "~/infrastructure/api/employeeRepository";
import { functionOperator } from "~/infrastructure/api/functionOperator";
import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

type UploadFileType = { path: string; file: File };

const employeeRepository = new EmployeeRepository();
const onboardingTaskRepository = new OnboardingTaskRepository();
const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export class UpdateOnboardingTaskUseCase {
  async execute(
    onboardingTaskId: string,
    newObject: Omit<Partial<OnboardingTaskType>, "type">,
    emailsWithoutOnnAccount: string[]
  ): Promise<OnboardingTaskType> {
    const uploadFiles: UploadFileType[] = [];

    const onboardingTask = await onboardingTaskRepository.findById(onboardingTaskId);
    const newOnboardingTask = OnboardingTaskFactory.createOnboardingTask({
      ...onboardingTask,
      ...newObject,
    });

    if (!isEmpty(emailsWithoutOnnAccount)) {
      const employees = await this.createAccount(emailsWithoutOnnAccount);
      newOnboardingTask.assigneeIds = [
        ...newOnboardingTask.assigneeIds,
        ...employees.map((v) => v.id),
      ];
    }

    // ステータスを完了に変更する際にロールがADMINの場合はロールをBY_NAMEに変更し担当者に現在のHRのidをセットする
    if (newOnboardingTask.assigneeRole === "ADMIN" && newOnboardingTask.status === "COMPLETED") {
      const admins = await employeeRepository.findByRole(newOnboardingTask.tenantId, Role.ADMIN);
      newOnboardingTask.assigneeRole = "BY_NAME";
      newOnboardingTask.assigneeIds = admins.map((v) => v.id);
    }

    // ステータスを完了に変更する際にロールがSUPPORT_MEMBERの場合はロールをBY_NAMEに変更し担当者に現在のSUPPORT_MEMBERのidをセットする
    if (
      newOnboardingTask.assigneeRole === "SUPPORT_MEMBER" &&
      newOnboardingTask.status === "COMPLETED"
    ) {
      const newHire = await employeeRepository.findById(newOnboardingTask.employeeId);
      newOnboardingTask.assigneeRole = "BY_NAME";
      newOnboardingTask.assigneeIds = newHire?.supportMemberEmployeeIds || [];
    }

    await onboardingTaskRepository.update(
      newOnboardingTask.id,
      OnboardingTaskFactory.createOnboardingTask({
        ...newOnboardingTask,
        filePaths: newOnboardingTask.filePaths.map((v) => {
          if (typeof v === "string") return v;
          const path = `tenants/${newOnboardingTask.tenantId}/onboarding_tasks/${newOnboardingTask.id}/${v.name}`;
          uploadFiles.push({ path, file: v });
          return path;
        }),
      })
    );

    await fileAPIAdapter.uploadFiles(uploadFiles);

    return newOnboardingTask;
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emailsWithoutOnnAccount: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emailsWithoutOnnAccount.map((email) => {
          return {
            email,
            role: Role.MEMBER,
            departmentIds: [],
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }
}
