import { useCallback } from "react";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileApiAdapter = new FileAPIAdapter({ bucketType: "private" });

type FilePath = string;

export const usePrivateFileAPIAdapter = () => {
  const uploadFile = useCallback(
    async ({ path, file }: { path: string; file: File }): Promise<FilePath> => {
      const filePath = await fileApiAdapter.upload({ path, file });
      return filePath;
    },
    []
  );

  const getFileUrl = useCallback(({ path }: { path: string }): string => {
    const url = fileApiAdapter.getFileUrl(path);
    return url;
  }, []);

  return {
    uploadFile,
    getFileUrl,
  };
};
