import { Box } from "@material-ui/core";
import { Employee, SlackUser } from "@onn/common";
import React, { ComponentProps, FC, useEffect, useState } from "react";

import { EmployeeList, EmployeeSearchForm } from "./parts";

import { SlackUserList, SlackUserSearchForm } from "~/components/domains/slackUsers";
import { Divider } from "~/components/uiParts";
import { useAllEmployees } from "~/hooks/employee";
import { useSlackUsers } from "~/hooks/slackUser";
import { useTenant } from "~/hooks/tenant";

type Props = {
  notAllowEmails: string[];
  selectedEmails: string[];
  onSelectEmails: (emails: string[]) => void;
  // 入社者の関係者を上部に固定するために渡す
  targetNewHireForFixingMemberOnTop?: Employee;
  rowRendererMode?: ComponentProps<typeof SlackUserList>["rowRendererMode"];
};

const siftSlackUsers = (employees: SlackUser[], allEmployees: Employee[], newHire?: Employee) => {
  if (!newHire) return employees;

  const generatedEmployees: SlackUser[] = [];
  // バディとサポートメンバーは先頭に移動させる
  employees.forEach((employee) => {
    const targetEmployee = allEmployees.find((v) => v.email === employee.email);
    if (targetEmployee && newHire.getMentorAndSupportMemberIds().includes(targetEmployee.id)) {
      return generatedEmployees.unshift(employee);
    }
    return generatedEmployees.push(employee);
  });

  return generatedEmployees;
};

const siftEmployees = (employees: Employee[], allEmployees: Employee[], newHire?: Employee) => {
  if (!newHire) return employees;

  const generatedEmployees: Employee[] = [];
  // バディとサポートメンバーは先頭に移動させる
  employees.forEach((employee) => {
    const targetEmployee = allEmployees.find((v) => v.email === employee.email);
    if (targetEmployee && newHire.getMentorAndSupportMemberIds().includes(targetEmployee.id)) {
      return generatedEmployees.unshift(employee);
    }
    return generatedEmployees.push(employee);
  });

  return generatedEmployees;
};

export const SelectEmployeesFormByPlatform: FC<Props> = ({
  notAllowEmails,
  selectedEmails,
  onSelectEmails,
  targetNewHireForFixingMemberOnTop,
  rowRendererMode,
}) => {
  const { slackUsers, loadingSlackUsers } = useSlackUsers();
  const { tenant } = useTenant();
  const { allEmployees } = useAllEmployees();

  const [displaySlackUsers, setDisplaySlackUsers] = useState<SlackUser[]>(
    slackUsers.filter((v) => !notAllowEmails.includes(v.email))
  ); // クライアント検索処理で再構成される表示用の配列
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(allEmployees); // クライアント検索処理で再構成される表示用の配列

  useEffect(() => {
    setDisplaySlackUsers(slackUsers);
  }, [slackUsers]);

  return (
    <>
      {tenant?.isIntegratedSlack ? (
        <>
          <Box p={rowRendererMode === "checkbox" ? "16px" : "0px"}>
            <SlackUserSearchForm
              variant={rowRendererMode === "checkbox" ? "outlined" : "standard"}
              showPlaceholder
              slackUsers={slackUsers.filter((v) => !notAllowEmails.includes(v.email))}
              onSearch={(results) => setDisplaySlackUsers(results)}
            />
          </Box>
          {rowRendererMode === "checkbox" && <Divider />}
          <SlackUserList
            slackUsers={siftSlackUsers(
              displaySlackUsers,
              allEmployees,
              targetNewHireForFixingMemberOnTop
            )}
            selectedSlackUsers={slackUsers.filter((v) => selectedEmails.includes(v.email))}
            onSelect={(slackUsers) => onSelectEmails(slackUsers.map((v) => v.email))}
            isLoading={loadingSlackUsers}
            isMultiple
            rowRendererMode={rowRendererMode}
          />
        </>
      ) : (
        <>
          <Box p={rowRendererMode === "checkbox" ? "16px" : "0px"}>
            <EmployeeSearchForm
              showPlaceholder
              variant={rowRendererMode === "checkbox" ? "outlined" : "standard"}
              employees={allEmployees}
              onSearch={(results) => setDisplayEmployees(results)}
            />
          </Box>
          {rowRendererMode === "checkbox" && <Divider />}
          <EmployeeList
            employees={siftEmployees(
              displayEmployees.filter((v) => !notAllowEmails.includes(v.email)),
              allEmployees,
              targetNewHireForFixingMemberOnTop
            )}
            selectedEmployees={allEmployees.filter((v) => selectedEmails.includes(v.email))}
            onSelect={(employees) => onSelectEmails(employees.map((v) => v.email))}
            isMultiple
            rowRendererMode={rowRendererMode}
          />
        </>
      )}
    </>
  );
};
