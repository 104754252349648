import { OnboardingMessageTask, Employee, OnboardingTaskType } from "@onn/common";
import { useCallback } from "react";

/**
 * オンボーディングタスクを配信済みのメッセージタスクに絞り込む関数を提供するhooks
 */
export const useFilterTasksToDelivered = () => {
  /**
   * オンボーディングタスクを配信済みのメッセージタスクに絞り込む
   * @param {OnboardingTaskType[]} onboardingTasks
   * @param {Employee} employee
   */
  const filterTasksToDelivered = useCallback(
    (onboardingTasks: OnboardingTaskType[], employee: Employee): OnboardingMessageTask[] =>
      onboardingTasks
        .filter((v): v is OnboardingMessageTask => v.type === "MESSAGE_TASK")
        .filter((v) => v.isDeliveredByEmployee(employee)),
    []
  );

  return { filterTasksToDelivered };
};
