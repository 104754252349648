import { Box } from "@material-ui/core";
import { fileTypeMatchers, OnboardingGeneralTask } from "@onn/common";
import React, { FC, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GeneralTaskButton } from "~/components/domains/onboardingTask/GeneralTaskButton";
import { Button, Divider, FileListItem, Linkify, Loading, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useMetaDataByUrls } from "~/hooks/file/useMetaDataByUrls";
import { useSnackbar, useQuery } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";
import { useGetOnboardingTask } from "~/pages/portal/onboardingTasks/hooks/useGetNewHireOnboardingTask";
import { captureException, downloadFileAsync } from "~/util";

const REWARD_ID = "complete-task";

export const OnboardingTaskDetail: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const { onboardingTaskId } = useParams<"onboardingTaskId">();
  const { query } = useQuery();
  const isPreview = query.get("preview") === "true";

  const {
    onboardingTask,
    isLoadingOnboardingTask,
    updateOnboardingTaskStatus,
    isLoadingUpdateOnboardingTask,
  } = useGetOnboardingTask(isPreview, onboardingTaskId);

  const { data: metadata } = useMetaDataByUrls(
    onboardingTask ? (onboardingTask.filePaths as string[]) : []
  );

  const handleDownloadAttachedFile = useCallback(
    async (url: string, fileName: string) => {
      try {
        await downloadFileAsync({
          url,
          fileName,
        });
      } catch (e) {
        enqueueSnackbar("ファイルのダウンロードに失敗しました", { variant: "error" });
        if (e instanceof Error) {
          captureException({ error: e, tags: { type: "downloadFileAsync" } });
        }
      }
    },
    [enqueueSnackbar]
  );

  if (isLoadingOnboardingTask) {
    return <Loading size="large" />;
  }

  if (!onboardingTask) {
    return <NotFound />;
  }

  return (
    <Box px={3} py={5}>
      <Typography variant="body2" bold color="textPrimary">
        内容を確認して、対応が済んだら「対応完了」ボタンを押しましょう！
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {onboardingTask instanceof OnboardingGeneralTask
          ? "※対応を完了すると管理者へ完了の通知が送信されます。"
          : "※「対応完了」ボタンを押すと管理者へ完了の通知が送信されます。"}
      </Typography>
      <Divider margin={16} orientation="horizontal" />
      <Box height="24px" />
      <StyledCard py="40px" px="24px" mb="40px">
        <Typography variant="h4" bold color="textPrimary" style={{ wordBreak: "break-all" }}>
          {onboardingTask.title}
        </Typography>
        <Box mt="8px" mb="16px">
          <StyledMutedTypography variant="caption">
            期日：
            {isPreview
              ? onboardingTask.dueDate.getDisplayTiming()
              : onboardingTask.dueDate.getDisplayTextByEmployee(currentUser, "M月d日")}
            まで
          </StyledMutedTypography>
        </Box>
        <Linkify>
          <Typography variant="body2" color="textPrimary">
            {onboardingTask.body}
          </Typography>
        </Linkify>
        {onboardingTask instanceof OnboardingGeneralTask && (
          <Box mt="32px">
            <GeneralTaskButton
              onboardingTask={onboardingTask}
              newHire={currentUser}
              disabled={isPreview || !currentUser.assignedAsNewcomer}
            />
          </Box>
        )}
        {onboardingTask instanceof OnboardingGeneralTask &&
          onboardingTask.actionId === "REGISTER_PROFILE" &&
          onboardingTask.status === "COMPLETED" && (
            <Box mt="16px">
              <Button
                variant="outlined"
                fullWidth
                borderRadius="circle"
                color="primary"
                onClick={() => navigate("/profile")}
                disabled={isPreview}
              >
                編集
              </Button>
            </Box>
          )}
        {metadata && (
          <Box display="flex" flexDirection="column" mt="24px" gridGap="16px">
            {metadata.map((v: { name: string; size: number; url: string }) => {
              if (fileTypeMatchers.image.test(v.name)) {
                return <img width="100%" key={v.name} src={v.url} alt={v.name} />;
              }
              const isPdf = fileTypeMatchers.pdf.test(v.name);
              return (
                <FileListItem
                  key={v.name}
                  onClick={
                    isPdf
                      ? () => window.open(v.url, "_blank", "noopener noreferrer")
                      : () => handleDownloadAttachedFile(v.url, v.name)
                  }
                  isPdf={isPdf}
                  canDownload={!fileTypeMatchers.pdf.test(v.name)}
                  name={v.name}
                  byte={v.size}
                />
              );
            })}
          </Box>
        )}
      </StyledCard>
      {!(onboardingTask instanceof OnboardingGeneralTask) && (
        <Box mb="24px" display="flex" flexDirection="column">
          <Button
            variant="contained"
            fullWidth
            borderRadius="circle"
            color={onboardingTask.status === "COMPLETED" ? "default" : "primary"}
            onClick={() => updateOnboardingTaskStatus(onboardingTask.id, onboardingTask.status)}
            disabled={isLoadingUpdateOnboardingTask}
          >
            <span id={REWARD_ID} />
            {onboardingTask.status === "COMPLETED" ? "完了取り消し" : "タスク対応完了"}
          </Button>
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        <Button
          variant="text"
          fullWidth
          borderRadius="regular"
          color="primary"
          onClick={() =>
            // NOTE: このページに直接遷移した時はlengthが1になるので、一覧に戻るようにする。
            // - この時遷移先でブラウザバックすると履歴がないが、lengthが2になってこのページに戻ってくる。
            // - その場合、navigate(-1)をするが履歴がないので動作しなくなる。そのためreplaceをtrueにすることでlengthを増やさないようにしている
            window.history.length >= 2
              ? navigate(-1)
              : navigate("/portal/onboarding_tasks", { replace: true })
          }
        >
          一覧に戻る
        </Button>
      </Box>
    </Box>
  );
};

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
`;

const StyledMutedTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;
