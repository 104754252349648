import { Box, Grid, Radio as MuiRadio, useMediaQuery } from "@material-ui/core";
import { QuestionItem as QuestionItemType } from "@onn/common";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts/Button";
import { Typography } from "~/components/uiParts/Typography";
import theme from "~/config/theme";

type CustomProps = {
  questionItems: QuestionItemType[];
  name?: string;
  value?: string;
  disableEffect?: boolean;
  onClickButton: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = ComponentProps<typeof MuiRadio> & CustomProps;

export const ButtonRadioGroup: FC<Props> = ({
  questionItems,
  name,
  value,
  disableEffect = false,
  onClickButton,
}) => {
  const isPc = useMediaQuery(theme.breakpoints.up("sm"));
  const isFive = questionItems.length === 5;

  return (
    questionItems && (
      <Grid container justifyContent="flex-start">
        {questionItems.map((questionItem, i) => {
          return (
            <Box
              key={`${name}ButtonRadio${i}`}
              display="inline"
              width={isPc ? (isFive ? "20%" : 1 / 3) : 1}
            >
              <StyledMuiRadio
                onChange={onClickButton}
                value={questionItem.name}
                checked={value === questionItem.name}
                name={name}
                color="primary"
                $disableEffect={disableEffect}
                checkedIcon={
                  <Button borderRadius="circle" variant="contained" color="primary">
                    {questionItem.title}
                  </Button>
                }
                icon={
                  <Button variant="outlined" color="default" borderRadius="circle">
                    <Typography variant="body2" bold color="textSecondary">
                      {questionItem.title}
                    </Typography>
                  </Button>
                }
              />
            </Box>
          );
        })}
      </Grid>
    )
  );
};

const StyledMuiRadio = styled(MuiRadio)<{ $disableEffect: boolean }>`
  width: 100%;
  padding-left: 0;

  ${(props) => props.$disableEffect && `pointer-events:none;`};
`;
