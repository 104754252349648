import { Box } from "@material-ui/core";
import { OnboardingMessageTask } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC } from "react";

import { Tooltip, Typography, UserIcon, UserIconGroup } from "~/components/uiParts";

const notAssignTextMap = {
  ADMIN: "取得中",
  MENTOR: "バディ未設定",
  SUPPORT_MEMBER: "サポートメンバー未設定",
  NEW_HIRE: "取得中",
  BY_NAME: "担当未設定",
  NOT_SET: "担当未設定",
};

type Props = {
  onboardingTask: OnboardingMessageTask;
  onClickEditTask: () => void;
};

export const RequestedEmployees: FC<Props> = ({ onboardingTask, onClickEditTask }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gridGap="8px"
      width="100%"
      onClick={(e) => {
        e.stopPropagation();
        onClickEditTask();
      }}
    >
      {isEmpty(onboardingTask.contents) ? (
        <Tooltip title="担当者を設定する" placement="bottom" arrow>
          <Box display="flex" alignItems="center" gridGap="8px" width="100%">
            <UserIcon username="" size="small" circular />
            <Typography variant="caption" color="textSecondary" noWrap>
              {notAssignTextMap[onboardingTask.assigneeRole]}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <UserIconGroup
          usersInfo={onboardingTask.contents.map((content) => ({
            username: content.name,
            profileIconImageUrl: content.iconPath,
          }))}
          max={4}
          tooltip
        />
      )}
    </Box>
  );
};
