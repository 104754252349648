import { Box, Grid } from "@material-ui/core";
import { Message } from "@onn/common";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  Icon,
  Paper,
  ImageUploadArea,
  MessageModal,
  TextareaAutosize,
  TextField,
  Typography,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { usePrompt } from "~/hooks/shared/usePrompt";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";
import { captureException } from "~/util";
import { getNameAndExtensionFromFilePath } from "~/util/getNameAndExtensionFromFilePath";

const fileApiAdapter = new FileAPIAdapter({ bucketType: "private" });
const MESSAGE_MAX_LENGTH = 500;

type ContentFormType = Pick<Message, "iconPath" | "fullName" | "position" | "content">;

export const TopMessage: FC = () => {
  const { currentUser } = useCurrentUser();
  const { tenantSettings, mutateTenantSettings } = useTenantSettings();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [topMessage, setTopMessage] = useState<Message>();
  const [newTopMessage, setNewTopMessage] = useState<ContentFormType>({
    iconPath: "",
    fullName: "",
    position: "",
    content: "",
  });
  const [inputIcon, setInputIcon] = useState<File>();
  const [inputIconPath, setInputIconPath] = useState("");
  const [currentIcon, setCurrentIcon] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  usePrompt("編集内容を破棄しますか？", isChanged);

  useEffect(() => {
    const fetchUrl = async (iconPath: string) => {
      const url = await fileApiAdapter.fetchUrl({ path: iconPath });
      url && setCurrentIcon(url);
    };

    if (tenantSettings && tenantSettings.messages) {
      const match = tenantSettings.messages.find((message) => message.title === "トップメッセージ");
      if (match) {
        setTopMessage(match);
        setNewTopMessage({
          iconPath: match.iconPath,
          fullName: match.fullName,
          position: match.position,
          content: match.content,
        });
        if (match.iconPath) {
          fetchUrl(match.iconPath);
        }
      }
    }
  }, [tenantSettings]);

  const handleUploadImage = async (file: File) => {
    setInputIcon(file);

    const reader = new FileReader();
    reader.onload = () => {
      // readAsDataURLはstringしか返さないが、resultの型にArrayBufferが含まれるので型チェックする
      if (typeof reader.result === "string") {
        if (!isChanged) setIsChanged(true);
        const { name, extension } = getNameAndExtensionFromFilePath(file.name);
        setInputIconPath(reader.result);
        setNewTopMessage((prev) => ({
          ...prev,
          iconPath: `tenants/${currentUser.tenantId}/messages/${name}.${extension}`,
        }));
      }
    };
    reader.readAsDataURL(file);
  };

  const handleErrorUploadImage = () => {
    enqueueSnackbar("アイコンは120px*120pxから2100px*2100pxの間で設定してください", {
      variant: "error",
    });
  };

  const checkMessageLength = useCallback(() => {
    return newTopMessage.content.length > MESSAGE_MAX_LENGTH;
  }, [newTopMessage]);

  useEffect(() => {
    const isSomeFieldBlank = Object.values(newTopMessage).some((value: string) => !value.trim());

    // ブランクのフィールドが存在 || メッセージ文字数が超過 || 変更されていない || (新たにアイコンを設定していない && 最初からアイコンが設定されていない)
    setButtonDisabled(
      isSomeFieldBlank ||
        checkMessageLength() ||
        !isChanged ||
        (!inputIcon && !newTopMessage.iconPath)
    );
  }, [newTopMessage, inputIcon, isChanged, checkMessageLength]);

  const handleChangeTopMessage = (newObject: Record<string, string>) => {
    if (!isChanged) setIsChanged(true);

    setNewTopMessage((prev) => ({ ...prev, ...newObject }));
  };

  const moveToSettings = () => {
    navigate("/settings/admin?target=portal#feature");
  };

  const handleSubmit = async () => {
    if (inputIcon) {
      await fileApiAdapter.upload({ path: newTopMessage.iconPath, file: inputIcon });
    }

    try {
      const message = {
        ...topMessage,
        ...newTopMessage,
        title: "トップメッセージ",
      } as Message;
      await TenantSettingsUseCase.updateMessage(currentUser.tenantId, message);
      mutateTenantSettings();
      setIsChanged(false);

      enqueueSnackbar("変更を保存しました", { variant: "success" });
      moveToSettings();
    } catch (e) {
      console.error(e);
      enqueueSnackbar("保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "TopMessage:handleSubmit" },
      });
    }
  };

  return (
    <>
      <StyledContainerBox pt={6} pb={6} pl={5} pr={5}>
        <Button
          variant="text"
          borderRadius="regular"
          color="default"
          onClick={() => moveToSettings()}
        >
          <Typography variant="body2" color="textSecondary">
            ← 機能設定
          </Typography>
        </Button>
        <Box height="40px" />
        <Typography variant="h4" bold>
          トップメッセージ
        </Typography>
        <Box height="40px" />
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <Paper square>
              <Typography variant="body2" bold appendRequiredSymbol>
                アイコン
              </Typography>
              <StyledTypography variant="caption">
                アイコン画像を設定してください。顔写真を推奨しています。
              </StyledTypography>
              <ImageUploadArea
                alt="アイコン"
                defaultImage={currentIcon}
                imagePath={inputIconPath}
                allowImageSize={{
                  max: { width: 2100, height: 2100 },
                  min: { width: 120, height: 120 },
                }}
                onChange={handleUploadImage}
                onError={handleErrorUploadImage}
                size="large"
              />

              <Box height="32px" />
              <Typography variant="body2" bold appendRequiredSymbol>
                氏名
              </Typography>
              <StyledTextField
                variant="outlined"
                placeholder="氏名を設定してください"
                fullWidth
                value={newTopMessage.fullName}
                onChange={(e) => handleChangeTopMessage({ fullName: e.target.value })}
              />
              <Box height="32px" />
              <Typography variant="body2" bold appendRequiredSymbol>
                肩書き
              </Typography>
              <StyledTextField
                variant="outlined"
                placeholder="ex: CEO, 代表取締役"
                fullWidth
                value={newTopMessage.position}
                onChange={(e) => handleChangeTopMessage({ position: e.target.value })}
              />
              <Box height="32px" />
              <Typography variant="body2" bold appendRequiredSymbol>
                メッセージ
              </Typography>
              <StyledTypography variant="caption">
                入社者が初めてログインした時に最初に表示されるコンテンツです。入社者全員に同じ文言が表示されるので、新しいメンバーに向けた入社を歓迎するメッセージを設定しましょう！
              </StyledTypography>
              <Box height="16px" />
              <StyledTextarea
                value={newTopMessage.content}
                minRows={4}
                fullWidth
                onChange={(e) => handleChangeTopMessage({ content: e.target.value })}
                placeholder="新しいメンバーが最初に見るメッセージです。新しいメンバーに向けた歓迎のメッセージを設定してください。"
                maxTextCount={MESSAGE_MAX_LENGTH}
                error={checkMessageLength()}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Box position="sticky" top="40px">
              <Button
                variant="contained"
                borderRadius="circle"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={buttonDisabled}
              >
                保存
              </Button>
              <Box mt={2} />
              <Button
                color="default"
                borderRadius="circle"
                variant="outlined"
                fullWidth
                onClick={() => moveToSettings()}
              >
                キャンセル
              </Button>
              <Box mt={2} />
              <Button
                variant="text"
                color="primary"
                borderRadius="regular"
                fullWidth
                onClick={() => setOpenPreviewModal(true)}
                startIcon={<Icon icon="eye" color="primary" size="md" />}
              >
                表示をプレビュー
              </Button>
            </Box>
          </Grid>
        </Grid>
      </StyledContainerBox>
      {openPreviewModal && (
        <MessageModal
          open={openPreviewModal}
          onCancel={() => setOpenPreviewModal(false)}
          profileIconImageUrl={inputIconPath || currentIcon}
          username={newTopMessage.fullName}
          position={newTopMessage.position}
          contentText={newTopMessage.content}
        />
      )}
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: calc(100vh - 80px);
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    margin-top: 16px;
  }
`;

const StyledTextarea = styled(TextareaAutosize)`
  box-sizing: inherit !important;
`;
