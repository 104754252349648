import { useState, useCallback } from "react";

import { useCreateUnreadCountByEmployeeIds } from "../contactRoomUnreadCount";
import { useCurrentUser } from "../employee";
import { useSnackbar } from "../shared";

import { UpdateAssigneeAndFollowersUseCase as UpdateAssigneeAndFollowersUseCase } from "~/service/usecases/contactRoom";
import { captureException } from "~/util";

const updateAssigneeAndFollowersUseCase = new UpdateAssigneeAndFollowersUseCase();
/**
 *AssigneeとFollowersのIDを更新するための関数を提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns updateAssigneeAndFollowers
 */
export const useUpdateAssigneeAndFollowers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { createUnreadCountByEmployeeIds } = useCreateUnreadCountByEmployeeIds();
  /**
   * aAssigneeとFollowersのIDを更新するための関数
   */
  const updateAssigneeAndFollowers = useCallback(
    async (
      contactRoomId: string,
      assigneeId: string | undefined,
      followerIds: string[],
      emailsWithoutOnnAccount: string[],
      assigneeEmailWithoutOnnAccount?: string
    ) => {
      setIsLoading(true);

      // 中途テナントの場合は入社者が担当者・フォロワーになる可能性があるため
      await createUnreadCountByEmployeeIds({
        employeeIds: [...followerIds, assigneeId].flatMap((id) => id || []),
        contactRoomId,
      }).catch((e) => {
        captureException({
          error: e as Error,
          tags: { type: "updateAssigneeAndFollowers:createUnreadCountByEmployeeIds" },
          extras: {
            contactRoomId,
            assigneeId,
            followerIds,
          },
        });
      });
      return await updateAssigneeAndFollowersUseCase
        .execute(
          currentUser,
          contactRoomId,
          assigneeId,
          followerIds,
          emailsWithoutOnnAccount,
          assigneeEmailWithoutOnnAccount
        )
        .then(() => {
          enqueueSnackbar(`対応チームを更新しました`, { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw new Error(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [createUnreadCountByEmployeeIds, currentUser, enqueueSnackbar]
  );

  return { isLoading, updateAssigneeAndFollowers };
};
