import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { FileThumbnail, Loading } from "~/components/uiParts";
import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

// ====================
// props
// ====================

type Props = {
  filePaths: string[];
};

// ====================
// main
// ====================

export const FileSection: FC<Props> = ({ filePaths }) => {
  // ====================
  // hooks
  // ====================

  const { data: files, isValidating: isValidatingMetadata } = useMetaDataByUrls(filePaths);
  const { setFiles, setPreviewFileIndex, handleDownloadFile } = useFileViewer();

  // ====================
  // main component
  // ====================

  return (
    <>
      <Box display="flex" gridGap="16px" flexWrap="wrap">
        {isValidatingMetadata ? (
          <Loading size="small" />
        ) : (
          <>
            {files &&
              files.map((file, index) => (
                <Box key={file.url} width={150} height={196}>
                  <FileThumbnail
                    src={file.url}
                    fileName={file.name}
                    filePath={file.url}
                    isDisplayActionContainer
                    onPreviewFile={() => {
                      setFiles(files);
                      setPreviewFileIndex(index);
                    }}
                    onDownloadFile={(url) => handleDownloadFile(url, [file])}
                  />
                </Box>
              ))}
          </>
        )}
      </Box>
    </>
  );
};
