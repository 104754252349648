import { isSameDay, isPast, subDays, addDays, getHours } from "date-fns";
import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { Employee } from "../../../../domain/Employee";
import { OnboardingTask } from "../../OnboardingTask";
import { OnboardingTaskMemo } from "../../OnboardingTaskMemo";
import { MessageContent } from "../MessageContent";

export class OnboardingMessageTask extends OnboardingTask {
  readonly id: string;
  readonly type = "MESSAGE_TASK" as const;
  readonly filePaths: (string | File)[];
  readonly memos: OnboardingTaskMemo[];
  // 1st版ではテンプレートを更新しても反映されるのはExperienceのものだけだが
  // 後々対応しようとすると大変なので予め保持しておく
  readonly templateId: string;
  // 配信日を迎えたタイミングで生成されるtransactionのid
  readonly contents: MessageContent[];
  // メッセージを依頼する対象のロール
  readonly requestedRoles: OnboardingTask["assigneeRole"][];

  constructor(init: ExcludeMethods<OnboardingMessageTask>) {
    super(init);
    this.id = init.id;
    this.filePaths = init.filePaths;
    this.memos = init.memos;
    this.templateId = init.templateId;
    this.contents = init.contents;
    this.requestedRoles = init.requestedRoles;
  }

  public static create(
    params: Optional<ConstructorParameters<typeof OnboardingMessageTask>[0], "id">
  ) {
    return new OnboardingMessageTask({
      ...params,
      id: params.id ?? v4(),
    });
  }

  /**
   * Employeeを元にコンテンツを生成して追加したインスタンスを返す
   * @param employee 追加するemployee
   * @returns OnboardingMessageTask
   */
  insertNewContent(employee: Employee): OnboardingMessageTask {
    // すでに同じemployeeが存在する場合は追加せずにスキップする
    if (this.contents.some((content) => content.requestedEmployeeId === employee.id)) {
      return this;
    }

    return new OnboardingMessageTask({
      ...this,
      contents: [...this.contents, MessageContent.createByEmployee(employee)],
    });
  }

  /**
   * 指定したコンテンツを除外した状態のインスタンスを返す
   * @param {string} contentId 除外するコンテンツのid
   * @returns OnboardingMessageTask
   */
  extractContent(contentId: string): OnboardingMessageTask {
    return new OnboardingMessageTask({
      ...this,
      contents: this.contents.filter((v) => v.id !== contentId),
    });
  }

  /**
   * ボディのテキストの[入社者名]を実際のものに置き換えて表示するメソッド
   * @param {string} newHireName 該当の入社者の名前
   * @returns string
   */
  getBodyTextReplacedWithNewHire(newHireName: string): string {
    return this.body.replace(/\[入社者名\]/g, newHireName);
  }

  /**
   * すでに存在しているメッセージの場合は更新。そうでない場合は追加するメソッド
   * @param {MessageContent} newMessageContent
   */
  upsertMessageContent(newMessageContent: MessageContent): OnboardingMessageTask {
    const targetMessageContent = this.contents.find((v) => v.id === newMessageContent.id);

    return new OnboardingMessageTask({
      ...this,
      contents: targetMessageContent
        ? [
            ...this.contents.filter((v) => v.id !== newMessageContent.id),
            newMessageContent.complete(),
          ]
        : [...this.contents, newMessageContent.complete()],
    });
  }

  /**
   * 入社者に対してメッセージを表示するかどうかを返すメソッド
   * @param newHire
   * @returns boolean
   */
  isDisplayToNewHire(newHire: Employee): boolean {
    if (isEmpty(this.contents.filter((v) => v.isCompleted))) return false;
    const date = this.dueDate.calculateDateByEmployee(newHire);
    if (!date) return false;

    return isPast(date);
  }

  /**
   * メッセージコンテンツを編集できるかどうかを返すメソッド
   * 期日の7日後まで編集ができる
   * @param newHire
   * @returns boolean
   */
  isEditableMessageContent(newHire: Employee): boolean {
    const dueDateTime = this.dueDate.calculateDateByEmployee(newHire);

    if (!dueDateTime) return true;

    return !isPast(addDays(dueDateTime, 7));
  }

  /**
   * 入社者を元にタスクをリマインドするかどうかを返すメソッド
   * 1日前、3日前、7日前 に通知を行う
   * @param employee 入社者
   * @returns boolean
   */
  shouldRemind(employee: Employee, currentDate: Date = new Date()): boolean {
    if (this.status === "COMPLETED") return false;
    if (this.contents.every((v) => v.isCompleted)) return false;

    const date = this.dueDate.calculateDateByEmployee(employee);

    if (!date) return false;

    return (
      isSameDay(currentDate, subDays(date, 7)) ||
      isSameDay(currentDate, subDays(date, 3)) ||
      isSameDay(currentDate, subDays(date, 1))
    );
  }

  /**
   * 入社者を元にメッセージを入社者に配信するかどうかを返すメソッド
   * 期日当日で配信時刻が同じ場合に true を返す
   * @param employee 入社者
   * @returns boolean
   */
  shouldSendMessageToNewHire(employee: Employee, currentDate: Date = new Date()): boolean {
    if (this.status === "COMPLETED") return false;
    if (!this.contents.some((v) => v.isCompleted)) return false;

    const date = this.dueDate.calculateDateByEmployee(employee);

    if (!date) return false;

    return isSameDay(currentDate, date) && getHours(currentDate) === this.dueDate.deliveryTime;
  }

  /**
   * メッセージ記入者に配信済みかどうかを返すメソッド
   * @param employee 入社者
   * @returns boolean
   */
  isDeliveredByEmployee(employee: Employee): boolean {
    if (!this.deliveryDate) return false;
    const deliveryDate = this.deliveryDate.calculateDateByEmployee(employee);
    if (!deliveryDate) return false;

    return deliveryDate <= new Date();
  }
}
