import { Box } from "@material-ui/core";
import { Employee, SlackUser } from "@onn/common";
import React, { useState, useCallback, FC, useMemo, useEffect } from "react";
import styled from "styled-components";

import { SlackUserList, SlackUserSearchForm } from "../../slackUsers";
import { SelectSingleEmployeesFormByPlatform } from "../SelectEmployeesFormByPlatform";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { useAllEmployees } from "~/hooks/employee";
import { useSlackUsers } from "~/hooks/slackUser";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (newMentorId?: string, emailForInvite?: string) => Promise<void>;
  newHire: Employee;
  mentor?: Employee;
  supportMembers?: Employee[];
};

export const AssignMentorModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  newHire,
  supportMembers,
  mentor,
}) => {
  const { tenant } = useTenant();
  const { slackUsers, loadingSlackUsers } = useSlackUsers();
  const { allEmployees } = useAllEmployees();

  const filterSlackUser = useCallback(
    (slackUsers: SlackUser[]) => {
      // 自分自身、サポートメンバーのidから成る配列
      const notAllowAssignIds = [
        newHire.slackUserId,
        ...(supportMembers || []).map((sm) => sm.slackUserId),
      ];

      // 自分自身 and サポートメンバーは選択肢から除外する
      const notAssignedSlackUsers = slackUsers.filter(
        (slackUser) => !notAllowAssignIds.includes(slackUser.slackUserId)
      );

      return notAssignedSlackUsers;
    },
    [newHire.slackUserId, supportMembers]
  );

  const [displaySlackUsers, setDisplaySlackUsers] = useState<SlackUser[]>([]); // クライアント検索処理で再構成される表示用の配列
  useEffect(() => {
    setDisplaySlackUsers(filterSlackUser(slackUsers));
  }, [filterSlackUser, slackUsers]);

  const [selectedSlackUser, setSelectedSlackUser] = useState<SlackUser | undefined>();
  useEffect(() => {
    setSelectedSlackUser(
      mentor ? slackUsers.find((su) => su.slackUserId === mentor.slackUserId) : undefined
    );
  }, [filterSlackUser, mentor, slackUsers]);

  const notAllowEmails = useMemo(() => {
    // 自分自身、サポートメンバーのemailから成る配列
    return [
      newHire.email,
      ...(supportMembers || []).map((sm) => sm.email),
      ...(mentor?.email ? [mentor?.email] : []),
    ];
  }, [mentor?.email, newHire.email, supportMembers]);

  const [selectedEmail, setSelectedEmail] = useState<string | undefined>();

  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    let newMentor: Employee | undefined = undefined;
    let emailForInvite: string | undefined = undefined;
    if (tenant?.isIntegratedSlack && selectedSlackUser) {
      const employee = allEmployees.find((v) => v.slackUserId === selectedSlackUser.slackUserId);
      if (employee) {
        newMentor = employee;
      } else {
        emailForInvite = selectedSlackUser.email;
      }
    } else if (selectedEmail) {
      newMentor = allEmployees.find((v) => v.email === selectedEmail);
      emailForInvite = selectedEmail;
    }

    setSending(true);
    await onSubmit(newMentor?.id, emailForInvite)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [
    allEmployees,
    onCancel,
    onSubmit,
    selectedEmail,
    selectedSlackUser,
    tenant?.isIntegratedSlack,
  ]);

  const buildButtonText = () =>
    newHire.mentorUserId
      ? sending
        ? "変更中"
        : "バディを変更する"
      : sending
      ? "設定中"
      : "バディを設定する";

  return (
    <Modal
      open={open}
      title="バディ設定"
      content={
        <Box>
          <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="16px">
            <Box width="100%">
              <Box textAlign="center">
                <UserIcon
                  username={newHire.getName()}
                  profileIconImageUrl={newHire.profileIconImageUrl}
                  size="medium"
                  borderColor="primary"
                />
                <Box pb={2} />
                <Typography variant="body2" align="center">
                  {`${newHire.getName()}さんのバディを\n設定してください`}
                </Typography>
              </Box>
            </Box>
            <Box width="100%">
              {tenant && tenant?.isIntegratedSlack ? (
                <>
                  <SlackUserSearchForm
                    variant="standard"
                    showPlaceholder
                    slackUsers={filterSlackUser(slackUsers)}
                    onSearch={(results) => setDisplaySlackUsers(results)}
                  />
                  <SlackUserList
                    slackUsers={displaySlackUsers}
                    selectedSlackUser={selectedSlackUser}
                    onSelect={setSelectedSlackUser}
                    isLoading={loadingSlackUsers}
                  />
                </>
              ) : (
                <SelectSingleEmployeesFormByPlatform
                  notAllowEmails={notAllowEmails}
                  selectedEmail={selectedEmail}
                  onSelectEmail={setSelectedEmail}
                />
              )}
            </Box>
          </Box>
          {tenant && !tenant.isIntegratedSlack && (
            <Typography variant="caption" color="textSecondary" align="center" display="block">
              Onnに登録されていないメンバーを設定したい場合は、
              <StyledPointerTypography
                display="inline"
                variant="caption"
                bold
                color="primary"
                onClick={() => window.open("/settings/admin", "_blank")}
              >
                こちら
              </StyledPointerTypography>
              から招待を行ってください。
            </Typography>
          )}
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={(!selectedEmail && !selectedSlackUser) || sending}
          >
            {buildButtonText()}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
