import { OnboardingGeneralTask, Employee } from "@onn/common";
import React, { useMemo, FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/uiParts";
import { useEmployeeModals } from "~/hooks/employee";

type Props = {
  onboardingTask: OnboardingGeneralTask;
  newHire: Employee;
  disabled?: boolean;
  onClickGeneralTaskButton?: () => void;
};

export const GeneralTaskButton: FC<Props> = ({
  onboardingTask,
  newHire,
  disabled,
  onClickGeneralTaskButton,
}) => {
  const {
    handleOpenManageMentorAndSupportMemberModal,
    handleOpenJoinDateModal,
    handleOpenIntegrateSlackModal,
  } = useEmployeeModals();
  const navigate = useNavigate();

  const onboardingGeneralTaskMap: Record<
    OnboardingGeneralTask["actionId"],
    { text: string; onClick: () => void }
  > = useMemo(() => {
    return {
      SET_JOIN_AT: {
        text: "入社日設定",
        onClick: () => handleOpenJoinDateModal(newHire),
      },
      ASSIGN_MENTOR_OR_SUPPORT_MEMBER: {
        text: "受け入れチーム設定",
        onClick: () => handleOpenManageMentorAndSupportMemberModal(newHire, undefined, []),
      },
      INTEGRATE_SLACK: {
        text: "Slackアカウント連携",
        onClick: () => handleOpenIntegrateSlackModal(newHire),
      },
      CREATE_ACCOUNT: {
        text: "アカウント登録",
        // このコンポーネントを表示するタイミングでは常に完了済みなので関数を定義しない
        onClick: () => void 0,
      },
      REGISTER_PROFILE: {
        text: "自己紹介記入",
        onClick: () => navigate("/profile"),
      },
    };
  }, [
    handleOpenIntegrateSlackModal,
    handleOpenJoinDateModal,
    handleOpenManageMentorAndSupportMemberModal,
    navigate,
    newHire,
  ]);

  if (onboardingTask.status === "COMPLETED") {
    return (
      <Button
        variant="contained"
        fullWidth
        borderRadius="circle"
        color={onboardingTask.status === "COMPLETED" ? "default" : "primary"}
        disabled
      >
        対応完了
      </Button>
    );
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      borderRadius="circle"
      fullWidth
      onClick={() => {
        onboardingGeneralTaskMap[onboardingTask.actionId].onClick();
        onClickGeneralTaskButton && onClickGeneralTaskButton();
      }}
      disabled={disabled}
    >
      {onboardingGeneralTaskMap[onboardingTask.actionId].text}
    </Button>
  );
};
