import { Box } from "@material-ui/core";
import React, { useCallback, useState, FC } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal, Icon } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  username: string;
  taskName: string;
  profileIconImageUrl?: string;
};

export const DeleteTaskModal: FC<Props> = ({
  open,
  onSubmit,
  onCancel,
  profileIconImageUrl,
  username,
  taskName,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await onSubmit()
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [onCancel, onSubmit]);

  const Content = (
    <Box textAlign="center">
      <Typography variant="body1">{username} さんの以下のタスクを削除しますか？</Typography>
      <StyledTaskItem display="flex" borderRadius="10px" mt="32px" p="24px" alignItems="center">
        <Icon icon="line" size="sm" color="lightGrey" />
        <Box display="inline-block" width="8px" />
        <StyledTypography color="textSecondary">{taskName}</StyledTypography>
        <Box ml="auto">
          <UserIcon
            profileIconImageUrl={profileIconImageUrl}
            username={username}
            size="small"
            circular={true}
          />
        </Box>
      </StyledTaskItem>
      <Box mt="32px" textAlign="center">
        <Typography variant="caption">この操作は取り消すことができません。</Typography>
      </Box>
    </Box>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        削除
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="タスク削除" content={Content} footer={Footer} onCancel={onCancel} />
  );
};

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const StyledTaskItem = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
