import { OnboardingGeneralTask } from "@onn/common";
import { useState, useCallback } from "react";
import { useSWRConfig } from "swr";

import { generateUseOnboardingTasksKeys } from "./useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "./useOnboardingTasksByEmployeeId";

import { CompleteGeneralTaskUseCase } from "~/service/usecases/onboardingTask/CompleteGeneralTaskUseCase";
import { captureException } from "~/util";

const completeGeneralTaskUseCase = new CompleteGeneralTaskUseCase();

/**
 * onboardingGeneralTaskを完了するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns completeOnboardingGeneralTask
 */
export const useCompleteOnboardingGeneralTask = (): {
  isLoading: boolean;
  completeOnboardingGeneralTask: (
    actionId: OnboardingGeneralTask["actionId"],
    employeeId: string,
    tenantId: string
  ) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useSWRConfig();

  const completeOnboardingGeneralTask = useCallback(
    async (actionId: OnboardingGeneralTask["actionId"], employeeId: string, tenantId: string) => {
      setIsLoading(true);
      await completeGeneralTaskUseCase
        .execute(actionId, employeeId)
        .then(() => {
          // 入社者詳細とタスクリストで表示されるタスク全てを再取得している
          mutate(generateUseOnboardingTasksByEmployeeId(employeeId));
          mutate(generateUseOnboardingTasksKeys(tenantId));
        })
        .catch((e) => {
          // 更新に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
          captureException({
            error: e,
            tags: { type: "completeGeneralTaskUseCase.execute" },
          });
        });
    },
    [mutate]
  );

  return { isLoading, completeOnboardingGeneralTask };
};
