import {
  displayAssigneeRoleMap,
  OnboardingExperienceTask,
  OnboardingExperienceTaskType,
  Employee,
  SlackUser,
} from "@onn/common";
import React, { FC, ComponentProps } from "react";
import styled from "styled-components";

import { Typography, UserIconGroup } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = {
  tasks: OnboardingExperienceTaskType[];
  assigneeRole: OnboardingExperienceTaskType["assigneeRole"];
  assigneeEmployees: Employee[];
  slackUsers: SlackUser[];
  allEmployeesWithDeleted: Employee[];
};

type UserInfo = ComponentProps<typeof UserIconGroup>["usersInfo"][number];

const backgroundColorMap: Record<OnboardingExperienceTask["assigneeRole"], string> = {
  ADMIN: theme.palette.secondary.main,
  MENTOR: theme.palette.primary.main,
  SUPPORT_MEMBER: theme.palette.blue.main,
  NEW_HIRE: theme.palette.background.default,
  BY_NAME: theme.palette.background.default,
  NOT_SET: theme.palette.grey[300],
};

export const AssigneeEmployees: FC<Props> = React.memo(
  ({ tasks, assigneeRole, assigneeEmployees, slackUsers, allEmployeesWithDeleted }) => {
    const task = tasks[0] as (typeof tasks)[number];
    return assigneeRole === "BY_NAME" ? (
      <UserIconGroup
        usersInfo={[
          ...assigneeEmployees.map(
            (employee) =>
              ({
                username: employee.getName(),
                profileIconImageUrl: employee.profileIconImageUrl,
                borderColor: "grey",
              } as UserInfo)
          ),
          ...(task.emailsWithoutOnnAccount
            ? task.emailsWithoutOnnAccount.flatMap((email) => {
                const employee = allEmployeesWithDeleted.find((v) => v.email === email);
                const slackUser = slackUsers.find((v) => v.email === email);

                if (employee) {
                  return {
                    username: employee?.getName(),
                    profileIconImageUrl: employee?.profileIconImageUrl,
                    borderColor: "grey",
                  } as UserInfo;
                } else if (slackUser) {
                  return {
                    username: slackUser.real_name,
                    profileIconImageUrl: slackUser.image_192,
                    borderColor: "grey",
                  } as UserInfo;
                }

                return [];
              })
            : []),
        ]}
        tooltip
      />
    ) : (
      <StyledLabel
        $backgroundColor={backgroundColorMap[assigneeRole]}
        $fontColor={assigneeRole === "NEW_HIRE" ? theme.palette.primary.main : "white"}
        $isDisplayBorder={assigneeRole === "NEW_HIRE"}
      >
        <Typography variant="body2" bold noWrap>
          {displayAssigneeRoleMap[assigneeRole]}
        </Typography>
      </StyledLabel>
    );
  }
);

const StyledLabel = styled.span<{
  $backgroundColor: string;
  $fontColor: string;
  $isDisplayBorder: boolean;
}>`
  .MuiTypography-root {
    line-height: 1;
  }
  padding: 8px 16px;
  border-radius: 5px;
  color: ${(props) => props.$fontColor};
  background-color: ${(props) => props.$backgroundColor};
  ${(props) =>
    props.$isDisplayBorder ? `border: 2px solid  ${props.theme.palette.primary.main}` : ""};
`;
