import { Box } from "@material-ui/core";
import { MessageContent } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { UserIconWithLabel, Typography, Paper, Linkify } from "~/components/uiParts";

type Props = {
  name: MessageContent["name"];
  iconPath: MessageContent["iconPath"];
  department: MessageContent["department"];
  message: MessageContent["message"];
};

export const MessagePaper: FC<Props> = React.memo(({ name, iconPath, department, message }) => {
  return (
    <Paper border="primary" isShowBgImage>
      <StyledUserIconWithLabel name={name} iconPath={iconPath} size="medium" />
      <Box height="16px" />
      {department && (
        <>
          <Typography variant="caption" color="textSecondary">
            {department}
          </Typography>
          <Box height="16px" />
        </>
      )}
      <Linkify>
        <StyledTypography color="textPrimary">{message}</StyledTypography>
      </Linkify>
    </Paper>
  );
});

const StyledUserIconWithLabel = styled(UserIconWithLabel)`
  .MuiTypography-root {
    white-space: normal;
  }
`;

const StyledTypography = styled(Typography)`
  word-break: break-all;
`;
