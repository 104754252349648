import { APISchema } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

/**
 * tenantIdをもとにアンケートの配信日を返すswr
 * @param tenantId tenantのid
 */
export const useSurveySendAts = (tenantId: string) => {
  const keyObj = { endpoint: "/get_survey_send_ats", tenantId } as const;

  return useSWR<string[]>(keyObj, async ({ endpoint, tenantId }: typeof keyObj) => {
    const queryParam: APISchema["/get_survey_send_ats"]["GET"]["query"] = { tenantId };
    const response = await apiClient.get(endpoint, queryParam);
    return response.data;
  });
};
