import { Box, ListItem } from "@material-ui/core";
import { SlackUser } from "@onn/common";
import React, { FC } from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { UserIcon, Typography, FormControlLabel, Checkbox } from "~/components/uiParts";

type Props = ListRowProps & {
  slackUsers: SlackUser[];
  selectedSlackUsers: SlackUser[];
  onSelectSlackUser: (slackUser: SlackUser) => void;
};

export const CheckboxRowRenderer: FC<Props> = ({
  slackUsers,
  selectedSlackUsers,
  onSelectSlackUser,
  ...props
}) => {
  const slackUser = slackUsers[props.index] as (typeof slackUsers)[number];

  return (
    <Box
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
    >
      <ListItem alignItems="flex-start">
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={selectedSlackUsers.includes(slackUser)}
              onChange={() => onSelectSlackUser(slackUser)}
              name={slackUser.slackUserId}
              value={slackUser.slackUserId}
            />
          }
          label={
            <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
              <UserIcon
                username={slackUser.real_name}
                profileIconImageUrl={slackUser.image_192}
                size="extraSmall"
                circular
              />
              <Typography variant="caption" color="textSecondary" noWrap>
                {slackUser.real_name}
              </Typography>
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
