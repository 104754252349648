import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import "firebase/compat/auth";
import "firebase/compat/firestore";

const prodConfig = {
  projectId: "onn-production",
  authDomain: "onn-production.firebaseapp.com",
  databaseURL: "https://onn-production.firebaseio.com",
  storageBucket: "onn-production.appspot.com",
  messagingSenderId: "981680166540",
  measurementId: "G-TDVWV62ST5",
  publicStorageUrl: "gs://onn-production.appspot.com",
  privateStorageUrl: "gs://onn-production_private",
};

const stgConfigForMidCareer = {
  apiKey: "AIzaSyCLDV3raj4tPH2KK6ZOmGtjF1UBBA-oQJ8",
  projectId: "onn-midcareer-staging",
  authDomain: "onn-midcareer-staging.firebaseapp.com",
  storageBucket: "onn-midcareer-staging.appspot.com",
  messagingSenderId: "642676176649",
  appId: "1:642676176649:web:ba90753b71d6e4e3e005d8",
  measurementId: "G-PTV07EFMMS",
};

const stgConfig = {
  projectId: "onn-staging",
  authDomain: "onn-staging.firebaseapp.com",
  databaseURL: "https://onn-staging.firebaseio.com",
  storageBucket: "onn-staging.appspot.com",
  messagingSenderId: "627055900285",
  measurementId: "G-ECYB94TJ7T",
  publicStorageUrl: "gs://onn-staging.appspot.com",
  privateStorageUrl: "gs://onn-staging_private",
};

const getConfig = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      return prodConfig;
    case "staging":
      return stgConfig;
    case "localhost":
      // localはstgのconfigで初期化する
      return stgConfig;
    case "test":
      // 単体テストはstgのconfigで初期化する
      return stgConfig;

    default:
      throw new Error(`${process.env.NODE_ENV} is an unexpected environment.`);
  }
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  ...getConfig(),
};

const _appForMidcareer = initializeApp(stgConfigForMidCareer, "midcareer");
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, "asia-northeast1");
export const storage = {
  /**
   * ユーザーのプロフィール画像や、アプリケーションの背景画像等、パブリックに公開されるファイルを管理するバケットとして利用している。
   */
  public: getStorage(app, getConfig().publicStorageUrl),
  /**
   * テナントや従業員などの単位でアクセスの制限を必要とするファイルを管理するバケットとして利用している。
   */
  private: getStorage(app, getConfig().privateStorageUrl),
} as const;

if (process.env.FIRESTORE_ENV === "emulator") {
  connectFirestoreEmulator(firestore, "localhost", 8888);
  connectStorageEmulator(storage.private, "localhost", 9199);
  connectStorageEmulator(storage.public, "localhost", 9199);
}

if (process.env.NODE_ENV === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
