// NOTE: このファイルはProviderのため様々な上位コンポーネントから呼び出されます 循環参照を避けるために、直接importしてください。
import React, { FC, useState, createContext, ReactNode, useContext, useCallback } from "react";

import { ChangeAssigneeModal } from "../domains/contactRooms/ChangeAssigneeModal";
import {
  AssignNewGraduateFollowersModal,
  AssignNewGraduateMentorModal,
  ChangeNewGraduateMentorConfirmModal,
  DismissNewGraduateFollowerConfirmModal,
  ManageMentorAndSupportMemberModal,
} from "../domains/employees";
import { InviteMidCarrierWithCSVModal } from "../domains/employees/InviteMidCarrierWithCSVModal";
import { SPEmployeeDetailModal } from "../domains/employees/SPEmployeeDetailModal/SPEmployeeDetailModal";
import { UpdateEventAssigneeModal, EventDeleteConfirmModal } from "../domains/events";
import { EventBulkRemindModal } from "../domains/events/EventBulkRemindModal";
import { ManageEditableEmployeesModal } from "../domains/onboardingExperience";
import { AddEditableEmployeesModal } from "../domains/onboardingExperience/AddEditableEmployeesModal";

import { EditOnboardingExperienceGeneralProcessAssigneeModal } from "../domains/onboardingExperience/EditOnboardingExperienceGeneralProcessAssigneeModal";
import { SelectOnboardingExperiencesForPreviewModal } from "../domains/onboardingExperience/SelectOnboardingExperiencesForPreviewModal";
import { ViewOnboardingExperienceGeneralTaskModal } from "../domains/onboardingExperience/ViewOnboardingExperienceGeneralTaskModal";
import { SelectTemplateTypeModal } from "../domains/onboardingExperienceTaskTemplate";
import { ConfirmDeleteTagModal } from "../domains/tenantSettings/ConfirmDeleteTagModal";

import { ModalTypes } from "./ModalTypes";

import {
  ConfirmPostMessageModal,
  EditContactMessageTemplateModal,
  DeleteContactMessageTemplateModal,
} from "~/components/domains/contactRooms";
import { AddFollowersModal } from "~/components/domains/contactRooms/AddFollowersModal";
import { ManageContactTeamModal } from "~/components/domains/contactRooms/ManageContactTeamModal";
import { ChangeDepartmentModal } from "~/components/domains/departments/ChangeDepartmentModal";
import { ConfirmDeleteDepartmentModal } from "~/components/domains/departments/ConfirmDeleteDepartmentModal";
import { EditDepartmentModal } from "~/components/domains/departments/EditDepartmentModal";
import { AddAdminModal } from "~/components/domains/employees/AddAdminModal";
import { AddNewGraduateByCSVModal } from "~/components/domains/employees/AddNewGraduateByCSVModal";
import { AssignMentorModal } from "~/components/domains/employees/AssignMentorModal";
import { AssignSupportMembersModal } from "~/components/domains/employees/AssignSupportMembersModal";
import { ChangeEmployeeNameModal } from "~/components/domains/employees/ChangeEmployeeNameModal";
import { ChangeMentorConfirmModal } from "~/components/domains/employees/ChangeMentorConfirmModal";
import { ChangeRoleModal } from "~/components/domains/employees/ChangeRoleModal";
import { ConfirmChangeRoleToAdminModal } from "~/components/domains/employees/ConfirmChangeRoleToAdminModal";
import { DeleteMemberModal } from "~/components/domains/employees/DeleteMemberModal";
import { DismissSupportMemberConfirmModal } from "~/components/domains/employees/DismissSupportMemberConfirmModal";
import { IntegrateSlackModal } from "~/components/domains/employees/IntegrateSlackModal";
import { InviteMidCarrierModal } from "~/components/domains/employees/InviteMidCarrierModal";
import { JoinDateModal } from "~/components/domains/employees/JoinDateModal";
import { ResendInvitationModal } from "~/components/domains/employees/ResendInvitationModal";
import { SegregateLineModal } from "~/components/domains/employees/SegregateLineModal";
import { ConfirmAddNewGraduateOnnEventModal } from "~/components/domains/events/ConfirmAddNewGraduateOnnEventModal";
import { DeleteOnboardingExperienceModal } from "~/components/domains/onboardingExperience/DeleteOnboardingExperienceModal";
import { DeleteOnboardingExperienceProcessModal } from "~/components/domains/onboardingExperience/DeleteOnboardingExperienceProcessModal";
import { EditOnboardingExperienceModal } from "~/components/domains/onboardingExperience/EditOnboardingExperienceModal";
import { EditOnboardingExperienceTaskModal } from "~/components/domains/onboardingExperience/EditOnboardingExperienceTaskModal";
import { DeleteOnboardingTaskTemplateModal } from "~/components/domains/onboardingExperienceTaskTemplate/DeleteOnboardingTaskTemplateModal";
import { AddRequestMessageTaskModal } from "~/components/domains/onboardingTask/AddRequestMessageTaskModal";
import { DeleteMultipleTasksModal } from "~/components/domains/onboardingTask/DeleteMultipleTasksModal";
import { DeleteTaskModal } from "~/components/domains/onboardingTask/DeleteTaskModal";
import { EditDueDateModal } from "~/components/domains/onboardingTask/EditDueDateModal";
import { EditTaskModal } from "~/components/domains/onboardingTask/EditTaskModal";
import { GenerateTasksFormExperienceModal } from "~/components/domains/onboardingTask/GenerateTasksFormExperienceModal";
import { ManageMessageTaskModal } from "~/components/domains/onboardingTask/ManageMessageTaskModal";
import { SegregateSlackModal } from "~/components/domains/tenant/SegregateSlackModal";

type ModalProps = ModalTypes[keyof ModalTypes] | undefined | null;

const DURATION = 195; // モーダルの開閉のアニメーション時間

export const ModalContext = createContext<{
  modal: ModalProps;
  open: boolean;
  handleModal: (props: ModalProps) => void;
}>({ modal: undefined, open: false, handleModal: () => void 0 });

export const ModalProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [modal, setModal] = useState<ModalProps>();
  const [open, setOpen] = useState(false);

  const handleModal = useCallback((args: ModalProps) => {
    if (!args) {
      // モーダルを閉じるときは先にopenをfalsyにして閉じるアニメーションを実行する
      setOpen(false);
      // アニメーションが終了したらmodalをnullにしてコンポーネントを破棄する
      setTimeout(() => setModal(null), DURATION);
    } else {
      setModal(args);
      setOpen(true);
    }
  }, []);

  return (
    <ModalContext.Provider value={{ modal, open, handleModal }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

const Modal = () => {
  const { modal, open, handleModal } = useContext(ModalContext);
  const handleCancel = useCallback(() => handleModal(null), [handleModal]);

  if (!modal) return null;

  switch (modal.name) {
    case "addAdminModal": {
      return <AddAdminModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addFollowersModal": {
      return <AddFollowersModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addEditableEmployeesModal": {
      return <AddEditableEmployeesModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addNewGraduateByCSVModal": {
      return <AddNewGraduateByCSVModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addRequestMessageTaskModal": {
      return <AddRequestMessageTaskModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignMentorModal": {
      return <AssignMentorModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignNewGraduateMentorModal": {
      return <AssignNewGraduateMentorModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignSupportMembersModal": {
      return <AssignSupportMembersModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignNewGraduateFollowersModal": {
      return (
        <AssignNewGraduateFollowersModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "changeAssigneeModal": {
      return <ChangeAssigneeModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "changeEmployeeNameModal": {
      return <ChangeEmployeeNameModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "changeMentorConfirmModal": {
      return <ChangeMentorConfirmModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "changeNewGraduateMentorConfirmModal": {
      return (
        <ChangeNewGraduateMentorConfirmModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "changeRoleModal": {
      return <ChangeRoleModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmPostMessageModal": {
      return <ConfirmPostMessageModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmChangeRoleToAdminModal": {
      return <ConfirmChangeRoleToAdminModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmDeleteDepartmentModal": {
      return <ConfirmDeleteDepartmentModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmDeleteTagModal": {
      return <ConfirmDeleteTagModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmAddNewGraduateOnnEventModal": {
      return (
        <ConfirmAddNewGraduateOnnEventModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "deleteContactMessageTemplateModal": {
      return (
        <DeleteContactMessageTemplateModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "deleteMemberModal": {
      return <DeleteMemberModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "deleteMultipleTasksModal": {
      return <DeleteMultipleTasksModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "deleteTaskModal": {
      return <DeleteTaskModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "deleteOnboardingExperienceModal": {
      return (
        <DeleteOnboardingExperienceModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "deleteOnboardingExperienceProcessModal": {
      return (
        <DeleteOnboardingExperienceProcessModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "deleteOnboardingTaskTemplateModal": {
      return (
        <DeleteOnboardingTaskTemplateModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "dismissSupportMemberConfirmModal": {
      return (
        <DismissSupportMemberConfirmModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "dismissNewGraduateFollowerConfirmModal": {
      return (
        <DismissNewGraduateFollowerConfirmModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "editContactMessageTemplateModal": {
      return (
        <EditContactMessageTemplateModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "editTaskModal": {
      return <EditTaskModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "editDepartmentModal": {
      return <EditDepartmentModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "editDueDateModal": {
      return <EditDueDateModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "editOnboardingExperienceModal": {
      return <EditOnboardingExperienceModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "editOnboardingExperienceTaskModal": {
      return (
        <EditOnboardingExperienceTaskModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "editOnboardingExperienceGeneralProcessAssigneeModal": {
      return (
        <EditOnboardingExperienceGeneralProcessAssigneeModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "generateTasksFormExperienceModal": {
      return (
        <GenerateTasksFormExperienceModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "segregateLineModal": {
      return <SegregateLineModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "integrateSlackModal": {
      return <IntegrateSlackModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "inviteMidCarrierModal": {
      return <InviteMidCarrierModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "inviteMidCarrierWithCSVModal": {
      return <InviteMidCarrierWithCSVModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "joinDateModal": {
      return <JoinDateModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "manageContactTeamModal": {
      return <ManageContactTeamModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "manageEditableEmployeesModal": {
      return <ManageEditableEmployeesModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "manageMentorAndSupportMemberModal": {
      return (
        <ManageMentorAndSupportMemberModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "manageMessageTaskModal": {
      return <ManageMessageTaskModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "resendInvitationModal": {
      return <ResendInvitationModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "segregateSlackModal": {
      return <SegregateSlackModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "viewOnboardingExperienceGeneralTaskModal": {
      return (
        <ViewOnboardingExperienceGeneralTaskModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "changeDepartmentModal": {
      return <ChangeDepartmentModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "selectOnboardingExperiencesForPreviewModal": {
      return (
        <SelectOnboardingExperiencesForPreviewModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "spEmployeeDetailModal": {
      return <SPEmployeeDetailModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "selectTemplateTypeModal": {
      return <SelectTemplateTypeModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "eventBulkRemindModal": {
      return <EventBulkRemindModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "eventDeleteConfirmModal": {
      return <EventDeleteConfirmModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "updateEventAssigneeModal": {
      return <UpdateEventAssigneeModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    default:
      throw new Error("modalのnameが不正です");
  }
};
