import { useCallback, useState } from "react";

import { useSnackbar } from "../shared/useSnackbar";

import { DeleteOnboardingExperienceUseCase } from "~/service/usecases/onboardingExperience";
import { captureException } from "~/util";

const deleteOnboardingExperienceUseCase = new DeleteOnboardingExperienceUseCase();

/**
 * onboardingExperienceを削除する関数を提供する
 */
export const useDeleteOnboardingExperience = (): {
  isLoading: boolean;
  deleteOnboardingExperience: (onboardingExperienceId: string) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * @param {string} onboardingExperienceId
   */
  const deleteOnboardingExperience = useCallback(
    async (onboardingExperienceId: string) => {
      setIsLoading(true);
      await deleteOnboardingExperienceUseCase
        .execute(onboardingExperienceId)
        .then(() => enqueueSnackbar("エクスペリエンスを削除しました", { variant: "success" }))
        .catch((e) => {
          enqueueSnackbar("エクスペリエンスの削除に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useDeleteOnboardingExperience:deleteOnboardingExperience",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return { isLoading, deleteOnboardingExperience };
};
