import { Box } from "@material-ui/core";
import React, { useMemo, useState, FC } from "react";
import styled from "styled-components";

import { Button, Modal, TextField, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type ModeType = "create" | "edit" | "duplicate";

const titleTextMap = {
  create: "作成",
  edit: "編集",
  duplicate: "複製",
} as const;

type Props = {
  open: boolean;
  onCancel: () => void;
  processTitle?: string;
  mode: ModeType;
  onSubmit: (title: string) => void;
};

export const EditOnboardingExperienceModal: FC<Props> = ({
  open,
  onCancel,
  processTitle,
  mode,
  onSubmit,
}) => {
  const [newTitle, setNewTitle] = useState(processTitle || "");

  const content = useMemo(
    () => (
      <>
        <Typography variant="body2" bold color="textSecondary">
          タイトル
        </Typography>
        <TextField
          defaultValue={newTitle}
          variant="outlined"
          fullWidth
          autoFocus
          onChange={(e) => setNewTitle(e.target.value)}
        />
      </>
    ),
    [newTitle]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(newTitle);
            onCancel();
          }}
          disabled={!newTitle?.trim()}
        >
          {titleTextMap[mode]}
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel, newTitle, mode, onSubmit]
  );

  return (
    <Modal
      title={`エクスペリエンス${titleTextMap[mode]}`}
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
