import React, { FC, ReactNode } from "react";
import { Outlet, useRoutes } from "react-router-dom";

import { SelectLoginTenant } from "./selectLoginTenant/SelectLoginTenant";

import { OnlyAuthenticationLayout } from "~/components/layouts/OnlyAuthenticationLayout";
type Props = {
  children: ReactNode;
};
export const AuthorizationRouterWrapper: FC<Props> = ({ children }) => {
  return useRoutes([
    {
      // １候補者に複数テナント紐づく場合に、テナントを選択する画面を表示する
      path: "/select-tenant",
      element: (
        <OnlyAuthenticationLayout>
          <Outlet />
        </OnlyAuthenticationLayout>
      ),
      children: [
        {
          index: true,
          element: <SelectLoginTenant />,
        },
      ],
    },
    {
      path: "*",
      element: children,
    },
  ]);
};
