import { Comment, ContentText, Role, Department } from "@onn/common";

import { CommentRepository } from "../../infrastructure/api/commentRepository";
import { EmployeeRepository } from "../../infrastructure/api/employeeRepository";

import { DepartmentRepository } from "~/infrastructure/api/departmentRepository";
import { functionOperator } from "~/infrastructure/api/functionOperator";
import { TransactionRepository } from "~/infrastructure/api/transactionRepository";

const MENTION_USER_REGEX = /(@\[.*?\]\(.*?\))/g;
const MENTION_USER_ID_REGEX = /\(.*?\)/g;
const MENTION_USER_NAME_REGEX = /\[.*?\]/g;

const commentRepository = new CommentRepository();
const employeeRepository = new EmployeeRepository();
const transactionRepository = new TransactionRepository();
const departmentRepositrory = new DepartmentRepository();

const notifyNewComment = async (commentId: string) =>
  await functionOperator.httpsCall("notifyNewComment", { commentId });

export class CommentUseCase {
  async whereByTransactionIds(transactionIds: string[], tenantId: string): Promise<Comment[]> {
    return await commentRepository.whereByTransactionIds(transactionIds, tenantId);
  }

  async add({
    contentString,
    transactionId,
    employeeId,
    tenantId,
  }: {
    contentString: string;
    transactionId: string;
    employeeId: string;
    tenantId: string;
  }): Promise<void> {
    if (!contentString || !transactionId || !employeeId) {
      throw new Error("入力値が不正です");
    }

    const isAccessible = await this.isAccessibleEmployee({ transactionId, employeeId });

    if (!isAccessible) {
      throw new Error("コメントを追加する権限がありません");
    }

    const { commentId } = await commentRepository.add({
      content: convertContentStringToCommentContent(contentString),
      transactionId,
      employeeId,
      tenantId,
    });
    // Slack上にコメント通知するだけなので、レスポンスは待たない
    notifyNewComment(commentId);
  }

  async update({
    commentId,
    transactionId,
    employeeId,
    contentString,
  }: {
    commentId: string;
    transactionId: string;
    employeeId: string;
    contentString: string;
  }): Promise<void> {
    if (!commentId || !contentString || !transactionId || !employeeId) {
      throw new Error("入力値が不正です");
    }

    const isAccessible = await this.isAccessibleEmployee({ transactionId, employeeId });

    if (!isAccessible) {
      throw new Error("コメントを更新する権限がありません");
    }

    const [targetComment, requestedUser] = await Promise.all([
      commentRepository.findById(commentId),
      employeeRepository.findById(employeeId),
    ]);

    if (!requestedUser) {
      throw new Error("コメントを更新する権限がありません");
    }

    if (!targetComment.editable(requestedUser)) {
      throw new Error("自分以外のコメントは更新できません");
    }

    await commentRepository.update({
      commentId,
      content: convertContentStringToCommentContent(contentString),
    });
  }

  async delete({
    commentId,
    transactionId,
    employeeId,
  }: {
    commentId: string;
    transactionId: string;
    employeeId: string;
  }): Promise<void> {
    if (!commentId || !transactionId || !employeeId) {
      throw new Error("入力値が不正です");
    }

    const isAccessible = await this.isAccessibleEmployee({ transactionId, employeeId });

    if (!isAccessible) {
      throw new Error("コメントを削除する権限がありません");
    }

    const [targetComment, requestedUser] = await Promise.all([
      commentRepository.findById(commentId),
      employeeRepository.findById(employeeId),
    ]);

    if (!requestedUser) {
      throw new Error("コメントを削除する権限がありません");
    }

    if (!targetComment.deletable(requestedUser)) {
      throw new Error("自分以外のコメントは削除できません");
    }

    await commentRepository.delete(commentId);
  }

  /**
   * コメントに対する操作が可能な権限を持つかどうかを返す
   * バディ + サポートメンバー + 管理者 + 管理者のユーザーのみtrueを返す
   */
  async isAccessibleEmployee({
    transactionId,
    employeeId,
  }: {
    transactionId: string;
    employeeId: string;
  }): Promise<boolean> {
    const transaction = await transactionRepository.findById(transactionId);
    const newHireEmployee = await employeeRepository.findById(transaction.employeeId);

    if (!newHireEmployee) {
      throw new Error("コメントに対する操作の権限がありません");
    }

    const fullAccessEmployees = await employeeRepository.findByRole(
      newHireEmployee.tenantId,
      Role.ADMIN
    );
    const departments = await departmentRepositrory.findByTenantId(newHireEmployee.tenantId);
    // 入社者が属する部署の部門管理者(入社者の所属する0以上の部署と、部門管理者が管理する0以上の部署のいずれかが一致していれば管理対象とする)
    const departmentAdmins = (
      await employeeRepository.findByRole(newHireEmployee.tenantId, Role.DEPARTMENT_ADMIN)
    ).filter((depAdmin) =>
      Department.getChildIds(depAdmin.departmentIds, departments).some((id) =>
        newHireEmployee.departmentIds.includes(id)
      )
    );

    // 操作者は削除されていないバディである
    const notDeletedMentor =
      newHireEmployee.mentorUserId &&
      newHireEmployee.deleted !== true &&
      employeeId === newHireEmployee.mentorUserId;

    // 操作者は削除されていないサポートメンバーである
    const notDeletedSupportMember =
      newHireEmployee.supportMemberEmployeeIds &&
      newHireEmployee.deleted !== true &&
      newHireEmployee.supportMemberEmployeeIds.includes(employeeId);

    // 操作者は削除されていない管理者である
    const notDeletedFullAccessEmployee = fullAccessEmployees.some(
      (employee) => employee.id === employeeId
    );

    // 操作者は削除されていない入社者の部署の部門管理者である
    const notDeletedDepartmentAdmin = departmentAdmins.some(
      (depAdmin) => depAdmin.id === employeeId
    );

    return (
      notDeletedMentor ||
      notDeletedSupportMember ||
      notDeletedFullAccessEmployee ||
      notDeletedDepartmentAdmin
    );
  }
}

const convertContentStringToCommentContent = (contentString: string): ContentText[] => {
  // formValueをメンション情報を含むテキストと、そうでないものに分割
  const fragments = contentString.split(MENTION_USER_REGEX);

  return fragments.map<ContentText>((fragment) => {
    if (!MENTION_USER_REGEX.test(fragment)) {
      return {
        type: "plain",
        text: fragment,
      };
    }

    // メンション情報からnameだけ取り出す
    const fragmentMatchUserNames = fragment.match(MENTION_USER_NAME_REGEX) ?? [];
    const matchedUserName = fragmentMatchUserNames[0]?.replace(/[[\]]/g, "") || "";

    // メンション情報からuserIdだけ取り出す
    const fragmentMatchUserIds = fragment.match(MENTION_USER_ID_REGEX) ?? [];
    const matchedUserId = fragmentMatchUserIds[0]?.replace(/[()]/g, "") || "";

    return {
      type: "mention",
      employeeId: matchedUserId,
      text: `@${matchedUserName}`,
    };
  });
};
