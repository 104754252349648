import { Box } from "@material-ui/core";
import { formatDistanceStrict } from "date-fns";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  joinAtDate: Date;
};

export const AfterJoin: FC<Props> = (props) => {
  const { joinAtDate } = props;
  const elapsedDays = formatDistanceStrict(Date.now(), joinAtDate.getTime(), {
    unit: "day",
  });

  return (
    <>
      <Typography variant="body2">入社から</Typography>
      <Box textAlign="center">
        <Typography variant="h2" bold color="primary">
          {elapsedDays}
        </Typography>

        <Box height="16px" />

        <Typography variant="body2">
          {`入社から日数が経ったとしても、\n困ったことがあれば、バディや人事などに気兼ねなく相談しましょう。`}
        </Typography>
      </Box>
    </>
  );
};
