import { Menu, MenuItem } from "@material-ui/core";
import React, { FC, useCallback, useState, MouseEvent } from "react";

import { Typography, IconButton } from "~/components/uiParts";

type Props = {
  onClickEditTaskButton: () => void;
  onClickDeleteTaskButton?: () => void;
  onClickOpenSidebarComments: () => void;
};

export const ManageOnboardingGeneralTaskMenu: FC<Props> = ({
  onClickEditTaskButton,
  onClickDeleteTaskButton,
  onClickOpenSidebarComments,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton icon="menuVert" onClick={openMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickEditTaskButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">タスク編集</Typography>
        </MenuItem>
        {onClickDeleteTaskButton && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onClickDeleteTaskButton();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">タスク削除</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickOpenSidebarComments();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">コメント追加</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
