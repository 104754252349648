import { OnboardingExperience, removeUndefinedFromObject } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  orderBy,
  WriteBatch,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IOnboardingExperienceRepository } from "~/service/repository/iOnboardingExperienceRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";
type OnboardingExperienceForDB = Omit<
  ConstructorParameters<typeof OnboardingExperience>[0],
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "onboardingExperiences";

export class OnboardingExperienceRepository implements IOnboardingExperienceRepository {
  async create(onboardingExperience: OnboardingExperience): Promise<void> {
    const ref = this.doc(onboardingExperience.id);
    await setDoc(ref, this.convertToPlainObject(onboardingExperience));
  }
  async deleteById(onboardingExperienceId: string): Promise<void> {
    return await deleteDoc(this.doc(onboardingExperienceId));
  }
  async findById(useOnboardingExperienceId: string): Promise<OnboardingExperience> {
    const doc = await getDoc(this.doc(useOnboardingExperienceId));

    if (!doc.data()) {
      throw new Error("対象のデータが見つかりませんでした。");
    }

    return this.dbToObject(doc.data() as OnboardingExperienceForDB);
  }

  async findByTenantId(tenantId: string): Promise<OnboardingExperience[]> {
    return await getDocs(
      query(this.collection(), where("tenantId", "==", tenantId), orderBy("createdAt", "asc"))
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => {
        return this.dbToObject(doc.data() as OnboardingExperienceForDB);
      });
    });
  }

  insertUpdateExperienceInBatch(batch: WriteBatch, experience: { id: string; index: number }) {
    batch.update(this.doc(experience.id), experience);
  }

  async update(onboardingExperienceId: string, updates: OnboardingExperience): Promise<void> {
    const ref = this.doc(onboardingExperienceId);

    await updateDoc(ref, this.convertToPlainObject(updates));
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private convertToPlainObject(
    onboardingExperience: OnboardingExperience
  ): OnboardingExperienceForDB {
    const now = convertDateToTimestamp(new Date());

    return removeUndefinedFromObject({
      ...onboardingExperience,
      createdAt: convertDateToTimestamp(onboardingExperience.createdAt),
      updatedAt: now,
    });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private dbToObject({ createdAt, updatedAt, ...rest }: OnboardingExperienceForDB) {
    return OnboardingExperience.create({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }
}
