import { Box } from "@material-ui/core";
import { convertToYYMMDD } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  buttonDisabled: boolean;
  sendAt: string;
};

export const BulkRemindModal: FC<Props> = ({
  open,
  onSubmit,
  onCancel,
  buttonDisabled,
  sendAt,
}) => {
  const formatSendAt = convertToYYMMDD(sendAt);

  return (
    <StyledModal
      open={open}
      title="一括リマインド"
      onCancel={onCancel}
      content={
        <Box textAlign="center">
          <Typography>{formatSendAt}配信のアンケートが「未回答」になっている方へ</Typography>
          <Typography>一括でリマインド通知を送信しますか？</Typography>
          <Box height="14px" />
          <Typography variant="caption">
            他の日に配信されたアンケートへのリマインドは、個別にリマインドを送信するか、{"\n"}
            配信日のフィルタリングを変更して一括リマインドをお願いします。
          </Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={buttonDisabled}
          >
            送信
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
