import { Employee } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useAllEmployees, useAllNewcomers } from "~/hooks/employee";
import { generateUseOnboardingTasksKeys } from "~/hooks/onboardingTask/useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask/useOnboardingTasksByEmployeeId";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

export const useAssignMentor = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();
  const { mutateAllEmployees } = useAllEmployees();

  const assignMentor = useCallback(
    async ({
      newHire,
      emailForInvite,
      newMentorId,
      mentor,
    }: {
      newHire: Employee;
      emailForInvite?: string;
      newMentorId?: string;
      mentor?: Employee;
    }) => {
      await EmployeeUseCase.inviteMentor({
        employeeId: newHire.id,
        oldMentorId: newHire.mentorUserId,
        nextMentorId: newMentorId,
        mentorEmail: emailForInvite,
      })
        .then(async () => {
          enqueueSnackbar(`バディを${mentor ? "変更" : "設定"}しました`, {
            variant: "success",
          });
          mutate(["employee", newHire.id]);
          mutateAllNewcomers();
          mutateAllEmployees();
          mutate(generateUseOnboardingTasksByEmployeeId(newHire.id));
          mutate(generateUseOnboardingTasksKeys(newHire.tenantId));
        })
        .catch((e) => {
          enqueueSnackbar(`バディの${mentor ? "変更" : "設定"}に失敗しました`, {
            variant: "error",
          });
          captureException({
            error: e as Error,
            tags: { type: "useAssignMentor:assignMentor" },
          });
        });
    },
    [enqueueSnackbar, mutate, mutateAllNewcomers, mutateAllEmployees]
  );

  return { assignMentor };
};
