import React, { useCallback } from "react";

import { useIsCompletedFilterFormState } from "./useIsCompletedFilterFormState";

import { IsCompletedFilter } from "~/components/domains/contactRooms";

/**
 * 対応済みフィルターコンポーネントを扱うhooks
 */
export const useIsCompletedFilter = () => {
  const { formState, formLabelText, searchCondition, toggleState } =
    useIsCompletedFilterFormState();

  const renderFilter = useCallback(
    () => (
      <IsCompletedFilter
        formLabelText={formLabelText}
        formState={formState}
        toggleForm={toggleState}
      />
    ),
    [formLabelText, formState, toggleState]
  );
  return {
    renderFilter,
    searchCondition,
  };
};
