import { Box, Menu as MuiMenu, MenuItem } from "@material-ui/core";
import React, { FC, useCallback, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { UserIcon, Icon, Typography, TooltipWhenTextTruncated } from "~/components/uiParts";
import { useSignOut } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";

const menuId = "primary-search-account-menu";

type Props = { shouldShowUserName?: boolean };

export const UserProfile: FC<Props> = ({ shouldShowUserName = true }) => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { signOut } = useSignOut();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSetting = useCallback(
    () => navigate(currentUser.isAdmin() ? "/settings/admin" : "/settings/account"),
    [navigate, currentUser]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickLogoutButton = useCallback(async () => {
    await signOut();
    navigate("/signin");
  }, [navigate, signOut]);

  const Menu = memo(() => {
    return (
      <MuiMenu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MenuItem onClick={handleClickSetting}>設定</MenuItem>
        <MenuItem onClick={handleClickLogoutButton}>ログアウト</MenuItem>
      </MuiMenu>
    );
  });

  return (
    <>
      <TooltipWhenTextTruncated text={currentUser.getName()}>
        {(ref) => (
          <StyledBox
            width="100%"
            display="flex"
            alignItems="center"
            onClick={handleProfileMenuOpen}
          >
            <UserIcon
              username={currentUser.getName()}
              profileIconImageUrl={currentUser.profileIconImageUrl}
              size="small"
            />
            {shouldShowUserName && (
              <>
                <StyledTypography variant="body2" noWrap ref={ref}>
                  {currentUser.getName()}
                </StyledTypography>
                <Icon icon="dropdownArrow" color="grey" size="sm" />
              </>
            )}
          </StyledBox>
        )}
      </TooltipWhenTextTruncated>
      <Menu />
    </>
  );
};

const StyledBox = styled(Box)`
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 12px;
    margin-right: 12px;
  }
`;
