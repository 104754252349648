import { Department, Employee } from "@onn/common";
import { useMemo } from "react";

import { useDepartmentsByTenantId } from "./useDepartmentsByTenantId";

export const useIsMyDepartmentAdmin = (
  me: Employee,
  employee: Employee
): {
  result: boolean;
  isLoading: boolean;
  departments: Department[];
} => {
  const { data: departments = [], isLoading } = useDepartmentsByTenantId(me.tenantId);

  return useMemo(() => {
    if (departments.length < 1) return { result: false, isLoading, departments };
    return {
      result:
        employee.isDepartmentAdmin() &&
        Department.getChildIds(employee.departmentIds, departments).some((id) =>
          me.departmentIds.includes(id)
        ),
      isLoading,
      departments,
    };
  }, [departments, me, employee, isLoading]);
};
