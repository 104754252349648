import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import React, { useCallback, useMemo, useState, FC } from "react";

import { DepartmentFilter, DepartmentTable } from "~/components/domains/departments";
import { Typography, Loading, Button, Icon } from "~/components/uiParts";
import { useDepartmentsByTenantId, useCreateDepartment } from "~/hooks/department";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export const DepartmentTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const {
    data: departments,
    isValidating,
    mutate: mutateDepartments,
  } = useDepartmentsByTenantId(currentUser.tenantId);
  const { createDepartment } = useCreateDepartment();

  const handleOpenEditDepartmentModal = useCallback(
    (departments: Department[]) => {
      handleModal({
        name: "editDepartmentModal",
        args: {
          departments,
          mode: "create",
          onSubmit: async (name, parentDepartment) => {
            await createDepartment(
              Department.create({
                name,
                layer: parentDepartment ? parentDepartment.layer + 1 : 1,
                tenantId: currentUser.tenantId,
                parentDepartmentId: parentDepartment ? parentDepartment.id : undefined,
              })
            ).then(() => mutateDepartments());
          },
        },
      });
    },
    [createDepartment, currentUser.tenantId, handleModal, mutateDepartments]
  );

  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<string[]>([]);
  const filteredDepartments = useMemo(() => {
    if (!departments) return [];
    if (!selectedDepartmentIds.length) return departments;

    const filteringDepartmentIds = Department.getChildIds(selectedDepartmentIds, departments);
    return departments.filter((department) => filteringDepartmentIds.includes(department.id));
  }, [departments, selectedDepartmentIds]);

  if (isValidating) {
    return <Loading size="large" fullHeight />;
  } else if (!departments) {
    throw new Error("部署の読み込みに失敗しました");
  } else {
    return (
      <>
        <Typography variant="body2" color="textSecondary">
          {
            "部署を登録・管理できます。登録された部署は、管理者(主に部門管理者)の閲覧範囲や通知範囲、そして入社者へのコンテンツ配信やタスク配信などに紐づきます。\n部署を編集する際は、影響範囲を考慮した変更をお願いいたします。"
          }
        </Typography>
        <Box display="flex" justifyContent="space-between" my="30px">
          <Box display="flex" flexDirection="column" gridRowGap="16px" width="240px">
            <Typography variant="body2" bold color="textSecondary">
              絞り込み
            </Typography>
            <DepartmentFilter
              departments={departments}
              selectedDepartmentIds={selectedDepartmentIds}
              isSelectedNoDepartment={false}
              onChange={(selectedDepartmentIds) => setSelectedDepartmentIds(selectedDepartmentIds)}
            />
          </Box>
          <Button
            color="primary"
            variant="outlined"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="primary" size="md" />}
            onClick={() => handleOpenEditDepartmentModal(departments)}
          >
            部署を追加
          </Button>
        </Box>

        <DepartmentTable departments={filteredDepartments} onUpdate={mutateDepartments} />
      </>
    );
  }
};
