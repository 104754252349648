import { Question, ItemType, QuestionItem, Survey, SurveyTiming } from "@onn/common";
import {
  doc,
  DocumentData,
  updateDoc,
  DocumentReference,
  getDocs,
  query,
  collection,
  CollectionReference,
  where,
} from "firebase/firestore";

import { firestore } from "../../config/firebase";

import { ITemplateRepository } from "~/service/repository/iTemplateRepository";

const COLLECTION_NAME = "templates";

enum ContentType {
  SURVEY = "SURVEY",
  LIBRARY = "LIBRARY",
}

const convertToQuestion = (data: DocumentData): Question => {
  const questionItems = data.questionItems
    ? data.questionItems.map((v: QuestionItem) => {
        return new QuestionItem(v.title, v.name, v.value, v.text || false, v.placeholder);
      })
    : [];
  const questions =
    data.type === ItemType.TITLE
      ? data.questions.map((v: Question) => {
          return new Question({
            ...v,
            questions: [],
            questionItems,
          });
        })
      : [];

  const question = { questions: questions, questionItems: questionItems };
  for (const key in data) {
    if (data[key] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      question[key] = data[key];
    }
  }
  return question as Question;
};

const convertToSurveyTimings = (data: DocumentData): SurveyTiming[] => {
  const surveyTimings = data.templateContents.surveyTimings;
  return surveyTimings.map((v: SurveyTiming) => {
    return new SurveyTiming(v.unit, v.value);
  });
};

const convertToSurvey = (data: DocumentData): Survey => {
  const questions = data.templateContents.questions.map((v: Question) => convertToQuestion(v));
  const surveyTimings = convertToSurveyTimings(data);
  return new Survey({
    tenantId: data.tenantId,
    id: data.id,
    title: data.templateContents.title,
    subtitle: data.templateContents.subtitle,
    description: data.templateContents.description,
    answerTime: data.templateContents.answerTime,
    questions: questions,
    timing: data.timing,
    remindTiming: data.remindTiming,
    disabled: data.disabled,
    surveyTimings: surveyTimings,
    type: data.templateContents.type,
    totalSection: data.templateContents.totalSection,
    sendTargetType: data.sendTargetType,
    contentsType: data.contentsType,
    sendWeekday: data.sendWeekday,
    // sendHour: data.sendHour,
    lang: data.templateContents.lang,
  });
};

const revertToSurveyTimings = (surveyTimings: SurveyTiming[]): SurveyTiming[] => {
  return surveyTimings.map((v) => {
    return { unit: v.unit, value: v.value };
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const revertFromSurvey = (data: DocumentData): Record<string, any> => {
  const questions = data.questions;
  return {
    timing: data.timing,
    remindTiming: data.remindTiming,
    disabled: data.disabled,
    sendWeekday: data.sendWeekday,
    // sendHour: data.sendHour,
    templateContents: {
      lang: data.lang,
      title: data.title,
      subtitle: data.subtitle || null,
      description: data.description,
      answerTime: data.answerTime || null,
      questions: questions,
      surveyTimings: revertToSurveyTimings(data.surveyTimings),
      type: data.type,
      totalSection: data.totalSection || null,
    },
  };
};

export class TemplateRepository implements ITemplateRepository {
  async findSurveys(tenantId: string): Promise<Survey[]> {
    return await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("contentsType", "==", ContentType.SURVEY)
      )
    ).then((snapshot) => {
      return snapshot.docs.map((doc) => convertToSurvey(doc.data()));
    });
  }
  async findSurvey(tenantId: string, surveyId: string): Promise<Survey> {
    return await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("contentsType", "==", ContentType.SURVEY),
        where("id", "==", surveyId)
      )
    ).then((snapshot) => {
      const surveys = snapshot.docs.map((doc) => convertToSurvey(doc.data()));
      return surveys[0] as (typeof surveys)[number];
    });
  }
  async updateSurvey(survey: Survey): Promise<void> {
    const surveyRef = this.doc(survey.id);
    // NOTE: 本来は firestore compatible を使うべきだが、Surveyと実際にDBに保存するSurveyTemplateの構造が異なるためrevertFromSurveyを使っている
    await updateDoc(surveyRef, revertFromSurvey(survey));
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}
