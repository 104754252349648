import { Box } from "@material-ui/core";
import { Department, OnboardingExperience } from "@onn/common";
import React, { FC } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  onClickNextButton: () => void;
  onboardingExperiences: OnboardingExperience[];
  departments: Department[];
};

export const DownloadSampleFileStepContent: FC<Props> = ({
  onClickNextButton,
  onboardingExperiences,
  ...props
}) => {
  const NEW_EMPLOYEES_SAMPLE_DATA = [
    ["姓", "名", "メールアドレス", "入社日", "部署", "部署", "部署", "体験", "体験", "体験"],
    ["サンプル", "太郎", "sample.taro@example.com", "20221001", "", "", "", "", "", ""],
  ];

  const { generateSampleCSV } = useGenerateSampleCSV();

  return (
    <>
      <Typography>
        最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
      </Typography>
      <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
        <StyledCSVLink
          data={NEW_EMPLOYEES_SAMPLE_DATA}
          filename="入社者招待_アップロード用サンプルファイル"
        >
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
          >
            サンプルファイルをダウンロード
          </Button>
        </StyledCSVLink>
        <StyledCSVLink
          data={generateSampleCSV(onboardingExperiences, props.departments)}
          filename="入社者招待_設定可能な項目一覧"
        >
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
          >
            設定値ファイルをダウンロード
          </Button>
        </StyledCSVLink>
      </Box>
      <StyledContainerBox p={2} mt={4} mb={5}>
        <Typography bold={true}>アップロード用ファイル作成に関するヒント</Typography>
        <Typography>
          フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
          <br />
          指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
        </Typography>
      </StyledContainerBox>
      <Box display="flex" justifyContent="center">
        <Box width="240px">
          <Button
            fullWidth={true}
            onClick={onClickNextButton}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
          >
            次へ
          </Button>
        </Box>
      </Box>
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  height: 48px;
`;
