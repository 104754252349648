import {
  createIMockOnboardingGeneralTask,
  createIMockOnboardingSimpleTask,
  OnboardingExperienceGeneralTask,
  OnboardingExperienceSimpleTask,
} from "@onn/common";
import { useCallback } from "react";

import { useTenantSettings } from "../tenantSetting";

import { useCurrentUser } from "~/hooks/employee";
import { useLocalStorage } from "~/hooks/shared";

export const useOpenPortalOnboardingTaskPreview = () => {
  const { currentUser } = useCurrentUser();
  const { storeValue } = useLocalStorage();
  const { tenantSettings } = useTenantSettings();

  return useCallback(
    (onboardingExperienceTasks: OnboardingExperienceSimpleTask[], url: string) => {
      // 入社者向けの共通タスクと通常タスクを取得する
      const generalTasks = OnboardingExperienceGeneralTask.getNewHireTasks(
        currentUser.tenantId,
        tenantSettings.generalTask
      ).map((gt) =>
        createIMockOnboardingGeneralTask({
          ...gt,
          filePaths: [],
          employeeId: currentUser.id,
          tenantId: currentUser.tenantId,
          createdAt: new Date(),
          updatedAt: new Date(),
        })
      );

      const simpleTasks = onboardingExperienceTasks
        .filter((task) => task.assigneeRole === "NEW_HIRE")
        .map((task) =>
          createIMockOnboardingSimpleTask({
            ...task,
            employeeId: currentUser.id,
            tenantId: currentUser.tenantId,
            createdAt: new Date(),
            updatedAt: new Date(),
          })
        );

      storeValue("OnboardingTaskForPreview", [...generalTasks, ...simpleTasks]);

      window.open(url, "_blank", "noopener noreferrer");
    },
    [currentUser.id, currentUser.tenantId, tenantSettings.generalTask, storeValue]
  );
};
