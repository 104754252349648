import { Box, Grid, TextField, IconButton } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Question, Survey } from "@onn/common";
import React, { FC, useState } from "react";

import { Icon, Typography } from "~/components/uiParts";

interface Props {
  question: Question;
  changeQuestion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  deleteQuestion: (survey: Survey, targetQuestion: Question) => void;
  survey: Survey;
  index: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    right: {
      textAlign: "right",
    },
    left: {
      textAlign: "left",
    },
  })
);

export const AdditionalQuestion: FC<Props> = ({
  question,
  changeQuestion,
  deleteQuestion,
  survey,
  index,
}) => {
  const classes = useStyles();
  const [hasError, setHasError] = useState<boolean>(false);
  const { title } = question;

  const checkAdditionalQuestionInvalid = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === "") {
      setHasError(true);
    } else {
      setHasError(false);
    }
    changeQuestion(event);
  };

  return (
    <Box key={question.name} mt={3}>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Typography variant="body1">{`Q${index}. `}</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            onChange={checkAdditionalQuestionInvalid}
            value={title}
            name="title"
            placeholder="質問"
            error={hasError}
            helperText={hasError && "追加設問を入力してください"}
          />
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.right}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => deleteQuestion(survey, question)}
            >
              <Icon icon="trash" size="md" color="grey" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
