import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnboardingSimpleTask } from "@onn/common";
import React, { useCallback, FC, useState, MouseEvent } from "react";

import { Button, IconButton, Typography } from "~/components/uiParts";
import { useReward } from "~/hooks/shared";

// ====================
// magic number
// ====================

const REWARD_ID = "complete-onboarding-task";

// ====================
// props
// ====================

type Props = {
  onboardingTask: OnboardingSimpleTask;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickChangeStatusButton: () => Promise<void>;
};

// ====================
// main
// ====================

export const OnboardingSimpleTaskControl: FC<Props> = ({
  onboardingTask,
  onClickEditButton,
  onClickDeleteTaskButton,
  onClickChangeStatusButton,
}) => {
  // ====================
  // state
  // ====================

  const [isUpdating, setIsUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // ====================
  // hooks
  // ====================

  const { reward } = useReward(REWARD_ID, {
    angle: 135,
    decay: 0.75,
    spread: 75,
    startVelocity: 125,
    elementCount: 40,
    elementSize: 30,
  });

  // ====================
  // variable
  // ====================

  const handleClickSwitchStatusButton = useCallback(() => {
    setIsUpdating(true);
    onClickChangeStatusButton().then(() => {
      reward();
      setIsUpdating(false);
    });
  }, [onClickChangeStatusButton, reward]);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  // ====================
  // main component
  // ====================

  return (
    <Box p="24px" display="flex" alignItems="center" position="absolute" bottom="16px" width="100%">
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        disabled={onboardingTask.status === "COMPLETED" || isUpdating}
        onClick={handleClickSwitchStatusButton}
        disableTouchRipple
      >
        <span id={REWARD_ID} />
        {onboardingTask.status === "COMPLETED" ? "対応完了済み" : "タスク対応完了"}
      </Button>
      <IconButton icon="menuVert" onClick={openMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {onboardingTask.status !== "COMPLETED" && (
          <MenuItem
            onClick={() => {
              onClickEditButton();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">タスク編集</Typography>
          </MenuItem>
        )}
        {onboardingTask.status !== "COMPLETED" && (
          <MenuItem
            onClick={() => {
              onClickDeleteTaskButton();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">タスク削除</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onClickChangeStatusButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">ステータス変更</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
