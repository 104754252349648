import { AllContactRoom, Employee, LatestContactMessage } from "@onn/common";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useSortContactRoomsV2 } from "~/components/domains/contactRooms/hooks";

import { useLatestContactMessage } from "~/hooks/contactMessage";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useIsCompletedFilter, useFilterContactRoomsWithIsCompletedV2 } from "~/hooks/contactRoom";
import { SearchCondition } from "~/hooks/contactRoom/useIsCompletedFilterFormState";
import { useCurrentUser } from "~/hooks/employee";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";
import { ContactMessageRepository } from "~/infrastructure/api/contactMessageRepository";

export type TabValue = "my" | "all";
const contactMessageRepository = new ContactMessageRepository();

export const useContactRoomListV2 = () => {
  const { currentUser } = useCurrentUser();
  const [selectedContactRoomId, setSelectedContactRoomId] = useState<string>();
  const {
    contactRoomUnreadCountList,
    contactRoomsWithoutCurrentUser: contactRoomsV2,
    isLoadingContactRooms,
    isLoadingUnreadMessageCountList,
  } = useContactContext();
  const {
    data: latestContactMessages = [],
    isLoading: isLoadingLatestMessages,
    mutate: latestMessageMutate,
  } = useLatestContactMessage({
    tenantId: currentUser.tenantId,
  });
  const { renderFilter: renderIsCompletedFilter, searchCondition: isCompletedSearchCondition } =
    useIsCompletedFilter();
  const [searchValue, setSearchValue] = useState("");
  // NOTE: 初期表示タブは管理者の場合は全体、そうでない場合は自身が担当者のみのルームのみ
  const [activeTab, setActiveTab] = useState<TabValue>(currentUser.isAdmin() ? "all" : "my");

  // 自分自身が対象となっているルームは取得しない
  // 入社者のユーザーでログインし、他の入社者のコンタクトルームの閲覧権限を有する場合に表示されてしまうことを防ぐ

  // コンタクトルーム一覧の未読数をリアルタイムで更新するため、コンタクトルームの更新を監視
  useEffect(() => {
    const unsubscribeFunctions = contactMessageRepository.listenNewContactMessage(
      contactRoomsV2.map((v) => v.id),
      currentUser.tenantId,
      () => {
        latestMessageMutate();
      }
    );
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoomsV2.length]);

  const currentContactRooms = useFilterAndSortContactRooms({
    contactRooms: contactRoomsV2,
    searchValue,
    isCompletedSearchCondition,
    currentUser,
    activeTab,
    latestContactMessages,
  });

  return {
    searchValue,
    setSearchValue,
    currentContactRooms,
    activeTab,
    setActiveTab,
    renderIsCompletedFilter,
    selectedContactRoomId,
    setSelectedContactRoomId,
    contactRoomUnreadCountList,
    isLoadingContactRooms,
    latestContactMessages,
    isLoadingLatestMessages,
    isLoadingUnreadMessageCountList,
  };
};

/**
 * コンタクトルーム一覧のフィルターとソートをする
 */
const useFilterAndSortContactRooms = ({
  contactRooms,
  searchValue,
  isCompletedSearchCondition,
  currentUser,
  activeTab,
  latestContactMessages,
}: {
  contactRooms: AllContactRoom[];
  searchValue: string;
  isCompletedSearchCondition: SearchCondition;
  currentUser: Employee;
  activeTab: TabValue;
  latestContactMessages?: LatestContactMessage[];
}): AllContactRoom[] => {
  const { sortContactRooms: sortContactRoomsV2 } = useSortContactRoomsV2();
  const { filterContactRoomsWithIsCompleted: filterContactRoomsWithIsCompletedV2 } =
    useFilterContactRoomsWithIsCompletedV2();

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  // TODO: LINEユーザーでのフィルターに対応する
  const filterContactRoomsBySearchTextV2 = useCallback(
    (contactRooms: AllContactRoom[]) =>
      filterObjectByPartialMatch<AllContactRoom>({
        objects: contactRooms,
        searchText: searchValue,
        getProperties: [
          (contactRoom: AllContactRoom) => {
            return contactRoom.employee instanceof Employee ? contactRoom.employee.email : "";
          },
          (contactRoom: AllContactRoom) => {
            return contactRoom.getRoomName();
          },
        ],
      }),
    [filterObjectByPartialMatch, searchValue]
  );

  const currentContactRooms = useMemo(() => {
    const tempResult = sortContactRoomsV2(
      filterContactRoomsBySearchTextV2(
        filterContactRoomsWithIsCompletedV2(contactRooms, isCompletedSearchCondition)
      ),
      latestContactMessages
    );
    if (activeTab === "all") {
      return tempResult;
    } else {
      return tempResult.filter((room) => room.assigneeId === currentUser.id);
    }
  }, [
    sortContactRoomsV2,
    filterContactRoomsBySearchTextV2,
    filterContactRoomsWithIsCompletedV2,
    contactRooms,
    isCompletedSearchCondition,
    latestContactMessages,
    activeTab,
    currentUser.id,
  ]);

  return currentContactRooms;
};
