import { Box, Menu } from "@material-ui/core";
import { Department } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, FC } from "react";
import styled from "styled-components";

import { DepartmentSelectMenu } from "../DepartmentSelectMenu";

import { Icon, Typography, Button } from "~/components/uiParts";

type Props = {
  departments: Department[];
  selectedDepartmentIds: string[];
  maxSelectCount?: number;
  onChange: (selectedDepartments: string[]) => void;
  // onSearch: (results: Department[]) => void; TODO: 検索は今後実装
};

export const DepartmentSelect: FC<Props> = ({
  departments,
  selectedDepartmentIds,
  maxSelectCount,
  onChange,
  // onSearch,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const [searchValue, setSearchValue] = useState("");

  // const handleSearch = useCallback(
  //   (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement>) => {
  //     e.preventDefault();

  //     const results = departments;

  //     onSearch(results);
  //   },
  //   [departments, onSearch]
  // );

  return (
    <>
      <Box position="relative">
        <StyledButtonWrapper>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            borderRadius="regular"
            variant="outlined"
            color="default"
            fullWidth
          >
            <Box width="100%" display="flex" alignItems="center" gridGap="4px">
              <StyledTypography variant="body2" noWrap>
                {selectedDepartmentIds.length
                  ? departments
                      .flatMap((department) =>
                        selectedDepartmentIds.includes(department.id) ? department.name : []
                      )
                      .join(", ")
                  : "所属なし"}
              </StyledTypography>
              <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
            </Box>
          </Button>
        </StyledButtonWrapper>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: -8, horizontal: "right" }}
      >
        {/* <Box width="360px"> */}
        {/* <Box p="16px">
            <StyledForm onSubmit={handleSearch}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                endAdornment={
                  <IconButton size="sm" color="grey" icon="search" onClick={handleSearch} />
                }
              />
            </StyledForm>
          </Box>
          <Divider /> */}
        {isEmpty(departments) ? (
          <Box py="16px" width="318px">
            <Box px="24px">
              <Typography variant="caption" color="textSecondary">
                部署が存在しません
              </Typography>
            </Box>
          </Box>
        ) : (
          <DepartmentSelectMenu
            departments={departments}
            selectedDepartmentIds={selectedDepartmentIds}
            onChange={(selectedDepartmentId) => onChange(selectedDepartmentId)}
            maxSelectCount={maxSelectCount}
            displayIndeterminateDirection="parent"
          />
        )}
        {/* </Box> */}
      </Menu>
    </>
  );
};

// const StyledForm = styled.form`
//   .MuiOutlinedInput-input {
//     padding: 8px;
//   }
// `;

const StyledButtonWrapper = styled(Box)`
  /* TODO: buttonコンポーネントを修正してネスト指定が必要ないようにする */
  .MuiButtonBase-root > div {
    padding: 16px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
