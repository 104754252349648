import { Employee, Department } from "@onn/common";
import React, { FC, useMemo } from "react";

import { UserSummaryTooltip } from "~/components/domains/employees";
import { UserIconWithLabel as CommonUserIconWithLabel } from "~/components/uiParts";

type Props = {
  iconPath?: string;
  userName: string;
  to?: string;
  employee?: Employee;
  departments: Department[];
  shouldDisplayContactRoomButton: boolean;
};

export const UserIconWithLabel: FC<Props> = React.memo(
  ({ iconPath, userName, to, employee, departments, shouldDisplayContactRoomButton }) => {
    const userIconWithLabel = useMemo(() => {
      return <CommonUserIconWithLabel iconPath={iconPath} name={userName} to={to} />;
    }, [iconPath, userName, to]);

    if (employee === undefined || employee.deleted) {
      return userIconWithLabel;
    }

    return (
      <UserSummaryTooltip
        employee={employee}
        departments={departments}
        shouldDisplayContactRoomButton={shouldDisplayContactRoomButton}
      >
        {userIconWithLabel}
      </UserSummaryTooltip>
    );
  }
);
