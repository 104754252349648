import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { Typography, Button, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const DeleteOnboardingExperienceProcessModal: FC<Props> = ({ open, onCancel, onSubmit }) => {
  const content = useMemo(() => {
    return (
      <Box textAlign="center">
        <Typography variant="body1">
          プロセスに含まれるタスクも同時に削除されますがよろしいですか？
        </Typography>
      </Box>
    );
  }, []);

  const footer = useMemo(() => {
    return (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="secondary"
          onClick={() => {
            onSubmit();
            onCancel();
          }}
        >
          削除
        </Button>
      </StyledButtonContainer>
    );
  }, [onCancel, onSubmit]);

  return (
    <Modal title="プロセス削除" open={open} onCancel={onCancel} content={content} footer={footer} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
