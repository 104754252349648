import { Box, Grid } from "@material-ui/core";
import { QuestionItem } from "@onn/common";
import React, { FC, useCallback } from "react";

import { Checkbox } from "~/components/uiParts/Checkbox";
import { FormControlLabel } from "~/components/uiParts/FormControlLabel";
import { QuestionTitle } from "~/components/uiParts/QuestionTitle";
import { TextContext } from "~/components/uiParts/TextContent";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  title: string;
  hrOnly?: boolean;
  questionItems: QuestionItem[];
  selectedAnswers: string[];
  freeAnswerText?: string;
  displayOnlySelectedAnswers?: boolean;
};
export const CheckboxQuestion: FC<Props> = (props: Props) => {
  const {
    title,
    hrOnly = false,
    questionItems,
    selectedAnswers,
    freeAnswerText,
    displayOnlySelectedAnswers = false,
  } = props;

  // 自由回答の分 height を調整するために 2 足して左側の高さが大きくなるように調整する
  const questionItemsGroup1 = questionItems.slice(0, questionItems.length / 2 + 2);
  const questionItemsGroup2 = questionItems.slice(questionItems.length / 2 + 2);

  const buildAnswer = useCallback(
    (questionItem: QuestionItem) => {
      const answerItem = selectedAnswers.find((item) => item === questionItem.name);

      // !answerItem の時回答は選択されていない
      if (!answerItem && displayOnlySelectedAnswers) {
        return null;
      }

      return (
        <div key={questionItem.name}>
          <FormControlLabel
            label={
              <Typography variant="body2" color="textSecondary">
                {questionItem.title}
              </Typography>
            }
            disableEffect
            control={<Checkbox checked={!!answerItem} disableEffect />}
          />
        </div>
      );
    },
    [displayOnlySelectedAnswers, selectedAnswers]
  );

  return (
    <>
      <QuestionTitle title={title} hrOnly={hrOnly} bold />
      <Box height="8px" />

      {/* 選ばれていない回答も表示する場合 ２列に分けて表示する。 */}
      <Grid container spacing={0}>
        <Grid item xs={displayOnlySelectedAnswers ? 12 : 5}>
          {questionItemsGroup1.map((v) => buildAnswer(v))}
        </Grid>
        <Grid item xs={displayOnlySelectedAnswers ? 12 : 7}>
          {questionItemsGroup2.map((v) => buildAnswer(v))}
          {freeAnswerText && (
            <Box pl={4}>
              <TextContext padding={2} isShowBg>
                {freeAnswerText}
              </TextContext>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
