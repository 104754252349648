import { Box, Menu } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useState, FC } from "react";
import styled from "styled-components";

import { EmployeeList, SearchForm } from "./parts";

import { Button, Divider, Icon, Typography, UserIcon } from "~/components/uiParts";

type Props = {
  employees: Employee[];
  selectedEmployees: Employee[];
  selectEmployees: (employees: Employee[]) => void;
  width?: string;
};

export const SelectMenu: FC<Props> = ({
  employees,
  selectedEmployees,
  selectEmployees,
  width = "240px",
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(employees);

  return (
    <>
      <StyledBox $width={width}>
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" alignItems="center" gridGap="4px">
            {selectedEmployees.length > 1 ? (
              <>
                <Typography variant="body2" noWrap>
                  {selectedEmployees.length}名のメンバー
                </Typography>
              </>
            ) : selectedEmployees.length === 1 ? (
              <>
                <UserIcon
                  username={(selectedEmployees[0] as (typeof selectedEmployees)[number]).getName()}
                  profileIconImageUrl={
                    (selectedEmployees[0] as (typeof selectedEmployees)[number]).profileIconImageUrl
                  }
                  size="extraSmall"
                  circular
                />
                <Typography variant="body2">
                  {(selectedEmployees[0] as (typeof selectedEmployees)[number]).getName()}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">メンバーを選択</Typography>
            )}
            <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
          </Box>
        </Button>
      </StyledBox>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        <Box>
          <Box p="16px">
            <SearchForm
              employees={employees}
              onSearch={(results) => setDisplayEmployees(results)}
            />
          </Box>
          <Divider />
          <EmployeeList
            employees={displayEmployees}
            selectedEmployees={selectedEmployees}
            onSelect={(employees) => selectEmployees(employees)}
          />
        </Box>
      </Menu>
    </>
  );
};

const StyledBox = styled(Box)<{ $width: string }>`
  width: ${(props) => props.$width};
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
