export const ErrorType = {
  OVER_THE_LIMIT: "OVER_THE_LIMIT",
  NO_EMAIL_ADDRESS: "NO_EMAIL_ADDRESS",
  DUPLICATED_EMAIL_ADDRESS_IN_CSV: "DUPLICATED_EMAIL_ADDRESS_IN_CSV",
  INVALID_EMAIL_ADDRESS: "INVALID_EMAIL_ADDRESS",
  ALREADY_REGISTERED_EMAIL_ADDRESS: "ALREADY_REGISTERED_EMAIL_ADDRESS",
  ALREADY_OTHER_TENANT_REGISTERED_EMAIL_ADDRESS: "ALREADY_OTHER_TENANT_REGISTERED_EMAIL_ADDRESS",
  NOT_FOUND_EMPLOYEE_BY_EMAIL_ADDRESS: "NOT_FOUND_EMPLOYEE_BY_EMAIL_ADDRESS",
  ALREADY_DELETED_USER: "ALREADY_DELETED_USER",
  INVALID_JOIN_AT: "INVALID_JOIN_AT",
  NO_EXISTING_DEPARTMENT_NAME: "NO_EXISTING_DEPARTMENT_NAME",
  DEPARTMENT_NAME_WITH_HALF_SLASH: "DEPARTMENT_NAME_WITH_HALF_SLASH",
  DUPLICATED_DEPARTMENT_NAME: "DUPLICATED_DEPARTMENT_NAME",
  NO_EXISTING_TAG_NAME: "NO_EXISTING_TAG_NAME",
  TAG_NAME_WITH_HALF_SLASH: "TAG_NAME_WITH_HALF_SLASH",
  DUPLICATED_TAG_NAME: "DUPLICATED_TAG_NAME",
  NO_EXISTING_ONBOARDING_EXPERIENCE_TITLE: "NO_EXISTING_ONBOARDING_EXPERIENCE_TITLE",
  DUPLICATED_ONBOARDING_EXPERIENCE_TITLE: "DUPLICATED_ONBOARDING_EXPERIENCE_TITLE",
  NO_EXISTING_ONNEVENT_TITLE: "NO_EXISTING_ONNEVENT_TITLE",
  DUPLICATED_ONNEVENT_TITLE: "DUPLICATED_ONNEVENT_TITLE",
  ALREADY_TAG_REGISTERED: "ALREADY_TAG_REGISTERED",
} as const;

type ErrorTypes = keyof typeof ErrorType;

export type ErrorStatus = Map<ErrorTypes, number[]>;
