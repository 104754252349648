import { SurveyTransaction, Month, Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { FindSurveyTransactionsForENPSUseCase } from "~/service/usecases/surveyTransaction";

/**
 * tenantId, periodをもとにeNPS集計用のemployeeIds, surveyTransactionsを返すswr
 * @param tenantId tenantのid
 * @param period 取得する入社者の入社年月
 */

export const useSurveyTransactionsForENPS = (
  tenantId: string,
  period: { year: number; month: Month }
): SWRResponse<
  {
    employees: Employee[];
    firstMonthSurveyTransactions: SurveyTransaction[];
    thirdMonthSurveyTransactions: SurveyTransaction[];
  },
  Error
> => {
  const findSurveyTransactionsForENPSUseCase = new FindSurveyTransactionsForENPSUseCase();

  return useSWR(`survey-transactions-for-eNPS-${tenantId}-${period.year}-${period.month}`, () =>
    findSurveyTransactionsForENPSUseCase
      .execute(tenantId, {
        year: period.year,
        month: period.month,
      })
      .then((res) => res)
  );
};
