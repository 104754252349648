import { Box, DialogContent, DialogActions } from "@material-ui/core";
import { isValid, format } from "date-fns";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, DatePicker, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (newHireId: string, joinDate: string) => Promise<void>;
  newHireId: string;
  joinAt?: string;
  profileIconImageUrl?: string;
  username: string;
  email: string;
};

export const JoinDateModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  newHireId,
  joinAt,
  profileIconImageUrl,
  username,
  email,
}) => {
  const [joinDate, setJoinDate] = useState<string>(joinAt || format(new Date(), "yyyy-MM-dd"));
  const [invalidJoinDate, setInvalidJoinDate] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const changeJoinAt = (date: Date | null) => {
    if (!isValid(date)) {
      setInvalidJoinDate(true);
      return;
    }
    setInvalidJoinDate(false);
    if (date !== null) {
      setJoinDate(format(date, "yyyy-MM-dd"));
    }
  };

  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit(newHireId, joinDate)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [joinDate, newHireId, onCancel, onSubmit]);

  const Content = (
    <StyledDialogContent>
      <StyledDetailBox>
        <UserIcon
          profileIconImageUrl={profileIconImageUrl}
          username={username}
          size="medium"
          borderColor="primary"
        />
        <Box height="8px" />
        <Typography variant="body2" bold noWrap>
          {username}
        </Typography>
        <Typography variant="caption" noWrap color="textSecondary">
          {email}
        </Typography>
      </StyledDetailBox>
      <StyledDatePicker fullWidth value={joinDate} onChange={changeJoinAt} />
    </StyledDialogContent>
  );

  const Footer = (
    <StyledDialogActions>
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          disabled={invalidJoinDate || sending}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          設定する
        </Button>
      </StyledButtonContainer>
    </StyledDialogActions>
  );

  return (
    <Modal open={open} title="入社日設定" content={Content} footer={Footer} onCancel={onCancel} />
  );
};

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 20px 48px;
    margin: 0 auto;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    text-align: center;
    display: block;
  }
`;

const StyledDetailBox = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-top: 32px;
`;

const StyledButtonContainer = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.default};
  ${mixin.fixedWidthButtonContainer}
`;
