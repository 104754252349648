import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnboardingGeneralTask, Employee } from "@onn/common";
import React, { useCallback, FC, useState, MouseEvent } from "react";

import { GeneralTaskButton } from "../../GeneralTaskButton";

import { IconButton, Typography } from "~/components/uiParts";
import { useIsMyDepartmentAdmin } from "~/hooks/department/useIsMyDepartmentAdmin";

// ====================
// props
// ====================

type Props = {
  onboardingTask: OnboardingGeneralTask;
  currentUser: Employee;
  newHire: Employee;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickGeneralTaskButton: () => void;
};

// ====================
// main
// ====================

export const OnboardingGeneralTaskControl: FC<Props> = ({
  onboardingTask,
  currentUser,
  newHire,
  onClickEditButton,
  onClickDeleteTaskButton,
  onClickGeneralTaskButton,
}) => {
  // ====================
  // state
  // ====================

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // ====================
  // hooks
  // ====================

  const { result: isEditableNewHireSetting } = useIsMyDepartmentAdmin(newHire, currentUser);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  // ====================
  // main component
  // ====================

  return (
    <Box p="24px" display="flex" alignItems="center" position="absolute" bottom="16px" width="100%">
      {/* 入社者の共通タスクは完了にできない */}
      <GeneralTaskButton
        onboardingTask={onboardingTask}
        newHire={newHire}
        disabled={
          !isEditableNewHireSetting ||
          onboardingTask.actionId === "CREATE_ACCOUNT" ||
          onboardingTask.actionId === "REGISTER_PROFILE"
        }
        onClickGeneralTaskButton={onClickGeneralTaskButton}
      />
      {onboardingTask.status !== "COMPLETED" && (
        <>
          <IconButton icon="menuVert" onClick={openMenu} />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                onClickEditButton();
                setAnchorEl(null);
              }}
            >
              <Typography variant="body2">タスク編集</Typography>
            </MenuItem>
            {/* 受け入れメンバー設定のみ削除を許容する */}
            {onboardingTask.actionId === "ASSIGN_MENTOR_OR_SUPPORT_MEMBER" && (
              <MenuItem
                onClick={() => {
                  onClickDeleteTaskButton();
                  setAnchorEl(null);
                }}
              >
                <Typography variant="body2">タスク削除</Typography>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </Box>
  );
};
