import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";

import { MentorAndSupportMemberList } from "~/components/domains/employees";
import { Divider, Typography, UserIconWithLabel } from "~/components/uiParts";

type Props = {
  mentor?: Employee | null;
  supportMembers?: Employee[];
  admins: Employee[];
};
export const AccessibleEmployeeList: FC<Props> = ({ mentor, supportMembers, admins }) => {
  return (
    <>
      <MentorAndSupportMemberList mentor={mentor} supportMembers={supportMembers} onlyView />

      <Box mt={4} mb={4}>
        <Divider />
      </Box>

      <Box>
        <Typography variant="body2" bold>
          管理者
        </Typography>
        {admins.map((employee) => (
          <Box mt={2} key={employee.id}>
            <UserIconWithLabel
              iconPath={employee.profileIconImageUrl}
              name={employee.getName()}
              secondaryText={employee.email}
              iconCircular
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
