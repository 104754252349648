import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Tooltip, Typography, UserIcon } from "~/components/uiParts";

const getTooltipTitleForMentor = (isEditable: boolean, mentor?: Employee) => {
  if (mentor) {
    return "受け入れチーム設定を開く";
  } else if (isEditable) {
    return "バディを設定する";
  }
  return "";
};

const getMentorLabelText = (mentor?: Employee) => {
  if (!mentor) return "未登録";

  // 設定済みでも招待中で氏名が設定されていなければemailを表示する
  if (mentor.getName().trim()) {
    return mentor.getName();
  } else {
    return mentor.email;
  }
};

type Props = {
  mentor?: Employee;
  supportMembers?: Employee[];
  isEditable: boolean;
  onClickMentor: () => void;
};

export const EmployeeMentor: FC<Props> = ({ mentor, isEditable, onClickMentor }) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Tooltip arrow placement="bottom" title={getTooltipTitleForMentor(isEditable, mentor)}>
        <StyledClickableBox
          display="flex"
          alignItems="center"
          $isClickable={isEditable || Boolean(mentor)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickMentor();
          }}
        >
          <UserIcon
            circular
            size="small"
            username={mentor ? mentor.getName() : "未登録"}
            profileIconImageUrl={mentor?.profileIconImageUrl}
            badgeType={mentor ? undefined : "dot"}
            borderColor="primary"
          />
          <Box display="inline-block" width="8px" />
          <Typography
            variant="caption"
            color={mentor ? "textPrimary" : "textSecondary"}
            display="block"
            noWrap
            disablePreWrap
          >
            {getMentorLabelText(mentor)}
          </Typography>
        </StyledClickableBox>
      </Tooltip>
    </Box>
  );
};

const StyledClickableBox = styled(Box)<{ $isClickable: boolean }>`
  ${({ $isClickable }) => ($isClickable ? `cursor : pointer;` : `cursor: default;`)}
  width: 100%;
  height: 40px;
`;
