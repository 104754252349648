import { Box } from "@material-ui/core";
import { OnboardingExperienceGeneralTask } from "@onn/common";
import React, { useMemo, FC } from "react";
import styled from "styled-components";

import { Button, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  task: OnboardingExperienceGeneralTask;
};

export const ViewOnboardingExperienceGeneralTaskModal: FC<Props> = ({ open, onCancel, task }) => {
  const content = useMemo(
    () => (
      <>
        <Box mb="32px">
          <StyledHeadBox>
            <Typography variant="body2" bold>
              タイトル
            </Typography>
          </StyledHeadBox>
          <Typography variant="body1">{task.title}</Typography>
        </Box>
        <Box mb="32px">
          <StyledHeadBox>
            <Typography variant="body2" bold>
              説明
            </Typography>
          </StyledHeadBox>
          <Typography variant="body1">{task.body}</Typography>
        </Box>
        <Box mb="32px">
          <StyledHeadBox>
            <Typography variant="body2" bold>
              通知タイミング
            </Typography>
          </StyledHeadBox>
          <Typography variant="body1">入社者招待後</Typography>
        </Box>
        <Box mb="26px">
          <StyledHeadBox>
            <Typography variant="body2" bold>
              期日
            </Typography>
          </StyledHeadBox>
          <Typography variant="body1">{task.dueDate.getDisplayTiming()}</Typography>
        </Box>
        <StyledCaptionBox>
          <StyledTypography variant="caption">
            ※ Onnのデフォルト機能のため編集や削除はできません
            <br />※ 全ての受け入れ体験共通で適応されています
          </StyledTypography>
        </StyledCaptionBox>
      </>
    ),
    [task]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          閉じる
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel]
  );

  return (
    <Modal title="共通タスク" open={open} onCancel={onCancel} content={content} footer={footer} />
  );
};

const StyledHeadBox = styled(Box)`
  margin-bottom: 16px;
`;

const StyledCaptionBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
