import { useCallback } from "react";

import { useCurrentUser } from "../employee";
import { useNotifyOperationLog, useSnackbar } from "../shared";

import { SegregateSlackUseCase } from "~/service/usecases/tenant";
import { captureException } from "~/util";

const segregateSlackUseCase = new SegregateSlackUseCase();

/**
 * テナントのSlack連携を解除するための関数hooks
 * @returns isLoading データを処理中かどうか
 * @returns segregateSlack
 */
export const useSegregateSlack = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { notifyOperationLog } = useNotifyOperationLog();

  /**
   * テナントのSlack連携を解除するための関数
   */
  const segregateSlack = useCallback(async () => {
    return await segregateSlackUseCase
      .execute(currentUser)
      .then(() => {
        enqueueSnackbar("Slack連携を解除しました", { variant: "success" });
        notifyOperationLog({
          type: "success",
          title: `${currentUser.getName()}さんがSlack連携を解除しました`,
          text: `<!here> \nテナント: ${currentUser.tenantId}`,
          footer: {
            icon: "slack",
            processName: "Slack連携の解除",
          },
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useSegregateSlack:segregateSlack",
          },
        });
      });
  }, [currentUser, enqueueSnackbar, notifyOperationLog]);

  return {
    segregateSlack,
  };
};
