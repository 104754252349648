import { addDays, format, isThisYear, isToday, isYesterday } from "date-fns";
import { v4 } from "uuid";

import { Employee } from "../../Employee";

type EmojiId = "👍" | "👏" | "👀" | "🎉";
type ContactEmoji = {
  id: EmojiId;
  count: number;
};

export class ContactMessage {
  id: string;
  contactRoomId: string;
  text?: string;
  filePaths?: string[];
  /**
   * LINE stamp sticker id
   */
  stickerId?: string;
  emojis?: ContactEmoji[];
  createdEmployeeId: string;
  updatedEmployeeId?: string;
  createdAt: Date;
  updatedAt: Date;
  // LINE未連携のコンタクトルームで発生するLINEユーザーのid
  // TODO: LINEアカウント追加時にEmployeeを発行するように修正される
  lineUserId?: string;
  // 送信取り消しように保持するid
  messageIdOfLine?: string;
  // 送信取り消しされたかどうか
  unsend?: boolean;
  isNewHireMessage: boolean;
  isSentByOfficialName?: boolean;
  isFailedDelivery?: boolean;
  // どのプラットフォームを経由してメッセージが作成されたのか
  platformTypeCreatedBy?: "onn" | "email" | "line" | "slack";
  tenantId: string;
  constructor(init: ExcludeMethods<ContactMessage>) {
    this.id = init.id;
    this.contactRoomId = init.contactRoomId;
    this.text = init.text;
    this.filePaths = init.filePaths;
    this.stickerId = init.stickerId;
    this.emojis = init.emojis;
    this.createdEmployeeId = init.createdEmployeeId;
    this.updatedEmployeeId = init.updatedEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
    this.lineUserId = init.lineUserId;
    this.messageIdOfLine = init.messageIdOfLine;
    this.unsend = init.unsend;
    this.isNewHireMessage = init.isNewHireMessage;
    this.isSentByOfficialName = init.isSentByOfficialName;
    this.isFailedDelivery = init.isFailedDelivery;
    this.platformTypeCreatedBy = init.platformTypeCreatedBy;
    this.tenantId = init.tenantId;
  }

  failToDelivery(): ContactMessage {
    return new ContactMessage({
      ...this,
      isFailedDelivery: true,
      updatedAt: new Date(),
    });
  }

  getDisplayDate(): string | null {
    const createdAt = this.createdAt;
    if (isToday(createdAt)) {
      // 当日の場合は時刻表示
      return format(createdAt, "HH:mm");
    }

    if (isYesterday(createdAt)) {
      return "昨日";
    }

    if (isYesterday(addDays(createdAt, 1))) {
      return "一昨日";
    }

    // 同じ年でない場合は年も表示する
    if (!isThisYear(createdAt)) {
      return format(createdAt, "yyyy/MM/dd");
    }

    // それ以外は日付表示
    return format(createdAt, "MM/dd");
  }

  public static create(params: Optional<ExcludeMethods<ContactMessage>, "id">): ContactMessage {
    return new ContactMessage({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static createNewMessage(params: CreateNewMessageParams): ContactMessage {
    const employee = params.creator.employee;

    return ContactMessage.create({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
      filePaths: params.filePaths || [],
      emojis: [],
      createdEmployeeId: employee.id,
      updatedEmployeeId: employee?.id,
      isNewHireMessage:
        "lineUserId" in params.creator || (!!employee && employee.assignedAsNewcomer),
      isSentByOfficialName: "isOfficialName" in params.creator,
      tenantId: params.tenantId,
    });
  }

  static plainToInstance(init: ExcludeMethods<ContactMessage>): ContactMessage {
    return new ContactMessage(init);
  }
}

type CreateNewMessageParams = {
  contactRoomId: string;
  creator: { employee: Employee };
  messageIdOfLine?: string;
  platformTypeCreatedBy: ContactMessage["platformTypeCreatedBy"];
  tenantId: string;
} & (
  | {
      text?: string;
      filePaths?: string[];
      stickerId?: never;
    }
  | {
      text?: never;
      filePaths?: never;
      stickerId: string;
    }
);
