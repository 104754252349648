import { SurveyTransaction } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { SurveyTransactionUseCase } from "~/service/usecases/transactionUseCase";

/**
 * employeeIdをもとにsurveyTransactionsを返すswr
 * @param employeeId employeeのid
 */
export const useSurveyTransactionsByEmployeeId = (
  employeeId: string
): SWRResponse<SurveyTransaction[], Error> => {
  const key = employeeId ? `survey-transactions-employee-${employeeId}` : null;

  return useSWR(key, () =>
    SurveyTransactionUseCase.whereByEmployeeId(employeeId).then((res) => res)
  );
};
