import { Box } from "@material-ui/core";
import { ContactMessage, Department, Employee } from "@onn/common";
import { format } from "date-fns";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { FileThumbnails } from "./FileThumbnails";
import { MessageText } from "./MessageText";
import { UserIcon as ContactMessageUserIcon } from "./UserIcon";
import { UserName } from "./UserName";

import { Typography, UserIcon } from "~/components/uiParts";
import { SmallUserIconGroup } from "~/components/uiParts/SmallUserIconGroup";
import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

type Props = {
  variant: "left" | "right";
  alreadyReadUsersInfo?: {
    username: ComponentProps<typeof UserIcon>["username"];
    profileIconImageUrl?: ComponentProps<typeof UserIcon>["profileIconImageUrl"];
    borderColor?: ComponentProps<typeof UserIcon>["borderColor"];
  }[];
  message: ContactMessage & { creatorName: string; creatorImageUrl?: string };
  departments: Department[];
  newHire?: Employee;
  isFileSending?: boolean;
};

export const NewHireContactMessageItem: FC<Props> = ({
  message,
  variant,
  alreadyReadUsersInfo = [],
  departments,
  newHire,
  isFileSending,
}) => {
  // 添付ファイルの送信中に取得してしまうと、エラーが返ってくるため、送信中のファイルは取得しないようにする
  const shouldFetchMetaData = message.filePaths && !isFileSending;
  const { data: files, isValidating: isValidatingMetadata } = useMetaDataByUrls(
    shouldFetchMetaData ? message.filePaths || [] : []
  );
  const { setFiles, setPreviewFileIndex, handleDownloadFile } = useFileViewer();

  if (message.unsend) {
    return (
      <StyledBox padding="8px 16px" width="350px">
        <Typography
          variant="caption"
          color="textSecondary"
        >{`${message.creatorName}がメッセージの送信を取り消しました`}</Typography>
      </StyledBox>
    );
  }
  return (
    <Box display="flex" flexDirection={variant === "left" ? "row" : "row-reverse"} gridGap="8px">
      <Box display="flex" flexDirection="column">
        <ContactMessageUserIcon
          name={message.creatorName}
          imageUrl={message.creatorImageUrl}
          newHire={newHire}
          departments={departments}
          isNewComerMessage={!!message.isNewHireMessage}
          shouldDisplayContactRoomButton={false}
        />
      </Box>
      <Box display="flex" flexDirection="column" gridGap="8px" maxWidth="70%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="8px"
          flexDirection={variant === "left" ? "row" : "row-reverse"}
        >
          <UserName
            userName={message.creatorName}
            newHire={newHire}
            departments={departments}
            shouldDisplayTooltip={!!message.isNewHireMessage}
            shouldDisplayContactRoomButton={false}
          />
          <StyledCreatedAtTypography variant="caption" color="textSecondary">
            {format(message.createdAt, "yyyy/MM/dd HH:mm")}
          </StyledCreatedAtTypography>
        </Box>
        {message.text && <MessageText variant={variant} messageText={message.text} />}
        {(isValidatingMetadata || files || isFileSending) && (
          <FileThumbnails
            variant={variant}
            messageText={message.text}
            isValidatingMetadata={isValidatingMetadata}
            isFileSending={isFileSending}
            files={files}
            handleDownloadFile={handleDownloadFile}
            setFiles={setFiles}
            setPreviewFileIndex={setPreviewFileIndex}
          />
        )}
        {message.isFailedDelivery && (
          <Typography variant="caption" color="textSecondary">
            送信に失敗しました
          </Typography>
        )}
        {alreadyReadUsersInfo.length > 0 && <SmallUserIconGroup userInfo={alreadyReadUsersInfo} />}
      </Box>
    </Box>
  );
};

const StyledCreatedAtTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 10px;
`;
