import { Employee, OnboardingExperience, OnboardingStatus } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

import { useAccessControl } from "../../accessControl";
import {
  useCompleteOnboardingGeneralTask,
  useGenerateTasksFormExperience,
} from "../../onboardingTask";

import { useAllNewcomers } from "./../useAllNewcomers";
import { generateUseEmployeeKeys } from "./../useEmployee";
import { useAssignMentor } from "./useAssignMentor";
import { useAssignSupportMembers } from "./useAssignSupportMembers";
import { useDismissSupportMember } from "./useDismissSupportMember";

import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { generateUseOnboardingTasksKeys } from "~/hooks/onboardingTask/useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask/useOnboardingTasksByEmployeeId";
import { useSnackbar } from "~/hooks/shared";
import { DepartmentUseCase } from "~/service/usecases/departmentUseCase";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

const departmentUseCase = new DepartmentUseCase();

/**
 * 入社者操作系のモーダルの呼び出し
 * 複数箇所で利用されるのでこのモーダルにロジックなどを隠蔽する
 */
export const useEmployeeModals = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useCurrentUser();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();
  const { isEditable } = useAccessControl();

  const { dismissSupportMember } = useDismissSupportMember();
  const { assignMentor } = useAssignMentor();
  const { assignSupportMembers } = useAssignSupportMembers();
  const { completeOnboardingGeneralTask } = useCompleteOnboardingGeneralTask();
  const { generateTasksFormExperience } = useGenerateTasksFormExperience();

  const handleOpenAssignMentorModal = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) =>
      handleModal({
        name: "assignMentorModal",
        args: {
          onSubmit: async (newMentorId?: string, emailForInvite?: string) => {
            // バディアサイン
            await assignMentor({ newHire, emailForInvite, newMentorId, mentor });
          },
          newHire,
          mentor,
          supportMembers,
        },
      }),
    [assignMentor, handleModal]
  );

  const handleOpenAssignSupportMembersModal = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) =>
      handleModal({
        name: "assignSupportMembersModal",
        args: {
          onSubmit: async (employeeIds: string[], emailsToInviteSupportMembers: string[]) => {
            await assignSupportMembers({ emailsToInviteSupportMembers, employeeIds, newHire });
          },
          newHire,
          mentor,
          supportMembers,
        },
      }),
    [assignSupportMembers, handleModal]
  );

  const handleOpenChangeEmployeeNameModal = useCallback(
    (newHire: Employee) =>
      handleModal({
        name: "changeEmployeeNameModal",
        args: {
          onSubmit: async (
            employeeId: string,
            { lastName, firstName }: { lastName: string; firstName: string }
          ) => {
            await EmployeeUseCase.update(employeeId, { lastName, firstName })
              .then(async () => {
                enqueueSnackbar("入社者の名前を変更しました", { variant: "success" });
                mutate(generateUseEmployeeKeys(employeeId));
                mutateAllNewcomers();
              })
              .catch((e) => {
                enqueueSnackbar("入社者の名前変更に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenChangeEmployeeNameModal" },
                });
              });
          },
          employee: newHire,
        },
      }),
    [enqueueSnackbar, handleModal, mutate, mutateAllNewcomers]
  );

  const handleOpenChangeMentorConfirmModal = useCallback(
    (newHire: Employee, mentor: Employee, supportMembers?: Employee[]) =>
      handleModal({
        name: "changeMentorConfirmModal",
        args: {
          onSubmit: () => handleOpenAssignMentorModal(newHire, mentor, supportMembers),
          profileIconImageUrl: mentor.profileIconImageUrl || "",
          username: mentor.getName(),
        },
      }),
    [handleModal, handleOpenAssignMentorModal]
  );

  const handleOpenDeleteEmployeeModal = useCallback(
    (newHire: Employee) => {
      handleModal({
        name: "deleteMemberModal",
        args: {
          onSubmit: async () => {
            await EmployeeUseCase.deleteNewHire(newHire.id)
              .then(async () => {
                enqueueSnackbar("入社者を削除しました", { variant: "success" });
                // topページ以外で削除した場合はtopページに戻る
                if (location.pathname !== "/") {
                  navigate("/");
                }
                mutateAllNewcomers();
              })
              .catch((e) => {
                enqueueSnackbar("入社者の削除に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenDeleteEmployeeModal" },
                });
              });
          },
          profileIconImageUrl: newHire.profileIconImageUrl,
          username: newHire.getName(),
        },
      });
    },
    [handleModal, enqueueSnackbar, mutateAllNewcomers, navigate]
  );

  const handleOpenDismissSupportMemberConfirmModal = useCallback(
    (newHire: Employee, supportMember: Employee) =>
      handleModal({
        name: "dismissSupportMemberConfirmModal",
        args: {
          onSubmit: async () => {
            await dismissSupportMember(newHire.id, supportMember.id).then(async () => {
              mutate(generateUseEmployeeKeys(newHire.id));
              mutateAllNewcomers();
            });
          },
          profileIconImageUrl: supportMember.profileIconImageUrl,
          username: supportMember.getName(),
        },
      }),
    [dismissSupportMember, handleModal, mutate, mutateAllNewcomers]
  );

  const handleOpenIntegrateSlackModal = useCallback(
    (newHire: Employee) =>
      handleModal({
        name: "integrateSlackModal",
        args: {
          onSubmit: async (selectedSlackUser) => {
            await EmployeeUseCase.integrateToSlack(
              selectedSlackUser.email,
              newHire.id,
              selectedSlackUser.slackUserId,
              selectedSlackUser.image_192
            )
              .then(() => {
                enqueueSnackbar("Slackとの連携に成功しました", { variant: "success" });
                mutate(generateUseEmployeeKeys(newHire.id));
                mutateAllNewcomers();
                mutate(generateUseOnboardingTasksByEmployeeId(newHire.id));
                mutate(generateUseOnboardingTasksKeys(newHire.tenantId));
              })
              .catch((e) => {
                enqueueSnackbar("Slackとの連携に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenIntegrateSlackModal" },
                });
              });
          },
          employee: newHire,
          currentUser: currentUser,
        },
      }),
    [currentUser, enqueueSnackbar, handleModal, mutate, mutateAllNewcomers]
  );

  const handleOpenJoinDateModal = useCallback(
    (newHire: Employee) =>
      handleModal({
        name: "joinDateModal",
        args: {
          onSubmit: async (newHireId: string, joinDate: string) => {
            await EmployeeUseCase.update(newHireId, {
              joinAt: joinDate,
              onboardingStatus: OnboardingStatus.JOIN_DATE_REGISTERED,
            })
              .then(() => {
                enqueueSnackbar("入社日を更新しました", { variant: "success" });
                mutate(generateUseEmployeeKeys(newHireId));
                mutateAllNewcomers();
              })
              .catch((e) => {
                enqueueSnackbar("入社日の更新に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenJoinDateModal" },
                });
              });

            await completeOnboardingGeneralTask("SET_JOIN_AT", newHire.id, newHire.tenantId);
          },
          newHireId: newHire.id,
          joinAt: newHire.joinAt,
          profileIconImageUrl: newHire.profileIconImageUrl,
          username: newHire.getName(),
          email: newHire.email,
        },
      }),
    [completeOnboardingGeneralTask, enqueueSnackbar, handleModal, mutate, mutateAllNewcomers]
  );

  const handleOpenManageMentorAndSupportMemberModal = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) =>
      handleModal({
        name: "manageMentorAndSupportMemberModal",
        args: {
          onClickAddMentorButton: () => {
            handleOpenAssignMentorModal(newHire, mentor, supportMembers);
          },
          onClickAddAssignSupportMember: () => {
            handleOpenAssignSupportMembersModal(newHire, mentor, supportMembers);
          },
          onClickChangeMentorButton: (mentor: Employee) => {
            handleOpenChangeMentorConfirmModal(newHire, mentor, supportMembers);
          },
          onClickDismissButton: (supportMember: Employee) => {
            handleOpenDismissSupportMemberConfirmModal(newHire, supportMember);
          },
          mentor,
          supportMembers,
          onlyView: !isEditable(newHire),
        },
      }),
    [
      handleModal,
      handleOpenAssignMentorModal,
      handleOpenAssignSupportMembersModal,
      handleOpenChangeMentorConfirmModal,
      handleOpenDismissSupportMemberConfirmModal,
      isEditable,
    ]
  );

  const handleOpenChangeDepartmentModal = useCallback(
    (newHire: Employee) =>
      handleModal({
        name: "changeDepartmentModal",
        args: {
          onSubmit: async (employee: Employee, departmentIds: string[]) => {
            // 部署の存在チェック
            if (!(await departmentUseCase.checkExist(employee.tenantId, departmentIds))) {
              enqueueSnackbar("部署の情報が更新されました。再度選択してください。", {
                variant: "info",
              });
              throw new Error();
            }

            await EmployeeUseCase.update(employee.id, { departmentIds })
              .then(async () => {
                enqueueSnackbar("部署が変更されました", { variant: "success" });
                mutate(generateUseEmployeeKeys(employee.id));
                mutateAllNewcomers();
              })
              .catch((e) => {
                enqueueSnackbar("部署の変更に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenChangeDepartmentModal" },
                });
              });
          },
          employee: newHire,
        },
      }),
    [enqueueSnackbar, handleModal, mutate, mutateAllNewcomers]
  );

  const handleOpenGenerateTasksFormExperienceModal = useCallback(
    (
      newHire: Employee,
      onboardingExperiencesByTenantId: OnboardingExperience[],
      mutateOnboardingTasks: () => void
    ) => {
      handleModal({
        name: "generateTasksFormExperienceModal",
        args: {
          onboardingExperiences: onboardingExperiencesByTenantId,
          onSubmit: async (selectedIds: string[]) => {
            await generateTasksFormExperience([newHire], selectedIds).then(() =>
              mutateOnboardingTasks()
            );
          },
        },
      });
    },
    [generateTasksFormExperience, handleModal]
  );

  return {
    handleOpenAssignMentorModal,
    handleOpenAssignSupportMembersModal,
    handleOpenChangeEmployeeNameModal,
    handleOpenDeleteEmployeeModal,
    handleOpenDismissSupportMemberConfirmModal,
    handleOpenChangeMentorConfirmModal,
    handleOpenIntegrateSlackModal,
    handleOpenJoinDateModal,
    handleOpenManageMentorAndSupportMemberModal,
    handleOpenChangeDepartmentModal,
    handleOpenGenerateTasksFormExperienceModal,
  };
};
