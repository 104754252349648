import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee } from "@onn/common";
import { compareAsc, compareDesc } from "date-fns";
import React, { useState, useEffect, useMemo, FC, ChangeEvent, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { BasicTab } from "./BasicTab";
import { DepartmentTab } from "./DepartmentTab";
import { ExternalToolTab } from "./ExternalToolTab";
import { FeatureTab } from "./FeatureTab";
import { MemberTab } from "./MemberTab";
import { TenantTab } from "./TenantTab";

import { Typography } from "~/components/uiParts";
import { useCurrentUser, useAllEmployees } from "~/hooks/employee";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";

type TabValue = "member" | "basic" | "feature" | "tenant" | "department" | "tag" | "externalTool";
type OrderType = "asc" | "desc";

export const SettingsAdmin: FC = () => {
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<TabValue>("member");
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState<OrderType>("desc");

  const { allEmployees: employees, mutateAllEmployees: mutateEmployees } = useAllEmployees();

  /**
   * ユーザー名・メールアドレスの検索
   */
  const filterEmployeesBySearchValue = useCallback(
    (employees: Employee[], searchValue: string) => {
      return filterObjectByPartialMatch({
        objects: employees,
        searchText: searchValue,
        getProperties: [
          (employee: Employee) => employee.getName(),
          (employee: Employee) => employee.email,
        ],
      });
    },
    [filterObjectByPartialMatch]
  );

  /**
   * 最終ログイン日時でソート
   */
  const sortEmployees = (targetEmployees: Employee[], order: OrderType) => {
    return targetEmployees.sort((a, b) => {
      // lastRefreshTimeはnullableだが、 new Date(null) は 1970/1/1(numberにキャストすると0)になるため、単純にcompareできない
      // よって、nullのとき(未登録のとき)、descで最も上位に表示したいため、現在時刻にしてからcompareする
      const aDate = a.lastRefreshTime ? new Date(a.lastRefreshTime) : new Date();
      const bDate = b.lastRefreshTime ? new Date(b.lastRefreshTime) : new Date();
      return order === "asc" ? compareAsc(aDate, bDate) : compareDesc(aDate, bDate);
    });
  };

  // 検索 & ソート の結果を表示
  const displayEmployees = useMemo(() => {
    return employees && sortEmployees(filterEmployeesBySearchValue(employees, searchValue), order);
  }, [employees, filterEmployeesBySearchValue, searchValue, order]);

  useEffect(() => {
    if (!currentUser.isAdmin()) {
      navigate(`#member`);
      setActiveTab("member");
    }

    switch (location.hash) {
      case "#basic": {
        setActiveTab("basic");
        break;
      }
      case "#feature": {
        setActiveTab("feature");
        break;
      }
      case "#tenant": {
        setActiveTab("tenant");
        break;
      }
      case "#department": {
        setActiveTab("department");
        break;
      }
      case "#tag": {
        setActiveTab("tag");
        break;
      }
      case "#externalTool": {
        setActiveTab("externalTool");
        break;
      }
      default: {
        setActiveTab("member");
        break;
      }
    }
  }, [currentUser, location.hash, navigate]);

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  const { isActiveNewGraduate } = tenant;

  const tabs: Array<{ label: string; value: string }> = useMemo(
    () => [
      {
        label: `ユーザー（${displayEmployees ? displayEmployees.length : "--"}）`,
        value: "member",
      },
      ...(currentUser.isAdmin()
        ? [
            {
              label: "基本設定",
              value: "basic",
            },
            {
              label: "機能設定",
              value: "feature",
            },
            {
              label: "会社設定",
              value: "tenant",
            },
            ...(isActiveNewGraduate
              ? []
              : [
                  {
                    label: "部署管理",
                    value: "department",
                  },
                ]),
            ...(isActiveNewGraduate
              ? [
                  {
                    label: "タグ管理",
                    value: "tag",
                  },
                ]
              : []),
            {
              label: "外部ツール連携",
              value: "externalTool",
            },
          ]
        : []),
    ],
    [currentUser, displayEmployees, isActiveNewGraduate]
  );

  return (
    <StyledBox py={6} px={5} display="flex" flexDirection="column" width="100%" alignItems="center">
      <Box width="100%" maxWidth="1090px">
        <Typography variant="h4" bold>
          管理者設定
        </Typography>
        <TabContext value={activeTab}>
          <StyledTabList
            onChange={handleChangeActiveTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <StyledTabPanel value="member">
            <Box pt="40px" pb="32px">
              {/*
              employeesが0人という状態は基本的におきえないので、
              MemberTab以下でemployeesがfetch中かどうかをemployeesが空かどうかで判別できるように
              ここでundefinedの場合[]を渡すように記載している
            */}
              <MemberTab
                displayEmployees={displayEmployees ?? []}
                mutateEmployees={mutateEmployees}
                setSearchValue={setSearchValue}
                order={order}
                setOrder={setOrder}
              />
            </Box>
          </StyledTabPanel>
          {currentUser.isAdmin() && (
            <>
              <StyledTabPanel value="basic">
                <Box pt="40px" pb="32px">
                  <BasicTab />
                </Box>
              </StyledTabPanel>
              <StyledTabPanel value="feature">
                <Box pt="40px" pb="32px">
                  <FeatureTab />
                </Box>
              </StyledTabPanel>
              <StyledTabPanel value="tenant">
                <Box pt="40px" pb="32px">
                  <TenantTab />
                </Box>
              </StyledTabPanel>
              <StyledTabPanel value="department">
                <Box pt="40px" pb="32px">
                  <DepartmentTab />
                </Box>
              </StyledTabPanel>
              <StyledTabPanel value="externalTool">
                <Box pt="40px" pb="32px">
                  <ExternalToolTab />
                </Box>
              </StyledTabPanel>
            </>
          )}
        </TabContext>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  min-height: 100vh;
  background: ${(props) => props.theme.palette.grey[50]};
`;
const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
