import { OnboardingTaskWithNewHire } from "@onn/common";
import { useState, useCallback } from "react";
import { useSWRConfig } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useCompleteOnboardingTasks, useDeleteOnboardingTasks } from "~/hooks/onboardingTask";
import { generateUseOnboardingTasksKeys } from "~/hooks/onboardingTask/useOnboardingTasks";

/**
 * 複数のタスクを扱うためのhooks
 */
export const useManageMultipleTasks = () => {
  const { currentUser } = useCurrentUser();

  const [selectedOnboardingTaskIds, setSelectedOnboardingTaskIds] = useState<string[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const { completeOnboardingTasks } = useCompleteOnboardingTasks();
  const { deleteOnboardingTasks } = useDeleteOnboardingTasks();

  const { handleModal } = useModal();
  const { mutate } = useSWRConfig();

  const handleClickCompleteMultipleTasks = useCallback(
    async (targetOnboardingTasks: OnboardingTaskWithNewHire[]) => {
      await completeOnboardingTasks(targetOnboardingTasks);
      mutate(generateUseOnboardingTasksKeys(currentUser.tenantId));
      setSelectedOnboardingTaskIds([]);
      setIsSelectedAll(false);
    },
    [completeOnboardingTasks, currentUser.tenantId, mutate]
  );

  const handleDeleteMultipleTasksModal = useCallback(
    (targetOnboardingTasks: OnboardingTaskWithNewHire[]) => {
      handleModal({
        name: "deleteMultipleTasksModal",
        args: {
          onSubmit: async () => {
            await deleteOnboardingTasks(targetOnboardingTasks);
            mutate(generateUseOnboardingTasksKeys(currentUser.tenantId));
            setSelectedOnboardingTaskIds([]);
            setIsSelectedAll(false);
          },
          count: targetOnboardingTasks.length,
        },
      });
    },
    [currentUser.tenantId, deleteOnboardingTasks, handleModal, mutate]
  );

  return {
    selectedOnboardingTaskIds,
    setSelectedOnboardingTaskIds,
    handleClickCompleteMultipleTasks,
    handleDeleteMultipleTasksModal,
    isSelectedAll,
    setIsSelectedAll,
  };
};
