import { Box, Grid, Menu, MenuItem, useMediaQuery } from "@material-ui/core";
import { OnboardingStatus, Employee, Department, MidCarrierPropertyForInvite } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, ReactNode, useMemo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { OpenInviteMembersModalButton } from "./OpenInviteMembersModalButton";

import { DepartmentFilter } from "~/components/domains/departments";
import { EmployeeTable } from "~/components/domains/employees";
import {
  DateFilter,
  FilterButton,
  IconButton,
  Loading,
  NotFoundPaper,
  Typography,
} from "~/components/uiParts";
import { SearchForm } from "~/components/uiParts/SearchForm";
import theme from "~/config/theme";
import { useDepartmentsByTenantId } from "~/hooks/department";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnboardingExperienceByTenantId } from "~/hooks/onboardingExperience";
import { useQuery } from "~/hooks/shared";
import { useInviteNewHires } from "~/pages/top/hooks/useInviteNewHires";

type StatusType = OnboardingStatus | "ALL";

type FilterOptionType = {
  title: string;
  totalCount: number;
  status: StatusType;
};

type Props = {
  filteredEmployeesForAllStatus: Employee[];
  employeesForTableView: Employee[];
  selectedDepartmentIds: string[];
  setSelectedDepartmentIds: (selectedDepartmentIds: string[]) => void;
  isSelectedNoDepartment: boolean;
  setIsSelectedNoDepartment: (isSelect: boolean) => void;
  selectedStatus: StatusType;
  selectedJoinAt: DateString[];
  selectableJoinAt: DateString[];
  setSelectedJoinAt: (selectedJoinAt: DateString[]) => void;
  setSelectedStatus: (selectedStatus: StatusType) => void;
  order: "asc" | "desc";
  setOrder: (order: "asc" | "desc") => void;
  invitedEmployees: Employee[];
  preboardingEmployees: Employee[];
  onboardingEmployees: Employee[];
  completedEmployees: Employee[];
  setSearchValue: (value: string) => void;
};

export const NewHireTab: FC<Props> = ({
  filteredEmployeesForAllStatus,
  employeesForTableView,
  selectedDepartmentIds,
  setSelectedDepartmentIds,
  isSelectedNoDepartment,
  setIsSelectedNoDepartment,
  selectedJoinAt,
  setSelectedJoinAt,
  selectableJoinAt,
  selectedStatus,
  setSelectedStatus,
  order,
  setOrder,
  invitedEmployees,
  preboardingEmployees,
  onboardingEmployees,
  completedEmployees,
  setSearchValue,
}) => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const { query } = useQuery();

  const { data: onboardingExperiences, isValidating: isValidatingOnBoardingExperiences } =
    useOnboardingExperienceByTenantId(currentUser.tenantId);
  const { data: departments, isValidating: isValidatingDepartments } = useDepartmentsByTenantId(
    currentUser.tenantId
  );

  const { inviteNewHires } = useInviteNewHires();

  const openDialogType = query.get("openDialogType");

  const filterOptions: FilterOptionType[] = [
    {
      title: "All Status",
      totalCount: filteredEmployeesForAllStatus.length,
      status: "ALL",
    },
    {
      title: "招待中",
      totalCount: invitedEmployees.length,
      status: OnboardingStatus.INVITED,
    },
    {
      title: "プレボーディング",
      totalCount: preboardingEmployees.length,
      status: OnboardingStatus.LOGGED_IN,
    },
    {
      title: "オンボーディング",
      totalCount: onboardingEmployees.length,
      status: OnboardingStatus.ANSWERED,
    },
    {
      title: "完了",
      totalCount: completedEmployees.length,
      status: OnboardingStatus.ONBOARDING_COMPLETED,
    },
  ];

  const handleClickFilterButton = (status: StatusType) => {
    if (status !== selectedStatus) {
      setSelectedStatus(status);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickedMenuItem = useCallback(() => {
    handleModal({
      name: "inviteMidCarrierWithCSVModal",
      args: {
        inviteType: "newHire",
        departments: departments || [],
        onboardingExperiences: onboardingExperiences || [],
        onClickUploadButton: async (inputValues: MidCarrierPropertyForInvite[]) => {
          await inviteNewHires(inputValues);
        },
      },
    });
    setAnchorEl(null);
  }, [handleModal, departments, onboardingExperiences, inviteNewHires]);

  // 自身の所属とその下位の所属 部門管理者はこれにしかアクセスできない
  const accessibleDepartments = useMemo(
    () =>
      (currentUser.isAdmin()
        ? departments
        : departments?.filter((department) =>
            Department.getChildIds(currentUser.departmentIds, departments).includes(department.id)
          )) || [],
    [currentUser, departments]
  );

  const hasNoDepartmentEmployee = useMemo(
    () => employeesForTableView.some((emp) => isEmpty(emp.departmentIds)),
    [employeesForTableView]
  );

  const openInviteMembersModal = useCallback(() => {
    handleModal({
      name: "inviteMidCarrierModal",
      args: {
        onSubmit: async (
          userDataArray: { email: string; departmentIds: string[]; employeeTagIds: string[] }[],
          selectedOnboardingExperienceIds: string[]
        ) => {
          await inviteNewHires(
            userDataArray.map((userData) => {
              return {
                ...userData,
                onboardingExperienceIds: selectedOnboardingExperienceIds,
                onnEventIds: [],
                selectedAuthenticationType: "email",
              };
            })
          );
        },
        currentUser,
        onboardingExperiences: onboardingExperiences || [],
        // 部門管理者の場合は自身の所属する部署しか招待できない
        departments: accessibleDepartments,
      },
    });
  }, [accessibleDepartments, currentUser, handleModal, inviteNewHires, onboardingExperiences]);

  useEffect(() => {
    if (openDialogType === "invite" && !isValidatingOnBoardingExperiences) {
      openInviteMembersModal();
    }
  }, [
    departments,
    isValidatingOnBoardingExperiences,
    onboardingExperiences,
    openDialogType,
    openInviteMembersModal,
  ]);

  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [text, buttons] = useMemo(() => {
    let text = "";
    let buttons: ReactNode | undefined = undefined;

    switch (selectedStatus) {
      case "ALL":
        text = `新しいメンバーを追加して、体系的な入社オンボーディングをはじめましょう！\n入社者を追加すると、自動的にOnnのアカウント登録や自己紹介の記入を促すメールが入社者に送られます。`;
        buttons = [
          <OpenInviteMembersModalButton
            key="invite-member-modal-button"
            onClick={() => openInviteMembersModal()}
          />,
        ];
        break;
      case OnboardingStatus.INVITED:
        text = "招待中の入社者はいません";
        break;
      case OnboardingStatus.LOGGED_IN:
        text = "プレボーディング中の入社者はいません";
        break;
      case OnboardingStatus.ANSWERED:
        text = "オンボーディング中の入社者はいません";
        break;
      case OnboardingStatus.ONBOARDING_COMPLETED:
        text = "オンボーディングが完了した入社者はいません";
        break;
      default:
        break;
    }
    return [text, buttons];
  }, [openInviteMembersModal, selectedStatus]);

  // TODO: swrがv2系になったらisLoadingに置き換える
  if (
    (isValidatingOnBoardingExperiences && !onboardingExperiences) ||
    (isValidatingDepartments && !departments)
  ) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <>
      <StyledBox py={5}>
        {filterOptions.map((option: FilterOptionType, i) => (
          <Box mr={i === 0 ? 5 : 2} key={`FilterButtonBox__${i}`}>
            <FilterButton
              title={option.title}
              totalCount={option.totalCount}
              isSelected={option.status === selectedStatus}
              onClick={() => handleClickFilterButton(option.status)}
            />
          </Box>
        ))}
      </StyledBox>
      {!currentUser.isMember() && (
        <Box mb="36px" display="flex" alignItems="flex-end" width="100%">
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={4} md={3}>
              <Box display="flex" flexDirection="column" gridRowGap="8px">
                <Typography variant="body2" bold color="textSecondary">
                  部署
                </Typography>
                <DepartmentFilter
                  departments={accessibleDepartments}
                  selectedDepartmentIds={selectedDepartmentIds}
                  isSelectedNoDepartment={isSelectedNoDepartment}
                  onChange={(selectedDepartmentIds) =>
                    setSelectedDepartmentIds(selectedDepartmentIds)
                  }
                  isShowNoDepartment={hasNoDepartmentEmployee}
                  onChangeNoDepartmentCheckbox={() =>
                    setIsSelectedNoDepartment(!isSelectedNoDepartment)
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={4} md={3}>
              <Box display="flex" flexDirection="column" gridRowGap="8px">
                <Typography variant="body2" bold color="textSecondary">
                  入社日
                </Typography>
                <DateFilter
                  onChange={setSelectedJoinAt}
                  selectedDateArray={selectedJoinAt}
                  selectableDateArray={selectableJoinAt}
                />
              </Box>
            </Grid>
            {md && <Grid md={2}></Grid>}
            <Grid item xs={4} md={4}>
              <SearchForm
                onSearchValue={setSearchValue}
                placeholder="ユーザー名・メールアドレスで検索"
                variant="standard"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box display="flex" alignItems="flex-end">
            <Box ml={5} mr={3}>
              <OpenInviteMembersModalButton onClick={openInviteMembersModal} />
            </Box>
            <IconButton icon="menuVert" onClick={handleOpenMenu} />
            <Menu
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem onClick={handleClickedMenuItem}>
                <Typography variant="body2">{"CSVアップロード"}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      )}
      {employeesForTableView && (
        <>
          {!isEmpty(employeesForTableView) ? (
            <EmployeeTable
              displayEmployees={employeesForTableView}
              departments={departments || []}
              order={order}
              setOrder={setOrder}
            />
          ) : currentUser.isMember() ? (
            <NotFoundPaper text="バディ・サポートメンバーに設定されていないため、表示する入社者がいません" />
          ) : (
            <NotFoundPaper text={text} buttons={buttons} />
          )}
        </>
      )}
    </>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  overflow-x: scroll;
`;
