import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { useManageMultipleTasks } from "./useManageMultipleTasks";
import { useViewModel } from "./useViewModel";

import { EmployeeFilter } from "~/components/domains/employees/EmployeeFilter";
import {
  OnboardingTaskSummarySidebar,
  OnboardingTaskTable,
  useSidebarTab,
} from "~/components/domains/onboardingTask";
import { useGetEmployeesByAssigneeRole } from "~/components/domains/onboardingTask/hooks";
import {
  DateFilter,
  Typography,
  FilterButton,
  IconButton,
  Tooltip,
  Button,
} from "~/components/uiParts";
import { useHandleManageMessageTaskModal } from "~/hooks/onboardingTask/messageTask";
import { useSetQueryString } from "~/hooks/shared";

export const TaskListTab: FC = () => {
  const { getEmployeesByAssigneeRole } = useGetEmployeesByAssigneeRole();
  const { setQueryString } = useSetQueryString();
  const { handleOpenManageMessageTaskModal } = useHandleManageMessageTaskModal();
  const { sidebarTab, setSidebarTab } = useSidebarTab();

  const {
    selectedOnboardingTaskIds,
    setSelectedOnboardingTaskIds,
    handleClickCompleteMultipleTasks,
    handleDeleteMultipleTasksModal,
    isSelectedAll,
    setIsSelectedAll,
  } = useManageMultipleTasks();

  const {
    currentUser,
    targetTaskForSummary,
    setTargetTaskForSummary,
    targetEmployeeForSummary,
    setTargetEmployeeIdForSummary,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectableEmployeeIds,
    selectedNewHireIds,
    selectableNewHireIds,
    setSelectedNewHireIds,
    selectedStatus,
    setSelectedStatus,
    selectedJoinAtStrings,
    setSelectedJoinAtStrings,
    selectableDateArray,
    onboardingTasks,
    filteredOnboardingTasks,
    isValidatingOnboardingTasks,
    isValidatingNewHires,
    mentor,
    supportMembers,
    admins,
    specificEmployees,
    handleOpenEditTaskModal,
    handleClickDeleteTaskButton,
    handleClickChangeStatusButton,
    filterOptions,
    mutateOnboardingTasks,
  } = useViewModel();

  const selectedOnboardingTasks = useMemo(
    () => filteredOnboardingTasks.filter((v) => selectedOnboardingTaskIds.includes(v.id)),
    [filteredOnboardingTasks, selectedOnboardingTaskIds]
  );

  const isIncludeMessageTask = useMemo(
    () => selectedOnboardingTasks.some((task) => task.type === "MESSAGE_TASK"),
    [selectedOnboardingTasks]
  );

  return (
    <>
      <StyledBox py={5}>
        {filterOptions.map((option, i) => (
          <Box mr={i === 0 ? 5 : 2} key={`FilterButtonBox__${i}`}>
            <FilterButton
              loading={(isValidatingOnboardingTasks && !onboardingTasks) || isValidatingNewHires}
              title={option.title}
              totalCount={option.totalCount}
              isSelected={option.status === selectedStatus}
              onClick={() => {
                setQueryString({ selectedStatus: option.status });
                setSelectedStatus(option.status);
              }}
            />
          </Box>
        ))}
      </StyledBox>

      <Box pb={5}>
        <Box display="flex" alignItems="end" gridGap="24px" mb="24px">
          <Box display="flex" flexDirection="column" gridRowGap="16px" flex={1} maxWidth="240px">
            <Typography variant="body2" bold color="textSecondary">
              入社者
            </Typography>
            <EmployeeFilter
              selectableEmployeeIds={selectableNewHireIds}
              selectedEmployeeIds={selectedNewHireIds}
              onChange={(selectedEmployeeIds) => {
                setQueryString({ newHireIds: selectedEmployeeIds });
                setSelectedNewHireIds(selectedEmployeeIds);
              }}
              employeeType="employee"
            />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap="16px" flex={1} maxWidth="240px">
            <Typography variant="body2" bold color="textSecondary">
              担当者
            </Typography>
            <EmployeeFilter
              currentUser={currentUser}
              selectableEmployeeIds={[currentUser.id, ...selectableEmployeeIds]}
              selectedEmployeeIds={selectedEmployeeIds}
              onChange={(selectedEmployeeIds) => {
                setQueryString({ employeeIds: selectedEmployeeIds });
                setSelectedEmployeeIds(selectedEmployeeIds);
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap="16px" flex={1} maxWidth="240px">
            <Typography variant="body2" bold color="textSecondary">
              入社日
            </Typography>
            <DateFilter
              selectableDateArray={selectableDateArray}
              selectedDateArray={selectedJoinAtStrings}
              onChange={(joinAtArray) => {
                setQueryString({ joinAtStrings: joinAtArray });
                setSelectedJoinAtStrings(joinAtArray);
              }}
            />
          </Box>
          {!isEmpty(selectedOnboardingTasks) && (
            <Box ml="auto" display="flex" alignItems="center" gridGap="8px">
              <Tooltip
                title={isIncludeMessageTask ? "メッセージタスクが含まれるので完了にできません" : ""}
              >
                <Button
                  onClick={() => handleClickCompleteMultipleTasks(selectedOnboardingTasks)}
                  borderRadius="regular"
                  variant="outlined"
                  color="primary"
                  disabled={isIncludeMessageTask}
                >
                  一括完了
                </Button>
              </Tooltip>
              <Tooltip title="一括削除">
                <IconButton
                  onClick={() =>
                    handleDeleteMultipleTasksModal(
                      filteredOnboardingTasks.filter((v) =>
                        selectedOnboardingTaskIds.includes(v.id)
                      )
                    )
                  }
                  icon="trash"
                  color="grey"
                />
              </Tooltip>
            </Box>
          )}
        </Box>
        <OnboardingTaskTable
          // 初回だけローディングするためにonboardingTasksがあればローディングしない。
          // onboardingTasksWithNewHireのフィルターにnewHiresを使っているのでisValidatingNewHiresも条件に加えている
          isLoading={(isValidatingOnboardingTasks && !onboardingTasks) || isValidatingNewHires}
          onboardingTasksWithNewHire={filteredOnboardingTasks}
          selectedTaskStatus={selectedStatus}
          selectedOnboardingTaskIds={selectedOnboardingTaskIds}
          setSelectedOnboardingTaskIds={(ids: string[]) => setSelectedOnboardingTaskIds(ids)}
          isSelectedAll={isSelectedAll}
          setIsSelectedAll={setIsSelectedAll}
          isDisplayNewHire
          onClickEditTaskButton={handleOpenEditTaskModal}
          onClickDeleteTaskButton={handleClickDeleteTaskButton}
          onClickSwitchStatusButton={handleClickChangeStatusButton}
          onClickManageWelcomeMessageButton={handleOpenManageMessageTaskModal}
          onClickTableRow={(task, employeeId) => {
            setQueryString({ onboardingTaskId: task.id });
            setTargetTaskForSummary(task);
            setTargetEmployeeIdForSummary(employeeId);
            setSidebarTab("taskDetail");
          }}
          onClickOpenSidebarComments={(task) => {
            setQueryString({ onboardingTaskId: task.id });
            setTargetTaskForSummary(task);
            setSidebarTab("taskMemos");
          }}
        />
        {targetTaskForSummary && targetEmployeeForSummary && (
          <OnboardingTaskSummarySidebar
            onboardingTask={targetTaskForSummary}
            defaultTab={sidebarTab}
            newHire={targetEmployeeForSummary}
            assigneeEmployees={getEmployeesByAssigneeRole({
              onboardingTask: targetTaskForSummary,
              newHire: targetEmployeeForSummary,
              mentor,
              supportMembers,
              specificEmployees,
              admins,
            })}
            isEnableNewHireLink
            mutateOnboardingTasks={mutateOnboardingTasks}
            onClickCloseButton={() => {
              setQueryString({ onboardingTaskId: undefined });
              setTargetTaskForSummary(undefined);
            }}
            onClickEditButton={() => {
              if (targetEmployeeForSummary) {
                setQueryString({ onboardingTaskId: undefined });
                handleOpenEditTaskModal(targetTaskForSummary, targetEmployeeForSummary);
                setTargetTaskForSummary(undefined);
              }
            }}
            onClickDeleteTaskButton={() => {
              if (targetEmployeeForSummary) {
                setQueryString({ onboardingTaskId: undefined });
                handleClickDeleteTaskButton(targetTaskForSummary, targetEmployeeForSummary);
                setTargetTaskForSummary(undefined);
              }
            }}
            onClickChangeStatusButton={async () => {
              if (targetEmployeeForSummary) {
                await handleClickChangeStatusButton(targetTaskForSummary, targetEmployeeForSummary);
              }
            }}
            onClickGeneralTaskButton={() => {
              setQueryString({ onboardingTaskId: undefined });
              setTargetTaskForSummary(undefined);
            }}
          />
        )}
      </Box>
    </>
  );
};
const StyledBox = styled(Box)`
  display: flex;
  overflow-x: scroll;
`;
