import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState, useContext } from "react";
import styled from "styled-components";

import { EmployeesContext } from "~/components/providers";
import { Button, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
};
export const SegregateLineModal: FC<Props> = ({ open, onCancel, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAllEmployees } = useContext(EmployeesContext);
  const handleClickSegregateButton = useCallback(() => {
    setIsLoading(true);
    onSubmit()
      .then(() => onCancel())
      .finally(() => {
        setIsLoading(false);
        mutateAllEmployees();
      });
  }, [onCancel, onSubmit, mutateAllEmployees]);

  const content = (
    <Box textAlign="center" display="flex" flexDirection="column" gridRowGap="24px">
      <Typography variant="body1" color="textPrimary">
        {`LINEとの連携を解除します。よろしいですか？`}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        この操作は取り消すことができません。
      </Typography>
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={handleClickSegregateButton}
        disabled={isLoading}
      >
        解除
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal title="LINE連携解除" open={open} onCancel={onCancel} content={content} footer={footer} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
