import React, { FC } from "react";

export const Survey: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-7556 -2097)">
        <path
          d="M29871.006,13331a1.006,1.006,0,0,1-1.006-1v-16a1,1,0,0,1,1.006-1h3v-1a.993.993,0,0,1,1-1h8a1,1,0,0,1,1,1v1h3a1,1,0,0,1,1,1v16a1,1,0,0,1-1,1Zm1-2h14v-14h-2v1a1,1,0,0,1-1,1h-8a1,1,0,0,1-1-1v-1h-2Zm4-14h6v-2h-6Zm1.59,9.826-1.418-1.417a1,1,0,0,1,1.418-1.41l.705.705,2.123-2.123a1,1,0,0,1,1.41,0,1.008,1.008,0,0,1,0,1.418l-2.828,2.827a.995.995,0,0,1-1.41,0Z"
          transform="translate(-22311.004 -11211.997)"
          stroke="none"
        />
      </g>
    </svg>
  );
};
