import { Chip as MuiChip } from "@material-ui/core";
import React, { FC, ComponentProps } from "react";
import styled from "styled-components";

type Props = Omit<ComponentProps<typeof MuiChip>, "color"> & {
  color: "muted" | "grey" | "primary" | "secondary" | "darkGrey";
  label: string;
  isOutlined?: boolean;
  bold?: boolean;
};

export const Chip: FC<Props> = ({ color, isOutlined, bold = false, ...props }) => {
  switch (color) {
    case "muted":
      return <StyledMutedChip {...props} $isOutlined={isOutlined} $bold={bold} />;
    case "grey":
      return <StyledGreyChip {...props} $isOutlined={isOutlined} $bold={bold} />;
    case "darkGrey":
      return <StyledDarkGreyChip {...props} $isOutlined={isOutlined} $bold={bold} />;
    case "primary":
      return <StyledPrimaryChip {...props} $isOutlined={isOutlined} $bold={bold} />;
    case "secondary":
      return <StyledSecondaryChip {...props} $bold={bold} />;
  }
};

const StyledChip = styled(MuiChip)<{ $bold: boolean }>`
  &.MuiChip-root {
    font-weight: ${(props) => props.$bold && `bold`};
    font-size: 10px;
    height: 24px;
    padding: 4px;
  }
`;

const StyledMutedChip = styled(StyledChip)<{ $isOutlined?: boolean }>`
  &.MuiChip-root {
    color: ${(props) => props.theme.palette.grey[400]};
    background-color: #fff;
    border: ${(props) => props.$isOutlined && `1px solid ${props.theme.palette.grey[200]}`};
  }

  /* onClickが有効の場合 */
  &.MuiChip-clickable:hover,
  &.MuiChip-clickable:focus {
    background-color: #fff;
  }
`;

const StyledGreyChip = styled(StyledChip)<{ $isOutlined?: boolean }>`
  &.MuiChip-root {
    color: ${(props) =>
      props.$isOutlined ? props.theme.palette.text.primary : props.theme.palette.grey[500]};
    background-color: ${(props) =>
      props.$isOutlined ? props.theme.palette.background.default : props.theme.palette.grey[100]};
    border: ${(props) => props.$isOutlined && `1px solid ${props.theme.palette.text.primary}`};
  }

  /* onClickが有効の場合 */
  &.MuiChip-clickable:hover,
  &.MuiChip-clickable:focus {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const StyledDarkGreyChip = styled(StyledChip)<{ $isOutlined?: boolean }>`
  &.MuiChip-root {
    color: ${(props) => (props.$isOutlined ? props.theme.palette.grey[500] : "#fff")};
    background-color: ${(props) =>
      props.$isOutlined ? props.theme.palette.background.default : props.theme.palette.grey[400]};
    border: ${(props) => props.$isOutlined && `1px solid ${props.theme.palette.text.primary}`};
  }

  /* onClickが有効の場合 */
  &.MuiChip-clickable:hover,
  &.MuiChip-clickable:focus {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const StyledPrimaryChip = styled(StyledChip)<{ $isOutlined?: boolean }>`
  &.MuiChip-root {
    color: ${(props) =>
      props.$isOutlined
        ? props.theme.palette.text.primary
        : props.theme.palette.primary.contrastText};
    background-color: ${(props) =>
      props.$isOutlined
        ? props.theme.palette.background.default
        : props.theme.palette.primary.main};
    border: ${(props) => props.$isOutlined && `1px solid ${props.theme.palette.primary.main}`};
  }

  /* onClickが有効の場合 */
  &.MuiChip-clickable:hover,
  &.MuiChip-clickable:focus {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledSecondaryChip = styled(StyledChip)`
  &.MuiChip-root {
    color: ${(props) => props.theme.palette.primary.contrastText};
    background-color: ${(props) => props.theme.palette.secondary.main};
  }

  /* onClickが有効の場合 */
  &.MuiChip-clickable:hover,
  &.MuiChip-clickable:focus {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;
