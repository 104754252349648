import { Grid, Box } from "@material-ui/core";
import { Employee, SelectableFeatures, TenantSettings, Survey } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useEffect, useRef, useState, RefObject, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { NewcomerIntroductionSettings } from "./NewcomerIntroductionSettings";
import { OnboardingSurveySettings } from "./OnboardingSurveySettings";
import { OneMonthSurveySettings } from "./OneMonthSurveySettings";

import {
  Button,
  Icon,
  Typography,
  Switch,
  Loading,
  Paper,
  Modal,
  TextContext,
  AdminContentWrapper,
} from "~/components/uiParts";
import { useEmployees, useAdmins } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useModal } from "~/hooks/modal";
import {
  useOnboardingExperienceByTenantId,
  useOnboardingExperienceTasksByExperienceIds,
} from "~/hooks/onboardingExperience";
import { useQuery, useSnackbar } from "~/hooks/shared";
import { useScrollToTargetElement } from "~/hooks/shared/useScrollToTargetElement";
import { useUpdateDefaultFollowerIds } from "~/hooks/tenantSetting";
import { useTenantSettings } from "~/hooks/tenantSetting/useTenantSettings";
import { useUpdateTenantSettings } from "~/hooks/tenantSetting/useUpdateTenantSettings";
import { SurveyTemplateUseCase } from "~/service/usecases/surveyTemplateUseCase";
import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";
import { captureException, mixin } from "~/util";

const surveyTemplateUseCase = new SurveyTemplateUseCase();
const AUTO_SCROLL_TO_CONTENT_Y_OFFSET = 20;

const SectionIds = {
  NOTIFICATION_DELIVERY: "notificationDelivery",
  ONBOARDING: "onboarding",
  ONE_MONTH: "oneMonth",
} as const;
type SectionIds = (typeof SectionIds)[keyof typeof SectionIds];

interface ModalContent {
  title: string;
  moveTo: string;
}

export const FeatureTab: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { tenantSettings, mutateTenantSettings } = useTenantSettings();
  const { isLoading: isLoadingUpdateTenantSettings, updateTenantSettings } =
    useUpdateTenantSettings();
  const { updateDefaultFollowerIds } = useUpdateDefaultFollowerIds();
  const { data: accessibleEmployees = [], isValidating: isValidatingAccessibleEmployees } =
    useEmployees(tenantSettings?.contact ? tenantSettings.contact.defaultFollowerEmployeeIds : []);
  const { data: admins = [], isValidating: isValidatingAdmins } = useAdmins(currentUser.tenantId);
  const { data: onboardingExperiences } = useOnboardingExperienceByTenantId(currentUser.tenantId);

  const notificationDeliveryRef = useRef<HTMLDivElement>(null);
  const surveyRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef<HTMLDivElement>(null);
  const newcomerPortalRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState<boolean>(false);
  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();

  const [features, setFeatures] = useState<{ [key in SelectableFeatures]: boolean }>(
    tenantSettings.features
  );
  const [editingSections, setEditingSections] = useState<Set<SectionIds>>(new Set());
  const [onboardingSurvey, setOnboardingSurvey] = useState<Survey>();
  const [oneMonthSurvey, setOneMonthSurvey] = useState<Survey>();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>();

  const { handleModal } = useModal();

  const { query } = useQuery();
  const target = query.get("target");

  const handleClickOptionSettingButton = (sectionId: SectionIds) => {
    setEditingSections((prevState) => new Set(prevState.add(sectionId)));
  };

  const handleCancelSettingButton = (sectionId: SectionIds) => {
    setEditingSections((prevState) => {
      prevState.delete(sectionId);
      return new Set(prevState);
    });
  };

  const handleSubmitNewMemberIntroductionSettings = useCallback(
    async (newMemberIntroduction: TenantSettings["newcomerIntroduction"]) => {
      try {
        await updateTenantSettings(currentUser.tenantId, {
          newcomerIntroduction: newMemberIntroduction,
        });
        enqueueSnackbar("変更を保存しました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("保存に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "FeatureTab:handleSubmitNewMemberIntroductionSettings" },
        });
      } finally {
        handleCancelSettingButton(SectionIds.NOTIFICATION_DELIVERY);
        mutateTenantSettings();
      }
    },
    [currentUser.tenantId, enqueueSnackbar, mutateTenantSettings, updateTenantSettings]
  );

  const handleSubmitOnBoardingSurveySettings = async (newOnBoardingSurvey: Survey) => {
    setUpdating(true);
    try {
      await surveyTemplateUseCase.update(newOnBoardingSurvey);
      setOnboardingSurvey(newOnBoardingSurvey);
      enqueueSnackbar("変更を保存しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "FeatureTab:handleSubmitOnBoardingSurveySettings" },
      });
    } finally {
      setUpdating(false);
      handleCancelSettingButton(SectionIds.ONBOARDING);
    }
  };

  const handleSubmitOneMonthSurveySettings = async (newOneMonthSurvey: Survey) => {
    setUpdating(true);
    try {
      await surveyTemplateUseCase.update(newOneMonthSurvey);
      setOneMonthSurvey(newOneMonthSurvey);
      enqueueSnackbar("変更を保存しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "FeatureTab:handleSubmitOneMonthSurveySettings" },
      });
    } finally {
      setUpdating(false);
      handleCancelSettingButton(SectionIds.ONE_MONTH);
    }
  };

  const handleClickAddFollower = useCallback(
    (followers: Employee[]) => {
      handleModal({
        name: "addFollowersModal",
        args: {
          followers: followers,
          onSubmit: async (followerIds: string[], emailsWithoutOnnAccount: string[]) => {
            await updateDefaultFollowerIds(
              currentUser.tenantId,
              followerIds,
              emailsWithoutOnnAccount
            );
            mutateTenantSettings();
          },
        },
      });
    },
    [currentUser.tenantId, mutateTenantSettings, handleModal, updateDefaultFollowerIds]
  );

  const handleClickManageButton = useCallback(
    (followers: Employee[], admins: Employee[]) => {
      handleModal({
        name: "manageContactTeamModal",
        args: {
          // 閲覧できるユーザーが設定されていない場合は管理者を表示する
          followers: isEmpty(followers) ? admins : followers,
          onSubmit: async (followers: string[]) => {
            await updateDefaultFollowerIds(currentUser.tenantId, followers, []);
            mutateTenantSettings();
          },
          onClickAddFollower: (followers: Employee[]) => handleClickAddFollower(followers),
        },
      });
    },
    [
      currentUser.tenantId,
      mutateTenantSettings,
      handleClickAddFollower,
      handleModal,
      updateDefaultFollowerIds,
    ]
  );

  const onboardingExperienceIds = useMemo(
    () => onboardingExperiences?.map((v) => v.id) || [],
    [onboardingExperiences]
  );
  const { data: onboardingExperienceTasks } =
    useOnboardingExperienceTasksByExperienceIds(onboardingExperienceIds);
  const handleClickSelectOnboardingExperiencesForPreview = useCallback(() => {
    handleModal({
      name: "selectOnboardingExperiencesForPreviewModal",
      args: {
        onboardingExperiences: onboardingExperiences || [],
        onboardingExperienceTasks: onboardingExperienceTasks || [],
      },
    });
  }, [handleModal, onboardingExperiences, onboardingExperienceTasks]);

  const handleCloseModal = () => setModalOpen(false);
  const handleMoveTo = (modalContent: ModalContent) =>
    navigate(`/settings/admin/${modalContent.moveTo}`);

  useEffect(() => {
    setFeatures(tenantSettings.features);
  }, [tenantSettings]);

  useEffect(() => {
    const fetchData = async () => {
      const surveys = await surveyTemplateUseCase.findAll(currentUser.tenantId);
      //  FIXME: 本来はdomainに定義してハードコードでなくtypeで判断すべき
      surveys.forEach((survey) => {
        if (survey.title.includes("オンボーディング")) {
          setOnboardingSurvey(survey);
        }
        if (survey.title.includes("振り返り")) {
          setOneMonthSurvey(survey);
        }
      });
      setLoading(false);
    };
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (!target || loading) return;

    switch (target) {
      case "survey": {
        setScrollTargetRef(surveyRef);
        break;
      }
      case "message": {
        setScrollTargetRef(messageRef);
        break;
      }
      case "portal": {
        setScrollTargetRef(newcomerPortalRef);
        break;
      }
    }
  }, [loading, target]);

  const handleSwitchFeature = async (
    newObject: Partial<{ [key in SelectableFeatures]: boolean }>
  ) => {
    if (SelectableFeatures.TOP_MESSAGE in newObject) {
      if (isEmpty(tenantSettings.messages)) {
        setModalContent({ title: "トップメッセージ", moveTo: "top_message" });
        setModalOpen(true);
        return;
      }
    }

    const newFeatures = { ...features, ...newObject };
    setFeatures(newFeatures);

    await TenantSettingsUseCase.updateFeature(currentUser.tenantId, newFeatures);
    mutateTenantSettings();
  };

  useScrollToTargetElement({
    enabled: !!scrollTargetRef,
    targetRef: scrollTargetRef,
    scrollYOffset: AUTO_SCROLL_TO_CONTENT_Y_OFFSET,
  });

  const handleClickTopMessageButton = () => {
    navigate("/settings/admin/top_message");
  };

  if (loading) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={3}>
            <Box
              gridRowGap="32px"
              display="flex"
              flexDirection="column"
              position="sticky"
              top="20px"
            >
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(notificationDeliveryRef)}
                $isActive={scrollTargetRef === notificationDeliveryRef}
              >
                通知配信設定
              </StyledSideTypography>
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(surveyRef)}
                $isActive={scrollTargetRef === surveyRef}
              >
                アンケート配信
              </StyledSideTypography>
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(newcomerPortalRef)}
                $isActive={scrollTargetRef === newcomerPortalRef}
              >
                入社者ポータル
              </StyledSideTypography>
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(contactRef)}
                $isActive={scrollTargetRef === contactRef}
              >
                コンタクト設定
              </StyledSideTypography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} ref={notificationDeliveryRef}>
              <StyledTextContext padding={2} isShowBg bold>
                通知配信設定
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px={2} pt={4}>
                <Typography variant="body2" bold>
                  新メンバー紹介通知
                </Typography>
                <StyledTypography variant="caption">
                  {`入社日に指定のチャンネルに歓迎メッセージと共に新メンバーの情報が流れます。\n新メンバーが記入した自己紹介も新メンバーの情報とともに流れ、既存メンバーが新メンバーを知るきっかけを作ります。`}
                </StyledTypography>
              </Box>
              <Box px={2} mt={2}>
                <NewcomerIntroductionSettings
                  newcomerIntroduction={tenantSettings.newcomerIntroduction}
                  editing={editingSections.has(SectionIds.NOTIFICATION_DELIVERY)}
                  updating={isLoadingUpdateTenantSettings}
                  onEdit={() => handleClickOptionSettingButton(SectionIds.NOTIFICATION_DELIVERY)}
                  onCancel={() => handleCancelSettingButton(SectionIds.NOTIFICATION_DELIVERY)}
                  onSubmit={handleSubmitNewMemberIntroductionSettings}
                />
              </Box>
            </Grid>
            <Box height="40px" />
            <Grid item xs={12} ref={surveyRef}>
              <StyledTextContext padding={2} isShowBg bold>
                アンケート配信
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px={2} pt={4}>
                <Typography variant="body2" bold>
                  オンボーディングアンケート
                </Typography>
                <Box height="8px" />
                <StyledTypography variant="caption">
                  {`変化の大きい入社前後における入社者一人ひとりのコンディションを、ローコストでリアルタイムに把握できる自動配信のアンケートです。\n※このアンケートは全入社者に対して配信されます。`}
                </StyledTypography>
              </Box>
              <Box px={2} mt={2}>
                {onboardingSurvey && (
                  <OnboardingSurveySettings
                    onboardingSurvey={onboardingSurvey}
                    editing={editingSections.has(SectionIds.ONBOARDING)}
                    updating={updating}
                    onEdit={() => handleClickOptionSettingButton(SectionIds.ONBOARDING)}
                    onCancel={() => handleCancelSettingButton(SectionIds.ONBOARDING)}
                    onSubmit={handleSubmitOnBoardingSurveySettings}
                  />
                )}
              </Box>
              <Box px={2} mt={3}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    振り返りアンケート
                  </Typography>
                  {/* <Switch
                  checked={features.oneMonth}
                  onChange={() =>
                    handleSwitchFeature({
                      [SelectableFeatures.ONE_MONTH]: !features.oneMonth,
                    })
                  }
                  color="primary"
                /> */}
                </Box>
                <StyledTypography variant="caption">
                  {`「入社後のギャップ」「企業理解や組織との関係性構築の状況」「入社初期の従業員エンゲージメント」を明らかにするアンケートです。戦力化の遅れや離職を未然に防ぐためのフォローや、オンボーディング施策の改善に役立てることが可能です。\n※このアンケートは全入社者に対して配信されます。`}
                </StyledTypography>
              </Box>
              {features.oneMonth && oneMonthSurvey && (
                <Box px={2} mt={2}>
                  <OneMonthSurveySettings
                    oneMonthSurvey={oneMonthSurvey}
                    editing={editingSections.has(SectionIds.ONE_MONTH)}
                    updating={updating}
                    onEdit={() => handleClickOptionSettingButton(SectionIds.ONE_MONTH)}
                    onCancel={() => handleCancelSettingButton(SectionIds.ONE_MONTH)}
                    onSubmit={handleSubmitOneMonthSurveySettings}
                  />
                </Box>
              )}
            </Grid>
            <Box height="40px" />
            <Grid item xs={12} ref={newcomerPortalRef}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  入社者ポータル
                </Typography>
                <StyledBox onClick={handleClickSelectOnboardingExperiencesForPreview}>
                  <Icon size="md" icon="eye" color="primary" />
                  <Box width="6px" />
                  <Typography variant="button">表示をプレビュー</Typography>
                </StyledBox>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px={2} mt={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    トップメッセージ
                  </Typography>
                  <Switch
                    checked={features.topMessage}
                    onChange={() =>
                      handleSwitchFeature({
                        [SelectableFeatures.TOP_MESSAGE]: !features.topMessage,
                      })
                    }
                    color="primary"
                  />
                </Box>
                <StyledTypography variant="caption">
                  入社者が初めてログインした時に最初に表示されるコンテンツです。入社者全員に同じ文言が表示されるので、新しいメンバーに向けた入社を歓迎するメッセージを設定しましょう！
                </StyledTypography>
              </Box>
              <Box px={2} mt={3}>
                <Button
                  color="primary"
                  borderRadius="circle"
                  variant="outlined"
                  onClick={handleClickTopMessageButton}
                >
                  コンテンツを編集
                </Button>
              </Box>
              <Box height="24px" />
              <Box px={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    オンボーディングライブラリ
                  </Typography>
                  <Switch
                    checked={features.library}
                    onChange={() =>
                      handleSwitchFeature({
                        [SelectableFeatures.LIBRARY]: !features.library,
                      })
                    }
                    color="primary"
                  />
                </Box>
                <StyledTypography variant="caption">
                  入社者が入社者ポータルで閲覧できる「組織・カルチャー・人」などに関わるコンテンツをカテゴリーに分けて設定することができます。入社者が入社1日目を迎えるまでに会社へのエンゲージメントを高めるコンテンツをカテゴリーごとに登録しましょう。
                </StyledTypography>
              </Box>
              <Box px={2} mt={3}>
                <Button
                  color="primary"
                  borderRadius="circle"
                  variant="outlined"
                  onClick={() => navigate("/tools#library")}
                >
                  ライブラリ設定へ
                </Button>
              </Box>
            </Grid>
            <Box height="40px" />
            <Grid item xs={12} ref={contactRef}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  コンタクト設定
                </Typography>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px="16px" mt="32px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    コンタクト：初期表示
                  </Typography>
                  <Switch
                    checked={features.contactInitialDisplay}
                    onChange={() =>
                      handleSwitchFeature({
                        [SelectableFeatures.CONTACT_INITIAL_DISPLAY]:
                          !features.contactInitialDisplay,
                      })
                    }
                    color="primary"
                  />
                </Box>
                <StyledTypography variant="caption">
                  各採用ツールやメッセージツールに散らばってしまいがちな全入社者とのやりとりをOnn上のチャット機能で集約できる機能です。機能をONにすると入社者ポータル上で入社者からいつでもメッセージを受け付けることができ、OFFにすると管理者・受け入れメンバーから1通目のメッセージを送信するまで入社者側にはコンタクト機能は表示されず入社者からのメッセージの送信もできません。
                </StyledTypography>
              </Box>
              <Box px="16px" mt="24px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    デフォルト対応チーム設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  入社者とのコンタクトを行える初期メンバーを指定することができます。初期設定としては、管理者の権限を持つメンバーが設定されています。なお、各入社者のコンタクトページで後からメンバーの追加や削除を行うことも可能です。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    disabled={isValidatingAdmins || isValidatingAccessibleEmployees}
                    onClick={() => handleClickManageButton(accessibleEmployees, admins)}
                  >
                    デフォルト対応チームを設定
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {modalOpen && modalContent && (
          <StyledModal
            open={modalOpen}
            title={`${modalContent.title}設定`}
            content={
              <Box textAlign="center">
                <Typography>
                  表示するコンテンツが登録されていません。{"\n"}
                  コンテンツを登録してから公開しましょう。
                </Typography>
                <Box height="16px" />
                <Typography variant="caption">
                  機能設定で機能をオンにすると、入社者に対してコンテンツが表示されます。
                </Typography>
              </Box>
            }
            footer={
              <StyledButtonContainer>
                <Button
                  variant="outlined"
                  fullWidth
                  borderRadius="circle"
                  color="default"
                  onClick={handleCloseModal}
                >
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  borderRadius="circle"
                  color="primary"
                  onClick={() => handleMoveTo(modalContent)}
                >
                  コンテンツを追加
                </Button>
              </StyledButtonContainer>
            }
            onCancel={handleCloseModal}
          />
        )}
      </Paper>
    </AdminContentWrapper>
  );
};

const StyledSideTypography = styled(Typography)<{ $isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.$isActive &&
    `&.MuiTypography-root {
        color: ${props.theme.palette.primary.main};
        font-weight: bold;
    }`}
`;

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 14px;
  cursor: pointer;
`;
