import { Box } from "@material-ui/core";
import { AllContactRoom, OnnContactRoom } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, UserIcon } from "~/components/uiParts";

type Props = {
  contactRoom: AllContactRoom;
  isDisplayBadge: boolean;
};

export const RoomIconV2: FC<Props> = React.memo(({ contactRoom, isDisplayBadge }) => {
  switch (contactRoom.type) {
    case undefined:
      return (
        <StyledBox>
          <UserIcon
            username=""
            profileIconImageUrl={(contactRoom as OnnContactRoom).getRoomImageUrl()}
            size="small"
            circular
          />
          {isDisplayBadge && (
            <StyledBadgeIconBox>
              <Icon icon="email" color="grey" size="ssm" />
            </StyledBadgeIconBox>
          )}
        </StyledBox>
      );
  }
});

const StyledBox = styled(Box)`
  position: relative;
  min-width: 40px;
  min-height: 44px;
`;

const StyledBadgeIconBox = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
`;
