import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Header } from "~/components/uiParts";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";
import { mixin } from "~/util";

type Props = {
  isBackgroundImage?: boolean;
  children: ReactNode;
};

export const DefaultLayout: FC<Props> = ({ isBackgroundImage = false, children }) => {
  const navigate = useNavigate();
  const handleClickLogo = (): void => {
    navigate("/");
  };

  return (
    <StyledDivWrapper>
      <Header onClickLogo={handleClickLogo} />
      <StyledBox display="flex" justifyContent="center" $isBackgroundImage={isBackgroundImage}>
        {children}
      </StyledBox>
    </StyledDivWrapper>
  );
};

const StyledDivWrapper = styled(Box)`
  background-color: ${(props) => `${props.theme.palette.grey[50]}`};

  ${mixin.breakDown.sm`
    background-color: white;
  `};
`;

const StyledBox = styled(Box)<{ $isBackgroundImage: boolean }>`
  min-height: calc(100vh - ${(props) => props.theme.constants.HEADER_HEIGHT}px);

  ${(props) =>
    props.$isBackgroundImage &&
    mixin.breakUp.sm`
    background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
    background-position: right top, left bottom;
    background-repeat: no-repeat;
  `}
`;
