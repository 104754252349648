import React, { FC } from "react";
import { Dot, DotProps } from "recharts";

// ====================
// props
// ====================

type Props = {
  chartDotData: DotProps & { index: number };
};

// ====================
// main
// ====================

export const NormalDot: FC<Props> = ({ chartDotData }) => {
  const { name, stroke, cx, cy, className } = chartDotData;

  return (
    <Dot
      r={5}
      name={name}
      stroke={stroke}
      strokeWidth={0}
      fill={stroke}
      cx={cx}
      cy={cy}
      className={className}
    />
  );
};
