import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import theme from "~/config/theme";

export const TenantLogo = ({
  url,
  tenantName,
  width = "80px",
  height = "80px",
}: {
  url?: string;
  tenantName?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <Box width={width} height={height}>
      {!url ? <StyledBox>{tenantName}</StyledBox> : <StyledImg src={url} alt={tenantName} />}
    </Box>
  );
};

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 2px solid ${theme.palette.grey[100]};
`;

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 2px solid ${theme.palette.grey[100]};
`;
