import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts/Button";
import { DividerWithText } from "~/components/uiParts/DividerWithText";

type Props = {
  handleLoginWithGoogleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const SSOButtonGroup: FC<Props> = (props) => {
  return (
    <StyledWrapper>
      <Box mt={3} mb={3}>
        <DividerWithText text="or" />
      </Box>
      <Button
        borderRadius="circle"
        variant="outlined"
        color="primary"
        fullWidth
        onClick={(event) => props.handleLoginWithGoogleClick(event)}
      >
        Googleアカウントでログイン
      </Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
`;
