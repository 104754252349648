import { List, ListItem } from "@material-ui/core";
import { SlackUser } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { CheckboxRowRenderer, CompactRowRenderer, DefaultRowRenderer } from "./SlackUserListRow";

import { Typography, Skeleton } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

const heightMap = {
  default: 400,
  compact: 224,
  checkbox: 224,
};

const rowHeightMap = {
  default: 56,
  compact: 40,
  checkbox: 52,
};

type Props = {
  slackUsers: SlackUser[];
  isLoading?: boolean;
  rowRendererMode?: "default" | "compact" | "checkbox";
} & (
  | {
      selectedSlackUsers: SlackUser[];
      selectedSlackUser?: undefined;
      onSelect: (slackUsers: SlackUser[]) => void;
      isMultiple: true;
    }
  | {
      selectedSlackUsers?: undefined;
      selectedSlackUser?: SlackUser;
      onSelect: (slackUser: SlackUser) => void;
      isMultiple?: false;
    }
);

export const SlackUserList: FC<Props> = ({
  slackUsers,
  selectedSlackUsers,
  selectedSlackUser,
  onSelect,
  isMultiple,
  isLoading,
  rowRendererMode = "default",
}) => {
  const handleSelectSlackUser = useCallback(
    (slackUser: SlackUser) => {
      if (!isMultiple) {
        return onSelect(slackUser);
      }

      // 選択済みのユーザーをクリックしたとき
      if (selectedSlackUsers.includes(slackUser)) {
        const newArray = selectedSlackUsers.filter((v) => {
          return v.slackUserId !== slackUser.slackUserId;
        });
        onSelect(newArray);
      } else {
        onSelect([...selectedSlackUsers, slackUser]);
      }
    },
    [isMultiple, onSelect, selectedSlackUsers]
  );

  const getSelectedSlackUsers = () => {
    if (isMultiple) {
      return selectedSlackUsers;
    }
    return selectedSlackUser ? [selectedSlackUser] : [];
  };

  return (
    <StyledList $height={heightMap[rowRendererMode]} $isScrollable={!isLoading}>
      {isLoading ? (
        <>
          {[...Array(10)].map((_, i) => (
            <ListItem key={`AssignMentorModal__ListItem--${i}`}>
              <Skeleton variant="userList" />
            </ListItem>
          ))}
        </>
      ) : (
        <AutoSizer
          disableHeight // 子要素の高さが固定長であるため高さを計算しない
        >
          {(size) => (
            <VirtualizedList
              height={heightMap[rowRendererMode]}
              width={size.width}
              overscanRowCount={10} // 先にレンダリングしておくリストの数
              rowCount={slackUsers.length}
              rowHeight={rowHeightMap[rowRendererMode]}
              rowRenderer={(props) => {
                if (rowRendererMode === "compact") {
                  return (
                    <CompactRowRenderer
                      {...props}
                      slackUsers={slackUsers}
                      selectedSlackUsers={getSelectedSlackUsers()}
                      onSelectSlackUser={handleSelectSlackUser}
                    />
                  );
                } else if (rowRendererMode === "checkbox") {
                  return (
                    <CheckboxRowRenderer
                      {...props}
                      slackUsers={slackUsers}
                      selectedSlackUsers={getSelectedSlackUsers()}
                      onSelectSlackUser={handleSelectSlackUser}
                    />
                  );
                }
                return (
                  <DefaultRowRenderer
                    {...props}
                    slackUsers={slackUsers}
                    selectedSlackUsers={getSelectedSlackUsers()}
                    onSelectSlackUser={handleSelectSlackUser}
                  />
                );
              }}
              noRowsRenderer={() => (
                <Typography variant="caption" align="center" display="block" color="textSecondary">
                  結果が見つかりませんでした
                </Typography>
              )}
            />
          )}
        </AutoSizer>
      )}
    </StyledList>
  );
};

const StyledList = styled(List)<{ $height: number; $isScrollable: boolean }>`
  height: ${(props) => props.$height}px;
  /* skeletonはスクロールさせない */
  overflow: ${(props) => (props.$isScrollable ? "auto" : "hidden")};
`;
