import { Menu, MenuItem, Box } from "@material-ui/core";
import { Employee, extractLatestStatus, convertStatusToString, AllContactRoom } from "@onn/common";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import React, { useState, useCallback, FC, MouseEvent, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ContentWithLabel } from "./ContentWithLabel";

import { DepartmentNameText } from "~/components/domains/departments";
import { useGetBorderColorById } from "~/components/domains/employees/hooks";
import {
  UserIcon,
  UserIconGroup,
  UserIconWithLabel,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  Loading,
  Icon,
} from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";
import { useIsMyDepartmentAdmin } from "~/hooks/department/useIsMyDepartmentAdmin";
import { useEmployee, useEmployeeModals, useEmployees } from "~/hooks/employee";
import { useResendInvitation } from "~/hooks/employee/useResendInvitation";
import { useOnboardingExperienceByTenantId } from "~/hooks/onboardingExperience";
import { useOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask";
import { useClipboard, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

type Props = {
  newHire: Employee;
  currentUser: Employee;
  contactRoom: AllContactRoom | undefined;
  isRounded?: boolean;
  unreadContactMessageCount: number;
};
const FirstIconWidthInUserIconGroup = 40;
const AfterTheFirstWidthInUserIconGroup = 32; // 2番目以降のアイコンや+nの部分

export const EmployeeSummaryPaper: FC<Props> = memo(function EmployeeSummary({
  newHire,
  currentUser,
  contactRoom,
  isRounded = true,
  unreadContactMessageCount,
}) {
  const {
    handleOpenAssignMentorModal,
    handleOpenAssignSupportMembersModal,
    handleOpenManageMentorAndSupportMemberModal,
    handleOpenChangeEmployeeNameModal,
    handleOpenDeleteEmployeeModal,
    handleOpenIntegrateSlackModal,
    handleOpenJoinDateModal,
    handleOpenChangeDepartmentModal,
    handleOpenGenerateTasksFormExperienceModal,
  } = useEmployeeModals();
  //state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { data: mentor } = useEmployee(newHire.mentorUserId || "");
  const { data: supportMembers = [] } = useEmployees(newHire.supportMemberEmployeeIds || []);
  const {
    data: onboardingExperiencesByTenantId,
    isValidating: isValidatingOnboardingExperiencesByTenantId,
  } = useOnboardingExperienceByTenantId(newHire.tenantId);
  const { mutate: mutateOnboardingTasks } = useOnboardingTasksByEmployeeId(newHire.id);
  const { resendInvitation } = useResendInvitation();

  const { getBorderColorById } = useGetBorderColorById();

  const {
    result: isMyDepartmentAdmin,
    isLoading: isValidatingDepartments,
    departments,
  } = useIsMyDepartmentAdmin(newHire, currentUser);

  const isAccessible = useMemo(
    () => currentUser.isAdmin() || isMyDepartmentAdmin,
    [currentUser, isMyDepartmentAdmin]
  );

  const renderTooltipTitleForMentor = () => {
    let title = "";
    if (mentor) {
      title = "受け入れチーム設定を開く";
    } else if (isAccessible) {
      title = "バディを設定する";
    }
    return title;
  };

  const buildMentorLabel = (mentor?: Employee) => {
    if (!mentor) return "未登録";

    // 設定済みでも招待中で氏名が設定されていなければemailを表示する
    if (mentor.getName().trim()) {
      return mentor.getName();
    } else {
      return mentor.email;
    }
  };

  // サポートメンバーが設定されている場合管理モーダルを表示し、そうでない場合はAdminOrDepartmentAdminの場合にのみ設定モーダルを表示する
  const switchSupportMemberModalOpen = useCallback(() => {
    if (!isEmpty(supportMembers)) {
      return handleOpenManageMentorAndSupportMemberModal(newHire, mentor, supportMembers);
    }
    if (isAccessible) {
      handleOpenAssignSupportMembersModal(newHire, mentor, supportMembers);
    }
  }, [
    handleOpenAssignSupportMembersModal,
    handleOpenManageMentorAndSupportMemberModal,
    isAccessible,
    mentor,
    newHire,
    supportMembers,
  ]);

  // バディが設定されている場合管理モーダルを表示し、そうでない場合はAdminOrDepartmentAdminの場合にのみ設定モーダルを表示する
  const switchMentorModalOpen = useCallback(() => {
    if (mentor) {
      return handleOpenManageMentorAndSupportMemberModal(newHire, mentor, supportMembers);
    }
    if (isAccessible) {
      return handleOpenAssignMentorModal(newHire, mentor, supportMembers);
    }
  }, [
    mentor,
    isAccessible,
    handleOpenManageMentorAndSupportMemberModal,
    newHire,
    supportMembers,
    handleOpenAssignMentorModal,
  ]);

  const resendInvitationMail = useCallback(async () => {
    await resendInvitation(newHire.id)
      .then(() => enqueueSnackbar("招待メールを再送しました", { variant: "success" }))
      .catch((e) => {
        captureException({
          error: e as Error,
          tags: { type: "EmployeeSummaryPaper:resendInvitationMail" },
        });
        enqueueSnackbar("招待メールの再送に失敗しました", { variant: "error" });
      });
  }, [enqueueSnackbar, resendInvitation, newHire.id]);

  const { handleCopy } = useClipboard();
  const handleClickCopyRegisterAccountLinkButton = useCallback(() => {
    handleCopy(`${window.location.origin}/account/${newHire.invitationToken}`, "招待リンク");
  }, [newHire.invitationToken, handleCopy]);

  // 画面遷移を防ぐpreventDefaultとメニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = useCallback((e: MouseEvent<HTMLElement>, callback: () => void) => {
    e.preventDefault();
    setAnchorEl(null);
    callback();
  }, []);

  const menuOptions = useMemo(() => {
    const optionsForUnregisteredNewHire = [];
    if (!newHire.uid) {
      // アカウント未登録の場合
      optionsForUnregisteredNewHire.push({
        title: "招待メール再送",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => resendInvitationMail()),
      });
    }

    if (!newHire.uid) {
      optionsForUnregisteredNewHire.push({
        title: "招待リンクをコピー",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, handleClickCopyRegisterAccountLinkButton),
      });
    }
    return [
      ...optionsForUnregisteredNewHire,
      {
        title: "氏名変更",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => handleOpenChangeEmployeeNameModal(newHire)),
      },
      {
        title: "入社日設定",
        func: (e: MouseEvent<HTMLElement>) => {
          handleClickHF(e, () => (isAccessible ? handleOpenJoinDateModal(newHire) : void 0));
        },
      },
      {
        title: "バディを追加",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => switchMentorModalOpen()),
      },
      {
        title: "サポートメンバーを追加",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () =>
            handleOpenAssignSupportMembersModal(newHire, mentor, supportMembers)
          ),
      },
      {
        title: "受け入れ体験設定",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () =>
            onboardingExperiencesByTenantId
              ? handleOpenGenerateTasksFormExperienceModal(
                  newHire,
                  onboardingExperiencesByTenantId,
                  mutateOnboardingTasks
                )
              : undefined
          ),
        disabled: !onboardingExperiencesByTenantId || isValidatingOnboardingExperiencesByTenantId,
      },
      {
        title: "Slackアカウント連携",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => handleOpenIntegrateSlackModal(newHire)),
      },
      {
        title: "部署変更",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => handleOpenChangeDepartmentModal(newHire)),
      },
      {
        title: "アカウント削除",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => handleOpenDeleteEmployeeModal(newHire)),
      },
    ];
  }, [
    handleClickHF,
    handleOpenDeleteEmployeeModal,
    handleOpenChangeDepartmentModal,
    handleOpenIntegrateSlackModal,
    handleOpenGenerateTasksFormExperienceModal,
    newHire,
    onboardingExperiencesByTenantId,
    mutateOnboardingTasks,
    handleOpenAssignSupportMembersModal,
    mentor,
    supportMembers,
    switchMentorModalOpen,
    isAccessible,
    isValidatingOnboardingExperiencesByTenantId,
    handleOpenJoinDateModal,
    handleOpenChangeEmployeeNameModal,
    resendInvitationMail,
    handleClickCopyRegisterAccountLinkButton,
  ]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const supportMembersInfoForIcon = useMemo<
    { username: string; profileIconImageUrl?: string; borderColor?: "primary" | "blue" }[]
  >(
    () =>
      supportMembers.map((sm) => ({
        username: sm.getName(),
        profileIconImageUrl: sm.profileIconImageUrl,
        borderColor: getBorderColorById(newHire, sm.id),
      })),
    [getBorderColorById, supportMembers, newHire]
  );

  const supportMemberAreaWidth = useMemo<number>(
    () =>
      supportMembers.length >= 4
        ? FirstIconWidthInUserIconGroup + AfterTheFirstWidthInUserIconGroup * 3
        : FirstIconWidthInUserIconGroup +
          AfterTheFirstWidthInUserIconGroup * (supportMembers.length - 1),
    [supportMembers.length]
  );

  if (isValidatingDepartments && !departments) {
    return (
      <Box height="116px">
        <Loading size="small" />
      </Box>
    );
  } else {
    return (
      <StyledPaper square={!isRounded}>
        <Box display="flex" alignItems="center">
          <Box>
            <UserIconWithLabel
              iconPath={newHire.profileIconImageUrl || ""}
              name={newHire.getName()}
              secondaryText={convertStatusToString(
                extractLatestStatus(newHire.onboardingStatuses || []),
                newHire.joinAt
              )}
              iconBadgeType={newHire.slackUserId ? "slack" : undefined}
            />
          </Box>

          <Box ml="auto">
            <Box display="flex" alignItems="center" flexWrap="wrap" pl={2}>
              <ContentWithLabel label="入社日">
                <Tooltip arrow placement="bottom" title={isAccessible ? "入社日を設定する" : ""}>
                  <StyledClickableBox
                    display="flex"
                    alignItems="center"
                    $isAccessible={isAccessible}
                    onClick={(e) =>
                      handleClickHF(e, () =>
                        isAccessible ? handleOpenJoinDateModal(newHire) : void 0
                      )
                    }
                  >
                    <Typography
                      variant="caption"
                      display="block"
                      color={newHire.joinAt ? "textPrimary" : "textSecondary"}
                      noWrap
                    >
                      {newHire.joinAt ? format(new Date(newHire.joinAt), "yyyy/MM/dd") : "未設定"}
                    </Typography>
                  </StyledClickableBox>
                </Tooltip>
              </ContentWithLabel>
            </Box>
          </Box>

          <Box ml="40px" maxWidth="180px">
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <ContentWithLabel label="部署">
                <DepartmentNameText
                  departments={departments}
                  targetDepartmentIds={newHire.departmentIds}
                  isEditable={isAccessible}
                  onClick={() => (isAccessible ? handleOpenChangeDepartmentModal(newHire) : void 0)}
                />
              </ContentWithLabel>
            </Box>
          </Box>

          <Box ml="40px">
            <Box display="flex" alignItems="center" flexWrap="wrap" pl={2}>
              <ContentWithLabel label="バディ">
                <Tooltip arrow placement="bottom" title={renderTooltipTitleForMentor()}>
                  <StyledClickableBox
                    display="flex"
                    alignItems="center"
                    $isAccessible={isAccessible || mentor != null}
                    onClick={(e) => handleClickHF(e, () => switchMentorModalOpen())}
                  >
                    {(mentor || isAccessible) && (
                      <>
                        <UserIcon
                          circular
                          size="small"
                          username={mentor ? mentor.getName() : "未登録"}
                          profileIconImageUrl={mentor?.profileIconImageUrl}
                          badgeType={mentor ? undefined : "dot"}
                          borderColor="primary"
                        />
                        <Box display="inline-block" width="8px" />
                      </>
                    )}
                    <Typography
                      variant="caption"
                      color={mentor ? "textPrimary" : "textSecondary"}
                      display="block"
                      noWrap
                      disablePreWrap
                    >
                      {buildMentorLabel(mentor)}
                    </Typography>
                  </StyledClickableBox>
                </Tooltip>
              </ContentWithLabel>
            </Box>
          </Box>

          <Box ml="40px">
            <ContentWithLabel label="サポートメンバー">
              {isEmpty(supportMembers) ? (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={isAccessible ? "サポートメンバーを設定する" : ""}
                >
                  <StyledClickableBox
                    display="flex"
                    alignItems="center"
                    $isAccessible={isAccessible || !isEmpty(supportMembers)}
                    onClick={(e) => handleClickHF(e, () => switchSupportMemberModalOpen())}
                  >
                    {isAccessible && (
                      <>
                        <UserIcon circular size="small" username="未登録" badgeType="dot" />
                        <Box display="inline-block" width="8px" />
                      </>
                    )}
                    <Typography variant="caption" color="textSecondary" display="block">
                      未登録
                    </Typography>
                  </StyledClickableBox>
                </Tooltip>
              ) : (
                <StyledClickableBox
                  display="flex"
                  alignItems="center"
                  onClick={(e) => handleClickHF(e, () => switchSupportMemberModalOpen())}
                  $isAccessible={isAccessible || !isEmpty(supportMembers)}
                  $width={supportMemberAreaWidth}
                >
                  <UserIconGroup usersInfo={supportMembersInfoForIcon} max={4} tooltip />
                </StyledClickableBox>
              )}
            </ContentWithLabel>
          </Box>

          <Box ml="64px">
            {isAccessible && <IconButton icon="menuVert" onClick={handleOpenMenu} />}
          </Box>

          {contactRoom && (
            <Box ml="8px" height="40px" p="8px">
              <Link to={`/contact_rooms?contactRoomId=${contactRoom.id}`}>
                <Badge
                  badgeContent={
                    unreadContactMessageCount > 0 ? (
                      <Typography bold variant="caption">
                        {unreadContactMessageCount}
                      </Typography>
                    ) : undefined
                  }
                  color="secondary"
                >
                  <Icon icon="paperAirplane" color="primary" size="md" />
                </Badge>
              </Link>
            </Box>
          )}
        </Box>

        {Boolean(anchorEl) && (
          <Menu
            key={newHire.id}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {menuOptions.map((option) => (
              <MenuItem key={option.title} onClick={option.func} disabled={option.disabled}>
                <Typography variant="body2">{option.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </StyledPaper>
    );
  }
});

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledClickableBox = styled(Box)<{ $isAccessible: boolean; $width?: number }>`
  ${({ $isAccessible }) => $isAccessible && `cursor: pointer;`}
  ${({ $width }) => ($width ? `width: ${$width}px` : "width: 100%")}
`;
