import { isAfter, subDays } from "date-fns";
import { v4 } from "uuid";

import { Employee } from "../../Employee";
import { OnboardingTask } from "../OnboardingTask";
import { OnboardingTaskMemo } from "../OnboardingTaskMemo";

type ActionId =
  | "SET_JOIN_AT"
  | "ASSIGN_MENTOR_OR_SUPPORT_MEMBER"
  | "INTEGRATE_SLACK"
  | "REGISTER_PROFILE"
  | "CREATE_ACCOUNT";

export class OnboardingGeneralTask extends OnboardingTask {
  id: string;
  type = "GENERAL_TASK" as const;
  actionId: ActionId;
  filePaths: (string | File)[];
  memos: OnboardingTaskMemo[];

  constructor(
    init: Omit<OnboardingGeneralTask, "isExpiredByEmployee" | "shouldDelivery" | "shouldRemind">
  ) {
    super(init);
    this.id = init.id;
    this.actionId = init.actionId;
    this.filePaths = init.filePaths;
    this.memos = init.memos;
  }

  public static create(
    params: Optional<ConstructorParameters<typeof OnboardingGeneralTask>[0], "id">
  ) {
    return new OnboardingGeneralTask({
      ...params,
      id: params.id ?? v4(),
    });
  }

  /**
   * 入社者を元にタスクをリマインドするかどうかを返すメソッド
   * 期日の3日前以降が通知の対象
   * @param employee 入社者
   * @returns boolean
   */
  shouldRemind(employee: Employee, currentDate: Date = new Date()): boolean {
    if (this.status === "COMPLETED") return false;
    if (this.assigneeRole === "NOT_SET") return false;
    if (!this.dueDate) return false;

    const date = this.dueDate.calculateDateByEmployee(employee);

    if (!date) return false;

    return isAfter(currentDate, subDays(date, 3));
  }
}
