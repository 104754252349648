import useSWR, { SWRResponse } from "swr";

import { FindOnboardingExperienceTasksByExperienceIdsUseCase } from "~/service/usecases/onboardingExperienceProcess";

const findOnboardingExperienceTasksByExperienceIdsUseCase =
  new FindOnboardingExperienceTasksByExperienceIdsUseCase();

/**
 * experienceIds をもとに onboardingExperienceTasks を返すswr
 * @param {string[]} experienceIds
 */
export const useOnboardingExperienceTasksByExperienceIds = (
  experienceIds?: string[]
): SWRResponse<
  Awaited<ReturnType<typeof findOnboardingExperienceTasksByExperienceIdsUseCase.execute>>,
  Error
> => {
  const keys = experienceIds ? ["onboardingExperienceTasksByExperienceIds", experienceIds] : null;

  return useSWR(keys, ([_key, ids]: [string, string[]]) =>
    findOnboardingExperienceTasksByExperienceIdsUseCase.execute(ids)
  );
};
