import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { CellMeasurer, CellMeasurerCache, WindowScroller } from "react-virtualized";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { PortalListItem } from "~/components/uiParts/PortalListItem";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

type Content = {
  id: string;
  isCompleted: boolean;
  error?: boolean;
  title: string;
  caption: string;
  to: string;
};

type Props = {
  contents: Content[];
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 140,
});

export const PortalListItemGroup: FC<Props> = ({ contents }) => {
  return (
    <WindowScroller>
      {({ height, isScrolling, scrollTop, onChildScroll }) => (
        <AutoSizer>
          {(size) => {
            return (
              <VirtualizedList
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                autoHeight
                height={height}
                width={size.width}
                overscanRowCount={20}
                rowCount={contents.length}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={(props) => {
                  const content = contents[props.index] as (typeof contents)[number];
                  return (
                    <CellMeasurer
                      key={props.key}
                      cache={cache}
                      parent={props.parent}
                      columnIndex={0}
                      rowIndex={props.index}
                    >
                      <Box display="flex" style={props.style} pb="40px">
                        <Box width="20px" mr="16px" position="relative">
                          <StyledBox
                            position="absolute"
                            key={content.id}
                            $borderHeight={cache.rowHeight({ index: props.index })}
                            top={40}
                            $isLast={props.index === contents.length - 1}
                          >
                            {content.isCompleted ? (
                              <StyledCheckboxWrapperBox textAlign="center" alignItems="center">
                                <Icon color="white" icon="check" size="sm" />
                              </StyledCheckboxWrapperBox>
                            ) : (
                              <Box>
                                <StyledCircle />
                              </Box>
                            )}
                          </StyledBox>
                        </Box>
                        <Box width="100%">
                          <PortalListItem
                            title={content.title}
                            to={content.to}
                            border={content.isCompleted}
                            caption={content.caption}
                            error={content.error}
                          />
                        </Box>
                      </Box>
                    </CellMeasurer>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledCheckboxWrapperBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  svg {
    width: 14px;
    height: 10px;
  }
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledBox = styled(Box)<{ $borderHeight: number; $isLast: boolean }>`
  ${(props) =>
    !props.$isLast &&
    `::after {
      width: 3px;
      left: 8px;
      position: absolute;
      height: ${props.$borderHeight}px;
      content: "" "";
      background-color: ${props.theme.palette.primary.main};
    }
    `}
`;
