import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";

import { ContactMessageDraft } from "@onn/common";
import React, { ChangeEvent, FC, useCallback, useMemo } from "react";
import { WindowScroller } from "react-virtualized";
import styled from "styled-components";

import { TabValue } from "./hooks/useContactRoomListV2";

import { useViewModelV2 } from "./hooks/useViewModelV2";

import { NewHireSPContactRoomItem, ContactRoomListItemV2 } from "~/components/domains/contactRooms";
import { Icon, Loading, TextField, Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { useContactMessageDraftByCreatedUserId } from "~/hooks/contactMessageDraft";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { captureException } from "~/util";

const tabs: Array<{ label: string; value: string }> = [
  {
    label: "あなた",
    value: "my",
  },
  {
    label: "全体",
    value: "all",
  },
];

// 自分自身が対象となっているルームは取得しない
// 入社者のユーザーでログインし、他の入社者のコンタクトルームの閲覧権限を有する場合に表示されてしまうことを防ぐ
export const NewHireSPContactMessages: FC = () => {
  const {
    currentContactRooms,
    activeTab,
    setActiveTab,
    setQueryString,
    renderIsCompletedFilter,
    searchValue,
    setSearchValue,
    setSelectedContactRoomId,
    contactRoomUnreadCountList,
    isLoadingContactRooms,
    selectedContactRoomId,
    latestContactMessages,
    isLoadingLatestMessages,
    isLoadingUnreadMessageCountList,
  } = useViewModelV2();

  const { currentUser } = useCurrentUser();
  const { saveContactMessageDraft, contactMessageDrafts } = useContactMessageDraftByCreatedUserId(
    currentUser.id
  );

  const isLoading =
    isLoadingContactRooms || isLoadingLatestMessages || isLoadingUnreadMessageCountList;

  const handleChangeActiveTab = useCallback(
    (_: ChangeEvent<unknown>, newValue: TabValue) => {
      setActiveTab(newValue);
    },
    [setActiveTab]
  );

  const content = useMemo(() => {
    if (selectedContactRoomId) {
      return (
        <NewHireSPContactRoomItem
          contactRoomId={selectedContactRoomId}
          contactMessageDraft={
            contactMessageDrafts.find(
              ({ contactRoomId }) => contactRoomId === selectedContactRoomId
            ) ??
            ContactMessageDraft.create({
              tenantId: currentUser.tenantId,
              createdUserId: currentUser.id,
              contactRoomId: selectedContactRoomId,
              text: "",
            })
          }
          saveContactMessageDraft={saveContactMessageDraft}
          onClickBack={() => {
            setSelectedContactRoomId(undefined);
            setQueryString({ contactRoomId: undefined });
          }}
        />
      );
    }

    return (
      <TabContext value={activeTab}>
        <Box width="100%" pb="24px" px="24px">
          <StyledTabList
            onChange={handleChangeActiveTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <Box mt="16px">
            <StyledForm onSubmit={(e) => e.preventDefault()}>
              <TextField
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                endAdornment={<Icon size="sm" color="grey" icon="search" />}
              />
            </StyledForm>
          </Box>
          <Box mt="16px">{renderIsCompletedFilter()}</Box>
        </Box>
        {isLoading ? (
          <Loading size={"small"} />
        ) : (
          <WindowScroller>
            {({ height }) => (
              <Box height="100%">
                <AutoSizer disableHeight>
                  {(size) => (
                    <VirtualizedList
                      height={height}
                      width={size.width}
                      overscanRowCount={10} // 先にレンダリングしておくリストの数
                      rowCount={currentContactRooms.length}
                      rowHeight={80}
                      rowRenderer={(props) => {
                        const contactRoom = currentContactRooms[
                          props.index
                        ] as (typeof currentContactRooms)[number];
                        const latestMessage = latestContactMessages?.find(
                          (lm) => lm.contactRoomId === contactRoom.id
                        );

                        const unreadCountInfo = contactRoomUnreadCountList.find(
                          (v) => v.contactRoomId === contactRoom.id
                        );
                        const unreadCount = unreadCountInfo?.unreadCount || 0;
                        // NOTE: コンタクトルームは存在するのに、未読情報が存在しない場合はSentryで通知を行う
                        if (!unreadCountInfo && !isLoadingUnreadMessageCountList) {
                          captureException({
                            error: new Error("未読情報が存在していません"),
                            tags: { type: "NewHirePCContactMessages" },
                            extras: {
                              contactRoomId: contactRoom.id,
                              unreadCountInfo,
                            },
                          });
                        }
                        return (
                          <Box
                            key={props.key}
                            style={props.style} // 動的に計算されるtopなどのプロパティが入る
                          >
                            <ContactRoomListItemV2
                              key={contactRoom.id}
                              contactRoom={contactRoom}
                              isSelected={false} // NOTE: SP版では選択状態はない
                              onClickContactRoomListItem={() => {
                                setSelectedContactRoomId(contactRoom.id);
                                setQueryString({ contactRoomId: contactRoom.id });
                              }}
                              unreadCount={unreadCount}
                              latestMessage={latestMessage}
                              isLoadingLatestMessages={isLoadingLatestMessages}
                            />
                          </Box>
                        );
                      }}
                      noRowsRenderer={() => (
                        <Box
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                          height="100%"
                        >
                          <Typography variant="body1" align="center">
                            {`担当者になっている\nコンタクトはありません`}
                          </Typography>
                        </Box>
                      )}
                    />
                  )}
                </AutoSizer>
              </Box>
            )}
          </WindowScroller>
        )}
      </TabContext>
    );
  }, [
    activeTab,
    contactMessageDrafts,
    contactRoomUnreadCountList,
    currentContactRooms,
    currentUser.id,
    currentUser.tenantId,
    handleChangeActiveTab,
    isLoading,
    isLoadingLatestMessages,
    isLoadingUnreadMessageCountList,
    latestContactMessages,
    renderIsCompletedFilter,
    saveContactMessageDraft,
    searchValue,
    selectedContactRoomId,
    setQueryString,
    setSearchValue,
    setSelectedContactRoomId,
  ]);

  return (
    <StyledContainerBox>
      <Box display="flex">
        <StyledBox width="100%" bgcolor="white" display="flex" flexDirection="column">
          {content}
        </StyledBox>
      </Box>
    </StyledContainerBox>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;

const StyledBox = styled(Box)`
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    padding-top: 0;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 20px;

    > .MuiTab-wrapper {
      font-size: 16px;
    }
  }
`;
