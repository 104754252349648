import { User } from "firebase/auth";
import React, { FC, ReactNode, createContext, useCallback, useEffect, useState } from "react";

import { auth } from "~/config/firebase";
import { useCookie } from "~/hooks/shared/useCookie";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";

const guardAuthUser = (authUser: unknown): authUser is User => {
  return !!authUser;
};

/**
 * NOTE: isReady は useEffect でイベントハンドラが実行されたことを保証するために使用している
 * - ex. 例えば AuthenticationGuard の初回 useEffect 実行時にはまだ AuthenticationProvider の useEffect が実行されていないため、
 *  authUser が null になってしまう
 */
export const AuthenticationContext = createContext<{
  isReady: boolean;
  authUser: User | null | undefined;
  guardAuthUser: (authUser: unknown) => authUser is User;
}>({
  isReady: false,
  authUser: undefined,
  guardAuthUser,
});

export const AuthenticationProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [firebaseAuthUser, setFirebaseAuthUser] = useState<User | null>(null);
  const [isReady, setReady] = useState<boolean>(false);

  const { updateValue, removeValue } = useCookie("idToken");

  const setNullToFirebaseAuthUser = useCallback(() => {
    setFirebaseAuthUser(null);
    removeValue();
    setReady(true);
  }, [removeValue]);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (authUser) => {
      if (!authUser) {
        setNullToFirebaseAuthUser();
        return;
      }

      setFirebaseAuthUser(authUser);
      authUser.getIdToken().then((idToken) => {
        updateValue(idToken);
      });
      setReady(true);
      AccountUseCase.updateLastRefreshTime();
    });

    return () => unsubscribe();
  }, [setNullToFirebaseAuthUser, updateValue]);

  return (
    <AuthenticationContext.Provider value={{ authUser: firebaseAuthUser, guardAuthUser, isReady }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
