import {
  Employee,
  Role,
  OnboardingExperience,
  OnboardingExperienceTaskType,
  OnboardingExperienceTaskFactory,
} from "@onn/common";
import { difference, isEmpty } from "lodash";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { OnboardingExperienceRepository } from "~/infrastructure/api/onboardingExperienceRepository";
import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

const onboardingExperienceRepository = new OnboardingExperienceRepository();
const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export class UpdateOnboardingExperienceUseCase {
  async execute({
    onboardingExperienceId,
    newOnboardingExperience,
    currentUser,
    newOnboardingExperienceTasks,
    emailsForEditableEmployeeIds,
  }: {
    onboardingExperienceId: string;
    newOnboardingExperience: OnboardingExperience;
    currentUser: Employee;
    newOnboardingExperienceTasks?: OnboardingExperienceTaskType[];
    emailsForEditableEmployeeIds?: string[];
  }): Promise<void> {
    const experiencesExistedAlready = await onboardingExperienceRepository.findByTenantId(
      currentUser.tenantId
    );

    if (
      experiencesExistedAlready.some(
        (experience) =>
          experience.id !== onboardingExperienceId &&
          experience.title === newOnboardingExperience.title
      )
    ) {
      throw new Error("同じタイトルのエクスペリエンスがすでに存在しています。");
    }

    const onboardingExperience = await onboardingExperienceRepository.findById(
      onboardingExperienceId
    );

    if (
      !currentUser.isAdmin() &&
      !onboardingExperience.editableEmployeeIds?.includes(currentUser.id)
    ) {
      throw new Error("エクスペリエンスを更新する権限がありません");
    }

    let createdEmployeesForEditableEmployees: Employee[] = [];
    if (emailsForEditableEmployeeIds && !isEmpty(emailsForEditableEmployeeIds)) {
      createdEmployeesForEditableEmployees = await this.createAccount(emailsForEditableEmployeeIds);
    }

    await onboardingExperienceRepository.update(onboardingExperienceId, {
      ...newOnboardingExperience,
      editableEmployeeIds: [
        ...(newOnboardingExperience.editableEmployeeIds || []),
        ...createdEmployeesForEditableEmployees.map((v) => v.id),
      ],
      updatedUserId: currentUser.id,
      updatedAt: new Date(),
    });

    // タスクの更新
    if (newOnboardingExperienceTasks) {
      const existedTasks = await onboardingExperienceTaskRepository.findByExperienceIds([
        onboardingExperienceId,
      ]);
      await this.updateOnboardingExperienceTasks(
        newOnboardingExperience.tenantId,
        newOnboardingExperienceTasks,
        existedTasks.map((v) => v.id)
      );
    }
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emails: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emails.map((email) => {
          return {
            email,
            role: Role.MEMBER,
            departmentIds: [],
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }

  private async updateOnboardingExperienceTasks(
    tenantId: string,
    onboardingExperienceTask: OnboardingExperienceTaskType[],
    existedTaskIds: string[]
  ) {
    const uploadFiles: { filePath: string; file: File }[] = [];
    let createdEmployees: Employee[] = [];

    const emails = Array.from(
      new Set(
        onboardingExperienceTask.flatMap((task) =>
          !task.emailsWithoutOnnAccount || isEmpty(task.emailsWithoutOnnAccount)
            ? []
            : task.emailsWithoutOnnAccount
        )
      )
    );

    if (!isEmpty(emails)) {
      createdEmployees = await this.createAccount(emails);
    }

    const newTasks = onboardingExperienceTask.flatMap((task, index) => {
      const { emailsWithoutOnnAccount } = task;
      // 招待したアカウントをassigneeIdsにセットする
      if (emailsWithoutOnnAccount) {
        const employeeIds = createdEmployees.flatMap((v) =>
          emailsWithoutOnnAccount.includes(v.email) ? v.id : []
        );
        task.assigneeIds = [...task.assigneeIds, ...employeeIds];

        // emailsWithoutOnnAccountはDBに保存しない
        delete task.emailsWithoutOnnAccount;
      }

      return OnboardingExperienceTaskFactory.createOnboardingTask({
        ...task,
        filePaths: task.filePaths.map((v) => {
          if (typeof v === "string") return v;
          const filePath = `tenants/${tenantId}/onboarding_tasks/${task.id}/${v.name}`;
          uploadFiles.push({ filePath, file: v });
          return filePath;
        }),
        // 更新時に毎回上書きする
        index,
      });
    });

    const batch = queryOperator.batch();

    newTasks.forEach((task) => {
      if (existedTaskIds.includes(task.id)) {
        onboardingExperienceTaskRepository.insertUpdateTaskInBatch(batch, task);
      } else {
        onboardingExperienceTaskRepository.insertCreateTaskInBatch(batch, task);
      }
    });

    // existedTaskIdsのうちnewTaskに存在しないものは削除するタスク
    difference(
      existedTaskIds,
      newTasks.map((v) => v.id)
    ).forEach((id) => {
      onboardingExperienceTaskRepository.insertDeleteTaskInBatch(batch, id);
    });

    await queryOperator.commit(batch);

    await Promise.all(
      uploadFiles.map(async (uploadFile) => {
        await fileAPIAdapter.upload({
          path: uploadFile.filePath,
          file: uploadFile.file,
        });
      })
    ).catch((e) => {
      // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
      captureException({ error: e, tags: { type: "fileAPIAdapter.fetchMetaDataByPaths" } });
    });
  }
}
