import { Library, Content } from "@onn/common";

import { LibraryRepository } from "~/infrastructure/api/libraryRepository";

const libraryRepository = new LibraryRepository();

export class LibraryUseCase {
  async create(
    library: Omit<Library, "id" | "index" | "createdAt" | "updatedAt" | "contents"> & {
      contents: Omit<Content, "id">[];
    },
    tenantId: string,
    spaceId?: string
  ): Promise<{ libraryId: string }> {
    let libraries = await libraryRepository.findAll(tenantId, { includePrivate: true });

    // 本来はrepositoryで絞り込むべきだが、indexを貼る必要があるので一旦アプリケーション側で絞り込む
    // librariesは全体数も多くないのでそこまでパフォーマンスに影響はない
    if (spaceId) {
      libraries = libraries.filter((v) => v.spaceId === spaceId);
    }

    return await libraryRepository.create({ ...library, index: libraries.length + 1 });
  }
  async fetchAll(
    tenantId: string,
    { includePrivate }: { includePrivate?: boolean } = {}
  ): Promise<Library[]> {
    const libraries = await libraryRepository.findAll(tenantId, { includePrivate });

    // contentsをindexの昇順でソートする
    return libraries.map(
      (library) =>
        new Library({ ...library, contents: library.contents.sort((a, b) => a.index - b.index) })
    );
  }
  findById = async (libraryId: string): Promise<Library> => {
    const library = await libraryRepository.findById(libraryId);
    return new Library({
      ...library,
      contents: library.contents.sort((a, b) => a.index - b.index),
    });
  };
  updateLibrary = async ({
    libraryId,
    library,
  }: {
    libraryId: string;
    library: Omit<Library, "contents"> & {
      contents: Array<Omit<Content, "id"> & { id?: string }>;
    };
  }): Promise<void> => {
    return await libraryRepository.updateLibrary({ libraryId, library });
  };
  deleteById = async (libraryId: string): Promise<void> => {
    const library = await libraryRepository.findById(libraryId);
    await libraryRepository.deleteById(libraryId);
    await libraryRepository.adjustLibraryIndex({
      minIndex: library.index,
      maxIndex: Infinity,
      point: -1,
    });
  };
}
