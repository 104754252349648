import { ButtonBase, Menu, MenuItem } from "@material-ui/core";
import { convertToYYMMDD } from "@onn/common";
import React, { FC, MouseEvent, useState } from "react";
import styled from "styled-components";

import { Icon, Typography } from "~/components/uiParts";

type Props = {
  currentSendAt: string;
  surveySendAts: string[];
  onClickSelectSendAt: (sendAt: string) => void;
};

export const SendAtsMenu: FC<Props> = ({ currentSendAt, surveySendAts, onClickSelectSendAt }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSelect = (sendAt: string) => {
    onClickSelectSendAt(sendAt);
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonBase onClick={handleOpen} focusRipple>
        <Typography>{currentSendAt}</Typography>
        <StyledIcon icon="chevronDown" size="sm" color="lightGrey" />
      </ButtonBase>

      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {surveySendAts.map((sendAt) => (
          <StyledMenuItem key={sendAt} onClick={() => handleClickSelect(sendAt)}>
            {`${convertToYYMMDD(sendAt)}配信`}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  & .MuiList-padding {
    padding: 12px 0;
  }
  max-height: 372px;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 12px 24px !important;
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
`;
