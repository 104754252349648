import { Box } from "@material-ui/core";
import { OnboardingTaskType } from "@onn/common";
import React, { FC } from "react";

import { Tooltip, Typography } from "~/components/uiParts";
import { useEmployee } from "~/hooks/employee";

type Props = {
  onClickDueDateStatus?: (onboardingTask: OnboardingTaskType, joinAt?: string) => void;
  onboardingTask: OnboardingTaskType;
};

export const DueDate: FC<Props> = ({ onClickDueDateStatus, onboardingTask }) => {
  const { data: newHire } = useEmployee(onboardingTask.employeeId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      onClick={(event) => {
        onClickDueDateStatus && onClickDueDateStatus(onboardingTask, newHire?.joinAt);
        // 親要素へのイベントの伝搬を防ぐ (onClickTableRowが実行され、サイドバーが開いてしまうのを防ぐ)
        event.stopPropagation();
      }}
      width="fit-content"
    >
      <Tooltip
        title={newHire?.joinAt ? onboardingTask.dueDate.getDisplayTiming() : ""}
        placement="bottom"
        arrow
      >
        <Typography variant="caption" color="textPrimary">
          {newHire && onboardingTask.dueDate.getDisplayTextByEmployee(newHire, "yyyy/MM/dd")}
        </Typography>
      </Tooltip>
    </Box>
  );
};
