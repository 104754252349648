import { Box, Grid, GridSize } from "@material-ui/core";
import {
  OnboardingExperience,
  Employee,
  OnboardingExperienceTaskFactory,
  OnboardingExperienceSimpleTask,
  OnboardingExperienceTaskType,
} from "@onn/common";
import { format } from "date-fns";
import React, { useCallback, useState, FC } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useGetEditableEmployees } from "../hooks";

import { EditableEmployeeIcons } from "./TableRowItems/EditableEmployeeIcons";
import { ManageOnboardingExperienceMenu } from "./TableRowItems/ManageOnboardingExperienceMenu";

import { DnDTable, Icon, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";
import {
  useAllEmployees,
  useAllEmployeesWithDeleted,
  useCurrentUser,
  useEmployee,
  useAdmins,
} from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import {
  useCreateOnboardingExperience,
  useDeleteOnboardingExperience,
  useOnboardingExperienceByTenantId,
  useUpdateOnboardingExperience,
  useUpdateOrderOfOnboardingExperience,
} from "~/hooks/onboardingExperience";
import { useOpenPortalOnboardingTaskPreview } from "~/hooks/openPortalPreview";

const EmployeeText: FC<{ updatedUserId: string }> = ({ updatedUserId }) => {
  const { data: employee } = useEmployee(updatedUserId, { shouldAutoRevalidate: false });
  if (!employee) return null;

  return (
    <Typography variant="body2" bold>
      {employee.getName()}
    </Typography>
  );
};

export const OnboardingExperienceTable: FC = () => {
  // ====================
  // state
  // ====================

  const [isUpdating, setIsUpdating] = useState(false);

  // ====================
  // hooks
  // ====================

  const { handleModal } = useModal();
  const { createOnboardingExperience } = useCreateOnboardingExperience();
  const { updateOnboardingExperience } = useUpdateOnboardingExperience();
  const { updateOrderOfOnboardingExperience } = useUpdateOrderOfOnboardingExperience();
  const { deleteOnboardingExperience } = useDeleteOnboardingExperience();
  const { getEditableEmployees } = useGetEditableEmployees();
  const { currentUser } = useCurrentUser();
  const { allEmployees, mutateAllEmployees } = useAllEmployees();
  const { data: admins = [] } = useAdmins(currentUser.tenantId);
  const { data: allEmployeesWithDeleted = [] } = useAllEmployeesWithDeleted(currentUser.tenantId);
  const { data: onboardingExperiences = [], mutate: mutateOnboardingExperiences } =
    useOnboardingExperienceByTenantId(currentUser.tenantId);

  // ====================
  // event handlers
  // ====================

  const handleChangeExperiencesOrder = useCallback(
    async (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効
      if (destinationIndex === undefined) return;

      // 移動元と移動先が同じ場合は処理を終了
      if (sourceIndex === destinationIndex) return;

      if (!isUpdating) setIsUpdating(true);

      const newOnboardingExperiences = [...onboardingExperiences];
      (newOnboardingExperiences[sourceIndex] as (typeof newOnboardingExperiences)[number]).index =
        destinationIndex;
      (
        newOnboardingExperiences[destinationIndex] as (typeof newOnboardingExperiences)[number]
      ).index = sourceIndex;
      newOnboardingExperiences.splice(sourceIndex, 1);
      newOnboardingExperiences.splice(
        destinationIndex,
        0,
        onboardingExperiences[sourceIndex] as (typeof newOnboardingExperiences)[number]
      );

      mutateOnboardingExperiences(newOnboardingExperiences, false);
      await updateOrderOfOnboardingExperience(newOnboardingExperiences);
      setIsUpdating(false);
    },
    [
      onboardingExperiences,
      isUpdating,
      updateOrderOfOnboardingExperience,
      mutateOnboardingExperiences,
    ]
  );

  const handleClickEditButton = useCallback(
    (onboardingExperience: OnboardingExperience) => {
      handleModal({
        name: "editOnboardingExperienceModal",
        args: {
          processTitle: onboardingExperience.title,
          mode: "edit",
          onSubmit: async (title: string) => {
            await updateOnboardingExperience({
              onboardingExperienceId: onboardingExperience.id,
              newOnboardingExperience: OnboardingExperience.create({
                ...onboardingExperience,
                title,
              }),
            });
            mutateOnboardingExperiences();
          },
        },
      });
    },
    [handleModal, mutateOnboardingExperiences, updateOnboardingExperience]
  );

  const handleClickDuplicateButton = useCallback(
    (onboardingExperience: (typeof onboardingExperiences)[number]) => {
      handleModal({
        name: "editOnboardingExperienceModal",
        args: {
          processTitle: `${onboardingExperience.title}のコピー`,
          mode: "duplicate",
          onSubmit: async (title: string) => {
            const newOnboardingExperience = OnboardingExperience.create({
              title,
              editableEmployeeIds: onboardingExperience.editableEmployeeIds,
              tenantId: currentUser.tenantId,
              updatedUserId: currentUser.id,
              createdAt: new Date(),
              updatedAt: new Date(),
            });
            await createOnboardingExperience(
              newOnboardingExperience,
              onboardingExperience.tasks.map((task) => {
                return OnboardingExperienceTaskFactory.createOnboardingTask({
                  ...task,
                  id: undefined,
                  onboardingExperienceId: newOnboardingExperience.id,
                });
              })
            );
            mutateOnboardingExperiences();
          },
        },
      });
    },
    [
      createOnboardingExperience,
      currentUser.id,
      currentUser.tenantId,
      handleModal,
      mutateOnboardingExperiences,
    ]
  );

  const handleClickAddEditorsButton = useCallback(
    (onboardingExperience: OnboardingExperience) => {
      handleModal({
        name: "addEditableEmployeesModal",
        args: {
          onSubmit: async (
            editableEmployeeIds: string[],
            emailsForEditableEmployeeIds: string[]
          ) => {
            await updateOnboardingExperience({
              onboardingExperienceId: onboardingExperience.id,
              newOnboardingExperience: OnboardingExperience.create({
                ...onboardingExperience,
                editableEmployeeIds: [
                  ...(onboardingExperience.editableEmployeeIds || []),
                  ...editableEmployeeIds,
                ],
              }),
              emailsForEditableEmployeeIds,
            });
            mutateAllEmployees();
            mutateOnboardingExperiences();
          },
          alreadyExistedEmployees: getEditableEmployees(
            onboardingExperience,
            allEmployees,
            admins,
            currentUser
          ),
          allEmployeesWithDeleted,
        },
      });
    },
    [
      allEmployees,
      allEmployeesWithDeleted,
      currentUser,
      getEditableEmployees,
      handleModal,
      mutateAllEmployees,
      mutateOnboardingExperiences,
      admins,
      updateOnboardingExperience,
    ]
  );

  const handleClickManageEditableEmployeesButton = useCallback(
    (onboardingExperience: OnboardingExperience) => {
      handleModal({
        name: "manageEditableEmployeesModal",
        args: {
          admins,
          editors: allEmployees.filter((v) =>
            onboardingExperience.editableEmployeeIds?.includes(v.id)
          ),
          onClickAddEditorsButton: (editors: Employee[]) =>
            handleClickAddEditorsButton(
              OnboardingExperience.create({
                ...onboardingExperience,
                editableEmployeeIds: editors.map((editor) => editor.id),
              })
            ),
          onClickDeleteEditorsButton: async (employeeId: string) => {
            await updateOnboardingExperience({
              onboardingExperienceId: onboardingExperience.id,
              newOnboardingExperience: OnboardingExperience.create({
                ...onboardingExperience,
                editableEmployeeIds: onboardingExperience.editableEmployeeIds
                  ? onboardingExperience.editableEmployeeIds.filter((v) => v !== employeeId)
                  : [],
              }),
            });
            mutateOnboardingExperiences();
          },
        },
      });
    },
    [
      allEmployees,
      handleClickAddEditorsButton,
      handleModal,
      mutateOnboardingExperiences,
      admins,
      updateOnboardingExperience,
    ]
  );

  const openPortalPreview = useOpenPortalOnboardingTaskPreview();
  const handleClickOpenPreviewButton = useCallback(
    (onboardingExperienceTasks: OnboardingExperienceTaskType[]) =>
      openPortalPreview(
        onboardingExperienceTasks.filter(
          (v): v is OnboardingExperienceSimpleTask => v.type === "SIMPLE_TASK"
        ),
        "/portal/onboarding_tasks?preview=true"
      ),
    [openPortalPreview]
  );

  const handleClickDeleteButton = useCallback(
    (onboardingExperience: OnboardingExperience) => {
      handleModal({
        name: "deleteOnboardingExperienceModal",
        args: {
          processTitle: onboardingExperience.title,
          onSubmit: async () => {
            await deleteOnboardingExperience(onboardingExperience.id);
            mutateOnboardingExperiences();
          },
        },
      });
    },
    [deleteOnboardingExperience, handleModal, mutateOnboardingExperiences]
  );

  // ====================
  // variables
  // ====================

  const headers = [
    {
      text: "タイプ",
      key: "type",
    },
    {
      text: "最終更新",
      key: "updatedAt",
    },
    {
      text: "編集可能メンバー",
      key: "editableMembers",
    },
    {
      text: "",
      key: "options",
    },
  ];

  const widthOptions = [7, 2, 2, 1] as GridSize[];

  // ====================
  // component
  // ====================

  return (
    <DnDTable
      headers={headers}
      widthOptions={widthOptions}
      dataArray={onboardingExperiences}
      editable={currentUser.isAdmin()} // admin のみDnD操作が可能
      onChangeExperiencesOrder={handleChangeExperiencesOrder}
      rowRenderer={(onboardingExperience) => {
        return (
          <StyledLink to={`/tools/onboarding_experiences/${onboardingExperience.id}`}>
            <StyledGrip id="gripVerticalWrapper" height="16px" minWidth="16px">
              <Icon icon="gripVertical" size="sm" color="lightGrey" />
            </StyledGrip>

            <StyledRow container alignItems="center">
              <StyledCell key="type" item xs={7}>
                <TooltipWhenTextTruncated text={onboardingExperience.title}>
                  {(ref) => (
                    <Typography variant="body1" bold noWrap ref={ref}>
                      {onboardingExperience.title}
                    </Typography>
                  )}
                </TooltipWhenTextTruncated>
                <Typography variant="body2" color="textSecondary">
                  タスク数: {onboardingExperience.tasks.length}
                </Typography>
              </StyledCell>
              <StyledCell key="updatedAt" item xs={2}>
                {onboardingExperience.updatedUserId && (
                  <EmployeeText updatedUserId={onboardingExperience.updatedUserId} />
                )}
                <Typography variant="caption" color="textSecondary">
                  {format(onboardingExperience.updatedAt, "yyyy/MM/dd")}
                </Typography>
              </StyledCell>
              <StyledCell key="editableMembers" item xs={2}>
                <EditableEmployeeIcons
                  key="editableEmployeesIcons"
                  employees={getEditableEmployees(
                    onboardingExperience,
                    allEmployees,
                    admins,
                    currentUser
                  )}
                />
              </StyledCell>
              <StyledCell key="options" item xs={1}>
                {getEditableEmployees(onboardingExperience, allEmployees, admins, currentUser).some(
                  (v) => v.id === currentUser.id
                ) && (
                  <ManageOnboardingExperienceMenu
                    onClickEditButton={() => handleClickEditButton(onboardingExperience)}
                    onClickDuplicateButton={() => handleClickDuplicateButton(onboardingExperience)}
                    onClickOpenPreviewButton={() =>
                      handleClickOpenPreviewButton(onboardingExperience.tasks)
                    }
                    onClickManageEditableEmployeesButton={() =>
                      handleClickManageEditableEmployeesButton(onboardingExperience)
                    }
                    onClickDeleteButton={() => handleClickDeleteButton(onboardingExperience)}
                  />
                )}
              </StyledCell>
            </StyledRow>
          </StyledLink>
        );
      }}
    />
  );
};

// ====================
// styles
// ====================

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 0 40px;
  width: 100%;
  position: relative;

  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover,
  &:active {
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;

const StyledGrip = styled(Box)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
`;

const StyledRow = styled(Grid)`
  padding: 24px 0;
  border-top: 1px solid ${(props) => props.theme.palette.grey[100]};
`;

const StyledCell = styled(Grid)`
  &.MuiGrid-root {
    padding-right: 16px;
  }
`;
