import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import { ContactMessageDraft } from "@onn/common";
import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

import { TabValue } from "./hooks/useContactRoomListV2";
import { useViewModelV2 } from "./hooks/useViewModelV2";

import { ContactRoomListItemV2, NewHirePCContactRoomItem } from "~/components/domains/contactRooms";
import { Icon, Loading, TextField, Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { useContactMessageDraftByCreatedUserId } from "~/hooks/contactMessageDraft";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { captureException } from "~/util";

export const NewHirePCContactMessages: FC = () => {
  const {
    currentContactRooms,
    activeTab,
    setActiveTab,
    setQueryString,
    renderIsCompletedFilter,
    searchValue,
    setSearchValue,
    setSelectedContactRoomId,
    contactRoomUnreadCountList,
    selectedContactRoomId,
    latestContactMessages,
    isLoadingLatestMessages,
    isLoadingContactRooms,
    isLoadingUnreadMessageCountList,
  } = useViewModelV2();

  const { currentUser } = useCurrentUser();

  const {
    contactMessageDrafts,
    isLoading: isLoadingDraft,
    saveContactMessageDraft,
  } = useContactMessageDraftByCreatedUserId(currentUser.id);

  const isLoading =
    isLoadingContactRooms || isLoadingLatestMessages || isLoadingUnreadMessageCountList;

  const tabs: Array<{ label: string; value: TabValue }> = [
    {
      label: "あなた",
      value: "my",
    },
    {
      label: "全体",
      value: "all",
    },
  ];

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    setActiveTab(newValue);
  };

  return (
    <StyledContainerBox>
      <Box display="flex" height="100vh">
        <StyledBox width="360px" bgcolor="white" display="flex" flexDirection="column">
          <TabContext value={activeTab}>
            <Box width="360px" pt="8px" pb="24px" px="24px">
              <StyledTabList
                onChange={handleChangeActiveTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {tabs.map((tab) => (
                  <StyledTab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </StyledTabList>
              <Box mt="16px">
                <StyledForm onSubmit={(e) => e.preventDefault()}>
                  <TextField
                    autoFocus
                    fullWidth
                    variant="outlined"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    endAdornment={<Icon size="sm" color="grey" icon="search" />}
                  />
                </StyledForm>
              </Box>
              <Box mt="16px">{renderIsCompletedFilter()}</Box>
            </Box>
            <Box height="100%">
              {isLoading ? (
                <Loading size={"small"} />
              ) : (
                <AutoSizer>
                  {(size) => (
                    <VirtualizedList
                      height={size.height}
                      width={size.width}
                      overscanRowCount={10} // 先にレンダリングしておくリストの数
                      rowCount={currentContactRooms.length}
                      rowHeight={80}
                      rowRenderer={(props) => {
                        const contactRoom = currentContactRooms[
                          props.index
                        ] as (typeof currentContactRooms)[number];
                        const latestMessage = latestContactMessages?.find(
                          (lm) => lm.contactRoomId === contactRoom.id
                        );

                        const unreadCountInfo = contactRoomUnreadCountList.find(
                          (v) => v.contactRoomId === contactRoom.id
                        );
                        const unreadCount = unreadCountInfo?.unreadCount || 0;
                        // NOTE: コンタクトルームは存在するのに、未読情報が存在しない場合はSentryで通知を行う
                        if (!unreadCountInfo && !isLoadingUnreadMessageCountList) {
                          captureException({
                            error: new Error("未読情報が存在していません"),
                            tags: { type: "NewHirePCContactMessages" },
                            extras: {
                              contactRoomId: contactRoom.id,
                              unreadCountInfo,
                            },
                          });
                        }
                        return (
                          <Box
                            key={props.key}
                            style={props.style} // 動的に計算されるtopなどのプロパティが入る
                          >
                            <ContactRoomListItemV2
                              key={props.key}
                              isSelected={
                                !!selectedContactRoomId && contactRoom.id === selectedContactRoomId
                              }
                              onClickContactRoomListItem={() => {
                                setSelectedContactRoomId(contactRoom.id);
                                setQueryString({ contactRoomId: contactRoom.id });
                              }}
                              contactRoom={contactRoom}
                              unreadCount={unreadCount}
                              latestMessage={latestMessage}
                            />
                          </Box>
                        );
                      }}
                      noRowsRenderer={() => (
                        <Box
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                          height="100%"
                        >
                          <Typography variant="body1" align="center">
                            {`担当者になっている\nコンタクトはありません`}
                          </Typography>
                        </Box>
                      )}
                    />
                  )}
                </AutoSizer>
              )}
            </Box>
          </TabContext>
        </StyledBox>
        <Box width="100%">
          {selectedContactRoomId &&
            (!isLoadingDraft ? (
              <NewHirePCContactRoomItem
                contactMessageDraft={
                  contactMessageDrafts.find(
                    ({ contactRoomId }) => contactRoomId === selectedContactRoomId
                  ) ??
                  ContactMessageDraft.create({
                    tenantId: currentUser.tenantId,
                    createdUserId: currentUser.id,
                    contactRoomId: selectedContactRoomId,
                    text: "",
                  })
                }
                contactRoomId={selectedContactRoomId}
                saveContactMessageDraft={saveContactMessageDraft}
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Loading size="large" />
              </Box>
            ))}
        </Box>
      </Box>
    </StyledContainerBox>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: 100vh;
`;

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[2]};
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;

    > .MuiTab-wrapper {
      font-size: 16px;
    }
  }
`;
