import {
  Employee,
  Role,
  MidCarrierPropertyForInvite,
  removeUndefinedFromObject,
  SlackUser,
} from "@onn/common";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  reauthenticateWithCredential,
  User,
  updateEmail,
  updatePassword,
  signInWithCustomToken,
  UserCredential,
} from "firebase/auth";
import {
  doc,
  DocumentData,
  updateDoc,
  DocumentReference,
  deleteField,
  WriteBatch,
} from "firebase/firestore";

// eslint-disable-next-line import/no-cycle
import { auth, firestore } from "../../config/firebase";
// eslint-disable-next-line import/no-cycle
import {
  IEmployeeRepository,
  IAccountRepository,
} from "../../service/repository/iEmployeeRepository";

import { functionOperator } from "./functionOperator";

import { apiClient } from "~/libs";

const COLLECTION_NAME = "employees";

const signInErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "メールアドレスまたはパスワードが正しくありません";
    case "auth/user-disabled":
      return "メールアドレスまたはパスワードが正しくありません";
    case "auth/user-not-found":
      return "メールアドレスまたはパスワードが正しくありません";
    case "auth/wrong-password":
      return "メールアドレスまたはパスワードが正しくありません";
    case "auth/too-many-requests":
      return "ログインの失敗回数が多すぎます。後でもう一度やり直してください。";
    case "deleted":
      return "メールアドレスまたはパスワードが正しくありません";
    default:
      return "";
  }
};

const passwordErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case "auth/weak-password":
      return "半角英数字6文字以上で入力してください";
    case "auth/wrong-password":
      return "現在のパスワードが間違っています";
    case "auth/too-many-requests":
      return "認証の失敗回数が多すぎます。後でもう一度やり直してください。";
    default:
      return "パスワードを変更できませんでした";
  }
};

const emailErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "メールアドレスは無効です";
    case "auth/email-already-in-use":
      return "こちらのメールアドレスは既に利用されています";
    case "auth/too-many-requests":
      return "認証の失敗回数が多すぎます。後でもう一度やり直してください。";
    case "auth/wrong-password":
      return "現在のパスワードが間違っています";
    default:
      return "メールアドレスの変更ができませんでした";
  }
};
export class EmployeeRepository implements IEmployeeRepository {
  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findByUid(): Promise<Employee[]> {
    const response = await apiClient.get("/get_employees_by_uid");
    return response.data.map((v) => {
      return Employee.plainToInstance(v);
    });
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findById(employeeId: string): Promise<Employee | undefined> {
    return await apiClient
      .get("/get_employee", { id: employeeId })
      .then((res) => (res.data ? Employee.plainToInstance(res.data) : undefined));
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async whereByIds(ids: string[]): Promise<Employee[]> {
    return apiClient
      .post("/get_employees", { ids, includeDeleted: true })
      .then((res) => res.data.map((data: Employee) => new Employee(data)));
  }

  async update(employeeId: string, updates: Partial<Employee>): Promise<void> {
    await updateDoc(this.doc(employeeId), removeUndefinedFromObject(updates));
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findAll(_tenantId: string): Promise<Employee[]> {
    const response = await apiClient.get("/listemployeesapi", {
      "include-deleted": false,
      limit: 1_000_000,
      offset: 0,
      filter: { isAcrossSpace: true },
    });
    return response.data.data.map((v) => {
      return Employee.plainToInstance(v);
    });
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findAllWithDeleted(): Promise<Employee[]> {
    const response = await apiClient.get("/listemployeesapi", {
      "include-deleted": true,
      limit: 1_000_000,
      offset: 0,
      filter: { isAcrossSpace: true },
    });
    return response.data.data.map((v) => {
      return new Employee(v);
    });
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findAllNewcomers(): Promise<Employee[]> {
    const response = await apiClient.get("/listemployeesapi", {
      "include-deleted": false,
      limit: 1_000_000,
      offset: 0,
      filter: { isAcrossSpace: true, isNewcomer: true },
    });
    return response.data.data.map((v) => {
      return Employee.plainToInstance(v);
    });
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findByRole(_tenantId: string, role: Role): Promise<Employee[]> {
    const response = await apiClient.get("/listemployeesapi", {
      "include-deleted": false,
      limit: 1_000_000,
      offset: 0,
      filter: { role },
    });
    return response.data.data.map((v) => {
      return new Employee(v);
    });
  }

  async getAllUserSlack(): Promise<SlackUser[]> {
    const slackUsers = await functionOperator
      .httpsCallFor2ndGen<never, SlackUser[]>("getalluserslack")
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error("getAllUserSlack fail.", error);
        return [];
      });
    return slackUsers;
  }

  async integrateToSlack(
    email: string,
    userId: string,
    slackUserId: string,
    profileImageUrl: string
  ): Promise<void> {
    const data = {
      email: email,
      slackUserId: slackUserId,
      userId: userId,
      profileImageUrl: profileImageUrl,
    };

    await functionOperator.httpsCallFor2ndGen("integratetoslack", data).catch((e) => {
      throw new Error(e);
    });
  }

  insertDeleteSlackUserIdInBatch(batch: WriteBatch, id: string): void {
    batch.update(this.doc(id), {
      slackUserId: deleteField(),
    });
  }

  async deleteNewHire(employeeId: string): Promise<void> {
    const data = {
      employeeId: employeeId,
    };

    await functionOperator.httpsCall("deleteEmployee", data).catch((error) => {
      throw new Error(error.message);
    });
  }
  async checkMailExist(email: string): Promise<boolean> {
    const data = {
      email: email,
    };

    return functionOperator
      .httpsCallFor2ndGen<unknown, boolean>("checkmailexist", data)
      .then((res) => {
        return res.data;
      });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }
}

export class AccountRepository implements IAccountRepository {
  async signIn(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(auth, email, password).catch(async (e) => {
      console.error(e.code, e.message);
      const errorMessage = signInErrorMessage(e.code);
      throw new Error(errorMessage);
    });
  }
  async signInWithCustomToken(token: string): Promise<UserCredential> {
    return await signInWithCustomToken(auth, token).catch(async (e) => {
      const errorMessage = signInErrorMessage(e.code);
      throw new Error(errorMessage);
    });
  }

  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  async findByUid(): Promise<Employee[]> {
    const response = await apiClient.get("/get_employees_by_uid");
    return response.data.map((v) => {
      return Employee.plainToInstance(v);
    });
  }
  async newHireAccountCreate(
    userDataArray: MidCarrierPropertyForInvite[]
  ): Promise<{ createdNewHires: Employee[]; failedNewHires: Employee[] }> {
    const res = await functionOperator.httpsCallFor2ndGen("newhireaccountcreate", {
      userDataArray,
    });

    // TODO: monorepo化完了後型を統一する
    const data = res.data as {
      succeededResultArray: { newHire: Employee }[];
      failedEmployeeArray: Employee[];
    };
    return {
      createdNewHires: data.succeededResultArray.map((v) => v.newHire),
      failedNewHires: data.failedEmployeeArray,
    };
  }
  async sendPasswordResetEmail(email: string): Promise<void> {
    await sendPasswordResetEmail(auth, email, { url: window.location.origin }).catch((e) => {
      console.error(e);
      throw new Error("パスワード再設定のメール送信に失敗しました");
    });
  }
  async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    if (!auth.currentUser) {
      throw new Error("ログインしているユーザーが存在しません");
    }
    const authEmail = auth.currentUser.email;

    if (!authEmail) {
      throw new Error("ログイン中のユーザーのemailが取得できませんでした");
    }
    const credential = EmailAuthProvider.credential(authEmail, currentPassword);

    await reauthenticateWithCredential(auth.currentUser, credential).catch((e) => {
      const errorMessage = passwordErrorMessage(e.code);
      throw new Error(errorMessage);
    });
    await updatePassword(auth.currentUser, newPassword).catch((e) => {
      const errorMessage = passwordErrorMessage(e.code);
      throw new Error(errorMessage);
    });
  }

  async updateEmail(newEmail: string, currentPassword: string): Promise<void> {
    if (!auth.currentUser) {
      throw new Error("ログインしているユーザーが存在しません");
    }
    const authEmail = auth.currentUser.email;

    if (!authEmail) {
      throw new Error("ログイン中のユーザーのemailが取得できませんでした");
    }

    const credential = EmailAuthProvider.credential(authEmail, currentPassword);

    await reauthenticateWithCredential(auth.currentUser, credential).catch((e) => {
      const errorMessage = passwordErrorMessage(e.code);
      throw new Error(errorMessage);
    });
    await updateEmail(auth.currentUser, newEmail).catch((e) => {
      const errorMessage = emailErrorMessage(e.code);
      throw new Error(errorMessage);
    });
  }

  /**
   * Authenticate Using Google Sign-In
   */
  async signInWithPopup(): Promise<User> {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then(async (result) => {
        return result.user;
      })
      .catch(async (error) => {
        // No error is displayed when user manipulates the popup.
        throw new Error(error);
      });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }
}
