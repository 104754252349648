import { Box } from "@material-ui/core";
import { SurveyTransaction, TransactionStatus, Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { Loading, Typography } from "~/components/uiParts";
import { useCalculateENPS } from "~/hooks/transaction";

type Status = "Rainy" | "Cloudy" | "Sunny";

const decideStatus = (score: number): Status => {
  if (score <= -5) {
    return "Rainy";
  } else if (score < 5) {
    return "Cloudy";
  } else {
    return "Sunny";
  }
};

const statusLabel: Record<Status, string> = {
  Rainy: "☂",
  Cloudy: "☁",
  Sunny: "☀",
};

type Props = {
  employees: Employee[];
  surveyTransactions: SurveyTransaction[];
  isValidating: boolean;
};

export const ENPS: FC<Props> = ({ employees, surveyTransactions, isValidating }) => {
  const { calculateENPS } = useCalculateENPS();
  const completedSurveyTransactions = useMemo(
    () => surveyTransactions.filter((t) => t.status === TransactionStatus.DONE),
    [surveyTransactions]
  );
  const hasScore = useMemo(
    () => !isEmpty(employees) && !isEmpty(completedSurveyTransactions),
    [employees, completedSurveyTransactions]
  );
  const score = useMemo(
    () => calculateENPS(completedSurveyTransactions),
    [calculateENPS, completedSurveyTransactions]
  );
  const displayText = useMemo(() => {
    if (isEmpty(employees)) return "該当入社者なし";
    if (isEmpty(surveyTransactions)) return "アンケート未配信";
    if (isEmpty(completedSurveyTransactions)) return "回答データなし";

    return `${score}`;
  }, [completedSurveyTransactions, employees, score, surveyTransactions]);

  return isValidating ? (
    <Loading size="small" />
  ) : (
    <StyledBox display="flex" gridGap="5px">
      {hasScore && (
        <StyledIconTypography variant="h2">{statusLabel[decideStatus(score)]}</StyledIconTypography>
      )}
      <StyledTypography
        bold={hasScore}
        variant={hasScore ? "h2" : "body2"}
        align="center"
        $status={decideStatus(score)}
      >
        {displayText}
      </StyledTypography>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  justify-content: center;
  align-items: center;
`;

const StyledIconTypography = styled(Typography)`
  font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols,
    emojione mozilla, twemoji mozilla, segoe ui symbol !important;
`;

const StyledTypography = styled(Typography)<{ $status: Status }>`
  color: ${(props) =>
    props.$status === "Rainy"
      ? props.theme.palette.secondary.main
      : props.theme.palette.text.primary};
`;
