import { Box, Grid, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { FC, ChangeEvent, useState } from "react";

import styled from "styled-components";

import { NewHireTab } from "./parts/NewHireTab";
import { useViewModel } from "./useViewModel";

import { Typography, Loading } from "~/components/uiParts";

type TabValue = "newHire";

export const NewHireTopIndex: FC = () => {
  const {
    allNewHires,
    filteredNewHiresForAllStatus,
    newHiresForTableView,
    selectedDepartmentIds,
    isSelectedNoDepartment,
    setIsSelectedNoDepartment,
    selectedStatus,
    setSelectedStatus,
    order,
    setOrder,
    setSelectedDepartmentIds,
    invitedEmployees,
    preboardingEmployees,
    onboardingEmployees,
    completedEmployees,
    setSearchValue,
    selectedJoinAt,
    setSelectedJoinAt,
    selectableJoinAt,
  } = useViewModel();

  const [activeTab, setActiveTab] = useState<TabValue>("newHire");
  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue): void => {
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: TabValue }> = [
    {
      label: `入社者(${allNewHires.length})`,
      value: "newHire",
    },
  ];

  return (
    <>
      {!filteredNewHiresForAllStatus ? (
        <Loading size="large" fullHeight />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box display="inline" pr={2}>
                <Typography variant="h4" bold display="inline">
                  オンボーディング
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <TabContext value={activeTab}>
              <StyledTabList
                onChange={handleChangeActiveTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
                {tabs.map((tab) => (
                  <StyledTab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </StyledTabList>
              <StyledTabPanel value="newHire">
                <NewHireTab
                  filteredEmployeesForAllStatus={filteredNewHiresForAllStatus}
                  employeesForTableView={newHiresForTableView}
                  selectedDepartmentIds={selectedDepartmentIds}
                  setSelectedDepartmentIds={setSelectedDepartmentIds}
                  isSelectedNoDepartment={isSelectedNoDepartment}
                  setIsSelectedNoDepartment={setIsSelectedNoDepartment}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  selectedJoinAt={selectedJoinAt}
                  setSelectedJoinAt={setSelectedJoinAt}
                  selectableJoinAt={selectableJoinAt}
                  order={order}
                  setOrder={setOrder}
                  invitedEmployees={invitedEmployees}
                  preboardingEmployees={preboardingEmployees}
                  onboardingEmployees={onboardingEmployees}
                  completedEmployees={completedEmployees}
                  setSearchValue={setSearchValue}
                />
              </StyledTabPanel>
            </TabContext>
          </Box>
        </>
      )}
    </>
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
