import { Employee, OnboardingExperienceTaskTemplate } from "@onn/common";

import { factory } from "~/service/repository/iOnboardingExperienceTaskTemplateRepository";

const onboardingExperienceTaskTemplateRepository =
  factory.onboardingExperienceTaskTemplateRepository();

export class CreateOnboardingExperienceTaskTemplateUseCase {
  async execute(
    currentUser: Employee,
    onboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate
  ): Promise<void> {
    if (!currentUser.isAdmin()) {
      throw new Error("タスクテンプレートを作成する権限がありません。");
    }

    await onboardingExperienceTaskTemplateRepository.create(onboardingExperienceTaskTemplate);
  }
}
