import { OnboardingExperience, Department } from "@onn/common";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = (
    onboardingExperiences: OnboardingExperience[],
    departments: Department[]
  ) => {
    const baseData = [
      ["「部署」コピー欄", "「体験」コピー欄"],
      [
        "該当する「部署」は以下候補からコピーして入社者情報記入欄にペーストしてください",
        "該当する「体験」は以下候補からコピーして入社者情報記入欄にペーストしてください",
      ],
    ];
    const generatedData = [...baseData];

    // lengthは長い方に合わせる
    const length = Math.max(departments?.length ?? 0, onboardingExperiences.length);

    // 部署は一列目に配置
    for (let index = 0; index < length; index++) {
      const experience = onboardingExperiences[index];
      const department = departments[index];
      generatedData.push([
        department ? Department.getFullName(departments, department.id) : "",
        experience ? experience.title : "",
      ]);
    }

    return generatedData;
  };

  return { generateSampleCSV };
};
