import React, { FC } from "react";

import { NewHirePCContactMessages, NewHireSPContactMessages } from "./NewHire";

import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

export const ContactMessages: FC = () => {
  const shouldSPView = useShouldSPView();

  return shouldSPView ? <NewHireSPContactMessages /> : <NewHirePCContactMessages />;
};
