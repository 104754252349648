import { Box } from "@material-ui/core";
import { format } from "date-fns";
import { first } from "lodash";
import React, { useMemo, FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Loading, Paper, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSurveySendAts, useSurveyTransactions } from "~/hooks/transaction";

export const SurveyResponseRatePaper: FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { data: surveySendAts = [] } = useSurveySendAts(currentUser.tenantId);
  const surveySendAt = first(surveySendAts);
  const { data: surveyTransactions = [], isLoading: isLoadingSurveyTransactions } =
    useSurveyTransactions({ sendAt: first(surveySendAts), tenantId: currentUser.tenantId });

  const [completedPercent, wholeCountOfSurveys] = useMemo(() => {
    let completedCount = 0;
    let wholeCountOfSurveys = 0;
    surveyTransactions.forEach((surveyTransaction) => {
      wholeCountOfSurveys += 1;
      if (surveyTransaction.status === "DONE") {
        completedCount += 1;
      }
    });

    return [(completedCount / wholeCountOfSurveys) * 100, wholeCountOfSurveys];
  }, [surveyTransactions]);

  return (
    <StyledPaper square>
      <Typography align="center" bold>
        アンケート回答率：{" "}
        {surveySendAt ? format(new Date(surveySendAt), "yyyy/M/d") : "----/--/--"} 配信分
      </Typography>
      {isLoadingSurveyTransactions ? (
        <Box pt="40px">
          <Loading size="small" />
        </Box>
      ) : (
        <>
          {surveySendAt ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                mt="56px"
                alignItems="center"
                gridRowGap="32px"
              >
                <Button
                  variant="text"
                  borderRadius="regular"
                  color="default"
                  onClick={() =>
                    navigate(
                      `/tasks?sendAt=${format(new Date(surveySendAt), "yyyy-MM-dd")}#surveyAnswered`
                    )
                  }
                >
                  <Typography align="center" variant="h2" bold color="textPrimary">
                    {Math.round(completedPercent)} %
                  </Typography>
                </Button>
                <Box display="flex" flexDirection="column">
                  <Typography align="center" variant="body2">
                    回答率
                  </Typography>
                  <Typography align="center" variant="body2">
                    （配信数：{wholeCountOfSurveys}件）
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Typography align="center" bold>
                アンケート回答率： 該当なし
              </Typography>
              <Box display="flex" flexDirection="column" mt="56px">
                <Typography align="center" variant="h4">
                  該当アンケートなし
                </Typography>
              </Box>
            </>
          )}
        </>
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;
