import {
  EmployeeToDisplay,
  DifferenceDate,
  Employee,
  MessageContent,
  OnboardingMessageTask,
  OnboardingTaskFactory,
  OnboardingTaskMemo,
  OnboardingTaskType,
} from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

// OnboardingTaskRepository から踏襲
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertPlainToInstance = (data: any) => {
  if (data.type === "MESSAGE_TASK") {
    return OnboardingMessageTask.create({
      ...data,
      deliveryDate: data.deliveryDate ? new DifferenceDate(data.deliveryDate) : undefined,
      dueDate: new DifferenceDate(data.dueDate),
      memos: data.memos.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (memo: any) =>
          new OnboardingTaskMemo({
            ...memo,
          })
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contents: data.contents.map((content: any) => {
        return new MessageContent({
          ...content,
        });
      }),
    });
  }

  return OnboardingTaskFactory.createOnboardingTask({
    ...data,
    deliveryDate: data.deliveryDate ? new DifferenceDate(data.deliveryDate) : undefined,
    dueDate: new DifferenceDate(data.dueDate),
    memos: data.memos.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (memo: any) =>
        new OnboardingTaskMemo({
          ...memo,
        })
    ),
  });
};

export const generateUseOnboardingTask = (id: string) => {
  return ["onboardingTask", id];
};
/**
 * idをもとにonboardingTaskを返すswr
 * @param {string} id id
 */
export const useOnboardingTask = (
  id?: string,
  option: {
    /**
     * 初期データ
     */
    fallbackData?: OnboardingTaskType;
    /**
     * hook呼び出し時に自動で再検証をするかどうか(デフォルトでtrue)
     */
    shouldAutoRevalidate?: boolean;
  } = { fallbackData: undefined, shouldAutoRevalidate: true }
): SWRResponse<{ onboardingTask: OnboardingTaskType; employee?: EmployeeToDisplay }, Error> => {
  const keys = id ? generateUseOnboardingTask(id) : null;

  const additionalOption = !option?.shouldAutoRevalidate
    ? {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    : undefined;

  return useSWR<{ onboardingTask: OnboardingTaskType; employee?: EmployeeToDisplay }>(
    keys,
    async ([_key, id]: [string, string]) => {
      const res = await apiClient.get("/get_onboarding_task", { taskId: id });

      return {
        onboardingTask: convertPlainToInstance(res.data.onboardingTask),
        employee: new Employee(res.data.employee),
      };
    },
    {
      fallbackData: option?.fallbackData ? { onboardingTask: option?.fallbackData } : undefined,
      ...additionalOption,
    }
  );
};
