import { OnboardingTaskMemo } from "../../domain";

export const createIOnboardingTaskMemo = (
  mock: Partial<OnboardingTaskMemo> = {}
): OnboardingTaskMemo => {
  return new OnboardingTaskMemo({
    id: mock.id ?? "OnboardingTaskMemo1",
    text: mock.text ?? "This is Memo Text",
    createdEmployeeId: mock.createdEmployeeId ?? "employee1",
    updatedEmployeeId: mock.updatedEmployeeId ?? "employee1",
    createdAt: mock.createdAt ?? new Date("2020/05/18 17:41"),
    updatedAt: mock.updatedAt ?? new Date("2020/05/18 17:41"),
  });
};
