import { Box } from "@material-ui/core";
import { Employee, Department } from "@onn/common";
import React, { VFC, useCallback, useState, useMemo } from "react";
import styled from "styled-components";

import { DepartmentSelect } from "../DepartmentSelect";

import { Button, Typography, Modal } from "~/components/uiParts";
import { useDepartmentsByTenantId } from "~/hooks/department";
import { useCurrentUser } from "~/hooks/employee";
import { captureException, mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (employee: Employee, departmentIds: string[]) => Promise<void>;
  employee: Employee;
};

export const ChangeDepartmentModal: VFC<Props> = ({ open, onCancel, onSubmit, employee }) => {
  const { currentUser } = useCurrentUser();
  const { data: departments = [], mutate } = useDepartmentsByTenantId(currentUser.tenantId);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState(employee.departmentIds);

  const selectableDepartments = useMemo(
    () =>
      currentUser.isAdmin()
        ? departments
        : // 部門管理者は、自身の所属とその下位の所属のみ変更可能
          departments.filter((department) =>
            Department.getChildIds(currentUser.departmentIds, departments).includes(department.id)
          ),
    [currentUser, departments]
  );

  const handleSubmit = useCallback(async () => {
    setIsProcessing(true);
    await onSubmit(employee, selectedDepartmentIds)
      .then(() => onCancel())
      .catch((e) => {
        mutate();
        captureException({
          error: e as Error,
          tags: { type: "ChangeDepartmentModal:handleSubmit" },
        });
      }) // 部署再取得
      .finally(() => setIsProcessing(false));
  }, [employee, selectedDepartmentIds, mutate, onCancel, onSubmit]);

  const Content: JSX.Element = (
    <>
      <StyledTypography variant="body1">
        部署を変更すると入社者に関する情報の閲覧権限も変更されます。{"\n"}
        「管理者」と選択した部署に紐づく「部門管理者」に閲覧権限が付与し直され、{"\n"}
        「バディ」「サポートメンバー」に設定されているメンバーの閲覧権限は残ります。
      </StyledTypography>
      <Box maxWidth="504px" mt={3} mx="auto">
        <DepartmentSelect
          departments={selectableDepartments}
          selectedDepartmentIds={selectedDepartmentIds}
          onChange={(selectedDepartmentIds) => setSelectedDepartmentIds(selectedDepartmentIds)}
        />
      </Box>
    </>
  );

  const Footer: JSX.Element = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isProcessing}
      >
        変更
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="部署変更" content={Content} footer={Footer} onCancel={onCancel} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    text-align: center;
  }
`;
