import { APISchema, Employee } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export type RegistrationInfo = APISchema["/get_registration_info"]["POST"]["response"]["data"];

/**
 * invitationTokenを元に登録に必要なデータを取得する
 * @param invitationToken invitationToken
 * @returns {RegistrationInfo}
 */
export const useRegistrationInfoByInvitationToken = (invitationToken?: string) => {
  return useSWR(
    invitationToken ? (["/get_registration_info", invitationToken] as const) : null,
    async ([endpoint, invitationToken]) => {
      const response = await apiClient.post(endpoint, {
        "invitation-token": invitationToken,
      });

      return {
        employee: new Employee(response.data.employee),
        tenantName: response.data.tenantName,
        enTenantName: response.data.enTenantName,
        tenantLogoUrl: response.data.tenantLogoUrl,
        emailAuthenticationStatus: response.data.emailAuthenticationStatus,
      };
    }
  );
};
