import React, { FC } from "react";

export const Cross: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g transform="translate(-1598.546 -292.54)">
        <rect
          width="4"
          height="32"
          rx="2"
          transform="translate(1628.173 299.539) rotate(45)"
          fill="#757575"
        />
        <rect
          width="4"
          height="32"
          rx="2"
          transform="translate(1631.002 322.167) rotate(135)"
          fill="#757575"
        />
      </g>
    </svg>
  );
};
