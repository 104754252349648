import { Employee, Department } from "@onn/common";
import React, { FC, createContext, ReactNode, useCallback } from "react";

import { useDepartmentsByTenantId } from "~/hooks/department";
import { useCurrentUser } from "~/hooks/employee";

export const AccessControlContext = createContext<{
  isEditable: (newHire: Employee) => boolean;
}>({
  isEditable: () => false,
});

export const AccessControlProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const { data: departments = [] } = useDepartmentsByTenantId(currentUser.tenantId);

  const isEditable = useCallback(
    (newHire: Employee): boolean => {
      if (currentUser.isAdmin()) return true;
      return (
        // 部門管理者の管理対象である部署に、入社者の所属部署が含まれているかどうか
        currentUser.isDepartmentAdmin() &&
        Department.getChildIds(currentUser.departmentIds, departments).some((id) =>
          newHire.departmentIds.includes(id)
        )
      );
    },
    [currentUser, departments]
  );

  if (!departments) return null;

  return (
    <AccessControlContext.Provider value={{ isEditable }}>{children}</AccessControlContext.Provider>
  );
};
