import { useCallback, useState } from "react";
import { useSWRConfig } from "swr";

import { generateOnboardingExperienceTaskTemplatesKeys } from "./useOnboardingExperienceTaskTemplates";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { UpdateOnboardingExperienceTaskTemplateUseCase } from "~/service/usecases/onboardingExperienceTaskTemplate";
import { captureException } from "~/util";

const useCase = new UpdateOnboardingExperienceTaskTemplateUseCase();

/**
 * onboardingExperienceTaskTemplate を更新する関数を提供する
 */
export const useUpdateOnboardingExperienceTaskTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();

  /**
   * onboardingExperienceTaskTemplate を更新する
   * @param {string} onboardingExperienceTaskId
   * @param {{ title: string; description: string }} params
   */
  const updateOnboardingExperienceTaskTemplate = useCallback(
    async (onboardingExperienceTaskId: string, params: { title: string; description: string }) => {
      setIsLoading(true);
      await useCase
        .execute(currentUser, onboardingExperienceTaskId, params)
        .then(() => {
          enqueueSnackbar("テンプレートを更新しました", { variant: "success" });
          mutate(generateOnboardingExperienceTaskTemplatesKeys(currentUser.tenantId));
        })
        .catch((_e) => {
          enqueueSnackbar("テンプレートを更新できませんでした", { variant: "error" });
          captureException({
            error: _e as Error,
            tags: {
              type: "useUpdateOnboardingExperienceTaskTemplate:updateOnboardingExperienceTaskTemplate",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar, mutate]
  );

  return { isLoading, updateOnboardingExperienceTaskTemplate };
};
