import { Box, Menu } from "@material-ui/core";
import { displayAssigneeRoleMap, OnboardingExperienceTask } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { SelectEmployeesFormByPlatform } from "~/components/domains/employees/SelectEmployeesFormByPlatform";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  SelectForm,
  Tooltip,
  Typography,
} from "~/components/uiParts";

// 単一選択 & ユーザー選択無し
type SingleAndNotSelectableUserProps = {
  isSelectableUser: false;
  isMultiple: false;
  assignableRoles: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">[];
  selectedAssigneeRole: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">;
  setAssigneeRole: (value: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">) => void;
};

// 単一選択 & ユーザー選択あり
type SingleAndSelectableUserProps = {
  isSelectableUser: true;
  isMultiple: false;
  assignableRoles: OnboardingExperienceTask["assigneeRole"][];
  selectedAssigneeRole: OnboardingExperienceTask["assigneeRole"];
  setAssigneeRole: (value: OnboardingExperienceTask["assigneeRole"]) => void;
};

// 複数選択 & ユーザー選択無し
type MultipleAndNotSelectableUserProps = {
  isSelectableUser: false;
  isMultiple: true;
  assignableRoles: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">[];
  selectedAssigneeRoles: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">[];
  setAssigneeRole: (value: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">) => void;
};

// 複数選択 & ユーザー選択あり
type MultipleAndSelectableUserProps = {
  isSelectableUser: true;
  isMultiple: true;
  assignableRoles: OnboardingExperienceTask["assigneeRole"][];
  selectedAssigneeRoles: OnboardingExperienceTask["assigneeRole"][];
  setAssigneeRole: (value: OnboardingExperienceTask["assigneeRole"]) => void;
};

type SlackUserProps = {
  selectedEmails: string[];
  setSelectedEmails: (emails: string[]) => void;
};

type Props = {
  tooltipText: string;
  caption?: string;
  readOnly?: boolean;
} & (
  | SingleAndNotSelectableUserProps
  | MultipleAndNotSelectableUserProps
  | (SlackUserProps & (SingleAndSelectableUserProps | MultipleAndSelectableUserProps))
);

export const AssigneeRoleForm: FC<Props> = (props) => {
  const { tooltipText, caption, assignableRoles, isSelectableUser, isMultiple, readOnly } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const assigneeRoles = useMemo(() => {
    return assignableRoles.map((v) => {
      return { value: v, name: displayAssigneeRoleMap[v] };
    });
  }, [assignableRoles]);

  const displayAssigneeRoleList = useMemo(
    () =>
      props.isMultiple
        ? props.selectedAssigneeRoles.map((role) => displayAssigneeRoleMap[role])
        : [],
    [props]
  );

  const handleChangeAssigneeRole = (
    value:
      | OnboardingExperienceTask["assigneeRole"]
      | Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">
  ) => {
    if (isSelectableUser) {
      props.setAssigneeRole(value as OnboardingExperienceTask["assigneeRole"]);
      if (value !== "BY_NAME") {
        props.setSelectedEmails([]);
      }
    } else {
      props.setAssigneeRole(value as Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">);
    }
  };

  return (
    <>
      <Box mt={5} display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          担当・依頼対象
        </Typography>
        <Tooltip title={tooltipText} placement="top-start">
          <Icon icon="help" size="sm" color="grey" />
        </Tooltip>
      </Box>
      {caption && <StyledMutedTypography variant="caption">{caption}</StyledMutedTypography>}
      <Box mt="16px" display="flex" gridGap="24px">
        {isMultiple && (
          <>
            <Box width="240px">
              <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                borderRadius="regular"
                variant="outlined"
                color="default"
                fullWidth
                disabled={readOnly}
              >
                <Box width="100%" display="flex" alignItems="center" gridGap="4px">
                  {props.selectedAssigneeRoles.length ? (
                    <Typography variant="body2" noWrap>
                      {displayAssigneeRoleList.join(",")}
                    </Typography>
                  ) : (
                    <Typography variant="body2">-</Typography>
                  )}
                  <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
                </Box>
              </Button>
            </Box>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: -8, horizontal: "right" }}
            >
              <Box width="240px" pl={2}>
                {assigneeRoles.map((role) => (
                  <StyledFormControlLabel
                    key={role.value}
                    control={
                      <Checkbox
                        checked={props.selectedAssigneeRoles.some((value) => value === role.value)}
                        onChange={() => {
                          if (isSelectableUser) {
                            props.setAssigneeRole(role.value);
                          } else {
                            role.value === "BY_NAME" ? {} : props.setAssigneeRole(role.value);
                          }
                        }}
                        value={role.value}
                      />
                    }
                    label={
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        gridGap="4px"
                        overflow="hidden"
                        py={1}
                      >
                        <Typography variant="body2" noWrap>
                          {role.name}
                        </Typography>
                      </Box>
                    }
                  />
                ))}
              </Box>
            </Menu>
          </>
        )}
        {!isMultiple && (
          <StyledSelectForm
            selected={props.selectedAssigneeRole}
            onChange={(e) =>
              handleChangeAssigneeRole(
                e.target.value as
                  | OnboardingExperienceTask["assigneeRole"]
                  | Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">
              )
            }
            menuItems={assigneeRoles}
            readOnly={readOnly}
          />
        )}
        {isSelectableUser &&
          ((!isMultiple && props.selectedAssigneeRole === "BY_NAME") ||
            (isMultiple && props.selectedAssigneeRoles.includes("BY_NAME"))) && (
            <>
              <Box width="240px">
                <Button
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  borderRadius="regular"
                  variant="outlined"
                  color="default"
                  fullWidth
                  disabled={readOnly}
                >
                  <Box width="100%" display="flex" alignItems="center" gridGap="4px">
                    {props.selectedEmails.length > 0 ? (
                      <Typography variant="body2" noWrap>
                        {props.selectedEmails.length}名のメンバー
                      </Typography>
                    ) : (
                      <Typography variant="body2">メンバーを選択</Typography>
                    )}
                    <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
                  </Box>
                </Button>
              </Box>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: -8, horizontal: "right" }}
              >
                <Box width="240px">
                  <SelectEmployeesFormByPlatform
                    notAllowEmails={[]}
                    selectedEmails={props.selectedEmails}
                    onSelectEmails={props.setSelectedEmails}
                    rowRendererMode="checkbox"
                  />
                </Box>
              </Menu>
            </>
          )}
      </Box>
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledSelectForm = styled(SelectForm)`
  width: 240px;
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
