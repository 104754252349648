import { SurveyAlert } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useCurrentUser } from "~/hooks/employee";
import { CompleteSurveyAlertUseCase } from "~/service/usecases/surveyAlert/";
import { captureException } from "~/util";

const useCase = new CompleteSurveyAlertUseCase();

/**
 * アンケートアラートを完了する関数を提供するhooks
 */
export const useCompleteSurveyAlert = (): {
  completeSurveyAlert: (surveyAlert: SurveyAlert) => Promise<void>;
} => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  アンケートアラートを完了する関数
   * @param {string} surveyAlertId
   */
  const completeSurveyAlert = useCallback(
    async (surveyAlert: SurveyAlert) => {
      return useCase
        .execute(currentUser, surveyAlert)
        .then(() => {
          enqueueSnackbar("アラートを対応済みに更新しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useCompleteSurveyAlert:completeSurveyAlert",
            },
          });
        });
    },
    [currentUser, enqueueSnackbar]
  );

  return {
    completeSurveyAlert,
  };
};
