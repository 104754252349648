import { Reaction } from "@onn/common";
import {
  DocumentData,
  CollectionReference,
  collection,
  where,
  query,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { chunk } from "lodash";

import { firestore } from "~/config/firebase";
import { IReactionRepository } from "~/service/repository/iReactionRepository";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type ReactionForDB = Omit<ConstructorParameters<typeof Reaction>[0], "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "reactions";
const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10;

export class ReactionRepository implements IReactionRepository {
  async whereByTransactionIds(transactionIds: string[]): Promise<Reaction[]> {
    const reactions: Reaction[] = [];

    await Promise.all(
      chunk(transactionIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map(async (chunkedTransactionIds) => {
        const { docs } = await getDocs(
          query(this.collection(), where("transactionId", "in", chunkedTransactionIds))
        );

        reactions.push(...docs.map((doc) => this.dbToObject(doc.data() as ReactionForDB)));
      })
    );

    return reactions;
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private dbToObject({ createdAt, updatedAt, ...rest }: ReactionForDB) {
    return new Reaction({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }
}
