import { Box } from "@material-ui/core";
import {
  OnboardingExperience,
  OnboardingExperienceTaskType,
  OnboardingExperienceSimpleTask,
} from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Checkbox, FormControlLabel, Modal, Typography } from "~/components/uiParts";
import { useOpenPortalOnboardingTaskPreview } from "~/hooks/openPortalPreview";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onboardingExperiences: OnboardingExperience[];
  onboardingExperienceTasks: OnboardingExperienceTaskType[];
};
export const SelectOnboardingExperiencesForPreviewModal: FC<Props> = ({
  open,
  onCancel,
  onboardingExperiences,
  onboardingExperienceTasks,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleToggleCheckBox = useCallback((onboardingExperienceId: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(onboardingExperienceId)) {
        return prev.filter((v) => v !== onboardingExperienceId);
      }
      return [...prev, onboardingExperienceId];
    });
  }, []);

  const openPortalPreview = useOpenPortalOnboardingTaskPreview();
  const handleClickOpenPreview = useCallback(() => {
    openPortalPreview(
      onboardingExperienceTasks.filter(
        (v): v is OnboardingExperienceSimpleTask =>
          selectedIds.findIndex((selectedId) => selectedId === v.onboardingExperienceId) !== -1
      ),
      "/portal?location=welcome&preview=true"
    );
    onCancel();
  }, [onCancel, onboardingExperienceTasks, openPortalPreview, selectedIds]);

  const content = (
    <Box>
      <Typography variant="body1" color="textPrimary">
        {`プレビューで確認したい受け入れ体験を選択してください。`}
      </Typography>
      <Box display="flex" flexDirection="column" mt="32px">
        {onboardingExperiences.map((onboardingExperience) => {
          return (
            <StyledFormControlLabel
              key={onboardingExperience.id}
              control={
                <Checkbox
                  checked={selectedIds.includes(onboardingExperience.id)}
                  onClick={() => handleToggleCheckBox(onboardingExperience.id)}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary" noWrap>
                  {onboardingExperience.title}
                </Typography>
              }
            />
          );
        })}
      </Box>
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleClickOpenPreview}
      >
        プレビュー
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      title="入社者ポータルプレビュー"
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
