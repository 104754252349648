import { OnboardingExperienceTaskTemplate } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "../employee";

import { FindOnboardingExperienceTaskTemplateUseCase } from "~/service/usecases/onboardingExperienceTaskTemplate";

const findOnboardingExperienceTaskTemplateUseCase =
  new FindOnboardingExperienceTaskTemplateUseCase();

/**
 * OnboardingExperienceTaskTemplate を返すswr
 * @param id OnboardingExperienceTaskTemplate のid
 */
export const useOnboardingExperienceTaskTemplate = (
  id?: string
): SWRResponse<OnboardingExperienceTaskTemplate, Error> => {
  const { currentUser } = useCurrentUser();
  const keys = id ? [`onboarding-experience-task-template-${id}`, id] : null;

  return useSWR(keys, ([_key, id]: [string, string]) =>
    findOnboardingExperienceTaskTemplateUseCase.execute(id, currentUser)
  );
};
