import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";

import { DashboardItem } from "./DashboardItem/DashboardItem";

import { Typography } from "~/components/uiParts";

export const DashboardIndex: FC = () => {
  return (
    <>
      <Box display="inline" pr={2}>
        <Typography variant="h4" bold display="inline">
          ダッシュボード
        </Typography>
      </Box>
      <Box mt="32px">
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box display="flex" flexDirection="column" gridGap="24px">
              <DashboardItem contentType="onboardingRank" />
              <DashboardItem contentType="eNPS" />
              <DashboardItem contentType="surveyAlert" />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" gridGap="24px">
              <DashboardItem contentType="taskProgress" />
              <DashboardItem contentType="surveyResponseRate" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
