import { SurveyTransaction, TransactionStatus } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { SurveyTransactionUseCase } from "~/service/usecases/transactionUseCase";

/**
 * @param tenantId tenantのid
 */
export const useCompletedSurveysOrderByAnsweredAt = (
  tenantId: string
): SWRResponse<SurveyTransaction[], Error> => {
  const keys = tenantId ? [`survey-transactions-${tenantId}`, tenantId] : null;

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    SurveyTransactionUseCase.whereByStatusAndTenantId(
      TransactionStatus.DONE,
      tenantId,
      "answeredAt"
    ).then((res) => res)
  );
};
