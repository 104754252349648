const MAX_DAYS_EACH_MONTH = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // うるう年を含める

/**
 *  何月は何日まであるのかを計算し、最大の日の値を返します
 * @returns {number} 月ごとの最終日
 */
export const getDayLengthByMonth = (month: number): number => {
  if (month < 1 || month > 12) {
    throw new Error("monthには1から12までの値を入力してください");
  }

  // 絶対存在するので、assertionを使う
  return MAX_DAYS_EACH_MONTH[month - 1] as (typeof MAX_DAYS_EACH_MONTH)[number];
};
