import { Box } from "@material-ui/core";
import React, { useMemo, FC } from "react";
import styled from "styled-components";

import { Button, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  processTitle: string;
  onSubmit: () => void;
};

export const DeleteOnboardingExperienceModal: FC<Props> = ({
  open,
  onCancel,
  processTitle,
  onSubmit,
}) => {
  const content = useMemo(
    () => (
      <>
        <Typography variant="body1" align="center">
          {`エクスペリエンスを削除しますか？\n 削除が行われるとエクスペリエンス内のコンテンツも失われます。\n この操作は取り消すことができません。`}
        </Typography>
        <Box height="24px" />
        <StyledBox px={3} py={4}>
          <Typography variant="body1">{processTitle}</Typography>
        </StyledBox>
      </>
    ),
    [processTitle]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="secondary"
          onClick={() => {
            onSubmit();
            onCancel();
          }}
        >
          削除
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel, onSubmit]
  );

  return (
    <Modal
      title="エクスペリエンスの削除"
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
