export const WEEKDAY_FOR_SURVEY = [
  { value: 0, name: "日曜日" },
  { value: 1, name: "月曜日" },
  { value: 2, name: "火曜日" },
  { value: 3, name: "水曜日" },
  { value: 4, name: "木曜日" },
  { value: 5, name: "金曜日(デフォルト)" },
  { value: 6, name: "土曜日" },
] as const;
type WEEKDAY_FOR_SURVEY = (typeof WEEKDAY_FOR_SURVEY)[keyof typeof WEEKDAY_FOR_SURVEY];

export class WeekdayForSurvey {
  weekday: WEEKDAY_FOR_SURVEY;
  constructor(weekday: WEEKDAY_FOR_SURVEY) {
    this.weekday = weekday;
  }

  static getBusinessDays(): { value: number; name: string }[] {
    return WEEKDAY_FOR_SURVEY.filter(({ value }) => [1, 2, 3, 4, 5].includes(value));
  }
}

// 8~19時を生成する
export const HOUR_FOR_SURVEY = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((hour) => {
  return {
    value: hour,
    name: `${hour}時~${hour === 9 ? "(デフォルト)" : ""}`,
  };
});
type HOUR_FOR_SURVEY = (typeof HOUR_FOR_SURVEY)[keyof typeof HOUR_FOR_SURVEY];

export class HourForSurvey {
  hour: HOUR_FOR_SURVEY;
  constructor(hour: HOUR_FOR_SURVEY) {
    this.hour = hour;
  }
}
