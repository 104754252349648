import { Box, FormControl, FormGroup, useMediaQuery, Slide } from "@material-ui/core";
import { QuestionItem as QuestionItemType } from "@onn/common";
import React, { useState, FC } from "react";
import styled from "styled-components";

import { Checkbox } from "~/components/uiParts/Checkbox";
import { FormControlLabel } from "~/components/uiParts/FormControlLabel";
import { TextareaAutosize } from "~/components/uiParts/TextareaAutosize";
import { Typography } from "~/components/uiParts/Typography";
import theme from "~/config/theme";

const CHECKBOX_LIST_BREAK_POINT_DEFAULT = 6; // PCのとき、改行を入れるまでの項目数
const CHECKBOX_LIST_BREAK_POINT_LARGE = 10; // PCのとき、改行までのアイテム数がデフォルトの2倍以上になる

type Props = {
  questionItems: QuestionItemType[];
  name?: string;
  values?: string[];
  valueOfOther?: string;
  disableEffect?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLElement>) => void;
};

export const CheckboxGroup: FC<Props> = ({
  questionItems,
  name,
  values,
  valueOfOther,
  disableEffect,
  handleChange,
}) => {
  const isPc = useMediaQuery(theme.breakpoints.up("sm"));
  const [freeTextEvent, setFreeTextEvent] = useState<React.ChangeEvent<HTMLTextAreaElement>>();

  const itemsInColumn =
    questionItems.length > CHECKBOX_LIST_BREAK_POINT_DEFAULT * 2
      ? CHECKBOX_LIST_BREAK_POINT_LARGE
      : CHECKBOX_LIST_BREAK_POINT_DEFAULT;

  const isText = (questionItem: QuestionItemType) => {
    return questionItem.title.includes("自由回答") || questionItem.title.includes("free answer");
  };

  const handleChangeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionItem: QuestionItemType
  ) => {
    handleChange && handleChange(event);

    if (isText(questionItem) && freeTextEvent) {
      handleChangeFreeText(freeTextEvent, event.target.checked);
    }
  };

  const handleChangeFreeText = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    freeTextChecked: boolean
  ) => {
    // 自由記入欄のチェックが入っていない場合はテキストを送信しない
    if (freeTextChecked && event) {
      event.persist();
      setFreeTextEvent(event);
      handleChange && handleChange(event);
    }
  };

  return (
    questionItems && (
      <FormControl component="fieldset" fullWidth>
        <StyledFormGroup column={isPc} $itemsInColumn={itemsInColumn}>
          {questionItems.map((questionItem, i) => {
            const checked = !!values?.find((v) => v === questionItem.name);

            return (
              <StyledResponsiveBox key={`Checkbox-${name}__${i}`}>
                <StyleFormControlLabel
                  $disableEffect={Boolean(disableEffect)}
                  control={
                    <Checkbox
                      disableEffect={disableEffect}
                      checked={checked}
                      onChange={(e) => handleChangeCheckBox(e, questionItem)}
                      name={name}
                      value={questionItem.name}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {questionItem.title}
                    </Typography>
                  }
                />
                <StyledTransitionWrapper>
                  {isText(questionItem) && (
                    <Slide in={checked} mountOnEnter unmountOnExit>
                      <Box>
                        <TextareaAutosize
                          maxRows={5} // TODO: 10くらいまで許容すべきだが現状のcssだと右にずれてしまう
                          disabled={disableEffect}
                          value={freeTextEvent ? freeTextEvent.target.value : valueOfOther}
                          minRows={4}
                          fullWidth
                          name={name}
                          onChange={(e) => handleChangeFreeText(e, checked)}
                          placeholder={
                            questionItem.placeholder
                              ? questionItem.placeholder.replace(`\\n`, `\n`)
                              : "回答を入力"
                          }
                        />
                      </Box>
                    </Slide>
                  )}
                </StyledTransitionWrapper>
              </StyledResponsiveBox>
            );
          })}
        </StyledFormGroup>
      </FormControl>
    )
  );
};

const StyledFormGroup = styled(FormGroup)<{ column: boolean; $itemsInColumn: number }>`
  /* 改行を入れるために、各チェックボックスの高さで */
  ${(props) => props.column && `height: ${44 * props.$itemsInColumn}px;`}
`;

const StyleFormControlLabel = styled(FormControlLabel)<{ $disableEffect: boolean }>`
  ${(props) => props.$disableEffect && `cursor: default;`}
`;

const StyledResponsiveBox = styled(Box)`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 50%;
  }
`;

const StyledTransitionWrapper = styled.div`
  overflow: hidden;
`;
