import { Employee, Department } from "@onn/common";
import React, { FC } from "react";

import { UserSummaryTooltip } from "../../employees";

import { UserIcon as CommonUserIcon } from "~/components/uiParts";
import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

type Props = {
  name: string;
  imageUrl?: string;
  newHire?: Employee;
  departments: Department[];
  shouldDisplayContactRoomButton: boolean;
  isNewComerMessage: boolean;
};

export const UserIcon: FC<Props> = React.memo(
  ({ name, newHire, imageUrl, departments, shouldDisplayContactRoomButton, isNewComerMessage }) => {
    const shouldSPView = useShouldSPView();

    if (shouldSPView && isNewComerMessage) {
      return (
        <CommonUserIcon
          username={name}
          profileIconImageUrl={imageUrl}
          size="small"
          circular
          hover={false}
          onClick={() => (newHire ? window.open(`/employee/${newHire.id}`, "_blank") : void 0)}
        />
      );
    }

    return isNewComerMessage && newHire ? (
      <UserSummaryTooltip
        employee={newHire}
        departments={departments}
        shouldDisplayContactRoomButton={shouldDisplayContactRoomButton}
      >
        <CommonUserIcon
          username={name}
          profileIconImageUrl={imageUrl}
          size="small"
          circular
          hover={false}
        />
      </UserSummaryTooltip>
    ) : (
      <CommonUserIcon
        username={name}
        profileIconImageUrl={imageUrl}
        size="small"
        circular
        hover={false}
      />
    );
  }
);
