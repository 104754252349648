import { Box, Table, TableCell, TableBody, TableHead, TableRow } from "@material-ui/core";
import { SurveyTransaction, Month, Employee } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { Button, Typography, UserIcon } from "~/components/uiParts";
import { useSortObjectForENPS } from "~/hooks/transaction";

type Props = {
  period: { year: number; month: number };
  nthOfMonth: Month;
  employees: Employee[];
  surveyTransactions: SurveyTransaction[];
};

export const ENPSList: FC<Props> = ({ period, nthOfMonth, employees, surveyTransactions }) => {
  const { sortObjectForENPS } = useSortObjectForENPS();

  const surveyTransactionObjects: Array<{
    employee: Employee;
    surveyTransaction: SurveyTransaction;
  }> = useMemo(
    () =>
      sortObjectForENPS(
        employees.flatMap((employee) =>
          surveyTransactions
            .filter((surveyTransaction) => surveyTransaction.employeeId === employee.id)
            .map((surveyTransaction) => ({
              employee,
              surveyTransaction,
            }))
        )
      ),
    [employees, sortObjectForENPS, surveyTransactions]
  );

  const Row = (props: {
    employee: Employee;
    surveyTransaction: SurveyTransaction;
  }): JSX.Element => {
    const joinDate = props.employee.joinAt
      ? `${parseInt(
          props.employee.joinAt.split("-")[1] as (typeof props.employee.joinAt)[number]
        )}/${parseInt(
          props.employee.joinAt.split("-")[2] as (typeof props.employee.joinAt)[number]
        )}`
      : "";
    const handleClickBackButton = useCallback(
      () =>
        window.open(
          `/employee/${props.employee.id}?answerId=${props.surveyTransaction.contents.id}`,
          "_blank",
          "noopener noreferrer"
        ),
      [props]
    );
    const userName = props.employee.getName();

    return (
      <TableRow>
        <StyledTableCell component="th" scope="row">
          <Box display="flex" gridGap="8px" alignItems="center">
            <UserIcon
              username={userName}
              profileIconImageUrl={props.employee.profileIconImageUrl}
              size="extraSmall"
              borderColor="primary"
            />
            <Typography variant="body1" noWrap>
              {userName}
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body1" align="center">
            {joinDate}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body1" align="center">
            {props.surveyTransaction.eNPSScore() ?? "未回答"}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          {props.surveyTransaction.eNPSScore() !== undefined && (
            <Button
              onClick={handleClickBackButton}
              borderRadius="regular"
              variant="outlined"
              color="default"
            >
              詳細
            </Button>
          )}
        </StyledTableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box pb={3}>
        <Typography variant="body1" align="center" bold>
          {`${period.year}/${period.month}入社者 ${nthOfMonth}カ月時点`}
        </Typography>
      </Box>

      <Box pb={1}>
        <Typography variant="body1">■対象者</Typography>
      </Box>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell width={"52%"}>
              <Typography variant="body1">氏名</Typography>
            </StyledTableCell>
            <StyledTableCell width={"16%"} align="center">
              <Typography variant="body1">入社日</Typography>
            </StyledTableCell>
            <StyledTableCell width={"16%"} align="center">
              <Typography variant="body1">スコア</Typography>
            </StyledTableCell>
            <StyledTableCell width={"16%"} align="center"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyTransactionObjects.map((obj, index) => (
            <Row employee={obj.employee} surveyTransaction={obj.surveyTransaction} key={index} />
          ))}
        </TableBody>
      </StyledTable>
    </>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    min-width: 100%;
    table-layout: fixed;
  }
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 10px 3px;
    border: none;
    word-wrap: break-word;
    word-break: break-all;
  }
`;
