import { OnboardingSimpleTask } from "@onn/common";
import { useCallback } from "react";

/**
 * 役割によって枠線の色を返す関数を提供するhooks
 */
export const useGetUserIconBorderColorByAssigneeRole = () => {
  /**
   * 役割によって枠線の色を返すhooks
   * @param {AssigneeRole} assigneeRole
   */
  const getBorderColorByAssigneeRole = useCallback(
    (assigneeRole: OnboardingSimpleTask["assigneeRole"]) => {
      switch (assigneeRole) {
        case "ADMIN":
          return "secondary";
        case "MENTOR":
          return "primary";
        case "SUPPORT_MEMBER":
          return "blue";
        case "BY_NAME":
          return "grey";
        default:
          return undefined; // NEW_HIRE はボーダーカラーなし
      }
    },
    []
  );
  return { getBorderColorByAssigneeRole };
};
