import "./i18n/config";

import { initializeZodSetting } from "@onn/common";
import React, { FC, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthenticationGuard } from "./components/providers/AuthenticationGuard";
import { AuthorizationGuard } from "./components/providers/AuthorizationGuard";
import { I18nProvider } from "./components/providers/I18nProvider";
import { SWRConfigWrapper } from "./components/providers/SWRConfigWrapper";
import { AuthorizationRouterWrapper } from "./pages/AuthorizationRouterWrapper";
import { GuestRouterWrapper } from "./pages/GuestRouterWrapper";
import { RouterWrapper } from "./pages/RouterWrapper";
import { initializeLogger } from "./util";

import { initializeCustomConsole } from "./util/initializeCustomConsole";

import {
  AccessControlProvider,
  CurrentUserProvider,
  EmployeesProvider,
  FileViewerProvider,
  GiveLoggerTagsProvider,
  MaintenanceProvider,
  ModalProvider,
  SnackbarProvider,
  SlackUsersProvider,
  SwitchViewportProvider,
  ThemeProvider,
  TenantProvider,
  TenantSettingsProvider,
  RevisionProvider,
  ContactProviderV2,
  AuthenticationProvider,
  AuthorizationProvider,
  CurrentTenantIdProvider,
} from "~/components/providers";

import { ErrorBoundary, ScrollToTop, GoogleAnalytics } from "~/pages/utils";

try {
  initializeCustomConsole();
} catch {
  // eslint-disable-next-line no-console
  console.error("Failed to initialize custom console");
}

initializeLogger();

// zodのエラーメッセージを指定したものに上書き
initializeZodSetting();

export const App: FC = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  // null を返す場合、マウント先ではスピナーを表示する
  return loading ? null : (
    <ThemeProvider>
      <ErrorBoundary>
        <SnackbarProvider>
          <MaintenanceProvider>
            <SWRConfigWrapper>
              <Router>
                <GoogleAnalytics />
                <ScrollToTop />
                <SwitchViewportProvider>
                  <FileViewerProvider>
                    <RevisionProvider>
                      <CurrentTenantIdProvider>
                        <AuthenticationProvider>
                          <AuthorizationProvider>
                            <GuestRouterWrapper>
                              <AuthenticationGuard>
                                <AuthorizationGuard>
                                  <AuthorizationRouterWrapper>
                                    <CurrentUserProvider>
                                      <I18nProvider>
                                        <AccessControlProvider>
                                          <GiveLoggerTagsProvider>
                                            <TenantProvider>
                                              <SlackUsersProvider>
                                                <TenantSettingsProvider>
                                                  <EmployeesProvider>
                                                    <ContactProviderV2>
                                                      <ModalProvider>
                                                        <RouterWrapper />
                                                      </ModalProvider>
                                                    </ContactProviderV2>
                                                  </EmployeesProvider>
                                                </TenantSettingsProvider>
                                              </SlackUsersProvider>
                                            </TenantProvider>
                                          </GiveLoggerTagsProvider>
                                        </AccessControlProvider>
                                      </I18nProvider>
                                    </CurrentUserProvider>
                                  </AuthorizationRouterWrapper>
                                </AuthorizationGuard>
                              </AuthenticationGuard>
                            </GuestRouterWrapper>
                          </AuthorizationProvider>
                        </AuthenticationProvider>
                      </CurrentTenantIdProvider>
                    </RevisionProvider>
                  </FileViewerProvider>
                </SwitchViewportProvider>
              </Router>
            </SWRConfigWrapper>
          </MaintenanceProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);
root.render(<App />);
