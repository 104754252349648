import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Paper, UserIcon, Icon, Typography, Linkify, Tooltip } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  newHireName: string;
  newHireProfileIconImageUrl?: string;
  senderName: string;
  senderDepartmentName?: string;
  senderProfileIconImageUrl?: string;
  messageText: string;
  isActive: boolean;
};

export const ActivityMessagePaper: FC<Props> = ({
  newHireName,
  newHireProfileIconImageUrl,
  senderName,
  senderDepartmentName,
  senderProfileIconImageUrl,
  messageText,
  isActive,
}) => {
  return (
    <StyledPaper isShowBgImage $isActive={isActive}>
      <Box mb={2.5} display="flex" alignItems="center" gridColumnGap="8px">
        <UserIcon
          username={senderName}
          profileIconImageUrl={senderProfileIconImageUrl}
          size="small"
        />
        <Tooltip title={senderDepartmentName || ""}>
          <Typography variant="body2" bold>
            {senderName}
          </Typography>
        </Tooltip>
        <Icon size="sm" icon="arrowRight" color="primary" />
        <UserIcon
          username={newHireName}
          profileIconImageUrl={newHireProfileIconImageUrl}
          size="small"
        />
      </Box>
      <Linkify>
        <StyledTypography variant="body1">{messageText}</StyledTypography>
      </Linkify>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;

const StyledTypography = styled(Typography)`
  word-break: break-all;
`;
