import { OnboardingExperienceTaskType } from "@onn/common";
import { isSameDay } from "date-fns";
import { isEqual } from "lodash";
import { useMemo } from "react";

export type ExperienceTaskGroup = {
  id: string;
  referenceDate: Date;
  assigneeRole: OnboardingExperienceTaskType["assigneeRole"];
  assigneeIds: string[];
  tasks: OnboardingExperienceTaskType[];
};

/**
 * onboardingExperienceTasks を experienceTaskGroupに変換して返すhooks
 * @param onboardingExperienceTasks onboardingExperienceTasks
 */
export const useExperienceTaskGroups = (
  onboardingExperienceTasks: OnboardingExperienceTaskType[]
) => {
  const result = useMemo(() => {
    const now = new Date();

    let experienceTaskGroups: ExperienceTaskGroup[] = [];

    const insertToGroup = (
      task: OnboardingExperienceTaskType,
      overrideAssigneeRole?: OnboardingExperienceTaskType["assigneeRole"]
    ) => {
      const referenceDate = task.deliveryDate.calculateDate(now);
      const assigneeRole = overrideAssigneeRole || task.assigneeRole;

      const existedTaskGroup = experienceTaskGroups.find((v) => {
        return (
          isSameDay(v.referenceDate, referenceDate) &&
          isEqual(v.assigneeIds, task.assigneeIds) &&
          v.assigneeRole === assigneeRole
        );
      });

      if (existedTaskGroup) {
        experienceTaskGroups = [
          ...experienceTaskGroups.filter((v) => v.id !== existedTaskGroup.id),
          { ...existedTaskGroup, tasks: [...existedTaskGroup.tasks, task] },
        ];
      } else {
        experienceTaskGroups = [
          ...experienceTaskGroups,
          {
            id: JSON.stringify({
              referenceDate,
              assigneeRole: assigneeRole,
              assigneeIds: task.assigneeIds,
            }),
            referenceDate,
            assigneeRole: assigneeRole,
            assigneeIds: task.assigneeIds,
            tasks: [task],
          },
        ];
      }
    };

    onboardingExperienceTasks.forEach((task) => insertToGroup(task));

    return experienceTaskGroups;
  }, [onboardingExperienceTasks]);

  return { experienceTaskGroups: result };
};
