import { Box } from "@material-ui/core";
import { SurveyTransaction, Employee } from "@onn/common";
import { format } from "date-fns";
import React, { FC, useMemo } from "react";

import { SurveyResult } from "../../SurveyResult";

import { Divider, TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser, useEmployee } from "~/hooks/employee";
import { useSurveyTransactionsByEmployeeId } from "~/hooks/transaction";

type Props = {
  surveyTransaction: SurveyTransaction;
  newHire: Employee;
};

export const SurveyDetail: FC<Props> = ({ surveyTransaction, newHire }) => {
  const { currentUser } = useCurrentUser();
  const { data: mentor } = useEmployee(newHire.mentorUserId);
  const { data: surveyTransactions, isValidating: isLoadingSurveyTransactions } =
    useSurveyTransactionsByEmployeeId(newHire.id);
  const subTitle = useMemo(
    () => `${newHire.getName()} ${surveyTransaction.contents.title}`,
    [newHire, surveyTransaction]
  );

  return (
    <>
      <Box pb={3}>
        <TooltipWhenTextTruncated text={subTitle}>
          {(ref) => (
            <Box width="100%" display="flex" alignItems="center" gridGap="12px">
              <UserIcon
                username={newHire.getName()}
                profileIconImageUrl={newHire.profileIconImageUrl}
                size="small"
              />
              <Typography variant="body1" color="textPrimary" bold noWrap ref={ref}>
                {subTitle}
              </Typography>
            </Box>
          )}
        </TooltipWhenTextTruncated>
      </Box>
      <Box display="flex" justifyContent="space-between" gridGap="12px">
        <Typography variant="body1">バディ: {mentor?.getName() || "未設定"}</Typography>
        <Typography variant="body1">
          回答日:{" "}
          {surveyTransaction.answeredAt
            ? format(surveyTransaction.answeredAt, "yyyy/MM/dd HH:mm")
            : ""}
        </Typography>
      </Box>
      <Divider margin={32} orientation="horizontal" />
      {!isLoadingSurveyTransactions && surveyTransactions && (
        <SurveyResult
          surveyTransaction={surveyTransaction}
          currentUser={currentUser}
          newHire={newHire}
          surveyTransactions={surveyTransactions}
        />
      )}
    </>
  );
};
