import { isValidUrl, MessageContent } from "@onn/common";
import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MessageContentEditor } from "./MessageContentEditor";

import { Loading } from "~/components/uiParts";
import { useAdmins, useCurrentUserNonGuarded, useEmployees } from "~/hooks/employee";
import { useEmployee } from "~/hooks/employee/useEmployee";
import { useOnboardingTask } from "~/hooks/onboardingTask";
import { useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { NotFound } from "~/pages/NotFound";

type LocationState = {
  inputIconDataUrl?: string;
  newMessageContent: MessageContent;
  uploadedIconFileName?: string;
};

const fileApiAdapter = new FileAPIAdapter({ bucketType: "public" });

export const MessageEdit: FC = () => {
  const navigate = useNavigate();

  const { id: taskId, contentId } = useParams<"id" | "contentId">();
  const { data: onboardingTaskData, isValidating: isValidatingOnboardingTaskData } =
    useOnboardingTask(taskId);
  const { currentUser } = useCurrentUserNonGuarded();

  const locationState = useLocation().state as LocationState;
  const { enqueueSnackbar } = useSnackbar();
  const isGuest = contentId === "guest";
  // confirm 画面から戻ってきた時に newMessageContent が set される
  const newMessageContentContentFromConfirmPage = locationState?.newMessageContent;
  const inputIconDataUrlFromConfirmPage = locationState?.inputIconDataUrl;
  const iconFileNameUrlFromConfirmPage = locationState?.uploadedIconFileName;

  const [inputIconDataUrl, setInputIconDataUrl] = useState(inputIconDataUrlFromConfirmPage ?? "");
  const [newMessageContent, setNewMessageContent] = useState<MessageContent>(
    newMessageContentContentFromConfirmPage ??
      MessageContent.create({
        name: "",
        iconPath: "",
        message: "",
        department: "",
        email: "",
        isCompleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
      })
  );
  const [iconFileName, setIconFileName] = useState<string>(iconFileNameUrlFromConfirmPage ?? "");

  const messageContent = useMemo(() => {
    const task = onboardingTaskData?.onboardingTask;
    if (isGuest || task?.type !== "MESSAGE_TASK") return null;
    return task.contents.find((content) => content.id === contentId);
  }, [onboardingTaskData?.onboardingTask, isGuest, contentId]);

  const { data: mentor } = useEmployee(
    currentUser ? onboardingTaskData?.employee?.mentorUserId : undefined
  );
  const { data: supportMembers = [] } = useEmployees(
    currentUser ? onboardingTaskData?.employee?.supportMemberEmployeeIds : undefined
  );
  const { data: admins = [] } = useAdmins(currentUser ? currentUser.tenantId : undefined);

  // iconPathがfullPathだった場合は何もしない
  const getFileUrl = useCallback((iconPath: string) => {
    if (isValidUrl(iconPath)) return;
    setInputIconDataUrl(fileApiAdapter.getFileUrl(iconPath));
  }, []);

  useEffect(() => {
    if (newMessageContent.iconPath) {
      getFileUrl(newMessageContent.iconPath);
    }
  }, [getFileUrl, newMessageContent.iconPath]);

  useEffect(() => {
    // 下書き状態のものがある場合は更新しない
    if (newMessageContentContentFromConfirmPage) {
      return;
    }

    if (messageContent) {
      setNewMessageContent(messageContent);
    }
  }, [newMessageContentContentFromConfirmPage, messageContent]);

  const handleChangeMessage = (newObject: Partial<MessageContent>) => {
    setNewMessageContent((prev) => MessageContent.create({ ...prev, ...newObject }));
  };

  const handleUploadImage = useCallback(async (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      // readAsDataURLはstringしか返さないが、resultの型にArrayBufferが含まれるので型チェックする
      if (typeof reader.result === "string") {
        setInputIconDataUrl(reader.result);
        setIconFileName(file.name);
      }
    };
    reader.readAsDataURL(file);
  }, []);

  const handleErrorUploadImage = () => {
    enqueueSnackbar("アイコンは120px*120pxから2100px*2100pxの間で設定してください", {
      variant: "error",
    });
  };

  const moveToConfirm = () => {
    navigate(`/messages/${taskId}/${contentId}/confirm`, {
      state: {
        newMessageContent,
        inputIconDataUrl,
        uploadedIconFileName: iconFileName,
      },
    });
  };

  // WelcomeMessageを新規作成する際に実行されるuseEffect
  // message部分が未記入であることを条件に、新規作成と判定している。
  useEffect(() => {
    if (messageContent?.message || onboardingTaskData?.onboardingTask?.type !== "MESSAGE_TASK")
      return;

    if (messageContent?.isCompleted) {
      navigate(`/messages/${taskId}/${messageContent.id}/complete`);
    }
  }, [navigate, messageContent, taskId, onboardingTaskData?.onboardingTask?.type]);

  // WelcomeMessageを編集する際に実行されるuseEffect
  // message部分が記入済みであることを条件に、既存コンテンツの編集と判定している。
  useEffect(() => {
    if (
      !onboardingTaskData ||
      !onboardingTaskData.employee ||
      !messageContent ||
      !messageContent.message
    ) {
      return;
    }

    if (onboardingTaskData.onboardingTask.isExpiredByEmployee(onboardingTaskData.employee)) {
      navigate(`/messages/${taskId}/${messageContent.id}/complete`);
    }
  }, [navigate, messageContent, taskId, onboardingTaskData]);

  if (isValidatingOnboardingTaskData) {
    return <Loading size="large" fullHeight />;
  }

  if (!taskId || !onboardingTaskData || !onboardingTaskData.employee) {
    return <NotFound />;
  }

  if (!isGuest && !messageContent) {
    return <NotFound />;
  }
  if (onboardingTaskData.onboardingTask?.type !== "MESSAGE_TASK") {
    return <NotFound />;
  }

  if (!onboardingTaskData.onboardingTask.isEditableMessageContent(onboardingTaskData.employee)) {
    return <NotFound />;
  }

  return (
    <MessageContentEditor
      newHire={onboardingTaskData.employee}
      mentor={mentor}
      onboardingTask={onboardingTaskData.onboardingTask}
      supportMembers={supportMembers}
      admins={admins}
      newMessageContent={newMessageContent}
      inputIconDataUrl={inputIconDataUrl}
      onChangeMessageContent={handleChangeMessage}
      onUploadImage={handleUploadImage}
      onErrorUploadImage={handleErrorUploadImage}
      onClickedConfirmButton={moveToConfirm}
    />
  );
};
