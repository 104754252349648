import { Box } from "@material-ui/core";
import { OnboardingExperience, Department, isValidEmail, Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { useCheckInputValues } from "../InviteMidCarrierWithCSVModal/useCheckInputValues";

import { useGetErrorMessage } from "../InviteMidCarrierWithCSVModal/useGetErrorMessage";

import { ConfirmStep } from "./ConfirmStep";
import { Footer } from "./Footer";
import { SelectExperienceStep } from "./SelectExperienceStep";
import { SelectMidCarrierStep } from "./SelectMidCarrierStep";

import { VerticalStepper, Button, Divider, Typography, Modal } from "~/components/uiParts";
import { useDebouncedCallback, useSnackbar } from "~/hooks/shared";
import { captureException, mixin } from "~/util";

type UserDataType = {
  email: string;
  emailErrorMessage: string;
  isValidEmail: boolean;
  departmentIds: string[];
  employeeTagIds: string[];
};

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (
    userDataArray: UserDataType[],
    selectedOnboardingExperienceIds: string[]
  ) => Promise<void>;
  currentUser: Employee;
  onboardingExperiences: OnboardingExperience[];
  departments: Department[];
};

const SELECT_NEW_HIRE_STEP = 1;
const SELECT_EXPERIENCE_STEP = 2;
const CONFIRM_STEP = 3;

export const InviteMidCarrierModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  currentUser,
  onboardingExperiences,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { checkInputValues } = useCheckInputValues();
  const { getErrorMessage } = useGetErrorMessage();

  const [userDataArray, setUserDataArray] = useState<UserDataType[]>([
    {
      email: "",
      emailErrorMessage: "",
      isValidEmail: false,
      departmentIds: [],
      employeeTagIds: [],
    },
  ]);

  const [isValidatingInputEmails, setIsValidatingInputEmails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(SELECT_NEW_HIRE_STEP);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSwitchCheckBox = useCallback((onboardingExperienceId: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(onboardingExperienceId)) {
        return prev.filter((v) => v !== onboardingExperienceId);
      }
      return [...prev, onboardingExperienceId];
    });
  }, []);

  const handleClickInvite = useCallback(async () => {
    setLoading(true);
    await onSubmit(userDataArray, selectedIds)
      .then(() => {
        onCancel();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "InviteMidCarrierModal:handleClickInvite" },
        });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, onCancel, onSubmit, selectedIds, userDataArray]);

  const updateUserDataArray = useCallback((newObject: Partial<UserDataType>, index: number) => {
    setUserDataArray((prevUserDataArray) => {
      return prevUserDataArray.map((prevUserData, prevIndex) => {
        if (prevIndex === index) {
          return { ...prevUserData, ...newObject };
        }
        return prevUserData;
      });
    });
  }, []);

  const handleValidEmailChange = useDebouncedCallback((callback) => callback(), 2000);
  const checkEmail = useCallback(
    (email: string, index: number) => {
      updateUserDataArray({ email, isValidEmail: false, emailErrorMessage: "" }, index);

      if (email === "") return;

      const isValid = isValidEmail(email);
      if (!isValid) {
        updateUserDataArray({ emailErrorMessage: "メールアドレスの形式が間違っています" }, index);
        return;
      }
      setIsValidatingInputEmails(true);

      handleValidEmailChange(async () => {
        const errorStatus = await checkInputValues(
          userDataArray.map((data, i) => {
            return {
              email: i === index ? email : data.email,
              departmentNames: [],
              tagNames: [],
              onboardingExperienceTitles: [],
              onnEventTitles: [],
            };
          })
        );
        if (errorStatus.size) {
          const errorMessage = getErrorMessage(errorStatus, {
            isCsv: false,
            inputRowSize: userDataArray.length,
            rowIndex: index,
          });
          updateUserDataArray({ emailErrorMessage: errorMessage }, index);
        } else {
          updateUserDataArray({ isValidEmail: true, emailErrorMessage: "" }, index);
        }
        setIsValidatingInputEmails(false);
      });
    },
    [updateUserDataArray, handleValidEmailChange, checkInputValues, userDataArray, getErrorMessage]
  );

  const handleAddEmails = useCallback(() => {
    setUserDataArray((prev) => [
      ...prev,
      {
        email: "",
        emailErrorMessage: "",
        isValidEmail: false,
        departmentIds: [],
        employeeTagIds: [],
      },
    ]);
  }, []);

  const handleDeleteEmails = useCallback((index: number): void => {
    setUserDataArray((prev) => prev.flatMap((v, prevIndex) => (prevIndex === index ? [] : v)));
  }, []);

  // 1個以上の正しいフォーマットの値(+ 0個以上のブランクの値)のとき真
  const checkUserDataArrayValid = useCallback(
    (userDataArray: UserDataType[]): boolean => {
      const filledUserDataArray = userDataArray.filter((userData) => userData.email !== "");
      const isUserDataArrayHasDepartments = userDataArray.every(
        (user) => !isEmpty(user.departmentIds)
      );

      if (currentUser.isAdmin()) {
        return (
          !isEmpty(filledUserDataArray) &&
          filledUserDataArray.every((filledUserData) => filledUserData.isValidEmail)
        );
      } else if (currentUser.isDepartmentAdmin()) {
        return (
          !isEmpty(filledUserDataArray) &&
          filledUserDataArray.every((filledUserData) => filledUserData.isValidEmail) &&
          // 部門管理者の場合、入社者招待の時はすべての入社者を部署に所属させなければならない(所属なしを招待できない)
          isUserDataArrayHasDepartments
        );
      } else {
        return false;
      }
    },
    [currentUser]
  );

  const newHireLabel = "入社者";
  const modalTitle = "入社者招待";

  const Content = (
    <Box display="flex" pt={2}>
      {!isEmpty(onboardingExperiences) && (
        <>
          <Box pl="30px">
            <VerticalStepper
              activeStep={step}
              labels={[`${newHireLabel}追加`, "体験選択", `内容確認／送信`]}
            />
          </Box>
          <Divider orientation="vertical" margin={20} />
        </>
      )}
      <Box flexGrow={1} overflow="hidden">
        {step === SELECT_NEW_HIRE_STEP && (
          <SelectMidCarrierStep
            userDataArray={userDataArray}
            departments={props.departments}
            onChangeInputEmail={(email: string, index: number) => {
              checkEmail(email, index);
            }}
            onChangeDepartments={(departmentIds: string[], index: number) => {
              updateUserDataArray({ departmentIds }, index);
            }}
            onDeleteEmails={handleDeleteEmails}
            onAddEmails={handleAddEmails}
          />
        )}
        {step === SELECT_EXPERIENCE_STEP && (
          <SelectExperienceStep
            onboardingExperiences={onboardingExperiences}
            selectedIds={selectedIds}
            onSwitchCheckBox={handleSwitchCheckBox}
          />
        )}
        {step === CONFIRM_STEP && (
          <ConfirmStep
            onboardingExperiences={onboardingExperiences}
            inviteEmails={userDataArray.map((userData) => userData.email)}
            selectedIds={selectedIds}
          />
        )}
        {!isEmpty(onboardingExperiences) ? (
          <StyledButtonContainer mt="40px">
            {step !== SELECT_NEW_HIRE_STEP && (
              <Button
                fullWidth
                borderRadius="circle"
                variant="outlined"
                color="default"
                onClick={() => setStep((prev) => prev - 1)}
              >
                戻る
              </Button>
            )}
            <Button
              fullWidth
              borderRadius="circle"
              variant="contained"
              color="primary"
              onClick={() => {
                if (step === CONFIRM_STEP) handleClickInvite();
                else {
                  setUserDataArray((prev) => prev.filter((v) => v.email.trim() !== ""));
                  setStep((prev) => prev + 1);
                }
              }}
              disabled={!checkUserDataArrayValid(userDataArray)}
              isLoading={loading || isValidatingInputEmails}
            >
              {step === CONFIRM_STEP ? "送信" : "次へ"}
            </Button>
          </StyledButtonContainer>
        ) : (
          <Box pt={2} textAlign="center">
            <Typography variant="caption" color="textSecondary">
              入力されたメールアドレス宛に招待メールが送られます。
              <br />
              招待がメールで送られる旨を{newHireLabel}に伝達してから送信しましょう。
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      title={modalTitle}
      content={Content}
      footer={
        isEmpty(onboardingExperiences) ? (
          <Footer
            onClickInviteButton={handleClickInvite}
            disabled={!checkUserDataArrayValid(userDataArray) || loading}
          />
        ) : undefined
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
