import { useEffect } from "react";

import { useContactRoomListV2 } from "./useContactRoomListV2";

import { useQuery, useSetQueryString } from "~/hooks/shared";

export const useViewModelV2 = () => {
  const contactRoomListOperationObj = useContactRoomListV2();

  const { query } = useQuery();
  const { setQueryString } = useSetQueryString();

  useEffect(() => {
    if (contactRoomListOperationObj.currentContactRooms) {
      const contactRoomId = query.get("contactRoomId");
      if (contactRoomId) contactRoomListOperationObj.setSelectedContactRoomId(contactRoomId);
    }
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoomListOperationObj.currentContactRooms]);

  return {
    ...contactRoomListOperationObj,
    setQueryString,
  };
};
