import { Box } from "@material-ui/core";
import { Employee, OnboardingTaskMemo } from "@onn/common";
import { format } from "date-fns";
import React, { FC } from "react";
import styled from "styled-components";

import { Linkify, Paper, Typography, UserIcon } from "~/components/uiParts";

const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";

type Props = {
  memo: OnboardingTaskMemo;
  author: Employee;
};

export const OnboardingTaskMemoItem: FC<Props> = ({ memo, author }) => {
  return (
    <StyledListItem>
      <Box width={40} mr={1}>
        <UserIcon
          circular
          size="small"
          username={author.getName()}
          profileIconImageUrl={author.deleted ? undefined : author.profileIconImageUrl}
        />
      </Box>

      <Box flex="1">
        <Box display="flex" mb="4px" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" bold noWrap>
            {author.getName()}
          </Typography>

          <Typography variant="caption" color="textSecondary" noWrap>
            {format(memo.createdAt, TIMESTAMP_FORMAT)}
          </Typography>
        </Box>

        <Linkify>
          <StyledPaper square>
            <Box p="24">{memo.text}</Box>
          </StyledPaper>
        </Linkify>
      </Box>
    </StyledListItem>
  );
};

const StyledListItem = styled.li`
  list-style: none;
  display: flex;
`;

const StyledPaper = styled(Paper)`
  word-break: break-all;
  font-size: 14px;

  &.MuiPaper-root {
    padding: 16px;
  }
`;
