import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Profile } from "~/components/domains/employees";
import { Divider, Paper, Typography } from "~/components/uiParts";

type Props = {
  employee: Employee;
};
export const ProfileTab: FC<Props> = ({ employee }) => {
  return (
    <Box pt={6} pb={6}>
      {!employee.isNewGraduate && (
        <>
          <StyledPaper>
            <Typography variant="body1" gutterBottom>
              自己紹介
            </Typography>
            <Divider />
            <StyledSpacer height="24px" />
            {employee.profile && <Profile profile={employee.profile} />}
          </StyledPaper>
          <StyledSpacer height="24px" />
        </>
      )}
      <StyledPaper>
        <Typography variant="body1" gutterBottom>
          アカウント情報
        </Typography>
        <Divider />
        <StyledSpacer height="24px" />
        <Box>
          <Typography variant="body1" bold gutterBottom>
            メールアドレス
          </Typography>
          <Typography variant="body1">{employee.email}</Typography>
        </Box>
      </StyledPaper>
    </Box>
  );
};
// 通常のBoxと分けて利用のため、Style付与していないものの命名をSpacerとしている
const StyledSpacer = styled(Box)``;
const StyledPaper = styled(Paper)`
  padding: 24px 32px;
`;
