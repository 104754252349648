import { TransactionStatus, AnswerItem } from "@onn/common";
import React, { useEffect, FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AnswerMain } from "~/components/domains/transactions";
import { Loading } from "~/components/uiParts";
import { useEmployee, useEmployees, useAdmins } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar } from "~/hooks/shared";
import { useSurveyTransaction } from "~/hooks/transaction";
import { NotFound } from "~/pages/NotFound";
import { SurveyTransactionUseCase } from "~/service/usecases/transactionUseCase";

export const JourneySurveysNew: FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { id: surveyTransactionId } = useParams<"id">();

  const { data: mentor } = useEmployee(currentUser.mentorUserId);
  const { data: supportMembers = [] } = useEmployees(currentUser.supportMemberEmployeeIds || []);
  const { data: admins = [], isValidating } = useAdmins(currentUser.tenantId);

  const { data: surveyTransaction, isValidating: isValidatingSurveyTransaction } =
    useSurveyTransaction(surveyTransactionId);

  useEffect(() => {
    if (!surveyTransaction) return;

    if (surveyTransaction.employeeId !== currentUser?.id) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    } else if (surveyTransaction.status === TransactionStatus.DONE) {
      navigate("/portal/surveys/thanks", { state: { survey: surveyTransaction.contents } });
    }
  }, [currentUser?.id, enqueueSnackbar, navigate, surveyTransaction]);

  const handleSubmit = useCallback(
    async (surveyTransactionId: string, answerItems: AnswerItem[]) => {
      try {
        await SurveyTransactionUseCase.create(surveyTransactionId, answerItems);

        navigate("/portal/surveys/thanks");
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(e.message);
        }
      }
    },
    [navigate]
  );

  if (isValidatingSurveyTransaction || isValidating) {
    return <Loading size="large" fullHeight />;
  }

  if (!surveyTransaction?.contents) {
    return <NotFound />;
  }

  return (
    <AnswerMain
      survey={surveyTransaction.contents}
      answerItems={surveyTransaction.resultContents}
      mentor={mentor}
      admins={admins}
      supportMembers={supportMembers}
      onSubmit={(answerItems) => handleSubmit(surveyTransaction.id, answerItems)}
    />
  );
};
