import { OnboardingGeneralTask } from "@onn/common";
import { isEmpty } from "lodash";

import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";

const onboardingTaskRepository = new OnboardingTaskRepository();

export class CompleteGeneralTaskUseCase {
  async execute(actionId: OnboardingGeneralTask["actionId"], employeeId: string) {
    const onboardingTasks = await onboardingTaskRepository.whereByActionId(actionId, employeeId);

    // 削除されたりなど、存在しない場合は何もしない
    if (isEmpty(onboardingTasks)) {
      return;
    }

    const asyncJobs = onboardingTasks.map(async (onboardingTask) => {
      await onboardingTaskRepository.update(
        onboardingTask.id,
        OnboardingGeneralTask.create({
          ...onboardingTask,
          status: "COMPLETED",
        })
      );
    });

    await Promise.all(asyncJobs);
  }
}
