import { OnboardingTaskMemo, OnboardingTaskFactory } from "@onn/common";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";

const onboardingTaskRepository = new OnboardingTaskRepository();

export class UpdateOnboardingTaskMemoUseCase {
  async execute(onboardingTaskId: string, text: string, employeeId: string) {
    const onboardingTask = await onboardingTaskRepository.findById(onboardingTaskId);

    const createdMemo = OnboardingTaskMemo.create(text, employeeId);

    await onboardingTaskRepository.update(
      onboardingTaskId,
      OnboardingTaskFactory.createOnboardingTask({
        ...onboardingTask,
        memos: [...(onboardingTask.memos || []), createdMemo],
      })
    );

    functionOperator.httpsCall("sendUpdateNotificationAboutTaskMemo", {
      onboardingTaskId,
      memoId: createdMemo.id,
    });
  }
}
