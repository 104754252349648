import { Box } from "@material-ui/core";
import { taskTypes } from "@onn/common";
import React, { FC } from "react";

import { useOnboardingTaskIcon } from "../../hooks/useOnboardingTaskIcon";

import { Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  taskType: keyof typeof taskTypes;
  title: string;
};

// ====================
// main
// ====================

export const TitleSection: FC<Props> = ({ taskType, title }) => {
  // ====================
  // hooks
  // ====================

  const { icon } = useOnboardingTaskIcon(taskType);

  // ====================
  // main component
  // ====================

  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      {icon}
      <Typography variant="h4" bold color="textPrimary">
        {title}
      </Typography>
    </Box>
  );
};
