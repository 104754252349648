import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useState, useCallback, FC } from "react";
import styled from "styled-components";

import { SelectSingleEmployeesFormByPlatform } from "../../employees/SelectEmployeesFormByPlatform";

import { Button, Modal, Typography, Loading } from "~/components/uiParts";
import { useCurrentUser, useAllEmployeesWithDeleted } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (assigneeEmailWithoutOnnAccount?: string, newAssignee?: Employee) => Promise<void>;
  newHire?: Employee;
  assignee?: Employee;
};

export const ChangeAssigneeModal: FC<Props> = ({ open, onCancel, onSubmit, newHire, assignee }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { isLoading: isEmployeeLoading, data: allEmployeesWithDeleted = [] } =
    useAllEmployeesWithDeleted(currentUser.tenantId);
  const [selectedEmail, setSelectedEmail] = useState<string>();

  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!selectedEmail) return;

    const newAssignee = allEmployeesWithDeleted.find(
      (employee) => employee.email === selectedEmail
    );

    if (newAssignee && newAssignee.deleted) {
      enqueueSnackbar("削除されたユーザーを担当者にすることはできません", {
        variant: "error",
      });
      return;
    }

    setSending(true);
    await onSubmit(newAssignee ? undefined : selectedEmail, newAssignee)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [allEmployeesWithDeleted, onSubmit, selectedEmail, enqueueSnackbar, onCancel]);

  const buildButtonText = () =>
    assignee ? (sending ? "変更中" : "担当者を変更する") : sending ? "設定中" : "担当者を設定する";

  return (
    <Modal
      open={open}
      title="担当者変更"
      content={
        isEmployeeLoading ? (
          <Loading size="large" />
        ) : (
          <Box>
            <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="24px">
              <Box width="100%" display="inline-block">
                <SelectSingleEmployeesFormByPlatform
                  notAllowEmails={[
                    ...(newHire?.email ? [newHire.email] : []),
                    ...(assignee ? [assignee.email] : []),
                  ]}
                  selectedEmail={selectedEmail}
                  onSelectEmail={setSelectedEmail}
                />
              </Box>
            </Box>
            {tenant && !tenant.isIntegratedSlack && (
              <Typography variant="caption" color="textSecondary" align="center" display="block">
                Onnに登録されていないメンバーを設定したい場合は、
                <StyledPointerTypography
                  display="inline"
                  variant="caption"
                  bold
                  color="primary"
                  onClick={() => window.open("/settings/admin", "_blank")}
                >
                  こちら
                </StyledPointerTypography>
                から招待を行ってください。
              </Typography>
            )}
          </Box>
        )
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedEmail || sending}
          >
            {buildButtonText()}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
