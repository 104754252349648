import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { TemplateTable } from "~/components/domains/onboardingExperienceTaskTemplate";
import { Button, Icon, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export const TemplatesTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const navigate = useNavigate();

  const handleClickAddTemplateButton = useCallback(() => {
    handleModal({
      name: "selectTemplateTypeModal",
      args: {
        onSubmit: () => {
          navigate("/tools/templates/new");
        },
      },
    });
  }, [handleModal, navigate]);

  return (
    <Box py="40px">
      <Box display="flex" alignItems="center" justifyContent="space-between" gridGap="40px">
        <Typography variant="body2" color="textSecondary">
          {`受け入れ体験構築に適した独自のテンプレートを作成することができます。${"\n"}事前に作成されたテンプレートは、エクスペリエンス編集画面でタスクを追加する際に選択できます。`}
        </Typography>
        {currentUser.isAdmin() && (
          <Button
            variant="outlined"
            borderRadius="circle"
            color="primary"
            onClick={handleClickAddTemplateButton}
            startIcon={<Icon icon="add" color="primary" size="md" />}
          >
            新規作成
          </Button>
        )}
      </Box>
      <Box mt="32px">
        <TemplateTable />
      </Box>
    </Box>
  );
};
