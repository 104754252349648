import { Box } from "@material-ui/core";
import { OnboardingExperienceSimpleTask } from "@onn/common";
import React, { FC } from "react";

import { TextField, Typography } from "~/components/uiParts";

type Props = {
  title: string;
  placeholder?: string;
  readOnly?: boolean;
  setTitle: (value: string) => void;
};
export const TitleForm: FC<Props> = ({ title, placeholder, readOnly = false, setTitle }) => {
  return (
    <>
      <Box mt="36px">
        <Typography variant="body2" bold>
          タイトル
        </Typography>
      </Box>
      <Box mt="16px" display="flex" gridGap="24px">
        <TextField
          name="title"
          value={title}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => setTitle(e.target.value)}
          maxTextCount={OnboardingExperienceSimpleTask.MAX_TITLE_NUM}
          error={title.length > OnboardingExperienceSimpleTask.MAX_TITLE_NUM}
          inputProps={{
            readOnly,
          }}
        />
      </Box>
    </>
  );
};
