import { Employee } from "@onn/common";
import { useState, useCallback } from "react";

import { useNotifyOperationLog, useSnackbar } from "../shared";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * 指定されたエクスペリエンスをもとに入社者にタスクを発行する関数を提供するhooks
 */
export const useGenerateTasksFormExperience = (): {
  isLoading: boolean;
  generateTasksFormExperience: (
    employees: Employee[],
    onboardingExperienceIds: string[]
  ) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { operationLog, notifyOperationLog } = useNotifyOperationLog();

  /**
   * 指定されたエクスペリエンスをもとに入社者にタスクを発行する関数
   * @params employees 入社者の配列
   * @params onboardingExperienceIds エクスペリエンスのidの配列
   */
  const generateTasksFormExperience = useCallback(
    async (employees: Employee[], onboardingExperienceIds: string[]) => {
      setIsLoading(true);
      try {
        await apiClient.post("/generate_tasks_from_process", {
          employeeIds: employees.map((v) => v.id),
          onboardingExperienceIds,
        });
        enqueueSnackbar("タスクを生成しました", { variant: "success" });
        notifyOperationLog(
          operationLog.generateOnboardingTaskFromExperience(currentUser, employees)
        );
      } catch (e) {
        enqueueSnackbar("タスクの生成に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useGenerateTasksFormExperience:generateTasksFormExperience",
          },
        });
      }
      setIsLoading(false);
    },
    [currentUser, enqueueSnackbar, notifyOperationLog, operationLog]
  );

  return { isLoading, generateTasksFormExperience };
};
