import { Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee } from "@onn/common";
import React, { FC, useState, useMemo, useCallback } from "react";
import styled from "styled-components";

import { AdminPanel } from "./AdminPanelContent";
import { EditorPanel } from "./EditorPanelContent";

import { Modal } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  admins: Employee[];
  editors: Employee[];
  onClickAddEditorsButton: (editors: Employee[]) => void;
  onClickDeleteEditorsButton: (employeeId: string) => Promise<void>;
};

export const ManageEditableEmployeesModal: FC<Props> = ({
  open,
  onCancel,
  admins,
  editors: _editors,
  onClickAddEditorsButton,
  onClickDeleteEditorsButton,
}) => {
  const [activeTab, setActiveTab] = useState("editor");
  const [editors, setEditors] = useState(_editors);

  const handleClickDeleteEditorsButton = useCallback(
    (employeeId: string) => {
      onClickDeleteEditorsButton(employeeId).then(() => {
        setEditors((prev) => prev.filter((v) => v.id !== employeeId));
      });
    },
    [onClickDeleteEditorsButton]
  );

  const handleChangeActiveTab = (_: React.ChangeEvent<Record<string, never>>, newValue: string) => {
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: string }> = useMemo(
    () => [
      {
        label: `管理者(${admins.length})`,
        value: "admin",
      },
      {
        label: `編集者(${editors.length})`,
        value: "editor",
      },
    ],
    [admins.length, editors.length]
  );

  const content = (
    <TabContext value={activeTab}>
      <StyledTabList onChange={handleChangeActiveTab} indicatorColor="primary" textColor="primary">
        {tabs.map((tab) => (
          <StyledTab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </StyledTabList>
      <StyledTabPanel aria-labelledby="admin" id="admin" value="admin">
        <AdminPanel admins={admins} />
      </StyledTabPanel>
      <StyledTabPanel aria-labelledby="editor" id="editor" value="editor">
        <EditorPanel
          editors={editors}
          onClickAddEditorsButton={() => onClickAddEditorsButton(editors)}
          onClickDeleteEditorsButton={handleClickDeleteEditorsButton}
        />
      </StyledTabPanel>
    </TabContext>
  );

  return <Modal title="編集者管理" open={open} onCancel={onCancel} content={content} />;
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 0;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 24px 0px;
  }
  height: 400px;
  overflow-y: scroll;
`;
