import { Box, Tab, Menu, MenuItem } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee, OnboardingMessageTask } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useCallback, MouseEvent, useMemo } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../employees/hooks";

import {
  Button,
  Icon,
  IconButton,
  Typography,
  Modal,
  UserIconWithLabel,
} from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  newHire: Employee;
  onboardingMessageTask: OnboardingMessageTask;
  onClickAddRequestButton: () => void;
  onClickCopyLinkButton: () => void;
  onClickCancelMessageRequestButton: (contentId: string) => Promise<OnboardingMessageTask | void>;
};

export const ManageMessageTaskModal: FC<Props> = ({
  open,
  onCancel,
  newHire,
  onboardingMessageTask: _onboardingMessageTask,
  onClickAddRequestButton,
  onClickCopyLinkButton,
  onClickCancelMessageRequestButton,
}) => {
  const [onboardingMessageTask, setOnboardingMessageTask] = useState(_onboardingMessageTask);
  const [activeTab, setActiveTab] = useState("progress");
  const [targetIndex, setTargetIndex] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { getBorderColorById } = useGetBorderColorById();

  const completedContents = useMemo(() => {
    return onboardingMessageTask.contents.filter((content) => content.isCompleted);
  }, [onboardingMessageTask.contents]);

  const inProgressContents = useMemo(() => {
    return onboardingMessageTask.contents.filter((content) => !content.isCompleted);
  }, [onboardingMessageTask.contents]);

  const handleChangeActiveTab = (_: React.ChangeEvent<Record<string, never>>, newValue: string) => {
    setActiveTab(newValue);
  };

  const openMenu = (event: MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    setTargetIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClickCancelMessageRequestButton = useCallback(
    async (contentId: string) => {
      setAnchorEl(null);
      await onClickCancelMessageRequestButton(contentId).then(
        (messageTask) => messageTask && setOnboardingMessageTask(messageTask)
      );
    },
    [onClickCancelMessageRequestButton]
  );

  const tabs: Array<{ label: string; value: string }> = useMemo(
    () => [
      {
        label: `記入依頼中(${inProgressContents.length})`,
        value: "progress",
      },
      {
        label: `記入済み(${completedContents.length})`,
        value: "complete",
      },
    ],
    [completedContents, inProgressContents]
  );

  return (
    <Modal
      open={open}
      title="メッセージ設定"
      onCancel={onCancel}
      content={
        <TabContext value={activeTab}>
          <StyledTabList
            onChange={handleChangeActiveTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <StyledTabPanel value="progress">
            <Box display="flex" flexDirection="column" gridGap="16px">
              {onboardingMessageTask.isEditableMessageContent(newHire) && (
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    borderRadius="regular"
                    onClick={onClickAddRequestButton}
                    startIcon={<Icon icon="add" size="sm" color="primary" />}
                  >
                    メンバーを追加
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    borderRadius="regular"
                    onClick={onClickCopyLinkButton}
                    startIcon={<Icon icon="link" size="sm" color="primary" />}
                  >
                    招待リンクをコピー
                  </Button>
                </Box>
              )}
              {isEmpty(inProgressContents) ? (
                <Typography>現在、記入依頼を送っているメンバーはいません。</Typography>
              ) : (
                inProgressContents.map((content, index) => (
                  <Box
                    key={`Box-${content.name}-${index}`}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <UserIconWithLabel
                      name={content.name}
                      secondaryText={content.email}
                      iconPath={content.iconPath}
                      iconCircular
                      iconBorderColor={
                        content.requestedEmployeeId
                          ? getBorderColorById(newHire, content.requestedEmployeeId)
                          : undefined
                      }
                    />

                    <Box ml={1}>
                      <IconButton icon="menuVert" onClick={(e) => openMenu(e, index)} />
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl) && targetIndex === index}
                        onClose={closeMenu}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={() => handleClickCancelMessageRequestButton(content.id)}>
                          <Typography variant="body2">記入依頼取消</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </StyledTabPanel>

          <StyledTabPanel value="complete">
            <Box display="flex" flexDirection="column" gridGap="16px">
              {isEmpty(completedContents) ? (
                <Typography>現在、記入済みのメンバーはいません。</Typography>
              ) : (
                completedContents.map((user, index) => (
                  <UserIconWithLabel
                    key={`UserIconWithLabel-${user.name}-${index}`}
                    name={user.name}
                    secondaryText={user.email}
                    iconPath={user.iconPath}
                    iconCircular
                    iconBorderColor={
                      user.requestedEmployeeId
                        ? getBorderColorById(newHire, user.requestedEmployeeId)
                        : undefined
                    }
                  />
                ))
              )}
            </Box>
          </StyledTabPanel>
        </TabContext>
      }
    />
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 0;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 24px 0px;
  }
  height: 400px;
  overflow-y: scroll;
`;
