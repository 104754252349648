import { Employee, OnboardingStatus, extractLatestStatus } from "@onn/common";
import { useCallback } from "react";

type StatusType = OnboardingStatus | "ALL";

export const useGetEmployeesByStatus = () => {
  const getEmployeesByStatus = useCallback(
    (onboardingStatuses: StatusType[], allNewcomers: Employee[]) => {
      return allNewcomers.filter((employee) =>
        onboardingStatuses.includes(extractLatestStatus(employee.onboardingStatuses || []))
      );
    },
    []
  );

  return { getEmployeesByStatus };
};
