export class QuestionItem {
  title: string;
  name: string; // 質問項目で一意になる情報
  value: number; // ラジオボタンの質問項目の場合、数値が入る
  text: boolean;
  placeholder: string;
  constructor(title: string, name: string, value: number, text: boolean, placeholder: string) {
    this.title = title;
    this.name = name;
    this.value = value;
    this.text = text;
    this.placeholder = placeholder;
  }

  static plainToInstance(init: DeepExcludeMethods<QuestionItem>): QuestionItem {
    return new QuestionItem(init.title, init.name, init.value, init.text, init.placeholder);
  }
}
