import { Drawer, Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: JSX.Element;
};

export const SurveyDrawer: FC<Props> = ({ isOpen, onClose, title, content }) => (
  <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
    <StyledBox width="100%" px={3} py={2}>
      <Typography variant="h4" align="center">
        {title}
      </Typography>
    </StyledBox>
    <Box px={2} py={3}>
      {content}
    </Box>
  </StyledDrawer>
);

const StyledDrawer = styled(Drawer)`
  & > .MuiDrawer-paper {
    width: 45%;
    ${mixin.breakDown.md`width: 60%`};
    ${mixin.breakDown.sm`width: 90%`};
  }
`;

const StyledBox = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
`;
