import { OnboardingTaskType } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { OnboardingTaskUseCase } from "~/service/usecases/onboardingTaskUseCase";

const onboardingTaskUseCase = new OnboardingTaskUseCase();

export const generateUseOnboardingTasksByEmployeeId = (employeeId: string) => {
  return ["onboardingTasks", employeeId];
};

/**
 * employeeIdをもとにonboardingTaskを返すswr
 * @param {string} employeeId employeeId
 */
export const useOnboardingTasksByEmployeeId = (
  employeeId?: string
): SWRResponse<OnboardingTaskType[], Error> => {
  const keys = employeeId ? generateUseOnboardingTasksByEmployeeId(employeeId) : null;

  return useSWR(keys, async ([_key, employeeId]: [string, string]) =>
    onboardingTaskUseCase.findByEmployeeId(employeeId)
  );
};
