import { Box, Menu } from "@material-ui/core";
import { Department } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useMemo, FC } from "react";
import styled from "styled-components";

import { DepartmentSelectMenu } from "../DepartmentSelectMenu";

import { Button, Icon, IconButton, Typography } from "~/components/uiParts";

type Props = {
  departments: Department[];
  selectedDepartmentIds: string[];
  isSelectedNoDepartment: boolean;
  onChange: (selectedDepartmentIds: string[]) => void;
  /**
   * 所属なしを選択肢に入れるかどうか
   */
  isShowNoDepartment?: boolean;
  onChangeNoDepartmentCheckbox?: () => void;
};

export const DepartmentFilter: FC<Props> = ({
  departments,
  selectedDepartmentIds,
  isSelectedNoDepartment,
  onChange,
  isShowNoDepartment,
  onChangeNoDepartmentCheckbox,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const labelText = useMemo(
    () =>
      isEmpty(selectedDepartmentIds)
        ? "部署を選択"
        : departments
            .flatMap((department) =>
              selectedDepartmentIds.includes(department.id) ? department.name : []
            )
            .join(", "),
    [departments, selectedDepartmentIds]
  );

  return (
    <>
      <Box position="relative">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon
              icon="filter"
              size="sm"
              color="grey"
              $isEmpty={isEmpty(selectedDepartmentIds)}
            />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedDepartmentIds) && (
          <StyledIconButton icon="close" size="sm" color="grey" onClick={() => onChange([])} />
        )}
      </Box>

      <Menu
        key="statusFilter"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        {isEmpty(departments) ? (
          <Box py="16px" width="240px">
            <Box px="24px">
              <Typography variant="caption" color="textSecondary">
                該当する入社者は存在しません
              </Typography>
            </Box>
          </Box>
        ) : (
          <DepartmentSelectMenu
            departments={departments}
            selectedDepartmentIds={selectedDepartmentIds}
            isSelectedNoDepartment={isSelectedNoDepartment}
            displayIndeterminateDirection="child"
            onChange={(selectedDepartmentIds) => onChange(selectedDepartmentIds)}
            isShowNoDepartment={isShowNoDepartment}
            onChangeNoDepartmentCheckbox={onChangeNoDepartmentCheckbox}
          />
        )}
      </Menu>
    </>
  );
};

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
