import { Box } from "@material-ui/core";
import { DifferenceDate } from "@onn/common";
import { toNumber } from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography, TextField, SelectForm } from "~/components/uiParts";

export type DifferenceDateFormType = Pick<
  DifferenceDate,
  "amount" | "unit" | "direction" | "referenceDateType"
> &
  Pick<Optional<DifferenceDate, "deliveryTime">, "deliveryTime">;

type Props = {
  differenceDate: DifferenceDateFormType;
  onChange: (differenceDate: Optional<DifferenceDateFormType, "amount">) => void;
  units: DifferenceDateFormType["unit"][];
  selectableDirections?: DifferenceDateFormType["direction"][];
  readOnly?: boolean;
  isDeliveryTime?: boolean;
};

const displayDeliveryTimeItems = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((hour) => {
  return {
    value: hour,
    name: `${hour}:00`,
  };
});

export const DifferenceDateForm: FC<Props> = ({
  differenceDate,
  onChange,
  units,
  selectableDirections = DifferenceDate.DIRECTIONS,
  readOnly,
  isDeliveryTime,
}) => {
  return (
    <Box display="flex" gridGap="16px" alignItems="center">
      <Typography variant="body2" color="textPrimary">
        {DifferenceDate.displayReferenceDateTypeMap[differenceDate.referenceDateType || "JOIN_AT"]}
      </Typography>
      <StyledTextField
        type="number"
        inputProps={{ min: 1, readOnly }}
        variant="outlined"
        defaultValue={differenceDate.amount}
        onChange={(e) => {
          onChange({
            ...differenceDate,
            amount: e.target.value === "" ? undefined : toNumber(e.target.value),
          });
        }}
      />
      <StyledSmallSelectForm
        selected={differenceDate.unit}
        onChange={(e) =>
          onChange({
            ...differenceDate,
            unit: e.target.value as DifferenceDateFormType["unit"],
          })
        }
        menuItems={units.map((unit: DifferenceDateFormType["unit"]) => {
          return { value: unit, name: DifferenceDate.displayUnitMap[unit] };
        })}
        readOnly={readOnly}
      />
      <StyledSmallSelectForm
        selected={differenceDate.direction}
        onChange={(e) =>
          onChange({
            ...differenceDate,
            direction: e.target.value as DifferenceDateFormType["direction"],
          })
        }
        menuItems={selectableDirections.map(
          (selectableDirection: DifferenceDateFormType["direction"]) => {
            return {
              value: selectableDirection,
              name: DifferenceDate.displayDirectionMap[selectableDirection],
            };
          }
        )}
        readOnly={readOnly}
      />
      {isDeliveryTime && (
        <StyledSmallSelectForm
          selected={differenceDate.deliveryTime || 9}
          onChange={(e) =>
            onChange({
              ...differenceDate,
              deliveryTime: e.target.value as DifferenceDateFormType["deliveryTime"],
            })
          }
          menuItems={displayDeliveryTimeItems}
          readOnly={readOnly}
          icon="clock"
        />
      )}
    </Box>
  );
};

const StyledSmallSelectForm = styled(SelectForm)`
  width: 120px;
  background: white;
`;

const StyledTextField = styled(TextField)`
  width: 120px;
  background: white;
  .MuiInputBase-root {
    height: 46px;
  }
  .MuiInputBase-input {
    padding: 16px;
  }
  .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    white-space: nowrap;
    margin-left: 0px;
  }
`;
