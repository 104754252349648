import { Box } from "@material-ui/core";
import { OnboardingTaskType } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { useOnboardingTaskIcon } from "../../hooks/useOnboardingTaskIcon";

import { Icon, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  title: string;
  type: OnboardingTaskType["type"];
  memoText?: string;
};

export const OnboardingTaskTitle: FC<Props> = ({ title, type, memoText }) => {
  const { icon } = useOnboardingTaskIcon(type);

  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      {icon}
      <StyledTypography variant="body1" color="textSecondary">
        {title}
      </StyledTypography>
      {memoText && (
        <Tooltip title={memoText} isEllipsis arrow>
          <StyledMemoIcon icon="memo" size="md" color="primary" />
        </Tooltip>
      )}
    </Box>
  );
};

// 省略される
const StyledTypography = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const StyledMemoIcon = styled(Icon)`
  margin-left: 4px;
`;
