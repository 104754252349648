import { Box } from "@material-ui/core";
import { Employee, OnboardingMessageTask } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";

import { SelectEmployeesFormByPlatform } from "../../employees/SelectEmployeesFormByPlatform";

import { UserIcon, Button, Typography, Modal, Icon } from "~/components/uiParts";
import { useEmployee, useEmployees } from "~/hooks/employee";

type Props = {
  open: boolean;
  onCancel: () => void;
  newHire: Employee;
  onSubmit: (emails: string[]) => Promise<void>;
  onClickCopyLinkButton: () => void;
  onboardingMessageTask: OnboardingMessageTask;
};

export const AddRequestMessageTaskModal: FC<Props> = ({
  open,
  onCancel,
  newHire,
  onSubmit,
  onClickCopyLinkButton,
  onboardingMessageTask,
}) => {
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [sending, setSending] = useState(false);
  const existedEmails = useMemo(
    () => onboardingMessageTask.contents.flatMap((v) => (v.email ? v.email : [])),
    [onboardingMessageTask.contents]
  );

  const { data: mentor } = useEmployee(newHire.mentorUserId);
  const { data: supportMembers = [] } = useEmployees(newHire.supportMemberEmployeeIds);

  useEffect(() => {
    setSelectedEmails(
      [...(mentor?.email ? [mentor.email] : []), ...supportMembers.map((v) => v.email)].filter(
        (email) => !existedEmails.includes(email)
      )
    );
  }, [existedEmails, mentor, supportMembers]);

  const handleSubmit = useCallback(async () => {
    setSending(true);
    onSubmit(selectedEmails).finally(() => {
      setSending(false);
    });
  }, [onSubmit, selectedEmails]);

  return (
    <StyledModal
      open={open}
      title="メッセージ記入依頼"
      headerContent={
        <Box ml={32}>
          <Button
            variant="text"
            color="primary"
            borderRadius="regular"
            onClick={onClickCopyLinkButton}
            startIcon={<Icon icon="link" size="sm" color={!sending ? "primary" : "grey"} />}
            disabled={sending}
          >
            招待リンクをコピー
          </Button>
        </Box>
      }
      onCancel={onCancel}
      content={
        <Box display="flex" gridGap={32}>
          <Box
            flex={1}
            gridGap={18}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <UserIcon
              username={newHire.getName()}
              profileIconImageUrl={newHire.profileIconImageUrl}
              size="medium"
              borderColor="primary"
            />
            <Typography variant="body1" color="textSecondary" align="center">
              {`${newHire.getName()}さんへのメッセージの記入依頼を送るメンバーを選択してください(複数選択可)`}
            </Typography>
          </Box>
          <Box flex={1}>
            <SelectEmployeesFormByPlatform
              notAllowEmails={existedEmails}
              selectedEmails={selectedEmails}
              onSelectEmails={setSelectedEmails}
              targetNewHireForFixingMemberOnTop={newHire}
            />
          </Box>
        </Box>
      }
      footer={
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gridGap="40px"
        >
          <Typography variant="caption" color="textSecondary" align="center">
            すでに記入依頼をしているメンバーは候補に表示されません。
          </Typography>
          <Box width="50%">
            <Button
              fullWidth
              borderRadius="circle"
              variant="contained"
              color="primary"
              disabled={isEmpty(selectedEmails) || sending}
              onClick={handleSubmit}
            >
              追加する
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  &.MuiDialog-paper {
    width: 800px;
  }
`;
