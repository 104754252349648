import { Box } from "@material-ui/core";
import { Profile as ProfileType } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { TextContext, Typography } from "~/components/uiParts";
import { isObjectAllValueFalsy } from "~/util";

type Props = {
  profile: ProfileType;
};

const profileOptions = [
  {
    key: "birthday",
    title: "誕生日",
    selectItem: true,
  },
  {
    key: "nickname",
    title: "ニックネーム",
    selectItem: false,
  },
  {
    key: "tags",
    title: "私を構成するタグ",
    selectItem: false,
  },
  {
    key: "career",
    title: "経歴サマリー",
    selectItem: false,
  },
  {
    key: "free",
    title: "これから一緒に働く仲間に一言",
    selectItem: false,
  },
] as const;

// 誕生日の年月日が別々のプロパティとして保持されているため、それらをまとめる
const recomposeProfile = (profile: ProfileType) => {
  const { birthMonth, birthDate, ...other } = profile;
  // 誕生日未入力のときはブランクを返す
  const birthday = isObjectAllValueFalsy({
    birthMonth,
    birthDate,
  })
    ? ""
    : `${profile.birthMonth}/${profile.birthDate}`;

  return {
    ...other,
    birthday,
  };
};

export const Profile: FC<Props> = ({ profile }) => {
  const recomposedProfile = recomposeProfile(profile);
  type keys = keyof typeof recomposedProfile;

  // 自己紹介が登録されていない（すべてブランク）のとき
  if (isObjectAllValueFalsy(recomposedProfile))
    return (
      <StyledContainer>
        <Typography variant="body1" align="left">
          自己紹介は未登録です
        </Typography>
      </StyledContainer>
    );

  return (
    <StyledBox>
      {profileOptions.map((option, i) => {
        const key: keys = option.key;
        return (
          <Box key={`Profile-ProfileItem__${i}`}>
            <Typography variant="body1" gutterBottom>
              {option.title}
            </Typography>
            <TextContext isShowBg={!option.selectItem}>
              {recomposedProfile[key] || `${option.title}は未登録です`}
            </TextContext>
          </Box>
        );
      })}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const StyledContainer = styled(Box)`
  padding: 24px 0;
`;
