import { Box } from "@material-ui/core";
import { OnboardingExperience } from "@onn/common";
import React, { FC, useCallback } from "react";

import { OnboardingExperienceTable } from "~/components/domains/onboardingExperience";
import { Button, Icon, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import {
  useCreateOnboardingExperience,
  useOnboardingExperienceByTenantId,
} from "~/hooks/onboardingExperience";

export const OnboardingExperienceTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();

  const { isLoading: isLoadingCreateOnboardingExperience, createOnboardingExperience } =
    useCreateOnboardingExperience();

  const { mutate: mutateOnboardingExperiences } = useOnboardingExperienceByTenantId(
    currentUser.tenantId
  );

  const handleClickAddExperienceButton = useCallback(() => {
    handleModal({
      name: "editOnboardingExperienceModal",
      args: {
        mode: "create",
        onSubmit: async (title: string) => {
          await createOnboardingExperience(
            OnboardingExperience.create({
              title,
              tenantId: currentUser.tenantId,
              createdAt: new Date(),
              updatedAt: new Date(),
              updatedUserId: currentUser.id,
            })
          );
          mutateOnboardingExperiences();
        },
      },
    });
  }, [
    createOnboardingExperience,
    currentUser.id,
    currentUser.tenantId,
    handleModal,
    mutateOnboardingExperiences,
  ]);

  return (
    <Box py="40px">
      <Box display="flex" alignItems="center" justifyContent="space-between" gridGap="40px">
        <Typography variant="body2" color="textSecondary">
          {`入社者を受け入れるための体験を複数設定し、受け入れ担当者や入社者のタスクを設定することができます。入社前から入社後までのスムーズな受け入れを進めるために、体系的な受け入れ体験を計画しましょう。`}
        </Typography>
        {currentUser.isAdmin() && (
          <Button
            variant="outlined"
            borderRadius="circle"
            color="primary"
            onClick={handleClickAddExperienceButton}
            disabled={isLoadingCreateOnboardingExperience}
            startIcon={<Icon icon="add" color="primary" size="md" />}
          >
            新規作成
          </Button>
        )}
      </Box>
      <Box mt="32px">
        <OnboardingExperienceTable />
      </Box>
    </Box>
  );
};
