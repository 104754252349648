import { Department, Employee } from "@onn/common";

import { DepartmentRepository } from "~/infrastructure/api/departmentRepository";
import { EmployeeRepository } from "~/infrastructure/api/employeeRepository";
import { captureException } from "~/util";

const departmentRepository = new DepartmentRepository();
const employeeRepository = new EmployeeRepository();

export class DepartmentUseCase {
  async findByTenantId(tenantId: string): Promise<Department[]> {
    return await departmentRepository.findByTenantId(tenantId);
  }

  async create(department: Department): Promise<void> {
    return await departmentRepository.create(department).catch((error) => {
      if (error instanceof Error) {
        captureException({ error, tags: { type: "DepartmentUseCase.update" } });
      }
      throw new Error(`部署設定の追加に失敗しました`);
    });
  }

  async update(departmentId: string, newObject: Department): Promise<void> {
    return await departmentRepository.update(departmentId, newObject).catch((error) => {
      if (error instanceof Error) {
        captureException({ error, tags: { type: "DepartmentUseCase.update" } });
      }
      throw new Error(`部署設定の更新に失敗しました`);
    });
  }

  async deleteDepartments(_currentUser: Employee, departmentIds: string[]): Promise<void> {
    for (const departmentId of departmentIds) {
      const employees = await employeeRepository.findAllWithDeleted();

      const targetEmployees = employees.filter((employee) =>
        employee.departmentIds.includes(departmentId)
      );

      for (const employee of targetEmployees) {
        const newDepartmentIds = employee.departmentIds.filter((id) => id !== departmentId);

        await employeeRepository
          .update(employee.id, { departmentIds: newDepartmentIds })
          .catch((error) => {
            captureException({
              error,
              tags: { type: "deleteDepartments" },
              extras: { employee, newDepartmentIds, departmentId },
            });

            throw new Error(`ユーザーの所属部署を更新中に問題が発生しました`);
          });
      }

      await departmentRepository.delete(departmentId).catch((error) => {
        captureException({
          error,
          tags: { type: "deleteDepartments" },
          extras: { departmentId },
        });

        throw new Error(`部署設定の更新に失敗しました`);
      });
    }
  }

  async checkExist(tenantId: string, departmentIds: string[]): Promise<boolean> {
    const departments = await departmentRepository.findByTenantId(tenantId);
    return departmentIds.every((departmentId) =>
      departments.some((department) => department.id === departmentId)
    );
  }
}
