import { Box } from "@material-ui/core";
import React from "react";

import { MetadataType } from "~/components/providers/FileViewerProvider";

import { FileThumbnail, Loading } from "~/components/uiParts";

/**
 * ファイルのサムネイルを表示するコンポーネント
 */
export const FileThumbnails = React.memo<{
  variant: "left" | "right";
  messageText?: string;
  isValidatingMetadata: boolean;
  isFileSending?: boolean;
  files:
    | {
        name: string;
        size: number;
        url: string;
        signedUrl?: string;
      }[]
    | undefined;
  handleDownloadFile: (url: string, metadata: MetadataType[]) => void;
  setFiles: React.Dispatch<React.SetStateAction<MetadataType[] | undefined>>;
  setPreviewFileIndex: React.Dispatch<React.SetStateAction<number>>;
}>(
  ({
    variant,
    messageText,
    isValidatingMetadata,
    isFileSending,
    files,
    handleDownloadFile,
    setFiles,
    setPreviewFileIndex,
  }) => {
    return (
      <Box
        display="flex"
        flexDirection={variant === "left" ? "row" : "row-reverse"}
        mt={messageText ? "16px" : "0px"}
        gridGap="16px"
        flexWrap="wrap"
      >
        {isValidatingMetadata || (isFileSending && !isValidatingMetadata) ? (
          <Loading size="small" />
        ) : (
          <>
            {files &&
              files.map((file, index) => (
                <Box key={file.url} width={150}>
                  <FileThumbnail
                    src={file.url}
                    fileName={file.name}
                    filePath={file.url}
                    isDisplayActionContainer={false}
                    onPreviewFile={() => {
                      setFiles(files);
                      setPreviewFileIndex(index);
                    }}
                    onDownloadFile={(url) => handleDownloadFile(url, [file])}
                  />
                </Box>
              ))}
          </>
        )}
      </Box>
    );
  }
);
