import React, { useState, FC, FormEvent } from "react";
import styled from "styled-components";

import { Button, TextareaAutosize } from "~/components/uiParts";

type Props = {
  onSubmit: (value: string) => Promise<void>;
};

export const OnboardingTaskMemoForm: FC<Props> = ({ onSubmit }) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await onSubmit(value);
    setValue("");
    setIsLoading(false);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledTextareaAutosize
        name="onboarding_memo"
        value={value}
        fullWidth
        // システム上は "memo" という命名だが、ユーザーには "コメント" として表記する
        placeholder="コメントを入力してください"
        onChange={(e) => setValue(e.target.value)}
        maxRows={1}
      />
      <StyledFooter>
        <Button
          key="save"
          type="submit"
          borderRadius="regular"
          variant="contained"
          color="primary"
          disabled={!value.trim() || isLoading}
        >
          保存
        </Button>
      </StyledFooter>
    </StyledForm>
  );
};

const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: none !important;
`;

const StyledForm = styled.form`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
  background-color: white;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.action.active};
  }

  &:focus-within {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 0 8px 8px 0;
`;
