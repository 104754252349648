import { Box } from "@material-ui/core";
import { Employee, Department } from "@onn/common";
import { format } from "date-fns";
import React, { useCallback, FC } from "react";
import styled from "styled-components";

import { IconButtonWithManageMemberMenu } from "./TableRowItems/IconButtonWithManageMemberMenu";

import { DepartmentNameText } from "~/components/domains/departments";
import { Chip, Typography, UserIconWithLabel } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import {
  useChangeRole,
  useCurrentUser,
  useDeleteEmployee,
  useResendInvitationToSlack,
} from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useTenant } from "~/hooks/tenant";

type OrderType = "asc" | "desc";

type Props = {
  isLoading: boolean;
  employees: Employee[];
  departments: Department[];
  mutateEmployees: () => void;
  order: OrderType;
  onClickSortLastRefreshTime: () => void;
};

export const MemberTable: FC<Props> = ({
  isLoading,
  employees,
  departments,
  mutateEmployees,
  order,
  onClickSortLastRefreshTime,
}) => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const { handleModal } = useModal();
  const { changeRole } = useChangeRole();
  const { resendInvitationToSlack } = useResendInvitationToSlack();
  const { deleteEmployee } = useDeleteEmployee();

  const accountType = (isNewComer: boolean) => {
    if (!isNewComer) return false;
    return tenant.isActiveNewGraduate ? "候補者" : "入社者";
  };

  const handleClickChangeRoleButton = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "changeRoleModal",
        args: {
          employee,
          onSubmit: async (
            employee: Employee,
            selectedRole: Employee["role"],
            assignedAsNewcomer?: boolean
          ) => {
            await changeRole(employee.id, selectedRole, assignedAsNewcomer).then(() => {
              mutateEmployees();
            });
          },
        },
      });
    },
    [changeRole, handleModal, mutateEmployees]
  );

  const handleClickResendInvitationButton = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "resendInvitationModal",
        args: {
          employee,
          onSubmit: async () => {
            await resendInvitationToSlack(employee.id);
          },
        },
      });
    },
    [handleModal, resendInvitationToSlack]
  );

  const handleDeleteEmployeeButton = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "deleteMemberModal",
        args: {
          profileIconImageUrl: employee.profileIconImageUrl,
          username: employee.getName(),
          onSubmit: async () => {
            await deleteEmployee(employee, employees).then(() => {
              mutateEmployees();
            });
          },
        },
      });
    },
    [deleteEmployee, employees, handleModal, mutateEmployees]
  );

  const widthOptions = ["35%", "15%", "15%", "15%", "10%", "10%"];

  return (
    <VirtualizedTableV2<Employee>
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "ユーザー",
        },
        ...(tenant.isActiveNewGraduate
          ? []
          : [
              {
                text: "部署",
              },
            ]),
        {
          text: "アカウントタイプ",
        },
        {
          text: "権限",
        },
        {
          text: "最終ログイン",
          order,
          onSort: onClickSortLastRefreshTime,
        },
        {
          text: "",
        },
      ]}
      rows={employees}
      rowRenderer={({ key, index, style, rowData: employee }) => {
        const contents = [
          <Box display="flex" key={`userIconWithLabel-${employee.id}`}>
            <UserIconWithLabel
              iconPath={employee.profileIconImageUrl || ""}
              name={employee.getName()}
              secondaryText={employee.email}
              iconCircular
            />
          </Box>,
          ...(tenant.isActiveNewGraduate
            ? []
            : [
                <DepartmentNameText
                  key={`department-${employee.id}`}
                  departments={departments}
                  targetDepartmentIds={employee.departmentIds}
                />,
              ]),
          <Typography key={`memberType-${employee.id}`} variant="caption" color="textSecondary">
            {accountType(employee.isNewcomer())}
          </Typography>,
          <Typography key={`displayRole-${employee.id}`} variant="caption" color="textSecondary">
            {employee.getDisplayRole()}
          </Typography>,
          <>
            {employee.uid ? (
              <Typography
                key={`lastRefreshTime-${employee.id}`}
                variant="caption"
                color="textSecondary"
              >
                {employee.lastRefreshTime ? format(employee.lastRefreshTime, "yyyy/MM/dd") : ""}
              </Typography>
            ) : (
              <StyledChipContainer>
                <Chip color="grey" label="招待中" />
              </StyledChipContainer>
            )}
          </>,
          <>
            <IconButtonWithManageMemberMenu
              isDisplayChangeRoleButton={currentUser.isAdmin() && !employee.isNewGraduate}
              onClickChangeRoleButton={() => handleClickChangeRoleButton(employee)}
              isDisplayResendInvitationButton={!employee.isNewcomer() && !employee.uid}
              onClickResendInvitationButton={() => handleClickResendInvitationButton(employee)}
              isDisplayDeleteEmployeeButton={currentUser.isAdmin()}
              onClickDeleteEmployeeButton={() => handleDeleteEmployeeButton(employee)}
            />
          </>,
        ];
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const StyledChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 62px;
`;
