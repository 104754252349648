import { APISchema, Role } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * idをもとにroleを変更する関数を提供するhooks
 */
export const useChangeRole = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * idをもとにroleを変更する
   * @param {string} employeeId
   * @param {Role} role
   * @param {boolean} assignedAsNewcomer?
   */
  const changeRole = useCallback(
    async (employeeId: string, role: Role, assignedAsNewcomer?: boolean) => {
      setIsLoading(true);

      const requestBody: APISchema["/update_role"]["PATCH"]["body"] = {
        employeeId,
        role,
        assignedAsNewcomer,
      };

      await apiClient
        .patch("/update_role", requestBody)
        .then(() => {
          enqueueSnackbar("権限を変更しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar("権限の変更に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useChangeRole:changeRole" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    changeRole,
  };
};
