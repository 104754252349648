export class AnswerItem {
  key: string;
  value: string | string[];
  text?: string;
  constructor(init: { key: string; value: string | string[]; text?: string }) {
    this.key = init.key;
    this.value = init.value;
    this.text = init.text;
  }

  static plainToInstance(init: DeepExcludeMethods<AnswerItem>): AnswerItem {
    return new AnswerItem(init);
  }
}
