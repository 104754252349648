import { TextField, Box, Link } from "@material-ui/core";
import { isValidEmail } from "@onn/common";
import React, { useState, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, PasswordField, Typography, Paper } from "~/components/uiParts";
import { SSOButtonGroupForNewGraduate } from "~/components/uiParts/SSOButtonGroup";
import { useSnackbar, useQuery } from "~/hooks/shared";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException, mixin } from "~/util";

export const SignIn: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { query } = useQuery();
  const destPath = query.get("dest-path");

  // 新卒向け環境 https://app.onn-hr.com/ からリダイレクトしてきた時に表示するメッセージ
  // TODO: 移行が完全に完了したら削除する
  useEffect(() => {
    const isRedirect = query.get("is-redirect");

    if (isRedirect === "true") {
      enqueueSnackbar(
        `新しいURLになりました。再度、ログインをお願いします。\n今後はこちらのURLをご利用ください。`,
        { variant: "info" }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisableLoginButton: boolean =
    loading || email.length === 0 || emailError || password.length < 6;
  const handleSignInClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isDisableLoginButton) {
      return;
    }

    try {
      setLoading(true);
      await AccountUseCase.signIn(email, password);
      navigate(destPath ?? "/");
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      captureException({
        error: e as Error,
        tags: {
          type: "SignIn:handleSignInClick",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Sign in with popup google auth
   *
   * @throws Error if user not fount
   */
  const signInWithPopup = async () => {
    const authUser = await AccountUseCase.signInWithPopup();
    if (!authUser) {
      throw new Error("ログインに失敗しました");
    }

    const users = await AccountUseCase.findByUid();
    // check user exit on system if not show error and delete auth user
    if (users.length === 0) {
      await authUser.delete();
      throw new Error("該当するユーザーがいません");
    }

    return authUser;
  };

  const handleLoginWithGoogleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    try {
      setLoading(true);
      await signInWithPopup();
      navigate(destPath ?? "/");
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = error.message as string;
        // No error is displayed when user manipulates the popup.
        if (errorMessage !== "Error") {
          enqueueSnackbar(errorMessage, { variant: "error" });
        }
      }
    }
  };

  // email が形式と合致するかのチェック
  useEffect(() => {
    if (email.length === 0) {
      return setEmailError(false);
    }
    setEmailError(!isValidEmail(email));
  }, [email]);

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Typography variant="h1" bold color="textPrimary">
          ログイン
        </Typography>
        <StyledForm onSubmit={handleSignInClick} noValidate>
          <Box mt={8}>
            <Box mb={4}>
              <TextField
                error={emailError}
                fullWidth
                margin="normal"
                id="email"
                type="email"
                label="メールアドレス"
                variant="outlined"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                helperText={emailError && "メールアドレスの形式を正しく入力してください"}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box mb={5}>
              <PasswordField
                fullWidth
                value={password}
                onChange={(value: string) => setPassword(value)}
              />
            </Box>
            <Box textAlign="center" mt={5} mb={5}>
              <Link href="/reset_password" underline="always" color="textSecondary">
                <Typography variant="body2">パスワードを忘れた場合</Typography>
              </Link>
            </Box>
          </Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            borderRadius="circle"
            color="primary"
            disabled={isDisableLoginButton}
          >
            ログイン
          </Button>
          <SSOButtonGroupForNewGraduate handleLoginWithGoogleClick={handleLoginWithGoogleClick} />
        </StyledForm>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    box-shadow: 0 0 10px #0000000d;
    ${mixin.breakDown.sm`
      box-shadow: none;
    `}
  }
`;
