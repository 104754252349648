import { ContactRoom, Employee } from "@onn/common";

import { IContactRoomRepository } from "~/service/repository/iContactRoomRepository";

export class FindUnFollowedContactRoomsUseCase {
  constructor(private readonly contactRoomRepository: IContactRoomRepository) {}

  async execute(currentUser: Employee, tenantId: string): Promise<ContactRoom[]> {
    if (!currentUser.isAdmin()) {
      throw new Error("権限がありません");
    }

    return await this.contactRoomRepository.findUnFollowedContactRooms(tenantId);
  }
}
