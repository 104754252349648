import { Employee } from "@onn/common";
import { isSameDay, isBefore, isAfter, subDays } from "date-fns";
import React, { FC } from "react";

import { AfterJoin } from "./variants/AfterJoin";
import { BeforeJoin } from "./variants/BeforeJoin";
import { BeforeJoinOneDayAgo } from "./variants/BeforeJoinOneDayAgo";
import { DayOfJoin } from "./variants/DayOfJoin";
import { NotSetJoinAt } from "./variants/NotSetJoinAt";

import { Paper } from "~/components/uiParts";

type Props = {
  newcomerEmployee: Employee;
  users: Employee[];
  isDisplayWelcomeMessage?: boolean;
  onClickWelcomeMessage?: () => void;
};

export const CountdownPaper: FC<Props> = ({
  newcomerEmployee,
  users,
  isDisplayWelcomeMessage = false,
  onClickWelcomeMessage,
}) => {
  const now = new Date();

  // 入社日未設定
  if (!newcomerEmployee.joinAt) {
    return (
      <Paper isShowBgImage border="primary">
        <NotSetJoinAt newcomerEmployee={newcomerEmployee} users={users} />
      </Paper>
    );
  }

  // 上記ガード節でjoinAtを持っていることが担保されている
  const givenJoinAtNewcomer = newcomerEmployee as AugmentedRequired<Employee, "joinAt">;
  const joinAtDate = new Date(givenJoinAtNewcomer.joinAt);

  // 入社当日~入社1日後
  const isDayOfJoin = isSameDay(now, joinAtDate);
  // 入社2日後~
  const isAfterJoin = isAfter(now, joinAtDate);
  // 入社前日09:00:00~入社当日00:00:00
  const isBeforeJoinOneDayAgo = isSameDay(now, subDays(joinAtDate, 1));
  // ~入社2日前
  const isBeforeJoin = isBefore(now, joinAtDate);

  if (isDayOfJoin) {
    return (
      <Paper isShowBgImage border="primary">
        <DayOfJoin />
      </Paper>
    );
  } else if (isAfterJoin) {
    return (
      <Paper isShowBgImage border="primary">
        <AfterJoin joinAtDate={joinAtDate} />
      </Paper>
    );
  } else if (isBeforeJoinOneDayAgo) {
    return (
      <Paper isShowBgImage border="primary">
        <BeforeJoinOneDayAgo
          newcomerEmployee={givenJoinAtNewcomer}
          users={users}
          isDisplayMessage={isDisplayWelcomeMessage}
          onClickButton={onClickWelcomeMessage}
        />
      </Paper>
    );
  } else if (isBeforeJoin) {
    return (
      <Paper isShowBgImage border="primary">
        <BeforeJoin newcomerEmployee={givenJoinAtNewcomer} users={users} />
      </Paper>
    );
  } else {
    return null;
  }
};
