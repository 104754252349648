export class Profile {
  birthMonth: number | "";
  birthDate: number | "";
  tags: string;
  career: string;
  free: string;
  nickname: string;
  constructor({
    birthMonth,
    birthDate,
    tags,
    career,
    free,
    nickname,
  }: {
    birthMonth: number | "";
    birthDate: number | "";
    tags: string;
    career: string;
    free: string;
    nickname: string;
  }) {
    this.birthMonth = birthMonth;
    this.birthDate = birthDate;
    this.tags = tags;
    this.career = career;
    this.free = free;
    this.nickname = nickname;
  }

  doesAllFieldsHaveValue(): boolean {
    return Object.values(this).every((v) => v != null && v !== "");
  }
}
