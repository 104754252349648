/*
  - プレボーディング -
  STANDBY: 入社者登録されたが招待メールは送信していない
  INVITED: 入社者登録され招待メールを送信している
  LOGGED_IN: 1回以上ログインした
  PROFILE_REGISTERED: アカウント情報を登録した
  JOIN_DATE_REGISTERED: 入社日を設定した
  MENTOR_REGISTERED: バディを設定した
  JOINED: 入社した
  - オンボーディング -
  ANSWERED: アンケートに回答した
  - 入社後のステータス -
  ONBOARDING_COMPLETED: ６ヶ月後にオンボーディング済みになる
*/
export enum OnboardingStatus {
  STANDBY = "STANDBY",
  INVITED = "INVITED",
  LOGGED_IN = "LOGGED_IN",
  PROFILE_REGISTERED = "PROFILE_REGISTERED",
  JOIN_DATE_REGISTERED = "JOIN_DATE_REGISTERED",
  MENTOR_REGISTERED = "MENTOR_REGISTERED",
  JOINED = "JOINED",
  ANSWERED = "ANSWERED",
  ONBOARDING_COMPLETED = "ONBOARDING_COMPLETED",
}
