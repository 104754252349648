import React, { FC } from "react";

export const ChevronUp: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.97 16.97">
      <path
        id="Union_48"
        d="M-5835-2862v-12h2.4v9.6h9.6v2.4Z"
        transform="translate(-6141.222 2102.229) rotate(135)"
        stroke="none"
      />
    </svg>
  );
};
