import { Employee, Month, SurveyTransaction } from "@onn/common";
import { isEmpty } from "lodash";

import { factory as employeeFactory } from "~/service/repository/iEmployeeRepository";
import { factory as transactionFactory } from "~/service/repository/iTransactionRepository";

const employeeRepository = employeeFactory.employeeRepository();
const surveyTransactionRepository = transactionFactory.surveyTransactionRepository();

/**
 * eNPS集計用の employeeIds, surveyTransaction を返すUseCase
 */
export class FindSurveyTransactionsForENPSUseCase {
  /**
   * eNPS集計用の employeeIds, surveyTransaction を返すUseCase
   * @param {string} tenantId
   * @param {{ year: number; month: Month }} period
   * @returns {{
   * employees: Employee[];
   * firstMonthSurveyTransactions: SurveyTransaction[];
   * thirdMonthSurveyTransactions: SurveyTransaction[];
   * }}
   */

  async execute(
    tenantId: string,
    period: { year: number; month: Month }
  ): Promise<{
    employees: Employee[];
    firstMonthSurveyTransactions: SurveyTransaction[];
    thirdMonthSurveyTransactions: SurveyTransaction[];
  }> {
    const employees = await employeeRepository.findAll(tenantId);
    const targetEmployees = this.filterEmployeesOfTargetPeriod(employees, period);

    if (isEmpty(targetEmployees)) {
      return {
        employees: targetEmployees,
        firstMonthSurveyTransactions: [],
        thirdMonthSurveyTransactions: [],
      };
    } else {
      return surveyTransactionRepository
        .whereByEmployeeIds(targetEmployees.map((e) => e.id))
        .then((surveyTransactions) => {
          const filteredTransactions = surveyTransactions.filter(
            (t) => t.contents.type === "ONBOARDING"
          );

          const firstMonthSurveyTransactions = filteredTransactions.filter((transaction) =>
            transaction.contents.surveyTimings.find(
              (timing) => timing.unit === "WEEK" && 2 <= timing.value && timing.value <= 4
            )
          );
          const thirdMonthSurveyTransactions = filteredTransactions.filter((transaction) =>
            transaction.contents.surveyTimings.find(
              (timing) => timing.unit === "WEEK" && 10 <= timing.value && timing.value <= 12
            )
          );

          return {
            employees: targetEmployees,
            firstMonthSurveyTransactions,
            thirdMonthSurveyTransactions,
          };
        });
    }
  }

  private filterEmployeesOfTargetPeriod(
    employees: Employee[],
    period: { year: number; month: Month }
  ) {
    return employees.filter((e) => {
      if (!e.joinAt) {
        return false;
      }

      const [year, month] = e.joinAt.split("-");
      if (!year || !month) return false;
      return parseInt(year) === period.year && parseInt(month) === period.month;
    });
  }
}
