import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { UserIcon, Button, Divider, Typography } from "~/components/uiParts";

type Props = {
  mentor?: Employee | null;
  supportMembers?: Employee[];
  onlyView?: boolean;
  onClickAddMentorButton?: () => void;
  onClickAddAssignSupportMember?: () => void;
  onClickChangeMentorButton?: (mentor: Employee) => void;
  onClickDismissButton?: (supportMember: Employee) => void;
};
export const MentorAndSupportMemberList: FC<Props> = ({
  mentor,
  supportMembers = [],
  onlyView,
  onClickAddMentorButton,
  onClickAddAssignSupportMember,
  onClickChangeMentorButton,
  onClickDismissButton,
}) => {
  return (
    <Box pt={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" bold>
          バディ
        </Typography>
        {!mentor && !onlyView && (
          <StyledTypography variant="body2" bold color="primary" onClick={onClickAddMentorButton}>
            + バディを追加
          </StyledTypography>
        )}
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <UserIcon
          username={mentor ? mentor.getName() : "バディ未設定"}
          profileIconImageUrl={mentor?.profileIconImageUrl}
          size="small"
          circular
          borderColor="primary"
        />
        {mentor ? (
          <>
            <Box display="inline-block" ml={2}>
              <Typography variant="body1" bold>
                {mentor.getName()}
              </Typography>
              <Typography variant="caption">{mentor?.email}</Typography>
            </Box>
            {!onlyView && (
              <Box ml="auto">
                <Button
                  variant="outlined"
                  borderRadius="regular"
                  color="default"
                  onClick={() => onClickChangeMentorButton?.(mentor)}
                >
                  変更
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box ml={1}>
            <Typography variant="caption">未設定</Typography>
          </Box>
        )}
      </Box>
      <Box mt={4} mb={4}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" bold>
          サポートメンバー
        </Typography>
        {!onlyView && (
          <StyledTypography
            variant="body2"
            bold
            color="primary"
            onClick={onClickAddAssignSupportMember}
          >
            + サポートメンバーを追加
          </StyledTypography>
        )}
      </Box>
      {supportMembers.length === 0 && (
        <Box mt={2} display="flex" alignItems="center">
          <UserIcon username="バディ未設定" size="small" circular borderColor="blue" />
          <Box ml={1}>
            <Typography variant="caption">未設定</Typography>
          </Box>
        </Box>
      )}
      {supportMembers.map((supportMember) => (
        <Box mt={2} display="flex" key={supportMember.id}>
          <UserIcon
            username={supportMember.getName()}
            profileIconImageUrl={supportMember.profileIconImageUrl}
            size="small"
            circular
            borderColor="blue"
          />
          <Box display="inline-block" ml={2}>
            <Typography variant="body1" bold>
              {supportMember.getName()}
            </Typography>
            <Typography variant="caption">{supportMember.email}</Typography>
          </Box>
          {!onlyView && (
            <Box ml="auto">
              <Button
                variant="outlined"
                borderRadius="regular"
                color="default"
                onClick={
                  onClickDismissButton ? () => onClickDismissButton(supportMember) : undefined
                }
              >
                解除
              </Button>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;
