import { SurveyTransaction } from "@onn/common";
import { round } from "lodash";
import { useCallback } from "react";

export const useCalculateENPS = () => {
  /**
   * アンケートからeNPSを計算する関数
   * @param {SurveyTransaction[]} transactions
   */
  const calculateENPS = useCallback((transactions: SurveyTransaction[]): number => {
    const scores = transactions.flatMap((transaction) => transaction.eNPSScore() ?? []);
    const percentageOfPromoters = round(scores.filter((v) => v >= 9).length / scores.length, 3);
    const percentageOfCritics = round(scores.filter((v) => v <= 6).length / scores.length, 3);

    // 小数点同士の計算を避けるために一度整数にしてから最後に割る
    return (percentageOfPromoters * 1000 - percentageOfCritics * 1000) / 10;
  }, []);

  return { calculateENPS };
};
