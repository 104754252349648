import { OnboardingTaskWithNewHire, OnboardingSimpleTask } from "@onn/common";
import { isAfter, isSameDay } from "date-fns";
import { useCallback } from "react";

const priorityForSortMap: Record<OnboardingSimpleTask["assigneeRole"], number> = {
  NEW_HIRE: 1,
  MENTOR: 2,
  SUPPORT_MEMBER: 3,
  ADMIN: 4,
  BY_NAME: 5,
  NOT_SET: 6,
};

/**
 * オンボーディングタスクをソートする関数を提供するhooks
 */
export const useSortOnboardingTasks = () => {
  /**
   * オンボーディングタスクをソートする
   * NOTE: 期日が近い順でソートする。同日の場合は担当ロールの表示優先度順
   * @param {OnboardingTaskWithNewHire[]} onboardingTaskWithNewHires
   */
  const sortOnboardingTasks = useCallback(
    (onboardingTaskWithNewHires: OnboardingTaskWithNewHire[]) => {
      const now = new Date();
      return onboardingTaskWithNewHires
        .sort((a, b) => {
          const dateArray = [a, b].map((task) =>
            task.dueDate.calculateDate(task.newHire?.joinAt ? new Date(task.newHire.joinAt) : now)
          );
          const aDueDate = dateArray[0] as (typeof dateArray)[number];
          const bDueDate = dateArray[1] as (typeof dateArray)[number];
          if (!isSameDay(aDueDate, bDueDate)) {
            return isAfter(aDueDate, bDueDate) ? 1 : -1;
          }
          if (a.assigneeRole !== b.assigneeRole) {
            return priorityForSortMap[a.assigneeRole] - priorityForSortMap[b.assigneeRole];
          }
          return a.index - b.index;
        })
        .sort((a, b) => {
          const dateArray = [a, b].map((task) =>
            task.dueDate.calculateDate(task.newHire?.joinAt ? new Date(task.newHire.joinAt) : now)
          );
          const aDueDate = dateArray[0] as (typeof dateArray)[number];
          const bDueDate = dateArray[1] as (typeof dateArray)[number];

          // 期日が異なるタスクは1段階目のソートで並び替えているので何もしない
          if (!isSameDay(aDueDate, bDueDate)) {
            return 0;
          }

          if (!a.onboardingExperienceId) {
            return 1;
          }

          if (!b.onboardingExperienceId) {
            return -1;
          }

          // onboardingExperienceIdが同じ場合はindex順で並び替える
          if (a.onboardingExperienceId === b.onboardingExperienceId) {
            return a.index - b.index;
          }

          return a.onboardingExperienceId > b.onboardingExperienceId ? 1 : -1;
        });
    },
    []
  );

  return { sortOnboardingTasks };
};
