import { Box, Link } from "@material-ui/core";
import { isValidPassword, Employee } from "@onn/common";
import { changeLanguage } from "i18next";
import React, { useState, useEffect, FC, useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled, { CSSObject } from "styled-components";

import { NotFound } from "../NotFound";

import TermsOfUse from "./TermsOfUse";

import { FirstAndLastNameInput, useFirstAndLastNameInput } from "./_shared";

import { Paper, Button, Divider, Icon, PasswordField, Typography } from "~/components/uiParts";
import { useSignUp } from "~/hooks/employee";
import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { useSnackbar, useQuery } from "~/hooks/shared";
import { mixin } from "~/util";

export const Registration: FC<{
  registrationInfoByInvitationToken: RegistrationInfo | undefined;
}> = ({ registrationInfoByInvitationToken }) => {
  const { t } = useTranslation(["account"]);

  const { id: invitationToken } = useParams<"id">();
  const navigate = useNavigate();
  const { query } = useQuery();

  const { enqueueSnackbar } = useSnackbar();

  const registrationInfo = registrationInfoByInvitationToken;
  const employee = registrationInfo?.employee;
  const tenantName = registrationInfo?.tenantName;
  const enTenantName = registrationInfo?.enTenantName;

  const {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    onChangeFirstName,
    onChangeLastName,
  } = useFirstAndLastNameInput(employee);

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const { loading: isSignUpLoading, signUp } = useSignUp();

  const isNewGraduate = employee?.isNewGraduate;

  const isDisableSignUpButton = useMemo(() => {
    return (
      firstName.length === 0 ||
      lastName.length === 0 ||
      password.length === 0 ||
      passwordError ||
      !isAgreed
    );
  }, [firstName.length, isAgreed, lastName.length, password.length, passwordError]);

  useEffect(() => {
    if (password.length === 0) {
      return setPasswordError(false);
    }
    setPasswordError(!isValidPassword(password));
  }, [password]);

  useEffect(() => {
    if (employee?.isRegistered()) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [employee, invitationToken, enqueueSnackbar, isNewGraduate, navigate, query]);

  useEffect(() => {
    // 未認証時はcurrentUserが取得できずそのままでは常にjaになるためオブジェクトからlangを取得する
    changeLanguage(employee?.lang);
  }, [employee?.lang]);

  const handleSubmit = useCallback(
    async (userAccount: Employee) => {
      await signUp({
        firstName,
        lastName,
        password,
        invitationToken: userAccount.invitationToken,
      });
    },
    [firstName, lastName, password, signUp]
  );

  if (!employee || !tenantName) {
    return <NotFound />;
  }

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        {!employee.isNewGraduate && (
          <Box display="inline" fontWeight={400} lineHeight="24px" mb="8px">
            <Typography variant="body2" color="textSecondary">
              {t("guideText", {
                // employeeがenの場合はenTenantNameがテナントに存在していること前提なのでenTenantNameがundefinedの場合を想定しないコード
                tenantName: employee.lang === "ja" ? tenantName : enTenantName,
                role: Employee.displayRoleMap[employee.role],
                context: employee.isNewGraduate ? "newGraduate" : "midCareer",
              })}
            </Typography>
          </Box>
        )}
        <Box mt={employee.isNewGraduate ? "0px" : "32px"}>
          <StyledForm
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(employee);
            }}
          >
            <Box mb={5}>
              <Typography variant="body2" bold gutterBottom>
                {t("mailAddress.label")}
              </Typography>

              <Box display="flex">
                <Box width="95%">
                  {/* TODO: FixedEmailInput に置き換える */}
                  <StyledFixedEmailInput
                    value={employee.email}
                    name="email"
                    type="email"
                    readOnly
                  />
                </Box>
                <Icon icon="check" color="primary" size="sm" />
              </Box>
              <StyledDivider />
            </Box>
            <Box>
              <Typography variant="body2" bold>
                {t("accountRegistrationInformation")}
              </Typography>

              <FirstAndLastNameInput
                firstNameError={firstNameError}
                lastNameError={lastNameError}
                onChangeFirstName={onChangeFirstName}
                onChangeLastName={onChangeLastName}
                firstName={firstName}
                lastName={lastName}
              />
            </Box>
            <Box mt={3}>
              <PasswordField
                label={t("password.label")}
                error={passwordError}
                fullWidth
                helperText={
                  passwordError ? t("password.validation.tooShort") : t("password.helperText")
                }
                value={password}
                onChange={(inputValue: string): void => setPassword(inputValue)}
              />
            </Box>
            <TermsOfUse
              isAgreed={isAgreed}
              isNewGrad={!!employee.isNewGraduate}
              onChange={() => setIsAgreed((prv) => !prv)}
            />
            <Box
              textAlign="center"
              color="textSecondary"
              lineHeight="24px"
              mt={5}
              mb={4}
              pl={3}
              pr={3}
            >
              <Typography variant="caption">
                <Trans
                  ns="account"
                  i18nKey="consentRelatedText"
                  components={{
                    l: (
                      <Link
                        href="https://onn-hr.com/privacy_policy"
                        underline="always"
                        target="_blank"
                        color="textSecondary"
                      />
                    ),
                  }}
                />
              </Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                borderRadius="circle"
                color="primary"
                disabled={isDisableSignUpButton || isSignUpLoading}
              >
                {isSignUpLoading ? t("submitButton.sending") : t("submitButton.enable")}
              </Button>
            </Box>
          </StyledForm>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledFixedEmailInput = styled.input`
  width: 100%;
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.theme.typography.subtitle2 as CSSObject};
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.breakDown.sm`
      box-shadow: none;
      `}
  }
`;

const StyledForm = styled.form`
  width: 100%;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledDivider = styled(Divider)`
  height: 2px;
`;
