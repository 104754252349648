import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  /**
   * 下位部署が存在する場合、下位部署も上位とフラットな構造で渡す
   */
  departments: Department[];
  onAccept: (departmentIds: string[]) => Promise<void>;
};

export const ConfirmDeleteDepartmentModal: FC<Props> = ({
  open,
  departments,
  onCancel,
  onAccept,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const sortedDepartment = useMemo(
    () => departments.sort((a, b) => (a.layer > b.layer ? 1 : -1)),
    [departments]
  );

  const handleAccept = useCallback(() => {
    setIsLoading(true);
    onAccept(departments.map((dep) => dep.id))
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [departments, onAccept, onCancel]);

  const getConfirmText = useCallback(() => {
    let result = "";
    const parentDepartment = sortedDepartment[0] as (typeof sortedDepartment)[number];

    result += `${parentDepartment.name}を削除します。\n`;

    // 下位部署が存在するとき
    if (departments.length > 1) {
      const count = departments.length - 1; // 親以外に削除される部署の個数を表示するため1引く

      result += `削除した場合、この部署に紐づく${count}個の下位部署も同時に削除され、\nこれらの部署が設定されたユーザーから部署の設定は解除されますがよろしいですか？`;
    } else {
      result += `削除した場合、この部署が設定されたユーザーから\n部署の設定は解除されますがよろしいですか？`;
    }

    return result;
  }, [departments, sortedDepartment]);

  return (
    <Modal
      open={open}
      title="部署削除"
      content={
        <>
          <Box mb={2} textAlign="center" display="flex" flexDirection="column" gridGap="18px">
            <Typography variant="body1">{getConfirmText()}</Typography>

            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません
            </Typography>
          </Box>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            onClick={handleAccept}
            isLoading={isLoading}
          >
            削除
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
