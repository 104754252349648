import { ChartItem, APISchema } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const useSurveyTransactionScores = ({ employeeIds }: { employeeIds: string[] }) => {
  const keyObj = {
    name: "get_surveys_scores",
    employeeIds,
  };

  return useSWR<Record<string, ChartItem[]>>(keyObj, async () => {
    const queryParam: APISchema["/get_survey_scores"]["GET"]["queryParams"] = {
      employee_ids: employeeIds,
    };
    const res = await apiClient.get(`/get_survey_scores`, queryParam);
    return res.data;
  });
};
