import { useState } from "react";

export type TabValue = "taskDetail" | "taskMemos";

/**
 * オンボーディングタスク詳細情報を表示するサイドバーのタブを管理するカスタムフック
 */
export const useSidebarTab = () => {
  const [sidebarTab, setSidebarTab] = useState<TabValue>("taskDetail");

  return { sidebarTab, setSidebarTab };
};
