import { Employee, OnboardingTaskWithNewHire } from "@onn/common";
import { useCallback } from "react";

export const useExtractAssigneeEmployeeIds = () => {
  /**
   * オンボーディングタスクからアサインされているユーザーのidを取得する
   * @param {string} currentUserId
   * @param {Employee} newHire
   * @param {OnboardingTask[]} onboardingTasks
   */
  const extractAssigneeEmployeeIds = useCallback(
    (onboardingTasks: OnboardingTaskWithNewHire[], admins: Employee[]) => {
      const assigneeEmployeeIds = new Set<string>([]);
      onboardingTasks.map((onboardingTask) => {
        switch (onboardingTask.assigneeRole) {
          case "NEW_HIRE":
            if (!onboardingTask.newHire) return;
            assigneeEmployeeIds.add(onboardingTask.newHire.id);
            break;

          case "ADMIN":
            admins.forEach((v) => {
              assigneeEmployeeIds.add(v.id);
            });
            break;

          case "MENTOR":
            if (!onboardingTask.newHire?.mentorUserId) return;
            assigneeEmployeeIds.add(onboardingTask.newHire.mentorUserId);
            break;

          case "SUPPORT_MEMBER":
            if (!onboardingTask.newHire?.supportMemberEmployeeIds) return;
            onboardingTask.newHire.supportMemberEmployeeIds.forEach((v) => {
              assigneeEmployeeIds.add(v);
            });
            break;

          // BY_NAMEはidによって変わるのでaddedRolesをチェックしない
          case "BY_NAME":
            onboardingTask.assigneeIds.forEach((v) => {
              assigneeEmployeeIds.add(v);
            });
            break;

          default:
            break;
        }
      });

      return Array.from(assigneeEmployeeIds);
    },
    []
  );

  return { extractAssigneeEmployeeIds };
};
