import { Box } from "@material-ui/core";
import React, { useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Paper, UserIcon, Typography } from "~/components/uiParts";
import { useEmployee } from "~/hooks/employee/useEmployee";
import { useSnackbar } from "~/hooks/shared";
import { mixin } from "~/util";
interface Props {
  mentorUserId?: string;
  surveyTitle: string;
  isPreview?: boolean;
}

export const SurveyThanks: FC<Props> = ({ mentorUserId, surveyTitle, isPreview }) => {
  const { t } = useTranslation(["survey"]);
  const { enqueueSnackbar } = useSnackbar();
  const { data: mentor } = useEmployee(mentorUserId);

  useEffect(() => {
    if (isPreview) {
      enqueueSnackbar("回答結果はプレビュー機能のため保存されません", { variant: "success" });
    }
  }, [enqueueSnackbar, isPreview]);

  const messagesBySurvey = () => {
    if (surveyTitle) {
      return (
        <Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="h2" bold color="primary">
              DONE!!
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="body1" color="textSecondary" align="center">
              {t("complete", { context: "hasAnswer" })}
            </Typography>
          </Box>
          <Box mt={3} textAlign={"center"}>
            {mentor ? (
              <UserIcon
                username={mentor.getName()}
                profileIconImageUrl={mentor.profileIconImageUrl}
                size="small"
                borderColor="primary"
                circular
              />
            ) : null}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="h2" bold color="primary">
              THANK YOU!
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="body1" align="center" color="textSecondary">
              {t("complete")}
            </Typography>
          </Box>
          <Box mt={3} textAlign={"center"}>
            {mentor ? (
              <UserIcon
                username={mentor.getName()}
                profileIconImageUrl={mentor.profileIconImageUrl}
                size="small"
                borderColor="primary"
                circular
              />
            ) : null}
          </Box>
        </Box>
      );
    }
  };

  return (
    <StyledBox maxWidth="848px">
      {surveyTitle && (
        <Box mb={4}>
          <Typography variant="h2" color="textSecondary">
            {surveyTitle}
          </Typography>
        </Box>
      )}
      <StyledPaper>{messagesBySurvey()}</StyledPaper>
      <Box mt={4} display="flex" justifyContent="center">
        <Typography variant="caption" color="textSecondary">
          {t("closeBrowser")}
        </Typography>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledPaper = styled(Paper)`
  margin-top: 28px;
  padding: 80px;
  ${mixin.breakDown.sm`
    margin-top: 24px;
    padding: 80px 24px;
  `}
`;
