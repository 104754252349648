import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

import { MessagePaper } from "~/components/domains/onboardingTask";
import { Typography, Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnboardingTask } from "~/hooks/onboardingTask";
import { NotFound } from "~/pages/NotFound";

export const MessageIndex: FC = () => {
  const { id: taskId } = useParams<"id">();
  const { currentUser } = useCurrentUser();
  const { data: onboardingTaskData, isValidating: isValidatingOnboardingTaskData } =
    useOnboardingTask(taskId);

  const messageContents = useMemo(() => {
    const task = onboardingTaskData?.onboardingTask;
    if (task?.type !== "MESSAGE_TASK") return [];
    return task.contents.filter((content) => content.isCompleted);
  }, [onboardingTaskData?.onboardingTask]);

  if (isValidatingOnboardingTaskData) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  if (
    !messageContents.length ||
    !onboardingTaskData ||
    currentUser.id !== onboardingTaskData.onboardingTask?.employeeId
  ) {
    return <NotFound />;
  }

  return (
    <Box pt="40px" px="24px" pb="126px">
      <Typography variant="h2" color="textPrimary">
        {onboardingTaskData.onboardingTask?.title}
      </Typography>
      {messageContents.map((message) => (
        <Box key={message.id} marginTop="24px">
          <MessagePaper
            name={message.name}
            iconPath={message.iconPath || ""}
            department={message.department}
            message={message.message}
          />
        </Box>
      ))}
    </Box>
  );
};
