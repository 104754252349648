import { OnboardingExperienceTaskTemplate } from "../../OnboardingExperienceTaskTemplate";
import { DifferenceDate } from "../../shared";

export const taskTypes = {
  SIMPLE_TASK: "SIMPLE_TASK",
  // SURVEY_TASK: "SURVEY_TASK",
  GENERAL_TASK: "GENERAL_TASK",
  MESSAGE_TASK: "MESSAGE_TASK",
} as const;
export const displayTaskTypeMap = {
  [taskTypes.SIMPLE_TASK]: "シンプルタスク",
  // [taskTypes.SURVEY_TASK]: "回答フォーム",
  [taskTypes.MESSAGE_TASK]: "メンバーメッセージ(寄せ書き)",
};

export const assigneeRoles = [
  "NEW_HIRE",
  "MENTOR",
  "SUPPORT_MEMBER",
  "ADMIN",
  "BY_NAME",
  "NOT_SET",
] as const;
export type AssigneeRole = (typeof assigneeRoles)[number];
export const displayAssigneeRoleMap: { [key in AssigneeRole]: string } = {
  ADMIN: "管理者",
  MENTOR: "バディ",
  SUPPORT_MEMBER: "サポートメンバー",
  NEW_HIRE: "入社者",
  BY_NAME: "特定のメンバーを指定",
  NOT_SET: "担当者をあとで設定",
};

export abstract class OnboardingExperienceTask {
  tenantId: string;

  type: keyof typeof taskTypes;

  onboardingExperienceId: string;
  title: string;
  body: string;
  deliveryDate: DifferenceDate;
  assigneeRole: AssigneeRole;
  assigneeIds: string[];

  dueDate: DifferenceDate;
  isRequired: boolean;
  index: number;
  status: "NOT_STARTED" | "INPROGRESS" | "COMPLETED";

  // 編集用のために一時的に一時的に保持しておくためのフィールド
  emailsWithoutOnnAccount?: string[];

  static taskTypes = taskTypes;
  static assigneeRoles = assigneeRoles;
  constructor(
    init: Pick<
      OnboardingExperienceTask,
      | "tenantId"
      | "type"
      | "onboardingExperienceId"
      | "title"
      | "body"
      | "assigneeRole"
      | "assigneeIds"
      | "deliveryDate"
      | "dueDate"
      | "isRequired"
      | "index"
      | "status"
      | "emailsWithoutOnnAccount"
    >
  ) {
    this.tenantId = init.tenantId;
    this.type = init.type;
    this.onboardingExperienceId = init.onboardingExperienceId;
    this.title = init.title;
    this.body = init.body;
    this.assigneeRole = init.assigneeRole;
    this.assigneeIds = init.assigneeIds;
    this.deliveryDate = init.deliveryDate;
    this.dueDate = init.dueDate;
    this.isRequired = init.isRequired;
    this.index = init.index;
    this.status = init.status;
    this.emailsWithoutOnnAccount = init.emailsWithoutOnnAccount;
  }

  abstract updateForUpdatingTemplate(
    template: OnboardingExperienceTaskTemplate
  ): OnboardingExperienceTask;
}
