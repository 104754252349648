import { Survey } from "@onn/common";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { SurveyThanks } from "~/components/domains/transactions";

type LocationState = {
  survey: Survey;
};

export const PreviewSurveyThanks: FC = () => {
  const locationState = useLocation().state as LocationState;

  const survey =
    locationState && locationState.survey ? locationState.survey : ({ title: "" } as Survey);

  return <SurveyThanks surveyTitle={survey.title} isPreview />;
};
