import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import React, { useState, FC, useCallback, useMemo, ComponentProps } from "react";
import styled from "styled-components";

import { Button, Typography, Modal, SelectForm, TextField } from "~/components/uiParts";
import { mixin } from "~/util";

type ModeType = "create" | "edit";
const titleTextMap = {
  create: "追加",
  edit: "編集",
} as const;

// undefinedにはならないので取り除く
type SelectFormItemType = Exclude<ComponentProps<typeof SelectForm>["menuItems"], undefined>;

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (name: string, parentDepartment?: Department) => Promise<void>;
  departments: Department[];
  selectedDepartment?: Department;
  mode: ModeType;
};

export const EditDepartmentModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  departments,
  selectedDepartment,
  mode,
}) => {
  // 編集のときのみ、初期値に選択された部署のnameを入れる
  const initialName = (mode === "edit" && selectedDepartment?.name) || "";
  // 編集のときは初期値に選択された部署のparentDepartmentIdを入れる
  // 追加のときは初期値に選択された部署自身のidを入れることで、 `下位に部署を追加` の場合、選択した部署の下位部署を選択している状態にする
  const initialParentDepartmentId =
    (mode === "edit" ? selectedDepartment?.parentDepartmentId : selectedDepartment?.id) || "";

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [parentDepartmentId, setParentDepartmentId] = useState(initialParentDepartmentId);

  const parentDepartment = useMemo(
    () => departments.find((dep) => dep.id === parentDepartmentId),
    [departments, parentDepartmentId]
  );

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    onSubmit(name, parentDepartment)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [name, onCancel, onSubmit, parentDepartment]);

  // ネストされた構造を持つmenuItemを得る
  const getNestedMenuItems: (departments: Department[]) => SelectFormItemType = useCallback(
    (departments: Department[]) => {
      return departments
        .map((dep) => {
          return [
            {
              value: dep.id,
              name: dep.name,
              customItemElement:
                dep.layer > 1 ? (
                  <Typography variant="body2">
                    <StyledSpan $layer={dep.layer}>ー</StyledSpan>
                    {dep.name}
                  </Typography>
                ) : (
                  <Typography variant="body2">{dep.name}</Typography>
                ),
            },
            // 下位部署がある場合は再帰的にmenuItemを得る
            dep.hasChildren() ? getNestedMenuItems(dep.children as Department[]) : [],
          ];
        })
        .flat(Infinity) as SelectFormItemType;
    },
    []
  );

  const menuItems = useMemo(
    () => [
      {
        value: "",
        name: "上位部署なし",
      },
      ...getNestedMenuItems(Department.nest(departments)),
    ],
    [getNestedMenuItems, departments]
  );

  return (
    <Modal
      open={open}
      title={`部署${titleTextMap[mode]}`}
      content={
        <>
          <Typography variant="body2" bold>
            部署名
          </Typography>
          <Box mt="16px" mb="32px">
            <TextField
              fullWidth
              name="departmentName"
              variant="outlined"
              placeholder="例：〇〇部"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </Box>
          <Typography variant="body2" bold>
            上位部署
          </Typography>
          <Box mt="16px">
            <SelectForm
              fullWidth
              selected={parentDepartmentId}
              onChange={(e) => {
                setParentDepartmentId(e.target.value as string);
              }}
              menuItems={menuItems}
            />
          </Box>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!name.trim()}
            isLoading={isLoading}
          >
            追加
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledSpan = styled.span<{ $layer: number }>`
  margin-right: 12px;
  /* layerが下がることに24pxのインデントを入れる */
  margin-left: ${(props) => (props.$layer - 1) * 24}px;
`;
