import { OnboardingTaskWithNewHire } from "@onn/common";
import { useCallback } from "react";

type Status = "COMPLETED" | "EXPIRED" | "NOT_STARTED";

export const useFilterTasksByStatus = () => {
  /**
   * オンボーディングタスクのstatusをfilterしたものを返す関数
   * @param {OnboardingTaskWithNewHire[]} onboardingTasks
   * @param {Status} selectedStatus
   */
  const filterTasksByStatus = useCallback(
    (onboardingTasks: OnboardingTaskWithNewHire[], selectedStatus: Status | "ALL") => {
      if (selectedStatus === "ALL") return onboardingTasks;
      return onboardingTasks.filter((task) => {
        if (task.status === "COMPLETED") {
          return selectedStatus === "COMPLETED";
        }
        if (task.newHire && task.isExpiredByEmployee(task.newHire)) {
          return selectedStatus === "EXPIRED";
        }

        return selectedStatus === "NOT_STARTED";
      });
    },
    []
  );

  return { filterTasksByStatus };
};
