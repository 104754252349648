import { Menu, MenuItem } from "@material-ui/core";
import React, { ComponentProps, useCallback, useState, MouseEvent, FC } from "react";

import { IconButton, Typography } from "~/components/uiParts";

type Props = {
  id: string;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickAddDepartment: () => void;
};

export const ContextMenu: FC<Props> = ({
  id,
  onClickEdit,
  onClickDelete,
  onClickAddDepartment,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickButton = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClickMenuItem = useCallback(
    (
      callback:
        | ComponentProps<typeof ContextMenu>["onClickEdit"]
        | ComponentProps<typeof ContextMenu>["onClickDelete"]
        | ComponentProps<typeof ContextMenu>["onClickAddDepartment"]
    ) => {
      callback();
      setAnchorEl(null);
    },
    []
  );

  return (
    <>
      <IconButton onClick={handleClickButton} icon="menuVert" />
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem key={`${id}-edit`} onClick={() => handleClickMenuItem(onClickEdit)}>
          <Typography variant="body2">編集</Typography>
        </MenuItem>
        <MenuItem key={`${id}-delete`} onClick={() => handleClickMenuItem(onClickDelete)}>
          <Typography variant="body2">削除</Typography>
        </MenuItem>
        <MenuItem key={`${id}-add`} onClick={() => handleClickMenuItem(onClickAddDepartment)}>
          <Typography variant="body2">下位に部署を追加</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
