import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { QuestionTitle } from "~/components/uiParts/QuestionTitle";
import { TextContext } from "~/components/uiParts/TextContent";

type Props = {
  title: string;
  hrOnly?: boolean;
  bold?: boolean;
  resultText: string | number;
};

export const DialogueQuestion: FC<Props> = (props) => {
  const { title, hrOnly = false, bold = false, resultText } = props;

  return (
    <>
      <QuestionTitle title={title} hrOnly={hrOnly} bold={bold} />
      <Box height="8px" />
      <TextContext padding={2} isShowBg>
        {resultText}
      </TextContext>
    </>
  );
};
