import { OnboardingSimpleTask } from "../../domain/OnboardingTask";
import { DifferenceDate } from "../../domain/shared";

import { createIOnboardingTaskMemo } from "./createIOnboardingTaskMemo";

export const createIMockOnboardingSimpleTask = (
  mock: Partial<OnboardingSimpleTask> = {}
): OnboardingSimpleTask => {
  return new OnboardingSimpleTask({
    id: mock.id ?? "onboardingTask1",
    type: "SIMPLE_TASK",
    onboardingExperienceId: mock.onboardingExperienceId,
    deliveryDate: mock.deliveryDate,
    title: mock.title ?? "入社者の入社経緯やグレードをバディに伝達",
    body:
      mock.body ??
      "受け入れを主にサポートするバディの方へ、採用面談時のログや入社理由、そして初期のグレードを伝えて、入社後の受け入れプランが立てられる状態にしましょう！ http://onn.xxxxxxxxxx.com",
    filePaths: mock.filePaths ?? [],
    status: mock.status ?? "NOT_STARTED",
    employeeId: mock.employeeId ?? "newHire1",
    tenantId: mock.tenantId ?? "tenant1",
    assigneeRole: mock.assigneeRole ?? "ADMIN",
    assigneeIds: mock.assigneeIds ?? [],
    dueDate: mock.dueDate ?? new DifferenceDate(),
    createdAt: mock.createdAt ?? new Date("2020/05/18 17:41"),
    updatedAt: mock.updatedAt ?? new Date("2020/05/18 17:41"),
    index: mock.index ?? 0,
    memos: mock.memos ?? [createIOnboardingTaskMemo()],
    isRequired: mock.isRequired ?? true,
  });
};
