// 以下のページはレスポンシブで表示する
const SP_PAGES_PATHS = [
  "/account", // 登録画面
  "/signin", // サインイン
  "/reset_email", // メールアドレスリセット
  "/reset_password", // パスワードリセット
  "/portal", // ポータル全体
  "/settings/account", // アカウント設定
  "/journey", // アンケート回答
  "/preview/surveys", // アンケートプレビュー
  "/messages", // メッセージ記入
  "/complete", // 完了(サンクス)ページ
  "/profile", // プロフィール作成
  "/contact_rooms", // コンタクトルーム
  "/share_invite", // 共通QRコード
];

/**
 *  * スマートフォン向けのUIが用意されているページは、viewportをwidth=device-widthにする
 *  * それ以外のページは、PC向けのUIしか用意されていないので、デバイスに関わらずviewportを1280pxで固定する
 */
export const useSwitchViewport = () => {
  const metaElement = document.querySelector("meta[name='viewport']") as Element;

  // 任意のタイミングでの実行
  const switchViewport = (pathname: string) => {
    const currentPathname = pathname ?? location.pathname;
    let viewportContent;

    if (SP_PAGES_PATHS.some((path) => currentPathname.includes(path))) {
      viewportContent = "width=device-width";
    } else {
      viewportContent = "width=1280";
    }
    metaElement.setAttribute("content", viewportContent);
  };

  return { switchViewport };
};
