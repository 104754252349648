import { Box } from "@material-ui/core";
import {
  Employee,
  OnboardingTaskType,
  OnboardingTaskWithNewHire,
  taskTypes,
  OnboardingMessageTask,
} from "@onn/common";
import { last } from "lodash";
import React, { useCallback, FC, useMemo } from "react";

import { OnboardingTaskStatusChip } from "../OnboardingTaskStatusChip";

import { AssigneeEmployees } from "./TableRowItems/AssigneeEmployees";
import { DueDate } from "./TableRowItems/DueDate";
import { ManageOnboardingGeneralTaskMenu } from "./TableRowItems/ManageOnboardingGeneralTaskMenu";
import { ManageOnboardingMessageTaskMenu } from "./TableRowItems/ManageOnboardingMessageTaskMenu";
import { ManageOnboardingSimpleTaskMenu } from "./TableRowItems/ManageOnboardingSimpleTaskMenu";
import { OnboardingTaskTitle } from "./TableRowItems/OnboardingTaskTitle";
import { RequestedEmployees } from "./TableRowItems/RequestedEmployees";

import {
  FilterStatusType,
  FILTER_STATUSES,
} from "~/components/domains/employees/TaskTab/useViewModel";
import { Typography, UserIcon, Checkbox } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  isLoading: boolean;
  onboardingTasksWithNewHire: OnboardingTaskWithNewHire[];
  isDisplayNewHire?: boolean;
  selectedTaskStatus?: FilterStatusType;
  selectedOnboardingTaskIds: string[];
  setSelectedOnboardingTaskIds: (ids: string[]) => void;
  isSelectedAll: boolean;
  setIsSelectedAll: (bool: boolean) => void;
  onClickEditTaskButton: (task: OnboardingTaskType, newHire: Employee) => void;
  onClickDeleteTaskButton: (task: OnboardingTaskType, newHire: Employee) => void;
  onClickSwitchStatusButton: (task: OnboardingTaskType, newHire: Employee) => void;
  onClickManageWelcomeMessageButton: (task: OnboardingMessageTask, newHire: Employee) => void;
  onClickTableRow: (task: OnboardingTaskType, employeeId: string) => void;
  onClickDueDateStatus?: (task: OnboardingTaskType, joinAt?: string) => void;
  onClickOpenSidebarComments: (task: OnboardingTaskType, employeeId: string) => void;
};

export const OnboardingTaskTable: FC<Props> = ({
  isLoading,
  onboardingTasksWithNewHire,
  isDisplayNewHire = false,
  selectedTaskStatus,
  selectedOnboardingTaskIds,
  setSelectedOnboardingTaskIds,
  isSelectedAll,
  setIsSelectedAll,
  onClickEditTaskButton,
  onClickDeleteTaskButton,
  onClickSwitchStatusButton,
  onClickManageWelcomeMessageButton,
  onClickTableRow,
  onClickDueDateStatus,
  onClickOpenSidebarComments,
}) => {
  const handleChangeSelectAllCheckBox = useCallback(() => {
    if (isSelectedAll) {
      setSelectedOnboardingTaskIds([]);
      setIsSelectedAll(false);
    } else {
      setSelectedOnboardingTaskIds(
        onboardingTasksWithNewHire.flatMap((v) =>
          v.status === "COMPLETED" || v.type === "GENERAL_TASK" ? [] : v.id
        )
      );
      setIsSelectedAll(true);
    }
  }, [isSelectedAll, onboardingTasksWithNewHire, setIsSelectedAll, setSelectedOnboardingTaskIds]);

  const handleChangeCheckBox = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, onboardingTaskId: string) => {
      e.preventDefault();
      e.stopPropagation();
      if (selectedOnboardingTaskIds.includes(onboardingTaskId)) {
        setSelectedOnboardingTaskIds(
          selectedOnboardingTaskIds.filter((id) => id !== onboardingTaskId)
        );
      } else {
        setSelectedOnboardingTaskIds([...selectedOnboardingTaskIds, onboardingTaskId]);
      }
    },
    [selectedOnboardingTaskIds, setSelectedOnboardingTaskIds]
  );

  const { currentUser } = useCurrentUser();

  /**
   * Onnの設定タスク以外の未完了タスクが1つでもあればfalseを返す
   */
  const existsInCompletedTask = useMemo(() => {
    const inCompletedTask = onboardingTasksWithNewHire.find(
      (task) => task.status !== "COMPLETED" && task.type !== taskTypes.GENERAL_TASK
    );
    return !!inCompletedTask;
  }, [onboardingTasksWithNewHire]);

  const widthOptions = isDisplayNewHire
    ? ["10%", "30%", "15%", "15%", "10%", "10%", "10%"]
    : ["10%", "35%", "0%", "20%", "15%", "10%", "10%"];

  return (
    <VirtualizedTableV2<OnboardingTaskWithNewHire>
      isLoading={isLoading}
      hover
      emptyText={
        selectedTaskStatus === FILTER_STATUSES.EXPIRED
          ? "🎉 期限内のタスクは全て完了しています 🎉"
          : "タスクが見つかりませんでした"
      }
      widthOptions={widthOptions}
      headers={[
        {
          text: "",
          onChangeCheckBox: handleChangeSelectAllCheckBox,
          checked: isSelectedAll,
          disabled: !existsInCompletedTask,
        },
        {
          text: "タイトル",
        },
        {
          text: isDisplayNewHire ? "入社者" : "",
        },
        {
          text: "担当者",
        },
        {
          text: "期日",
        },
        {
          text: "ステータス",
        },
        {
          text: "",
        },
      ]}
      rows={onboardingTasksWithNewHire}
      rowRenderer={({ key, index, style, rowData: onboardingTask }) => {
        const { newHire } = onboardingTask;

        const contents = [
          /**
           * 一括操作のためのチェックボックス
           */
          <>
            {onboardingTask.status !== "COMPLETED" &&
              onboardingTask.type !== taskTypes.GENERAL_TASK && (
                <Checkbox
                  key={`select-${onboardingTask.id}`}
                  checked={selectedOnboardingTaskIds.includes(onboardingTask.id)}
                  onClick={(e) => handleChangeCheckBox(e, onboardingTask.id)}
                />
              )}
          </>,
          /*
           * タイトル
           */
          <OnboardingTaskTitle
            key={`title-${onboardingTask.id}`}
            title={onboardingTask.title}
            type={onboardingTask.type}
            memoText={last(onboardingTask.memos)?.text}
          />,
          /*
           * 入社者
           */
          <>
            {isDisplayNewHire && onboardingTask.newHire && (
              <Box display="flex" alignItems="center" gridGap="8px">
                <UserIcon
                  key={onboardingTask.newHire.id}
                  username={onboardingTask.newHire.getName()}
                  profileIconImageUrl={onboardingTask.newHire.profileIconImageUrl}
                  size="small"
                  circular
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                  noWrap
                  disablePreWrap
                >
                  {onboardingTask.newHire.getName()}
                </Typography>
              </Box>
            )}
          </>,
          /*
           * 担当者
           */
          <>
            {newHire && (
              <>
                {onboardingTask.type === "MESSAGE_TASK" ? (
                  <RequestedEmployees
                    key={`requestedEmployees-${onboardingTask.id}`}
                    onboardingTask={onboardingTask}
                    onClickEditTask={() =>
                      onClickManageWelcomeMessageButton(onboardingTask, newHire)
                    }
                  />
                ) : (
                  <AssigneeEmployees
                    key={`assigneeEmployees-${onboardingTask.id}`}
                    onboardingTask={onboardingTask}
                    newHire={newHire}
                    onClickEditTask={() => onClickEditTaskButton(onboardingTask, newHire)}
                  />
                )}
              </>
            )}
          </>,
          /*
           * 期日
           */
          <DueDate
            key={`joinAt-${onboardingTask.id}`}
            onClickDueDateStatus={onClickDueDateStatus}
            onboardingTask={onboardingTask}
          />,
          /*
           * ステータス
           */
          <>
            {newHire && (
              <OnboardingTaskStatusChip
                key={`status-${onboardingTask.id}`}
                newHire={newHire}
                onboardingTask={onboardingTask}
                onClickSwitchStatusButton={() =>
                  newHire && onClickSwitchStatusButton(onboardingTask, newHire)
                }
              />
            )}
          </>,
          /*
           * Menu
           */
          <>
            {newHire && (
              <>
                {onboardingTask.type === taskTypes.MESSAGE_TASK &&
                  onboardingTask.status !== "COMPLETED" && (
                    <ManageOnboardingMessageTaskMenu
                      key={`menu-${onboardingTask.id}`}
                      onClickEditTaskButton={() => onClickEditTaskButton(onboardingTask, newHire)}
                      onClickDeleteTaskButton={() =>
                        onClickDeleteTaskButton(onboardingTask, newHire)
                      }
                      onClickManageWelcomeMessageButton={() =>
                        onClickManageWelcomeMessageButton(onboardingTask, newHire)
                      }
                      onClickOpenSidebarComments={() => {
                        onClickOpenSidebarComments(onboardingTask, newHire.id);
                      }}
                      newHire={newHire}
                      currentUser={currentUser}
                    />
                  )}
                {onboardingTask.type === taskTypes.SIMPLE_TASK && (
                  <ManageOnboardingSimpleTaskMenu
                    key={`menu-${onboardingTask.id}`}
                    isCompleted={onboardingTask.status === "COMPLETED"}
                    onClickEditTaskButton={() => onClickEditTaskButton(onboardingTask, newHire)}
                    onClickDeleteTaskButton={() => onClickDeleteTaskButton(onboardingTask, newHire)}
                    onClickSwitchStatusButton={() =>
                      onClickSwitchStatusButton(onboardingTask, newHire)
                    }
                    onClickOpenSidebarComments={() => {
                      onClickOpenSidebarComments(onboardingTask, newHire.id);
                    }}
                  />
                )}
                {onboardingTask.type === taskTypes.GENERAL_TASK &&
                  onboardingTask.status !== "COMPLETED" && (
                    <ManageOnboardingGeneralTaskMenu
                      key={`menu-${onboardingTask.id}`}
                      onClickEditTaskButton={() => onClickEditTaskButton(onboardingTask, newHire)}
                      onClickDeleteTaskButton={
                        onboardingTask.actionId === "ASSIGN_MENTOR_OR_SUPPORT_MEMBER"
                          ? () => onClickDeleteTaskButton(onboardingTask, newHire)
                          : undefined
                      }
                      onClickOpenSidebarComments={() => {
                        onClickOpenSidebarComments(onboardingTask, newHire.id);
                      }}
                    />
                  )}
              </>
            )}
          </>,
        ];

        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                onClick: () => onClickTableRow(onboardingTask, onboardingTask.employeeId),
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};
