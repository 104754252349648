import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { QuestionTitle } from "~/components/uiParts/QuestionTitle";
import { TextContext } from "~/components/uiParts/TextContent";

type Props = {
  title: string;
  point: number;
  text: string;
};

export const SatisfactionQuestion: FC<Props> = (props) => {
  const { title, point, text } = props;

  return (
    <>
      <QuestionTitle title={title} bold />
      <Box height="8px" />
      <Box display="flex" alignItems="center">
        <TextContext variant="body1" padding={0}>
          {point}
        </TextContext>
        <Box display="inline-block" width="8px" />
        <TextContext variant="body2" padding={0}>
          {text}
        </TextContext>
      </Box>
      <Box height="8px" />
      <TextContext variant="caption" padding={0}>
        ※ 1.非常に不満、3.どちらとも言えない、5.非常に満足
      </TextContext>
    </>
  );
};
