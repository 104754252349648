import { v4 } from "uuid";

import { getDayDiffInJST } from "../../../utils";
import { Employee } from "../../Employee";
import { EditableEntityBase } from "../../shared";
import { ReadLog } from "../ReadLog/ReadLog";

export class ContactRoom extends EditableEntityBase {
  readonly id: string;
  readonly tenantId: string;
  spaceId?: string;
  employeeId: string; //入社者のid
  title: string;
  assigneeId?: string;
  followerIds: string[]; //メッセージを閲覧できるユーザーの配列
  isCompleted: boolean; //そのチャットのやり取りが完了しているかどうか
  readLogs: ReadLog[];
  incompleteSince: Date | null;
  type?: "LINE_USER" | "LINE_GROUP";
  targetId?: string;
  isUnFollowByEmployee?: boolean;
  lastUnfollowedAt?: Date;
  constructor(init: ExcludeMethods<ContactRoom>) {
    super(init);
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.employeeId = init.employeeId;
    this.title = init.title;
    this.followerIds = init.followerIds;
    this.isCompleted = init.isCompleted;
    this.readLogs = init.readLogs;
    this.assigneeId = init.assigneeId;
    this.incompleteSince = init.incompleteSince ?? null;
    this.type = init.type ?? undefined;
    this.targetId = init.targetId;
    this.isUnFollowByEmployee = init.isUnFollowByEmployee;
    this.lastUnfollowedAt = init.lastUnfollowedAt;
  }

  update(
    currentUserId: string | undefined,
    newObject: Partial<
      Pick<
        ContactRoom,
        | "title"
        | "employeeId"
        | "followerIds"
        | "isCompleted"
        | "readLogs"
        | "assigneeId"
        | "incompleteSince"
        | "lastUnfollowedAt"
        | "spaceId"
      >
    >
  ): this {
    if (newObject.title) {
      this.title = newObject.title;
    }
    if (newObject.employeeId) {
      this.employeeId = newObject.employeeId;
    }
    if (newObject.followerIds) {
      this.followerIds = newObject.followerIds;
    }
    if (newObject.isCompleted != null && newObject.isCompleted !== this.isCompleted) {
      this.incompleteSince = newObject.isCompleted ? null : new Date();
    }
    if (newObject.isCompleted != null) {
      this.isCompleted = newObject.isCompleted;
    }
    if (newObject.readLogs) {
      this.readLogs = newObject.readLogs;
    }
    if (newObject.assigneeId) {
      this.assigneeId = newObject.assigneeId;
    }
    if (newObject.lastUnfollowedAt) {
      this.lastUnfollowedAt = newObject.lastUnfollowedAt;
    }
    if (newObject.spaceId) {
      this.spaceId = newObject.spaceId;
    }

    return super.onUpdate(currentUserId);
  }

  updateReadLog(employeeId: string): this {
    const readLog = this.readLogs.find((readLog) => readLog.employeeId === employeeId);

    let newReadLogs: ReadLog[] = [];
    if (readLog) {
      newReadLogs = this.readLogs.map((readLog) => {
        if (readLog.employeeId === employeeId) return readLog.update();
        return readLog;
      });
    } else {
      newReadLogs = [...this.readLogs, ReadLog.createByEmployeeId(employeeId)];
    }

    return this.update(employeeId, {
      readLogs: newReadLogs,
    });
  }

  public static create(params: Optional<ExcludeMethods<ContactRoom>, "id">): ContactRoom {
    return new ContactRoom({
      ...params,
      id: params.id ?? v4(),
    });
  }

  isAccessible(currentUser: Employee) {
    if (currentUser.tenantId !== this.tenantId) return false;
    if (currentUser.isAdmin()) return true;

    return (
      this.followerIds.includes(currentUser.id) ||
      currentUser.id === this.employeeId ||
      currentUser.id === this.assigneeId
    );
  }

  checkIsNewHire(employeeId: string) {
    return this.employeeId === employeeId;
  }

  shouldNotifyIncomplete(): boolean {
    return (
      !this.isCompleted &&
      this.incompleteSince != null &&
      getDayDiffInJST(new Date(), this.incompleteSince) >= 2 &&
      this.assigneeId != null
    );
  }

  timestampIncompleteSince(): void {
    this.incompleteSince = new Date();
  }

  static plainToInstance(init: ExcludeMethods<ContactRoom>): ContactRoom {
    return new ContactRoom({
      ...init,
      readLogs: init.readLogs.map((v) => ReadLog.plainToInstance(v)),
    });
  }
}
