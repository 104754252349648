import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import { first, isEmpty } from "lodash";
import React, { useCallback, useMemo, FC } from "react";
import styled from "styled-components";

import { TooltipWhenTextTruncated, Typography } from "~/components/uiParts";

type Props = {
  departments: Department[];
  targetDepartmentIds: string[];
  isEditable?: boolean;
  onClick?: () => void;
};

export const DepartmentNameText: FC<Props> = ({
  departments,
  targetDepartmentIds,
  isEditable,
  onClick = () => void 0,
}) => {
  const getDepartmentNames = useCallback(() => {
    const targets = departments.filter((dep) => targetDepartmentIds.includes(dep.id));

    if (isEmpty(targets)) return ["所属なし"];

    return targets.map((target) => Department.getFullName(departments, target.id));
  }, [departments, targetDepartmentIds]);

  const joinedWithDotNames = useMemo(() => getDepartmentNames().join("｜"), [getDepartmentNames]);
  const givenNewLineNames = useMemo(() => getDepartmentNames().join("\n"), [getDepartmentNames]);

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <StyledClickableBox
        display="flex"
        alignItems="center"
        $isEditable={isEditable}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
      >
        <TooltipWhenTextTruncated text={givenNewLineNames}>
          {(ref) => (
            <StyledTypography
              variant="caption"
              color={first(getDepartmentNames()) === "所属なし" ? "textSecondary" : "textPrimary"}
              ref={ref}
            >
              {joinedWithDotNames}
            </StyledTypography>
          )}
        </TooltipWhenTextTruncated>
      </StyledClickableBox>
    </Box>
  );
};

// 2行を超えたら省略される
const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;

const StyledClickableBox = styled(Box)<{ $isEditable?: boolean }>`
  ${({ $isEditable }) => ($isEditable ? `cursor : pointer;` : `cursor: default;`)}
  width: 100%;
  height: 40px;
`;
