import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  survey: { surveyId: string; employee: Employee };
  onSubmit: () => void;
  onCancel: () => void;
  buttonDisabled: boolean;
};

export const RemindModal: FC<Props> = ({ open, survey, onSubmit, onCancel, buttonDisabled }) => {
  const userName = survey.employee.getName();
  return (
    <StyledModal
      open={open}
      title="リマインド通知送信"
      onCancel={onCancel}
      content={
        <Box textAlign="center">
          <UserIcon
            profileIconImageUrl={survey.employee.profileIconImageUrl}
            username={userName}
            size="medium"
            borderColor="primary"
          />
          <Box height="10px" />
          <Typography variant="body2" bold noWrap>
            {userName}
          </Typography>
          <Box height="24px" />
          <Typography>回答ステータスが「未回答」になっている{userName}さんへ</Typography>
          <Typography>リマインド通知を送信しますか？</Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={buttonDisabled}
          >
            送信
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
