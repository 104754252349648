import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { MouseEvent, useState, FC } from "react";
import styled from "styled-components";

import { Typography, IconButton, UserIconWithLabel } from "~/components/uiParts";

type Props = {
  editors: Employee[];
  onClickAddEditorsButton: () => void;
  onClickDeleteEditorsButton: (employeeId: string) => void;
};

export const EditorPanel: FC<Props> = ({
  editors,
  onClickAddEditorsButton,
  onClickDeleteEditorsButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [targetIndex, setTargetIndex] = useState<number>();

  const openMenu = (event: MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    setTargetIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <Box>
      <StyledTypography variant="body2" bold color="primary" onClick={onClickAddEditorsButton}>
        + 編集者を追加
      </StyledTypography>
      <Box display="flex" flexDirection="column" gridGap="16px" mt="24px">
        {editors.map((employee, index) => (
          <Box key={employee.id} display="flex" justifyContent="space-between">
            <UserIconWithLabel
              name={employee.getName()}
              secondaryText={employee.email}
              iconPath={employee.profileIconImageUrl}
              iconCircular
            />
            <Box pr="24px">
              <IconButton icon="menuVert" onClick={(e) => openMenu(e, index)} />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) && targetIndex === index}
                onClose={closeMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    setAnchorEl(null);
                    closeMenu(e);
                    onClickDeleteEditorsButton(employee.id);
                  }}
                >
                  <Typography variant="body2">削除</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;
