import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const DayOfJoin: FC = () => {
  return (
    <Box textAlign="center">
      <Typography variant="h2" bold color="primary">
        🎉Welcome!🎉
      </Typography>

      <Box height="16px" />

      <Typography variant="body2">
        {`いよいよ入社日を迎えました！\n素敵なDAY1を迎えるために、\n今日の予定を確認しましょう。`}
      </Typography>
    </Box>
  );
};
