import React, { ComponentProps, useState, FC } from "react";
import styled, { keyframes } from "styled-components";

import { Chip } from "~/components/uiParts";

type TransitionalStyleType = {
  color: ComponentProps<typeof Chip>["color"];
  label: ComponentProps<typeof Chip>["label"];
};

type Props = {
  initialStyle: TransitionalStyleType;
  midStyle: TransitionalStyleType;
  finalStyle: TransitionalStyleType;
};

export const AnimatedTaskStatusChip: FC<Props> = ({ initialStyle, midStyle, finalStyle }) => {
  const [state, setState] = useState<keyof Props>("initialStyle");

  switch (state) {
    case "initialStyle":
      return (
        <InitialChip
          onAnimationEnd={() => {
            setState("midStyle");
          }}
          $color={initialStyle.color}
          bold
          {...initialStyle}
        />
      );
    case "midStyle":
      return (
        <MidChip
          onAnimationEnd={() => {
            setState("finalStyle");
          }}
          bold
          {...midStyle}
        />
      );
    case "finalStyle":
      return <FinalChip bold {...finalStyle} />;
  }
};

const initialLabelKeyframes = keyframes`
  100% {
    opacity: 0;
  }
`;

const initialRootKeyframes = (color: string) => keyframes`
  100% {
    background-color:  ${color};
  }
`;

const midLabelKeyFrames = keyframes`
  0% {
    font-size: 0px;
    opacity: 0;
  }

  12%, 55% {
    font-size: 14px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    font-size: 14px;
  }
`;

const finalLabelKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const INITIAL_CHIP_WIDTH = "62px";
const FINAL_CHIP_WIDTH = "52px";

const InitialChip = styled(Chip)<{ $color: string }>`
  &.MuiChip-root {
    width: ${INITIAL_CHIP_WIDTH};
    animation: ${(props) => initialRootKeyframes(props.color)} ease-out 0.1s;
    animation-fill-mode: forwards;
  }

  & .MuiChip-label {
    animation: ${initialLabelKeyframes} ease-out 0.1s;
    animation-fill-mode: forwards;
  }
`;

const MidChip = styled(Chip)`
  &.MuiChip-root {
    width: ${INITIAL_CHIP_WIDTH};
  }

  & .MuiChip-label {
    animation: ${midLabelKeyFrames} linear 1.3s;
    animation-fill-mode: forwards;
  }
`;

const FinalChip = styled(Chip)`
  &.MuiChip-root {
    width: ${FINAL_CHIP_WIDTH};
  }

  & .MuiChip-label {
    animation: ${finalLabelKeyFrames} ease-in 0.2s;
    animation-fill-mode: forwards;
  }
`;
