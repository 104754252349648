import { GeneralTaskSettings } from "./GeneralTaskSettings";
import { Message } from "./Message";

export type InvitationMessage = {
  iconPath?: string;
  fullName: string;
  content: string;
};

type NewcomerIntroduction = {
  deliveryTime: number;
};

type Survey = {
  ignoreEmployeeIds: string[];
};

class ContactSetting {
  defaultFollowerEmployeeIds: string[];
  constructor(init: ContactSetting) {
    this.defaultFollowerEmployeeIds = init.defaultFollowerEmployeeIds;
  }
}

export class TenantSettings {
  id: string;
  tenantId: string;
  invitationMessage?: InvitationMessage;
  newcomerIntroduction: NewcomerIntroduction;
  contact?: ContactSetting;
  generalTask: GeneralTaskSettings;
  features: { [key in SelectableFeatures]: boolean };
  messages?: Message[];
  survey: Survey;
  ignoreMailsForInviteMail?: string[]; // 招待メールを送信しないメールアドレスのリスト

  constructor({
    id,
    tenantId,
    invitationMessage,
    newcomerIntroduction,
    contact,
    generalTask,
    features,
    messages,
    survey,
    ignoreMailsForInviteMail,
  }: ExcludeMethods<TenantSettings>) {
    this.id = id;
    this.tenantId = tenantId;
    this.newcomerIntroduction = newcomerIntroduction;
    this.invitationMessage = invitationMessage;
    this.contact = contact;
    this.generalTask = generalTask;
    this.features = features;
    this.messages = messages;
    this.survey = survey;
    this.ignoreMailsForInviteMail = ignoreMailsForInviteMail;
  }
}

/**
 * 各機能を利用するかどうか
 */
export const SelectableFeatures = {
  CUSTOM_INVITATION_MESSAGE: "customInvitationMessage", // 入社者招待カスタムメッセージ
  ONE_MONTH: "oneMonth", // 振り返りアンケート
  TOP_MESSAGE: "topMessage", // トップメッセージ
  LIBRARY: "library", // ライブラリ
  CONTACT_INITIAL_DISPLAY: "contactInitialDisplay", // コンタクトの初期表示
} as const;
export type SelectableFeatures = (typeof SelectableFeatures)[keyof typeof SelectableFeatures];
