import { Department } from "@onn/common";
import { useCallback } from "react";

export const useShiftLayerDeep = () => {
  /**
   * 部署をある他の部署の下位に編集したとき、その部署自身と下位にある部署すべてのlayerを +1 または -1 する
   * parentLayerは、移動先の部署のlayerであり、0は最上位を示す
   */
  const shiftLayerDeep = useCallback((department: Department, parentLayer = 0): Department => {
    const thisLayer = parentLayer + 1;

    return department.hasChildren()
      ? Department.create({
          ...department,
          layer: thisLayer,
          children: department.children?.map((child) => {
            return shiftLayerDeep(child, thisLayer);
          }),
        })
      : Department.create({ ...department, layer: thisLayer });
  }, []);

  return { shiftLayerDeep };
};
