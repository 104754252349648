import {
  Library,
  OnboardingTaskType,
  TenantSettings,
  Employee,
  typedEntries,
  AllContactRoom,
} from "@onn/common";
import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useLatestContactMessage } from "~/hooks/contactMessage";

import { useCanDisplayContactRooms } from "~/hooks/portal";

export type LinkKey =
  | "home"
  | "contactRooms"
  | "lineQr"
  | "onboardingTasks"
  | "surveys"
  | "libraries";

/**
 * 表示可能な遷移先ページのキーをを返すhooks
 */
export const useViewableLinks: (args: {
  currentUser: Employee;
  tenantSettings: TenantSettings;
  contactRooms: AllContactRoom[];
  onboardingTasks: OnboardingTaskType[];
  libraries: Library[] | undefined;
}) => LinkKey[] = ({ currentUser, tenantSettings, contactRooms, onboardingTasks, libraries }) => {
  const { canDisplayContactRooms } = useCanDisplayContactRooms();

  const { data: latestContactMessage = [] } = useLatestContactMessage({
    contactRoomIds: contactRooms.map((v) => v.id),
    tenantId: currentUser.tenantId,
  });

  const isDisplayContactRoom = useMemo(
    () =>
      canDisplayContactRooms({
        doesExistsContactRooms: !isEmpty(contactRooms),
        doesExistsLatestMessages: !isEmpty(latestContactMessage),
        isContactInitialDisplay: tenantSettings.features.contactInitialDisplay,
      }),
    [
      canDisplayContactRooms,
      contactRooms,
      latestContactMessage,
      tenantSettings.features.contactInitialDisplay,
    ]
  );

  const linkRecord: Record<LinkKey, boolean> = {
    home: true,
    lineQr: !!currentUser.lineUserId && !currentUser.isFollowedLineOfficialAccount,
    contactRooms: isDisplayContactRoom,
    onboardingTasks: !isEmpty(onboardingTasks),
    surveys: currentUser?.isNewcomer(),
    libraries: !isEmpty(libraries) && tenantSettings.features.library,
  };

  return typedEntries(linkRecord).flatMap(([k, v]) => (v ? [k] : []));
};
