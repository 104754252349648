import { useCallback, useState } from "react";
import { useSWRConfig } from "swr";

import { generateOnboardingExperienceTaskTemplatesKeys } from "./useOnboardingExperienceTaskTemplates";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { DeleteOnboardingExperienceTaskTemplateUseCase } from "~/service/usecases/onboardingExperienceTaskTemplate";
import { captureException } from "~/util";

const useCase = new DeleteOnboardingExperienceTaskTemplateUseCase();

/**
 * onboardingExperienceTaskTemplate を削除する関数を提供する
 */
export const useDeleteOnboardingExperienceTaskTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();

  /**
   * onboardingExperienceTaskTemplate を削除する
   * @param {string} onboardingExperienceTaskId
   * @param {{ title: string; description: string }} params
   */
  const deleteOnboardingExperienceTaskTemplate = useCallback(
    async (onboardingExperienceTaskId: string) => {
      setIsLoading(true);
      await useCase
        .execute(currentUser, onboardingExperienceTaskId)
        .then(() => {
          enqueueSnackbar("テンプレートを削除しました", { variant: "success" });
          mutate(generateOnboardingExperienceTaskTemplatesKeys(currentUser.tenantId));
        })
        .catch((e: Error) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useDeleteOnboardingExperienceTaskTemplate:deleteOnboardingExperienceTaskTemplate",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar, mutate]
  );

  return { isLoading, deleteOnboardingExperienceTaskTemplate };
};
