import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { CustomOnboardingExperienceTaskTemplate } from "~/hooks/onboardingExperienceTaskTemplate";

type Props = {
  template: CustomOnboardingExperienceTaskTemplate;
};

export const UpdatedContent: FC<Props> = React.memo(({ template }) => {
  const isUpdatedByEmployee = !!template.updatedEmployee;

  return (
    <Box display="column" gridGap="16px">
      <Typography variant="body2" bold color="textPrimary">
        {isUpdatedByEmployee ? template.updatedEmployee?.getName() : template.isDefault && "Onn"}
      </Typography>
      {isUpdatedByEmployee && (
        <StyledTypography variant="caption">
          {format(template.updatedAt, "yyyy/MM/dd")}
        </StyledTypography>
      )}
    </Box>
  );
});

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;
