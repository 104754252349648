import { Department } from "@onn/common";
import { useState, useCallback } from "react";

import { useSnackbar } from "../shared";

import { DepartmentUseCase } from "~/service/usecases/departmentUseCase";
import { captureException } from "~/util";

const departmentUseCase = new DepartmentUseCase();

export const useCreateDepartment = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const createDepartment = useCallback(
    async (department: Department) => {
      setIsLoading(true);
      await departmentUseCase
        .create(department)
        .then(() => {
          enqueueSnackbar("部署を追加しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useCreateDepartment:createDepartment" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return { isLoading, createDepartment };
};
