import { Employee } from "@onn/common";
import { useCallback, useState } from "react";
import { useSWRConfig } from "swr";

import { useRedirectAfterRegistration } from "../useRedirectAfterRegistration";
import { useSignOut } from "../useSignOut";

import { auth } from "~/config/firebase";
import { generateUseOnboardingTasksKeys } from "~/hooks/onboardingTask/useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask/useOnboardingTasksByEmployeeId";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";
import { apiClient } from "~/libs";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * アカウント登録をする関数を提供するhooks
 */
export const useSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useSignOut();
  const { updateCurrentTenantId } = useCurrentTenantId();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();
  const { mutate } = useSWRConfig();

  /**
   * アカウント登録をする関数
   */
  const signUp = useCallback(
    async ({
      firstName,
      lastName,
      password,
      invitationToken,
    }: {
      firstName: string;
      lastName: string;
      password: string;
      invitationToken: string;
    }) => {
      setLoading(true);
      const isSignedIn = !!auth.currentUser;

      try {
        const employee = await apiClient
          .post("/signup", {
            firstName,
            lastName,
            password,
            invitationToken,
          })
          .then((res) => {
            return new Employee(res.data.userAccount);
          });

        await AccountUseCase.signIn(employee.email, password);
        mutate(generateUseOnboardingTasksByEmployeeId(employee.id));
        mutate(generateUseOnboardingTasksKeys(employee.tenantId));

        updateCurrentTenantId(employee.tenantId);
        await redirectAfterRegistration(employee);
        enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        // in case error and signed in -> sign out
        if (isSignedIn) {
          await signOut();
        }
        captureException({
          error: e as Error,
          tags: { type: "useSignUp:signUp" },
        });
        setLoading(false);
      }
    },
    [enqueueSnackbar, mutate, redirectAfterRegistration, signOut, updateCurrentTenantId]
  );

  return { signUp, loading };
};
