import { Box } from "@material-ui/core";
import { MessageContent } from "@onn/common";
import React, { FC, useState, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { NotFound } from "../../NotFound";

import { AccessibleEmployeeList } from "~/components/domains/employees";
import { MessagePaper } from "~/components/domains/onboardingTask";
import { Loading, Button, Divider, Paper, Typography, Modal } from "~/components/uiParts";
import { useAdmins, useCurrentUserNonGuarded, useEmployee, useEmployees } from "~/hooks/employee";
import { useOnboardingTask } from "~/hooks/onboardingTask";
import { useSubmitMessageContent } from "~/hooks/onboardingTask/messageTask/useSubmitMessageContent";
import { useSnackbar } from "~/hooks/shared";
import { mixin, captureException } from "~/util";

type LocationState = {
  inputIconDataUrl?: string;
  newMessageContent?: MessageContent;
  uploadedIconFileName?: string;
};

export const MessageConfirm: FC = () => {
  const navigate = useNavigate();

  const { currentUser } = useCurrentUserNonGuarded();

  const { id: taskId, contentId } = useParams<"id" | "contentId">();
  const {
    data: onboardingTaskData,
    isValidating: isValidatingOnboardingTaskData,
    mutate: mutateOnboardingTaskData,
  } = useOnboardingTask(taskId);

  const locationState = useLocation().state as LocationState | null;
  const { enqueueSnackbar } = useSnackbar();
  const newMessageContent = locationState ? locationState.newMessageContent : null;
  const isGuest = contentId === "guest";

  const [openViewableModal, setOpenViewableModal] = useState(false);
  const inputIconDataUrl = locationState?.inputIconDataUrl;
  const uploadedIconFileName = locationState?.uploadedIconFileName;

  const messageContent = useMemo(() => {
    const onboardingTask = onboardingTaskData?.onboardingTask;

    if (isGuest || onboardingTask?.type !== "MESSAGE_TASK") {
      return null;
    }

    return onboardingTask.contents.find((content) => content.id === contentId);
  }, [onboardingTaskData?.onboardingTask, isGuest, contentId]);

  const { data: mentor } = useEmployee(
    currentUser ? onboardingTaskData?.employee?.mentorUserId : undefined
  );
  const { data: supportMembers = [] } = useEmployees(
    currentUser ? onboardingTaskData?.employee?.supportMemberEmployeeIds : undefined
  );
  const { data: admins = [] } = useAdmins(currentUser ? currentUser.tenantId : undefined);

  const { isLoading: isLoadingSubmitMessageContent, submitMessageContent } =
    useSubmitMessageContent();

  const sendMessage = useCallback(
    async (newMessageContent: MessageContent, taskId: string) => {
      const onboardingTask = onboardingTaskData?.onboardingTask;
      if (!onboardingTask) {
        return;
      }

      try {
        await submitMessageContent(
          onboardingTask,
          newMessageContent,
          false,
          inputIconDataUrl,
          uploadedIconFileName
        );

        // データを再検証してからリダイレクトする
        await mutateOnboardingTaskData();
        navigate(`/messages/${taskId}/${newMessageContent.id}/complete`);
      } catch (e) {
        enqueueSnackbar("送信に失敗しました", { variant: "error" });
        if (e instanceof Error) {
          captureException({
            error: e,
            tags: { type: "sendMessage" },
          });
        }
      }
    },
    [
      enqueueSnackbar,
      inputIconDataUrl,
      mutateOnboardingTaskData,
      navigate,
      onboardingTaskData?.onboardingTask,
      submitMessageContent,
      uploadedIconFileName,
    ]
  );

  const handleClickPrev = useCallback((): void => {
    navigate(`/messages/${taskId}/${contentId}/edit`, {
      state: {
        newMessageContent,
        inputIconDataUrl,
        uploadedIconFileName,
      },
    });
    window.scrollTo(0, 0);
  }, [contentId, inputIconDataUrl, navigate, newMessageContent, taskId, uploadedIconFileName]);

  if (!newMessageContent) {
    navigate(`/messages/${taskId}/${contentId}`);
    // NOTE: newMessageContentが存在しない状態でレンダリングさせないためにnullを返す
    return <NotFound />;
  }

  if (isValidatingOnboardingTaskData) {
    return <Loading size="large" fullHeight />;
  }

  if (!taskId || !onboardingTaskData || !contentId || !onboardingTaskData?.employee)
    return <NotFound />;

  if (!isGuest && !messageContent) return <NotFound />;

  if (onboardingTaskData?.onboardingTask.type !== "MESSAGE_TASK") {
    return <NotFound />;
  }

  if (!onboardingTaskData.onboardingTask.isEditableMessageContent(onboardingTaskData.employee)) {
    return <NotFound />;
  }

  return (
    <>
      <StyledContainer maxWidth="1090px">
        <StyledTitle variant="h2">{onboardingTaskData.onboardingTask?.title}</StyledTitle>
        <StyledPaper paddingLarge>
          <Typography variant="body2" bold>
            {onboardingTaskData.employee.getName()}さんへ送信されるメッセージのプレビューです。
          </Typography>
          {currentUser && (
            <Typography variant="body2">
              ※送信内容は、
              <StyledPointerTypography
                display="inline"
                variant="body2"
                color="primary"
                onClick={() => setOpenViewableModal(true)}
              >
                こちら
              </StyledPointerTypography>
              のメンバーが管理画面から確認することができます。
            </Typography>
          )}
          <Box height="16px" />
          <Divider orientation="horizontal" />
          <Box height="40px" />
          <MessagePaper
            name={newMessageContent.name}
            iconPath={inputIconDataUrl || newMessageContent.iconPath || ""}
            department={newMessageContent.department}
            message={newMessageContent.message}
          />
          <StyledButtonContainer mt={5}>
            <Button
              fullWidth
              variant="outlined"
              borderRadius="circle"
              color="primary"
              onClick={handleClickPrev}
            >
              メッセージを修正
            </Button>
            <Button
              fullWidth
              variant="contained"
              borderRadius="circle"
              color="primary"
              disabled={isLoadingSubmitMessageContent}
              onClick={() => sendMessage(newMessageContent, taskId)}
            >
              {onboardingTaskData.onboardingTask.isDeliveredByEmployee(onboardingTaskData.employee)
                ? "送信"
                : "保存"}
            </Button>
          </StyledButtonContainer>
        </StyledPaper>
      </StyledContainer>
      <Modal
        open={openViewableModal}
        title={`${onboardingTaskData.onboardingTask.title}を閲覧できるメンバー`}
        content={
          <AccessibleEmployeeList mentor={mentor} supportMembers={supportMembers} admins={admins} />
        }
        onCancel={() => setOpenViewableModal(false)}
      />
    </>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledTitle = styled(Typography)`
  padding-bottom: 32px;

  ${mixin.breakDown.sm`
    padding: 40px 24px 0;
  `}
`;

const StyledPaper = styled(Paper)`
  ${mixin.breakDown.sm`
    &.MuiPaper-elevation1 {
      box-shadow: none;
    }
    &.MuiPaper-rounded {
      border-radius: 0;
    }
  `}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
