import React, { FC } from "react";

import { Chip } from "~/components/uiParts";
import { CustomOnboardingExperienceTaskTemplate } from "~/hooks/onboardingExperienceTaskTemplate";

type Props = {
  template: CustomOnboardingExperienceTaskTemplate;
};

export const Status: FC<Props> = React.memo(({ template }) => {
  return (
    <Chip
      color={template.isPublic ? "primary" : "grey"}
      label={template.isPublic ? "利用可" : "下書き"}
      bold
    />
  );
});
