import { v4 } from "uuid";

export class SurveyAlert {
  id: string;
  tenantId: string;
  transactionId: string;
  isCompleted: boolean;
  operatedEmployeeId?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    init: Pick<
      SurveyAlert,
      | "id"
      | "tenantId"
      | "transactionId"
      | "isCompleted"
      | "operatedEmployeeId"
      | "createdAt"
      | "updatedAt"
    >
  ) {
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.transactionId = init.transactionId;
    this.isCompleted = init.isCompleted;
    this.operatedEmployeeId = init.operatedEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  public static create(params: Pick<SurveyAlert, "tenantId" | "transactionId">) {
    return new SurveyAlert({
      id: v4(),
      tenantId: params.tenantId,
      transactionId: params.transactionId,
      isCompleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public done(employeeId: string, now: Date) {
    return new SurveyAlert({
      ...this,
      isCompleted: true,
      operatedEmployeeId: employeeId,
      updatedAt: now,
    });
  }
}
