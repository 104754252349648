import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";

import { Icon, Typography } from "~/components/uiParts";
import { CustomOnboardingExperienceTaskTemplate } from "~/hooks/onboardingExperienceTaskTemplate";
import logoSquare from "~/images/logo-square.svg";

type Props = {
  template: CustomOnboardingExperienceTaskTemplate;
};

export const Type: FC<Props> = React.memo(({ template }) => {
  const icon: JSX.Element = useMemo(() => {
    switch (template.type) {
      case "MESSAGE":
        return <Icon size="md" icon="speechBalloon" color="lightGrey" />;
      case "REGULAR_SURVEY":
        return <Icon size="md" icon="survey" color="lightGrey" />;
      case "IRREGULAR_SURVEY":
        return <Icon size="md" icon="list" color="lightGrey" />;
    }
  }, [template]);

  return (
    <Box display="flex" gridGap="16px" alignItems="center">
      {icon}
      <Typography variant="body1" color="textSecondary" noWrap>
        {template.title}
      </Typography>
      {template.isDefault && <img height={24} width={24} src={logoSquare} alt="Onnアイコン" />}
    </Box>
  );
});
