import { Box, DialogContent } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useState, FC, useCallback } from "react";
import styled from "styled-components";

import { Button, UserIcon, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  employee: Employee;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
};

export const ConfirmChangeRoleToAdminModal: FC<Props> = ({
  open,
  employee,
  onCancel,
  onSubmit,
}) => {
  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit()
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [onCancel, onSubmit]);

  return (
    <Modal
      open={open}
      title="権限変更"
      content={
        <DialogContent>
          <Box mb={3} textAlign="center">
            <UserIcon
              username={employee.getName()}
              size="medium"
              profileIconImageUrl={employee.profileIconImageUrl}
              borderColor="primary"
            />
            <Typography variant="body2" bold noWrap>
              {employee.getName().trim() || employee.email}
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography variant="body1" color="textSecondary">
              オンボーディングを終了し、管理者権限に変更しますか？
            </Typography>
            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません。 <br />
              権限を変更すると、この入社者のアンケート回答などの情報は閲覧できなくなります。
            </Typography>
          </Box>
        </DialogContent>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={sending}
          >
            変更する
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
