import { Box } from "@material-ui/core";
import {
  OnboardingExperienceGeneralTask,
  GeneralTaskSettings,
  OnboardingExperienceTaskType,
  OnboardingExperienceTaskFactory,
  OnboardingExperienceSimpleTask,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { Fragment, useCallback, useEffect, useState, FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { NotFound } from "../NotFound";

import { useViewModel, StepType } from "./useViewModel";

import {
  OnboardingExperienceGeneralProcessItem,
  OnboardingExperienceProcessItem,
} from "~/components/domains/onboardingExperience";
import { useGetEditableEmployees } from "~/components/domains/onboardingExperience/hooks";
import {
  Button,
  DnDProvider,
  DragToScrollArea,
  Icon,
  Loading,
  Paper,
  TextField,
  Tooltip,
  TutorialCard,
  Typography,
  UserIconGroup,
} from "~/components/uiParts";
import {
  useAllEmployees,
  useAllEmployeesWithDeleted,
  useCurrentUser,
  useDepartmentAdmins,
  useAdmins,
} from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOpenPortalOnboardingTaskPreview } from "~/hooks/openPortalPreview";
import { useLocalStorage } from "~/hooks/shared";
import { useSlackUsers } from "~/hooks/slackUser";
import { useTenant } from "~/hooks/tenant";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { useUpdateGeneralTaskSettings } from "~/hooks/tenantSetting/useUpdateGeneralTaskSettings";
import { mixin } from "~/util";

// 上部のborder部分
const BORDER_HEIGHT = 85;
// title のペーパー部分
const TITLE_PAPER_HEIGHT = 88;

export const OnboardingExperienceEdit: FC = () => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const { slackUsers = [] } = useSlackUsers();
  const { allEmployees } = useAllEmployees();
  const { id: onboardingExperienceId } = useParams<"id">();
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const { data: allEmployeesWithDeleted = [] } = useAllEmployeesWithDeleted(currentUser.tenantId);
  const { data: admins = [] } = useAdmins(currentUser.tenantId);
  const { data: departmentAdmins = [] } = useDepartmentAdmins(currentUser.tenantId);
  const { tenantSettings } = useTenantSettings();

  const { getEditableEmployees } = useGetEditableEmployees();

  const { storeValue } = useLocalStorage();

  const {
    deliveryDateExperienceTaskGroupsMap,
    isValidatingOnboardingExperience,
    newOnboardingExperience,
    updateExperience,
    upsertTask,
    deleteTask,
    submitOnboardingExperience,
    isLoadingUpdateOnboardingExperience,
    completedSteps,
    setCompletedSteps,
    changeTasksOrderByDnD,
  } = useViewModel(onboardingExperienceId);
  const [generalTaskSettings, setGeneralTaskSettings] = useState<GeneralTaskSettings>();
  useEffect(() => {
    if (tenantSettings) {
      setGeneralTaskSettings(tenantSettings.generalTask);
    }
  }, [tenantSettings]);
  const { updateGeneralTaskSettings } = useUpdateGeneralTaskSettings();

  const tutorialItems = [
    {
      text: "オンボーディングエクスペリエンスとは？：詳しく知る",
      isCompleted: completedSteps.includes(StepType.READ_PROCESS_DETAIL_STEP),
      onClick: () =>
        openNotionPage("https://www.notion.so/workside/30e6821b767e44919a8521c3ef811c7a"),
    },
    {
      text: "タスクを追加する",
      isCompleted: completedSteps.includes(StepType.CREATE_TASK_STEP),
      onClick: () => handleOpenEditTaskModal(),
    },
  ];

  const [isShowTutorialCard, setIsShowTutorialCard] = useState(true);

  const onboardingExperienceGeneralTasksForNewHire: OnboardingExperienceGeneralTask[] = useMemo(
    () =>
      OnboardingExperienceGeneralTask.getNewHireTasks(tenant.tenantId, tenantSettings.generalTask),
    [tenant.tenantId, tenantSettings.generalTask]
  );

  const onboardingExperienceGeneralTasksForAdmin: OnboardingExperienceGeneralTask[] = useMemo(
    () =>
      OnboardingExperienceGeneralTask.createTasksByActionIds(
        tenant.tenantId,
        tenantSettings.generalTask
      ).filter((v) => v.assigneeRole !== "NEW_HIRE"),
    [tenantSettings.generalTask, tenant.tenantId]
  );

  const openNotionPage = useCallback(
    (url: string) => {
      window.open(url, "_blank", "noopener noreferrer");
      if (!completedSteps.includes(StepType.READ_PROCESS_DETAIL_STEP)) {
        setCompletedSteps((prev) => [...prev, StepType.READ_PROCESS_DETAIL_STEP]);
        storeValue(StepType.READ_PROCESS_DETAIL_STEP, true);
      }
    },
    [completedSteps, storeValue, setCompletedSteps]
  );

  /**
   * プレビューモードで /portal を開く
   */
  const openPortalPreview = useOpenPortalOnboardingTaskPreview();
  const handleClickOpenPreviewButton = useCallback(() => {
    const tasks = Object.values(deliveryDateExperienceTaskGroupsMap).flatMap((displayTiming) =>
      displayTiming.map((v) => v.tasks).flat()
    );
    openPortalPreview(
      tasks.filter((v): v is OnboardingExperienceSimpleTask => v.type === "SIMPLE_TASK"),
      "/portal/onboarding_tasks?preview=true"
    );
  }, [deliveryDateExperienceTaskGroupsMap, openPortalPreview]);

  const [processPaperDomRectsMap, setProcessPaperDomRectsMap] = useState<{
    [processId: string]: DOMRect;
  }>({});
  // 線の表示のためにDragToScrollAreaがどれくらいスクロールしたかを保持する
  const [scrollSize, setScrollSize] = useState<{ scrollTop: number; scrollLeft: number }>();

  const handleOpenViewGeneralProcessTaskModal = (task: OnboardingExperienceGeneralTask) => {
    handleModal({
      name: "viewOnboardingExperienceGeneralTaskModal",
      args: {
        task,
      },
    });
  };

  const handleOpenEditOnboardingExperienceGeneralProcessAssigneeModal = useCallback(() => {
    generalTaskSettings &&
      handleModal({
        name: "editOnboardingExperienceGeneralProcessAssigneeModal",
        args: {
          generalTaskSettings,
          selectableEmployees: [...admins, ...departmentAdmins],
          onUpsert: (newGeneralTaskSettings: GeneralTaskSettings) => {
            updateGeneralTaskSettings(newGeneralTaskSettings);
            setGeneralTaskSettings(newGeneralTaskSettings);
          },
        },
      });
  }, [departmentAdmins, generalTaskSettings, handleModal, admins, updateGeneralTaskSettings]);

  const isEditableExperience = useMemo(
    () =>
      !!newOnboardingExperience &&
      getEditableEmployees(newOnboardingExperience, allEmployees, admins, currentUser).some(
        (v) => v.id === currentUser.id
      ),
    [allEmployees, currentUser, getEditableEmployees, newOnboardingExperience, admins]
  );

  const handleOpenEditTaskModal = useCallback(
    (task?: OnboardingExperienceTaskType, associateTasks?: OnboardingExperienceTaskType[]) => {
      handleModal({
        name: "editOnboardingExperienceTaskModal",
        args: {
          task,
          associateTasks,
          onboardingExperienceId: onboardingExperienceId || "",
          onUpsertTask: (newTask: OnboardingExperienceTaskType) => upsertTask(newTask),
          onDeleteTask: (task: OnboardingExperienceTaskType) => deleteTask(task),
          editable: isEditableExperience,
          allEmployeesWithDeleted,
        },
      });
    },
    [
      handleModal,
      onboardingExperienceId,
      isEditableExperience,
      upsertTask,
      deleteTask,
      allEmployeesWithDeleted,
    ]
  );

  const measureRef = useCallback(
    (node: HTMLDivElement, processId: string) => {
      if (node) {
        const paperDomRect = processPaperDomRectsMap[processId];
        const domRect = node.getBoundingClientRect();
        // 高さが変わっていたときのみ更新する
        if (paperDomRect?.top !== domRect.top || paperDomRect?.bottom !== domRect.bottom) {
          setProcessPaperDomRectsMap((prevState) => {
            return { ...prevState, [processId]: domRect };
          });
        }
      }
    },
    [processPaperDomRectsMap]
  );

  const addTaskButton = useMemo(
    () => (
      <Button
        variant="outlined"
        borderRadius="circle"
        color="primary"
        onClick={() => handleOpenEditTaskModal()}
      >
        + タスクを追加
      </Button>
    ),
    [handleOpenEditTaskModal]
  );

  if (isValidatingOnboardingExperience) {
    return <Loading size="large" fullHeight />;
  }

  if (!newOnboardingExperience) {
    return <NotFound />;
  }

  return (
    <>
      <StyledBox position="relative">
        <DragToScrollArea onScroll={(scrollSize) => setScrollSize(scrollSize)}>
          <Box width="100%" position="relative" pb="80px">
            <StyledTitlePaper>
              <TextField
                defaultValue={newOnboardingExperience.title}
                variant="standard"
                fullWidth
                inputProps={{ readOnly: !isEditableExperience }}
                onChange={(e) => updateExperience({ title: e.target.value })}
              />
              <Box width="240px">
                <UserIconGroup
                  max={4}
                  tooltip
                  usersInfo={getEditableEmployees(
                    newOnboardingExperience,
                    allEmployees,
                    admins,
                    currentUser
                  ).map((employee) => ({
                    username: employee.getName(),
                    profileIconImageUrl: employee.profileIconImageUrl,
                  }))}
                />
              </Box>
            </StyledTitlePaper>
            {isEditableExperience && (
              <StyledAddTaskButtonWrapper>{addTaskButton}</StyledAddTaskButtonWrapper>
            )}
            <Box
              display="inline-flex"
              justifyContent="center"
              position="relative"
              mt="100px"
              pl="40px"
              pr="500px" // プロセス1つ分余白を持つ
              minWidth="100%"
            >
              <StyledBorder width="100%" position="absolute" top="76px" left="0" />
              {/* 新卒向けテナントでは共通タスクを表示しない */}
              {!tenant.isActiveNewGraduate && (
                <Box pt="40px" width="560px" position="relative">
                  <Tooltip arrow placement="right" title="通知タイミング">
                    <Typography variant="body1">入社者招待</Typography>
                  </Tooltip>
                  <Box>
                    <StyledCircle />
                  </Box>
                  <>
                    {[
                      onboardingExperienceGeneralTasksForAdmin,
                      onboardingExperienceGeneralTasksForNewHire,
                    ].map((taskGroup, index) => {
                      const id = `generalTask-${index}`;
                      const processPaperDomRect = processPaperDomRectsMap[id];
                      return (
                        <div key={id}>
                          {processPaperDomRect && (
                            <StyledConnector
                              position="absolute"
                              top={BORDER_HEIGHT}
                              left="9px"
                              height={
                                processPaperDomRect.top +
                                // topはビューポートからの距離なので縦方向にスクロールした分を足す
                                (scrollSize?.scrollTop || 0) +
                                processPaperDomRect.height / 2 -
                                BORDER_HEIGHT -
                                TITLE_PAPER_HEIGHT
                              }
                              width="20px"
                            />
                          )}
                          <Box key={id} mt="40px" px="30px">
                            <OnboardingExperienceGeneralProcessItem
                              generalTasks={taskGroup}
                              assigneeEmployees={[...admins, ...departmentAdmins].filter((e) =>
                                generalTaskSettings?.assigneeIds.includes(e.id)
                              )}
                              slackUsers={slackUsers}
                              allEmployeesWithDeleted={allEmployeesWithDeleted}
                              editable={isEditableExperience}
                              onClickViewTaskButton={handleOpenViewGeneralProcessTaskModal}
                              onClickChangeAssigneeButton={
                                handleOpenEditOnboardingExperienceGeneralProcessAssigneeModal
                              }
                              ref={(node: HTMLDivElement) => measureRef(node, id)}
                            />
                          </Box>
                        </div>
                      );
                    })}
                  </>
                </Box>
              )}
              {isEmpty(Object.values(deliveryDateExperienceTaskGroupsMap)) ? (
                <Box display="flex" justifyContent="center" position="relative" zIndex={1}>
                  <Box pt="40px" width="500px" zIndex={1}>
                    <Typography variant="body1">入社当日</Typography>
                    <Box>
                      <StyledCircle />
                    </Box>
                    {isEditableExperience && (
                      <StyledButtonWrapper mt="40px">
                        <Button
                          fullWidth
                          borderRadius="regular"
                          color="default"
                          onClick={() => handleOpenEditTaskModal()}
                          variant="outlined"
                        >
                          + タスクを追加
                        </Button>
                      </StyledButtonWrapper>
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  <DnDProvider onDragEnd={changeTasksOrderByDnD}>
                    {Object.entries(deliveryDateExperienceTaskGroupsMap).map(
                      ([displayTiming, taskGroups], index) => {
                        return (
                          <Box key={index} pt="40px" width="560px" position="relative">
                            <Tooltip arrow placement="right" title="通知タイミング">
                              <Typography variant="body1">{displayTiming}</Typography>
                            </Tooltip>
                            <Box>
                              <StyledCircle />
                            </Box>

                            {taskGroups.map((taskGroup, index) => {
                              const paperDomRect = processPaperDomRectsMap[taskGroup.id];
                              const assigneeEmployees = taskGroup.assigneeIds.flatMap(
                                (id) => allEmployeesWithDeleted.find((v) => v.id === id) || []
                              );

                              return (
                                <Fragment key={taskGroup.id}>
                                  {paperDomRect && (
                                    <StyledConnector
                                      position="absolute"
                                      top={BORDER_HEIGHT}
                                      left="9px"
                                      height={
                                        paperDomRect.top +
                                        // topはビューポートからの距離なので縦方向にスクロールした分を足す
                                        (scrollSize?.scrollTop || 0) +
                                        paperDomRect.height / 2 -
                                        BORDER_HEIGHT -
                                        TITLE_PAPER_HEIGHT
                                      }
                                      width="20px"
                                    />
                                  )}
                                  <Box key={index} mt="40px" px="30px">
                                    <OnboardingExperienceProcessItem
                                      tasks={taskGroup.tasks}
                                      assigneeRole={taskGroup.assigneeRole}
                                      assigneeEmployees={assigneeEmployees}
                                      slackUsers={slackUsers}
                                      allEmployeesWithDeleted={allEmployeesWithDeleted}
                                      editable={isEditableExperience}
                                      onClickEditTaskButton={(task) =>
                                        handleOpenEditTaskModal(
                                          task,
                                          taskGroups.flatMap(
                                            (group) =>
                                              group.tasks.find(
                                                (groupTask) =>
                                                  groupTask.type === "MESSAGE_TASK" &&
                                                  task.type === "MESSAGE_TASK" &&
                                                  groupTask.associationId === task.associationId
                                              ) || []
                                          )
                                        )
                                      }
                                      onClickDeleteTaskButton={deleteTask}
                                      onClickAddTaskButton={() => {
                                        const newTask = {
                                          ...(taskGroup.tasks[
                                            taskGroup.tasks.length - 1
                                          ] as (typeof taskGroup.tasks)[number]),
                                          id: undefined,
                                          title: "",
                                          body: "",
                                          filePaths: [],
                                        };
                                        if (newTask.type === "MESSAGE_TASK") {
                                          newTask.templateId = "";
                                        }
                                        handleOpenEditTaskModal(
                                          OnboardingExperienceTaskFactory.createOnboardingTask(
                                            newTask
                                          )
                                        );
                                      }}
                                      ref={(node: HTMLDivElement) => measureRef(node, taskGroup.id)}
                                    />
                                  </Box>
                                </Fragment>
                              );
                            })}
                          </Box>
                        );
                      }
                    )}
                  </DnDProvider>
                </>
              )}
            </Box>
          </Box>
        </DragToScrollArea>
        {isEditableExperience && (
          <>
            <Box position="absolute" right="24px" bottom="24px" zIndex={1}>
              {isShowTutorialCard && !completedSteps.includes(StepType.CREATE_TASK_STEP) && (
                <TutorialCard
                  title={`自社に最適化された\n最高の入社体験を構築しましょう！`}
                  tutorialItems={tutorialItems}
                  // チュートリアルの完了度合いに関わらず、「閉じる」ボタンを押すと一時的にチュートリアルを非表示にする
                  onClose={() => setIsShowTutorialCard(false)}
                />
              )}
            </Box>
          </>
        )}
      </StyledBox>
      <StyledFooter
        position="fixed"
        gridGap="16px"
        padding="16px 24px"
        bottom={0}
        left={0}
        width="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {isEditableExperience && (
          <>
            <Button
              variant="text"
              borderRadius="regular"
              color="primary"
              onClick={handleClickOpenPreviewButton}
              disabled={isLoadingUpdateOnboardingExperience}
            >
              <Icon size="md" icon="eye" color="primary" />
              <Typography variant="button">
                {tenant.isActiveNewGraduate ? "候補者" : "入社者"}画面のプレビュー
              </Typography>
            </Button>
            <Button
              variant="text"
              borderRadius="regular"
              color="default"
              onClick={() => navigate("/tools#onboarding_experiences")}
              disabled={isLoadingUpdateOnboardingExperience}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={() =>
                submitOnboardingExperience(newOnboardingExperience.id, newOnboardingExperience)
              }
              disabled={
                isLoadingUpdateOnboardingExperience || !newOnboardingExperience.title.trim()
              }
            >
              保存
            </Button>
          </>
        )}
      </StyledFooter>
    </>
  );
};

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledButtonWrapper = styled(Box)`
  /* ボタンにスタイルを当てるためのワークアラウンド */
  .MuiButtonBase-root > div {
    color: ${(props) => props.theme.palette.grey[200]};
    border: ${(props) => `2px dashed ${props.theme.palette.grey[200]}`};
    font-size: 14px;
    border-radius: 10px;
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
      border: ${(props) => `2px dashed ${props.theme.palette.primary.main}`};
    }
  }
  .MuiTouchRipple-root {
    border-radius: 10px;
  }
`;

const FOOTER_HEIGHT = 78;

const StyledBorder = styled(Box)`
  height: 2px;
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledBox = styled(Box)`
  /* ビルダー内部のテキストは選択させないようにする */
  user-select: none;
  position: relative;
  height: calc(100vh - ${FOOTER_HEIGHT}px);
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledTitlePaper = styled(Paper)`
  display: flex;
  grid-gap: 40px;
  margin-left: 24px;
  max-width: 676px;
  padding: 16px 24px;
  position: fixed;
  z-index: 999;

  ${mixin.pc`
    top: 24px;
  `}

  ${mixin.sp`
    margin-top: 24px
  `}
`;

const StyledAddTaskButtonWrapper = styled(Box)`
  position: fixed;
  right: 24px;
  z-index: 999;
  .MuiButtonBase-root {
    box-shadow: ${(props) => props.theme.shadows[10]};
  }

  ${mixin.pc`
    top: 35px;
  `}

  ${mixin.sp`
    margin-top: 35px
  `}
`;

const StyledFooter = styled(Box)`
  height: ${FOOTER_HEIGHT}px;
  background-color: white;
  border-top: solid 1px ${(props) => props.theme.palette.grey[100]};
`;

const StyledConnector = styled(Box)`
  border-bottom-left-radius: 10px;
  border-left: solid 2px ${(props) => props.theme.palette.grey[200]};
  border-bottom: solid 2px ${(props) => props.theme.palette.grey[200]};
`;
