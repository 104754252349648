import { TemplateContentsType } from "../Template/TemplateContentsType";
import { SendTargetType } from "../Transaction/Transaction";
import { LangType } from "../shared/LangType";

import { Question } from "./Question";

export enum TimingUnit {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export class SurveyTiming {
  unit: TimingUnit;
  value: number;
  constructor(unit: TimingUnit, value: number) {
    this.unit = unit;
    this.value = value;
  }
  static plainToInstance(init: DeepExcludeMethods<SurveyTiming>): SurveyTiming {
    return new SurveyTiming(init.unit, init.value);
  }
}

export enum SurveyType {
  NONE = "NONE",
  JOINED = "JOINED",
  ONBOARDING = "ONBOARDING",
}

export class Survey {
  tenantId: string;
  id: string;
  title: string;
  subtitle?: string;
  description: string;
  answerTime: number; // 使用されてない？
  questions: Question[];
  timing: number; // アンケートの場合は、surveyTimingsが利用される
  remindTiming: number; // リマインドのタイミング（日数）
  disabled: boolean;
  surveyTimings: SurveyTiming[]; // アンケートのタイミング
  totalSection: number;
  type: SurveyType;
  sendTargetType: SendTargetType; // 送信先タイプ
  contentsType: TemplateContentsType; // テンプレートのタイプ
  sendWeekday: number; // 配信曜日 0(Sun)~6(Sat)
  // sendHour: number; // 配信時間 6時~21時
  lang: LangType; // どの言語向けのSurveyかを示す
  constructor(init: {
    tenantId: string;
    id: string;
    title: string;
    subtitle?: string;
    description: string;
    answerTime: number;
    questions: Question[];
    timing: number;
    remindTiming: number;
    disabled: boolean;
    surveyTimings: SurveyTiming[];
    totalSection: number;
    type: SurveyType;
    sendTargetType: SendTargetType;
    contentsType: TemplateContentsType;
    sendWeekday: number;
    // sendHour: number
    lang: LangType;
  }) {
    this.tenantId = init.tenantId;
    this.id = init.id;
    this.title = init.title;
    this.subtitle = init.subtitle;
    this.description = init.description;
    this.answerTime = init.answerTime;
    this.questions = init.questions;
    this.timing = init.timing;
    this.remindTiming = init.remindTiming;
    this.disabled = init.disabled;
    this.surveyTimings = init.surveyTimings;
    this.totalSection = init.totalSection;
    this.type = init.type;
    this.sendTargetType = init.sendTargetType;
    this.contentsType = init.contentsType;
    this.sendWeekday = init.sendWeekday;
    // this.sendHour = init.sendHour;
    this.lang = init.lang;
  }

  static plainToInstance(init: DeepExcludeMethods<Survey>): Survey {
    return new Survey({
      ...init,
      questions: init.questions.map((q) => Question.plainToInstance(q)),
      surveyTimings: init.surveyTimings.map((st) => SurveyTiming.plainToInstance(st)),
    });
  }
}
