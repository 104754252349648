import { Employee, Department, OnboardingTask, OnboardingTaskWithNewHire } from "@onn/common";

import { DepartmentRepository } from "~/infrastructure/api/departmentRepository";
import { EmployeeRepository } from "~/infrastructure/api/employeeRepository";
import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";

const onboardingTaskRepository = new OnboardingTaskRepository();
const employeeRepository = new EmployeeRepository();
const departmentRepository = new DepartmentRepository();

export class FindOnboardingTaskByTenantIdUseCase {
  async execute(tenantId: string, currentUser: Employee): Promise<OnboardingTaskWithNewHire[]> {
    const [onboardingTasks, newHires, departments] = await Promise.all([
      onboardingTaskRepository.findByTenantId(tenantId),
      employeeRepository.findAllNewcomers(),
      departmentRepository.findByTenantId(currentUser.tenantId),
    ]);

    return newHires.flatMap((newHire: Employee) => {
      const newHireOnboardingTasks = onboardingTasks.filter(
        (onboardingTask) => onboardingTask.employeeId === newHire.id
      );

      if (
        !this.getIsAllowGetOnboardingTasks(
          newHireOnboardingTasks,
          newHire,
          currentUser,
          departments
        )
      )
        return [];

      return newHireOnboardingTasks.map((v: OnboardingTaskWithNewHire) => {
        v.newHire = newHire;
        return v;
      });
    });
  }

  /**
   * onboardingTask を閲覧できるかを判定する関数
   * @param onboardingTasks
   * @param newHire
   * @param currentUser
   * @returns
   */
  private getIsAllowGetOnboardingTasks(
    onboardingTasks: OnboardingTask[],
    newHire: Employee,
    currentUser: Employee,
    departments: Department[]
  ) {
    // 管理者はすべて閲覧可能
    if (currentUser.isAdmin()) {
      return true;
    }
    // 部門管理者は、自身が管理する部署の入社者であれば閲覧可能
    else if (
      currentUser.isDepartmentAdmin() &&
      Department.getChildIds(currentUser.departmentIds, departments).some((id) =>
        newHire.departmentIds.includes(id)
      )
    ) {
      return true;
    }
    // メンバーの場合、バディやサポートメンバーであれば閲覧可能
    else if (
      newHire.mentorUserId === currentUser.id ||
      newHire.supportMemberEmployeeIds?.includes(currentUser.id)
    ) {
      return true;
    }

    // それ以外の場合、バイネームでアサインされているタスクが一つでもあれば閲覧可能
    return onboardingTasks.some((onboardingTask) =>
      onboardingTask.assigneeIds.includes(currentUser.id)
    );
  }
}
