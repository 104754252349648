import { APISchema, OnboardingExperience, OnboardingExperienceWithProcesses } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

/**
 * tenantIdをもとにonboardingExperienceを返すswr
 * @param tenantId tenantのid
 */
export const useOnboardingExperienceByTenantId = (tenantId: string) => {
  const keys = tenantId ? ["onboardingExperience", tenantId] : null;

  return useSWR<APISchema["/onboardingexperiencesapi"]["GET"]["response"]["data"]>(
    keys,
    async () => {
      const response = await apiClient.get("/onboardingexperiencesapi");
      return response.data.map((v) => {
        const onboardingExperience = new OnboardingExperience(v);
        const onboardingExperienceWithProcesses =
          onboardingExperience as OnboardingExperienceWithProcesses;
        onboardingExperienceWithProcesses.tasks = v.tasks;
        return onboardingExperienceWithProcesses;
      });
    }
  );
};
