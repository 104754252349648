import { Box } from "@material-ui/core";
import { format, isBefore, isPast, isSameDay } from "date-fns";
import { isEmpty } from "lodash";
import React, { useMemo, FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Loading, Paper, Typography } from "~/components/uiParts";
import { useAllNewcomers, useCurrentUser } from "~/hooks/employee";
import { useOnboardingTasks } from "~/hooks/onboardingTask/useOnboardingTasks";

export const TaskProgressPaper: FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { data: onboardingTasks = [], isValidating: isValidatingOnboardingTasks } =
    useOnboardingTasks(currentUser.tenantId);
  const { data: allNewcomers = [], isValidating: isValidatingAllNewcomers } = useAllNewcomers();

  const expiredOnboardingTasks = useMemo(() => {
    return onboardingTasks.filter((v) => {
      if (v.status === "COMPLETED") {
        return false;
      }

      return v.newHire && v.isExpiredByEmployee(v.newHire);
    });
  }, [onboardingTasks]);

  // 次回入社日
  const upcomingDate: Date | null = useMemo(() => {
    let tempDate: Date | null = null;

    // 入社者の中から直近の入社日を取得する
    allNewcomers.forEach((newcomer) => {
      if (!newcomer.joinAt) return;
      const joinAtDate = new Date(newcomer.joinAt);

      if (isPast(joinAtDate)) return;
      if (!tempDate || isBefore(joinAtDate, tempDate)) {
        tempDate = joinAtDate;
      }
    });

    return tempDate;
  }, [allNewcomers]);

  const upcomingExpiredOnboardingTasks = useMemo(() => {
    if (!upcomingDate) return [];
    return expiredOnboardingTasks.filter((v) => {
      if (!v.newHire?.joinAt) return false;
      return isSameDay(new Date(v.newHire.joinAt), upcomingDate);
    });
  }, [expiredOnboardingTasks, upcomingDate]);

  return (
    <StyledPaper square>
      <Typography align="center" bold>
        タスク進捗
      </Typography>
      {isValidatingOnboardingTasks || isValidatingAllNewcomers ? (
        <Box mt="40px">
          <Loading size="small" />
        </Box>
      ) : (
        <>
          <Box display="flex" mt="56px" justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              width="50%"
              justifyContent="center"
              gridRowGap="32px"
              alignItems="center"
            >
              <Button
                variant="text"
                borderRadius="regular"
                color={isEmpty(expiredOnboardingTasks) ? "default" : "secondary"}
              >
                <Typography
                  bold
                  variant="h2"
                  onClick={() => navigate("/tasks?selectedStatus=EXPIRED")}
                >
                  {expiredOnboardingTasks.length}
                </Typography>
              </Button>
              <Box display="flex" flexDirection="column">
                <Typography align="center" variant="body2">
                  期限切れ
                </Typography>
                <Typography align="center" variant="body2">
                  （全体）
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width="50%"
              justifyContent="center"
              gridRowGap="32px"
              alignItems="center"
            >
              {upcomingDate ? (
                <Button
                  variant="text"
                  borderRadius="regular"
                  color={isEmpty(upcomingExpiredOnboardingTasks) ? "default" : "secondary"}
                >
                  <Typography
                    bold
                    variant="h2"
                    onClick={() =>
                      navigate(
                        `/tasks?selectedStatus=EXPIRED&joinAtStrings=${format(
                          upcomingDate,
                          "yyyy-MM-dd"
                        )}`
                      )
                    }
                  >
                    {upcomingExpiredOnboardingTasks.length}
                  </Typography>
                </Button>
              ) : (
                <Typography align="center" variant="h4">
                  該当タスクなし
                </Typography>
              )}
              <Box display="flex" flexDirection="column">
                <Typography align="center" variant="body2">
                  期限切れ
                </Typography>
                <Typography align="center" variant="body2">
                  （次回入社者：{upcomingDate ? format(upcomingDate, "M/d") : "該当者なし"}）
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;
