import React, { FC } from "react";

export const ChevronDown: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.971 16.971">
      <path
        id="Union_53"
        d="M0,12V0H2.4V9.6H12V12Z"
        transform="translate(0 8.485) rotate(-45)"
        stroke="none"
      />
    </svg>
  );
};
