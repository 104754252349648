import { Box } from "@material-ui/core";
import React, { FC, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Loading, Button, Paper, Typography } from "~/components/uiParts";
import { useCurrentUserNonGuarded } from "~/hooks/employee";
import { useOnboardingTask } from "~/hooks/onboardingTask";
import { NotFound } from "~/pages/NotFound";
import { mixin } from "~/util";

export const MessageTaskLanding: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUserNonGuarded();
  const { id: taskId, contentId } = useParams<"id" | "contentId">();
  const { data: onboardingTaskData, isValidating: isValidatingOnboardingTaskData } =
    useOnboardingTask(taskId);

  const isGuest = contentId === "guest";

  const messageContent = useMemo(() => {
    const task = onboardingTaskData?.onboardingTask;
    if (isGuest || task?.type !== "MESSAGE_TASK") return null;
    return task.contents.find((content) => content.id === contentId);
  }, [onboardingTaskData?.onboardingTask, isGuest, contentId]);

  /**
   * ログインユーザーが担当者の場合、メッセージ編集画面へリダイレクトする
   */
  const onClickWriteMessage = useCallback(() => {
    if (!onboardingTaskData || onboardingTaskData.onboardingTask.type !== "MESSAGE_TASK") return;
    const guestLink = `/messages/${taskId}/guest/edit`;
    // ユーザーがログインしていない場合はゲスト用のページに遷移する
    if (!currentUser) {
      navigate(guestLink);
      return;
    }

    const requestedContent = onboardingTaskData.onboardingTask?.contents.find(
      (content) => content.requestedEmployeeId === currentUser.id
    );
    if (requestedContent) {
      navigate(`/messages/${taskId}/${requestedContent.id}/edit`);
    } else {
      navigate(guestLink);
    }
  }, [onboardingTaskData, taskId, currentUser, navigate]);

  useEffect(() => {
    if (messageContent?.isCompleted) {
      navigate(`/messages/${taskId}/${messageContent.id}/complete`);
    }
  }, [navigate, messageContent, taskId]);

  if (isValidatingOnboardingTaskData) {
    return <Loading size="large" fullHeight />;
  }

  if (!onboardingTaskData || !onboardingTaskData.employee) {
    return <NotFound />;
  }

  if (!isGuest && !messageContent) {
    return <NotFound />;
  }

  if (onboardingTaskData.onboardingTask.type !== "MESSAGE_TASK") {
    return <NotFound />;
  }

  if (!onboardingTaskData.onboardingTask.isEditableMessageContent(onboardingTaskData.employee)) {
    return <NotFound />;
  }

  return (
    <StyledContainer maxWidth="800px">
      <StyledTitle variant="h2" color="textPrimary">
        {onboardingTaskData.onboardingTask.title}
      </StyledTitle>
      <StyledPaper paddingLarge>
        <Typography variant="body2">
          {onboardingTaskData.onboardingTask.getBodyTextReplacedWithNewHire(
            onboardingTaskData.employee.getName()
          )}
          {`\n`}
          メッセージは、
          {onboardingTaskData.onboardingTask.dueDate.getDisplayTextByEmployee(
            onboardingTaskData.employee,
            "yyyy/MM/dd h時"
          )}{" "}
          に{onboardingTaskData.employee.getName()}
          さんへと送られます。
        </Typography>
        <Box textAlign="center" mt={5}>
          <StyledButtonContainer>
            <Button
              fullWidth
              borderRadius="circle"
              variant="contained"
              color="primary"
              onClick={onClickWriteMessage}
            >
              メッセージを書く
            </Button>
          </StyledButtonContainer>
          <Box height="24px" />
          <Typography variant="caption" color="primary">
            回答期間：
            {onboardingTaskData.onboardingTask.dueDate.getDisplayTextByEmployee(
              onboardingTaskData.employee,
              "yyyy/MM/dd h時"
            )}
            まで
          </Typography>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledTitle = styled(Typography)`
  padding-bottom: 32px;

  ${mixin.breakDown.sm`
    padding: 40px 24px 32px;
  `}
`;

const StyledPaper = styled(Paper)`
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;

  ${mixin.breakDown.sm`
    &.MuiPaper-root {
      box-shadow: none;
      border-radius: 0;
    }
  `}
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
