import { IEmployeeActiveLogSchemaSchema } from "./schema";

/*
 * READ_MESSAGE // トップメッセージを確認したとき
 * REGISTERED_PROFILE // 自己紹介を設定したとき
 * VISITED_LIBRARY_CONTENT // ライブラリのコンテンツを開いたとき
 * VISITED_ONN_EVENT_LANDING_PAGE // イベントページの扉ページを開いたとき
 * VISITED_ONN_EVENT_FORM_PAGE // イベントページの編集ページを開いたとき
 * VISITED_ONN_EVENT_THANKS_PAGE // イベントページの回答完了ページを開いたとき
 * */
export const EMPLOYEE_ACTION_TYPE = {
  READ_MESSAGE: "READ_MESSAGE",
  REGISTERED_PROFILE: "REGISTERED_PROFILE",
  VISITED_LIBRARY_CONTENT: "VISITED_LIBRARY_CONTENT",
  VISITED_ONN_EVENT_LANDING_PAGE: "VISITED_ONN_EVENT_LANDING_PAGE",
  VISITED_ONN_EVENT_FORM_PAGE: "VISITED_ONN_EVENT_FORM_PAGE",
  VISITED_ONN_EVENT_THANKS_PAGE: "VISITED_ONN_EVENT_THANKS_PAGE",
  // ANSWERED_SURVEY: "ANSWERED_SURVEY",
} as const;

export class EmployeeActiveLog implements IEmployeeActiveLogSchemaSchema {
  id: string;
  employeeId: string;
  type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE];
  createdAt: Date;
  tenantId: string;
  targetId?: string;
  constructor({
    id,
    employeeId,
    type,
    createdAt,
    targetId,
    tenantId,
  }: {
    id: string;
    employeeId: string;
    type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE];
    createdAt: Date;
    tenantId: string;
    targetId?: string;
  }) {
    this.id = id;
    this.employeeId = employeeId;
    this.type = type;
    this.createdAt = createdAt;
    this.tenantId = tenantId;
    this.targetId = targetId;
  }
}
