import { Box, Tooltip } from "@material-ui/core";
import { fileTypeMatchers } from "@onn/common";
import React, { useMemo, FC, useCallback } from "react";
import styled from "styled-components";

import { ActionButton } from "./ActionButton";

import { Typography } from "~/components/uiParts/Typography";
import DownloadIcon from "~/images/icons/download/white_48x48.svg";
import FileIcon from "~/images/icons/file/grey_48x48.svg";
import VisibilityIcon from "~/images/icons/visibility/white_48x48.svg";
import { getNameAndExtensionFromFilePath } from "~/util";

type Props = {
  src: string;
  fileName: string;
  filePath: string;
  isDisplayActionContainer: boolean;
  onPreviewFile: () => void;
  onDownloadFile: (url: string) => void;
  onDeleteFile?: (filePath: string) => void;
};

type FileType = {
  previewable: boolean;
  name: string;
  extension: string;
};

export const FileThumbnail: FC<Props> = ({
  src,
  fileName,
  filePath,
  isDisplayActionContainer,
  onPreviewFile,
  onDownloadFile,
  onDeleteFile,
}) => {
  const fileType = useMemo<FileType>(() => {
    const { name, extension } = getNameAndExtensionFromFilePath(fileName);

    return {
      previewable: !!fileName.match(fileTypeMatchers.image),
      name,
      extension: `.${extension}`,
    };
  }, [fileName]);

  const handleDeleteFile = useCallback(
    (filePath: string) => onDeleteFile && onDeleteFile(filePath),
    [onDeleteFile]
  );

  const handleDownloadFile = useCallback(() => {
    onDownloadFile(src);
  }, [onDownloadFile, src]);

  return (
    <StyledFigure $isDisplayActionContainer={isDisplayActionContainer}>
      {fileType.previewable ? (
        <Tooltip title={`${fileType.name}.${fileType.extension}`}>
          <StyledImageContainerPreviewable onClick={onPreviewFile}>
            <StyledImage src={src} alt="thumbnail" />
          </StyledImageContainerPreviewable>
        </Tooltip>
      ) : (
        <StyledImageContainerNotPreviewable onClick={handleDownloadFile}>
          <StyledPlaceholderImage />
        </StyledImageContainerNotPreviewable>
      )}
      <Box p={1}>
        {!fileType.previewable && (
          <StyledFigCaption>
            <Typography display="block" variant="caption" noWrap>
              {fileType.name}
            </Typography>
            <Typography display="block" variant="caption">
              {fileType.extension}
            </Typography>
          </StyledFigCaption>
        )}

        {isDisplayActionContainer && (
          <StyledActionContainer
            mt={1}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
          >
            <ActionButton actionType="download" onClick={handleDownloadFile} />
            {onDeleteFile && (
              <>
                <StyledDivider />
                <ActionButton actionType="delete" onClick={() => handleDeleteFile(filePath)} />
              </>
            )}
          </StyledActionContainer>
        )}
      </Box>
    </StyledFigure>
  );
};

const StyledFigure = styled.figure<{ $isDisplayActionContainer: boolean }>`
  ${(props) =>
    props.$isDisplayActionContainer &&
    `
    &:hover {
      box-shadow: ${props.theme.shadows[2]};
      border-radius: 4px;
    }
      `}
`;

const StyledImageContainerPreviewable = styled.button`
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: ${(2 / 3) * 100}%; // アスペクト比が3:2になるように
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  cursor: pointer;
  outline: none;
  touch-action: manipulation;
  user-select: none;
  background-color: transparent;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: background-color 0.3s ease;
  }

  ${StyledFigure}:hover & {
    border-radius: 4px 4px 0px 0px;

    ::after {
      background-color: rgba(0, 0, 0, 0.4);
      background-image: url(${VisibilityIcon});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;

const StyledImageContainerNotPreviewable = styled(StyledImageContainerPreviewable)`
  ${StyledFigure}:hover & {
    ::after {
      background-image: url(${DownloadIcon});
    }
  }
`;

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: contain;
`;

const StyledPlaceholderImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(${FileIcon});
  background-position: center;
  background-repeat: no-repeat;
`;

const StyledFigCaption = styled.figcaption`
  display: flex;
  justify-content: center;
`;

const StyledActionContainer = styled(Box)`
  width: 100%;

  ${StyledFigure}:not(:hover) & {
    display: none;
  }
`;

const StyledDivider = styled.span`
  width: 1px;
  height: 16px;
  background-color: ${(props) => props.theme.palette.grey[200]};
  align-self: center;
`;
