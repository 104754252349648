import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";

import { Button, Typography, Modal, UserIcon, TextField } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (
    employeeId: string,
    { lastName, firstName }: { lastName: string; firstName: string }
  ) => Promise<void>;
  employee: Employee;
};

export const ChangeEmployeeNameModal: FC<Props> = ({ open, onCancel, onSubmit, employee }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");

  const invalidForm = useMemo(() => {
    return !lastName.trim() || !firstName.trim();
  }, [firstName, lastName]);

  useEffect(() => {
    // uidが存在しないかつfirstNameが存在しない場合はフォームをブランクにする
    if (employee.uid || employee.firstName) {
      setLastName(employee.lastName);
      setFirstName(employee.firstName);
    } else {
      setLastName("");
      setFirstName("");
    }
  }, [employee.firstName, employee.lastName, employee.uid]);

  const handleSubmit = useCallback(async () => {
    setIsProcessing(true);
    await onSubmit(employee.id, { lastName, firstName })
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [employee.id, firstName, lastName, onCancel, onSubmit]);

  const Content: JSX.Element = (
    <Box>
      <Box textAlign="center">
        <UserIcon
          profileIconImageUrl={employee.profileIconImageUrl}
          size="medium"
          username={employee.getName()}
        />
        <Box pt="8px">
          {/* uidが存在しないかつfirstNameが存在しない場合は登録前なので表示しない */}
          {(employee.uid || employee.firstName) && (
            <Typography variant="body2" bold noWrap>
              {employee.getName()}
            </Typography>
          )}
          <Typography variant="caption" noWrap color="textSecondary">
            {employee.email}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" gridGap="16px" justifyContent="center" pt={4}>
        <StyledTextField
          label="姓"
          name="lastName"
          value={lastName}
          variant="outlined"
          onChange={(e) => setLastName(e.target.value)}
        />
        <StyledTextField
          label="名"
          name="firstName"
          value={firstName}
          variant="outlined"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Box>
    </Box>
  );

  const Footer: JSX.Element = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isProcessing || invalidForm}
      >
        保存
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="氏名変更" content={Content} footer={Footer} onCancel={onCancel} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledTextField = styled(TextField)`
  width: 33%;
`;
