import { Box, MenuItem, Menu } from "@material-ui/core";
import { ContactRoom, Employee } from "@onn/common";
import { format } from "date-fns";
import React, { FC, useMemo, ReactNode, useState, useCallback, MouseEvent } from "react";
import styled from "styled-components";

import {
  Button,
  Chip,
  Divider,
  Icon,
  Loading,
  Modal,
  Typography,
  UserIcon,
} from "~/components/uiParts";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useUpdateIsCompleted } from "~/hooks/contactRoom/useUpdateIsCompleted";
import { useEmployee, useEmployees } from "~/hooks/employee";
import { useTenant } from "~/hooks/tenant";

type SectionProps = {
  label: string;
  children: ReactNode;
  captionText?: string;
};
const Section = (props: SectionProps): JSX.Element => {
  const { label, children, captionText } = props;
  return (
    <Box>
      <Box marginBottom="16px">
        <Typography variant="caption" color="textPrimary" bold>
          {label}
        </Typography>
        {captionText && (
          <Typography variant="caption" color="textSecondary">
            {captionText}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};

type IconListProps = {
  employees: Employee[];
};
const IconList = ({ employees }: IconListProps): JSX.Element => {
  return (
    // NOTE: スクロールバーと重なってしまうため padding-bottom を設定
    <Box display="flex" gridGap="4px" flexWrap="wrap" paddingBottom="8px">
      {employees.map((employee) => (
        <UserIcon
          key={employee.id}
          circular
          size="small"
          username={employee ? employee.getName() : "未登録"}
          profileIconImageUrl={employee?.profileIconImageUrl}
          borderColor="primary"
        />
      ))}
    </Box>
  );
};

type ContactStatusMenuProps = {
  contactRoom: ContactRoom;
  updateIsCompleted: ReturnType<typeof useUpdateIsCompleted>["updateIsCompleted"];
};
const ContactStatusMenu = ({
  contactRoom,
  updateIsCompleted,
}: ContactStatusMenuProps): JSX.Element => {
  const [anchorIsCompetedEl, setAnchorIsCompletedEl] = useState<HTMLElement | null>(null);
  const handleOpenIsCompletedMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorIsCompletedEl(event.currentTarget);
  }, []);
  const handleCloseIsCompletedMenu = useCallback(() => {
    setAnchorIsCompletedEl(null);
  }, []);

  return (
    <>
      <StyledChip
        color={contactRoom.isCompleted ? "grey" : "secondary"}
        label={contactRoom.isCompleted ? "対応済" : "未対応"}
        bold
        size="medium"
        clickable
        onClick={handleOpenIsCompletedMenu}
        icon={
          <Icon
            icon="dropdownArrow"
            color={contactRoom.isCompleted ? "darkGrey" : "white"}
            size="sm"
          />
        }
      />
      <Menu
        anchorEl={anchorIsCompetedEl}
        keepMounted
        open={!!anchorIsCompetedEl}
        onClose={handleCloseIsCompletedMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {contactRoom.isCompleted ? (
          <MenuItem
            onClick={() => {
              updateIsCompleted(contactRoom.id, false);
              handleCloseIsCompletedMenu();
            }}
          >
            <Typography variant="body2">未対応</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              updateIsCompleted(contactRoom.id, true);
              handleCloseIsCompletedMenu();
            }}
          >
            <Typography variant="body2">対応済み</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

type Props = {
  open: boolean;
  onCancel: () => void;
  employee: Employee;
  contactRoom: ContactRoom;
};
export const SPEmployeeDetailModal: FC<Props> = ({ open, onCancel, employee, contactRoom }) => {
  // テナント
  const { tenant } = useTenant();

  // 担当者・サポートメンバー
  const { data: mentor, isLoading: isLoadingMentor } = useEmployee(employee.mentorUserId || "");
  const { data: supportMembers, isLoading: isLoadingSupportMembers } = useEmployees(
    employee.supportMemberEmployeeIds || []
  );

  const { contactRoomsWithoutCurrentUser: contactRooms, contactRoomsMutate } = useContactContext();

  // 対応ステータス
  const { updateIsCompleted } = useUpdateIsCompleted(async (_) => {
    contactRoomsMutate();
  });

  // レンダリングに使用するコンタクトルーム
  const contactRoomForRender = useMemo(() => {
    return contactRooms.find((room) => room.id === contactRoom.id) ?? contactRoom;
  }, [contactRooms, contactRoom]);

  const Content = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridGap="32px">
        <Section label="入社日">
          <Typography>
            {employee.joinAt ? format(new Date(employee.joinAt), "yyyy/MM/dd") : "入社日未設定"}
          </Typography>
        </Section>
        <Section label={tenant.isActiveNewGraduate ? "担当者" : "バディ"}>
          {isLoadingMentor ? (
            <Loading size="small" />
          ) : mentor ? (
            <IconList employees={[mentor]} />
          ) : (
            <Typography>未設定</Typography>
          )}
        </Section>
        <Section label={tenant.isActiveNewGraduate ? "サポートメンバー" : "フォロワー"}>
          {isLoadingSupportMembers ? (
            <Loading size="small" />
          ) : supportMembers && supportMembers.length > 0 ? (
            <IconList employees={supportMembers} />
          ) : (
            <Typography>未設定</Typography>
          )}
        </Section>
        <Section label="対応ステータス">
          <ContactStatusMenu
            contactRoom={contactRoomForRender}
            updateIsCompleted={updateIsCompleted}
          />
        </Section>
        <Divider />
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={() => {
              window.open(`/employee/${employee.id}`, "_blank", "noopener noreferrer");
            }}
          >
            {tenant.isActiveNewGraduate ? "候補者" : "入社者"}詳細を見る
          </Button>
        </Box>
      </Box>
    ),
    [
      contactRoomForRender,
      employee.id,
      employee.joinAt,
      isLoadingMentor,
      isLoadingSupportMembers,
      mentor,
      supportMembers,
      tenant.isActiveNewGraduate,
      updateIsCompleted,
    ]
  );

  return (
    <StyledModal
      fullWidth
      fullScreen
      open={open}
      headerContent={
        <Typography variant="body1" bold>
          {tenant.isActiveNewGraduate ? "候補者" : "入社者"}情報
        </Typography>
      }
      content={Content}
      onCancel={onCancel}
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    margin: 0;
  }
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    > .MuiChip-icon {
      order: 1; // アイコンと文字列の表示順を入れ替える
      margin-left: -12px;
      margin-right: 4px;
    }
  }
`;
