import { Box } from "@material-ui/core";
import { fileTypeMatchers } from "@onn/common";
import React, { ComponentProps, useCallback, useMemo, useState, FC } from "react";
import styled from "styled-components";

import { Header } from "./Header";
import { PreviewImage } from "./PreviewImage";

import { Button } from "~/components/uiParts/Button";
import { IconButton } from "~/components/uiParts/IconButton";
import { Typography } from "~/components/uiParts/Typography";
import { useLockBodyScroll } from "~/hooks/shared/useLockBodyScroll";

type HeaderProps = ComponentProps<typeof Header>;

type Props = {
  src: string;
  fileName: string;
  filePath: string;
  pagingEnabled: boolean;
  onDownload: (url: string) => void;
  onDeleteFile?: (path: string) => void;
  onClickPrev: () => void;
  onClickNext: () => void;
} & Pick<HeaderProps, "onClose">;

// TODO: PDFに対応するときに "image" | "pdf" のようにする予定
type Previewable = { value: true; fileType: "image" } | { value: false };

const ZOOM_PERCENT_STEP_VALUE = 25;

const MIN_ZOOM_PERCENT_VALUE = 25;

const MAX_ZOOM_PERCENT_VALUE = 300;

export const FileViewer: FC<Props> = ({
  src,
  fileName,
  filePath,
  pagingEnabled,
  onClickNext,
  onClickPrev,
  onDownload,
  onDeleteFile,
  onClose,
}) => {
  const [zoomPercent, setZoomPercent] = useState(100);

  const previewable = useMemo<Previewable>(() => {
    if (fileTypeMatchers.image.test(fileName)) {
      return { value: true, fileType: "image" };
    }

    return { value: false };
  }, [fileName]);

  const handleZoomIn = useCallback(
    () => setZoomPercent((prev) => prev + ZOOM_PERCENT_STEP_VALUE),
    []
  );
  const handleZoomOut = useCallback(
    () => setZoomPercent((prev) => prev - ZOOM_PERCENT_STEP_VALUE),
    []
  );

  const handleDownloadFile = useCallback(() => onDownload(src), [onDownload, src]);

  const handleDeleteFile = useCallback(
    () => onDeleteFile && onDeleteFile(filePath),
    [onDeleteFile, filePath]
  );

  useLockBodyScroll(true);

  return (
    <StyledContainer>
      <Header
        fileName={fileName}
        previewable={previewable.value}
        zoomPercent={zoomPercent}
        zoomInDisabled={zoomPercent >= MAX_ZOOM_PERCENT_VALUE}
        zoomOutDisabled={zoomPercent <= MIN_ZOOM_PERCENT_VALUE}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onDownload={handleDownloadFile}
        onDeleteFile={onDeleteFile ? handleDeleteFile : undefined}
        onClose={onClose}
      />
      <StyledBody padding={pagingEnabled ? "0" : "0px 48px"}>
        {pagingEnabled && (
          <IconButton icon="chevronLeft" size="md" color="white" onClick={onClickPrev} />
        )}
        <StyledBodyInner>
          {previewable.value ? (
            <StyledViewer zoom={zoomPercent}>
              {previewable.fileType === "image" ? (
                <PreviewImage src={src} fileName={fileName} pagingEnabled={pagingEnabled} />
              ) : null}
            </StyledViewer>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <StyledNotPreviewAbleTypography variant="body1">
                このファイルはプレビューできません
              </StyledNotPreviewAbleTypography>
              <Box mt={2}>
                <Button
                  color="primary"
                  variant="contained"
                  borderRadius="regular"
                  onClick={handleDownloadFile}
                >
                  ダウンロード
                </Button>
              </Box>
            </Box>
          )}
        </StyledBodyInner>
        {pagingEnabled && (
          <IconButton icon="chevronRight" size="md" color="white" onClick={onClickNext} />
        )}
      </StyledBody>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => props.theme.zIndex.modal};
  background-color: rgba(29, 29, 29, 0.95);
`;

const StyledBody = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledBodyInner = styled(Box)`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledViewer = styled(Box)<{ zoom: number }>`
  display: flex;
  zoom: ${(props) => props.zoom}%;
`;

const StyledNotPreviewAbleTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;
