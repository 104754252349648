import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { TenantLogo } from "~/components/domains/tenant/tenantLogo";

import { Loading, Typography } from "~/components/uiParts";
import { useAuthentication } from "~/hooks/context";
import { useSigninToTargetTenant } from "~/hooks/employee";
import { useCurrentPortalAccessibleTenants } from "~/hooks/tenant";

export const SelectLoginTenant = () => {
  const { authUser } = useAuthentication();
  const { data: tenants, isLoading } = useCurrentPortalAccessibleTenants(authUser?.uid);
  const { signinToTargetTenant } = useSigninToTargetTenant();

  if (isLoading || !tenants || tenants?.length === 1) {
    <Loading size="large" />;
  }

  return (
    <Box width={"100%"} padding={"40px"}>
      {tenants?.length ? (
        <>
          <StyledTypography variant="h4">ログインする企業を選択する</StyledTypography>
          <Box display={"flex"} gridGap={"40px"} paddingX="16px">
            {tenants.map((tenant) => {
              return (
                <Box key={tenant.tenantId} onClick={() => signinToTargetTenant(tenant.tenantId)}>
                  <TenantLogo url={tenant.logoUrl} tenantName={tenant.tenantName} />
                </Box>
              );
            })}
          </Box>
        </>
      ) : (
        <Typography>ログイン可能な企業がありません。</Typography>
      )}
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  margin-bottom: 40px;
`;
