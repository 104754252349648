import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";

import { Typography, UserIcon } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  assigneeEmployees: Employee[];
};

// ====================
// main
// ====================

export const AssigneeSection: FC<Props> = ({ assigneeEmployees }) => {
  // ====================
  // main component
  // ====================

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="caption" bold color="textPrimary">
        担当者
      </Typography>
      <Box display="flex" gridGap="4px" flexWrap="wrap">
        {assigneeEmployees.map((assigneeEmployee) => (
          <UserIcon
            key={assigneeEmployee.id}
            username={assigneeEmployee.getName()}
            profileIconImageUrl={assigneeEmployee.profileIconImageUrl}
            size="small"
            circular
            hover
          />
        ))}
      </Box>
    </Box>
  );
};
