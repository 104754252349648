import { Employee, Department, Role } from "@onn/common";

export type OtherEmployee = {
  id: string;
  name: string;
  profileIconImageUrl?: string;
  deleted?: boolean;
  role: Role;
  departmentIds: string[];
};

/**
 * 入社者詳細にアクセス可能なEmployeeの一覧を計算する関数
 * 以下条件を満たすEmployeeがアクセス可能となる
 *
 * - 削除されていない
 * - ADMIN権限を持つ、もしくは該当の入社者の現在のバディまたはサポートメンバーである
 * - 入社者が所属する部署のDepartmentAdmin
 */
export const calcAccessibleEmployees: (params: {
  newHireEmployee?: Employee;
  otherEmployees: OtherEmployee[];
  departmentIds: string[];
  departments: Department[];
}) => OtherEmployee[] = ({ newHireEmployee, otherEmployees, departmentIds, departments }) => {
  if (!newHireEmployee) return [];

  return otherEmployees.filter((employee) => {
    if (employee.deleted) {
      return false;
    }

    return (
      // 管理者はすべての入社者詳細にアクセス可能
      employee.role === Role.ADMIN ||
      // バディ・サポートメンバーは入社者詳細にアクセス可能
      newHireEmployee.mentorUserId === employee.id ||
      (newHireEmployee.supportMemberEmployeeIds
        ? newHireEmployee.supportMemberEmployeeIds.includes(employee.id)
        : false) ||
      // 部門管理者と入社者の所属が一つでもかぶっている場合はアクセス可能(積集合が一つでも存在するとき)
      (Role.DEPARTMENT_ADMIN === employee.role &&
        Department.getChildIds(departmentIds, departments).some((id) =>
          newHireEmployee.departmentIds.includes(id)
        ))
    );
  });
};
