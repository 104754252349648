import { Employee, OnboardingMessageTask } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "../../../modal";
import { useInsertNewContentToOnboardingMessageTask } from "../useInsertNewContentToOnboardingMessageTask";

import { useGetCopyMessageTaskTextForShareURL } from "~/components/domains/onboardingTask/hooks";
import { useClipboard } from "~/hooks/shared";

export const useHandleAddRequestMessageTaskModal = () => {
  const { handleModal } = useModal();

  const { insertNewContentToOnboardingMessageTask } = useInsertNewContentToOnboardingMessageTask();

  const { handleCopy } = useClipboard();
  const { getCopyMessageTaskTextForShareURL } = useGetCopyMessageTaskTextForShareURL();

  /**
   * @param {Employee} newHire 入社者
   * @param {OnboardingMessageTask} onboardingMessageTask
   */
  const handleAddRequestMessageTaskModal = useCallback(
    async (
      onboardingMessageTask: OnboardingMessageTask,
      newHire: Employee,
      onSubmit: (onboardingMessageTask: OnboardingMessageTask) => void
    ) =>
      handleModal({
        name: "addRequestMessageTaskModal",
        args: {
          newHire,
          onboardingMessageTask,
          onSubmit: async (emails: string[]) => {
            await insertNewContentToOnboardingMessageTask(
              onboardingMessageTask.id,
              emails,
              newHire
            ).then((createdWelcomeMessage) => {
              // 処理が正常に完了したらWelcomeMessage管理モーダルを開く
              if (createdWelcomeMessage) {
                onSubmit(createdWelcomeMessage);
              }
            });
          },
          onClickCopyLinkButton: () => {
            handleCopy(getCopyMessageTaskTextForShareURL(newHire, onboardingMessageTask), "リンク");
          },
        },
      }),
    [
      getCopyMessageTaskTextForShareURL,
      handleCopy,
      handleModal,
      insertNewContentToOnboardingMessageTask,
    ]
  );

  return { handleAddRequestMessageTaskModal };
};
