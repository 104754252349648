import { SurveyTransaction } from "@onn/common/domain";
import useSWR, { SWRResponse } from "swr";

import { SurveyTransactionUseCase } from "~/service/usecases/transactionUseCase";

/**
 * employeeIdsをもとにsurveyTransactionsを返すswr
 * @param employeeIds employeeのidの配列
 */
export const useSurveyTransactionsByEmployeeIds = (
  employeeIds: string[]
): SWRResponse<SurveyTransaction[], Error> => {
  const keys = employeeIds ? [`survey-transactions-employeeIds`, employeeIds] : null;

  return useSWR(keys, () =>
    SurveyTransactionUseCase.whereByEmployeeIds(employeeIds).then((res) => res)
  );
};
