import { differenceInCalendarDays } from "date-fns";

/**
 * 現在時刻から何日前・何日後か表示し、当日は1日後を表示する
 * @param {Date} referenceDate
 * @param {string} text 何の当日なのか
 */
export const getDisplayDifferenceDate = (referenceDate: Date, text?: string) => {
  const now = new Date();
  const days = differenceInCalendarDays(referenceDate, now);

  return `${days >= 0 ? days + 1 : Math.abs(days)}日${days < 0 ? "前" : "後"}${
    days === 0 && text ? `(${text}当日)` : ""
  }`;
};
