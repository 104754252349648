import { Box } from "@material-ui/core";
import { Survey } from "@onn/common";
import React, { FC, useMemo } from "react";

import { Button } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import { SurveyTemplateUseCase } from "~/service/usecases/surveyTemplateUseCase";
import { captureException } from "~/util";

const surveyTemplateUseCase = new SurveyTemplateUseCase();

type Props = {
  survey: Survey;
};

export const SurveySide: FC<Props> = ({ survey }) => {
  const { enqueueSnackbar } = useSnackbar();

  // タイトルが空欄のものが存在する場合更新できない
  const hasError = useMemo(
    () =>
      survey.questions.some((v) => {
        return v.title.trim() === "";
      }),
    [survey]
  );

  const handleSubmit = async () => {
    try {
      await surveyTemplateUseCase.update(survey);
      enqueueSnackbar("変更を保存しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "SurveySide:handleSubmit" },
      });
    }
  };
  return (
    <Box position="sticky" top="40px">
      <Button
        variant="contained"
        borderRadius="circle"
        color="primary"
        fullWidth
        disabled={hasError}
        onClick={handleSubmit}
      >
        変更を保存
      </Button>
      <Box mt={2} />

      <Button
        borderRadius="circle"
        color="primary"
        variant="outlined"
        fullWidth
        onClick={() =>
          window.open(`/preview/surveys/${survey.id}`, "_blank", "noopener noreferrer")
        }
      >
        プレビュー
      </Button>
    </Box>
  );
};
