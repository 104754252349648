import { Employee } from "@onn/common";

import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";
import { factory } from "~/service/repository/iOnboardingExperienceTaskTemplateRepository";

const onboardingExperienceTaskTemplateRepository =
  factory.onboardingExperienceTaskTemplateRepository();
const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();

/**
 * タスクテンプレートを削除するUseCase
 */
export class DeleteOnboardingExperienceTaskTemplateUseCase {
  /**
   * タスクテンプレートを削除するUseCase
   * @param {string} onboardingExperienceTaskId
   */
  async execute(currentUser: Employee, onboardingExperienceTaskId: string): Promise<void> {
    const onboardingExperienceTask = await onboardingExperienceTaskTemplateRepository.findById(
      onboardingExperienceTaskId
    );

    if (!onboardingExperienceTask.isEditable(currentUser)) {
      throw new Error("タスクテンプレートを削除する権限がありません。");
    }

    const batch = queryOperator.batch();

    await onboardingExperienceTaskTemplateRepository.insertDeleteByIdInBatch(
      batch,
      onboardingExperienceTaskId
    );

    await onboardingExperienceTaskRepository.insertDeleteByTemplateIdInBatch(
      batch,
      onboardingExperienceTaskId
    );

    await queryOperator.commit(batch);
  }
}
