import { Box } from "@material-ui/core";
import { SurveyTransaction, Employee, AnswerItem, ItemType, Question } from "@onn/common";
import React, { FC } from "react";

import { SurveyResultItem } from "./SurveyResultItem";

import { Divider, Typography } from "~/components/uiParts";
import { useAccessControl } from "~/hooks/accessControl";

type RenderSurveyResultItemProps = {
  question: Question;
  resultContents: AnswerItem[];
  currentUser: Employee;
  newHire: Employee;
  templateId: string;
  pastSurveyTransactions: SurveyTransaction[];
  isFirstOfQuestionGroup?: boolean;
  isSurveyTab?: boolean;
  isShowAdminOnly?: boolean;
};

type Props = {
  surveyTransaction: SurveyTransaction;
  currentUser: Employee;
  newHire: Employee;
  surveyTransactions: SurveyTransaction[];
  isSurveyTab?: boolean;
  isShowAdminOnly?: boolean;
};

const isAdminGroup = (isShowAdminOnly: boolean, isEditable: boolean) =>
  isEditable || isShowAdminOnly;
const isOnboardingSurvey = (title: string) => title.includes("オンボーディングアンケート");

const RenderSurveyResultItem: FC<RenderSurveyResultItemProps> = ({
  question,
  resultContents,
  currentUser,
  newHire,
  templateId,
  pastSurveyTransactions,
  isFirstOfQuestionGroup = false,
  isSurveyTab = false,
  isShowAdminOnly = false,
}) => {
  const { isEditable } = useAccessControl();
  // hrOnly の場合回答を表示しない
  if (question.hrOnly && !isAdminGroup(isShowAdminOnly, isEditable(newHire))) {
    return null;
  }

  // TITLE でない時は answer が見つかるので SurveyResultItem を返す
  if (question.type !== ItemType.TITLE) {
    const answer = resultContents.find((resultContent) => {
      return resultContent.key === question.name;
    });
    return (
      <>
        <SurveyResultItem
          question={question}
          answerResult={answer}
          templateId={templateId}
          isFirstOfQuestionGroup={isFirstOfQuestionGroup}
          pastSurveyTransactions={pastSurveyTransactions}
          isSurveyTab={isSurveyTab}
        />
        <Box height="32px" />
      </>
    );
  }

  return (
    <>
      <Box display="flex">
        <Typography variant="body2" bold appendRequiredSymbol={question.required}>
          {question.title}
        </Typography>
      </Box>
      <Box height="16px" />
      {question.questions.map((question, index) => {
        return (
          <RenderSurveyResultItem
            key={`RenderSurveyResultItem__${index}`}
            question={question}
            resultContents={resultContents}
            currentUser={currentUser}
            newHire={newHire}
            templateId={templateId}
            pastSurveyTransactions={pastSurveyTransactions}
            isSurveyTab={isSurveyTab}
            isFirstOfQuestionGroup={index === 0}
          />
        );
      })}
    </>
  );
};

export const SurveyResult: FC<Props> = ({
  surveyTransaction,
  currentUser,
  newHire,
  surveyTransactions,
  isSurveyTab,
  isShowAdminOnly = false,
}) => {
  const { isEditable } = useAccessControl();

  const { contents, resultContents, templateId, id } = surveyTransaction;
  const { questions, title } = contents;

  const pastSurveyTransactions = surveyTransactions.filter((v) => {
    return v.sendAt < surveyTransaction.sendAt;
  });

  const section1Questions = questions.filter((q) => q.section === 1 || q.section === undefined); // section1(1ページ目)の設問(undefinedの場合もある..)
  const section2Questions = questions.filter((q) => q.section === 2); // section2(2ページ目)の設問

  return (
    <>
      {section1Questions.map((q1, i) => (
        <div key={`RenderSurveyResultItem-q1__${id}-${i}`}>
          <RenderSurveyResultItem
            question={q1}
            resultContents={resultContents}
            currentUser={currentUser}
            newHire={newHire}
            templateId={templateId}
            pastSurveyTransactions={pastSurveyTransactions}
            isSurveyTab={isSurveyTab}
            isShowAdminOnly={isShowAdminOnly}
          />
        </div>
      ))}

      {/* オンボーディングアンケートは2セクション目は必ずhrOnlyなのでオンボーディング and メンバー権限は弾く */}
      {(!isOnboardingSurvey(title) || isAdminGroup(isShowAdminOnly, isEditable(newHire))) && (
        <>
          <Divider margin={32} orientation="horizontal" />
          {section2Questions.map((q2, i) => (
            <div key={`RenderSurveyResultItem-q2__${id}-${i}`}>
              <RenderSurveyResultItem
                question={q2}
                resultContents={resultContents}
                currentUser={currentUser}
                newHire={newHire}
                templateId={templateId}
                pastSurveyTransactions={pastSurveyTransactions}
                isSurveyTab={isSurveyTab}
                isShowAdminOnly={isShowAdminOnly}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};
