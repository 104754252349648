import { OnboardingStatus } from "../../domain/Employee";

/**
 * OnboardingStatusesの中から最新のステータスを抽出します
 * @param {OnboardingStatus[]} targetStatuses 入社者のステータスの配列
 * @returns {OnboardingStatus} 最新のステータス
 */
export const extractLatestStatus = (targetStatuses: OnboardingStatus[]): OnboardingStatus => {
  const sampleStatuses = Object.keys(OnboardingStatus);
  const alignedStatuses: OnboardingStatus[] = [];

  sampleStatuses.forEach((sample) => {
    alignedStatuses.push(...targetStatuses.filter((target) => target === sample));
  });

  return alignedStatuses[alignedStatuses.length - 1] as OnboardingStatus;
};
