import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SurveyAnsweredTab } from "./SurveyAnsweredTab";
import { TaskListTab } from "./TaskListTab";

import { Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type TabValue = "onboardingTaskList" | "surveyAnswered";

export const Tasks: FC = () => {
  const [activeTab, setActiveTab] = useState<TabValue>("onboardingTaskList");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    switch (location.hash) {
      case "#onboardingTaskList": {
        setActiveTab("onboardingTaskList");
        break;
      }
      case "#surveyAnswered": {
        setActiveTab("surveyAnswered");
        break;
      }
      default: {
        setActiveTab("onboardingTaskList");
      }
    }
  }, [currentUser, location.hash]);

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: string }> = [
    {
      label: "リスト",
      value: "onboardingTaskList",
    },
    ...(currentUser.isAdmin() || currentUser.isDepartmentAdmin()
      ? [
          {
            label: "回答結果",
            value: "surveyAnswered",
          },
        ]
      : []),
  ];

  return (
    <>
      <Box display="inline" pr={2}>
        <Typography variant="h4" bold display="inline">
          タスク
        </Typography>
      </Box>

      <Box>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <StyledTabPanel value="onboardingTaskList">
            <TaskListTab />
          </StyledTabPanel>
          <StyledTabPanel value="surveyAnswered">
            <SurveyAnsweredTab />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
