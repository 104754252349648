import { OnboardingTaskType } from "@onn/common";
import { useState, useCallback } from "react";
import { useSWRConfig } from "swr";

import { useSnackbar } from "../shared/useSnackbar";

import { generateUseOnboardingTask } from "./useOnboardingTask";

import { UpdateOnboardingTaskUseCase } from "~/service/usecases/onboardingTask";
import { captureException } from "~/util";

const updateOnboardingTaskUseCase = new UpdateOnboardingTaskUseCase();

/**
 * onboardingTaskを更新するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns updateOnboardingTask
 */
export const useUpdateOnboardingTask = (): {
  isLoading: boolean;
  updateOnboardingTask: (
    onboardingTaskId: string,
    newObject: Partial<OnboardingTaskType>,
    emailsWithoutOnnAccount: string[],
    feedbackText?: string
  ) => Promise<OnboardingTaskType | null>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();

  const updateOnboardingTask = useCallback(
    async (
      onboardingTaskId: string,
      newObject: Partial<OnboardingTaskType>,
      emailsWithoutOnnAccount: string[],
      feedbackText = "オンボーディングタスクを更新しました"
    ): Promise<OnboardingTaskType | null> => {
      setIsLoading(true);
      return await updateOnboardingTaskUseCase
        .execute(onboardingTaskId, newObject, emailsWithoutOnnAccount)
        .then((result) => {
          enqueueSnackbar(feedbackText, { variant: "success" });
          mutate(generateUseOnboardingTask(onboardingTaskId));
          return result;
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useUpdateOnboardingTask:updateOnboardingTask",
            },
          });
          return null;
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, mutate]
  );

  return { isLoading, updateOnboardingTask };
};
