import { Survey } from "@onn/common";

import { factory } from "../repository/iTemplateRepository";

const templateRepository = factory.templateRepository();

export class SurveyTemplateUseCase {
  async find(tenantId: string, surveyId: string): Promise<Survey> {
    return templateRepository.findSurvey(tenantId, surveyId);
  }
  async findAll(tenantId: string): Promise<Survey[]> {
    return templateRepository.findSurveys(tenantId);
  }
  async update(survey: Survey): Promise<void> {
    return templateRepository.updateSurvey(survey);
  }
}
