import { Box } from "@material-ui/core";
import { Employee, OnboardingTaskType } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { OnboardingTaskMemoForm } from "./OnboardingTaskMemoForm";
import { OnboardingTaskMemoItem } from "./OnboardingTaskMemoItem";

import { Typography } from "~/components/uiParts";
import { useAllEmployeesWithDeleted } from "~/hooks/employee";
import { useUpdateOnboardingTaskMemo } from "~/hooks/onboardingTask/useUpdateOnboardingTaskMemo";

type Props = {
  currentUser: Employee;
  onboardingTask: OnboardingTaskType;
  mutateOnboardingTasks: () => void;
};

export const OnboardingTaskMemos: FC<Props> = ({
  currentUser,
  onboardingTask,
  mutateOnboardingTasks,
}) => {
  const { data: allEmployeesWithDeleted = [] } = useAllEmployeesWithDeleted(currentUser.tenantId);
  const { updateOnboardingTaskMemo } = useUpdateOnboardingTaskMemo();

  const handleUpdateTaskMemo = useCallback(
    async (newOnboardingTaskId: string, text: string, employeeId: string) => {
      await updateOnboardingTaskMemo(newOnboardingTaskId, text, employeeId);
      mutateOnboardingTasks();
    },
    [updateOnboardingTaskMemo, mutateOnboardingTasks]
  );

  return (
    <>
      <StyledBox>
        <Box display="flex" flexDirection="column" gridGap="24px" px="24px" py="32px">
          <StyledTypography variant="caption" color="textPrimary">
            {/* システム上は "memo" という命名だが、ユーザーには "コメント" として表記する */}
            コメントは受け入れチームにのみ共有され、入社者には共有されません。
          </StyledTypography>

          {onboardingTask.memos.map((memo) => {
            const author = allEmployeesWithDeleted.find((v) => v.id === memo.createdEmployeeId);
            if (!author) return null;

            return <OnboardingTaskMemoItem key={memo.id} memo={memo} author={author} />;
          })}
        </Box>
      </StyledBox>

      <Box p="24px" position="absolute" bottom="0" width="100%">
        <OnboardingTaskMemoForm
          onSubmit={async (value) => {
            await handleUpdateTaskMemo(onboardingTask.id, value, currentUser.id);
          }}
        />
      </Box>
    </>
  );
};

const HEADER_HEIGHT = 154;
const FOOTER_HEIGHT = 148;

const StyledBox = styled(Box)`
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
  overflow-y: auto;
`;

const StyledTypography = styled(Typography)`
  padding: 16px;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;
