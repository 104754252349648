import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, FC } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../hooks";

import { Tooltip, Typography, UserIcon, UserIconGroup } from "~/components/uiParts";

type Props = {
  mentorId?: string;
  newHire: Employee;
  supportMembers: Employee[];
  isEditable: boolean;
  onClickSupportMembers: () => void;
};

export const EmployeeSupportMembers: FC<Props> = ({
  newHire,
  supportMembers,
  isEditable,
  onClickSupportMembers,
}) => {
  const { getBorderColorById } = useGetBorderColorById();
  const isClickable = useMemo(
    () => isEditable || !isEmpty(supportMembers),
    [isEditable, supportMembers]
  );
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClickSupportMembers();
    },
    [onClickSupportMembers]
  );
  const usersInfo = useMemo<
    {
      username: string;
      profileIconImageUrl?: string;
      borderColor?: "primary" | "blue";
    }[]
  >(
    () =>
      supportMembers.map((sm) => ({
        username: sm.getName(),
        profileIconImageUrl: sm.profileIconImageUrl,
        borderColor: getBorderColorById(newHire, sm.id),
      })),
    [supportMembers, getBorderColorById, newHire]
  );

  if (isEmpty(supportMembers)) {
    return (
      <Tooltip arrow placement="bottom" title={isEditable ? "サポートメンバーを設定する" : ""}>
        <StyledClickableBox
          display="flex"
          alignItems="center"
          onClick={handleClick}
          $isClickable={isClickable}
        >
          <UserIcon circular size="small" username="未登録" badgeType="dot" />
          <Box display="inline-block" width="8px" />
          <Typography variant="caption" color="textSecondary" display="block">
            未登録
          </Typography>
        </StyledClickableBox>
      </Tooltip>
    );
  } else {
    return (
      <StyledClickableBox
        display="flex"
        alignItems="center"
        onClick={handleClick}
        $isClickable={isClickable}
      >
        <UserIconGroup usersInfo={usersInfo} tooltip max={4} />
      </StyledClickableBox>
    );
  }
};

const StyledClickableBox = styled(Box)<{ $isClickable: boolean }>`
  ${({ $isClickable }) => ($isClickable ? `cursor : pointer;` : `cursor: default;`)}
  width: 100%;
  height: 40px;
`;
