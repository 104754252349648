import { ErrorType, ErrorStatus } from "@onn/common";
import { useCallback } from "react";

export const useGetErrorMessage = () => {
  const getErrorMessage = useCallback(
    (
      errorStatus: ErrorStatus,
      options: { isCsv: boolean; inputRowSize: number; rowIndex?: number }
    ): string => {
      const { isCsv, inputRowSize, rowIndex } = options;
      const errorMessages: string[] = [];
      errorStatus.forEach((rows, errorType) => {
        if (typeof rowIndex === "number" && !rows.includes(rowIndex)) return;

        const errorRowNumbers = rows.map((v) => v + 1).toString();

        if (isCsv && errorType === ErrorType.OVER_THE_LIMIT) {
          errorMessages.push(
            `※ ${inputRowSize}件のデータが入力(空白行を含む)されています。一度あたりの最大登録数は100件です`
          );
        }

        if (isCsv && errorType === ErrorType.NO_EMAIL_ADDRESS) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「メールアドレス(必須)」が入力されていません。また、空白行の場合は削除してください`
          );
        }

        if (errorType === ErrorType.DUPLICATED_EMAIL_ADDRESS_IN_CSV) {
          errorMessages.push(
            `※ ${
              isCsv ? `${errorRowNumbers}行目の「メールアドレス」` : "メールアドレス"
            }が重複しています。どちらかを削除してください`
          );
        }

        if (errorType === ErrorType.INVALID_EMAIL_ADDRESS) {
          errorMessages.push(
            `${
              isCsv ? `※ ${errorRowNumbers}行目の「メールアドレス」` : "メールアドレス"
            } の形式が間違っています`
          );
        }

        if (errorType === ErrorType.ALREADY_REGISTERED_EMAIL_ADDRESS) {
          errorMessages.push(
            `${
              isCsv ? `※ ${errorRowNumbers}行目の「メールアドレス」` : "このメールアドレス"
            }はすでに招待済みです`
          );
        }

        if (errorType === ErrorType.ALREADY_OTHER_TENANT_REGISTERED_EMAIL_ADDRESS) {
          errorMessages.push(
            `${
              isCsv ? `※ ${errorRowNumbers}行目の「メールアドレス」` : "このメールアドレス"
            }は招待できません。Onnサポートチームまでお問い合わせください`
          );
        }

        if (isCsv && errorType === ErrorType.INVALID_JOIN_AT) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「入社日」を指定のフォーマット(yyyymmdd)に沿って入力し直してください`
          );
        }

        if (isCsv && errorType === ErrorType.NO_EXISTING_DEPARTMENT_NAME) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「部署」が間違っています。サンプルファイルをダウンロードし、コピーしてください`
          );
        }

        if (isCsv && errorType === ErrorType.DEPARTMENT_NAME_WITH_HALF_SLASH) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「部署」が間違っています。半角の「/」ではなく全角の「／」を入力してください`
          );
        }

        if (isCsv && errorType === ErrorType.DUPLICATED_DEPARTMENT_NAME) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「部署」に同一の部署が複数設定されています`
          );
        }

        if (isCsv && errorType === ErrorType.NO_EXISTING_TAG_NAME) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「タグ」が間違っています。サンプルファイルをダウンロードし、コピーしてください`
          );
        }

        if (isCsv && errorType === ErrorType.TAG_NAME_WITH_HALF_SLASH) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「タグ」が間違っています。半角の「/」ではなく全角の「／」を入力してください`
          );
        }

        if (isCsv && errorType === ErrorType.DUPLICATED_TAG_NAME) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「タグ」に同一のタグが複数設定されています`
          );
        }

        if (isCsv && errorType === ErrorType.NO_EXISTING_ONBOARDING_EXPERIENCE_TITLE) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「体験」が間違っています。サンプルファイルをダウンロードし、コピーしてください`
          );
        }

        if (isCsv && errorType === ErrorType.DUPLICATED_ONBOARDING_EXPERIENCE_TITLE) {
          errorMessages.push(
            `※ ${errorRowNumbers}行目の「体験」に同一の体験が複数設定されています`
          );
        }
      });

      return errorMessages.join("\n");
    },
    []
  );

  return { getErrorMessage };
};
