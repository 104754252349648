import { Employee, OnboardingTaskType } from "@onn/common";
import { useCallback } from "react";

/**
 * タスクのRoleを元にemployeesを返すhooksを提供する
 */
export const useGetEmployeesByAssigneeRole = () => {
  /**
   * タスクのRoleを元にemployeesを返す
   * @param {OnboardingTask} OnboardingTaskType
   * @param {Employee} newHire
   * @param {Employee[]} specificEmployees
   * @param {Employee[]} supportMembers
   * @param {Employee} mentor
   * @param {Employee[]} admins
   */
  const getEmployeesByAssigneeRole = useCallback(
    ({
      onboardingTask,
      newHire,
      specificEmployees,
      supportMembers,
      mentor,
      admins,
    }: {
      onboardingTask: OnboardingTaskType;
      newHire?: Employee;
      specificEmployees: Employee[];
      supportMembers: Employee[];
      mentor?: Employee;
      admins: Employee[];
    }) => {
      if (onboardingTask.assigneeRole === "NEW_HIRE") return newHire ? [newHire] : [];
      if (onboardingTask.assigneeRole === "BY_NAME") return specificEmployees;
      if (onboardingTask.assigneeRole === "SUPPORT_MEMBER") return supportMembers;
      if (onboardingTask.assigneeRole === "MENTOR") return mentor ? [mentor] : [];
      if (onboardingTask.assigneeRole === "ADMIN") return admins;
      return [];
    },
    []
  );
  return { getEmployeesByAssigneeRole };
};
