import { Department, Employee } from "@onn/common";
import React, { FC, useMemo } from "react";

import { UserSummaryTooltip } from "../../employees";

import { Typography } from "~/components/uiParts";

type Props = {
  userName: string;
  newHire?: Employee;
  departments: Department[];
  shouldDisplayTooltip: boolean;
  shouldDisplayContactRoomButton: boolean;
};

export const UserName: FC<Props> = React.memo(
  ({ userName, newHire, departments, shouldDisplayTooltip, shouldDisplayContactRoomButton }) => {
    const userNameView = useMemo(
      () => (
        <Typography variant="caption" color="textSecondary" bold>
          {userName}
        </Typography>
      ),
      [userName]
    );

    return shouldDisplayTooltip && newHire ? (
      <UserSummaryTooltip
        employee={newHire}
        departments={departments}
        shouldDisplayContactRoomButton={shouldDisplayContactRoomButton}
      >
        {userNameView}
      </UserSummaryTooltip>
    ) : (
      <>{userNameView}</>
    );
  }
);
