import { Failure, Result, Success } from "@onn/common";
import { isEmpty, zip } from "lodash";

import { useCallback } from "react";

import { InputValue } from "./inputValueType";

/**
 * ダブルクォーテーションで囲まれたvalueを変換するための関数。
 * CSVを扱う環境によっては、値がダブルクォーテーションで囲まれてしまうため。
 * "test" -> test
 * @param input
 * @returns
 */
const removeQuotes = (input: string): string => {
  if (input.startsWith('"') && input.endsWith('"')) {
    return input.substring(1, input.length - 1);
  }
  return input;
};

const mapLabelToInputValueProp = (label: string | undefined): keyof InputValue | undefined => {
  switch (label) {
    case "姓":
      return "lastName";
    case "名":
      return "firstName";
    case "メールアドレス":
      return "email";
    case "入社日":
      return "joinAt";
    case "タグ":
      return "tagNames";
    case "体験":
      return "onboardingExperienceTitles";
    case "イベント":
      return "onnEventTitles";
    case "部署":
      return "departmentNames";
    default:
      return undefined;
  }
};

const setValueToInputValues = (
  inputValues: InputValue[],
  prop: keyof InputValue,
  value: string | undefined,
  index: number
): InputValue[] => {
  if (value == null || isEmpty(value)) return inputValues;
  switch (prop) {
    case "lastName":
    case "firstName":
    case "email":
    case "joinAt":
      (inputValues[index] as (typeof inputValues)[number])[prop] = value;
      break;
    case "tagNames":
    case "onboardingExperienceTitles":
    case "onnEventTitles":
    case "departmentNames":
      (inputValues[index] as (typeof inputValues)[number])[prop].push(value);
      break;
    default:
      break;
  }
  return inputValues;
};

export const useParseFile = () => {
  const parseFile = useCallback((result: string): Result<InputValue[], Error> => {
    const regex = new RegExp(/\r\n|\r|\n/);
    const tmp = result.trimEnd().split(regex); // TODO: 終端だけでなく全ての空行を無視して計算できるとよい
    const loadedInputResult = tmp.map((row) => row.split(",").map((v) => removeQuotes(v)));

    // カラムでグルーピングする
    const zipped = zip(...loadedInputResult);

    // 参照渡しをしないために、新しいオブジェクトを作成する
    let inputValues: InputValue[] = Array.from({ length: tmp.length - 1 }, () =>
      Object.assign(
        {},
        {
          departmentNames: [],
          tagNames: [],
          onboardingExperienceTitles: [],
          onnEventTitles: [],
        }
      )
    );

    const invalidLabels = zipped.flatMap(([label, ...values]) => {
      const prop = mapLabelToInputValueProp(label);
      if (prop === undefined) return label;
      values.forEach((value, index) => {
        inputValues = setValueToInputValues(inputValues, prop, value, index);
      });
      return [];
    });

    if (invalidLabels.length > 0) {
      const typoLabels = invalidLabels
        .filter((label): label is string => label != null && label !== "")
        .map((label) => `「${label}」`);
      const typoLabelText = `${typoLabels.join("、")}という存在しないヘッダー`;
      const numberOfUndefinedLabels = invalidLabels.length - typoLabels.length;
      const numberOfUndefinedLabelsText = `${numberOfUndefinedLabels}件の空のヘッダー`;

      if (typoLabels.length > 0 && numberOfUndefinedLabels > 0) {
        return new Failure(
          new Error(
            `${typoLabelText}、及び${numberOfUndefinedLabelsText}があります。サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`
          )
        );
      }

      if (typoLabels.length > 0) {
        return new Failure(
          new Error(
            `${typoLabelText}があります。サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`
          )
        );
      }

      if (numberOfUndefinedLabels > 0) {
        return new Failure(
          new Error(
            `${numberOfUndefinedLabelsText}があります。サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`
          )
        );
      }
    }

    return new Success(inputValues);
  }, []);
  return { parseFile };
};
