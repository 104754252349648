import { Department } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { DepartmentUseCase } from "~/service/usecases/departmentUseCase";

const departmentUseCase = new DepartmentUseCase();

/**
 * tenantIdをもとにテナントに紐づいたdepartmentを返すswr
 * @param {string} tenantId
 * @return department[]
 */
export const useDepartmentsByTenantId = (tenantId: string): SWRResponse<Department[], Error> => {
  const key = tenantId ? ["department", tenantId] : null;

  return useSWR(key, ([_key, tenantId]: [string, string]) =>
    departmentUseCase.findByTenantId(tenantId).then((res) => res)
  );
};
