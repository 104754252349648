import React, { ReactNode } from "react";
import { SWRConfig } from "swr";

import { useSnackbar } from "~/hooks/shared";

import { ApiResponseError } from "~/util";
import { CustomConsole } from "~/util/initializeCustomConsole";
import { NetworkError, captureException } from "~/util/loggerUtil";

export const SWRConfigWrapper = ({ children }: { children: ReactNode }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        onError: (error: Error | ApiResponseError | NetworkError, key) => {
          if (process.env.NODE_ENV === "localhost") {
            // ローカルでの開発時にデバックしやすいようにエラーをログに出す
            console.error(error);
          }
          // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
          if (error instanceof ApiResponseError) {
            captureException({
              error,
              tags: { type: key },
              extras: { error },
            });
          } else if (error instanceof NetworkError) {
            enqueueSnackbar("通信エラーが発生しました。ネットワークの接続を確認してください", {
              variant: "error",
            });
            return;
          } else {
            captureException({
              error: new Error("[アプリケーションエラー]" + error.message),
              tags: { type: key },
              extras: {
                history: JSON.stringify((console as CustomConsole).history),
                error,
                onLine: navigator.onLine,
              },
            });
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
