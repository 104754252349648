/*
  完了通知対象がユーザに直接かSlackのチャネルかどうか
*/
export enum TransactionStatus {
  SENT = "SENT",
  DONE = "DONE",
}

export type Memo = {
  title: string;
  text: string;
  createdEmployeeId: string;
};

export enum TransactionDataType {
  PROFILE_COMMENT = "PROFILE_COMMENT", // 自己紹介登録
  MENTOR_REGISTRATION = "MENTOR_REGISTRATION", // バディ設定
  JOIN_DATE_INPUT = "JOIN_DATE_INPUT", // 入社日付登録
  WELCOME = "WELCOME", // 入社
  SURVEY = "SURVEY", // アンケート
  ADD_MEMO = "ADD_MEMO", // メモ追加
}

/*
  アンケートやナッジなどの送信対象がユーザに直接かSlackのチャネルかどうか
*/
export enum SendTargetType {
  USER = "USER",
  SLACK = "SLACK",
}

export class Transaction {
  id: string;
  dataType: TransactionDataType; // トランザクションデータのタイプ
  contents: Record<string, unknown>;
  remindDate: string; // リマインド通知の日付
  sendAt: Date; // トランザクションに関する通知の送信日（アンケートの場合、アンケート送信日付）
  sendTo: string; // トランザクションの送信対象（employeeIdが入る）
  status: TransactionStatus;
  templateId: string;
  tenantId: string;
  employeeId: string;
  constructor(
    id: string,
    dataType: TransactionDataType,
    contents: Record<string, unknown>,
    remindDate: string,
    sendAt: Date,
    sendTo: string,
    status: TransactionStatus,
    templateId: string,
    tenantId: string,
    employeeId: string
  ) {
    this.id = id;
    this.dataType = dataType;
    this.contents = contents;
    this.remindDate = remindDate;
    this.sendAt = sendAt;
    this.sendTo = sendTo;
    this.status = status;
    this.templateId = templateId;
    this.tenantId = tenantId;
    this.employeeId = employeeId;
  }
}
