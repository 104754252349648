import { Employee, Role, ContactRoom } from "@onn/common";
import { isEmpty } from "lodash";

import { ContactRoomRepository } from "~/infrastructure/api/contactRoomRepository";
import { EmployeeRepository } from "~/infrastructure/api/employeeRepository";
import { functionOperator } from "~/infrastructure/api/functionOperator";
import { TenantRepository } from "~/infrastructure/api/tenantRepository";

const contactRoomRepository = new ContactRoomRepository();
const employeeRepository = new EmployeeRepository();
const tenantRepository = new TenantRepository();

export class UpdateAssigneeAndFollowersUseCase {
  async execute(
    currentUser: Employee,
    contactRoomId: string,
    assigneeId: string | undefined,
    followerIds: string[],
    followerEmailsWithoutOnnAccount: string[],
    assigneeEmailWithoutOnnAccount?: string
  ): Promise<ContactRoom> {
    const tenant = await tenantRepository.get();
    const contactRoom = await contactRoomRepository.findById(contactRoomId);

    const createdAssigneeId = assigneeEmailWithoutOnnAccount
      ? (await this.createAccount([assigneeEmailWithoutOnnAccount]))[0]?.id
      : undefined;

    let createdFollowerIds: string[] = [];
    if (followerEmailsWithoutOnnAccount && !isEmpty(followerEmailsWithoutOnnAccount)) {
      createdFollowerIds = (await this.createAccount(followerEmailsWithoutOnnAccount)).map(
        (v) => v.id
      );
    }

    const newContactRoom = contactRoom.update(currentUser.id, {
      assigneeId: createdAssigneeId ?? assigneeId,
      followerIds: [...followerIds, ...createdFollowerIds],
    });

    // 新卒向けテナントとコンタクトルームが入社者に紐付いている場合に実行する
    if (tenant?.isActiveNewGraduate && contactRoom.employeeId) {
      await employeeRepository.update(contactRoom.employeeId, {
        mentorUserId: createdAssigneeId ?? assigneeId,
        supportMemberEmployeeIds: [...followerIds, ...createdFollowerIds],
      });
    }

    await contactRoomRepository.update(newContactRoom);

    return newContactRoom;
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emailsWithoutOnnAccount: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emailsWithoutOnnAccount.map((email) => {
          return {
            email,
            role: Role.MEMBER,
            departmentIds: [],
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }
}
