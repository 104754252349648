import { Dialog, Box, makeStyles } from "@material-ui/core";
import React, { FC, ComponentProps, useCallback } from "react";
import styled from "styled-components";

import { IconButton } from "~/components/uiParts/IconButton";
import { Typography } from "~/components/uiParts/Typography";

export const useBlurBackdrop = makeStyles(() => ({
  backDrop: {
    backdropFilter: "blur(10px)",
  },
}));

type Props = Omit<ComponentProps<typeof Dialog>, "content"> & {
  open: boolean;
  title?: string;
  headerContent?: JSX.Element;
  content: JSX.Element;
  onCancel?: () => void;
  footer?: JSX.Element;
  isSpMode?: boolean;
  disableBackdropModal?: boolean;
};

export const Modal: FC<Props> = ({
  open,
  title,
  headerContent,
  content,
  footer,
  onCancel,
  isSpMode,
  fullWidth,
  fullScreen,
  disableBackdropModal,
  ...props
}) => {
  const classes = useBlurBackdrop();

  const handleCancel = useCallback(() => {
    if (disableBackdropModal) return;

    onCancel && onCancel();
  }, [disableBackdropModal, onCancel]);

  if (isSpMode) {
    return (
      <StyledSpDialog
        maxWidth="sm"
        open={open}
        onClose={onCancel}
        fullScreen={fullScreen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        {...props}
      >
        {onCancel && (
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="md" icon="close" onClick={onCancel} color="white" />
          </Box>
        )}

        {content}
      </StyledSpDialog>
    );
  } else {
    return (
      <StyledPcDialog
        maxWidth="md"
        open={open}
        onClose={handleCancel}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        {...props}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" bold>
              {title}
            </Typography>
            <>{headerContent}</>
            {onCancel && <IconButton size="md" icon="close" onClick={onCancel} />}
          </Box>
          <Box>{content}</Box>
          {footer && <StyledFooter>{footer}</StyledFooter>}
        </Box>
      </StyledPcDialog>
    );
  }
};

const StyledPcDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      min-width: 800px;
      padding: 40px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
      margin: 16px;
      padding: 20px;
    }
  }
`;

const StyledSpDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    background-color: unset;
    box-shadow: unset;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      min-width: 400px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
      margin: 16px;
    }
  }
`;

const StyledFooter = styled.footer`
  margin-top: 40px;
`;
