import {
  OnboardingExperience,
  OnboardingExperienceTaskType,
  OnboardingExperienceTask,
  OnboardingExperienceTaskFactory,
  OnboardingExperienceMessageTask,
} from "@onn/common";
import { isAfter, isSameDay } from "date-fns";
import { isEqual } from "lodash";
import { useState, useEffect, useMemo, useCallback, useLayoutEffect } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "~/hooks/employee";
import {
  useOnboardingExperience,
  useUpdateOnboardingExperience,
} from "~/hooks/onboardingExperience";
import {
  ExperienceTaskGroup,
  useExperienceTaskGroups,
} from "~/hooks/onboardingExperience/useExperienceTaskGroups";
import { usePrompt, useLocalStorage, useSnackbar } from "~/hooks/shared";
import { getDisplayDifferenceDate } from "~/util";

export const StepType = {
  READ_PROCESS_DETAIL_STEP: "hasReadProcessDetail",
  CREATE_PROCESS_STEP: "hasCreatedProcess",
  CREATE_TASK_STEP: "hasCreatedTask",
};
// 全てのstepを含んだ配列を定義
const allSteps = Object.values(StepType);
type AllStepTypes = (typeof allSteps)[number];

const priorityForSortMap: Record<OnboardingExperienceTask["assigneeRole"], number> = {
  NEW_HIRE: 1,
  MENTOR: 2,
  SUPPORT_MEMBER: 3,
  ADMIN: 4,
  BY_NAME: 5,
  NOT_SET: 6,
};

/**
 * オンボーディングプロセスのStateを管理するViewModel
 */
export const useViewModel = (onboardingExperienceId?: string) => {
  const { currentUser } = useCurrentUser();
  const {
    data: onboardingExperience,
    isValidating: isValidatingOnboardingExperience,
    mutate: mutateOnboardingExperience,
  } = useOnboardingExperience(currentUser, onboardingExperienceId);
  const { isLoading: isLoadingUpdateOnboardingExperience, updateOnboardingExperience } =
    useUpdateOnboardingExperience();
  const navigate = useNavigate();
  const { retrieveValue, storeValue } = useLocalStorage();
  const { enqueueSnackbar } = useSnackbar();

  const [newOnboardingExperience, setNewOnboardingExperience] = useState<OnboardingExperience>();
  const [newOnboardingExperienceTasks, setNewOnboardingExperienceTasks] = useState<
    OnboardingExperienceTaskType[]
  >([]);

  const { experienceTaskGroups } = useExperienceTaskGroups(newOnboardingExperienceTasks);

  const sortOnboardingTaskGroup = useCallback((experienceTaskGroups: ExperienceTaskGroup[]) => {
    return experienceTaskGroups
      .sort((a, b) => {
        return isAfter(a.referenceDate, b.referenceDate) ? 1 : -1;
      })
      .sort((a, b) => {
        if (!isSameDay(a.referenceDate, b.referenceDate)) {
          return 0;
        }
        return priorityForSortMap[a.assigneeRole] - priorityForSortMap[b.assigneeRole];
      });
  }, []);

  const sortOnboardingTasks = useCallback(
    (onboardingExperienceTasks: OnboardingExperienceTaskType[], now: Date) => {
      return onboardingExperienceTasks
        .sort((a, b) => {
          if (isSameDay(a.deliveryDate.calculateDate(now), b.deliveryDate.calculateDate(now)))
            return a.index - b.index;
          return isAfter(a.deliveryDate.calculateDate(now), b.deliveryDate.calculateDate(now))
            ? 1
            : -1;
        })
        .sort((a, b) => {
          if (!isSameDay(a.deliveryDate.calculateDate(now), b.deliveryDate.calculateDate(now))) {
            return 0;
          }
          return priorityForSortMap[a.assigneeRole] - priorityForSortMap[b.assigneeRole];
        });
    },
    []
  );

  useEffect(() => {
    if (onboardingExperience) {
      setNewOnboardingExperienceTasks(onboardingExperience.tasks);
      const now = new Date();
      setNewOnboardingExperienceTasks(
        sortOnboardingTasks(onboardingExperience.tasks, now).map((task, index) =>
          OnboardingExperienceTaskFactory.createOnboardingTask({ ...task, index })
        )
      );
    }
  }, [onboardingExperience, sortOnboardingTasks]);

  const [isChanged, setIsChanged] = useState(false);
  usePrompt("編集内容を破棄しますか？", isChanged);

  const [completedSteps, setCompletedSteps] = useState<AllStepTypes[]>(
    allSteps.filter((step) => {
      return Boolean(retrieveValue<boolean>(step));
    })
  );

  useLayoutEffect(() => {
    if (onboardingExperience) {
      setNewOnboardingExperience(
        new OnboardingExperience({
          ...onboardingExperience,
          updatedUserId: currentUser.id,
        })
      );
    }
  }, [currentUser.id, onboardingExperience]);

  const updateExperience = useCallback((newObject: Partial<OnboardingExperience>) => {
    setNewOnboardingExperience((prev) => {
      if (!prev) return prev;

      return new OnboardingExperience({
        ...prev,
        ...newObject,
      });
    });
  }, []);

  const deliveryDateExperienceTaskGroupsMap = useMemo(() => {
    const map: Record<string, ExperienceTaskGroup[]> = {};

    sortOnboardingTaskGroup(experienceTaskGroups).forEach((experienceTaskGroup) => {
      const displayTimingText = `入社${getDisplayDifferenceDate(
        experienceTaskGroup.referenceDate,
        "入社"
      )}`;
      const existedExperienceTaskGroup = map[displayTimingText];
      map[displayTimingText] = existedExperienceTaskGroup
        ? [...existedExperienceTaskGroup, experienceTaskGroup]
        : [experienceTaskGroup];
    });

    return map;
  }, [experienceTaskGroups, sortOnboardingTaskGroup]);

  const upsertTask = useCallback(
    (newTask: OnboardingExperienceTaskType) => {
      if (!isChanged) setIsChanged(true);
      setNewOnboardingExperienceTasks((prev) => {
        const isUpdate = prev.some((v) => v.id === newTask.id);

        return sortOnboardingTasks(
          isUpdate
            ? prev.map((task) => {
                if (task.id !== newTask.id) return task;
                return newTask;
              })
            : [...prev, newTask],
          new Date()
        ).map((task, index) =>
          OnboardingExperienceTaskFactory.createOnboardingTask({ ...task, index })
        );
      });

      if (!completedSteps.includes(StepType.CREATE_TASK_STEP)) {
        setCompletedSteps((prev) => [...prev, StepType.CREATE_TASK_STEP]);
        storeValue(StepType.CREATE_TASK_STEP, true);
      }
    },
    [isChanged, completedSteps, sortOnboardingTasks, storeValue]
  );

  const deleteTask = useCallback(
    (targetTask: OnboardingExperienceTaskType) => {
      if (!isChanged) setIsChanged(true);

      setNewOnboardingExperienceTasks((prev) =>
        sortOnboardingTasks(
          prev.flatMap((task) => {
            if (task.id === targetTask.id) return [];
            if (task.type !== "MESSAGE_TASK" || targetTask.type !== "MESSAGE_TASK") return task;
            if (task.associationId !== targetTask.associationId) return task;

            return OnboardingExperienceMessageTask.create({
              ...task,
              requestedRoles: task.requestedRoles.filter((v) => v !== targetTask.assigneeRole),
            });
          }),
          new Date()
        ).map((task, index) =>
          OnboardingExperienceTaskFactory.createOnboardingTask({ ...task, index })
        )
      );
    },
    [isChanged, sortOnboardingTasks]
  );

  const submitOnboardingExperience = useCallback(
    (onboardingExperienceId: string, newOnboardingExperience: OnboardingExperience) => {
      setIsChanged(false);
      updateOnboardingExperience({
        onboardingExperienceId,
        newOnboardingExperience,
        newOnboardingExperienceTasks,
      })
        .then(() => {
          mutateOnboardingExperience();
          navigate("/tools#onboarding_experiences");
        })
        .catch(() => {
          // hooksのでsnackbarを返しているのでここでは何もしない
          setIsChanged(true);
        });
    },
    [mutateOnboardingExperience, navigate, newOnboardingExperienceTasks, updateOnboardingExperience]
  );

  /**
   * DnD タスク入れ替え
   */
  const changeTasksOrder = useCallback(
    (
      draggableId: string,
      sourceDroppableId: string,
      sourceIndex: number,
      destinationDroppableId: string,
      destinationIndex: number
    ) => {
      // 移動元と移動先が同じ場合は処理を終了
      if (sourceIndex === destinationIndex && sourceDroppableId === destinationDroppableId) return;

      const destinationTask = newOnboardingExperienceTasks.find(
        (task) => task.id === destinationDroppableId
      );

      const sourceTask = newOnboardingExperienceTasks.find((task) => task.id === draggableId);

      // メッセージタスクはプロセスを跨ぐ移動はできない
      if (
        sourceTask?.type === "MESSAGE_TASK" &&
        (sourceTask.assigneeRole !== destinationTask?.assigneeRole ||
          !isEqual(sourceTask?.deliveryDate, destinationTask?.deliveryDate))
      ) {
        enqueueSnackbar("メッセージタスクはドラッグでタイミングを変更できません", {
          variant: "error",
        });
        return;
      }

      const tmp = [...newOnboardingExperienceTasks];
      const removed = tmp.splice(sourceIndex, 1)[0] as (typeof tmp)[number];
      tmp.splice(
        // 小さいindexに移動させるときのずれを解消するため
        destinationIndex > sourceIndex && sourceDroppableId !== destinationDroppableId
          ? destinationIndex - 1
          : destinationIndex,
        0,
        removed
      );

      setNewOnboardingExperienceTasks([
        ...tmp.map((v, index) => {
          if (sourceIndex === v.index) {
            return OnboardingExperienceTaskFactory.createOnboardingTask({
              ...v,
              index,
              deliveryDate: destinationTask?.deliveryDate || v.deliveryDate,
              assigneeRole: destinationTask?.assigneeRole || v.assigneeRole,
              assigneeIds: destinationTask?.assigneeIds || v.assigneeIds,
            });
          }
          return OnboardingExperienceTaskFactory.createOnboardingTask({
            ...v,
            index,
          });
        }),
      ]);
    },
    [enqueueSnackbar, newOnboardingExperienceTasks]
  );

  /**
   * DnDでタスクの順序を入れ替え
   */
  const changeTasksOrderByDnD = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { droppableId: sourceDroppableId, index: sourceIndex } = result.source;
      const { droppableId: destinationDroppableId, index: destinationIndex } = result.destination;
      const { draggableId } = result;

      // drop可能範囲以外でのdropは無効
      if (destinationDroppableId === undefined || destinationIndex === undefined) return;

      if (!isChanged) setIsChanged(true);

      changeTasksOrder(
        draggableId,
        sourceDroppableId,
        sourceIndex,
        destinationDroppableId,
        destinationIndex
      );
    },
    [changeTasksOrder, isChanged]
  );

  return {
    deliveryDateExperienceTaskGroupsMap,
    isValidatingOnboardingExperience,
    onboardingExperience,
    newOnboardingExperience,
    updateExperience,
    upsertTask,
    deleteTask,
    submitOnboardingExperience,
    isLoadingUpdateOnboardingExperience,
    completedSteps,
    setCompletedSteps,
    changeTasksOrderByDnD,
  };
};
