import { List } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { Row } from "./Row";

import { Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

type Props = {
  employees: Employee[];
  selectedEmployees: Employee[];
  onSelect: (employees: Employee[]) => void;
};

export const EmployeeList: FC<Props> = ({ employees, selectedEmployees, onSelect }) => {
  const height = 224;
  const rowHeight = 52;
  const overscanRowCount = 10;

  const handleSelect = useCallback(
    (employee: Employee) => {
      // 選択済みのユーザーをクリックしたとき
      if (selectedEmployees.includes(employee)) {
        const newArray = selectedEmployees.filter((v) => {
          return v.id !== employee.id;
        });
        onSelect(newArray);
      } else {
        onSelect([...selectedEmployees, employee]);
      }
    },
    [onSelect, selectedEmployees]
  );

  return (
    <StyledList $height={height}>
      {
        <AutoSizer
          disableHeight // 子要素の高さが固定長であるため高さを計算しない
        >
          {(size) => (
            <VirtualizedList
              height={height}
              width={size.width}
              overscanRowCount={overscanRowCount}
              rowCount={employees.length}
              rowHeight={rowHeight}
              rowRenderer={(props) => {
                return (
                  <Row
                    {...props}
                    employees={employees}
                    selectedEmployees={selectedEmployees}
                    onSelect={handleSelect}
                  />
                );
              }}
              noRowsRenderer={() => (
                <Typography variant="caption" align="center" display="block" color="textSecondary">
                  結果が見つかりませんでした
                </Typography>
              )}
            />
          )}
        </AutoSizer>
      }
    </StyledList>
  );
};

const StyledList = styled(List)<{ $height: number }>`
  height: ${(props) => props.$height}px;
  overflow: auto;
`;
