import { Employee, OnboardingMessageTask } from "@onn/common";
import { useState, useCallback } from "react";
import { useSWRConfig } from "swr";

import { generateUseOnboardingTask } from "../../useOnboardingTask";
import { generateUseOnboardingTasksKeys } from "../../useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "../../useOnboardingTasksByEmployeeId";

import { useDepartmentsByTenantId } from "~/hooks/department";
import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { useSlackUsers } from "~/hooks/slackUser";
import { InsertNewContentToOnboardingMessageTaskUseCase } from "~/service/usecases/onboardingTask/InsertNewContentToOnboardingMessageTaskUseCase/InsertNewContentToOnboardingMessageTaskUseCase";
import { captureException } from "~/util";

const insertNewContentToOnboardingMessageTaskUseCase =
  new InsertNewContentToOnboardingMessageTaskUseCase();

/**
 * 記入依頼者を追加するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns insertNewContentToOnboardingMessageTask
 */
export const useInsertNewContentToOnboardingMessageTask = (): {
  isLoading: boolean;
  insertNewContentToOnboardingMessageTask: (
    onboardingTaskId: string,
    emails: string[],
    newHire: Employee
  ) => Promise<OnboardingMessageTask | null>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { data: departments } = useDepartmentsByTenantId(currentUser.tenantId);
  const { enqueueSnackbar } = useSnackbar();
  const { slackUsers } = useSlackUsers();
  const { mutate } = useSWRConfig();

  const insertNewContentToOnboardingMessageTask = useCallback(
    async (
      onboardingTaskId: string,
      emails: string[],
      newHire: Employee
    ): Promise<OnboardingMessageTask | null> => {
      setIsLoading(true);
      return await insertNewContentToOnboardingMessageTaskUseCase
        .execute(onboardingTaskId, currentUser, emails, slackUsers, newHire, departments ?? [])
        .then((result) => {
          mutate(generateUseOnboardingTask(onboardingTaskId));
          mutate(generateUseOnboardingTasksKeys(currentUser.tenantId));
          mutate(generateUseOnboardingTasksByEmployeeId(result.employeeId));
          enqueueSnackbar("記入依頼メンバーを追加しました", { variant: "success" });
          return result;
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useInsertNewContentToOnboardingMessageTask:insertNewContentToOnboardingMessageTask",
            },
          });
          return null;
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar, mutate, slackUsers, departments]
  );

  return { isLoading, insertNewContentToOnboardingMessageTask };
};
