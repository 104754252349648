import { Box } from "@material-ui/core";
import { Employee, Role, Department } from "@onn/common";
import React, { useState, FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { AdminForm } from "./AdminForm";
import { useCheckUserDataArrayValid } from "./useCheckUserDataArrayValid";

import { Button, Tooltip, Typography, Modal, Icon } from "~/components/uiParts";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type UserDataFormType = { email?: string; role?: Role; departmentIds: string[] };
type UserDataType = { email: string; role: Role; departmentIds: string[] };

const initUserData = {
  departmentIds: [],
};

type Props = {
  currentUser: Employee;
  open: boolean;
  existedEmployees: Employee[];
  departments: Department[];
  onSubmit: (userDataArray: UserDataType[]) => Promise<void>;
  onCancel: () => void;
};

const MAX_INVITED_FIELDS = 10;

export const AddAdminModal: FC<Props> = ({
  currentUser,
  open,
  existedEmployees,
  departments,
  onSubmit,
  onCancel,
}) => {
  const [userDataArray, setUserDataArray] = useState<UserDataFormType[]>([initUserData]);

  const [sending, setSending] = useState(false);

  const { checkUserDataArrayValid } = useCheckUserDataArrayValid();
  const { tenant } = useTenant();

  const selectableDepartments = useMemo(
    () =>
      currentUser.isAdmin()
        ? departments
        : // 部門管理者は、自身の所属とその下位の所属に入社者を招待できる
          departments.filter((department) =>
            Department.getChildIds(currentUser.departmentIds, departments).includes(department.id)
          ),
    [currentUser, departments]
  );

  const updateUserDataArray = useCallback((newObject: Partial<UserDataFormType>, index: number) => {
    setUserDataArray((prevUserDataArray) => {
      return prevUserDataArray.map((prevUserData, prevIndex) => {
        if (prevIndex === index) {
          return { ...prevUserData, ...newObject };
        }
        return prevUserData;
      });
    });
  }, []);

  const handleAddEmails = useCallback(() => {
    setUserDataArray((prev) => [...prev, initUserData]);
  }, []);

  const handleDeleteEmails = useCallback((index: number): void => {
    setUserDataArray((prev) => prev.flatMap((v, prevIndex) => (prevIndex === index ? [] : v)));
  }, []);

  const handleSubmit = useCallback(
    async (_userDataArray: UserDataFormType[]) => {
      setSending(true);

      // emailを使ってアカウントを作成するのでフィルターをする
      const userDataArray = _userDataArray.filter((userData): userData is UserDataType => {
        if (!userData.role || !userData.email) return false;
        return true;
      });

      await onSubmit(userDataArray)
        .then(() => {
          onCancel();
        })
        .finally(() => {
          setSending(false);
        });
    },
    [onCancel, onSubmit]
  );

  return (
    <Modal
      fullWidth
      open={open}
      title="管理者追加"
      content={
        <Box pr={5} pl={5}>
          <Box display="flex" marginTop="16px" gridGap="16px">
            <Box flex={1}>
              <Typography variant="body2" bold color="textSecondary">
                {tenant?.isIntegratedSlack ? "メンバー" : "メールアドレス"}
              </Typography>
            </Box>
            <Box flex={1} display="flex" gridGap="12px" alignItems="center">
              <Typography variant="body2" bold color="textSecondary">
                権限
              </Typography>
              <Tooltip
                title={`管理者：全ての機能の閲覧・編集と一部削除ができます。\n部門管理者：自身が属する部署全体の閲覧・編集ができます。\nメンバー：指定された部署に該当する入社者の閲覧・編集ができます。`}
                placement="top-start"
              >
                <Icon icon="help" size="sm" color="grey" />
              </Tooltip>
            </Box>
            <Box flex={1}>
              <Typography variant="body2" bold color="textSecondary">
                部署
              </Typography>
            </Box>
            {/* 削除アイコン分の余白を作るためのBox */}
            <Box padding="12px 20px" />
          </Box>

          {userDataArray.map((userData, index) => {
            return (
              <AdminForm
                key={index}
                isAdmin={currentUser.isAdmin()}
                userData={userData}
                // 部門管理者の場合は自身の所属する部署しか招待できない
                departments={selectableDepartments}
                existedEmails={[...existedEmployees.map((v) => v.email)]}
                onSelectEmail={(email) => {
                  updateUserDataArray({ email }, index);
                }}
                onChangeDepartments={(departmentIds) =>
                  updateUserDataArray({ departmentIds }, index)
                }
                onClickRole={(role) => updateUserDataArray({ role }, index)}
                onClickDeleteFormButton={() => handleDeleteEmails(index)}
                displayDeleteFormIcon={userDataArray.length > 1}
              />
            );
          })}

          {userDataArray.length !== MAX_INVITED_FIELDS && (
            <StyledAddAdminTypography
              variant="body2"
              bold
              color="primary"
              onClick={handleAddEmails}
            >
              <Icon size="sm" icon="add" color="primary" />
              管理者を追加
            </StyledAddAdminTypography>
          )}

          <Box pr={10} pl={10}>
            <Box pt={2} />
            <Typography variant="caption" color="textSecondary" align="center" display="block">
              管理者追加後、メールアドレス宛に招待が通知されます。
              <br />
              入社者の追加はここからは行えません。入社者追加は
              <StyledPointerTypography
                display="inline"
                variant="caption"
                bold
                color="primary"
                onClick={() => window.open("/", "_blank")}
              >
                こちら
              </StyledPointerTypography>
              からお願いします。
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(userDataArray)}
            disabled={
              !checkUserDataArrayValid(
                userDataArray,
                existedEmployees.map((v) => v.email)
              ) || sending
            }
          >
            {!sending ? "招待を送信" : "送信中"}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledAddAdminTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 24px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    cursor: pointer;
  }
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
