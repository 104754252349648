import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts/Typography";

type Props = {
  title: string;
  bold?: boolean;
  hrOnly?: boolean;
};

export const QuestionTitle: FC<Props> = (props) => {
  const { title, bold = false, hrOnly = false } = props;

  return (
    <>
      <Typography variant="body2" display="inline" bold={bold}>
        {title}
      </Typography>
      {hrOnly && (
        <>
          <Box display="inline-block" width="16px" />
          <Typography variant="caption" color="textSecondary">
            ※管理者のみに表示されています
          </Typography>
        </>
      )}
    </>
  );
};
