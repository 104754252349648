import { TableCell, TableRow } from "@material-ui/core";
import { Month } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useMemo, FC, useCallback, useState } from "react";
import styled from "styled-components";

import { ENPS } from "./ENPS";
import { ENPSList } from "./ENPSList";

import { SurveyDrawer } from "~/components/domains/transactions/survey/SurveyDrawer";
import { Button, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSendGAEvent } from "~/hooks/shared";
import { useSurveyTransactionsForENPS } from "~/hooks/transaction";

type Props = { index: number; period: { year: number; month: Month } };

export const ENPSRow: FC<Props> = ({ index, period }) => {
  const { currentUser } = useCurrentUser();
  const { sendGAEvent } = useSendGAEvent();
  const { data: dataForENPS, isValidating: isValidatingDataForENPS } = useSurveyTransactionsForENPS(
    currentUser.tenantId,
    {
      year: period.year,
      month: period.month,
    }
  );
  const [openedDrawerNumber, setOpenedDrawerNumber] = useState<1 | 3 | null>(null);

  const openDrawer = useCallback((number: 1 | 3): void => setOpenedDrawerNumber(number), []);
  const closeDrawer = useCallback((): void => setOpenedDrawerNumber(null), []);
  const drawerTitle = "eNPS詳細";

  const firstMonthENPSButton = useMemo(
    () => (
      <Button
        onClick={() => {
          openDrawer(1);
          sendGAEvent("click_first_month_enps_button", currentUser.id);
        }}
        variant="text"
        color="default"
        borderRadius="regular"
        disabled={isValidatingDataForENPS || isEmpty(dataForENPS?.firstMonthSurveyTransactions)}
      >
        <ENPS
          employees={dataForENPS ? dataForENPS.employees : []}
          surveyTransactions={dataForENPS ? dataForENPS.firstMonthSurveyTransactions : []}
          isValidating={isValidatingDataForENPS}
        />
      </Button>
    ),
    [currentUser.id, dataForENPS, isValidatingDataForENPS, openDrawer, sendGAEvent]
  );
  const thirdMonthENPSButton = useMemo(
    () => (
      <Button
        onClick={() => {
          openDrawer(3);
          sendGAEvent("click_third_month_enps_button", currentUser.id);
        }}
        variant="text"
        color="default"
        borderRadius="regular"
        disabled={isValidatingDataForENPS || isEmpty(dataForENPS?.thirdMonthSurveyTransactions)}
      >
        <ENPS
          employees={dataForENPS ? dataForENPS.employees : []}
          surveyTransactions={dataForENPS ? dataForENPS.thirdMonthSurveyTransactions : []}
          isValidating={isValidatingDataForENPS}
        />
      </Button>
    ),
    [currentUser.id, dataForENPS, isValidatingDataForENPS, openDrawer, sendGAEvent]
  );

  return (
    <>
      <TableRow key={index}>
        <StyledTableCell component="th" scope="row">
          <Typography variant="body1">{`${period.year}/${period.month}入社`}</Typography>
        </StyledTableCell>
        <StyledTableCell align="center">{firstMonthENPSButton}</StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1">→</Typography>
        </StyledTableCell>
        <StyledTableCell align="center">{thirdMonthENPSButton}</StyledTableCell>
      </TableRow>
      <SurveyDrawer
        isOpen={openedDrawerNumber === 1}
        onClose={closeDrawer}
        title={drawerTitle}
        content={
          <ENPSList
            period={period}
            nthOfMonth={1}
            employees={dataForENPS ? dataForENPS.employees : []}
            surveyTransactions={dataForENPS ? dataForENPS.firstMonthSurveyTransactions : []}
          />
        }
      />
      <SurveyDrawer
        isOpen={openedDrawerNumber === 3}
        onClose={closeDrawer}
        title={drawerTitle}
        content={
          <ENPSList
            period={period}
            nthOfMonth={3}
            employees={dataForENPS ? dataForENPS.employees : []}
            surveyTransactions={dataForENPS ? dataForENPS.thirdMonthSurveyTransactions : []}
          />
        }
      />
    </>
  );
};

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 16px 3px;
    border: none;
  }
`;
