import { Employee, EmployeeToDisplay } from "@onn/common";
import useSWR, { KeyedMutator, SWRResponse, useSWRConfig } from "swr";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

export const generateUseEmployeeKeys = (employeeId: string) => {
  return ["employee", employeeId];
};

/**
 * idをもとにemployeeを返すswr
 * @param employeeId employeeのid
 * @param option フェッチに関するオプション
 */
export const useEmployee = (
  employeeId?: string,
  option: {
    /**
     * hook呼び出し時に自動で再検証をするかどうか(デフォルトでtrue)
     */
    shouldAutoRevalidate?: boolean;
  } = { shouldAutoRevalidate: true }
): SWRResponse<Employee | undefined, Error> => {
  const key = employeeId ? generateUseEmployeeKeys(employeeId) : null;

  const additionalOption = !option?.shouldAutoRevalidate
    ? {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    : undefined;

  return useSWR(
    key,
    async ([_key, employeeId]: [string, string]) => {
      return EmployeeUseCase.findById(employeeId);
    },
    {
      ...additionalOption,
    }
  );
};

export const useGenerateMutateEmployee = () => {
  const { mutate } = useSWRConfig();

  const generateMutateEmployee: (
    employeeId: string
  ) => KeyedMutator<EmployeeToDisplay | undefined> = (employeeId: string) => {
    return async (data, opts) => {
      const key = generateUseEmployeeKeys(employeeId);
      if (!data) {
        return mutate(key);
      }
      return mutate(key, data, opts);
    };
  };

  return { generateMutateEmployee };
};
