import { OnboardingExperienceTaskType } from "@onn/common";

import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";

const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();
export class FindOnboardingExperienceTasksByExperienceIdsUseCase {
  async execute(experienceIds: string[]): Promise<OnboardingExperienceTaskType[]> {
    return await onboardingExperienceTaskRepository.findByExperienceIds(experienceIds);
  }
}
