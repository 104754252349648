import { SurveyTransaction } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { SurveyTransactionUseCase } from "~/service/usecases/transactionUseCase";

/**
 * transactionIdをもとにsurveyTransactionを返すswr
 * @param transactionId transactionのId
 */
export const useSurveyTransaction = (
  transactionId?: string
): SWRResponse<SurveyTransaction, Error> => {
  const keys = transactionId ? ["survey-transaction", transactionId] : null;

  return useSWR(keys, ([_key, transactionId]: [string, string]) =>
    SurveyTransactionUseCase.find(transactionId)
  );
};
