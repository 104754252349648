import { Box } from "@material-ui/core";
import React, { ReactNode, FC } from "react";

import { TaskProgressPaper } from "~/components/domains/onboardingTask/TaskProgressPaper";
import { ENPSPaper } from "~/components/domains/transactions/survey/ENPSPaper";
import { OnboardingRankPaper } from "~/components/domains/transactions/survey/OnboardingRankPaper";
import { SurveyAlertPaper } from "~/components/domains/transactions/survey/SurveyAlertPaper";
import { SurveyResponseRatePaper } from "~/components/domains/transactions/survey/SurveyResponseRatePaper";

type ContentType =
  | "onboardingRank"
  | "eNPS"
  | "taskProgress"
  | "surveyAlert"
  | "surveyResponseRate";

const contentsMap: Record<ContentType, ReactNode> = {
  onboardingRank: <OnboardingRankPaper />,
  eNPS: <ENPSPaper now={new Date()} />,
  taskProgress: <TaskProgressPaper />,
  surveyAlert: <SurveyAlertPaper />,
  surveyResponseRate: <SurveyResponseRatePaper />,
};

type Props = {
  contentType: ContentType;
};

export const DashboardItem: FC<Props> = ({ contentType }) => {
  return <Box>{contentsMap[contentType]}</Box>;
};
