import { useCallback } from "react";

import { captureException } from "~/util";

export const useStorage = (
  storage: Storage
): {
  storeValue: <T>(key: string, value?: T) => void;
  retrieveValue: <T>(
    key: string,
    handleStorageObject?: {
      func: (value: T) => T;
      onError?: () => void;
      errorTypeName?: string;
    }
  ) => T | null;
  removeValue: (key: string) => void;
} => {
  const storeValue = useCallback(
    <T>(key: string, value?: T): void => {
      storage.setItem(key, JSON.stringify(value));
      window.dispatchEvent(new Event("storage"));
    },
    [storage]
  );

  const retrieveValue = useCallback(
    <T>(
      key: string,
      handleStorageObject?: {
        func: (value: T) => T;
        onError?: () => void;
        errorTypeName?: string;
      }
    ): T | null => {
      const value = storage.getItem(key);
      if (value === null) return null;
      if (handleStorageObject === undefined) return JSON.parse(value);

      try {
        return handleStorageObject.func(JSON.parse(value));
      } catch (error) {
        if (error instanceof Error) {
          captureException({
            error,
            tags: {
              type: handleStorageObject.errorTypeName || "usestorage.retrieveValue",
            },
          });
        }

        handleStorageObject.onError?.();
        return null;
      }
    },
    [storage]
  );

  const removeValue = useCallback(
    (key: string): void => {
      storage.removeItem(key);
      window.dispatchEvent(new Event("storage"));
    },
    [storage]
  );

  return { storeValue, retrieveValue, removeValue };
};
