import {
  MessageTemplate,
  OnboardingExperienceTaskTemplate,
  removeUndefinedFromObject,
} from "@onn/common";
import { OnboardingExperienceTaskTemplateType } from "@onn/common/domain/OnboardingExperienceTaskTemplate/OnboardingExperienceTaskTemplateFactory/OnboardingExperienceTaskTemplateFactory";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  setDoc,
  where,
  query,
  getDocs,
  orderBy,
  WriteBatch,
  Timestamp,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IOnboardingExperienceTaskTemplateRepository } from "~/service/repository/iOnboardingExperienceTaskTemplateRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type OnboardingExperienceTaskTemplateForDB = Omit<
  ConstructorParameters<typeof OnboardingExperienceTaskTemplate>[0],
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "onboardingExperienceTaskTemplates";

export class OnboardingExperienceTaskTemplateRepository
  implements IOnboardingExperienceTaskTemplateRepository
{
  async create(onboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate): Promise<void> {
    const ref = this.doc(onboardingExperienceTaskTemplate.id);
    await setDoc(ref, this.convertToPlainObject(onboardingExperienceTaskTemplate));
  }

  async insertUpdateTemplateInBatch(
    batch: WriteBatch,
    onboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate
  ): Promise<void> {
    batch.update(
      this.doc(onboardingExperienceTaskTemplate.id),
      this.convertToPlainObject(onboardingExperienceTaskTemplate)
    );
  }

  async insertDeleteByIdInBatch(batch: WriteBatch, id: string): Promise<void> {
    const template = await this.doc(id);
    batch.delete(template);
  }

  async findAll(tenantId: string): Promise<OnboardingExperienceTaskTemplate[]> {
    return await getDocs(
      query(this.collection(), where("tenantId", "==", tenantId), orderBy("createdAt", "asc"))
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => {
        return this.convertToOnboardingExperienceTask(doc.data());
      });
    });
  }

  async findById(id: string): Promise<OnboardingExperienceTaskTemplateType> {
    const doc = await getDoc(this.doc(id));

    if (!doc.data()) {
      throw new Error("対象のデータが見つかりませんでした。");
    }

    return this.convertToOnboardingExperienceTask(
      doc.data() as OnboardingExperienceTaskTemplateForDB
    );
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private convertToOnboardingExperienceTask = (
    data: DocumentData
  ): OnboardingExperienceTaskTemplateType =>
    // 他のtypeがコレクションに含まれるようになった場合は、typeによってclassを分岐させる
    new MessageTemplate({
      id: data.id,
      tenantId: data.tenantId,
      isDefault: data.isDefault,
      title: data.title,
      description: data.description,
      isPublic: data.isPublic,
      editableEmployeeIds: data.editableEmployeeIds,
      createdEmployeeId: data.createdEmployeeId,
      updatedEmployeeId: data.updatedEmployeeId,
      createdAt: convertTimestampToDate(data.createdAt),
      updatedAt: convertTimestampToDate(data.updatedAt),
    });

  private convertToPlainObject(
    onboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate
  ): OnboardingExperienceTaskTemplateForDB {
    return removeUndefinedFromObject({
      ...onboardingExperienceTaskTemplate,
      createdAt: convertDateToTimestamp(onboardingExperienceTaskTemplate.createdAt),
      updatedAt: convertDateToTimestamp(onboardingExperienceTaskTemplate.updatedAt),
    });
  }
}
