import { v4 } from "uuid";

export class OnboardingExperience {
  id: string;
  title: string;
  tenantId: string;
  editableEmployeeIds?: string[];
  createdAt: Date;
  updatedAt: Date;
  updatedUserId?: string;
  index?: number;

  constructor({
    id,
    title,
    tenantId,
    editableEmployeeIds,
    createdAt,
    updatedAt,
    updatedUserId,
    index,
  }: OnboardingExperience) {
    this.id = id;
    this.title = title;
    this.tenantId = tenantId;
    this.editableEmployeeIds = editableEmployeeIds;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.updatedUserId = updatedUserId;
    this.index = index;
  }

  public static create(params: Optional<OnboardingExperience, "id">) {
    return new OnboardingExperience({
      ...params,
      id: params.id ?? v4(),
    });
  }
}
