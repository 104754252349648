import { Box, ListItem } from "@material-ui/core";
import { SlackUser } from "@onn/common";
import React, { FC } from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { UserIcon, Typography } from "~/components/uiParts";

type Props = ListRowProps & {
  slackUsers: SlackUser[];
  selectedSlackUsers: SlackUser[];
  onSelectSlackUser: (slackUser: SlackUser) => void;
};

export const CompactRowRenderer: FC<Props> = ({
  slackUsers,
  selectedSlackUsers,
  onSelectSlackUser,
  ...props
}) => {
  const slackUser = slackUsers[props.index] as (typeof slackUsers)[number];
  const isSelectedAny = selectedSlackUsers.length > 0;
  const isSelectedThis = selectedSlackUsers.includes(slackUser);

  return (
    <StyledDiv
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
      $isOpacity={!isSelectedAny || isSelectedThis} // 何も選択されていない or 自身が選択されている ときに不透明
    >
      <ListItem
        className={isSelectedThis ? "selected" : ""}
        alignItems="flex-start"
        selected={isSelectedThis}
        onClick={() => onSelectSlackUser(slackUser)}
        data-testid={isSelectedThis ? "SlackUserList_StyledListItem_selected" : undefined}
      >
        <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
          <UserIcon
            username={slackUser.real_name}
            profileIconImageUrl={slackUser.image_192}
            size="extraSmall"
            circular
          />
          <Typography variant="caption" color="textSecondary" noWrap>
            {slackUser.real_name}
          </Typography>
        </Box>
      </ListItem>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{
  /**
   * trueのとき、不透明な要素として表示する
   */
  $isOpacity: boolean;
}>`
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${(props) =>
    props.$isOpacity &&
    `
      opacity: 1;
      background-color: inherit;
      :hover,
      :focus {
        background-color: inherit;
      }
    `}
`;
