import { z } from "zod";

import { ChartItem } from "../../../Survey";
import { SurveyTransaction, TransactionStatus } from "../../../Transaction";

export interface APISchemaTransactions {
  "/get_surveys": {
    /**
     * アンケート回答一覧を取得する
     */
    GET: {
      queryParams: z.infer<typeof GetTransactionsapiSurveySchema>["query"];
      response: {
        data: DeepExcludeMethods<SurveyTransaction>[];
      };
    };
  };
  /**
   * フォーマットされたアンケート配信日一覧を取得する
   */
  "/get_survey_send_ats": {
    GET: {
      query: {
        tenantId: string;
      };
      response: {
        data: string[];
      };
    };
  };
  "/get_survey_scores": {
    GET: {
      queryParams: z.infer<typeof GetSurveyScoresSchema>["query"];
      response: {
        data: Record<string, ChartItem[]>;
      };
    };
  };
}

const zodTransactionStatusEnum = z.enum([TransactionStatus.DONE, TransactionStatus.SENT]);

export const GetTransactionsapiSurveySchema = z.object({
  query: z.object({
    transactionStatus: z
      .union([zodTransactionStatusEnum.array(), zodTransactionStatusEnum])
      .optional(),
    sendAt: z.string().optional(),
  }),
});

export const GetSurveyScoresSchema = z.object({
  query: z.object({
    employee_ids: z.array(z.string()),
  }),
});
