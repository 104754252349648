import { Box } from "@material-ui/core";
import { DifferenceDate } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { DifferenceDateForm } from "~/components/domains/differenceDate";
import { DifferenceDateFormType } from "~/components/domains/differenceDate/DifferenceDateForm/DifferenceDateForm";
import { Typography } from "~/components/uiParts";

type Props = {
  deliveryDate: DifferenceDate;
  setDeliveryDate: (value: Optional<DifferenceDateFormType, "amount">) => void;
  readOnly?: boolean;
};
export const DeliveryDateForm: FC<Props> = ({ deliveryDate, setDeliveryDate, readOnly }) => {
  return (
    <>
      <Box mt="36px" display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          依頼タイミング
        </Typography>
      </Box>
      <Box mt="16px">
        <StyledMutedTypography variant="caption">
          {`入社者にタスクの依頼が通知されるタイミングの設定ができます。\nタスクの完了までにかかる時間を考慮して、期日より前のタイミングを設定してください。`}
        </StyledMutedTypography>
      </Box>
      <Box mt="16px">
        <DifferenceDateForm
          differenceDate={deliveryDate}
          onChange={(deliveryDate) => setDeliveryDate(deliveryDate)}
          units={["DAY"]}
          readOnly={readOnly}
        />
      </Box>
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
