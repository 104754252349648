import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC } from "react";

import { Typography, PortalListItemGroup, Paper, Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSurveyTransactionsByEmployeeId } from "~/hooks/transaction/useSurveyTransactionsByEmployeeId";

export const SurveyIndex: FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: surveyTransactions, isValidating: isLoadingSurveyTransactions } =
    useSurveyTransactionsByEmployeeId(currentUser.id);

  const contents =
    !isLoadingSurveyTransactions && surveyTransactions
      ? surveyTransactions.map((transaction) => {
          const isAnswered = !!transaction.answeredAt;
          return {
            id: transaction.id,
            isCompleted: isAnswered,
            title: transaction.contents.title,
            caption:
              isAnswered && transaction.answeredAt
                ? `${format(transaction.answeredAt, "yyyy/MM/dd")} 回答`
                : "未回答",
            to: isAnswered
              ? `/portal/surveys/${transaction.id}`
              : `/journey/surveys/${transaction.id}`,
          };
        })
      : [];

  if (isLoadingSurveyTransactions) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box pt="40px" px="24px" pb="126px">
      <Typography variant="h2" color="textPrimary">
        アンケート
      </Typography>
      <Box height={40} />
      {contents.length === 0 ? (
        <Paper>
          <Typography align="center">アンケートはまだ届いていません</Typography>
        </Paper>
      ) : (
        <PortalListItemGroup contents={contents} />
      )}
    </Box>
  );
};
