import React, { FC, ReactNode } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { MessageEdit } from "./messageTask/MessageEdit";
import { ResetEmailShow } from "./reset_email/show";
import ResetPasswordComplete from "./reset_password/complete";
import { ResetPasswordShow } from "./reset_password/show";
import { SignIn } from "./signIn";

import { DefaultLayout } from "~/components/layouts";
import { useAuthenticationNonGuarded } from "~/hooks/context";
import { useScrollTopByDetectTransition } from "~/hooks/shared";
import { Account } from "~/pages/account/show";
import { MessageComplete } from "~/pages/messageTask/MessageComplete";
import { MessageConfirm } from "~/pages/messageTask/MessageConfirm";
import { MessageTaskLanding } from "~/pages/messageTask/MessageTaskLanding";

const GuestRoute = ({ component: Component }: { component: FC }) => {
  useScrollTopByDetectTransition();
  const { authUser } = useAuthenticationNonGuarded();

  if (authUser) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

// 認証ユーザーが存在しても、リダイレクトさせない
// アカウント登録画面等で使用する
const AccountRegisterRoute = ({ component: Component }: { component: FC }) => {
  useScrollTopByDetectTransition();

  return <Component />;
};

type Props = {
  children: ReactNode;
};
export const GuestRouterWrapper: FC<Props> = ({ children }) => {
  return useRoutes([
    // 一時的なページパス
    {
      path: "/welcome_message",
      element: (
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      ),
      children: [
        {
          path: "/welcome_message/:id/:contentId",
          element: <MessageTaskLanding />,
        },
        {
          path: "/welcome_message/:id/:contentId/edit",
          element: <MessageEdit />,
        },
        {
          path: "/welcome_message/:id/:contentId/confirm",
          element: <MessageConfirm />,
        },
        {
          path: "/welcome_message/:id/:contentId/complete",
          element: <MessageComplete />,
        },
      ],
    },
    {
      path: "/messages",
      element: (
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      ),
      children: [
        {
          path: "/messages/:id/:contentId",
          element: <MessageTaskLanding />,
        },
        {
          path: "/messages/:id/:contentId/edit",
          element: <MessageEdit />,
        },
        {
          path: "/messages/:id/:contentId/confirm",
          element: <MessageConfirm />,
        },
        {
          path: "/messages/:id/:contentId/complete",
          element: <MessageComplete />,
        },
      ],
    },
    {
      // 招待トークン(個別招待URL)を用いたアカウント登録のためのページ
      // この場合のアカウント登録は、このページで完結する
      path: "/account/:id",
      element: (
        <DefaultLayout isBackgroundImage>
          <AccountRegisterRoute component={Account} />
        </DefaultLayout>
      ),
    },
    {
      path: "/signin",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={SignIn} />
        </DefaultLayout>
      ),
    },
    {
      // デフォルトのリンクの場合遷移時にリセットが実行されてしまうため、メールアドレスの変更時のリセットリンクの遷移先にOnnのリンクを設定している
      // NOTE: https://support.google.com/firebase/answer/7000714#actionlink
      path: "/reset_email",
      element: (
        <DefaultLayout isBackgroundImage>
          <ResetEmailShow />
        </DefaultLayout>
      ),
    },
    {
      path: "/reset_password",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={ResetPasswordShow} />
        </DefaultLayout>
      ),
    },
    {
      path: "/reset_password/complete",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={ResetPasswordComplete} />
        </DefaultLayout>
      ),
    },
    {
      path: "*",
      element: children,
    },
  ]);
};
