import { Employee, Role, OnboardingTaskType, OnboardingTaskFactory } from "@onn/common";
import { isEmpty } from "lodash";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

type UploadFileType = { path: string; file: File };
const onboardingTaskRepository = new OnboardingTaskRepository();
const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export class OnboardingTaskUseCase {
  async findById(id: string): Promise<OnboardingTaskType> {
    return await onboardingTaskRepository.findById(id);
  }
  async create(newOnboardingTask: OnboardingTaskType, emailsWithoutOnnAccount: string[]) {
    const uploadFiles: UploadFileType[] = [];

    if (!isEmpty(emailsWithoutOnnAccount)) {
      const employees = await this.createAccount(emailsWithoutOnnAccount);
      newOnboardingTask.assigneeIds = [
        ...newOnboardingTask.assigneeIds,
        ...employees.map((v) => v.id),
      ];
    }

    await onboardingTaskRepository.create(
      OnboardingTaskFactory.createOnboardingTask({
        ...newOnboardingTask,
        filePaths: newOnboardingTask.filePaths.map((v) => {
          if (typeof v === "string") return v;
          const path = `tenants/${newOnboardingTask.tenantId}/onboarding_tasks/${newOnboardingTask.id}/${v.name}`;
          uploadFiles.push({ path, file: v });
          return path;
        }),
      })
    );

    await fileAPIAdapter.uploadFiles(uploadFiles);
  }
  async completeOnboardingTasks(onboardingTaskIds: string[]): Promise<void> {
    return await onboardingTaskRepository.completeOnboardingTasks(onboardingTaskIds);
  }
  async findByEmployeeId(employeeId: string): Promise<OnboardingTaskType[]> {
    return await onboardingTaskRepository.findByEmployeeId(employeeId);
  }
  async findByEmployeeIds(employeeIds: string[]): Promise<OnboardingTaskType[]> {
    return await onboardingTaskRepository.findByEmployeeIds(employeeIds);
  }
  async deleteById(onboardingTaskId: string): Promise<void> {
    return await onboardingTaskRepository.deleteById(onboardingTaskId);
  }

  async deleteByIds(onboardingTaskIds: string[]): Promise<void> {
    return await onboardingTaskRepository.deleteByIds(onboardingTaskIds);
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emailsWithoutOnnAccount: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emailsWithoutOnnAccount.map((email) => {
          return {
            email,
            role: Role.MEMBER,
            departmentIds: [],
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }
}
