import React, { FC } from "react";

export const Triangle: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        d="M16,7.939,6.964,23.429H25.036L16,7.939m0-5.969a1.982,1.982,0,0,1,1.728.992L30.245,24.421a2,2,0,0,1-1.728,3.008H3.482a2,2,0,0,1-1.728-3.008L14.272,2.962A1.982,1.982,0,0,1,16,1.969Z"
        transform="translate(4 4)"
        fill="#27c1fc"
      />
    </svg>
  );
};
