import { isAfter, subDays } from "date-fns";
import { v4 } from "uuid";

import { Employee } from "../../Employee";
import { OnboardingTask } from "../OnboardingTask";
import { OnboardingTaskMemo } from "../OnboardingTaskMemo";

export class OnboardingSimpleTask extends OnboardingTask {
  id: string;
  type = "SIMPLE_TASK" as const;
  filePaths: (string | File)[];
  memos: OnboardingTaskMemo[];

  constructor(
    init: Omit<OnboardingSimpleTask, "isExpiredByEmployee" | "shouldDelivery" | "shouldRemind">
  ) {
    super(init);
    this.id = init.id;
    this.filePaths = init.filePaths;
    this.memos = init.memos;
  }

  public static create(
    params: Optional<ConstructorParameters<typeof OnboardingSimpleTask>[0], "id">
  ) {
    return new OnboardingSimpleTask({
      ...params,
      id: params.id ?? v4(),
    });
  }

  /**
   * 入社者を元にタスクをリマインドするかどうかを返すメソッド
   * 期日の3日前以降が通知の対象
   * @param employee 入社者
   * @returns boolean
   */
  shouldRemind(employee: Employee, currentDate: Date = new Date()): boolean {
    if (this.status === "COMPLETED") return false;
    if (this.assigneeRole === "NOT_SET") return false;
    if (!this.dueDate) return false;

    const date = this.dueDate.calculateDateByEmployee(employee);

    if (!date) return false;

    return isAfter(currentDate, subDays(date, 3));
  }
}
