import { isEmpty } from "lodash";

import { OnboardingExperienceRepository } from "~/infrastructure/api/onboardingExperienceRepository";
import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";

const onboardingExperienceRepository = new OnboardingExperienceRepository();
const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();

export class DeleteOnboardingExperienceUseCase {
  async execute(onboardingExperienceId: string): Promise<void> {
    await onboardingExperienceRepository.deleteById(onboardingExperienceId);

    const tasks = await onboardingExperienceTaskRepository.findByExperienceIds([
      onboardingExperienceId,
    ]);

    if (!isEmpty(tasks)) {
      const batch = queryOperator.batch();

      tasks.forEach((task) => {
        onboardingExperienceTaskRepository.insertDeleteTaskInBatch(batch, task.id);
      });

      await queryOperator.commit(batch);
    }
  }
}
