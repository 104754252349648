import { Box, List } from "@material-ui/core";
import { typedEntries } from "@onn/common";
import React, { FC, ReactNode, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LinkKey, useViewableLinks } from "./hooks/useViewableLinks/useViewableLinks";

import { ContactContextV2 } from "~/components/providers";
import { Divider, GlobalNaviMenu, Header } from "~/components/uiParts";
import { AccountSettingButton } from "~/components/uiParts/GlobalNaviMenu/parts/AccountSettingButton";
import { ListItem } from "~/components/uiParts/GlobalNaviMenu/parts/ListItem";
import { LogoutButton } from "~/components/uiParts/GlobalNaviMenu/parts/LogoutButton";
import { useCurrentUser } from "~/hooks/employee";
import { useLibraries } from "~/hooks/library";
import { useGetNewHireOnboardingTasks } from "~/hooks/portal";
import { useIsPreview } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";

type Props = {
  children: ReactNode;
  fixedWindowHeight?: boolean;
};

export const PortalLayout: FC<Props> = ({ fixedWindowHeight, children }) => {
  const navigate = useNavigate();
  const isPreview = useIsPreview();
  const { currentUser } = useCurrentUser();
  const { tenantSettings } = useTenantSettings();
  const { data: libraries } = useLibraries(currentUser.tenantId);

  const { totalUnreadCount, contactRooms } = useContext(ContactContextV2);

  const { onboardingTasks } = useGetNewHireOnboardingTasks(
    currentUser.id,
    currentUser.tenantId,
    isPreview
  );
  const viewableLinks = useViewableLinks({
    currentUser,
    tenantSettings,
    contactRooms,
    libraries,
    onboardingTasks,
  });

  const isDisplayContactBadge = useMemo(() => 0 < totalUnreadCount, [totalUnreadCount]);

  const handleClickLogo = () => {
    navigate({ pathname: "/portal", search: location.search });
  };

  const featurePaths: Record<LinkKey, { label: string; to: string; badgeLabel?: string }> = {
    home: { label: "ホーム", to: "/portal" },
    lineQr: { label: "LINE公式アカウント", to: "/portal/line_qr" },
    onboardingTasks: { label: "タスク", to: "/portal/onboarding_tasks" },
    surveys: { label: "アンケート", to: "/portal/surveys" },
    libraries: { label: "ライブラリ", to: "/portal/libraries" },
    contactRooms: {
      label: "コンタクト",
      to: "/portal/contact_rooms",
      badgeLabel: 0 < totalUnreadCount ? totalUnreadCount.toString() : undefined,
    },
  };
  const featureLinks = typedEntries(featurePaths).map(([key, value]) => ({
    isView: viewableLinks.includes(key),
    ...value,
  }));

  return (
    <StyledDivWrapper $fixedWindowHeight={fixedWindowHeight}>
      <Header
        currentUser={currentUser}
        onClickLogo={handleClickLogo}
        drawerMenu={
          <GlobalNaviMenu
            isBadge={isDisplayContactBadge}
            renderDrawerContent={(closeDrawer: () => void) => (
              <List
                style={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
                disablePadding
              >
                {featureLinks.map(
                  (link, i) =>
                    link.isView && (
                      <ListItem
                        key={i}
                        label={link.label}
                        to={link.to}
                        badgeLabel={link.badgeLabel}
                        onClick={closeDrawer}
                      />
                    )
                )}
                <Divider margin={0} orientation="horizontal" />
                <AccountSettingButton currentUser={currentUser} onClick={closeDrawer} />
                <Divider margin={0} orientation="horizontal" />
                <Box pb="16px">
                  <LogoutButton />
                </Box>
              </List>
            )}
          />
        }
      />
      <StyledBox display="flex" justifyContent="center" $fixedWindowHeight={fixedWindowHeight}>
        <StyledDiv $fixedWindowHeight={fixedWindowHeight}>{children}</StyledDiv>
      </StyledBox>
    </StyledDivWrapper>
  );
};

const StyledDivWrapper = styled.div<{ $fixedWindowHeight?: boolean }>`
  height: ${({ $fixedWindowHeight }) => ($fixedWindowHeight ? "100%;" : "auto")};
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledBox = styled(Box)<{ $fixedWindowHeight?: boolean }>`
  height: ${({ $fixedWindowHeight, theme }) =>
    $fixedWindowHeight ? `calc(100% - ${theme.constants.HEADER_HEIGHT}px)` : "auto"};
`;

const StyledDiv = styled.div<{ $fixedWindowHeight?: boolean }>`
  ${({ $fixedWindowHeight, theme }) =>
    $fixedWindowHeight
      ? "height: 100%"
      : `min-height: calc(100vh - ${theme.constants.HEADER_HEIGHT}px)`};
  width: 100vw;
  max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`};
  // モバイル端末用のブレークポイントとはあえてずらしている
`;
