import { Box, Paper, Divider, TextField } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Survey, Question } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { AdditionalQuestion, DefaultQuestion } from "~/components/domains/transactions";
import { Button, Icon, Typography, TextContext, Tooltip } from "~/components/uiParts";

interface Props {
  survey: Survey;
  additionalQuestions: Question[];
  changeSurvey: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
  addQuestion: (survey: Survey) => void;
  changeQuestion: (
    event: React.ChangeEvent<{
      id: string;
      name: string;
      value: string;
    }>,
    survey: Survey,
    targetQuestion: Question
  ) => void;
  deleteQuestion: (survey: Survey, targetQuestion: Question) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    surveyPaper: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(40)}px ${theme.spacing(
        5
      )}px`,
    },
    surveyDescription: {
      fontWeight: "normal",
      color: theme.palette.text.primary,
    },
    info: {
      cursor: "pointer",
    },
  })
);

export const SurveyMain: FC<Props> = ({
  survey,
  additionalQuestions,
  changeSurvey,
  addQuestion,
  changeQuestion,
  deleteQuestion,
}) => {
  const classes = useStyles();
  const defaultQuestionCount = survey.questions.filter((v) => v.mode === "DEFAULT").length;

  const defaultQuestionList =
    survey &&
    survey.questions
      .filter((v) => v.mode === "DEFAULT")
      .map((question, i) => (
        <DefaultQuestion key={`${question.name}_${i}`} index={i + 1} question={question} />
      ));

  const additionalQuestionList = additionalQuestions.map((question: Question, i: number) => (
    <AdditionalQuestion
      key={question.name}
      index={defaultQuestionCount + i + 1}
      survey={survey}
      question={question}
      changeQuestion={(e) => changeQuestion(e, survey, question)}
      deleteQuestion={deleteQuestion}
    />
  ));

  return (
    <Paper square elevation={10} classes={{ root: classes.surveyPaper }}>
      <Box display="flex">
        <Typography variant="body2" bold>
          アンケート説明文
        </Typography>
        <Box pl={2} className={classes.info}>
          <Tooltip
            title="[入社者名][管理者名]のコードは、Onnから送信される際登録されているユーザー名に自動で置き換わります。"
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
      </Box>
      <Box mb={2} />
      <Divider />
      <TextField
        multiline
        fullWidth
        margin="normal"
        defaultValue={survey.description}
        onChange={changeSurvey}
        minRows={4}
        name="description"
        helperText="※このアンケート説明文は、アンケート回答画面の冒頭に表示されます"
        InputProps={{
          classes: {
            input: classes.surveyDescription,
          },
        }}
      />
      <Box mb={10} />
      <Typography variant="body2" bold>
        アンケート内容(必須配信内容)
      </Typography>

      <Box mb={2} />
      <Divider />
      {defaultQuestionList}
      <Box mb={11} />
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="body2" bold>
            追加質問項目(任意・自由記述)
          </Typography>
        </Box>
        <StyleTypography variant="caption" color="textSecondary">
          ※最大3問まで任意の質問を追加できます。
        </StyleTypography>
      </Box>
      <Box mb={2} />
      <Divider />
      <Box mb={2} />
      <TextContext isShowBg bold padding={2}>
        ※追加質問項目は、管理者・バディ・サポートメンバーに回答が公開される設問です。
      </TextContext>
      {additionalQuestionList}
      <Box mb={6} />
      {additionalQuestions.length < 3 && (
        <StyledButtonWrapper>
          <Button
            fullWidth
            color="default"
            borderRadius="regular"
            onClick={() => addQuestion(survey)}
            variant="outlined"
            startIcon={<Icon icon="add" color="lightGrey" size="md" />}
          >
            質問を追加
          </Button>
        </StyledButtonWrapper>
      )}
    </Paper>
  );
};

const StyledButtonWrapper = styled.div`
  /* ボタンにスタイルを当てるためのワークアラウンド */
  .MuiButtonBase-root > div {
    color: ${(props) => props.theme.palette.grey[200]};
    border: ${(props) => `2px dashed ${props.theme.palette.grey[200]}`};
    font-size: 14px;
    border-radius: 10px;
    height: 54px;
  }
`;

const StyleTypography = styled(Typography)`
  &.MuiTypography-caption {
    font-size: 10px;
  }
`;
