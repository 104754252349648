import { TransactionStatus } from "@onn/common";
import React, { useEffect, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SurveyLanding } from "~/components/domains/transactions";
import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar } from "~/hooks/shared";
import { useSurveyTransaction } from "~/hooks/transaction";
import { NotFound } from "~/pages/NotFound";

export const JourneySurvey: FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { id: surveyTransactionId } = useParams<"id">();
  const { data: surveyTransaction, isValidating: isValidatingSurveyTransaction } =
    useSurveyTransaction(surveyTransactionId);

  const { enqueueSnackbar } = useSnackbar();

  const isCurrentUser = surveyTransaction && currentUser.id === surveyTransaction.employeeId;
  const isDone = surveyTransaction && surveyTransaction.status === TransactionStatus.DONE;

  useEffect(() => {
    if (surveyTransaction && !isCurrentUser) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    } else if (surveyTransaction && isDone) {
      navigate("/portal/surveys/thanks", { state: { survey: surveyTransaction.contents } });
    }
  }, [enqueueSnackbar, isCurrentUser, isDone, navigate, surveyTransaction]);

  if (isValidatingSurveyTransaction) {
    return <Loading size="large" fullHeight />;
  }

  if (!surveyTransaction?.contents) {
    return <NotFound />;
  }

  return (
    <SurveyLanding
      survey={surveyTransaction.contents}
      surveyTransaction={surveyTransaction}
      userName={currentUser.getName()}
    />
  );
};
