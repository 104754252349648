import { Box, Menu } from "@material-ui/core";
import {
  DifferenceDate,
  FileType,
  Employee,
  OnboardingSimpleTask,
  displayAssigneeRoleMap,
  OnboardingExperienceTask,
  OnboardingTaskFactory,
  OnboardingTaskType,
  taskTypes,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useMemo, useCallback, FC } from "react";
import styled from "styled-components";

import { DifferenceDateForm } from "../../differenceDate";
import { SelectEmployeesFormByPlatform } from "../../employees/SelectEmployeesFormByPlatform";

import {
  Button,
  Modal,
  Icon,
  SelectForm,
  TextareaAutosizeAttachableFile,
  TextField,
  Tooltip,
  Typography,
} from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import { useSlackUsers } from "~/hooks/slackUser";
import { mixin } from "~/util";

const MAX_FILE_SIZE_MB = 10;
const UPLOAD_ACCEPTED_FILE_TYPES: FileType[] = [
  "word",
  "excel",
  "powerPoint",
  "pdf",
  "image",
  "zip",
];

type Props = {
  open: boolean;
  onCancel: () => void;
  employee: Employee;
  task?: OnboardingTaskType;
  allEmployeesWithDeleted: Employee[];
  onSubmit: (newTask: OnboardingTaskType, emailsWithoutOnnAccount: string[]) => Promise<void>;
};

export const EditTaskModal: FC<Props> = ({
  open,
  onCancel,
  employee,
  task,
  allEmployeesWithDeleted,
  onSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { slackUsers } = useSlackUsers();

  const [isSending, setIsSending] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    task
      ? allEmployeesWithDeleted.filter((v) => task?.assigneeIds.includes(v.id)).map((v) => v.email)
      : []
  );

  const [newTask, setNewTask] = useState<OnboardingTaskType>(
    OnboardingTaskFactory.createOnboardingTask(
      task
        ? { ...task }
        : {
            id: undefined,
            title: "",
            body: "",
            filePaths: [],
            assigneeRole: "NEW_HIRE",
            dueDate:
              // 入社日が存在すれば本日が期日になるようなDueDateが初期値になる
              employee.joinAt
                ? DifferenceDate.convertDateIntoDifferenceDate(
                    new Date(),
                    new Date(employee.joinAt)
                  )
                : new DifferenceDate(),
            deliveryDate: new DifferenceDate({ amount: 7, unit: "DAY", direction: "BEFORE" }),
            status: "NOT_STARTED",
            employeeId: employee.id,
            tenantId: employee.tenantId,
            assigneeIds: [],
            createdAt: new Date(),
            updatedAt: new Date(),
            memos: [],
            isRequired: true,
            index: 0,
            type: "SIMPLE_TASK",
          }
    )
  );

  const handleSubmit = useCallback(async () => {
    const emailsWithoutOnnAccount: string[] = [];

    const existedAllEmployees = selectedEmails.flatMap((email) => {
      const slackUser = slackUsers.find((v) => v.email === email);
      // NOTE: Slack上にユーザーの情報が存在していた時には新規招待せずに紐づいたユーザーをアサインする
      const employee = allEmployeesWithDeleted.find((v) => {
        if (v.email === email) return true;
        if (!slackUser) return false;
        return v.slackUserId === slackUser.slackUserId;
      });

      if (employee) {
        return employee;
      }
      emailsWithoutOnnAccount.push(email);
      return [];
    });

    if (existedAllEmployees.some((existedEmployee) => existedEmployee.deleted)) {
      return enqueueSnackbar("削除されたユーザーにタスクを担当させることはできません", {
        variant: "error",
      });
    }

    setIsSending(true);
    await onSubmit(
      OnboardingTaskFactory.createOnboardingTask({
        ...newTask,
        assigneeIds: existedAllEmployees.map((v) => v.id),
      }),
      emailsWithoutOnnAccount
    ).then(() => {
      setIsSending(false);
      onCancel();
    });
  }, [
    allEmployeesWithDeleted,
    enqueueSnackbar,
    newTask,
    onCancel,
    onSubmit,
    selectedEmails,
    slackUsers,
  ]);

  const handleChangeSettings = useCallback(
    (newObject: Omit<Partial<OnboardingTaskType>, "type">) => {
      setNewTask((prev) => OnboardingTaskFactory.createOnboardingTask({ ...prev, ...newObject }));
    },
    []
  );

  const handleChangeAttachedFiles = useCallback(
    (newFiles: (File | Pick<File, "name">)[]) => {
      handleChangeSettings({
        filePaths: newFiles.map((v) => {
          return v instanceof File ? v : v.name;
        }),
      });
    },
    [handleChangeSettings]
  );

  const isInvalidNewTask = useMemo(() => {
    return (
      // BY_NAMEだが担当者の指定がされていないかつslackユーザーも選択されていないときは不正
      (newTask.assigneeRole === "BY_NAME" && isEmpty(selectedEmails)) ||
      !newTask.title ||
      newTask.title.length > OnboardingSimpleTask.MAX_TITLE_NUM ||
      !newTask.body ||
      !newTask.dueDate.amount ||
      newTask.dueDate.amount <= 0
    );
  }, [newTask.assigneeRole, newTask.body, newTask.dueDate.amount, newTask.title, selectedEmails]);

  const Content = (
    <Box>
      {newTask.type !== "MESSAGE_TASK" && (
        <>
          <Box display="flex" gridGap="12px" alignItems="center">
            <Typography variant="body2" bold>
              担当者
            </Typography>
            <Tooltip
              title="「管理者、 バディ、サポートメンバー」を選択すると、入社者登録後に設定された該当のロールのメンバーが自動的にセットされます。「特定のメンバー指定」を選択すると、前入社者に固定で指定した特定のメンバーが自動的にセットされます。「担当者を後で設定」を選択すると、入社者登録後に手動でメンバーを設定することができます。"
              placement="top-start"
            >
              <Icon icon="help" size="sm" color="grey" />
            </Tooltip>
          </Box>
          <Box mt="16px" display="flex" gridGap="24px" mb="36px">
            <StyledSelectForm
              selected={newTask.assigneeRole}
              onChange={(e) => {
                handleChangeSettings({
                  assigneeRole: e.target.value as OnboardingSimpleTask["assigneeRole"],
                  assigneeIds: [],
                });
                setSelectedEmails([]);
              }}
              menuItems={OnboardingExperienceTask.assigneeRoles.flatMap((v) => {
                // 担当者をあとで設定はそれがすでに選択されたタスクのときにのみ選択肢に表示される
                if (v === "NOT_SET" && task?.assigneeRole !== "NOT_SET") return [];
                // タスクの編集時は担当者をあとで設定が選択することはできない
                return { value: v, name: displayAssigneeRoleMap[v], disabled: v === "NOT_SET" };
              })}
            />
            {newTask.assigneeRole === "BY_NAME" && (
              <>
                <Box width="240px">
                  <Button
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    borderRadius="regular"
                    variant="outlined"
                    color="default"
                    fullWidth
                  >
                    <Box width="100%" display="flex" alignItems="center" gridGap="4px">
                      {selectedEmails.length > 0 ? (
                        <Typography variant="body2" noWrap>
                          {selectedEmails.length}名のメンバー
                        </Typography>
                      ) : (
                        <Typography variant="body2">メンバーを選択</Typography>
                      )}
                      <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
                    </Box>
                  </Button>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: -8, horizontal: "right" }}
                >
                  <Box width="240px">
                    <SelectEmployeesFormByPlatform
                      notAllowEmails={[]}
                      selectedEmails={selectedEmails}
                      onSelectEmails={setSelectedEmails}
                      rowRendererMode="checkbox"
                    />
                  </Box>
                </Menu>
              </>
            )}
          </Box>
        </>
      )}
      <Box display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          タイトル
        </Typography>
      </Box>
      <Box mt="16px" display="flex" gridGap="24px">
        <TextField
          name="title"
          value={newTask.title}
          fullWidth
          variant="outlined"
          placeholder="例：入社当日の予定を確認する"
          error={newTask.title.length > OnboardingSimpleTask.MAX_TITLE_NUM}
          onChange={(e) => handleChangeSettings({ title: e.target.value })}
          maxTextCount={OnboardingSimpleTask.MAX_TITLE_NUM}
        />
      </Box>
      <Box mt="36px" display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          説明文
        </Typography>
        <Tooltip
          title="入社者にはタスクに対して完了ボタンが表示され、ステータスが変化すると管理画面から進捗を確認することができます。完了状態や完了条件がわかる説明文やファイルの添付をしてください。"
          placement="top-start"
        >
          <Icon icon="help" size="sm" color="grey" />
        </Tooltip>
      </Box>
      <Box mt="16px">
        <TextareaAutosizeAttachableFile
          value={newTask.body}
          fullWidth
          placeholder={`例：入社にあたって、入社当日の集合場所やオリエンテーションの流れなど、よくある質問をまとめた資料をご用意しました！\n 入社までに以下のURLを確認して、最高のDAY1を迎えましょう！\nhttp://onn.xxxxxxxxxx.com`}
          onChange={(e) => handleChangeSettings({ body: e.target.value })}
          minRows={4}
          maxFileSizeMb={MAX_FILE_SIZE_MB}
          accepts={UPLOAD_ACCEPTED_FILE_TYPES}
          onChangeFiles={handleChangeAttachedFiles}
          attachedFiles={
            newTask.filePaths
              ? newTask.filePaths.map((v) => (typeof v === "string" ? { name: v } : v))
              : []
          }
        />
      </Box>
      <Box mt="36px">
        <Typography variant="body2" bold>
          期日
        </Typography>
        <Typography variant="caption">
          タスクの完了期日を設定してください。
          {employee.joinAt &&
            newTask?.deliveryDate &&
            "通知タイミングより前の日付は設定できません。"}
        </Typography>
      </Box>
      <Box mt="16px">
        <DifferenceDateForm
          differenceDate={newTask.dueDate}
          onChange={(selectedDate) =>
            handleChangeSettings({
              dueDate: new DifferenceDate({ ...newTask.dueDate, ...selectedDate }),
            })
          }
          units={
            newTask.type === "MESSAGE_TASK"
              ? ["DAY", "BUSINESS_DAY", "MONTH"]
              : ["DAY", "BUSINESS_DAY"]
          }
          isDeliveryTime={newTask.type === taskTypes.MESSAGE_TASK}
        />
      </Box>
      {newTask.deliveryDate && (
        <>
          <Box mt="36px" display="flex" gridGap="12px" alignItems="center">
            <Typography variant="body2" bold>
              依頼タイミング
            </Typography>
          </Box>
          <Box mt="16px">
            <StyledMutedTypography variant="caption">
              {`入社者にタスクの依頼が通知されるタイミングの設定ができます。\nタスクの完了までにかかる時間を考慮して、期日より前のタイミングを設定してください。`}
            </StyledMutedTypography>
          </Box>
          <Box mt="16px">
            <DifferenceDateForm
              differenceDate={newTask.deliveryDate}
              onChange={(selectedDate) => {
                handleChangeSettings({
                  deliveryDate: new DifferenceDate({
                    ...newTask.deliveryDate,
                    ...selectedDate,
                  }),
                });
              }}
              units={["DAY"]}
            />
          </Box>
        </>
      )}
    </Box>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        disabled={isInvalidNewTask || isSending}
        onClick={handleSubmit}
      >
        {task ? "保存" : "追加"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title={task ? "タスク編集" : "タスク追加"}
      content={Content}
      footer={Footer}
      onCancel={onCancel}
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledSelectForm = styled(SelectForm)`
  width: 240px;
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
