import { httpsCallableFromURL } from "firebase/functions";
import urlJoin from "url-join";

import { functions } from "~/config/firebase";
import { getStorageAccessors } from "~/util";

const generateBaseUrl1stGen = (functionName: string) => {
  return process.env.NODE_ENV === "localhost"
    ? urlJoin("http://localhost:5001/onn-midcareer-staging/asia-northeast1", functionName)
    : process.env.NODE_ENV === "production"
    ? urlJoin(`https://asia-northeast1-onn-midcareer-production.cloudfunctions.net/${functionName}`)
    : urlJoin(`https://asia-northeast1-onn-midcareer-staging.cloudfunctions.net/${functionName}`);
};

/**
 * cloud functionのURLを生成する
 * @param functionName
 * @returns url
 */
export const generateBaseUrl = (functionName: string) => {
  return process.env.NODE_ENV === "localhost"
    ? urlJoin("http://localhost:5001/onn-midcareer-staging/asia-northeast1", functionName)
    : process.env.NODE_ENV === "production"
    ? urlJoin(`https://${functionName}-nzp7oulcea-an.a.run.app`)
    : urlJoin(`https://${functionName}-6cgusats4a-an.a.run.app`);
};

const { retrieveValue } = getStorageAccessors(localStorage);

// onCall関数の場合headersに値をセットできないためbodyに詰めて送る
type DataAsHeader = {
  tenantId: string | null;
  spaceId: string | null;
};

export const functionOperator = {
  httpsCall: <RequestData = unknown, ResponseData = unknown>(
    functionName: string,
    data?: RequestData | null
  ) => {
    const tenantId = retrieveValue<string>("tenantId");
    const requestData = data ? { ...data, tenantId } : ({ tenantId } as RequestData & DataAsHeader);

    return httpsCallableFromURL<RequestData, ResponseData>(
      functions,
      generateBaseUrl1stGen(functionName)
    )(requestData);
  },

  /**
   * 第二世代のCloudFunction関数
   */
  httpsCallFor2ndGen: <RequestData = unknown, ResponseData = unknown>(
    functionName: string,
    data?: RequestData | null
  ) => {
    const tenantId = retrieveValue<string>("tenantId");

    const requestData = data ? { ...data, tenantId } : ({ tenantId } as RequestData & DataAsHeader);

    return httpsCallableFromURL<RequestData, ResponseData>(
      functions,
      generateBaseUrl(functionName)
    )(requestData);
  },
};
