import { OnboardingMessageTask, Employee } from "@onn/common";

import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";

const onboardingTaskRepository = new OnboardingTaskRepository();

export class FindMessageTasksForNewHireUseCase {
  async execute(newHire: Employee): Promise<OnboardingMessageTask[]> {
    const onboardingTasks = await onboardingTaskRepository.findByEmployeeId(newHire.id);

    const messageTasks = onboardingTasks.filter(
      (v): v is OnboardingMessageTask => v.type === "MESSAGE_TASK"
    );

    return messageTasks.filter((v) => v.isDisplayToNewHire(newHire));
  }
}
