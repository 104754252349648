import { Employee, OnboardingExperience } from "@onn/common";
import { useCallback } from "react";

// ビルダーによって編集可能なEmployeeを返す関数を提供するhooks
export const useGetEditableEmployees = (): {
  getEditableEmployees: (
    onboardingExperience: OnboardingExperience,
    allEmployees: Employee[],
    admins: Employee[],
    currentUser: Employee
  ) => Employee[];
} => {
  /**
   * ビルダーによって編集可能なEmployeeを返す関数を提供する
   * @param {OnboardingExperience} onboardingExperience
   * @param {Employee[]} allEmployees
   * @param {Employee[]} admins
   * @returns employees
   */
  const getEditableEmployees = useCallback(
    (
      onboardingExperience: OnboardingExperience,
      allEmployees: Employee[],
      admins: Employee[],
      currentUser: Employee
    ) => {
      const { editableEmployeeIds } = onboardingExperience;

      if (!editableEmployeeIds) {
        // 自身を先頭に表示する
        if (admins.some((v) => v.id === currentUser.id)) {
          return [currentUser, ...admins.filter((v) => v.id !== currentUser.id)];
        }
        return admins;
      }

      const employees = [
        ...allEmployees.filter((v) => editableEmployeeIds?.includes(v.id)),
        ...admins,
      ];

      // 自身を先頭に表示する
      if (employees.some((v) => v.id === currentUser.id)) {
        return [currentUser, ...employees.filter((v) => v.id !== currentUser.id)];
      }
      return employees;
    },
    []
  );

  return { getEditableEmployees };
};
