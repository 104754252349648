import { Employee } from "@onn/common";
import React, { FC } from "react";

import { UserIconGroup } from "~/components/uiParts";

type Props = {
  employees: Employee[];
};

export const EditableEmployeeIcons: FC<Props> = ({ employees }) => {
  return (
    <UserIconGroup
      tooltip
      usersInfo={employees.map((employee) => ({
        username: employee.getName(),
        profileIconImageUrl: employee.profileIconImageUrl,
      }))}
    />
  );
};
