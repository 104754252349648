import { Box } from "@material-ui/core";
import { Employee, OnboardingTaskType } from "@onn/common";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  newHire: Employee;
  onboardingTask: OnboardingTaskType;
};

// ====================
// main
// ====================

export const DueDateSection: FC<Props> = ({ newHire, onboardingTask }) => {
  // ====================
  // main component
  // ====================

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="caption" bold color="textPrimary">
        期日
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {`${onboardingTask.dueDate.getDisplayTextByEmployee(newHire, "yyyy/MM/dd")} まで`}
      </Typography>
    </Box>
  );
};
