import useSWR, { SWRResponse } from "swr";

import { FindUncompletedCustomSurveyAlertsUseCase } from "~/service/usecases/surveyAlert";
import { CustomOnboardingSurveysForAlert } from "~/service/usecases/surveyAlert/FindUncompletedCustomSurveyAlertsUseCase/FindUncompletedCustomSurveyAlertsUseCase";
export type { CustomOnboardingSurveysForAlert };

const findUncompletedCustomSurveyAlertsUseCase = new FindUncompletedCustomSurveyAlertsUseCase();

/**
 * @param tenantId tenantのid
 */
export const useUncompletedCustomSurveyAlerts = (
  tenantId: string
): SWRResponse<CustomOnboardingSurveysForAlert[], Error> => {
  const keys = tenantId ? [`uncompleted-survey-alerts-${tenantId}`, tenantId] : null;

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    findUncompletedCustomSurveyAlertsUseCase.execute(tenantId).then((res) => res)
  );
};
