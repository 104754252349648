import { Employee } from "@onn/common";
import React, { FC } from "react";

import { MentorAndSupportMemberList } from "~/components/domains/employees";
import { Modal } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  onClickAddMentorButton: () => void;
  onClickAddAssignSupportMember: () => void;
  onClickChangeMentorButton: (mentor: Employee) => void;
  onClickDismissButton: (employee: Employee) => void;
  mentor?: Employee;
  supportMembers?: Employee[];
  onlyView?: boolean;
};

export const ManageMentorAndSupportMemberModal: FC<Props> = ({
  open,
  onCancel,
  onClickAddMentorButton,
  onClickAddAssignSupportMember,
  onClickChangeMentorButton,
  onClickDismissButton,
  mentor,
  supportMembers,
  onlyView,
}) => {
  return (
    <Modal
      open={open}
      title="受け入れチーム設定"
      content={
        <MentorAndSupportMemberList
          mentor={mentor}
          supportMembers={supportMembers}
          onClickAddMentorButton={onClickAddMentorButton}
          onClickAddAssignSupportMember={onClickAddAssignSupportMember}
          onClickChangeMentorButton={onClickChangeMentorButton}
          onClickDismissButton={onClickDismissButton}
          onlyView={onlyView}
        />
      }
      footer={<></>}
      onCancel={onCancel}
    />
  );
};
