import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import React, { useEffect, useState, ChangeEvent, FC, useCallback } from "react";
import styled from "styled-components";

import {
  Button,
  Icon,
  UserIcon,
  Typography,
  Modal,
  SelectForm,
  Tooltip,
} from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { mixin } from "~/util";

const menuItems = [
  { value: Role.ADMIN, name: "管理者" },
  { value: Role.MEMBER, name: "メンバー" },
];

const unchangeableRole = [Role.DEPARTMENT_ADMIN];

type Props = {
  open: boolean;
  employee: Employee;
  onCancel: () => void;
  onSubmit: (employee: Employee, selectedRole: Role, assignedAsNewcomer?: boolean) => Promise<void>;
};

export const ChangeRoleModal: FC<Props> = ({ open, employee, onCancel, onSubmit }) => {
  const { handleModal } = useModal();

  const [selectedRole, setSelectedRole] = useState<Role>(employee.role);
  const [errorText, setErrorText] = useState("");
  const [sending, setSending] = useState(false);

  const unchangeableMenuItems = [
    { value: employee.role, name: Employee.displayRoleMap[employee.role] },
  ];

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    setSelectedRole(e.target.value as Role);
  };

  const handleSubmit = useCallback(
    async (employee: Employee, selectedRole: Role, assignedAsNewcomer?: boolean) => {
      setSending(true);
      await onSubmit(employee, selectedRole, assignedAsNewcomer)
        .then(() => {
          onCancel();
        })
        .finally(() => {
          setSending(false);
        });
    },
    [onCancel, onSubmit]
  );

  /**
   * 入社者 -> ADMINに変更する場合のみ確認モーダルを開く
   */
  const handleOpenConfirmChangeRoleToAdminModal = useCallback(
    (
      employee: Employee,
      selectedRole: Role.ADMIN,
      assignedAsNewcomer = false // ADMIN権限を付与するので入社者ステータスはfalseにする
    ) => {
      handleModal({
        name: "confirmChangeRoleToAdminModal",
        args: {
          employee,
          onSubmit: async () => {
            await handleSubmit(employee, selectedRole, assignedAsNewcomer);
          },
        },
      });
    },
    [handleModal, handleSubmit]
  );

  useEffect(() => {
    if (employee.role === Role.DEPARTMENT_ADMIN) {
      setErrorText("部門管理者権限は変更できません");
    } else if (!employee.uid) {
      setErrorText("招待中のアカウントは権限を変更できません");
    } else {
      setErrorText("");
    }
  }, [employee, selectedRole]);

  return (
    <Modal
      open={open}
      title="権限変更"
      content={
        <div>
          <Box mb={5} textAlign="center">
            <UserIcon
              username={employee.getName()}
              size="medium"
              profileIconImageUrl={employee.profileIconImageUrl}
              borderColor="primary"
            />
            <StyledTypography variant="body2" bold>
              {employee.getName().trim() || employee.email}
            </StyledTypography>
          </Box>

          <Box pr={10} pl={10}>
            <Box display="flex" alignItems="center">
              <StyledTypography variant="body2" bold color="textSecondary" display="inline">
                権限
              </StyledTypography>
              <Tooltip
                title="管理者権限はすべての入社者の情報を閲覧できます。主に人事以上の役割のメンバーを割り当ててください。 メンバー権限はアサインされている入社者の情報のみを閲覧できます。主にバディやサポートメンバーになるメンバーを割り当ててください。"
                placement="top"
              >
                <Icon icon="help" size="sm" color="grey" />
              </Tooltip>
            </Box>
            <SelectForm
              fullWidth
              selected={selectedRole}
              onChange={handleChange}
              menuItems={
                unchangeableRole.includes(employee.role) ? unchangeableMenuItems : menuItems
              }
              errorText={errorText}
            />
          </Box>
        </div>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={
              // 入社者のロールがADMINに変更される場合のみ確認モーダルを開く、それ以外はそのまま変更を反映する
              employee.isNewcomer() && selectedRole === Role.ADMIN
                ? () => handleOpenConfirmChangeRoleToAdminModal(employee, selectedRole)
                : () => handleSubmit(employee, selectedRole)
            }
            disabled={employee.role === selectedRole || Boolean(errorText) || sending} // 変更があったときのみ活性化
          >
            設定する
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledTypography = styled(Typography)`
  margin: 8px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
