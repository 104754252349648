import { EmployeeToDisplay, plainToInstance } from "@onn/common";
import { useCallback, useMemo } from "react";
import useSWR, { KeyedMutator, SWRResponse, useSWRConfig } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";

const getKey = ({ tenantId }: { tenantId: string }) => {
  return {
    endpoint: "/get_all_newcomers",
    tenantId,
  } as const;
};

/**
 * 権限がメンバーの場合自分がアサインされている入社者のみを取得する
 */
export const useAllNewcomers = (): SWRResponse<EmployeeToDisplay[], Error> => {
  const { currentUser } = useCurrentUser();

  return useSWR(getKey({ tenantId: currentUser.tenantId }), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);

    const employees = response.data.map((v) => {
      return plainToInstance(v);
    });

    return employees;
  });
};

export const useMutateAllNewcomers = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();

  const key = useMemo(() => getKey({ tenantId: currentUser.tenantId }), [currentUser.tenantId]);

  const mutateAllNewcomers: KeyedMutator<EmployeeToDisplay[] | undefined> = useCallback(
    async (data, opts) => {
      if (!data) {
        return mutate(key);
      }
      return mutate(key, data, opts);
    },
    [key, mutate]
  );

  return { mutateAllNewcomers };
};
