import { Box } from "@material-ui/core";
import { Department, MidCarrierPropertyForInvite, OnboardingExperience } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "./DownloadSampleFileStepContent";
import { UploadFileStepContent } from "./UploadFileStepContent";

import { Modal, VerticalStepper } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  onboardingExperiences: OnboardingExperience[];
  onClickUploadButton: (inputValues: MidCarrierPropertyForInvite[]) => Promise<void>;
  inviteType: "newHire";
  departments: Department[];
};

export const InviteMidCarrierWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onboardingExperiences,
  onClickUploadButton,
  ...props
}) => {
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            onboardingExperiences={onboardingExperiences}
            {...props}
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            onClickCancellation={onCancel}
            onboardingExperiences={onboardingExperiences}
            onClickUploadButton={onClickUploadButton}
            onUploadNewHires={onCancel}
            {...props}
          />
        );
    }
  }, [activeStep, onboardingExperiences, props, onClickUploadButton, onCancel]);

  return (
    <Modal
      open={open}
      title={"CSVアップロード"}
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
