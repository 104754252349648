import { useCallback } from "react";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

/**
 * 招待メールを再送するhooks
 * @returns resendInvitation リクエスト関数
 */
export const useResendInvitation = (): {
  resendInvitation: (employeeId: string) => Promise<void>;
} => {
  const resendInvitation = useCallback(async (employeeId: string) => {
    await EmployeeUseCase.resendInvitation(employeeId).then((res) => res);
  }, []);

  return { resendInvitation };
};
