import { Box } from "@material-ui/core";
import { OnboardingExperience } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

// 小文字のonboardingExperienceと競合してインポートできないため ~/domain/OnboardingExperience/OnboardingExperience からインポートしている
// TODO: onboardingExperience.ts をリネームしたタイミングで ~/domain/OnboardingExperience に書き換える

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  onboardingExperiences: OnboardingExperience[];
  selectedIds: string[];
  onSwitchCheckBox: (onboardingExperienceId: string) => void;
};

// 配信するエクスペリエンスを選択するステップ
export const SelectExperienceStep: FC<Props> = ({
  onboardingExperiences,
  selectedIds,
  onSwitchCheckBox,
}) => {
  return (
    <Box>
      <Typography variant="body1" color="textPrimary">
        {`招待する入社者に該当する受け入れ体験を選択してください。\n指定された体験に設定されたタスクが、入社者と受け入れチームに配信されます。`}
      </Typography>
      <Box display="flex" flexDirection="column" mt="32px">
        {onboardingExperiences.map((onboardingExperience) => {
          return (
            <StyledFormControlLabel
              key={onboardingExperience.id}
              control={
                <Checkbox
                  checked={selectedIds.includes(onboardingExperience.id)}
                  onClick={() => onSwitchCheckBox(onboardingExperience.id)}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary" noWrap>
                  {onboardingExperience.title}
                </Typography>
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
