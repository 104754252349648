import { Box } from "@material-ui/core";
import { Employee, isValidEmail, MessageContent, OnboardingMessageTask } from "@onn/common";
import React, { FC, useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import { AccessibleEmployeeList } from "~/components/domains/employees";
import {
  Button,
  Divider,
  Paper,
  ImageUploadArea,
  Typography,
  TextField,
  TextareaAutosize,
  Modal,
} from "~/components/uiParts";
import { ExampleModal } from "~/pages/messageTask/MessageEdit";
import { mixin } from "~/util";

type Props = {
  newHire: Employee;
  mentor: Employee | undefined;
  supportMembers: Employee[];
  admins: Employee[];
  onboardingTask?: OnboardingMessageTask;
  newMessageContent: MessageContent;
  inputIconDataUrl: string;
  onChangeMessageContent: (changedContent: Partial<MessageContent>) => void;
  onUploadImage: (image: File) => void;
  onErrorUploadImage: () => void;
  onClickedConfirmButton: () => void;
};

export const MessageContentEditor: FC<Props> = ({
  newHire,
  mentor,
  supportMembers,
  admins,
  onboardingTask,
  newMessageContent,
  inputIconDataUrl,
  onChangeMessageContent,
  onUploadImage,
  onErrorUploadImage,
  onClickedConfirmButton,
}) => {
  const [openViewableModal, setOpenViewableModal] = useState(false);
  const [openExampleModal, setOpenExampleModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleChangeWelcomeMessage = useCallback(
    (newObject: Partial<MessageContent>) => {
      onChangeMessageContent(newObject);
    },
    [onChangeMessageContent]
  );

  const checkEmail = useCallback(
    (email: string) => {
      // Emailは任意のため空文字の場合はエラーを表示しない
      setEmailError(!!email.trim() && !isValidEmail(email));
      handleChangeWelcomeMessage({ email: email });
    },
    [setEmailError, handleChangeWelcomeMessage]
  );

  const setExampleMessage = (example: { message: string }) => {
    onChangeMessageContent(example);
    setOpenExampleModal(false);
  };

  useEffect(() => {
    const isInvalidNewMessageContent =
      !newMessageContent.message?.trim() || !newMessageContent.name.trim();

    // iconPathに値があるか画像をアップロードしている場合は問題ない
    const isInvalidInputIconDataUrl =
      !inputIconDataUrl.trim() && !newMessageContent.iconPath?.trim();

    const isInvalidInputEmail =
      !!newMessageContent.email &&
      // emailは任意のため空文字であっても問題ない
      newMessageContent.email !== "" &&
      !isValidEmail(newMessageContent.email);

    setButtonDisabled(
      isInvalidNewMessageContent || isInvalidInputIconDataUrl || isInvalidInputEmail
    );
  }, [
    newMessageContent.message,
    newMessageContent.name,
    newMessageContent.iconPath,
    newMessageContent.email,
    inputIconDataUrl,
  ]);

  return (
    <>
      <StyledContainer maxWidth="1090px">
        <StyledTitle variant="h2">{onboardingTask?.title}</StyledTitle>
        <StyledPaper paddingLarge>
          <Typography variant="body2" bold>
            {newHire.getName()}さんへのメッセージ記入をお願いします。
          </Typography>
          <Typography variant="body2">
            ※送信内容は、
            <StyledPointerTypography
              display="inline"
              variant="body2"
              color="primary"
              onClick={() => setOpenViewableModal(true)}
            >
              こちら
            </StyledPointerTypography>
            のメンバーが管理画面から確認することができます。
          </Typography>
          <Box height="16px" />
          <Divider orientation="horizontal" />
          <Box height="20px" />
          <Typography variant="body2" bold>
            氏名
          </Typography>
          <Typography variant="caption" color="textSecondary">
            メッセージに表示されるあなたの氏名を設定してください。
          </Typography>
          <Box height="16px" />
          <TextField
            variant="outlined"
            placeholder="氏名を記入してください"
            fullWidth
            value={newMessageContent.name}
            onChange={(e) => handleChangeWelcomeMessage({ name: e.target.value })}
          />
          <Box height="32px" />
          <Typography variant="body2" bold>
            メールアドレス(任意)
          </Typography>
          <Typography variant="caption" color="textSecondary">
            メッセージの編集に必要なURLをメールアドレス宛にお送りします。
          </Typography>
          <Box height="16px" />
          <TextField
            fullWidth
            error={emailError}
            variant="outlined"
            name="email"
            type="email"
            autoComplete="email"
            helperText={emailError && "メールアドレスの形式を正しく入力してください"}
            placeholder="メールアドレスを記入してください"
            value={newMessageContent.email || ""}
            onChange={(event) => checkEmail(event.target.value)}
          />
          <Box height="32px" />
          <Typography variant="body2" bold>
            部署(任意)
          </Typography>
          <Box height="16px" />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="所属する部署を記入してください"
            value={newMessageContent.department}
            onChange={(event) => handleChangeWelcomeMessage({ department: event.target.value })}
          />
          <Box height="32px" />
          <Typography variant="body2" bold>
            写真
          </Typography>
          <Typography variant="caption" color="textSecondary">
            メッセージに表示されるユーザーアイコンを設定してください。入社前で顔と名前が一致しない入社者のために
          </Typography>
          <Typography variant="caption" color="textSecondary" bold>
            顔写真を推奨
          </Typography>
          <Typography variant="caption" color="textSecondary">
            しています。
          </Typography>
          <Box height="16px" />
          <ImageUploadArea
            alt="アイコン"
            defaultImage={newMessageContent.iconPath}
            imagePath={inputIconDataUrl}
            allowImageSize={{
              max: { width: 2100, height: 2100 },
              min: { width: 120, height: 120 },
            }}
            onChange={onUploadImage}
            onError={onErrorUploadImage}
            size="large"
          />
          <Box height="32px" />
          <Typography variant="body2" bold>
            メッセージ
          </Typography>
          <Typography variant="caption" color="textSecondary" display="block">
            簡単な自己紹介を添えて、入社が楽しみになるような歓迎の気持ちを伝えましょう。
          </Typography>
          <Box my="16px">
            <Button
              variant="outlined"
              borderRadius="regular"
              onClick={() => setOpenExampleModal(true)}
              color="default"
            >
              記入例を見る
            </Button>
          </Box>
          <StyledTextarea
            value={newMessageContent.message || ""}
            minRows={5}
            fullWidth
            onChange={(e) => handleChangeWelcomeMessage({ message: e.target.value })}
            placeholder="初めまして！〇〇さんのバディになりました△△と申します！入社後のサポートを担当させていただきます。普段の仕事では、＿＿＿＿＿を担当しているので、〇〇さんとも一緒に働くことが多いと思うのでよろしくお願いします！自己紹介拝見しましたが、〇〇が好きなんですね！趣味が合うメンバーも多いので、楽しみにしていてください！〇〇さんが入社するのをチームでお待ちしています！！"
          />
          <StyledButtonContainer mt={5}>
            <Button
              fullWidth
              borderRadius="circle"
              variant="contained"
              color="primary"
              disabled={buttonDisabled}
              onClick={onClickedConfirmButton}
            >
              確認画面へ
            </Button>
          </StyledButtonContainer>
        </StyledPaper>
      </StyledContainer>
      {openViewableModal && (
        <Modal
          open={openViewableModal}
          title={`${onboardingTask?.title}を閲覧できるメンバー`}
          content={
            <AccessibleEmployeeList
              mentor={mentor}
              supportMembers={supportMembers}
              admins={admins}
            />
          }
          onCancel={() => setOpenViewableModal(false)}
        />
      )}
      {openExampleModal && (
        <ExampleModal
          open={openExampleModal}
          onCopy={(message) => setExampleMessage({ message: message })}
          onClose={() => setOpenExampleModal(false)}
        />
      )}
    </>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledTitle = styled(Typography)`
  padding-bottom: 32px;

  ${mixin.breakDown.sm`
    padding: 40px 24px 0;
  `}
`;

const StyledPaper = styled(Paper)`
  ${mixin.breakDown.sm`
    &.MuiPaper-elevation1 {
      box-shadow: none;
    }
    &.MuiPaper-rounded {
      border-radius: 0;
    }
  `}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledTextarea = styled(TextareaAutosize)`
  box-sizing: inherit !important;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
