export type ActionId =
  | "SET_JOIN_AT"
  | "ASSIGN_MENTOR_OR_SUPPORT_MEMBER"
  | "INTEGRATE_SLACK"
  | "REGISTER_PROFILE"
  | "CREATE_ACCOUNT";

export const defaultActionIds: ActionId[] = [
  "SET_JOIN_AT",
  "ASSIGN_MENTOR_OR_SUPPORT_MEMBER",
  "INTEGRATE_SLACK",
  "CREATE_ACCOUNT",
  "REGISTER_PROFILE",
];

export class GeneralTaskSettings {
  readonly assigneeRole: "ADMIN" | "BY_NAME";
  readonly assigneeIds: string[];
  readonly actionIds: ActionId[];

  constructor({ assigneeRole, assigneeIds, actionIds }: GeneralTaskSettings) {
    this.assigneeRole = assigneeRole;
    this.assigneeIds = assigneeIds;
    this.actionIds = actionIds;
  }

  public static create({ assigneeRole, assigneeIds, actionIds }: GeneralTaskSettings) {
    return new GeneralTaskSettings({ assigneeRole, assigneeIds, actionIds });
  }
}
