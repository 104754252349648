import { Box, Tabs, Tab } from "@material-ui/core";
import { AuthenticationType } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { SettingsAccountForEmailAuth } from "./SettingsAccountForEmailAuth";
import { SettingsAccountForGoogleAuth } from "./SettingsAccountForGoogleAuth";
import { SettingsAccountForLineAuth } from "./SettingsAccountForLineAuth";

import { Paper, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { mixin } from "~/util";

// TODO: LINE認証ユーザーの場合でも、メールアドレスを変更できるようにする。またLINE認証ユーザーの場合は、パスワード変更のinputが表示されないようにする。

export const SettingsAccount: FC = () => {
  const { currentUser } = useCurrentUser();

  const renderSettingsAccount = (authenticationType: AuthenticationType) => {
    switch (authenticationType) {
      case "email":
        return <SettingsAccountForEmailAuth />;
      case "line":
        return <SettingsAccountForLineAuth />;
      case "google":
        return <SettingsAccountForGoogleAuth />;
      default:
        throw new Error("Invalid authenticationType");
    }
  };

  return (
    <StyledContainer>
      <StyledBox display="flex" flexDirection="column" width="100%" alignItems="center">
        <Box width="100%" maxWidth="1090px">
          <StyledTypography variant="h4" bold>
            アカウント設定
          </StyledTypography>
          <StyledTabs value={0} indicatorColor="primary" textColor="primary">
            <Tab label="アカウント" />
          </StyledTabs>
          <StyledPaper square>
            <StyledWrapper>
              {currentUser.currentAuthenticationType
                ? renderSettingsAccount(currentUser.currentAuthenticationType)
                : null}
            </StyledWrapper>
          </StyledPaper>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

const FOOTER_HEIGHT = 96;

const StyledBox = styled(Box)`
  ${mixin.pc`
    padding: 48px 40px ${FOOTER_HEIGHT + 40}px 40px;
  `};
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  &.MuiTabs-root {
    ${mixin.sp`display: none;`};
  }
`;

const StyledWrapper = styled.div`
  max-width: 500px;
`;

const StyledTypography = styled(Typography)`
  ${mixin.sp`
    &.MuiTypography-root {
      font-size: 32px;
      font-weight: normal;
    }
  `}
`;

const StyledContainer = styled.div`
  ${mixin.sp`
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 40px 24px 40px 24px;
  `};
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    margin: 40px 0px 32px;
    ${mixin.sp`
      box-shadow: none;
      padding: 40px 0;
      margin: 0px;
    `}
  }
`;
