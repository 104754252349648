import { Box } from "@material-ui/core";
import { ChartItem } from "@onn/common";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  DotProps,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

import { MarkedDot, NormalDot } from "./CustomizedDot";

import { Loading } from "~/components/uiParts";
import theme from "~/config/theme";

// ====================
// type
// ====================

type CustomizedDotProps = DotProps & { index: number };

// ====================
// props
// ====================

type Props = {
  chartData: ChartItem[];
};

// ====================
// main
// ====================

export const SplineChart: FC<Props> = ({ chartData }) => {
  // ====================
  // state
  // ====================

  const [isLoading, setIsLoading] = useState(true);

  // ====================
  // effect
  // ====================

  // チャート描画のために遅延させる
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  // ====================
  // useMemo
  // ====================

  const removedUndefinedData = useMemo(() => chartData.filter((v) => !!v.answer), [chartData]);

  // グラフの最初と最後に余白を持たせるため、配列の前後にundefinedのデータを追加
  const customizedChartData: Partial<ChartItem>[] = useMemo(
    () => [{}, ...removedUndefinedData, {}],
    [removedUndefinedData]
  );

  // ====================
  // useCallback
  // ====================

  const isLastDot = useCallback(
    (index: number) => index + 2 === customizedChartData.length,
    [customizedChartData.length]
  );

  const renderCustomizedDot = useCallback(
    (props: CustomizedDotProps) =>
      // 最後のDotのみハイライト
      isLastDot(props.index) ? (
        <MarkedDot chartDotData={props} key={props.index} />
      ) : (
        <NormalDot chartDotData={props} key={props.index} />
      ),
    [isLastDot]
  );

  // ====================
  // component
  // ====================

  return (
    <StyledBox>
      <label>充実度</label>
      <ResponsiveContainer width="100%" height={300}>
        {isLoading ? (
          <Loading color="grey" size="small" />
        ) : (
          <LineChart
            data={customizedChartData}
            margin={{
              left: -20,
              bottom: 50,
            }}
          >
            <CartesianGrid vertical={false} stroke={theme.palette.divider} />
            <Legend iconType="plainline" verticalAlign="top" align="right" height={50} />
            <XAxis
              dataKey="week"
              axisLine={false}
              tickLine={false}
              tickMargin={24}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              domain={[1, 5]}
              ticks={[1, 2, 3, 4, 5]}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
            />
            <Line
              name="入社者回答"
              type="natural"
              dataKey="answer"
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              dot={renderCustomizedDot}
              activeDot={false}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </StyledBox>
  );
};

// ====================
// style
// ====================

const StyledBox = styled(Box)`
  background: ${(props) => props.theme.palette.background.paper};
  padding: 0 ${(props) => props.theme.spacing(5)}px;
  padding-top: ${(props) => props.theme.spacing(4)}px;
`;
