import { Employee, Role, OnboardingMessageTask, Department, SlackUser } from "@onn/common";
import { isEmpty } from "lodash";

import { EmployeeRepository } from "~/infrastructure/api/employeeRepository";
import { functionOperator } from "~/infrastructure/api/functionOperator";
import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";

const employeeRepository = new EmployeeRepository();
const onboardingTaskRepository = new OnboardingTaskRepository();

export class InsertNewContentToOnboardingMessageTaskUseCase {
  async execute(
    id: string,
    currentUser: Employee,
    emails: string[],
    slackUsers: SlackUser[],
    newHire: Employee,
    departments: Department[]
  ): Promise<OnboardingMessageTask> {
    const isMyDepartmentAdmin =
      currentUser.role === "DEPARTMENT_ADMIN" &&
      Department.getChildIds(currentUser.departmentIds, departments).some((id) =>
        newHire.departmentIds.includes(id)
      );

    if (!currentUser.isAdmin() && !isMyDepartmentAdmin) {
      throw new Error("記入依頼を行う権限がありません");
    }

    if (isEmpty(emails)) {
      throw new Error("emailsの指定は必須です");
    }

    const onboardingMessageTask = await onboardingTaskRepository.findById(id);

    if (onboardingMessageTask.type !== "MESSAGE_TASK") {
      throw new Error("指定したタスクはメッセージタスクではありません");
    }

    const employees = await employeeRepository.findAll(currentUser.tenantId);

    const existedEmployees: Employee[] = [];
    const emailsForInvite: string[] = [];

    emails.forEach((email) => {
      const slackUser = slackUsers.find((v) => v.email === email);
      // NOTE: Slack上にユーザーの情報が存在していた時には新規招待せずに紐づいたユーザーをアサインする
      const employee = employees.find((v) => {
        if (v.email === email) return true;
        if (!slackUser) return false;
        return v.slackUserId === slackUser.slackUserId;
      });
      if (employee) {
        existedEmployees.push(employee);
      } else {
        emailsForInvite.push(email);
      }
    });

    const createdEmployees = isEmpty(emailsForInvite)
      ? []
      : await this.createAccount(emailsForInvite);

    let newOnboardingMessageTask = onboardingMessageTask;
    [...existedEmployees, ...createdEmployees].forEach((employee) => {
      newOnboardingMessageTask = newOnboardingMessageTask.insertNewContent(employee);
    });

    await onboardingTaskRepository.update(newOnboardingMessageTask.id, newOnboardingMessageTask);

    return newOnboardingMessageTask;
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emailsForInvite: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emailsForInvite.map((email) => {
          return {
            email,
            role: Role.MEMBER,
            departmentIds: [],
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }
}
