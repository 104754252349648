import { Box } from "@material-ui/core";
import {
  Employee,
  SurveyTransaction,
  TransactionDataType,
  TransactionStatus,
  Transaction,
  MemoTransaction,
} from "@onn/common";
import { format } from "date-fns";
import React, {
  useState,
  useEffect,
  ReactNode,
  useRef,
  FC,
  useCallback,
  ComponentProps,
} from "react";
import styled from "styled-components";

import {
  JoinedDateNotifyPaper,
  JoiningPaper,
  MentorDecisionNotifyPaper,
} from "~/components/domains/activities";
import { Profile } from "~/components/domains/employees";
import { ReactionContent, SendReactionModal } from "~/components/domains/reactions";
import { MemoPreview, SurveyResult } from "~/components/domains/transactions";
import { ActivityContentItem, Paper, Divider, Icon, Typography } from "~/components/uiParts";
import { useEmployee, useScrollToTargetElement } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { ReactionUseCase } from "~/service/usecases/reactionUseCase";
import { captureException, mixin } from "~/util";

const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";

type ReactionsProps = ComponentProps<typeof ReactionContent>;

type MemoAttachedFile = {
  fileName: string;
  src: string;
  path: string;
};

type MemoAttachedFilesMap = {
  [memoId: string]: MemoAttachedFile[];
};

type Props = {
  transaction: Transaction | SurveyTransaction | MemoTransaction;
  employee: Employee;
  isScrollTarget: boolean;
  reactionModalOpened: boolean;
  memoAttachedFilesMap: MemoAttachedFilesMap;
  memoAttachedFilesLoading: boolean;
  surveyTransactions: SurveyTransaction[];
  onClickAddCommentButton: () => void;
  onDownloadMemoAttachedFile: (url: string) => void;
  onDeleteMemoAttachedFile: (path: string) => void;
  isActive?: boolean;
  isLoadingTransactionReactionsMap: boolean;
} & Pick<ReactionsProps, "reactions">;

export const ActivityContent: FC<Props> = ({
  transaction,
  employee,
  isScrollTarget,
  reactionModalOpened,
  memoAttachedFilesMap,
  memoAttachedFilesLoading,
  surveyTransactions,
  reactions,
  isLoadingTransactionReactionsMap,
  onClickAddCommentButton,
  onDownloadMemoAttachedFile,
  onDeleteMemoAttachedFile,
  isActive = false,
}) => {
  const reactionUseCase = new ReactionUseCase();

  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const { data: mentor } = useEmployee(employee.mentorUserId);

  const [memoCreatedUser, setMemoCreatedUser] = useState<Employee>();
  const [displayReactions, setDisplayReactions] = useState<
    {
      employeeId: string;
      name: string;
      profileIconImageUrl?: string;
    }[]
  >([]);
  const [reactionModalOpen, setReactionModalOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (reactions) setDisplayReactions(reactions);
  }, [reactions]);

  let caption = !(
    transaction instanceof MemoTransaction || transaction instanceof SurveyTransaction
  ) // メモ・アンケート・WelcomeMessage以外のキャプション
    ? `${transaction.sendAt?.toString().replace(/-/g, "/")} 完了`
    : "";
  let title = "";
  let content: ReactNode | null;

  const findMemoCreatedUser = async (memoCreatedUserId: string) => {
    setMemoCreatedUser(await EmployeeUseCase.findById(memoCreatedUserId));
  };

  const handleOpenReactionModal = useCallback(() => {
    setReactionModalOpen(true);
  }, []);

  const sendReaction = async (reactionComment: string) => {
    await reactionUseCase
      .send(reactionComment, transaction.id)
      .then(async () => {
        const user = {
          employeeId: currentUser.id,
          name: currentUser.getName(),
          profileIconImageUrl: currentUser.profileIconImageUrl,
        };

        setDisplayReactions([user, ...displayReactions]);
        enqueueSnackbar("リアクションを送信しました", { variant: "success" });
      })
      .catch((e) => {
        enqueueSnackbar("リアクションを送信できませんでした", { variant: "error" });
        captureException({ error: e as Error, tags: { type: "ActivityContent:sendReaction" } });
      });
  };

  useEffect(() => {
    transaction instanceof MemoTransaction &&
      findMemoCreatedUser(transaction.contents.createdEmployeeId);
  }, [employee, transaction]);

  useEffect(() => {
    if (!reactionModalOpened || isLoadingTransactionReactionsMap || !reactions) return;

    if (reactions.some((reaction) => reaction.employeeId === currentUser.id)) {
      enqueueSnackbar("すでにリアクションを送信しています", { variant: "error" });
    } else {
      handleOpenReactionModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser.id,
    enqueueSnackbar,
    handleOpenReactionModal,
    isLoadingTransactionReactionsMap,
    reactionModalOpened,
  ]);

  useScrollToTargetElement({
    enabled: isScrollTarget,
    targetRef: rootRef,
  });

  const targetTransaction = transaction as Transaction | SurveyTransaction | MemoTransaction;

  switch (targetTransaction.dataType) {
    case TransactionDataType.JOIN_DATE_INPUT:
      title = "入社日設定";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;

      content = (
        <JoinedDateNotifyPaper
          fullName={employee.getName()}
          hasMentor={employee.hasMentor()}
          joinedAt={employee.joinAt?.toString().replace(/-/g, "/") ?? ""}
          isActive={isActive}
        />
      );
      break;
    case TransactionDataType.MENTOR_REGISTRATION:
      if (!mentor) break;

      title = "バディ設定";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;

      content = (
        <MentorDecisionNotifyPaper
          newHireName={employee.getName()}
          mentorProfileIconImageUrl={mentor && mentor.profileIconImageUrl}
          mentorName={mentor && mentor.getName()}
          isActive={isActive}
        />
      );
      break;
    case TransactionDataType.PROFILE_COMMENT:
      title = "自己紹介";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;
      content = (
        <StyledPaper $isActive={isActive}>
          {employee.profile && <Profile profile={employee.profile} />}
        </StyledPaper>
      );
      break;
    case TransactionDataType.SURVEY: {
      const surveyTransaction = targetTransaction as SurveyTransaction;
      caption = surveyTransaction.answeredAt
        ? `${format(surveyTransaction.answeredAt, TIMESTAMP_FORMAT)} 回答`
        : "";
      title = surveyTransaction.contents.title;

      if (surveyTransaction.status === TransactionStatus.SENT) {
        return <></>;
      }
      content = (
        <StyledPaper $isActive={isActive}>
          <SurveyResult
            surveyTransaction={surveyTransaction}
            currentUser={currentUser}
            newHire={employee}
            surveyTransactions={surveyTransactions}
          />
          <StyledDivider orientation="horizontal" />
          <Box height="20px" />
          <ReactionContent
            transactionId={surveyTransaction.id}
            reactions={displayReactions}
            newHire={employee}
            onClickReactionButton={handleOpenReactionModal}
          />
        </StyledPaper>
      );
      break;
    }
    case TransactionDataType.WELCOME:
      title = "入社";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;
      content = <JoiningPaper newHireEmployeeName={employee.getName()} isActive={isActive} />;
      break;
    case TransactionDataType.ADD_MEMO: {
      const memoTransaction = transaction as MemoTransaction;
      title = "メモ";
      caption = `${format(memoTransaction.createdAt, "yyyy/MM/dd HH:mm")} 投稿`;

      const attachedFiles = memoAttachedFilesMap[memoTransaction.id] ?? [];

      content = (
        <StyledPaper $isActive={isActive}>
          {!memoTransaction.deleted ? (
            <MemoPreview
              title={memoTransaction.contents.title}
              text={memoTransaction.contents.text}
              createdUser={
                memoCreatedUser
                  ? {
                      name: memoCreatedUser.getName(),
                      profileIconImageUrl: memoCreatedUser.profileIconImageUrl,
                      deleted: memoCreatedUser.deleted,
                    }
                  : undefined
              }
              createdAt={format(memoTransaction.createdAt, TIMESTAMP_FORMAT)}
              updatedAt={format(memoTransaction.updatedAt, TIMESTAMP_FORMAT)}
              attachedFiles={attachedFiles}
              attachedFilesLoading={memoAttachedFilesLoading}
              onDeleteFile={onDeleteMemoAttachedFile}
              onDownloadFile={onDownloadMemoAttachedFile}
            />
          ) : (
            <StyledBox display="flex" alignItems="center" $isActive={isActive}>
              <Box display="flex" mr={0.5}>
                <Icon icon="trash" size="md" color="grey" />
              </Box>
              <Typography>このメモは削除されました。</Typography>
            </StyledBox>
          )}
        </StyledPaper>
      );
      break;
    }
    default:
      break;
  }

  return (
    <div ref={rootRef}>
      <ActivityContentItem
        title={title}
        caption={caption}
        content={content}
        shareLink={`${location.origin}/employee/${employee.id}?contentId=${transaction.id}`}
        onClickAddCommentButton={onClickAddCommentButton}
        onClickOpenReactionModal={
          transaction instanceof SurveyTransaction ? () => setReactionModalOpen(true) : undefined
        }
      />
      {reactionModalOpen && (
        <SendReactionModal
          open={reactionModalOpen}
          onSubmit={sendReaction}
          onCancel={() => setReactionModalOpen(false)}
        />
      )}
    </div>
  );
};

const StyledDivider = styled(Divider)`
  margin: 0 -40px !important;
`;

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;

const StyledBox = styled(Box)<{ $isActive: boolean }>`
  &.MuiPaper-root {
    ${(props) => props.$isActive && mixin.outlineAnimation()}
  }
`;
