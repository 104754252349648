import { Employee, OnboardingTaskType } from "@onn/common";
import { useState, useCallback } from "react";

import { useNotifyOperationLog, useSnackbar } from "../shared";

import { useCurrentUser } from "~/hooks/employee";
import { OnboardingTaskUseCase } from "~/service/usecases/onboardingTaskUseCase";
import { captureException } from "~/util";

const onboardingTaskUseCase = new OnboardingTaskUseCase();
/**
 * onboardingTaskを削除するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns deleteOnboardingTask
 */
export const useDeleteOnboardingTask = (): {
  isLoading: boolean;
  deleteOnboardingTask: (task: OnboardingTaskType, employee: Employee) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { operationLog, notifyOperationLog } = useNotifyOperationLog();

  const deleteOnboardingTask = useCallback(
    async (task: OnboardingTaskType, employee: Employee) => {
      setIsLoading(true);
      await onboardingTaskUseCase
        .deleteById(task.id)
        .then(() => enqueueSnackbar("オンボーディングタスクを削除しました", { variant: "success" }))
        .catch((e) => {
          enqueueSnackbar("オンボーディングタスクの削除に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useDeleteOnboardingTask:deleteOnboardingTask",
            },
          });
        })
        .finally(() => setIsLoading(false));

      notifyOperationLog(operationLog.deleteOnboardingTask(currentUser, task, employee));
    },
    [currentUser, enqueueSnackbar, notifyOperationLog, operationLog]
  );

  return { isLoading, deleteOnboardingTask };
};
