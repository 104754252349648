import { Reaction } from "@onn/common";
import { HttpsCallableResult } from "firebase/functions";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { ReactionRepository } from "~/infrastructure/api/reactionRepository";

const reactionRepository = new ReactionRepository();

export class ReactionUseCase {
  async whereByTransactionIds(transactionIds: string[]): Promise<Reaction[]> {
    return await reactionRepository.whereByTransactionIds(transactionIds);
  }

  async send(comment: string, transactionId: string): Promise<HttpsCallableResult> {
    return await functionOperator.httpsCall("sendReaction", { comment, transactionId });
  }
}
