import { useCallback } from "react";

import { useCurrentTenantId } from "../tenant/useCurrentTenantId";

import { auth } from "~/config/firebase";

export const useSignOut = () => {
  const { resetCurrentTenantId } = useCurrentTenantId();

  const signOut = useCallback(async () => {
    const isSignedIn = !!auth.currentUser;
    if (isSignedIn) {
      await auth.signOut();
    }

    resetCurrentTenantId();
  }, [resetCurrentTenantId]);

  return { signOut };
};
