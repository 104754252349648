import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useAuthorizationNonGuarded } from "../context";
import { useCurrentTenantId } from "../tenant/useCurrentTenantId";

export const useCurrentUserNonGuarded = () => {
  const { currentTenantId } = useCurrentTenantId();
  const { portalAccessibleUsers } = useAuthorizationNonGuarded();

  const currentUser = useMemo(() => {
    // 型ガードのために ! を isEmpty とは別に使っている
    if (!portalAccessibleUsers || isEmpty(portalAccessibleUsers)) {
      return null;
    }

    if (portalAccessibleUsers.length === 1) {
      return portalAccessibleUsers[0];
    }

    if (currentTenantId) {
      return portalAccessibleUsers.find((v) => v.tenantId === currentTenantId) ?? null;
    }

    return null;
  }, [currentTenantId, portalAccessibleUsers]);

  return { currentUser };
};
