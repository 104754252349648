import { Box } from "@material-ui/core";
import { SurveyTransaction, Question, ItemType, AnswerItem } from "@onn/common";
import { isEmpty, isString } from "lodash";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import {
  CheckboxQuestion,
  SatisfactionQuestion,
  DialogueQuestion,
  TextContext,
  TransitionWithChart,
  FiveGradeEvaluationQuestion,
  NPSForm,
  QuestionTitle,
} from "~/components/uiParts";

type Props = {
  question: Question;
  answerResult?: AnswerItem;
  isFirstOfQuestionGroup?: boolean;
  templateId?: string;
  pastSurveyTransactions: Array<SurveyTransaction>;
  isSurveyTab?: boolean;
};

// TITLE_ICON_CUSTOM_RADIO のとき
const TitleIconCustomRadioQuestion: FC<Pick<Props, "question" | "answerResult">> = ({
  question,
  answerResult,
}) => {
  const { questionItems } = question;
  const questionItem = questionItems.find(
    (questionItem) => questionItem.name === answerResult?.value
  );

  return (
    <DialogueQuestion
      title={question.title}
      hrOnly={question.hrOnly}
      resultText={questionItem?.title as string}
      bold
    />
  );
};

// TITLE_CUSTOM_RADIO のとき
const RatingRadioQuestion: FC<
  Pick<Props, "question" | "answerResult" | "isFirstOfQuestionGroup">
> = ({ question, answerResult, isFirstOfQuestionGroup }) => {
  const { questionItems } = question;
  const questionItem = questionItems.find(
    (questionItem) =>
      questionItem.name === answerResult?.value || // 振り返りアンケート(新)はTITLE_CUSTOM_RADIOでvalueをquestionItem.nameと同様に`questionItem4`のように持っているため単純に比較できる
      questionItem.name === `questionItem${answerResult?.value}` // 1monthアンケート(旧)はTITLE_CUSTOM_RADIOでvalueを単なる整数で持っているため加工してから比較しなければならない
  );

  // 左から小さい順に表示するために並べ替える
  const questionItemsForDisplay = questionItems.sort((a, b) => (a.value > b.value ? 1 : -1));

  return (
    <>
      {isFirstOfQuestionGroup && (
        <Box display="flex" justifyContent="flex-end">
          <Box px={3} width={328}>
            <Box display="flex" justifyContent="space-between">
              {questionItems.map((questionItem) => {
                return (
                  <Box key={`ratingRadioQuestionBox__${questionItem.value}`}>
                    <TextContext variant="body2">{questionItem.value}</TextContext>
                  </Box>
                );
              })}
            </Box>
            <Box height="8px" />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {questionItemsForDisplay.map((questionItem, index) => {
                return (
                  <div key={`ratingRadioQuestionStyledText__${questionItem.value}`}>
                    {index === 0 && (
                      <StyledTextContext padding={0} align="center" $isLeft>
                        {questionItem.title}
                      </StyledTextContext>
                    )}
                    {index === 2 && (
                      <StyledTextContext padding={0} align="center">
                        {questionItem.title}
                      </StyledTextContext>
                    )}
                    {index === questionItems.length - 1 && (
                      <StyledTextContext padding={0} align="center" $isRight>
                        {questionItem.title}
                      </StyledTextContext>
                    )}
                  </div>
                );
              })}
            </Box>
            <Box height="16px" />
          </Box>
        </Box>
      )}
      <FiveGradeEvaluationQuestion
        title={question.title}
        hrOnly={question.hrOnly}
        evaluationValue={questionItem?.value as number}
      />
    </>
  );
};

const StyledTextContext = styled(TextContext)<{ $isLeft?: boolean; $isRight?: boolean }>`
  width: 88px;

  ${({ $isLeft }) => $isLeft && `margin-left: -24px`}
  ${({ $isRight }) => $isRight && `margin-right: -24px`}
`;

// CUSTOM_RADIO のとき
const CustomRadioQuestion: FC<Pick<Props, "question" | "answerResult">> = ({
  question,
  answerResult,
}) => {
  const { questionItems } = question;
  const questionItem = questionItems.find((questionItem) =>
    questionItem.name.includes(answerResult?.value as string)
  );

  return (
    <SatisfactionQuestion
      title={question.title}
      point={questionItem?.value as number}
      text={questionItem?.title as string}
    />
  );
};

// MEDIUM_RATING のとき
const RatingQuestion: FC<Omit<Props, "isFirstOfQuestionGroup" | "isSurveyTab">> = ({
  question,
  answerResult,
  templateId,
  pastSurveyTransactions,
}) => {
  const { questionItems } = question;
  const latestSurveyTransaction = pastSurveyTransactions
    .filter((v) => v.templateId === templateId)
    .sort((a, b) => (a.sendAt < b.sendAt ? 1 : -1))[0];

  const previousSatisfaction =
    latestSurveyTransaction?.resultContents &&
    latestSurveyTransaction.resultContents.find((v) => v.key === answerResult?.key);

  // ※ 1.充実していなかった～5.とても充実していた といったキャプションを生成する
  const caption = useMemo(() => {
    const length = questionItems.length;
    // 古いアンケート構造ではキャプションは存在しないのでガード節を入れる
    if (isEmpty(questionItems)) return "";

    return `※1.${(questionItems[0] as (typeof questionItems)[number]).title}〜${length}.${
      (questionItems[length - 1] as (typeof questionItems)[number]).title
    }`;
  }, [questionItems]);

  return (
    <TransitionWithChart
      title={question.title}
      hrOnly={question.hrOnly}
      latelySatisfaction={Number(answerResult?.value as string)}
      previousSatisfaction={Number(previousSatisfaction?.value) || undefined}
      caption={caption}
    />
  );
};

// TEXT のとき
const Text: FC<Pick<Props, "question" | "answerResult">> = ({ question, answerResult }) => {
  const resultText = answerResult?.value ?? "回答なし";

  return (
    <DialogueQuestion
      title={question?.title}
      hrOnly={question.hrOnly}
      // resultText が配列でないことを確認する
      resultText={isString(resultText) ? resultText : ""}
      bold
    />
  );
};

// TITLE_TEXT のとき
const TitleTextQuestion: FC<Pick<Props, "question" | "answerResult">> = ({
  question,
  answerResult,
}) => {
  const resultText = answerResult?.value ?? "回答なし";

  return (
    <DialogueQuestion
      title={question.title}
      // resultText が配列でないことを確認する
      resultText={isString(resultText) ? resultText : ""}
      bold
    />
  );
};

// SERIAL_NUMBER_TEXTのとき
const SerialNumberTextQuestion: FC<Pick<Props, "question" | "answerResult">> = ({
  question,
  answerResult,
}) => {
  const resultText = answerResult?.value ?? "回答なし";

  return (
    <DialogueQuestion
      title={question?.title}
      hrOnly={question.hrOnly}
      // resultText が配列でないことを確認する
      resultText={isString(resultText) ? resultText : ""}
      bold
    />
  );
};

// CHECK_BOXのとき
const CheckboxTypeQuestion: FC<Pick<Props, "question" | "answerResult" | "isSurveyTab">> = ({
  question,
  answerResult,
  isSurveyTab,
}) => {
  if (!Array.isArray(answerResult?.value)) {
    const resultText = answerResult?.value ?? "回答なし";

    return (
      <DialogueQuestion
        title={question?.title}
        hrOnly={question.hrOnly}
        resultText={resultText}
        bold
      />
    );
  }

  // SurveyTab では、選ばれなかった checkbox も表示する
  return (
    <CheckboxQuestion
      title={question.title}
      hrOnly={question.hrOnly}
      questionItems={question.questionItems}
      selectedAnswers={answerResult?.value as string[]}
      freeAnswerText={answerResult?.text}
      displayOnlySelectedAnswers={!isSurveyTab}
    />
  );
};

// FIXME: switch文で書いてJSX内でキャストするのをやめたい
export const SurveyResultItem: FC<Props> = ({
  question,
  answerResult,
  isFirstOfQuestionGroup,
  templateId,
  pastSurveyTransactions,
  isSurveyTab,
}) => {
  return (
    <>
      {question.type === ItemType.TITLE_ICON_CUSTOM_RADIO && (
        <TitleIconCustomRadioQuestion {...{ question, answerResult }} />
      )}
      {question.type === ItemType.TITLE_CUSTOM_RADIO && (
        <RatingRadioQuestion {...{ question, answerResult, isFirstOfQuestionGroup }} />
      )}
      {question.type === ItemType.CUSTOM_RADIO && (
        <CustomRadioQuestion {...{ question, answerResult }} />
      )}
      {question.type === ItemType.TEXT && <Text {...{ question, answerResult }} />}
      {question.type === ItemType.TITLE_TEXT && (
        <TitleTextQuestion {...{ question, answerResult }} />
      )}
      {question.type === ItemType.MEDIUM_RATING && (
        <RatingQuestion
          {...{
            question,
            answerResult,
            templateId,
            pastSurveyTransactions,
          }}
        />
      )}
      {question.type === ItemType.SERIAL_NUMBER_TEXT && (
        <SerialNumberTextQuestion {...{ question, answerResult }} />
      )}
      {question.type === ItemType.CHECK_BOX && (
        <CheckboxTypeQuestion {...{ isSurveyTab, question, answerResult }} />
      )}
      {question.type === ItemType.NPS && (
        <>
          <QuestionTitle title={question.title} bold />
          <Box height="8px" />

          <NPSForm
            value={Number(answerResult?.value as string)}
            isReadOnly
            captionLeft={(question.caption as string[])[0] as string}
            captionRight={
              (question.caption as string[])[(question.caption?.length as number) - 1] as string
            }
          />
        </>
      )}
    </>
  );
};
