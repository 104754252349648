import { Employee } from "@onn/common";
import { isArray, isEmpty } from "lodash";
import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAuthenticationNonGuarded } from "~/hooks/context";
import { useSignOut } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

const guardAuthorizedUsers = (
  authorizedUsers: unknown
): authorizedUsers is NonEmptyArray<Employee> => {
  return !isEmpty(authorizedUsers);
};
const guardPortalAccessibleUsers = (
  portalAccessibleUsers: unknown
): portalAccessibleUsers is Employee[] => {
  return isArray(portalAccessibleUsers);
};

export const AuthorizationContext = createContext<{
  authorizedUsers: Employee[] | null | undefined;
  portalAccessibleUsers: Employee[] | null | undefined;
  mutateAuthorizedUsers: () => Promise<void>;
  guardAuthorizedUsers(authorizedUsers: unknown): authorizedUsers is NonEmptyArray<Employee>;
  guardPortalAccessibleUsers(portalAccessibleUsers: unknown): portalAccessibleUsers is Employee[];
}>({
  authorizedUsers: undefined,
  portalAccessibleUsers: undefined,
  mutateAuthorizedUsers: async () => void 0,
  guardAuthorizedUsers,
  guardPortalAccessibleUsers,
});

export const AuthorizationProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { authUser } = useAuthenticationNonGuarded();
  const [authorizedUsers, setAuthorizedUsers] = useState<Employee[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useSignOut();

  const portalAccessibleUsers = useMemo(() => {
    return authorizedUsers?.filter((employee) => employee.canUseOnn()) ?? null;
  }, [authorizedUsers]);

  const mutateAuthorizedUsers = useCallback(async () => {
    if (!authUser) {
      setAuthorizedUsers(null);
      return;
    }

    await AccountUseCase.findByUid()
      .then(async (employees) => {
        if (employees.length === 0) {
          await signOut();
        }

        setAuthorizedUsers(employees);
      })
      .catch((e) => {
        enqueueSnackbar("ユーザーの取得に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "AuthorizationProvider" },
        });
      });
  }, [authUser, enqueueSnackbar, signOut]);

  useEffect(() => {
    mutateAuthorizedUsers();
  }, [mutateAuthorizedUsers]);

  return (
    <AuthorizationContext.Provider
      value={{
        authorizedUsers,
        portalAccessibleUsers,
        mutateAuthorizedUsers,
        guardAuthorizedUsers,
        guardPortalAccessibleUsers,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
