import { differenceInMonths, differenceInWeeks, isBefore } from "date-fns";

import { OnboardingStatus } from "../../domain/Employee";

/**
 * OnboardingStatusを日本語に変換します
 * @param {OnboardingStatus} status 入社社のステータス
 * @param {string} joinAt 入社社の入社日
 * @returns {string} 日本語化されたステータス
 */
export const convertStatusToString = (status: OnboardingStatus, joinAt?: string): string => {
  const buildElapsedFromJoin = (joinAt: string) => {
    const elapsedMonths = differenceInMonths(new Date(), new Date(joinAt)) + 1;
    const elapsedWeeks = differenceInWeeks(new Date(), new Date(joinAt)) + 1;

    return (elapsedMonths > 1 && `入社${elapsedMonths}ヶ月目`) || `入社${elapsedWeeks}週目`;
  };

  switch (status) {
    case OnboardingStatus.STANDBY:
    case OnboardingStatus.INVITED:
      return "招待中";
    case OnboardingStatus.LOGGED_IN:
    case OnboardingStatus.PROFILE_REGISTERED:
    case OnboardingStatus.JOIN_DATE_REGISTERED:
    case OnboardingStatus.MENTOR_REGISTERED:
      return "プレボーディング";
    case OnboardingStatus.JOINED:
    case OnboardingStatus.ANSWERED:
      // JOINEDだが今より未来の入社日が設定されているとマイナス表示されてしまうので、オンボーディング中と表示する
      return joinAt && isBefore(new Date(joinAt), new Date())
        ? buildElapsedFromJoin(joinAt)
        : "オンボーディング";
    case OnboardingStatus.ONBOARDING_COMPLETED:
      return "完了";
  }
};
