import { Box } from "@material-ui/core";

// 小文字のonboardingExperienceと競合してインポートできないため ~/domain/OnboardingExperience/OnboardingExperience からインポートしている
// TODO: onboardingExperience.ts をリネームしたタイミングで ~/domain/OnboardingExperience に書き換える
import { OnboardingExperience } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { Button, Checkbox, FormControlLabel, Modal, Typography } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  onboardingExperiences: OnboardingExperience[];
  onSubmit: (selectedIds: string[]) => Promise<void>;
};

export const GenerateTasksFormExperienceModal: FC<Props> = ({
  open,
  onCancel,
  onboardingExperiences,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSwitchCheckBox = useCallback((onboardingExperienceId: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(onboardingExperienceId)) {
        return prev.filter((v) => v !== onboardingExperienceId);
      }
      return [...prev, onboardingExperienceId];
    });
  }, []);

  const handleSubmit = useCallback(
    (selectedIds: string[]) => {
      setIsLoading(true);
      onSubmit(selectedIds)
        .then(() => {
          onCancel();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [onCancel, onSubmit]
  );

  const Content = (
    <Box>
      <Typography variant="body1" color="textPrimary">
        {`入社者に該当する受け入れ体験を選択してください。\n指定された体験に設定されたタスクが、入社者と受け入れチームに配信されます。`}
      </Typography>
      <Box display="flex" flexDirection="column" mt="32px">
        {onboardingExperiences.map((onboardingExperience) => {
          return (
            <StyledFormControlLabel
              key={onboardingExperience.id}
              control={
                <Checkbox
                  checked={selectedIds.includes(onboardingExperience.id)}
                  onClick={() => handleSwitchCheckBox(onboardingExperience.id)}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary" noWrap>
                  {onboardingExperience.title}
                </Typography>
              }
            />
          );
        })}
      </Box>
    </Box>
  );

  const Footer = (
    <Box display="flex" justifyContent="center" mx="auto" width="50%">
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={() => handleSubmit(selectedIds)}
        isLoading={isLoading}
        disabled={isEmpty(selectedIds)}
      >
        タスクを生成
      </Button>
    </Box>
  );

  return (
    <Modal
      open={open}
      title="受け入れ体験を選択"
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
