import { Employee, OnboardingExperience, OnboardingExperienceTaskType } from "@onn/common";

import { OnboardingExperienceRepository } from "~/infrastructure/api/onboardingExperienceRepository";
import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";

const onboardingExperienceRepository = new OnboardingExperienceRepository();
const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();

type OnboardingExperienceWithProcesses = OnboardingExperience & {
  tasks: OnboardingExperienceTaskType[];
};

export class FindOnboardingExperienceUseCase {
  async execute(id: string, currentUser: Employee): Promise<OnboardingExperienceWithProcesses> {
    const onboardingExperience = (await onboardingExperienceRepository.findById(
      id
    )) as OnboardingExperienceWithProcesses;

    if (onboardingExperience.tenantId !== currentUser.tenantId) {
      throw new Error("このエクスペリエンスの閲覧権限がありません。");
    }

    onboardingExperience.tasks = await onboardingExperienceTaskRepository.findByExperienceIds([
      onboardingExperience.id,
    ]);

    return onboardingExperience;
  }
}
