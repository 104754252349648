import React, { FC } from "react";

export const Line: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" stroke="none">
      <g transform="translate(-230.962 -650)">
        <rect width="12" height="2" transform="translate(233.962 653)" />
        <rect width="12" height="2" transform="translate(233.962 658)" />
        <rect width="8" height="2" transform="translate(233.962 663)" />
      </g>
      <rect width="18" height="18" fill="none" />
    </svg>
  );
};
