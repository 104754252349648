import { Box, Menu } from "@material-ui/core";
import { SlackUser } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { SlackUserList, SlackUserSearchForm } from "~/components/domains/slackUsers";
import { Button, Divider, Icon, Typography } from "~/components/uiParts";
import { useSlackUsers } from "~/hooks/slackUser";
type Props = {
  selectedEmail?: string;
  existedEmails: string[];
  onSelectEmail: (email: string) => void;
};

const filterSlackUsers = (slackUsers: SlackUser[], existedEmails: string[]) => {
  return slackUsers.filter((su) => !existedEmails.includes(su.email));
};

export const SelectSlackUserButton: FC<Props> = ({
  selectedEmail,
  existedEmails,
  onSelectEmail,
}) => {
  const { slackUsers, loadingSlackUsers } = useSlackUsers();
  const selectedSlackUser = slackUsers.find((slackUser) => slackUser.email === selectedEmail);
  const [anchorElForSlackUser, setAnchorElForSlackUser] = useState<HTMLElement | null>(null);

  const [displaySlackUsers, setDisplaySlackUsers] = useState<SlackUser[]>(slackUsers);
  const selectableSlackUsers = useMemo(() => {
    return filterSlackUsers(displaySlackUsers, existedEmails);
  }, [displaySlackUsers, existedEmails]);

  return (
    <>
      <StyledButtonWrapper>
        <Button
          onClick={(e) => setAnchorElForSlackUser(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" alignItems="center" gridGap="4px">
            {selectedSlackUser ? (
              <StyledTypography variant="body2" noWrap>
                {selectedSlackUser.real_name}
              </StyledTypography>
            ) : (
              <StyledTypography variant="body2" noWrap>
                招待するメンバーを選択
              </StyledTypography>
            )}
            <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
          </Box>
        </Button>
      </StyledButtonWrapper>
      <Menu
        anchorEl={anchorElForSlackUser}
        open={Boolean(anchorElForSlackUser)}
        onClose={() => setAnchorElForSlackUser(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        <Box width="236px">
          <Box p="16px">
            <SlackUserSearchForm
              variant="outlined"
              slackUsers={slackUsers}
              onSearch={(slackUsers) => setDisplaySlackUsers(slackUsers)}
            />
          </Box>
          <Divider />
          <SlackUserList
            slackUsers={selectableSlackUsers}
            selectedSlackUser={selectedSlackUser}
            onSelect={(slackUser) => {
              setAnchorElForSlackUser(null);
              onSelectEmail(slackUser.email);
            }}
            rowRendererMode="compact"
            isLoading={loadingSlackUsers}
          />
        </Box>
      </Menu>
    </>
  );
};

const StyledButtonWrapper = styled(Box)`
  /* TODO: buttonコンポーネントを修正してネスト指定が必要ないようにする */
  .MuiButtonBase-root > div {
    padding: 16px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
