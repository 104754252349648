import {
  DifferenceDate,
  OnboardingExperienceTaskFactory,
  OnboardingExperienceTaskType,
  removeUndefinedFromObject,
} from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  setDoc,
  where,
  query,
  getDocs,
  WriteBatch,
} from "firebase/firestore";
import { chunk } from "lodash";

import { firestore } from "~/config/firebase";
import { IOnboardingExperienceTaskRepository } from "~/service/repository/iOnboardingExperienceTaskRepository";

const COLLECTION_NAME = "onboardingExperienceTasks";
const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10; // 配列処理で同一クエリにおいて実行できる最大長

export class OnboardingExperienceTaskRepository implements IOnboardingExperienceTaskRepository {
  async create(onboardingExperience: OnboardingExperienceTaskType): Promise<void> {
    const ref = this.doc(onboardingExperience.id);
    await setDoc(ref, this.convertToPlainObject(onboardingExperience));
  }

  async findByExperienceIds(ids: string[]): Promise<OnboardingExperienceTaskType[]> {
    const onboardingExperienceTask: OnboardingExperienceTaskType[] = [];

    await Promise.all(
      chunk(ids, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map(async (chunkedIds) => {
        const { docs } = await getDocs(
          query(this.collection(), where("onboardingExperienceId", "in", chunkedIds))
        );

        onboardingExperienceTask.push(
          ...docs.map((doc) => this.dbToObject(doc.data() as OnboardingExperienceTaskType))
        );
      })
    );

    return onboardingExperienceTask;
  }

  async findByTemplateId(id: string): Promise<OnboardingExperienceTaskType[]> {
    return await getDocs(query(this.collection(), where("templateId", "==", id))).then(
      async (snapshot) => {
        return snapshot.docs.map((doc) => {
          return this.dbToObject(doc.data() as OnboardingExperienceTaskType);
        });
      }
    );
  }

  insertCreateTaskInBatch(batch: WriteBatch, Task: OnboardingExperienceTaskType) {
    batch.set(this.doc(Task.id), this.convertToPlainObject(Task));
  }

  insertDeleteTaskInBatch(batch: WriteBatch, TaskId: string) {
    batch.delete(this.doc(TaskId));
  }

  insertUpdateTaskInBatch(batch: WriteBatch, Task: OnboardingExperienceTaskType) {
    batch.update(this.doc(Task.id), this.convertToPlainObject(Task));
  }

  async insertDeleteByTemplateIdInBatch(batch: WriteBatch, templateId: string) {
    const tasks = await getDocs(
      query(this.collection(), where("templateId", "==", templateId))
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => doc.ref);
    });

    tasks.forEach((task) => batch.delete(task));
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private convertToPlainObject(onboardingExperienceTask: OnboardingExperienceTaskType) {
    return removeUndefinedFromObject({
      ...onboardingExperienceTask,
      deliveryDate: { ...onboardingExperienceTask.deliveryDate },
      dueDate: { ...onboardingExperienceTask.dueDate },
    });
  }

  private dbToObject({ deliveryDate, dueDate, ...rest }: OnboardingExperienceTaskType) {
    return OnboardingExperienceTaskFactory.createOnboardingTask({
      ...rest,
      deliveryDate: new DifferenceDate(deliveryDate),
      dueDate: new DifferenceDate(dueDate),
    });
  }
}
