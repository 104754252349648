import { Department } from "@onn/common";
import React, { FC } from "react";

import { AllContactMessage } from "./type";

import { NewHireContactMessageItem } from "~/components/domains/contactMessage";

export const MessagesList: FC<{
  contactMessages: AllContactMessage[];
  departments: Department[];
  fileSignedUrlMapObj: Record<string, string>;
}> = ({ contactMessages, departments }) => {
  return (
    <>
      {contactMessages.map((message) => {
        const variant = message.isNewHireMessage ? "right" : "left";

        return (
          <NewHireContactMessageItem
            key={message.id}
            message={message}
            variant={variant}
            departments={departments}
          />
        );
      })}
    </>
  );
};
