import { SlackUser } from "@onn/common";
import React, { FC, useState, useCallback, FormEvent, MouseEvent, ChangeEvent } from "react";
import styled from "styled-components";

import { TextField, IconButton } from "~/components/uiParts";
import { useDebouncedCallback, useFilterObjectByPartialMatch } from "~/hooks/shared";

type Props = {
  variant: "standard" | "outlined";
  showPlaceholder?: boolean;
  slackUsers: SlackUser[];
  onSearch: (results: SlackUser[]) => void;
};

export const SlackUserSearchForm: FC<Props> = ({
  variant,
  showPlaceholder,
  slackUsers,
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const handleSearchSlackUserChange = useDebouncedCallback((callback) => callback(), 200);
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const handleSearchSlackUser = useCallback(
    (
      e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
      query: string
    ) => {
      e.preventDefault();

      const results = filterObjectByPartialMatch({
        objects: slackUsers,
        searchText: query,
        getProperties: [(slackUser) => slackUser.real_name, (slackUser) => slackUser.email],
      });

      onSearch(results);
    },
    [filterObjectByPartialMatch, slackUsers, onSearch]
  );

  return (
    <StyledForm onSubmit={(e) => handleSearchSlackUser(e, searchValue)}>
      <TextField
        autoFocus
        fullWidth
        variant={variant}
        placeholder={showPlaceholder ? "ユーザー名・メールアドレスで検索" : ""}
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);

          handleSearchSlackUserChange(() => {
            handleSearchSlackUser(e, e.target.value);
          });
        }}
        endAdornment={
          <IconButton
            size="sm"
            color="grey"
            icon="search"
            onClick={(e) => handleSearchSlackUser(e, searchValue)}
          />
        }
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;
