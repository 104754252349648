import { Role } from "@onn/common";
import React, { FC, useMemo } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { NotFound } from "./NotFound";

import { DashboardIndex } from "./dashboards";
import { NewHireEmployeeDetail } from "./employee/NewHire";
import { JourneySurveysNew } from "./journey/surveys/new";
import { JourneySurvey } from "./journey/surveys/show";
import { Thanks } from "./journey/thanks";
import { LibraryEdit } from "./library/edit";
import { OnboardingExperienceEdit } from "./onboarding_experience/edit";
import { PortalIndex } from "./portal";
import { ContactMessageDetailContainer } from "./portal/contactMessages/detail";
import { ContactRoomsIndex } from "./portal/contactRooms";
import { LibraryIndex } from "./portal/libraries";
import { LibraryDetail } from "./portal/libraries/detail";
import { MessageIndex } from "./portal/messages";
import { OnboardingTaskIndex } from "./portal/onboardingTasks";
import { OnboardingTaskDetail } from "./portal/onboardingTasks/detail";
import { SurveyIndex } from "./portal/surveys";
import { SurveyDetail } from "./portal/surveys/detail";
import { PreviewSurveysNew } from "./preview/surveys/new";
import { PreviewSurveyThanks } from "./preview/surveys/thanks";
import { ProfilePage } from "./profile";
import { ProfileConfirm } from "./profile/confirm";
import { SettingsAccount } from "./settings/account";
import { TopMessage } from "./settings/admin/TopMessage/show";
import { SurveyShow } from "./surveys/show";
import { TemplateEdit } from "./template/edit";
import { TimelineIndex } from "./timeline";

import { ThanksToApplication } from "~/components/domains/employees/ThanksToApplication";
import { AdminLayout, DefaultLayout, PortalLayout, AdminSettingLayout } from "~/components/layouts";
import { useCurrentUser } from "~/hooks/employee";
import { useScrollTopByDetectTransition, useSnackbar } from "~/hooks/shared";

import { Complete } from "~/pages/complete/index";
import { ContactMessages } from "~/pages/contactMessages";
import { PreviewSurvey } from "~/pages/preview/surveys/show";
import { SettingsAdmin } from "~/pages/settings/admin";
import { Tasks } from "~/pages/tasks";
import { ToolIndex } from "~/pages/tool";
import { NewHireTopIndex } from "~/pages/top/NewHire";

// ログインしているユーザーであればアクセスできるルート
const EmployeeRoute = ({ component: Component }: { component: FC }) => {
  useScrollTopByDetectTransition();
  return <Component />;
};

// 入社者のみがアクセスできるルート
const NewHireRoute = ({ component: Component }: { component: FC }) => {
  useScrollTopByDetectTransition();
  const { currentUser } = useCurrentUser();

  const isPreview = new URLSearchParams(location.search).get("preview") === "true";
  const isNewcomer = currentUser.isNewcomer();

  const canNewcomerAccessToContents = useMemo(() => {
    //「不合格・不採用」「辞退」の場合はアクセスできない
    if (
      currentUser.recruitmentStatus &&
      ["rejected", "withdrew"].includes(currentUser.recruitmentStatus)
    ) {
      return false;
    }

    return true;
  }, [currentUser]);

  // ?preview=trueのとき、newcomer以外もアクセス可能
  if (!isNewcomer && !isPreview) {
    return <Navigate to="/" />;
  }

  // 入社者の場合は、不合格・不採用・辞退の場合はアクセスできない
  if (isNewcomer && !canNewcomerAccessToContents) {
    return <ThanksToApplication />;
  }

  return <Component />;
};

// 入社者以外の特定のロールを持つユーザーがアクセスできるルート
const SpecificRolesRoute = ({ roles, component: Component }: { roles: Role[]; component: FC }) => {
  useScrollTopByDetectTransition();
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  if (currentUser.isNewcomer()) {
    return <Navigate to="/portal" />;
  }

  if (!roles.includes(currentUser.role)) {
    enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
    return <Navigate to="/" />;
  }

  return <Component />;
};

export const RouterWrapper: FC = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <AdminLayout isCentralChildren>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        {
          index: true,
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.DEPARTMENT_ADMIN, Role.MANAGER, Role.MEMBER]}
              component={NewHireTopIndex}
            />
          ),
        },
        {
          path: "/dashboards",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={DashboardIndex} />,
        },
        {
          path: "/employee/:id",
          element: <EmployeeRoute component={NewHireEmployeeDetail} />,
        },
        {
          path: "/tools",
          element: <EmployeeRoute component={ToolIndex} />,
        },
        {
          path: "/tasks",
          element: <EmployeeRoute component={Tasks} />,
        },
        // {
        //   path: "/surveys",
        //   element: (
        //     <SpecificRolesRoute
        //       roles={[Role.ADMIN, Role.DEPARTMENT_ADMIN]}
        //       component={Surveys}
        //     />
        //   ),
        // },
        {
          path: "/timelines",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={TimelineIndex} />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        {
          path: "/tools/onboarding_experiences/:id",
          element: <EmployeeRoute component={OnboardingExperienceEdit} />,
        },
        {
          path: "/tools/templates/new",
          element: <EmployeeRoute component={TemplateEdit} />,
        },
        {
          path: "/tools/templates/:id/edit",
          element: <EmployeeRoute component={TemplateEdit} />,
        },
        {
          path: "/contact_rooms",
          element: <EmployeeRoute component={ContactMessages} />,
        },
        {
          path: "/surveys/:id",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={SurveyShow} />,
        },
        {
          path: "/libraries/new",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={LibraryEdit} />,
        },
        {
          path: "/libraries/:id/edit",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={LibraryEdit} />,
        },
      ],
    },
    {
      path: "/settings",
      element: (
        <AdminSettingLayout>
          <Outlet />
        </AdminSettingLayout>
      ),
      children: [
        {
          path: "/settings/account",
          element: <EmployeeRoute component={SettingsAccount} />,
        },
        {
          path: "/settings/admin",
          element: (
            <SpecificRolesRoute
              roles={[Role.ADMIN, Role.DEPARTMENT_ADMIN]}
              component={SettingsAdmin}
            />
          ),
        },
        {
          path: "/settings/admin/top_message",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={TopMessage} />,
        },
      ],
    },
    {
      path: "/portal",

      children: [
        {
          path: "/portal/contact_rooms/:id",
          element: (
            <PortalLayout fixedWindowHeight>
              <NewHireRoute component={ContactMessageDetailContainer} />
            </PortalLayout>
          ),
        },
        {
          element: (
            <PortalLayout>
              <Outlet />
            </PortalLayout>
          ),
          children: [
            {
              path: "/portal/",
              element: <NewHireRoute component={PortalIndex} />,
            },
            {
              path: "/portal/contact_rooms",
              element: <NewHireRoute component={ContactRoomsIndex} />,
            },
            {
              path: "/portal/onboarding_tasks",
              element: <NewHireRoute component={OnboardingTaskIndex} />,
            },
            {
              path: "/portal/onboarding_tasks/:onboardingTaskId",
              element: <NewHireRoute component={OnboardingTaskDetail} />,
            },
            {
              path: "/portal/libraries",
              element: <NewHireRoute component={LibraryIndex} />,
            },
            {
              path: "/portal/libraries/:libraryId",
              element: <NewHireRoute component={LibraryDetail} />,
            },
            {
              path: "/portal/messages/:id",
              element: <NewHireRoute component={MessageIndex} />,
            },
            {
              path: "/portal/surveys",
              element: <NewHireRoute component={SurveyIndex} />,
            },
            {
              path: "/portal/surveys/:id",
              element: <NewHireRoute component={SurveyDetail} />,
            },
            {
              path: "/portal/surveys/thanks",
              element: <NewHireRoute component={Thanks} />,
            },
            {
              path: "/portal/settings/account",
              element: <NewHireRoute component={SettingsAccount} />,
            },
          ],
        },
      ],
    },
    {
      path: "/",
      element: (
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      ),
      children: [
        {
          path: "/journey/surveys/:id",
          element: <NewHireRoute component={JourneySurvey} />,
        },
        {
          path: "/journey/surveys/:id/new",
          element: <NewHireRoute component={JourneySurveysNew} />,
        },
        {
          path: "/preview/surveys/:id",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={PreviewSurvey} />,
        },
        {
          path: "/preview/surveys/:id/new",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={PreviewSurveysNew} />,
        },
        {
          path: "/preview/thanks",
          element: <SpecificRolesRoute roles={[Role.ADMIN]} component={PreviewSurveyThanks} />,
        },
        {
          path: "/profile",
          element: <NewHireRoute component={ProfilePage} />,
        },
        {
          path: "/profile/confirm",
          element: <NewHireRoute component={ProfileConfirm} />,
        },
        {
          path: "/complete",
          element: <Complete />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};
