import { Employee, Department, extractLatestStatus, convertStatusToString } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useEffect, useCallback } from "react";

import { EmployeeDifferenceSatisfaction } from "./TableRowItems/EmployeeDifferenceSatisfaction";
import { EmployeeJoinAt } from "./TableRowItems/EmployeeJoinAt";
import { EmployeeManageMenu } from "./TableRowItems/EmployeeManageMenu";
import { EmployeeMentor } from "./TableRowItems/EmployeeMentor";
import { EmployeeSupportMembers } from "./TableRowItems/EmployeeSupportMembers";

import { DepartmentNameText } from "~/components/domains/departments";
import { UserSummaryTooltip } from "~/components/domains/employees";
import { UserIconWithLabel } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useAccessControl } from "~/hooks/accessControl";
import {
  useAllEmployees,
  useCurrentUser,
  useEmployeeModals,
  useResendInvitation,
} from "~/hooks/employee";
import { useOnboardingExperienceByTenantId } from "~/hooks/onboardingExperience";
import { useOnboardingTasks } from "~/hooks/onboardingTask/useOnboardingTasks";
import { useClipboard, useQuery, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

const getMentor = (allEmployees: Employee[], mentorId?: string) => {
  return allEmployees.find((employee) => employee.id === mentorId);
};

const getSupportMembers = (allEmployees: Employee[], supportMemberIds?: string[]) => {
  if (!supportMemberIds) return [];
  return allEmployees.filter((employee) => supportMemberIds.includes(employee.id));
};

type Props = {
  displayEmployees: Employee[];
  departments: Department[];
  order: "asc" | "desc";
  setOrder: (order: "asc" | "desc") => void;
};

export const EmployeeTable: FC<Props> = ({ displayEmployees, departments, order, setOrder }) => {
  const { currentUser } = useCurrentUser();
  const { allEmployees } = useAllEmployees();
  const { isEditable } = useAccessControl();
  const { enqueueSnackbar } = useSnackbar();
  const { query } = useQuery();

  const {
    data: onboardingExperiencesByTenantId,
    isValidating: isValidatingOnboardingExperiencesByTenantId,
  } = useOnboardingExperienceByTenantId(currentUser.tenantId);
  const { mutate: mutateOnboardingTasks } = useOnboardingTasks(currentUser.tenantId);

  const { resendInvitation } = useResendInvitation();

  const {
    handleOpenAssignMentorModal,
    handleOpenAssignSupportMembersModal,
    handleOpenChangeEmployeeNameModal,
    handleOpenDeleteEmployeeModal,
    handleOpenJoinDateModal,
    handleOpenIntegrateSlackModal,
    handleOpenManageMentorAndSupportMemberModal,
    handleOpenChangeDepartmentModal,
    handleOpenGenerateTasksFormExperienceModal,
  } = useEmployeeModals();

  const [isCheckQuery, setIsCheckQuery] = useState(false);

  const resendInvitationMail = useCallback(
    async (newHire: Employee) => {
      await resendInvitation(newHire.id)
        .then(() => enqueueSnackbar("招待メールを再送しました", { variant: "success" }))
        .catch((e) => {
          captureException({
            error: e as Error,
            tags: { type: "EmployeeTable:resendInvitationMail" },
          });
          enqueueSnackbar("招待メールの再送に失敗しました", { variant: "error" });
        });
    },
    [enqueueSnackbar, resendInvitation]
  );

  const { handleCopy } = useClipboard();
  const handleClickCopyRegisterAccountLinkButton = useCallback(
    (newHireTransactionId: string) =>
      handleCopy(`${window.location.origin}/account/${newHireTransactionId}`, "招待リンク"),
    [handleCopy]
  );

  // サポートメンバーが設定されている場合管理モーダルを表示し、そうでない場合はAdminの場合にのみ設定モーダルを表示する
  const switchSupportMemberModalOpen = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) => {
      if (!isEmpty(supportMembers)) {
        return handleOpenManageMentorAndSupportMemberModal(newHire, mentor, supportMembers);
      }
      if (isEditable(newHire)) {
        handleOpenAssignSupportMembersModal(newHire, mentor, supportMembers);
      }
    },
    [isEditable, handleOpenManageMentorAndSupportMemberModal, handleOpenAssignSupportMembersModal]
  );

  // バディが設定されている場合管理モーダルを表示し、そうでない場合はAdminの場合にのみ設定モーダルを表示する
  const switchMentorModalOpen = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) => {
      if (mentor) {
        return handleOpenManageMentorAndSupportMemberModal(newHire, mentor, supportMembers);
      }
      if (isEditable(newHire)) {
        return handleOpenAssignMentorModal(newHire, mentor, supportMembers);
      }
    },
    [isEditable, handleOpenManageMentorAndSupportMemberModal, handleOpenAssignMentorModal]
  );

  useEffect(() => {
    if (isCheckQuery) return;
    setIsCheckQuery(true);

    const targetEmployeeId = query.get("targetEmployeeId");
    const newHire = displayEmployees.find(
      (displayEmployee) => displayEmployee.id === targetEmployeeId
    );

    if (!newHire) return;

    const mentor = getMentor(allEmployees, newHire.mentorUserId);
    const supportMembers = getSupportMembers(allEmployees, newHire.supportMemberEmployeeIds);

    // クエリパラメータで指定されている場合はダイアログを開く
    switch (query.get("openDialogType")) {
      case "slack":
        handleOpenIntegrateSlackModal(newHire);
        break;
      case "mentor":
        handleOpenAssignMentorModal(newHire, mentor, supportMembers);
        break;
      case "mentorAndSupportMember":
        handleOpenManageMentorAndSupportMemberModal(newHire, mentor, supportMembers);
        break;
      case "join":
        handleOpenJoinDateModal(newHire);
        break;
    }
  }, [
    allEmployees,
    displayEmployees,
    handleOpenAssignMentorModal,
    handleOpenIntegrateSlackModal,
    handleOpenJoinDateModal,
    handleOpenManageMentorAndSupportMemberModal,
    isCheckQuery,
    query,
  ]);

  const widthOptions = ["25%", "7%", "15%", "15%", "15%", "15%", "8%"];
  return (
    <VirtualizedTableV2<Employee>
      hover
      widthOptions={widthOptions}
      headers={[
        {
          text: "ユーザー",
        },
        {
          text: "スコア",
        },
        {
          text: "入社日",
          onSort: () => setOrder(order === "desc" ? "asc" : "desc"),
          order,
        },
        {
          text: "部署",
        },
        {
          text: "バディ",
        },
        {
          text: "サポートメンバー",
        },
        {
          text: "",
        },
      ]}
      rows={displayEmployees}
      rowRenderer={({ key, index, style, rowData: displayNewHire }) => {
        const mentor = getMentor(allEmployees, displayNewHire.mentorUserId);
        const supportMembers = getSupportMembers(
          allEmployees,
          displayNewHire.supportMemberEmployeeIds
        );

        const contents = [
          <UserSummaryTooltip
            key={`user-${displayNewHire.id}`}
            employee={displayNewHire}
            departments={departments}
          >
            <UserIconWithLabel
              iconPath={displayNewHire.profileIconImageUrl || ""}
              name={displayNewHire.getName()}
              secondaryText={convertStatusToString(
                extractLatestStatus(displayNewHire.onboardingStatuses || []),
                displayNewHire.joinAt
              )}
              iconBadgeType={displayNewHire?.slackUserId ? "slack" : undefined}
            />
          </UserSummaryTooltip>,
          <EmployeeDifferenceSatisfaction
            key={`score-${displayNewHire.id}`}
            employeeId={displayNewHire.id}
          />,
          <EmployeeJoinAt
            key={`joinAt-${displayNewHire.id}`}
            isEditable={isEditable(displayNewHire)}
            onClick={() =>
              isEditable(displayNewHire) ? handleOpenJoinDateModal(displayNewHire) : void 0
            }
            joinAt={displayNewHire.joinAt}
          />,
          <DepartmentNameText
            key={`department-${displayNewHire.id}`}
            departments={departments}
            targetDepartmentIds={displayNewHire.departmentIds}
            isEditable={isEditable(displayNewHire)}
            onClick={() =>
              isEditable(displayNewHire) ? handleOpenChangeDepartmentModal(displayNewHire) : void 0
            }
          />,
          <EmployeeMentor
            key={`mentor-${displayNewHire.id}`}
            mentor={mentor}
            supportMembers={supportMembers}
            isEditable={isEditable(displayNewHire)}
            onClickMentor={() => switchMentorModalOpen(displayNewHire, mentor, supportMembers)}
          />,
          <EmployeeSupportMembers
            key={`supportMembers-${displayNewHire.id}`}
            newHire={displayNewHire}
            mentorId={mentor ? mentor.id : undefined}
            supportMembers={supportMembers}
            isEditable={isEditable(displayNewHire)}
            onClickSupportMembers={() =>
              switchSupportMemberModalOpen(displayNewHire, mentor, supportMembers)
            }
          />,
          <>
            {isEditable(displayNewHire) && (
              <EmployeeManageMenu
                newHire={displayNewHire}
                onClickAddMentorButton={() =>
                  switchMentorModalOpen(displayNewHire, mentor, supportMembers)
                }
                onClickAddSupportMemberButton={() =>
                  handleOpenAssignSupportMembersModal(displayNewHire, mentor, supportMembers)
                }
                onClickChangeEmployeeNameButton={() =>
                  handleOpenChangeEmployeeNameModal(displayNewHire)
                }
                onClickChangeJoinDateButton={() => handleOpenJoinDateModal(displayNewHire)}
                onClickDeleteAccountButton={() => handleOpenDeleteEmployeeModal(displayNewHire)}
                onClickIntegrateSlackButton={() => handleOpenIntegrateSlackModal(displayNewHire)}
                onClickResendInvitationButton={() => resendInvitationMail(displayNewHire)}
                onClickCopyRegisterAccountLinkButton={handleClickCopyRegisterAccountLinkButton}
                onClickChangeDepartmentButton={() =>
                  handleOpenChangeDepartmentModal(displayNewHire)
                }
                onClickGenerateTasksFromExperienceButton={() =>
                  onboardingExperiencesByTenantId
                    ? handleOpenGenerateTasksFormExperienceModal(
                        displayNewHire,
                        onboardingExperiencesByTenantId,
                        mutateOnboardingTasks
                      )
                    : undefined
                }
                disabledGenerateTasksFromExperienceButton={
                  !onboardingExperiencesByTenantId || isValidatingOnboardingExperiencesByTenantId
                }
              />
            )}
          </>,
        ];

        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/employee/${displayNewHire.id}`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};
