import { v4 } from "uuid";

import { OnboardingExperienceTaskTemplate } from "../../OnboardingExperienceTaskTemplate";
import { OnboardingTaskMemo } from "../../OnboardingTask";
import {
  OnboardingExperienceTask,
  taskTypes,
} from "../OnboardingExperienceTask/OnboardingExperienceTask";

export class OnboardingExperienceMessageTask extends OnboardingExperienceTask {
  id: string;
  type = taskTypes.MESSAGE_TASK;
  filePaths: (File | string)[];
  memos: OnboardingTaskMemo[];
  templateId: string;
  // メッセージを依頼する対象のロール
  requestedRoles: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">[];
  associationId: string;

  constructor(init: Omit<OnboardingExperienceMessageTask, "updateForUpdatingTemplate">) {
    super(init);
    this.id = init.id;
    this.filePaths = init.filePaths;
    this.memos = init.memos;
    this.templateId = init.templateId;
    this.requestedRoles = init.requestedRoles;
    this.associationId = init.associationId;
  }

  public static create(
    params: Optional<
      Omit<OnboardingExperienceMessageTask, "updateForUpdatingTemplate">,
      "id" | "associationId"
    >
  ) {
    return new OnboardingExperienceMessageTask({
      ...params,
      id: params.id ?? v4(),
      requestedRoles: params.requestedRoles ?? [],
      associationId: params.associationId ?? v4(),
    });
  }

  updateForUpdatingTemplate(
    template: OnboardingExperienceTaskTemplate
  ): OnboardingExperienceMessageTask {
    return new OnboardingExperienceMessageTask({
      ...this,
      title: template.title,
      body: template.description,
    });
  }
}
