import { Employee, OnboardingMessageTask } from "@onn/common";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { OnboardingTaskRepository } from "~/infrastructure/api/onboardingTaskRepository";

const onboardingTaskRepository = new OnboardingTaskRepository();

export class CancelMessageRequestUseCase {
  async execute(
    taskId: string,
    contentId: string,
    currentUser: Employee
  ): Promise<OnboardingMessageTask> {
    if (!currentUser.isAdmin()) {
      throw new Error("権限がありません");
    }

    const messageTask = await onboardingTaskRepository.findById(taskId);

    if (!messageTask) {
      throw new Error("メッセージタスクが見つかりませんでした");
    }

    if (messageTask.type !== "MESSAGE_TASK") {
      throw new Error("対象のタスクはメッセージタスクである必要があります");
    }

    const newMessageTask = messageTask.extractContent(contentId);
    await onboardingTaskRepository.update(newMessageTask.id, newMessageTask);

    const content = messageTask.contents.find((v) => v.id === contentId);
    if (content?.requestedEmployeeId) {
      await functionOperator.httpsCall("notifyCancelMessageRequest", {
        messageTaskId: messageTask.id,
        targetUserId: content.requestedEmployeeId,
      });
    }

    return newMessageTask;
  }
}
