import { OnboardingExperience, OnboardingExperienceTaskType } from "@onn/common";
import { useCallback, useState } from "react";

import { useNotifyOperationLog } from "../shared";
import { useSnackbar } from "../shared/useSnackbar";

import { useCurrentUser } from "~/hooks/employee";
import { UpdateOnboardingExperienceUseCase } from "~/service/usecases/onboardingExperience";

const updateOnboardingExperienceUseCase = new UpdateOnboardingExperienceUseCase();

/**
 * onboardingExperienceを更新する関数を提供する
 */
export const useUpdateOnboardingExperience = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { operationLog, notifyOperationLog } = useNotifyOperationLog();

  /**
   * @param {string} onboardingExperienceId onboardingExperienceのid
   * @param {Partial<OnboardingExperience>} updates 更新の中身
   */
  const updateOnboardingExperience = useCallback(
    async ({
      onboardingExperienceId,
      newOnboardingExperience,
      newOnboardingExperienceTasks,
      emailsForEditableEmployeeIds,
    }: {
      onboardingExperienceId: string;
      newOnboardingExperience: OnboardingExperience;
      newOnboardingExperienceTasks?: OnboardingExperienceTaskType[];
      emailsForEditableEmployeeIds?: string[];
    }) => {
      setIsLoading(true);
      await updateOnboardingExperienceUseCase
        .execute({
          onboardingExperienceId,
          newOnboardingExperience,
          currentUser,
          newOnboardingExperienceTasks,
          emailsForEditableEmployeeIds,
        })
        .then(() => {
          enqueueSnackbar("エクスペリエンスを更新しました", { variant: "success" });
          notifyOperationLog(
            operationLog.updateOnboardingBuilder(currentUser, onboardingExperienceId)
          );
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw new Error(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar, notifyOperationLog, operationLog]
  );

  return { isLoading, updateOnboardingExperience };
};
