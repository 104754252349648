import { Menu, MenuItem } from "@material-ui/core";
import { OnboardingSimpleTask, OnboardingTaskType, Employee } from "@onn/common";
import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  MouseEvent,
  useEffect,
  ComponentProps,
} from "react";
import styled from "styled-components";

import { AnimatedTaskStatusChip } from "./AnimatedTaskStatusChip";

import { Chip, Typography } from "~/components/uiParts";
import { usePreviousValue } from "~/hooks/shared";

type ChipProps = Pick<React.ComponentProps<typeof Chip>, "color" | "label">;

type Props = {
  onboardingTask: OnboardingTaskType;
  newHire: Employee;
  onClickSwitchStatusButton?: () => void;
};

const taskStyles = {
  completed: { label: "完了", color: "primary" },
  expired: { label: "期限切れ", color: "secondary" },
  waiting: { label: "未着手", color: "grey" },
  // アニメーション用のステート
  tada: { label: "🎉", color: "primary" },
} as const;

export const OnboardingTaskStatusChip: FC<Props> = ({
  onboardingTask,
  newHire,
  onClickSwitchStatusButton,
}) => {
  const statusChipProps: Pick<ChipProps, "label" | "color"> = useMemo(() => {
    if (onboardingTask.status === "COMPLETED") {
      return taskStyles.completed;
    }

    if (onboardingTask.isExpiredByEmployee(newHire)) {
      return taskStyles.expired;
    }

    return taskStyles.waiting;
  }, [newHire, onboardingTask]);

  const [animationStyles, setAnimationStyles] = useState<ComponentProps<
    typeof AnimatedTaskStatusChip
  > | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { value } = usePreviousValue<OnboardingSimpleTask["status"]>(onboardingTask.status);

  useEffect(() => {
    // 未完了から完了にステータスが変わった時にアニメーションを実行する
    if (value === "NOT_STARTED" && onboardingTask.status === "COMPLETED") {
      setAnimationStyles({
        initialStyle: statusChipProps,
        midStyle: taskStyles.tada,
        finalStyle: taskStyles.completed,
      });

      // AnimatedTaskStatusChipのアニメーションが終わった後にstyleを消す
      setTimeout(() => setAnimationStyles(null), 1700);
    }
  }, [onboardingTask.status, value, statusChipProps]);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const clickMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (!onClickSwitchStatusButton) return;
      onClickSwitchStatusButton();
      setAnchorEl(null);
    },
    [onClickSwitchStatusButton]
  );

  return (
    <>
      {animationStyles ? (
        <AnimatedTaskStatusChip {...animationStyles} />
      ) : (
        <Chip
          color={statusChipProps.color}
          label={statusChipProps.label}
          bold
          onClick={onboardingTask instanceof OnboardingSimpleTask ? openMenu : undefined}
        />
      )}

      {onClickSwitchStatusButton && (
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <StyledMenuItem onClick={clickMenu}>
            <Typography variant="body2">
              {statusChipProps.label === "完了" ? "未着手" : "完了"}
            </Typography>
          </StyledMenuItem>
        </StyledMenu>
      )}
    </>
  );
};

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 24px;
  }
`;
