import { Grid, Box } from "@material-ui/core";
import { Employee, EMPLOYEE_ACTION_TYPE, OnboardingStatus } from "@onn/common";
import React, { useState, FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useNotifyIntroduction } from "~/hooks/employee/useNotifyIntroduction";
import { useUploadImageFile, checkInputIconHasValues, useInputIcon } from "~/hooks/file";
import { useCompleteOnboardingGeneralTask } from "~/hooks/onboardingTask";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeActiveLogUseCase } from "~/service/usecases/employeeActiveLogUseCase";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { TransactionUseCase } from "~/service/usecases/transactionUseCase";
import { captureException, mixin } from "~/util";

type LocationState = {
  userDataForUpdate: Employee;
  inputIconForUpdate: Parameters<typeof useInputIcon>[0];
};

const employeeActiveLogUseCase = new EmployeeActiveLogUseCase();

export const ProfileConfirm: FC = () => {
  const { currentUser } = useCurrentUser();
  const { state } = useLocation();
  const userDataForUpdate = (state as LocationState | undefined)?.userDataForUpdate;
  const inputIconForUpdate = (state as LocationState | undefined)?.inputIconForUpdate;

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { uploadImageFile } = useUploadImageFile();
  const { completeOnboardingGeneralTask } = useCompleteOnboardingGeneralTask();
  const { notifyIntroduction } = useNotifyIntroduction();

  const navigate = useNavigate();

  if (!userDataForUpdate?.profile) {
    // NOTE: userDataForUpdateが存在しない状態でレンダリングさせないためにnullを返す
    return <Navigate to="/profile" />;
  }

  const handleClickSubmit = async (): Promise<void> => {
    if (!inputIconForUpdate) {
      return;
    }

    setLoading(true);
    try {
      const shouldUploadImage =
        checkInputIconHasValues(inputIconForUpdate) &&
        /data:image\//i.test(inputIconForUpdate.dataUrl) &&
        currentUser.profileIconImageUrl !== inputIconForUpdate.path;

      if (shouldUploadImage) {
        await uploadImageFile({
          dataUrl: inputIconForUpdate.dataUrl,
          path: inputIconForUpdate.path,
        });
      }
      await EmployeeUseCase.update(userDataForUpdate.id, {
        profile: userDataForUpdate.profile,
        onboardingStatus: OnboardingStatus.PROFILE_REGISTERED,
        ...(shouldUploadImage ? { profileIconImageUrl: inputIconForUpdate.path } : {}),
      });

      await TransactionUseCase.updateProfileType(userDataForUpdate.tenantId, userDataForUpdate.id);
      const registeredProfileLogs = await employeeActiveLogUseCase.findByEmployeeIdAndType(
        currentUser.id,
        currentUser.tenantId,
        EMPLOYEE_ACTION_TYPE.REGISTERED_PROFILE
      );
      if (!registeredProfileLogs.length) {
        await employeeActiveLogUseCase.create({
          employeeId: currentUser.id,
          type: EMPLOYEE_ACTION_TYPE.REGISTERED_PROFILE,
          tenantId: currentUser.tenantId,
        });
      }
      await completeOnboardingGeneralTask("REGISTER_PROFILE", currentUser.id, currentUser.tenantId);

      // コアの処理でなく、他ユーザーへの通知処理なので、パフォーマンス改善のため、awaitしないようにした
      // https://github.com/workside-inc/onn/pull/3593
      notifyIntroduction();
      navigate("/complete", { state: { from: "profile" } });
    } catch (e) {
      enqueueSnackbar("自己紹介を保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "ProfileConfirm:handleClickSubmit" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClickPrev = (): void => {
    navigate("/profile", {
      state: {
        userDataFromConfirmPage: userDataForUpdate,
        inputIconFromConfirmPage: inputIconForUpdate,
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <StyledBox>
      <Typography variant="h2">Profile</Typography>
      <StyledCard>
        {inputIconForUpdate && (
          <Box mb={8}>
            <Typography variant="body1" bold>
              プロフィール画像
            </Typography>
            <Box mt={3}>
              <img width={160} src={inputIconForUpdate.dataUrl} alt="アイコン" />
            </Box>
          </Box>
        )}
        <Box mb={8}>
          <Typography variant="body1" bold>
            誕生日
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">
              {userDataForUpdate.profile.birthMonth}/{userDataForUpdate.profile.birthDate}
            </Typography>
          </Box>
        </Box>
        <Box mb={8}>
          <Typography variant="body1" bold>
            ニックネーム
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">{userDataForUpdate.profile.nickname}</Typography>
          </Box>
        </Box>
        <Box mb={8}>
          <Typography variant="body1" bold>
            私を構成するタグ
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">{userDataForUpdate.profile.tags}</Typography>
          </Box>
        </Box>
        <Box mb={8}>
          <Typography variant="body1" bold>
            経歴サマリー
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">{userDataForUpdate.profile.career}</Typography>
          </Box>
        </Box>
        <Box mb={8}>
          <Typography variant="body1" bold>
            これから一緒に働く仲間に一言！
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">{userDataForUpdate.profile.free}</Typography>
          </Box>
        </Box>
        <Box mt={7} mb={9} display="flex">
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={3} xs={12}>
              <Button
                fullWidth
                variant="outlined"
                borderRadius="circle"
                color="primary"
                disabled={loading}
                onClick={handleClickPrev}
              >
                修正する
              </Button>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Button
                fullWidth
                variant="contained"
                borderRadius="circle"
                color="primary"
                disabled={loading}
                onClick={handleClickSubmit}
              >
                {loading ? "送信中" : "送信する"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </StyledCard>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  width: 100%;
  ${mixin.breakDown.sm`
    background-color: white;
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 28px;
  padding: 80px;
  ${mixin.breakDown.sm`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0px;
    width: 100%;
  `}
`;
