import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { Typography, Button, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const BulkRemindModal: FC<Props> = ({ open, onCancel, onSubmit }) => {
  const content = useMemo(() => {
    return (
      <Box textAlign="center">
        <Typography variant="body1">
          ステータスが「期限切れ」になっているタスクの担当者へ <br />
          一括でリマインド通知を送信しますか？
        </Typography>
        <Box mt={1}>
          <Typography variant="caption">この操作は取り消すことができません。</Typography>
        </Box>
      </Box>
    );
  }, []);

  const footer = useMemo(() => {
    return (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          削除
        </Button>
      </StyledButtonContainer>
    );
  }, [onCancel, onSubmit]);

  return (
    <Modal
      title="一括リマインド通知"
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
