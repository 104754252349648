import { Box } from "@material-ui/core";
import { Question, ItemType } from "@onn/common";
import React, { FC, Fragment } from "react";

import { RatingRadioGroup, Typography } from "~/components/uiParts";

interface Props {
  question: Question;
}

const RatingRadio = () => {
  const captions = ["充実して\nいなかった", "どちらとも\n言えない", "とても充実\nしていた"];
  return <RatingRadioGroup hasLabel radioLength={5} captions={captions} disableEffect />;
};

const RegularText = (text: string) => <Typography variant="body1">{text}</Typography>;

const BoldText = (text: string) => (
  <Typography variant="body2" bold>
    {text}
  </Typography>
);

const Title = (question: Question) => {
  switch (question.type) {
    case ItemType.CUSTOM_RADIO:
    case ItemType.TITLE_CUSTOM_RADIO:
    case ItemType.TITLE_ICON_CUSTOM_RADIO:
      return RegularText(question.title);
    default:
      return "";
  }
};

const Content = (question: Question): JSX.Element[] | JSX.Element => {
  switch (question.type) {
    case ItemType.TITLE: {
      const childLength = question.questions?.length;
      return question.questions.map((questionChild: Question, i) => {
        const pdContent = questionChild.questionItems?.length > 0 ? 2 : 0;
        return (
          <Box key={`ContentQuestion-content_${i}`}>
            {Title(questionChild)}
            <Box pl={pdContent} pb={childLength === i + 1 ? 0 : pdContent}>
              {Content(questionChild)}
            </Box>
          </Box>
        );
      });
    }
    case ItemType.SMALL_RATING:
      return BoldText(`1.よくなかった～3.よかった`);
    case ItemType.MEDIUM_RATING:
      return RatingRadio();
    case ItemType.LARGE_RATING:
      return BoldText(`1.よくなかった～10.よかった`);
    case ItemType.CUSTOM_RADIO:
    case ItemType.TITLE_CUSTOM_RADIO:
    case ItemType.TITLE_ICON_CUSTOM_RADIO: {
      const indexItemEnd = question.questionItems.length - 1;
      const answer: string =
        question.questionItems.length > 0
          ? `1.${(question.questionItems[0] as (typeof question.questionItems)[number]).title}～${
              question.questionItems.length
            }.${
              (question.questionItems[indexItemEnd] as (typeof question.questionItems)[number])
                .title
            }`
          : "";
      return BoldText(answer);
    }
    case ItemType.CHECK_BOX:
      return question.questionItems.map((questionItem, index) => {
        return <Fragment key={index}>{BoldText(`・${questionItem.title}`)}</Fragment>;
      });
    case ItemType.TEXT:
    case ItemType.SERIAL_NUMBER_TEXT:
    case ItemType.TITLE_TEXT:
      return BoldText(`・${question.title}`);
    case ItemType.NPS:
      return BoldText(
        `0.${question.caption ? question.caption[0] : ""}〜10.${
          question.caption ? question.caption.slice(-1)[0] : ""
        }`
      );
    default:
      return <Box />;
  }
};

export const ContentQuestion: FC<Props> = ({ question }) => {
  switch (question.type) {
    case ItemType.TEXT:
    case ItemType.SERIAL_NUMBER_TEXT:
    case ItemType.TITLE_TEXT:
      return <Box />;
    default:
      return <Box pt={3}>{Content(question)}</Box>;
  }
};
