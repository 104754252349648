import { OnboardingTaskType } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback } from "react";

/**
 * オンボーディングタスクを入社者のidでfilterする関数を提供するhooks
 */
export const useFilterTasksByNewHireIds = () => {
  /**
   * オンボーディングタスクを入社者のidでfilterする
   * @param {OnboardingSimpleTask[]} onboardingTasks
   * @param {string[]} newHireIds
   * @param {Employee["joinAt"]} joinAt
   */
  const filterTasksByNewHireIds = useCallback(
    (onboardingTasks: OnboardingTaskType[], newHireIds: string[]) => {
      // employeeIdが選択されていない場合はフィルターしない
      if (isEmpty(newHireIds)) return onboardingTasks;
      return onboardingTasks.filter((task) => {
        return newHireIds.includes(task.employeeId);
      });
    },
    []
  );

  return { filterTasksByNewHireIds };
};
