import { Box, Grid } from "@material-ui/core";
import { Survey, SurveyType, ItemType, Question, QuestionMode } from "@onn/common";
import React, { useCallback, useEffect, useState, FC } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { SurveyMain, SurveySide } from "~/components/domains/transactions";
import { Typography, Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar } from "~/hooks/shared";
import { useSurveyTemplate } from "~/hooks/templates";

export const SurveyShow: FC = () => {
  const { currentUser } = useCurrentUser();

  const { id: surveyId } = useParams<"id">();
  const { data: surveyTemplate, isValidating: isValidatingSurveyTemplate } = useSurveyTemplate(
    currentUser.tenantId,
    surveyId
  );
  const [newSurveyTemplate, setNewSurveyTemplate] = useState<Survey>();
  useEffect(() => {
    setNewSurveyTemplate(surveyTemplate);
  }, [surveyTemplate]);

  const changeSurvey = useCallback(
    (event: React.ChangeEvent<{ name: string; value: string }>, survey: Survey): void => {
      const { target } = event;
      setNewSurveyTemplate({
        ...survey,
        [target.name]: target.name === "disabled" ? Boolean(target.value) : event.target.value,
      });
    },
    []
  );

  const changeQuestion = useCallback(
    (
      event: React.ChangeEvent<{ id: string; name: string; value: string }>,
      survey: Survey,
      targetQuestion: Question
    ): void => {
      const { target } = event;
      setNewSurveyTemplate({
        ...survey,
        questions: survey.questions.map((question: Question) => {
          if (question.name === targetQuestion.name) {
            return {
              ...question,
              [target.name]: target.name === "required" ? Boolean(target.value) : target.value,
            };
          }
          return question;
        }),
      });
    },
    []
  );

  const addQuestion = useCallback(async (survey: Survey): Promise<void> => {
    const lastQuestion = survey.questions.slice(-1)[0] as (typeof survey.questions)[number];
    const isTitle = lastQuestion.type === ItemType.TITLE;
    const questionNumber =
      Number(
        isTitle
          ? (
              lastQuestion.questions.slice(-1)[0] as (typeof lastQuestion.questions)[number]
            ).name.substring(8)
          : lastQuestion.name.substring(8)
      ) + 1;

    setNewSurveyTemplate({
      ...survey,
      questions: [
        ...survey.questions,
        {
          title: "",
          name: `question${questionNumber}`,
          required: false,
          mode: QuestionMode.ADDITION,
          type: ItemType.TEXT,
          section: survey.type === SurveyType.JOINED ? 1 : 2, // SurveyType.JOINEDはオンボーディングアンケートである。オンボーディングアンケートの場合は1ページ目、振り返りアンケートの場合は2ページ目の末尾に追加する
        } as Question,
      ],
    });
  }, []);

  const deleteQuestion = useCallback(
    async (survey: Survey, targetQuestion: Question): Promise<void> => {
      setNewSurveyTemplate({
        ...survey,
        questions: survey.questions.filter((question) => question.name !== targetQuestion.name),
      });
    },
    []
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isValidatingSurveyTemplate && !surveyTemplate) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [enqueueSnackbar, navigate, surveyTemplate, isValidatingSurveyTemplate]);

  if (isValidatingSurveyTemplate || !surveyTemplate || !newSurveyTemplate) {
    return <Loading size="large" fullHeight />;
  }

  return (
    <StyledBox pt={5} pb={5} pl={5} pr={5}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <StyledLink to={`/surveys`}>
            <Typography variant="body2" color="textSecondary">
              ← アンケート一覧
            </Typography>
          </StyledLink>
          <Box mb={5} />
          <Typography variant="h4" bold color="textPrimary">
            {surveyTemplate.title}
          </Typography>
          <Box mb={5} />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <SurveyMain
            survey={newSurveyTemplate}
            additionalQuestions={newSurveyTemplate.questions.filter((v) => v.mode === "ADDITION")}
            changeSurvey={(e) => changeSurvey(e, surveyTemplate)}
            addQuestion={addQuestion}
            changeQuestion={changeQuestion}
            deleteQuestion={deleteQuestion}
          />
        </Grid>
        <Grid item xs={4}>
          <SurveySide survey={newSurveyTemplate} />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
