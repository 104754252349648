import { Box, TextareaAutosize as MuiTextareaAutosize } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type CustomProps = {
  value?: string;
  error?: boolean;
  maxTextCount?: number;
  fullWidth?: boolean;
  defaultValue?: string;
  helperText?: React.ReactNode;
};

type Props = ComponentProps<typeof MuiTextareaAutosize> & CustomProps;

// 特に指定しない場合はmaxRows=16
export const TextareaAutosize: FC<Props> = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ value = "", error, maxRows = 16, maxTextCount, fullWidth, ...props }, ref) => {
    return (
      <StyledBox>
        <StyledMuiTextareaAutosize
          {...props}
          value={value}
          $error={error}
          $fullWidth={fullWidth}
          maxRows={maxRows}
          ref={ref}
        />
        {maxTextCount && (
          <StyledMaxLengthTypography variant="caption">
            {value.length}/{maxTextCount}
          </StyledMaxLengthTypography>
        )}
        {props.helperText && (
          <StyledTypography variant="overline" color={error ? "error" : "textSecondary"}>
            {props.helperText}
          </StyledTypography>
        )}
      </StyledBox>
    );
  }
);

const StyledBox = styled(Box)`
  position: relative;
  display: inline;
`;

const StyledMaxLengthTypography = styled(Typography)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledMuiTextareaAutosize = styled(MuiTextareaAutosize)<
  Props & { $fullWidth?: boolean; $error?: boolean }
>`
  width: ${(props) => (props.$fullWidth ? `100%;` : `inherit`)};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 16px;
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.$error ? props.theme.palette.error.main : props.theme.palette.grey[200])};
  border-radius: 5px;
  resize: none;
  outline: none;
  white-space: pre-wrap;

  ::placeholder {
    color: ${(props) => props.theme.palette.grey[200]};
  }

  &:hover {
    border: 1px solid
      ${(props) =>
        props.$error ? props.theme.palette.error.main : props.theme.palette.action.active};
  }

  &:focus {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    padding: 17.5px 15px;
    border: 2px solid
      ${(props) =>
        props.$error ? props.theme.palette.error.main : props.theme.palette.primary.main};
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
`;
