import { OnboardingTaskType, Employee } from "@onn/common";
import { useState, useCallback } from "react";

import { useNotifyOperationLog, useSnackbar } from "../shared";

import { useCurrentUser } from "~/hooks/employee";
import { OnboardingTaskUseCase } from "~/service/usecases/onboardingTaskUseCase";

const onboardingTaskUseCase = new OnboardingTaskUseCase();
/**
 * onboardingTaskを作成するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns createOnboardingTask
 */
export const useCreateOnboardingTask = (): {
  isLoading: boolean;
  createOnboardingTask: (
    newOnboardingTask: OnboardingTaskType,
    newHire: Employee,
    emailsWithoutOnnAccount: string[]
  ) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();

  const createOnboardingTask = useCallback(
    async (
      newOnboardingTask: OnboardingTaskType,
      newHire: Employee,
      emailsWithoutOnnAccount: string[]
    ) => {
      setIsLoading(true);
      await onboardingTaskUseCase
        .create(newOnboardingTask, emailsWithoutOnnAccount)
        .then(() => enqueueSnackbar("オンボーディングタスクを作成しました", { variant: "success" }))
        .catch(() =>
          enqueueSnackbar("オンボーディングタスクの作成に失敗しました", { variant: "error" })
        )
        .finally(() => setIsLoading(false));
      notifyOperationLog(
        operationLog.createOnboardingTask(currentUser, newOnboardingTask, newHire)
      );
    },
    [currentUser, enqueueSnackbar, notifyOperationLog, operationLog]
  );

  return { isLoading, createOnboardingTask };
};
