import { Employee, Role } from "@onn/common";
import { isEmpty } from "lodash";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { TenantSettingsRepository } from "~/infrastructure/api/tenantSettingsRepository";

const tenantSettingsRepository = new TenantSettingsRepository();

export class UpdateDefaultFollowerIdsUseCase {
  async execute(
    tenantId: string,
    newFollowerIds: string[],
    emailsWithoutOnnAccount: string[]
  ): Promise<void> {
    let createdEmployeeIds: string[] = [];
    if (!isEmpty(emailsWithoutOnnAccount)) {
      createdEmployeeIds = (await this.createAccount(emailsWithoutOnnAccount)).map((v) => v.id);
    }

    await tenantSettingsRepository.update(tenantId, {
      contact: {
        defaultFollowerEmployeeIds: [...newFollowerIds, ...createdEmployeeIds],
      },
    });
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emailsWithoutOnnAccount: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emailsWithoutOnnAccount.map((email) => {
          return {
            email,
            role: Role.MEMBER,
            departmentIds: [],
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }
}
