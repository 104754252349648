import {
  SurveyTransaction,
  TransactionStatus,
  Transaction,
  MemoTransaction,
  Employee,
  ChartItem,
} from "@onn/common";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { calcAccessibleEmployees } from "../functions/calcAccessibleEmployees";

import { sortTransactions } from "../functions/sortTransactions";
import { useGetIsAccessibleEmployeePage } from "../hooks";
import { useMemoUseCase } from "../hooks/useMemoUseCase";
import { useOtherEmployees } from "../hooks/useOtherEmployees";
import { TransactionCommentsMap, useTransactionComments } from "../hooks/useTransactionComments";

import { TransactionReactionsMap, useTransactionReactions } from "../hooks/useTransactionReactions";

import type { OtherEmployee } from "../functions/calcAccessibleEmployees";

import { useAccessControl } from "~/hooks/accessControl";
import { useDepartmentsByTenantId } from "~/hooks/department";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask";
import { useSnackbar } from "~/hooks/shared";
import { useSurveyTransactionScores } from "~/hooks/transaction";
import { useTransactions } from "~/hooks/transaction/useTransactions";

type MemoUseCase = ReturnType<typeof useMemoUseCase>;

export const useViewModel = (
  newHireEmployee?: Employee
): {
  transactionsLoading: boolean;
  memoAttachedFilesLoading: boolean;
  commentsLoading: boolean;
  transactionCommentsMap: TransactionCommentsMap;
  transactionReactionsMap: TransactionReactionsMap;
  isLoadingTransactionReactionsMap: boolean;
  transactions: (Transaction | SurveyTransaction | MemoTransaction)[];
  surveyTransactions: SurveyTransaction[];
  memos: MemoUseCase["memos"];
  memoAttachedFilesMap: MemoUseCase["attachedFilesMap"];
  currentUser: Employee;
  chartPointItems: ChartItem[];
  accessibleEmployees: OtherEmployee[];
  handleCreateMemo: MemoUseCase["handleCreateMemo"];
  handleDropFilesError: (message: string) => void;
  handleEditMemo: MemoUseCase["handleEditMemo"];
  handleDeleteMemo: MemoUseCase["handleDeleteMemo"];
  handleDownloadMemoAttachedFile: MemoUseCase["handleDownloadAttachedFile"];
  handleDeleteMemoAttachedFile: MemoUseCase["handleDeleteAttachedFile"];
  loadTransactionComments: (transactionId: string) => void;
} => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useCurrentUser();
  const { isEditable } = useAccessControl();

  const { data: departments = [] } = useDepartmentsByTenantId(currentUser.tenantId);
  const { data: onboardingTasks, isValidating: isValidatingOnboardingTasks } =
    useOnboardingTasksByEmployeeId(newHireEmployee?.id);

  const { getIsAccessibleEmployeePage } = useGetIsAccessibleEmployeePage();
  const { data: surveyTransactionScores = {} } = useSurveyTransactionScores({
    employeeIds: newHireEmployee ? [newHireEmployee.id] : [],
  });

  const { otherEmployees, isLoadingOtherEmployees } = useOtherEmployees({ newHireEmployee });
  const {
    data: transactions,
    mutate: mutateTransactions,
    isValidating: isValidatingTransactions,
  } = useTransactions(newHireEmployee?.id);

  const transactionIds = useMemo(() => {
    if (!transactions) return [];
    return transactions.map(({ id }) => id);
  }, [transactions]);

  const { commentsLoading, loadTransactionComments, transactionCommentsMap } =
    useTransactionComments({
      transactionIds,
      otherEmployees,
    });

  const { transactionReactionsMap, isLoadingTransactionReactionsMap } = useTransactionReactions({
    transactionIds,
    otherEmployees,
    isLoadingOtherEmployees,
    isValidatingTransactions,
  });

  const accessibleEmployees = useMemo(
    () =>
      calcAccessibleEmployees({
        newHireEmployee,
        otherEmployees,
        departmentIds: currentUser.departmentIds,
        departments,
      }),
    [newHireEmployee, otherEmployees, currentUser.departmentIds, departments]
  );

  const sortedTransactions = useMemo(() => {
    if (!transactions) return [];

    const completedTransactions = transactions
      .filter((t) => t.status !== TransactionStatus.SENT)
      // SENT ではない場合、 answeredAt は必須で存在するので型キャストする
      .map((t) => t as AugmentedRequired<SurveyTransaction, "answeredAt">);
    return sortTransactions([...completedTransactions]);
  }, [transactions]);

  const surveyTransactions = useMemo<SurveyTransaction[]>(
    () => sortedTransactions.filter((v): v is SurveyTransaction => v instanceof SurveyTransaction),
    [sortedTransactions]
  );

  const memoTransactions = useMemo<MemoTransaction[]>(
    () => sortedTransactions.filter((v): v is MemoTransaction => v instanceof MemoTransaction),
    [sortedTransactions]
  );

  const {
    loading: memoOperationLoading,
    attachedFilesLoading,
    memos,
    attachedFilesMap,
    handleCreateMemo,
    handleEditMemo,
    handleDeleteMemo,
    handleDownloadAttachedFile,
    handleDeleteAttachedFile,
  } = useMemoUseCase({
    newHireEmployee,
    otherEmployees,
    memoTransactions,
    onSuccess: () => mutateTransactions(),
  });

  const handleDropFilesError = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: "error" });
    },
    [enqueueSnackbar]
  );

  // 同じテナントの入社者か確認する
  useEffect(() => {
    if (newHireEmployee && currentUser?.tenantId !== newHireEmployee.tenantId) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [currentUser?.tenantId, enqueueSnackbar, navigate, newHireEmployee]);

  // 削除されていない入社者か確認する
  useEffect(() => {
    if (newHireEmployee && newHireEmployee.deleted) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [currentUser, enqueueSnackbar, navigate, newHireEmployee]);

  // currentUserがアクセスできるかを確認する
  useEffect(() => {
    if (
      newHireEmployee &&
      onboardingTasks &&
      !getIsAccessibleEmployeePage(currentUser, newHireEmployee, onboardingTasks, isEditable)
    ) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [
    currentUser,
    enqueueSnackbar,
    getIsAccessibleEmployeePage,
    isEditable,
    isValidatingOnboardingTasks,
    navigate,
    newHireEmployee,
    onboardingTasks,
  ]);

  return {
    transactionsLoading: memoOperationLoading || !transactions,
    memoAttachedFilesLoading: attachedFilesLoading,
    commentsLoading,
    transactionCommentsMap,
    transactionReactionsMap,
    isLoadingTransactionReactionsMap,
    transactions: sortedTransactions,
    surveyTransactions,
    memos,
    memoAttachedFilesMap: attachedFilesMap,
    currentUser,
    chartPointItems: newHireEmployee ? surveyTransactionScores[newHireEmployee.id] || [] : [],
    accessibleEmployees,
    handleCreateMemo,
    handleDropFilesError,
    handleEditMemo,
    handleDeleteMemo,
    handleDownloadMemoAttachedFile: handleDownloadAttachedFile,
    handleDeleteMemoAttachedFile: handleDeleteAttachedFile,
    loadTransactionComments,
  };
};
