import { Survey } from "@onn/common";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { SurveyThanks } from "~/components/domains/transactions";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";

type LocationState = {
  survey?: Survey;
};

export const Thanks: FC = () => {
  const { currentUser } = useCurrentUser();
  const locationState = useLocation().state as LocationState;
  const survey =
    locationState && locationState.survey ? locationState.survey : ({ title: "" } as Survey);

  return <SurveyThanks mentorUserId={currentUser.mentorUserId} surveyTitle={survey.title} />;
};
