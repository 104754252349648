import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  department: Department;
  checked: boolean;
  /**
   * 下位部署がチェックされたときはindeterminateにしてにして、同一系列の部署が選択できないようにする
   */
  checkedChild: boolean;
  disabled?: boolean;
  onChange: () => void;
};

export const DepartmentFormControlLabel: FC<Props> = ({
  department,
  checked,
  checkedChild,
  disabled,
  onChange,
}) => (
  <StyledFormControlLabel
    onChange={onChange}
    control={
      <Checkbox
        checked={checked}
        indeterminate={checkedChild}
        name={department.id}
        value={department.id}
        disabled={disabled && !checked}
      />
    }
    disabled={disabled && !checked}
    label={
      <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
        <StyledTypography variant="body2" noWrap $disabled={disabled && !checked}>
          {department.name}
        </StyledTypography>
      </Box>
    }
  />
);

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)<{ $disabled?: boolean }>`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) =>
      props.$disabled ? props.theme.palette.text.disabled : props.theme.palette.text.secondary};
  }
`;
