import {
  QuestionItem,
  Question,
  ItemType,
  Survey,
  SurveyTransaction,
  MemoTransaction,
} from "@onn/common";
import { format } from "date-fns";

import { MemoDB } from "../transactionRepository";

import { convertTimestampToDate } from "~/util/convertTimestampToDate";

/**
 * transactionRepository.tsからexportされる複数のクラスで横断的に利用されているconvert関数をこのファイルに定義する
 * transactionRepositoryからは1クラスのみをexportするべきであり、convert関数を唯一に統一されるべきであるため、
 * その対応が完了したら、convert関数はprivateな関数に移動し、このファイルは削除されるべきである
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const convertToQuestion = (data): Question => {
  const questionItems = data.questionItems
    ? (data.questionItems as QuestionItem[]).map((v) => {
        return new QuestionItem(v.title, v.name, v.value, v.text || false, v.placeholder);
      })
    : [];
  const questions =
    data.type === ItemType.TITLE
      ? (data.questions as Question[]).map((v) => {
          const questionItems = v.questionItems
            ? v.questionItems.map((v) => {
                return new QuestionItem(v.title, v.name, v.value, v.text || false, v.placeholder);
              })
            : [];
          return new Question({
            ...v,
            questions: [],
            questionItems,
          });
        })
      : [];
  return new Question({
    ...data,
    questions,
    questionItems,
  });
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const convertToSurvey = (data): Survey => {
  const questions = (data.contents.questions as Question[]).map((v) => convertToQuestion(v));

  return new Survey({
    tenantId: data.tenantId,
    id: data.id,
    title: data.contents.title,
    subtitle: data.contents.subtitle,
    description: data.contents.description,
    answerTime: data.contents.answerTime,
    questions: questions,
    timing: data.timing,
    remindTiming: data.remindTiming,
    disabled: data.disabled,
    totalSection: data.contents.totalSection,
    surveyTimings: data.contents.surveyTimings,
    type: data.contents.type,
    sendTargetType: data.sendTargetType,
    contentsType: data.contentsType,
    sendWeekday: data.sendWeekday,
    lang: data.contents.lang,
  });
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const convertToSurveyTransaction = (data): SurveyTransaction => {
  const survey = convertToSurvey(data);
  const answerItems = data.resultContents.answers;
  return new SurveyTransaction({
    id: data.id,
    contents: survey,
    dataType: data.dataType,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // FIXME: remindDate をオプショナルにする
    remindDate: data.remindDate ? format(new Date(data.remindDate), "yyyy-MM-dd") : null,
    resultContents: answerItems ? answerItems : [],
    sendAt: convertTimestampToDate(data.sendAt),
    answeredAt: data.answeredAt ? convertTimestampToDate(data.answeredAt) : undefined,
    sendTarget: data.sendTarget,
    sendTo: data.sendTo,
    status: data.status,
    templateId: data.templateId,
    employeeId: data.employeeId,
    tenantId: data.tenantId,
  });
};

export const convertToMemoTransaction = (data: MemoDB): MemoTransaction => {
  return new MemoTransaction(
    data.id,
    data.dataType,
    data.contents,
    data.tenantId,
    data.employeeId,
    data.status,
    convertTimestampToDate(data.createdAt),
    convertTimestampToDate(data.updatedAt),
    convertTimestampToDate(data.sendAt),
    data.deleted
  );
};
