import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { format } from "date-fns";
import React, { FC } from "react";
import styled from "styled-components";

import { Tooltip, Typography } from "~/components/uiParts";

type Props = {
  isEditable: boolean;
  onClick: () => void;
  joinAt: Employee["joinAt"];
};

export const EmployeeJoinAt: FC<Props> = ({ isEditable, onClick, joinAt }) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Tooltip arrow placement="bottom" title={isEditable ? "入社日を設定する" : ""}>
        <StyledClickableBox
          display="flex"
          alignItems="center"
          $isEditable={isEditable}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        >
          <Typography variant="caption" color="textSecondary" display="block">
            {joinAt ? format(new Date(joinAt), "yyyy/MM/dd") : "未設定"}
          </Typography>
        </StyledClickableBox>
      </Tooltip>
    </Box>
  );
};

const StyledClickableBox = styled(Box)<{ $isEditable: boolean }>`
  ${({ $isEditable }) => ($isEditable ? `cursor : pointer;` : `cursor: default;`)}
  width: 100%;
  height: 40px;
`;
