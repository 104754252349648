import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, OnboardingExperienceTaskType, SlackUser } from "@onn/common";
import { isEmpty } from "lodash";
import React, { MouseEvent, useState, useCallback, forwardRef } from "react";
import styled from "styled-components";

import { AssigneeEmployees } from "./parts";

import { IconButton, Typography } from "~/components/uiParts";
import { Paper } from "~/components/uiParts/Paper";

type Props = {
  tasks: OnboardingExperienceTaskType[];
  assigneeRole: OnboardingExperienceTaskType["assigneeRole"];
  assigneeEmployees: Employee[];
  slackUsers: SlackUser[];
  allEmployeesWithDeleted: Employee[];
  editable: boolean;
  menuItems: {
    title: string;
    onClick: () => void;
  }[];
  tasksElement: JSX.Element;
};

export const ProcessItemTemplate = forwardRef<HTMLDivElement, Props>(
  (
    {
      tasks,
      assigneeRole,
      assigneeEmployees,
      slackUsers,
      allEmployeesWithDeleted,
      editable,
      menuItems,
      tasksElement,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setAnchorEl(null);
    }, []);

    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="16px">
          <AssigneeEmployees
            tasks={tasks}
            assigneeRole={assigneeRole}
            assigneeEmployees={assigneeEmployees}
            slackUsers={slackUsers}
            allEmployeesWithDeleted={allEmployeesWithDeleted}
          />
          {!isEmpty(menuItems) && editable && <IconButton icon="menuVert" onClick={openMenu} />}
          {!isEmpty(menuItems) && (
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  onClick={() => {
                    menuItem.onClick();
                    setAnchorEl(null);
                  }}
                  key={index}
                  tabIndex={0 - index}
                >
                  <Typography variant="body2">{menuItem.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
        {/* paper部分の高さを求めるためにwrapする */}
        <div ref={ref}>
          <StyledPaper>{tasksElement}</StyledPaper>
        </div>
      </>
    );
  }
);

const StyledPaper = styled(Paper)`
  padding: 24px;
  grid-row-gap: 24px;
  display: flex;
  flex-direction: column;
`;
