import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { QuestionTitle } from "~/components/uiParts/QuestionTitle";
import { RatingRadioGroup } from "~/components/uiParts/RatingRadioGroup";

type Props = {
  title: string;
  hrOnly?: boolean;
  evaluationValue: number;
};

export const FiveGradeEvaluationQuestion: FC<Props> = (props) => {
  const { title, hrOnly = false, evaluationValue } = props;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <QuestionTitle title={title} hrOnly={hrOnly} />
      <Box>
        <RatingRadioGroup radioLength={5} value={evaluationValue} disableEffect />
      </Box>
    </Box>
  );
};
