import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = { isAgreed: boolean; isNewGrad: boolean; onChange: () => void };

const TermsOfUse: FC<Props> = React.memo(({ isAgreed, isNewGrad, onChange }) => {
  const { t } = useTranslation(["account"]);
  const termsOfUseText = useMemo(() => {
    // NOTE: 新卒向けのi18nは別途対応
    if (isNewGrad) {
      return `・本サービスでは、個人情報について、当社が別途定めるプライバシーポリシーの定めに従い、取り扱うものとします。\n・当社は、本サービスの提供若しくは運営又はサービスの向上等に必要な範囲内で、本サービスにおけるあなたと本サービスを利用する企業との間のメッセージ（本サービスと連携するLINE等の外部サービスを通じてなされるメッセージを含みます。また、これらのメッセージに係る日時、既読の有無等の情報を含みます。）その他の本サービスを利用する企業との関連する情報の閲覧・利用をすることがあります。`;
    } else {
      return t("termsOfUse");
    }
  }, [isNewGrad, t]);

  return (
    <Box mt="40px">
      <Box p="16px" overflow="scroll" border="1px solid gray">
        <Typography variant="caption" color="textSecondary">
          {termsOfUseText}
        </Typography>
      </Box>
      <Box mt="16px">
        <FormControlLabel
          key="isAgreed"
          control={<Checkbox checked={isAgreed} onChange={onChange} />}
          label={
            <Typography variant="caption" color="textSecondary">
              {t("consentRelatedCheckbox")}
            </Typography>
          }
        />
      </Box>
    </Box>
  );
});

export default TermsOfUse;
