import { Box } from "@material-ui/core";
import { isValidEmail, MessageContent } from "@onn/common";
import React, { FC, useMemo, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { NotFound } from "../../NotFound";

import { Paper, Typography, Loading, TextField, Button } from "~/components/uiParts";
import { useOnboardingTask } from "~/hooks/onboardingTask";
import { useSubmitMessageContent } from "~/hooks/onboardingTask/messageTask/useSubmitMessageContent";
import { mixin } from "~/util";

export const MessageComplete: FC = () => {
  // ====================
  // navigate
  // ====================

  const navigate = useNavigate();

  // ====================
  // hooks
  // ====================

  const { id: taskId, contentId } = useParams<"id" | "contentId">();
  const { data: onboardingTaskData, isValidating: isValidatingOnboardingTask } =
    useOnboardingTask(taskId);

  // ====================
  // state
  // ====================

  const [email, setEmail] = useState("");
  const [isInvalidEmailType, setIsInvalidEmailType] = useState(false);

  const { isLoading: isLoadingSubmitMessageContent, submitMessageContent } =
    useSubmitMessageContent();

  // ====================
  // variables
  // ====================

  const messageContent = useMemo(() => {
    const task = onboardingTaskData?.onboardingTask;
    if (task?.type !== "MESSAGE_TASK") return null;
    return task.contents.find((content) => content.id === contentId);
  }, [contentId, onboardingTaskData?.onboardingTask]);

  const isEmptyEmail = useMemo(() => email === "", [email]);

  // ====================
  // event handlers
  // ====================

  const handleSetEmail = useCallback(
    (input: string) => {
      // Emailは任意のため空文字の場合はエラーを表示しない
      setIsInvalidEmailType(!!input.trim() && !isValidEmail(input));
      setEmail(input);
    },
    [setIsInvalidEmailType, setEmail]
  );

  const handleUpdateMessageContentAndSendEmail = useCallback(
    async (messageContent: MessageContent, email: string) => {
      const task = onboardingTaskData?.onboardingTask;
      if (!task) return;

      await submitMessageContent(
        task,
        MessageContent.create({
          ...messageContent,
          email: email,
        }),
        true
      );
    },
    [onboardingTaskData?.onboardingTask, submitMessageContent]
  );

  // ====================
  // effect
  // ====================

  useEffect(() => {
    if (!isValidatingOnboardingTask && messageContent && !messageContent.isCompleted) {
      navigate(`/messages/${taskId}/${messageContent.id}`);
    }
  }, [isValidatingOnboardingTask, navigate, messageContent, taskId]);

  // ====================
  // component
  // ====================

  if (isValidatingOnboardingTask) {
    return <Loading size="large" fullHeight />;
  }

  if (!messageContent || !onboardingTaskData || !onboardingTaskData.employee) {
    return <NotFound />;
  }

  if (onboardingTaskData.onboardingTask.type !== "MESSAGE_TASK") {
    return <NotFound />;
  }

  return (
    <StyledContainer maxWidth="800px">
      <StyledTitle variant="h2">{onboardingTaskData.onboardingTask.title}</StyledTitle>

      <Paper paddingLarge>
        <Box mb={4}>
          <Typography variant="h2" bold color="primary" align="center">
            THANK YOU!
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography align="center">
            {onboardingTaskData.onboardingTask.title}の記入をしていただきありがとうございました！🙌
            {"\n"}
            {"\n\n"}
            このメッセージは、
            {onboardingTaskData.onboardingTask.dueDate.getDisplayTextByEmployee(
              onboardingTaskData.employee,
              "M月d日 h時"
            )}
            に{onboardingTaskData.employee.getName()}
            さんへと送られます。{"\n"}
            {onboardingTaskData.employee.getName()}{" "}
            さんが気持ちよく働けるように、みんなでサポートしていきましょう！
          </Typography>
        </Box>

        {!messageContent.email && (
          <>
            <Typography display="block" variant="caption" color="textSecondary" align="center">
              メッセージの確認や編集をご希望の場合は、メールアドレスを入力し送信ボタンを押してください。
              {"\n"}
              メッセージの編集に必要なURLをメールアドレス宛にお送りします。
            </Typography>

            <Box height="12px" />

            <StyledFormButtonContainer>
              <StyledTextField
                fullWidth
                error={isInvalidEmailType}
                variant="outlined"
                name="email"
                type="email"
                autoComplete="email"
                helperText={isInvalidEmailType && "メールアドレスの形式を正しく入力してください"}
                placeholder="メールアドレスを記入してください(任意)"
                value={email}
                onChange={(event) => handleSetEmail(event.target.value)}
              />
              <Button
                borderRadius="regular"
                variant="contained"
                color="primary"
                disabled={isEmptyEmail || isInvalidEmailType || isLoadingSubmitMessageContent}
                onClick={() => handleUpdateMessageContentAndSendEmail(messageContent, email)}
              >
                送信
              </Button>
            </StyledFormButtonContainer>
          </>
        )}
      </Paper>

      <Box mt={4} mb={27} textAlign="center">
        <Typography variant="caption" color="textSecondary">
          ブラウザを閉じて終了してください。
        </Typography>
      </Box>
    </StyledContainer>
  );
};

// ====================
// styles
// ====================

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledTitle = styled(Typography)`
  padding-bottom: 32px;

  ${mixin.breakDown.sm`
    padding: 40px 24px 32px;
  `}
`;

const StyledFormButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 12px;
  width: 70%;
  margin: 0 auto;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 34px;

    input {
      font-size: 14px;
      padding: 8px 14px;
    }
  }
`;
