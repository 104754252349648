import { Transaction, SurveyTransaction, MemoTransaction, TransactionDataType } from "@onn/common";
import { isAfter } from "date-fns";

export const sortTransactions = (
  transactions: Array<
    Transaction | AugmentedRequired<SurveyTransaction, "answeredAt"> | MemoTransaction
  >
): Array<Transaction | SurveyTransaction | MemoTransaction> => {
  const returnTimeForSort = (
    t: Transaction | AugmentedRequired<SurveyTransaction, "answeredAt"> | MemoTransaction
  ): Date => {
    if (t instanceof Transaction) return new Date(t.sendAt.toString());
    // SURVEYはansweredAtでソートする
    if (t.dataType === TransactionDataType.SURVEY) return t.answeredAt;
    // ADD_MEMOはcreatedAtでソートする
    if (t instanceof MemoTransaction) return t.createdAt;

    return new Date(t.sendAt.toString());
  };

  // 適切なプロパティでソートする
  return transactions.sort((t1, t2) => {
    if (isAfter(returnTimeForSort(t1), returnTimeForSort(t2))) {
      return -1;
    } else {
      return 1;
    }
  });
};
