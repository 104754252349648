import { ContactRoom, Employee } from "@onn/common";

import { IContactRoomRepository } from "~/service/repository/iContactRoomRepository";

export class UpdateIsCompletedUseCase {
  constructor(private readonly contactRoomRepository: IContactRoomRepository) {}

  async execute(
    currentUser: Employee,
    contactRoomId: string,
    isCompleted: boolean
  ): Promise<ContactRoom> {
    const contactRoom = await this.contactRoomRepository.findById(contactRoomId);

    const newContactRoom = contactRoom.update(currentUser.id, {
      isCompleted,
    });

    await this.contactRoomRepository.update(newContactRoom);

    return newContactRoom;
  }
}
