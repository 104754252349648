import { useEffect, useState } from "react";

import { OtherEmployee } from "./useOtherEmployees";

import { useReactionsByTransactionIds } from "~/hooks/reaction";

type Reaction = {
  employeeId: string;
  name: string;
  profileIconImageUrl?: string;
};

export type TransactionReactionsMap = { [transactionId: string]: Reaction[] };

export const useTransactionReactions = ({
  transactionIds,
  otherEmployees,
  isValidatingTransactions,
  isLoadingOtherEmployees,
}: {
  transactionIds: string[];
  otherEmployees: OtherEmployee[];
  isValidatingTransactions: boolean;
  isLoadingOtherEmployees: boolean;
}) => {
  const [transactionReactionsMap, setTransactionReactionsMap] = useState<TransactionReactionsMap>();
  const [isLoadingTransactionReactionsMap, setIsLoadingTransactionReactionsMap] = useState(true);

  const { data: reactions, isValidating: isValidatingReactions } =
    useReactionsByTransactionIds(transactionIds);

  useEffect(() => {
    if (isValidatingTransactions || isValidatingReactions || isLoadingOtherEmployees) {
      return;
    }

    setTransactionReactionsMap(
      (reactions || []).reduce((acc, current) => {
        const employee = otherEmployees.find((employee) => employee.id === current.employeeId);
        if (!employee) return acc;

        return {
          ...acc,
          [current.transactionId]: [
            ...(acc[current.transactionId] ?? []),
            {
              employeeId: employee.id,
              name: employee.name,
              profileIconImageUrl: employee.profileIconImageUrl,
            },
          ],
        };
      }, {} as TransactionReactionsMap)
    );

    setIsLoadingTransactionReactionsMap(false);
  }, [
    isLoadingOtherEmployees,
    isValidatingReactions,
    isValidatingTransactions,
    otherEmployees,
    reactions,
  ]);

  return {
    transactionReactionsMap: transactionReactionsMap || {},
    isLoadingTransactionReactionsMap,
  };
};
