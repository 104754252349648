import {
  OnboardingExperienceSimpleTask,
  DifferenceDate,
  OnboardingExperienceTaskType,
  OnboardingExperienceTaskFactory,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { useCallback, FC, useEffect } from "react";
import styled from "styled-components";

import { AssigneeRoleForm } from "../shared/AssigneeRoleForm";
import { BodyForm } from "../shared/BodyForm";
import { DeliveryDateForm } from "../shared/DeliveryDateForm";
import { DueDateForm } from "../shared/DueDateForm";
import { TitleForm } from "../shared/TitleForm";

import { Typography } from "~/components/uiParts";

type Props = {
  onboardingExperienceId: string;
  newTask: OnboardingExperienceTaskType;
  editable?: boolean;
  selectedEmails: string[];
  setInvalid: (invalid: boolean) => void;
  setNewTask: (newTask: React.SetStateAction<OnboardingExperienceTaskType>) => void;
  setSelectedEmails: (emails: string[]) => void;
};

export const SimpleTask: FC<Props> = ({
  newTask,
  editable = true,
  selectedEmails,
  setInvalid,
  setNewTask,
  setSelectedEmails,
}) => {
  useEffect(() => {
    setInvalid(
      // BY_NAMEだが担当者の指定がされていないかつslackユーザーも選択されていないときは不正
      (newTask.assigneeRole === "BY_NAME" && isEmpty(selectedEmails)) ||
        !newTask.title.trim() ||
        newTask.title.trim().length > OnboardingExperienceSimpleTask.MAX_TITLE_NUM ||
        !newTask.body.trim() ||
        !newTask.dueDate.amount ||
        newTask.dueDate.amount <= 0
    );
  }, [
    newTask.assigneeRole,
    newTask.body,
    newTask.dueDate.amount,
    newTask.title,
    setInvalid,
    selectedEmails,
  ]);

  const handleChangeSettings = useCallback(
    (newObject: Omit<Partial<OnboardingExperienceTaskType>, "type">) =>
      setNewTask((prev: OnboardingExperienceTaskType) =>
        OnboardingExperienceTaskFactory.createOnboardingTask({ ...prev, ...newObject })
      ),
    [setNewTask]
  );

  const handleChangeAttachedFiles = useCallback(
    (newFiles: (File | Pick<File, "name">)[]) => {
      handleChangeSettings({
        filePaths: newFiles.map((v) => {
          return v instanceof File ? v : v.name;
        }),
      });
    },
    [handleChangeSettings]
  );

  return (
    <>
      <StyledMutedTypography variant="caption">
        担当者がタスクを実行し、完了ボタンを押すことでタスクが完了します。
      </StyledMutedTypography>
      <AssigneeRoleForm
        isSelectableUser={true}
        isMultiple={false}
        tooltipText="「管理者、バディ、サポートメンバー」を選択すると、該当のロールに設定されたメンバーが自動的にアサインされます。「特定のメンバーを指定」を選択すると、指定したメンバーが自動的にアサインされます。「担当者をあとで設定」を選択すると、タスク発行後に手動で入社者ごとに担当メンバーを設定することができます。"
        selectedAssigneeRole={newTask.assigneeRole}
        assignableRoles={["NEW_HIRE", "MENTOR", "SUPPORT_MEMBER", "ADMIN", "BY_NAME", "NOT_SET"]}
        setAssigneeRole={(value) =>
          handleChangeSettings({
            assigneeRole: value,
            assigneeIds: [],
            emailsWithoutOnnAccount: [],
          })
        }
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        readOnly={!editable}
      />
      <TitleForm
        title={newTask.title}
        placeholder="例：入社当日の予定を確認する"
        readOnly={!editable}
        setTitle={(value) => handleChangeSettings({ title: value })}
      />
      <BodyForm
        title="説明"
        caption="タスクの概要や完了条件の記載、必要なファイルの添付を行なってください。"
        placeholder={`例：\n入社にあたって、入社当日の集合場所やオリエンテーションの流れなど、よくある質問をまとめた資料をご用意しました！\n 入社までに以下のURLを確認して、最高のDAY1を迎えましょう！\nhttp://onn.xxxxxxxxxx.com`}
        body={newTask.body}
        filePaths={newTask.filePaths}
        readOnly={!editable}
        setBody={(value) => handleChangeSettings({ body: value })}
        setFiles={handleChangeAttachedFiles}
      />
      <DueDateForm
        dueDate={newTask.dueDate}
        units={
          newTask.type === "MESSAGE_TASK"
            ? ["DAY", "BUSINESS_DAY", "MONTH"]
            : ["DAY", "BUSINESS_DAY"]
        }
        caption="タスクの期日を入力してください。"
        readOnly={!editable}
        setDueDate={(value) =>
          handleChangeSettings({
            dueDate: new DifferenceDate({ ...newTask.dueDate, ...value }),
          })
        }
      />
      <DeliveryDateForm
        deliveryDate={newTask.deliveryDate}
        setDeliveryDate={(value) =>
          handleChangeSettings({
            deliveryDate: new DifferenceDate({ ...newTask.deliveryDate, ...value }),
          })
        }
        readOnly={!editable}
      />
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
