import { Employee } from "@onn/common";
import { useContext } from "react";

import { AuthorizationContext } from "~/components/providers";

type GuardedResult = {
  authorizedUsers: NonEmptyArray<Employee>;
  portalAccessibleUsers: Employee[];
  mutateAuthorizedUsers: () => Promise<void>;
};

type NonGuardedResult = {
  authorizedUsers: Employee[] | null;
  portalAccessibleUsers: Employee[] | null;
  mutateAuthorizedUsers: () => Promise<void>;
};

export const useAuthorization = (): GuardedResult => {
  const {
    authorizedUsers,
    portalAccessibleUsers,
    mutateAuthorizedUsers,
    guardAuthorizedUsers,
    guardPortalAccessibleUsers,
  } = useContext(AuthorizationContext);

  if (authorizedUsers === undefined || portalAccessibleUsers === undefined) {
    throw new Error("AuthorizationProvider の子コンポーネントでのみ使用できます");
  }

  if (
    !guardAuthorizedUsers(authorizedUsers) ||
    !guardPortalAccessibleUsers(portalAccessibleUsers)
  ) {
    throw new Error("AuthorizationGuard の子コンポーネントでのみ使用できます");
  }

  return { authorizedUsers, portalAccessibleUsers, mutateAuthorizedUsers };
};

export const useAuthorizationNonGuarded = (): NonGuardedResult => {
  const { authorizedUsers, portalAccessibleUsers, mutateAuthorizedUsers } =
    useContext(AuthorizationContext);

  if (authorizedUsers === undefined || portalAccessibleUsers === undefined) {
    throw new Error("AuthorizationProvider の子コンポーネントでのみ使用できます");
  }

  return { authorizedUsers, portalAccessibleUsers, mutateAuthorizedUsers };
};
