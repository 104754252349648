import { Tenant } from "@onn/common";
import React, { FC, useCallback, useState, createContext, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUser, useSignOut } from "~/hooks/employee";
import { apiClient } from "~/libs";
import { captureException, chanelTalk } from "~/util";

export const TenantContext = createContext<{
  tenant: Tenant | undefined;
  fetchTenant: () => void;
}>({
  tenant: undefined,
  fetchTenant: () => void 0,
});

export const TenantProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();
  const { signOut } = useSignOut();

  const [tenant, setTenant] = useState<Tenant | null>();

  const fetchTenant = useCallback(async () => {
    const tenant = await apiClient
      .get("/get_tenant")
      .then((res) => Tenant.plainToInstance(res.data));
    setTenant(tenant);
  }, []);

  useEffect(() => {
    fetchTenant();
  }, [fetchTenant]);

  useEffect(() => {
    if (!tenant) return;

    // NOTE: 新卒向けテナントの管理者にのみ表示する
    if (currentUser.isAdmin() && Tenant.isNewGraduateTenant(tenant)) {
      chanelTalk(currentUser.getName(), tenant.tenantName, location.pathname);
    }
  }, [currentUser, location.pathname, tenant]);

  useEffect(() => {
    if (!tenant) return;

    // 新卒向けのユーザーが新卒向け環境にアクセスしないようにリダイレクトさせる処理
    if (Tenant.isNewGraduateTenant(tenant)) {
      captureException({
        error: new Error("新卒テナントのユーザーが中途向けドメインにアクセスしました"),
        extras: {
          tenant,
          currentUser,
        },
        tags: {
          type: "redirect-to-new-graduate-domain",
        },
      });
      signOut();
      window.location.replace(
        process.env.NODE_ENV === "production"
          ? "https://app.onn-hr.com/?is-redirect=true"
          : "https://staging.app.onn-hr.com/?is-redirect=true"
      );
    }
  }, [currentUser, signOut, tenant]);

  if (!tenant) return null;

  return (
    <TenantContext.Provider value={{ tenant, fetchTenant }}>{children}</TenantContext.Provider>
  );
};
