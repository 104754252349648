import { TextField, Box } from "@material-ui/core";
import { isValidEmail } from "@onn/common";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Paper, Typography } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException, mixin } from "~/util";

export const ResetPasswordShow: FC = () => {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const checkEmail = (email: string) => {
    if (isValidEmail(email)) {
      setError(false);
    } else {
      setError(true);
    }
    setEmail(email);
  };

  const handleToSubmit = async () => {
    setLoading(true);
    try {
      const checkMailExist = await AccountUseCase.checkMailExist(email);
      // メールアドレスが存在しない場合も、セキュリティ対策のためFBせずに、完了画面に遷移する
      if (checkMailExist) {
        await AccountUseCase.sendPasswordResetEmail(email);
      }
      navigate("/reset_password/complete", { state: { email: email } });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      captureException({
        error: e as Error,
        tags: { type: "ResetPasswordShow:handleToSubmit" },
      });
    }
    setLoading(false);
  };

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Typography variant="h2" bold>
          {`パスワード再設定\nメールを送る`}
        </Typography>
        <Box mt="8px">
          <Typography variant="body2">{`登録済みのメールアドレスを入力してください。\n再設定用メールをお送りします。`}</Typography>
        </Box>
        <form>
          <Box mt={8}>
            <TextField
              fullWidth
              error={error}
              margin="none"
              id="email"
              label="メールアドレス"
              variant="outlined"
              name="email"
              type="email"
              autoComplete="email"
              helperText={error && "メールアドレスの形式を正しく入力してください"}
              autoFocus
              value={email}
              color="primary"
              onChange={(event) => checkEmail(event.target.value)}
            />
          </Box>
          <Box mt={8}>
            <Button
              fullWidth
              type="submit"
              borderRadius="circle"
              variant="contained"
              color="primary"
              disabled={loading || email.length === 0 || error}
              onClick={() => handleToSubmit()}
            >
              {loading ? "送信中" : "再設定用メールを送信する"}
            </Button>
          </Box>
        </form>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    box-shadow: 0 0 10px #0000000d;
    ${mixin.breakDown.sm`
      box-shadow: none;
    `}
  }
`;
