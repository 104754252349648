import { OnboardingExperience, OnboardingExperienceTaskType } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "../shared/useSnackbar";

import { CreateOnboardingExperienceUseCase } from "~/service/usecases/onboardingExperience";
import { captureException } from "~/util";

const createOnboardingExperienceUseCase = new CreateOnboardingExperienceUseCase();

/**
 * onboardingExperienceを作成する関数を提供する
 */
export const useCreateOnboardingExperience = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * onboardingExperienceを作成する
   */
  const createOnboardingExperience = useCallback(
    async (
      newOnboardingExperience: OnboardingExperience,
      onboardingExperienceTasks?: OnboardingExperienceTaskType[]
    ) => {
      setIsLoading(true);
      await createOnboardingExperienceUseCase
        .execute(newOnboardingExperience, onboardingExperienceTasks)
        .then(() => enqueueSnackbar("エクスペリエンスを作成しました", { variant: "success" }))
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useCreateOnboardingExperience:createOnboardingExperience",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return { isLoading, createOnboardingExperience };
};
