import { Employee, Department } from "@onn/common";
import React, { ReactElement, FC } from "react";

import { UserSummaryCard } from "./UserSummaryCard";

import { Tooltip } from "~/components/uiParts";

type Props = {
  employee: Employee;
  departments: Department[];
  shouldDisplayContactRoomButton?: boolean;
  children: ReactElement;
};

export const UserSummaryTooltip: FC<Props> = ({
  employee,
  departments,
  shouldDisplayContactRoomButton,
  children,
}) => {
  return (
    <Tooltip
      title={
        <UserSummaryCard
          employee={employee}
          departments={departments}
          shouldDisplayContactRoomButton={shouldDisplayContactRoomButton}
        />
      }
      placement="top-start"
      interactive
      isBgTransparent
    >
      {children}
    </Tooltip>
  );
};
