import { Employee } from "@onn/common";
import { chunk } from "lodash";

import { EmployeeRepository } from "~/infrastructure/api/employeeRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";
import { factory } from "~/service/repository/ITenantRepository";

const tenantRepository = factory.tenantRepository();
const employeeRepository = new EmployeeRepository();

export class SegregateSlackUseCase {
  async execute(currentUser: Employee): Promise<void> {
    if (!currentUser.isAdmin()) {
      throw new Error("権限がありません");
    }

    const employees = await employeeRepository.findAll(currentUser.tenantId);

    await Promise.all(
      chunk(employees, 500).map(async (chunkEmployees) => {
        const batch = queryOperator.batch();
        chunkEmployees.forEach((employee) => {
          if (employee.slackUserId) {
            employeeRepository.insertDeleteSlackUserIdInBatch(batch, employee.id);
          }
        });

        await batch.commit();
      })
    );

    await tenantRepository.deleteSlackAccessToken(currentUser.tenantId);
  }
}
