import { Menu, MenuItem } from "@material-ui/core";
import React, { FC, useCallback, useState, MouseEvent } from "react";

import { Typography, IconButton } from "~/components/uiParts";

type Props = {
  onClickEditButton: () => void;
  onClickDuplicateButton: () => void;
  onClickManageEditableEmployeesButton: () => void;
  onClickOpenPreviewButton: () => void;
  onClickDeleteButton: () => void;
};

export const ManageOnboardingExperienceMenu: FC<Props> = ({
  onClickEditButton,
  onClickDuplicateButton,
  onClickManageEditableEmployeesButton,
  onClickOpenPreviewButton,
  onClickDeleteButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton icon="menuVert" onClick={openMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickEditButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">タイトル編集</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickDuplicateButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">エクスペリエンス複製</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickManageEditableEmployeesButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">編集者管理</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickOpenPreviewButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">プレビュー</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClickDeleteButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">エクスペリエンス削除</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
