import { Box } from "@material-ui/core";
import { Library, Message, Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, {
  FC,
  Fragment,
  useEffect,
  useState,
  useMemo,
  useRef,
  RefObject,
  useContext,
} from "react";
import { useLocation } from "react-router-dom";

import { CountdownPaper } from "~/components/domains/employees";
import { ContactContextV2 } from "~/components/providers";
import { Typography, PortalListItem, MessageModal } from "~/components/uiParts";
import { useLatestContactMessage } from "~/hooks/contactMessage";
import { useMessageTasksForNewHire } from "~/hooks/onboardingTask/messageTask";
import { useCanDisplayContactRooms } from "~/hooks/portal";
import { useScrollToTargetElement } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting/useTenantSettings";

type Props = {
  newcomerEmployee: Employee;
  libraries: Library[];
  showOnboardingTaskContent: boolean;
  admins: Employee[];
  messageIconPath: string;
  isPreview: boolean;
  isFirstLogin: boolean;
  createReadMessageLog: (message: Message) => void;
};

export const PortalContent: FC<Props> = ({
  newcomerEmployee,
  admins,
  messageIconPath,
  libraries,
  showOnboardingTaskContent,
  isPreview,
  isFirstLogin,
  createReadMessageLog,
}) => {
  const { tenantSettings } = useTenantSettings();
  const location = useLocation();
  const { canDisplayContactRooms } = useCanDisplayContactRooms();

  const { contactRoomUnreadCountList, contactRooms } = useContext(ContactContextV2);

  const taskSectionRef = useRef<HTMLDivElement>(null);
  const welcomeSectionRef = useRef<HTMLDivElement>(null);
  const librarySectionRef = useRef<HTMLDivElement>(null);
  const contactSectionRef = useRef<HTMLDivElement>(null);
  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();

  const [messageModalOpen, setMessageModalOpen] = useState(isFirstLogin); // 初回ログイン時にトップメッセージを開く(プレビューでは開かない)
  const [selectedMessage, setSelectedMessage] = useState<Message>();

  const existTopMessage = tenantSettings.features.topMessage && !isEmpty(tenantSettings.messages);

  const { data: messageTasksForNewHire = [] } = useMessageTasksForNewHire();

  const { data: latestContactMessage = [] } = useLatestContactMessage({
    contactRoomIds: contactRooms.map((v) => v.id),
    tenantId: newcomerEmployee.tenantId,
  });

  const isDisplayContactRoom = useMemo(
    () =>
      canDisplayContactRooms({
        doesExistsContactRooms: !isEmpty(contactRooms),
        doesExistsLatestMessages: !isEmpty(latestContactMessage),
        isContactInitialDisplay: tenantSettings.features.contactInitialDisplay,
      }),
    [
      canDisplayContactRooms,
      contactRooms,
      latestContactMessage,
      tenantSettings.features.contactInitialDisplay,
    ]
  );
  const handleOpenMessageModal = async (message: Message) => {
    setMessageModalOpen(true);
    setSelectedMessage(message);
    createReadMessageLog(message);
  };

  const unreadContactMessageCountLabel = useMemo<string | undefined>(() => {
    if (isEmpty(contactRooms)) return;

    const totalUnreadCount = contactRooms.reduce((acc, cur) => {
      const unreadCount =
        contactRoomUnreadCountList.find((v) => v.contactRoomId === cur.id)?.unreadCount || 0;
      return acc + unreadCount;
    }, 0);
    return totalUnreadCount > 0 ? totalUnreadCount.toString() : undefined;
  }, [contactRoomUnreadCountList, contactRooms]);

  useEffect(() => {
    if (tenantSettings) {
      // 初回ログイン時にトップメッセージを開く
      if (isFirstLogin && tenantSettings.messages && !isEmpty(tenantSettings.messages)) {
        setSelectedMessage(tenantSettings.messages[0]);
        createReadMessageLog(
          tenantSettings.messages[0] as (typeof tenantSettings.messages)[number]
        );
      }
    }
  }, [createReadMessageLog, isFirstLogin, tenantSettings]);

  useScrollToTargetElement({
    enabled: !!scrollTargetRef,
    targetRef: scrollTargetRef,
    scrollYOffset: 0,
  });

  useEffect(() => {
    if (!location.hash) return;
    switch (location.hash) {
      case "#task": {
        setScrollTargetRef(taskSectionRef);
        break;
      }
      case "#message": {
        setScrollTargetRef(welcomeSectionRef);
        break;
      }
      case "#library": {
        setScrollTargetRef(librarySectionRef);
        break;
      }
      case "#contact": {
        setScrollTargetRef(contactSectionRef);
        break;
      }
    }
  }, [location.hash]);

  return (
    <>
      <Box px={3} py={5}>
        <CountdownPaper
          newcomerEmployee={newcomerEmployee}
          users={admins}
          isDisplayWelcomeMessage={!isEmpty(messageTasksForNewHire)}
          onClickWelcomeMessage={() => setScrollTargetRef(welcomeSectionRef)}
        />
      </Box>

      <Box px={3} pb={5}>
        {showOnboardingTaskContent && (
          <Box pt="40px" {...{ ref: taskSectionRef }}>
            <Typography variant="h3" color="textPrimary">
              Task
            </Typography>
            <Typography variant="caption" color="textPrimary">
              入社前後でやることをチェックしましょう！
            </Typography>
            <Box mt="24px">
              <PortalListItem
                to={`/portal/onboarding_tasks${isPreview ? "?preview=true" : ""}`}
                title="オンボーディングタスク"
              />
            </Box>
          </Box>
        )}
        {(existTopMessage || !isEmpty(messageTasksForNewHire)) && (
          <Box pt="40px" {...{ ref: welcomeSectionRef }}>
            <Typography variant="h3" color="textPrimary">
              Message
            </Typography>
            <Typography variant="caption" color="textPrimary">
              社員からのメッセージを確認しましょう！
            </Typography>
            {existTopMessage &&
              tenantSettings.messages &&
              tenantSettings.messages.map((message) => (
                <Fragment key={message.id}>
                  <Box height="24px" />
                  <PortalListItem
                    title={message.title}
                    icon={{ name: message.fullName, url: messageIconPath }}
                    onClick={() => handleOpenMessageModal(message)}
                  />
                </Fragment>
              ))}
            {messageTasksForNewHire.map((messageTask) => {
              return (
                <Box mt="24px" key={messageTask.id}>
                  <PortalListItem
                    title={messageTask.title}
                    emoji="🎉"
                    to={`/portal/messages/${messageTask.id}`}
                  />
                </Box>
              );
            })}
          </Box>
        )}

        {selectedMessage && (
          <MessageModal
            open={messageModalOpen}
            onCancel={() => setMessageModalOpen(false)}
            profileIconImageUrl={messageIconPath}
            username={selectedMessage.fullName}
            position={selectedMessage.position}
            contentText={selectedMessage.content}
          />
        )}

        {tenantSettings.features.library && libraries.length > 0 && (
          <Box pt="40px" {...{ ref: librarySectionRef }}>
            <Typography variant="h3" color="textPrimary">
              Library
            </Typography>
            <Typography variant="caption" color="textPrimary">
              会社の理解を深めましょう！
            </Typography>
            {libraries.map((library) => {
              return (
                <Fragment key={library.id}>
                  <Box height="24px" />
                  <PortalListItem
                    to={`/portal/libraries/${library.id}${isPreview ? "?preview=true" : ""}`}
                    title={library.title}
                    emoji={library.label}
                  />
                </Fragment>
              );
            })}
          </Box>
        )}

        {isDisplayContactRoom && (
          <Box pt="40px" {...{ ref: contactSectionRef }}>
            <Typography variant="h3" color="textPrimary">
              Contact
            </Typography>
            <Typography variant="caption" color="textPrimary">
              入社にあたって聞きたいことや相談したいことがある方は、こちらから人事・受け入れ担当者へ問い合わせましょう。
            </Typography>
            <Box mt="24px">
              <PortalListItem
                title="トーク一覧"
                to={`/portal/contact_rooms`}
                badgeLabel={unreadContactMessageCountLabel}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
