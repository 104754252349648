import { ContactMessage, removeUndefinedFromObject } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  setDoc,
  where,
  query,
  orderBy,
  Timestamp,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import { chunk } from "lodash";

import { firestore } from "~/config/firebase";
import { IContactMessageRepository } from "~/service/repository/iContactMessageRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type MessageForDB = Omit<ExcludeMethods<ContactMessage>, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "contactMessages";
const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10; // 配列処理で同一クエリにおいて実行できる最大長
export class ContactMessageRepository implements IContactMessageRepository {
  async create(contactMessage: ContactMessage): Promise<void> {
    const ref = this.doc(contactMessage.id);
    await setDoc(ref, this.convertToPlainObject(contactMessage));
  }

  listenNewContactMessage(
    contactRoomIds: string[],
    tenantId: string,
    onAddedNewMessage: (newMessage: ContactMessage) => void
  ): (() => void)[] {
    return chunk(contactRoomIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map((chunk) => {
      const q = query(
        this.collection(),
        where("contactRoomId", "in", chunk),
        where("tenantId", "==", tenantId),
        orderBy("createdAt", "asc"),
        startAfter(new Date())
      );
      return onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            onAddedNewMessage(this.dbToObject(change.doc.data() as MessageForDB));
          }
        });
      });
    });
  }
  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private convertToPlainObject(contactMessage: ContactMessage): MessageForDB {
    return removeUndefinedFromObject({
      ...contactMessage,
      createdAt: convertDateToTimestamp(contactMessage.createdAt),
      updatedAt: convertDateToTimestamp(contactMessage.updatedAt),
    });
  }
  private dbToObject({ createdAt, updatedAt, ...rest }: MessageForDB): ContactMessage {
    return new ContactMessage({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }
}
