import { Menu, MenuItem } from "@material-ui/core";

import React, { FC, MouseEvent, useState } from "react";
import styled from "styled-components";

import { IconButton } from "~/components/uiParts";

type Props = {
  onClickOpenRemindModal: () => void;
};

export const SurveyTransactionMenu: FC<Props> = ({ onClickOpenRemindModal }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModal = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    onClickOpenRemindModal();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton icon="menuVert" onClick={(e) => handleOpenMenu(e)} />

      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <StyledMenuItem onClick={handleClickOpenModal}>リマインド通知送信</StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  & .MuiList-padding {
    padding: 12px 0;
  }
  max-height: 372px;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 12px 24px !important;
`;
