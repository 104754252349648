import { Department, removeUndefinedFromObject } from "@onn/common";
import {
  deleteDoc,
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  orderBy,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IDepartmentRepository } from "~/service/repository/iDepartmentRepository";

type DepartmentForDB = Omit<ConstructorParameters<typeof Department>[0], "flat" | "hasChildren">;

const COLLECTION_NAME = "departments";

export class DepartmentRepository implements IDepartmentRepository {
  async findByTenantId(tenantId: string): Promise<Department[]> {
    return await getDocs(
      query(this.collection(), where("tenantId", "==", tenantId), orderBy("name", "asc"))
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => {
        return this.dbToObject(doc.data() as Department);
      });
    });
  }

  async create(department: Department): Promise<void> {
    const ref = this.doc(department.id);
    await setDoc(ref, this.convertToPlainObject(department));
  }

  async update(departmentId: string, newObject: Department): Promise<void> {
    const ref = this.doc(departmentId);
    await updateDoc(ref, this.convertToPlainObject(newObject));
  }

  async delete(departmentId: string): Promise<void> {
    await deleteDoc(this.doc(departmentId));
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private convertToPlainObject(department: Department): DepartmentForDB {
    return removeUndefinedFromObject({
      ...department,
      // childrenはデータベースでは保持しない
      // データベースでは、フラットなオブジェクトのレコードのみが入る
      children: undefined,
    });
  }

  private dbToObject({
    id,
    name,
    layer,
    tenantId,
    parentDepartmentId,
    children,
  }: Department): Department {
    // childrenが存在する場合、再帰的にインスタンスに変換してフロントエンドで利用します
    if (children) {
      return new Department({
        id,
        name,
        layer,
        tenantId,
        parentDepartmentId,
        children: children.map((child) => this.dbToObject(child)),
      });
    } else {
      return new Department({
        id,
        name,
        layer,
        tenantId,
        parentDepartmentId,
        children,
      });
    }
  }
}
