import { Box, Grid } from "@material-ui/core";
import { Question } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { ContentQuestion } from "~/components/domains/transactions";
import { Typography } from "~/components/uiParts";

interface Props {
  index: number;
  question: Question;
}

export const DefaultQuestion: FC<Props> = (props) => {
  const { index, question } = props;

  return (
    <Box key={question.name} mt={3}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body1">
            {`Q${index}. `}
            {`${question.title}`}
            {question.hrOnly && (
              <StyleTypography variant="caption" color="textSecondary">
                ※管理者のみに回答が公開される設問です。
              </StyleTypography>
            )}
          </Typography>
          <ContentQuestion question={question} />
        </Grid>
      </Grid>
    </Box>
  );
};

const StyleTypography = styled(Typography)`
  &.MuiTypography-caption {
    font-size: 10px;
  }
`;
