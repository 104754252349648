import {
  AllContactRoom,
  ContactRoomWithMessagesAndTarget,
  LatestContactMessage,
} from "@onn/common";
import { isAfter, isEqual } from "date-fns";
import { useCallback } from "react";

/**
 * @deprecated CFからのデータ取得により不要になったため、削除予定
 * ContactRoomに紐づく最新メッセージの作成日時, 入社者の入社日によりソートする関数を提供するhooks
 */
export const useSortContactRooms = () => {
  /**
   * ContactRoomに紐づく最新メッセージの作成日時, 入社者の入社日によりソートする関数
   * - メッセージが存在する場合: 最新メッセージの作成日時が現在と近いものから降順
   * - メッセージが存在しない場合: 下記優先度で降順
   *  1. 入社日が未設定
   *  2. 入社日が近いもの
   *
   * @param {ContactRoomWithMessagesAndTarget} contactRooms
   */
  const sortContactRooms = useCallback((contactRooms: ContactRoomWithMessagesAndTarget[]) => {
    const sortByJoinAt = (
      contactRoomA: ContactRoomWithMessagesAndTarget,
      contactRoomB: ContactRoomWithMessagesAndTarget
    ) => {
      // 入社日が未設定の場合は上にするように
      if (!contactRoomA.roomTarget.joinAt) return -1;
      if (!contactRoomB.roomTarget.joinAt) return 1;

      // 入社日が近い方が上にするように
      if (new Date(contactRoomA.roomTarget.joinAt) > new Date(contactRoomB.roomTarget.joinAt)) {
        return -1;
      } else {
        return 1;
      }
    };

    return contactRooms.sort((a, b) => {
      // メッセージがない場合はコンタクトルームが作られた日、メッセージがあるものは最新のメッセージを比較のベースにする

      const aCreatedAt = a.messages.slice(-1)[0]?.createdAt ?? a.createdAt;
      const bCreatedAt = b.messages.slice(-1)[0]?.createdAt ?? b.createdAt;

      // 時間が同じものは入社日が近いものを上にするように
      if (isEqual(aCreatedAt, bCreatedAt)) {
        return sortByJoinAt(a, b);
      }

      // 日時が現在と近いものを上にするように
      return isAfter(aCreatedAt, bCreatedAt) ? -1 : 1;
    });
  }, []);

  return { sortContactRooms };
};

export const useSortContactRoomsV2 = () => {
  /**
   * ContactRoomに紐づく最新メッセージの作成日時, 入社者の入社日によりソートする関数
   * - メッセージが存在する場合: 最新メッセージの作成日時が現在と近いものから降順
   * - メッセージが存在しない場合: 下記優先度で降順
   *  1. 入社日が未設定
   *  2. 入社日が近いもの
   *
   * @param {AllContactRoom} contactRooms
   */
  const sortContactRooms = useCallback(
    (contactRooms: AllContactRoom[], latestContactMessages?: LatestContactMessage[]) => {
      const sortByJoinAt = (contactRoomA: AllContactRoom, contactRoomB: AllContactRoom) => {
        // 入社日が未設定の場合は上にするように
        if (!contactRoomA.employee?.joinAt) return -1;
        if (!contactRoomB.employee?.joinAt) return 1;

        // 入社日が近い方が上にするように
        if (new Date(contactRoomA.employee.joinAt) > new Date(contactRoomB.employee.joinAt)) {
          return -1;
        } else {
          return 1;
        }
      };

      return contactRooms.sort((a, b) => {
        // メッセージがない場合はコンタクトルームが作られた日、メッセージがあるものは最新のメッセージを比較のベースにする
        const aLatestMessage = latestContactMessages?.find((v) => v.contactRoomId === a.id);
        const bLatestMessage = latestContactMessages?.find((v) => v.contactRoomId === b.id);
        const aCreatedAt = aLatestMessage?.createdAt ?? a.createdAt;
        const bCreatedAt = bLatestMessage?.createdAt ?? b.createdAt;

        // 時間が同じものは入社日が近いものを上にするように
        if (isEqual(aCreatedAt, bCreatedAt)) {
          return sortByJoinAt(a, b);
        }

        // 日時が現在と近いものを上にするように
        return isAfter(aCreatedAt, bCreatedAt) ? -1 : 1;
      });
    },
    []
  );

  return { sortContactRooms };
};
