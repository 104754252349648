import { OnboardingStatus } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";
import theme from "~/config/theme";

type Props = {
  stage: OnboardingStatus;
  label: string;
};

export const StageChip: FC<Props> = (props) => {
  const { stage, label } = props;
  let backgroundColor: string;
  let fontColor: string;

  switch (stage) {
    case OnboardingStatus.STANDBY:
    case OnboardingStatus.INVITED:
      backgroundColor = theme.palette.grey[100];
      fontColor = theme.palette.text.primary;
      break;
    case OnboardingStatus.LOGGED_IN:
    case OnboardingStatus.PROFILE_REGISTERED:
    case OnboardingStatus.JOIN_DATE_REGISTERED:
    case OnboardingStatus.MENTOR_REGISTERED:
      backgroundColor = theme.palette.blue.main;
      fontColor = theme.palette.secondary.contrastText;
      break;
    case OnboardingStatus.JOINED:
    case OnboardingStatus.ANSWERED:
      backgroundColor = theme.palette.primary.main;
      fontColor = theme.palette.secondary.contrastText;
      break;
    case OnboardingStatus.ONBOARDING_COMPLETED:
      backgroundColor = theme.palette.grey[400];
      fontColor = theme.palette.secondary.contrastText;
      break;
  }

  return (
    <StyledLabel backgroundColor={backgroundColor} fontColor={fontColor}>
      <Typography variant="caption" bold noWrap>
        {label}
      </Typography>
    </StyledLabel>
  );
};

const StyledLabel = styled.span<{ backgroundColor: string; fontColor: string }>`
  padding: 4px 16px;
  border-radius: 5px;
  color: ${(props) => props.fontColor};
  background-color: ${(props) => props.backgroundColor};
`;
