import { Box } from "@material-ui/core";
import { Employee, OnboardingTaskType } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { EmojiConfettiForTask } from "~/components/domains/onboardingTask";
import { useSortOnboardingTasks } from "~/components/domains/onboardingTask/hooks";
import {
  Loading,
  Divider,
  Typography,
  ProgressCircle,
  PortalListItemGroup,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useGetNewHireOnboardingTasks } from "~/hooks/portal";
import { useQuery } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";

const getContentsForPortalListItemGroup = (
  onboardingTasks: OnboardingTaskType[],
  newHire: Employee,
  isPreview: boolean
) => {
  return onboardingTasks.map((onboardingTask) => {
    return {
      id: onboardingTask.id,
      isCompleted: onboardingTask.status === "COMPLETED",
      title: onboardingTask.title,
      // プレビューの場合は DisplayTiming を表示させる
      caption: `期日： ${
        isPreview
          ? onboardingTask.dueDate.getDisplayTiming()
          : onboardingTask.dueDate.getDisplayTextByEmployee(newHire, "M月d日")
      }まで`,
      to: `/portal/onboarding_tasks/${onboardingTask.id}${isPreview ? "?preview=true" : ""}`,
      // プレビューの場合は常に error 表示しない
      error: isPreview ? false : onboardingTask.isExpiredByEmployee(newHire),
    };
  });
};

export const OnboardingTaskIndex: FC = () => {
  const { currentUser } = useCurrentUser();
  const { query } = useQuery();
  const isPreview = query.get("preview") === "true";
  const { onboardingTasks, isLoading: isLoadingOnboardingTasks } = useGetNewHireOnboardingTasks(
    currentUser.id,
    currentUser.tenantId,
    isPreview
  );

  const { completionPercent, uncompletedOnboardingTasks } = useMemo(() => {
    const completedOnboardingTasks: OnboardingTaskType[] = [];
    const uncompletedOnboardingTasks: OnboardingTaskType[] = [];

    onboardingTasks.forEach((v) => {
      v.status === "COMPLETED"
        ? completedOnboardingTasks.push(v)
        : uncompletedOnboardingTasks.push(v);
    });
    const completionPercent = Math.floor(
      (completedOnboardingTasks.length / onboardingTasks.length) * 100
    );

    return { completionPercent, uncompletedOnboardingTasks };
  }, [onboardingTasks]);

  // 未完了タスク
  const uncompletedOnboardingTask = useMemo(
    () => ({
      exists: !isEmpty(uncompletedOnboardingTasks),
      count: uncompletedOnboardingTasks.length,
    }),
    [uncompletedOnboardingTasks]
  );
  // 期限切れタスク
  const expiredOnboardingTask = useMemo(() => {
    const expiredOnboardingTasks = uncompletedOnboardingTasks.filter((v) =>
      // プレビュー状態では期限切れタスクなし
      isPreview ? false : v.isExpiredByEmployee(currentUser)
    );

    return {
      exists: !isEmpty(expiredOnboardingTasks),
      count: expiredOnboardingTasks.length,
    };
  }, [uncompletedOnboardingTasks, currentUser, isPreview]);

  const { sortOnboardingTasks } = useSortOnboardingTasks();
  // リストに表示するコンテンツ
  const portalListContents = useMemo(
    () =>
      getContentsForPortalListItemGroup(
        sortOnboardingTasks(onboardingTasks),
        currentUser,
        isPreview
      ),
    [sortOnboardingTasks, onboardingTasks, currentUser, isPreview]
  );

  if (isLoadingOnboardingTasks) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  if (!onboardingTasks || isEmpty(onboardingTasks)) {
    return <NotFound />;
  }

  return (
    <>
      <EmojiConfettiForTask
        isAllTaskCompleted={!uncompletedOnboardingTask.exists}
        currentUserId={currentUser.id}
      />
      <Box px={3} py={5}>
        <Typography variant="h2" color="textPrimary">
          {/* TODO: 中途の方を文言変更(https://app.clickup.com/t/864emfdcf) */}
          タスク
        </Typography>
        {expiredOnboardingTask.exists && (
          <StyledBox display="flex" alignItems="center" mt="40px" p="16px 12px" bgcolor="white">
            <Typography variant="body2" bold color="secondary">
              ※ 期日が過ぎているタスクが
              {expiredOnboardingTask.count}
              つあります
            </Typography>
          </StyledBox>
        )}
        <Box display="flex" alignItems="center" mt="20px">
          <Box height="64px" mr="16px">
            <ProgressCircle
              percent={completionPercent}
              color={expiredOnboardingTask.exists ? "secondary" : "primary"}
            />
          </Box>
          <Box>
            <Typography variant="body2" color="textPrimary">
              {uncompletedOnboardingTask.exists
                ? "タスクをチェックして、完了させましょう！" // TODO: 中途の方を文言変更(https://app.clickup.com/t/864emfdcf)
                : "すべてのタスクが完了しました！🎉\nありがとうございます！"}
            </Typography>
            <StyledTypography variant="caption">
              {uncompletedOnboardingTask.exists
                ? `残り${uncompletedOnboardingTask.count}個`
                : "完了"}
            </StyledTypography>
          </Box>
        </Box>
        <Divider margin={16} orientation="horizontal" />
        <Box height="24px" />
        <PortalListItemGroup contents={portalListContents} />
      </Box>
    </>
  );
};

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
  margin-top: 8px;
`;

const StyledBox = styled(Box)`
  border: ${(props) => props.theme.palette.secondary.main} 1px solid;
  border-radius: 8px;
`;
