import { Box } from "@material-ui/core";
import { OnboardingExperienceTaskType } from "@onn/common";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Typography, Icon, IconButton } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  title: string;
  editable: boolean;
  onClickEditButton: () => void;
  onClickDeleteButton: () => void;
  timing: string;
  taskType: OnboardingExperienceTaskType["type"];
};

const iconMap: Record<OnboardingExperienceTaskType["type"], ComponentProps<typeof Icon>["icon"]> = {
  MESSAGE_TASK: "speechBalloon",
  SIMPLE_TASK: "line",
  GENERAL_TASK: "list",
};

export const ProcessTaskItem: FC<Props> = React.memo(
  ({ title, editable, onClickEditButton, onClickDeleteButton, timing, taskType }) => {
    return (
      <StyledBox
        py="8px"
        pl="8px"
        pr="16px"
        display="flex"
        alignItems="center"
        gridGap="8px"
        onClick={onClickEditButton}
      >
        {editable && (
          <Box id="gripVerticalWrapper" height="16px" minWidth="16px">
            <Icon icon="gripVertical" size="sm" color="lightGrey" />
          </Box>
        )}
        <StyledIcon size="md" icon={iconMap[taskType]} color="lightGrey" />
        <Box paddingLeft="8px" mr="auto" overflow="hidden">
          <StyledTypography variant="body1" noWrap>
            {title}
          </StyledTypography>
          <StyledTypography variant="caption" color="textSecondary" noWrap disablePreWrap>
            期日 : {timing}
          </StyledTypography>
        </Box>
        <IconButton icon={editable ? "navi" : "eye"} color="primary" size="md" />
        {editable && (
          <IconButton
            icon="close"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickDeleteButton();
            }}
          />
        )}
      </StyledBox>
    );
  }
);

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-right: auto;
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  border-radius: 8px;
  .MuiButtonBase-root {
    padding: 4px;
    display: none;
  }
  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover {
    background-color: ${(props) =>
      `rgba(${mixin.generateRgbaFromColorCode(props.theme.palette.grey[300])}, 0.1)`};
    .MuiButtonBase-root,
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;

const StyledIcon = styled(Icon)`
  align-self: flex-start;
`;
