import { Box } from "@material-ui/core";
import {
  Employee,
  SurveyTransaction,
  Transaction,
  MemoTransaction,
  OnboardingMessageTask,
  MessageContent,
} from "@onn/common";
import { isAfter } from "date-fns";
import React, { FC, ComponentProps, useMemo } from "react";
import styled from "styled-components";

import { ActivityListItemMemoForm, ActivityListItemMemoFormProps } from "./ActiviListItemMemoForm";
import { ActivityContentForMessage } from "./ActivityContentForMessage";
import { ActivityContentForMessageContent } from "./ActivityContentForMessageContent";
import { ActivityListItem } from "./ActivityListItem";

import { CommentList } from "~/components/domains/comments";
import { Loading, Typography, NotFoundPaper } from "~/components/uiParts";
import { useOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask";
import { useFilterTasksToDelivered } from "~/hooks/onboardingTask/messageTask";

type CommentListProps = ComponentProps<typeof CommentList>;
type ActivityListItemProps = ComponentProps<typeof ActivityListItem>;

type SuggestedMentionUsers = ActivityListItemProps["suggestedMentionUsers"];
type Comments = ActivityListItemProps["comments"];
type Reactions = ActivityListItemProps["reactions"];
type TransactionCommentsMap = { [transactionId: string]: Comments };
type TransactionReactionsMap = { [transactionId: string]: Reactions };

const returnTimeForSort = (t: Transaction | SurveyTransaction | MemoTransaction) => {
  // SURVEYはansweredAtでソートする
  if (t instanceof SurveyTransaction) return t.answeredAt;
  // ADD_MEMOはcreatedAtでソートする
  if (t instanceof MemoTransaction) return t.createdAt;

  return new Date(t.sendAt.toString());
};

type Props = {
  transactions: Array<Transaction | SurveyTransaction | MemoTransaction> | undefined;
  isLoadingTransactions: boolean;
  contentId: string | null;
  employee: Employee;
  suggestedMentionUsers: SuggestedMentionUsers;
  transactionCommentsLoading: boolean;
  currentUserId: string;
  memoAttachedFilesMap: ActivityListItemProps["memoAttachedFilesMap"];
  memoAttachedFilesLoading: boolean;
  transactionCommentsMap: TransactionCommentsMap;
  commentFocused: boolean;
  reactionModalOpened: boolean;
  transactionReactionsMap: TransactionReactionsMap;
  isLoadingTransactionReactionsMap: boolean;
  onSubmitMemo?: ActivityListItemMemoFormProps["onSubmitMemo"];
  onDropFilesError?: ActivityListItemMemoFormProps["onDropFilesError"];
  onDownloadMemoAttachedFile: ActivityListItemProps["onDownloadMemoAttachedFile"];
  onDeleteMemoAttachedFile: ActivityListItemProps["onDeleteMemoAttachedFile"];
} & Pick<CommentListProps, "loadTransactionComments">;

export const ActivityTab: FC<Props> = ({
  transactions,
  isLoadingTransactions,
  contentId,
  employee,
  suggestedMentionUsers,
  transactionCommentsLoading,
  currentUserId,
  transactionCommentsMap,
  commentFocused,
  reactionModalOpened,
  memoAttachedFilesMap,
  memoAttachedFilesLoading,
  transactionReactionsMap,
  isLoadingTransactionReactionsMap,
  onSubmitMemo,
  onDropFilesError,
  loadTransactionComments,
  onDownloadMemoAttachedFile,
  onDeleteMemoAttachedFile,
}) => {
  const { data: onboardingTasks = [], isValidating: isValidatingOnboardingTasks } =
    useOnboardingTasksByEmployeeId(employee.id);

  const { filterTasksToDelivered } = useFilterTasksToDelivered();

  // メッセージタスクはアクティビティに表示する
  const messageTasks = useMemo(() => {
    return filterTasksToDelivered(onboardingTasks, employee);
  }, [employee, filterTasksToDelivered, onboardingTasks]);

  const surveyTransactions = useMemo(() => {
    return (
      transactions?.filter((v): v is SurveyTransaction => v instanceof SurveyTransaction) ?? []
    );
  }, [transactions]);

  const sortedTransactionsAndMessageTasks = useMemo(() => {
    const getDate = (
      transactionOrMessageTask:
        | NonNullable<typeof transactions>[number]
        | (typeof messageTasks)[number]
        | (typeof messageTasks)[number]["contents"][number]
    ) => {
      if (transactionOrMessageTask instanceof OnboardingMessageTask) {
        return transactionOrMessageTask.dueDate.calculateDateByEmployee(employee);
      }
      if (transactionOrMessageTask instanceof MessageContent) {
        return transactionOrMessageTask.updatedAt;
      }

      return returnTimeForSort(transactionOrMessageTask);
    };
    return [
      ...(transactions ?? []),
      ...messageTasks,
      ...messageTasks.flatMap((v) => v.contents.filter((v) => v.isCompleted)),
    ].sort((a, b) => {
      const dateA = getDate(a);
      const dateB = getDate(b);

      if (!dateA) return -1;
      if (!dateB) return 1;

      return isAfter(dateA, dateB) ? -1 : 1;
    });
  }, [messageTasks, transactions, employee]);

  return (
    <Box pt={5} pb={5}>
      {transactions === undefined && (isLoadingTransactions || isValidatingOnboardingTasks) ? (
        <Loading size="small" />
      ) : (
        <>
          {sortedTransactionsAndMessageTasks.length === 0 ? (
            <NotFoundPaper text="アクティビティはまだ登録されていません。" />
          ) : (
            <StyledList>
              <Box pb={3}>
                <Typography variant="body1">最新アクティビティ</Typography>
              </Box>
              {onSubmitMemo && onDropFilesError && (
                <ActivityListItemMemoForm
                  onSubmitMemo={onSubmitMemo}
                  onDropFilesError={onDropFilesError}
                />
              )}
              {sortedTransactionsAndMessageTasks.map((sortedTransactionsAndMessageTask) => {
                if (sortedTransactionsAndMessageTask instanceof OnboardingMessageTask) {
                  return (
                    <ActivityContentForMessage
                      key={sortedTransactionsAndMessageTask.id}
                      onboardingMessageTask={sortedTransactionsAndMessageTask}
                      newHire={employee}
                      isScrollTarget={contentId === sortedTransactionsAndMessageTask.id}
                      isActive={sortedTransactionsAndMessageTask.id === contentId}
                    />
                  );
                }
                if (sortedTransactionsAndMessageTask instanceof MessageContent) {
                  return (
                    <ActivityContentForMessageContent
                      key={sortedTransactionsAndMessageTask.id}
                      messageTask={messageTasks.find(
                        (v) =>
                          !!v.contents.find((c) => c.id === sortedTransactionsAndMessageTask.id)
                      )}
                      messageContent={sortedTransactionsAndMessageTask}
                      newHire={employee}
                      isScrollTarget={contentId === sortedTransactionsAndMessageTask.id}
                      isActive={sortedTransactionsAndMessageTask.id === contentId}
                    />
                  );
                }
                return (
                  <ActivityListItem
                    key={sortedTransactionsAndMessageTask.id}
                    surveyTransactions={surveyTransactions}
                    transaction={sortedTransactionsAndMessageTask}
                    isScrollTarget={contentId === sortedTransactionsAndMessageTask.id}
                    employee={employee}
                    currentUserId={currentUserId}
                    comments={transactionCommentsMap[sortedTransactionsAndMessageTask.id] ?? []}
                    commentDisabled={transactionCommentsLoading}
                    commentFocused={commentFocused}
                    reactionModalOpened={reactionModalOpened}
                    suggestedMentionUsers={suggestedMentionUsers}
                    reactions={transactionReactionsMap[sortedTransactionsAndMessageTask.id] || []}
                    isLoadingTransactionReactionsMap={isLoadingTransactionReactionsMap}
                    memoAttachedFilesMap={memoAttachedFilesMap}
                    memoAttachedFilesLoading={memoAttachedFilesLoading}
                    onDownloadMemoAttachedFile={onDownloadMemoAttachedFile}
                    onDeleteMemoAttachedFile={onDeleteMemoAttachedFile}
                    loadTransactionComments={loadTransactionComments}
                    isActive={sortedTransactionsAndMessageTask.id === contentId}
                  />
                );
              })}
            </StyledList>
          )}
        </>
      )}
    </Box>
  );
};

const StyledList = styled.ul`
  margin: 0px 0px 48px;
  padding: 0px;
`;
