import React, { FC } from "react";

import { Linkify, Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  description: string;
};

// ====================
// main
// ====================

export const DescriptionSection: FC<Props> = ({ description }) => {
  // ====================
  // main component
  // ====================

  return (
    <Linkify>
      <Typography variant="body1">{description}</Typography>
    </Linkify>
  );
};
