import { Employee } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useAllEmployees, useAllNewcomers } from "~/hooks/employee";
import { generateUseOnboardingTasksKeys } from "~/hooks/onboardingTask/useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask/useOnboardingTasksByEmployeeId";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

export const useAssignSupportMembers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();
  const { mutateAllEmployees } = useAllEmployees();

  const assignSupportMembers = useCallback(
    async ({
      emailsToInviteSupportMembers,
      employeeIds,
      newHire,
    }: {
      emailsToInviteSupportMembers: string[];
      employeeIds: string[];
      newHire: Employee;
    }) => {
      await EmployeeUseCase.inviteSupportMember(
        newHire.id,
        employeeIds,
        emailsToInviteSupportMembers
      )
        .then(() => {
          enqueueSnackbar("サポートメンバーに追加しました", { variant: "success" });
          mutate(["employee", newHire.id]);
          mutateAllNewcomers();
          mutateAllEmployees();
          mutate(generateUseOnboardingTasksByEmployeeId(newHire.id));
          mutate(generateUseOnboardingTasksKeys(newHire.tenantId));
        })
        .catch((e) => {
          enqueueSnackbar("サポートメンバーへの追加に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useAssignSupportMembers:assignSupportMembers" },
          });
          return;
        });
    },
    [enqueueSnackbar, mutate, mutateAllNewcomers, mutateAllEmployees]
  );

  return { assignSupportMembers };
};
