import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Month } from "@onn/common";
import { subMonths } from "date-fns";
import React, { useMemo, FC } from "react";
import styled from "styled-components";

import { ENPSRow } from "./parts/ENPSRow";

import { Icon, Paper, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  now: Date;
};

export const ENPSPaper: FC<Props> = ({ now }) => {
  const aggregateMonthsCount = 6;
  const periods = useMemo(
    () =>
      [...Array(aggregateMonthsCount).keys()].map((i) => {
        const date = subMonths(now, i);
        return { year: date.getFullYear(), month: (date.getMonth() + 1) as Month };
      }),
    [now]
  );

  return (
    <StyledPaper square>
      <Box mt="36px" display="flex" gridGap="12px" alignItems="center" justifyContent="center">
        <Typography align="center" bold>
          eNPS
        </Typography>
        <Tooltip
          // eslint-disable-next-line no-irregular-whitespace
          title={`入社者が回答した振り返りアンケートから算出されます。\n表示されるアイコンと数字の関係は次の通りです。\n・晴れ：5以上\n・曇り：-5〜5\n・雨　：-5以下`}
        >
          <Icon icon="help" size="sm" color="grey" />
        </Tooltip>
      </Box>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell width={"35%"}></StyledTableCell>
            <StyledTableCell align="center" width={"30%"}>
              <Typography variant="body1">1ヶ月目</Typography>
            </StyledTableCell>
            <StyledTableCell width={"5%"}></StyledTableCell>
            <StyledTableCell align="center" width={"30%"}>
              <Typography variant="body1">3ヶ月目</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periods.map((period, index) => (
            <ENPSRow period={period} index={index} key={index} />
          ))}
        </TableBody>
      </StyledTable>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Table)`
  &.MuiTable-root {
    min-width: auto;
  }
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 16px 3px;
    border: none;
  }
`;
