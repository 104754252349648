import { Box } from "@material-ui/core";
import { ItemType, Question, AnswerItem } from "@onn/common";
import { isArray } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  ButtonRadioGroup,
  CheckboxGroup,
  RatingRadioGroup,
  TextareaAutosize,
  NPSForm,
} from "~/components/uiParts";

type Props = {
  question: Question;
  changeAnswer: (event: React.ChangeEvent<HTMLElement>) => void;
  isRead?: boolean;
  answerItem?: AnswerItem;
  caption?: string | string[];
};

/**
 * 評価のサイズを取得する
 *
 * @param question
 */
const rantingSize = (question: Question) => {
  switch (question.type) {
    case ItemType.SMALL_RATING:
      return 3;
    case ItemType.MEDIUM_RATING:
      return 5;
    // ItemType.LARGE
    default:
      return 10;
  }
};

export const FormType: FC<Props> = ({ question, changeAnswer, isRead, answerItem, caption }) => {
  const { t } = useTranslation(["survey"]);

  const questionItems = question && question.questionItems;
  switch (question.type) {
    case ItemType.TITLE:
      return <Box />;
    case ItemType.SMALL_RATING:
    case ItemType.MEDIUM_RATING:
    case ItemType.LARGE_RATING:
      if (isRead) {
        return <Box>{`${answerItem ? answerItem.value : ""}`}</Box>;
      }

      return (
        <RatingRadioGroup
          value={Number(answerItem ? answerItem.value : 0)}
          hasLabel
          radioLength={rantingSize(question)}
          name={question.name}
          onChangeRating={changeAnswer}
          captions={isArray(caption) ? caption : [caption as string]}
        />
      );
    case ItemType.CUSTOM_RADIO:
    case ItemType.TITLE_CUSTOM_RADIO:
    case ItemType.TITLE_ICON_CUSTOM_RADIO: {
      // questionItems = 5のCUSTOM_RADIOの場合、Ratingコンポーネントを表示します
      //  それ以外の場合は、ボタンラジオグループコンポーネントが表示されます
      const captionsRadio = isArray(caption) ? [...caption] : [];
      if (questionItems.length === 5 && captionsRadio.length === 0) {
        captionsRadio.push((questionItems[0] as (typeof questionItems)[number]).title); // 最初にキャプションを取得します
        captionsRadio.push((questionItems[2] as (typeof questionItems)[number]).title); // 真ん中にキャプションを取得
        captionsRadio.push((questionItems[4] as (typeof questionItems)[number]).title); // 最終的なキャプションを取得する
      }

      // questionItemsが5の場合は、5段階評価の設問なので、RatingRadioGroupを利用する
      // 6の場合は、単純に線形な評価でないため、ButtonRadioGroupを利用するが、これは2021年4月ごろには廃止して良い
      return questionItems.length === 5 ? (
        <RatingRadioGroup
          name={question.name}
          hasLabel
          disableEffect={isRead}
          radioLength={questionItems.length}
          // 旧データに対して、questionItem[x]のフォーマットで保存しましたので、ここで[x]部分しか取得しないように
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={parseInt(answerItem ? answerItem.value.toString().match(/(\d+)/)[0] : "0")}
          onChangeRating={changeAnswer}
          captions={captionsRadio}
        />
      ) : (
        <ButtonRadioGroup
          name={question.name}
          disableEffect={isRead}
          questionItems={questionItems}
          value={answerItem?.value.toString()}
          onClickButton={changeAnswer}
        />
      );
    }
    case ItemType.CHECK_BOX: {
      const values = Array.isArray(answerItem?.value) ? answerItem?.value : [];
      return (
        <CheckboxGroup
          questionItems={questionItems}
          disableEffect={isRead}
          name={question.name}
          values={values}
          valueOfOther={answerItem?.text}
          handleChange={changeAnswer}
        />
      );
    }
    case ItemType.TEXT:
    case ItemType.SERIAL_NUMBER_TEXT:
    case ItemType.TITLE_TEXT:
      return (
        <TextareaAutosize
          disabled={isRead}
          value={answerItem && answerItem.value.toString()}
          minRows={4}
          fullWidth
          name={question.name}
          onChange={changeAnswer}
          placeholder={
            question.placeholder ? question.placeholder.replace(`\\n`, `\n`) : t("placeholder")
          }
        />
      );
    case ItemType.NPS: {
      const npsCaption = caption as string[];

      return (
        <NPSForm
          name={question.name}
          value={answerItem && Number(answerItem.value)}
          onChange={changeAnswer}
          captionLeft={npsCaption[0] as string}
          captionRight={npsCaption[npsCaption.length - 1] as string}
        />
      );
    }
  }
};
