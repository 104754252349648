import { SlackUser } from "@onn/common";
import React, { createContext, useState, useEffect, ReactNode, FC, useCallback } from "react";

import { useTenant } from "~/hooks/tenant";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

type Props = {
  children: ReactNode;
};

type ContextType = {
  slackUsers: SlackUser[];
  loadingSlackUsers: boolean;
};

export const SlackUsersContext = createContext<ContextType>({
  slackUsers: [],
  loadingSlackUsers: false,
});

export const SlackUsersProvider: FC<Props> = ({ children }) => {
  const { tenant } = useTenant();
  const [slackUsers, setSlackUsers] = useState<SlackUser[]>([]);
  const [loadingSlackUsers, setLoadingSlackUsers] = useState(false);

  const fetchSlackUsers = useCallback(async () => {
    if (tenant?.isIntegratedSlack) {
      setLoadingSlackUsers(true);
      setSlackUsers(
        await EmployeeUseCase.getAllUserSlack().then((res) => {
          setLoadingSlackUsers(false);
          return res;
        })
      );
    }
  }, [tenant?.isIntegratedSlack]);

  useEffect(() => {
    fetchSlackUsers();
  }, [fetchSlackUsers]);

  return (
    <SlackUsersContext.Provider value={{ slackUsers, loadingSlackUsers }}>
      {children}
    </SlackUsersContext.Provider>
  );
};
