import { Box } from "@material-ui/core";
import { isAfter, startOfMonth, subMonths } from "date-fns";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { Loading, Paper, Typography } from "~/components/uiParts";
import { useAllNewcomers } from "~/hooks/employee";
import { useCalculateENPS, useSurveyTransactionsByEmployeeIds } from "~/hooks/transaction";

const now = new Date();

export const OnboardingRankPaper: FC = () => {
  const { data: allNewcomers = [], isValidating: isValidatingAllNewcomers } = useAllNewcomers();

  // 表示の対象になるのは入社月6ヶ月前まで
  // 例：表示した日が 10月12日の場合、入社日が5月1日~10月31日までの入社者が対象になる
  const targetNewcomerIds = useMemo(() => {
    return allNewcomers.flatMap((newcomer) => {
      if (!newcomer.joinAt) return [];
      return isAfter(new Date(newcomer.joinAt.toString()), subMonths(startOfMonth(now), 5))
        ? newcomer.id
        : [];
    });
  }, [allNewcomers]);

  const { data: surveyTransactions = [], isValidating: isValidatingSurveyTransactions } =
    useSurveyTransactionsByEmployeeIds(targetNewcomerIds);

  const { calculateENPS } = useCalculateENPS();

  // NOTE: https://www.notion.so/workside/0767c0a90a614958894ec05ac6c7e9c4#c7b94e01f4fa451ca31e9143dc13d7c3
  const displayRankText = useMemo(() => {
    // 1ヶ月目のに回答された振り返りアンケートのみが表示対象
    const targetSurveyTransactions = surveyTransactions.filter((v) => {
      const surveyTiming = v.contents.surveyTimings[0] as (typeof v.contents.surveyTimings)[number];
      return (
        v.contents.type === "ONBOARDING" &&
        v.status === "DONE" &&
        surveyTiming.unit === "WEEK" &&
        surveyTiming.value <= 4 &&
        surveyTiming.value >= 2
      );
    });
    const enps = calculateENPS(targetSurveyTransactions);

    if (enps >= 30) {
      return { rank: "S", percent: 10 };
    }
    if (enps >= 15) {
      return { rank: "A", percent: 20 };
    }
    if (enps >= 0) {
      return { rank: "B", percent: 50 };
    }
    if (enps >= -15) {
      return { rank: "C", percent: 80 };
    }

    return { rank: "D", percent: 100 };
  }, [calculateENPS, surveyTransactions]);

  return (
    <StyledPaper square>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Typography variant="h2" bold>
            オンボーディングランク
          </Typography>
          <Typography variant="body1">（入社直近6ヶ月の期間）</Typography>
        </Box>
        <Box>
          {isValidatingAllNewcomers || isValidatingSurveyTransactions ? (
            <Loading size="small" />
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography variant="h2" bold align="center">
                {displayRankText.rank}
              </Typography>
              <Typography variant="body1">（上位{displayRankText.percent}％）</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;
