import { Employee, SurveyTransaction } from "@onn/common";
import { useCallback } from "react";

export const useSortObjectForENPS = () => {
  type SurveyTransactionObject = {
    employee: Employee;
    surveyTransaction: SurveyTransaction;
  };
  /**
   * eNPS, 入社日順に並び替えをする関数
   * @param {{employee: Employee; surveyTransaction: SurveyTransaction;}} objects
   */
  const sortObjectForENPS = useCallback(
    (objects: SurveyTransactionObject[]): SurveyTransactionObject[] => {
      return objects.sort((a, b) => {
        const aScore = toNumber(a.surveyTransaction.eNPSScore());
        const bScore = toNumber(b.surveyTransaction.eNPSScore());
        if (aScore === bScore) {
          return compareJoinAt(a.employee, b.employee);
        } else {
          return bScore - aScore;
        }
      });
    },
    []
  );

  const toNumber = (v: number | undefined) =>
    typeof v !== "undefined" ? v : -Number.MAX_SAFE_INTEGER;

  const compareJoinAt = (employeeA: Employee, employeeB: Employee): number => {
    if (!employeeA.joinAt && !employeeB.joinAt) {
      return 0;
    }
    if (!employeeA.joinAt) {
      return 1;
    }
    if (!employeeB.joinAt) {
      return -1;
    }

    const joinDateA = new Date(employeeB.joinAt);
    const joinDateB = new Date(employeeA.joinAt);
    if (joinDateB < joinDateA) {
      return -1;
    } else {
      return 1;
    }
  };

  return { sortObjectForENPS };
};
