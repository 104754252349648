import { useMemo } from "react";

import { useAllNewcomers } from "~/hooks/employee";

function useCandidates(canUseNotRegistered: boolean) {
  const { data: allNewGraduates } = useAllNewcomers();

  const candidates = useMemo(() => {
    return allNewGraduates?.filter((newGraduate) => {
      if (!newGraduate.isNotifiable()) {
        return false;
      }
      const { recruitmentStatus } = newGraduate;
      if (!canUseNotRegistered && !newGraduate.isRegistered()) return false;

      return recruitmentStatus !== "rejected" && recruitmentStatus !== "withdrew";
    });
  }, [allNewGraduates, canUseNotRegistered]);

  return { candidates };
}

const csvHeader = ["姓", "名", "メールアドレス"] as const;

export function useCandidatesCsvData(canUseNotRegistered: boolean) {
  const { candidates } = useCandidates(canUseNotRegistered);

  const records = candidates?.map((candidate) => {
    return [candidate.lastName, candidate.firstName, candidate.email];
  });

  return {
    data: [csvHeader, ...(records ?? [])],
  };
}
