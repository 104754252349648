import React from "react";

export const Circle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        d="M16,4a12,12,0,1,0,8.485,3.515A11.921,11.921,0,0,0,16,4m0-4A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
        transform="translate(4 4)"
        fill="#27e0b7"
      />
    </svg>
  );
};
