import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Paper,
} from "@material-ui/core";
import {
  extractLatestStatus,
  convertStatusToString,
  Employee,
  SurveyTransaction,
  TransactionStatus,
} from "@onn/common";
import { format } from "date-fns";
import React, { FC, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { SurveyTransactionMenu } from "./components";

import {
  UserIconWithLabel,
  Icon,
  TableRow,
  Typography,
  Skeleton,
  Chip,
} from "~/components/uiParts";

const DATE_FORMAT = "yyyy/MM/dd";

type OrderType = "asc" | "desc";

type Props = {
  surveyTransactions: SurveyTransaction[];
  employees: Employee[];
  loading: boolean;
  onClickOpenRemindModal: (surveyId: string, employee: Employee) => void;
};

const SurveyTransactionSkeleton: FC = () => (
  <Skeleton
    variant="userListTable"
    columnOptions={[
      {
        variant: "rect",
        grid: 4,
      },
      {
        variant: "text",
        typographyVariant: "body1",
        grid: 1,
      },
      {
        variant: "text",
        typographyVariant: "caption",
        grid: 3,
      },
      {
        variant: "text",
        typographyVariant: "caption",
        grid: 1,
      },
      {
        variant: "text",
        typographyVariant: "caption",
        grid: 1,
      },
      {
        variant: "text",
        typographyVariant: "caption",
        grid: 1,
      },
      {
        variant: "none",
        typographyVariant: "caption",
        grid: 1,
      },
    ]}
  />
);

export const SurveyTransactionTable: FC<Props> = ({
  surveyTransactions,
  employees,
  loading,
  onClickOpenRemindModal,
}) => {
  const [answeredAtOrder, setAnsweredAtOrder] = useState<OrderType>("desc");
  const [scoreOrder, setScoreOrder] = useState<OrderType | undefined>();
  const [transactions, setTransactions] = useState(surveyTransactions);

  useEffect(() => {
    const sortTransactionsByAnsweredAt = () => {
      const transactionsWithoutAnsweredAt: SurveyTransaction[] = [];
      // 回答の可否で配列を分ける
      const transactionsWithAnsweredAt: SurveyTransaction[] = surveyTransactions
        .filter((surveyTransaction) => {
          if (surveyTransaction.answeredAt) return true;
          transactionsWithoutAnsweredAt.push(surveyTransaction);
          return false;
        })
        .map((t) => t as AugmentedRequired<SurveyTransaction, "answeredAt">)
        .sort((a, b) => {
          if (answeredAtOrder === "desc") return a.answeredAt > b.answeredAt ? -1 : 1;
          if (answeredAtOrder === "asc") return a.answeredAt > b.answeredAt ? 1 : -1;
          return 0;
        });

      // 降順の場合、未回答の transaction を後に表示する
      const rebuildTransactions: SurveyTransaction[] =
        answeredAtOrder === "desc"
          ? [...transactionsWithAnsweredAt, ...transactionsWithoutAnsweredAt]
          : [...transactionsWithoutAnsweredAt, ...transactionsWithAnsweredAt];

      setTransactions(rebuildTransactions);
    };
    sortTransactionsByAnsweredAt();
  }, [answeredAtOrder, surveyTransactions]);

  const handleSwitchScoreSort = useCallback(
    (
      hasScoreTransactions: SurveyTransaction[],
      noScoreTransactions: SurveyTransaction[],
      order: OrderType
    ): OrderType => {
      hasScoreTransactions.sort((a, b) => {
        const scoreA = a.resultContents.find((content) => content.key === "question1")?.value || "";
        const scoreB = b.resultContents.find((content) => content.key === "question1")?.value || "";

        return order === "asc" ? Number(scoreA) - Number(scoreB) : Number(scoreB) - Number(scoreA);
      });

      setTransactions([...hasScoreTransactions, ...noScoreTransactions]);
      return order;
    },
    []
  );

  const handleClickSortScore = useCallback(() => {
    // score の有無で配列を分ける
    const hasScoreTransactions: SurveyTransaction[] = [];
    const noScoreTransactions: SurveyTransaction[] = [];
    transactions.forEach((transaction) => {
      if (
        transaction.contents.title.includes("オンボーディングアンケート") &&
        transaction.status === "DONE"
      ) {
        hasScoreTransactions.push(transaction);
      } else {
        noScoreTransactions.push(transaction);
      }
    });

    setScoreOrder((prevState) => {
      if (prevState === "desc") {
        return handleSwitchScoreSort(hasScoreTransactions, noScoreTransactions, "asc");
      }

      return handleSwitchScoreSort(hasScoreTransactions, noScoreTransactions, "desc");
    });
  }, [transactions, handleSwitchScoreSort]);

  const handleClickSortAnsweredAtDate = useCallback(() => {
    // answeredAtOrderをtoggleする
    setAnsweredAtOrder((prevState) => (prevState === "desc" ? "asc" : "desc"));
  }, []);

  // 未回答 or 1ヶ月アンケート の場合 => "-" を表示
  const getDisplayScore = (surveyTransaction: SurveyTransaction) => {
    if (
      surveyTransaction.contents.title.includes("オンボーディングアンケート") &&
      surveyTransaction.status === "DONE"
    ) {
      const targetAnswer = surveyTransaction.resultContents.find(
        (content) => content.key === "question1"
      );

      return targetAnswer?.value || "-";
    } else {
      return "-";
    }
  };

  if (!loading && !transactions.length) return null;

  return (
    <TableContainer>
      <Paper square>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledHeaderGrid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="caption" color="textPrimary">
                      回答者
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <StyledIconContainer onClick={() => handleClickSortScore()}>
                      <Typography variant="caption" color="textPrimary">
                        スコア
                      </Typography>
                      <StyledIcon
                        icon={scoreOrder === "asc" ? "arrowDropUp" : "arrowDropDown"}
                        color="grey"
                        size={"sm"}
                      />
                    </StyledIconContainer>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption" color="textPrimary">
                      タイプ
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="caption" color="textPrimary">
                      配信日
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <StyledIconContainer onClick={() => handleClickSortAnsweredAtDate()}>
                      <Typography variant="caption" color="textPrimary">
                        回答日
                      </Typography>
                      <StyledIcon
                        icon={answeredAtOrder === "asc" ? "arrowDropUp" : "arrowDropDown"}
                        color="grey"
                        size={"sm"}
                      />
                    </StyledIconContainer>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="caption" color="textPrimary">
                      回答ステータス
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </StyledHeaderGrid>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? [...Array(10)].map((_, i) => (
                  <TableRow key={i}>
                    <StyledTableCell>
                      <Box py={4} borderTop={1} borderColor="grey.100">
                        <SurveyTransactionSkeleton />
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))
              : transactions.map((survey) => {
                  const employee = employees.find((employee) => employee.id === survey.employeeId);
                  if (!employee) return null;

                  return (
                    <TableRow key={survey.id} hover>
                      <StyledTableCell>
                        <StyledLink to={`/employee/${employee.id}/?answerId=${survey.id}`}>
                          <StyledGrid container alignItems="center">
                            <StyledCell item xs={4}>
                              <UserIconWithLabel
                                iconPath={employee.profileIconImageUrl || ""}
                                name={employee.getName()}
                                secondaryText={convertStatusToString(
                                  extractLatestStatus(employee.onboardingStatuses || []),
                                  employee.joinAt
                                )}
                                iconBadgeType={employee.slackUserId ? "slack" : undefined}
                              />
                            </StyledCell>
                            <StyledCell item xs={1}>
                              <Typography variant="body1" bold color="textPrimary">
                                {getDisplayScore(survey)}
                              </Typography>
                            </StyledCell>
                            <StyledCell item xs={3}>
                              <Typography variant="caption" color="textPrimary">
                                {survey.contents.title}
                              </Typography>
                            </StyledCell>
                            <StyledCell item xs={1}>
                              <Typography variant="caption" color="textPrimary">
                                {format(survey.sendAt, "yyyy/MM/dd")}
                              </Typography>
                            </StyledCell>
                            <StyledCell item xs={1}>
                              <Typography variant="caption" color="textPrimary">
                                {survey.answeredAt ? format(survey.answeredAt, DATE_FORMAT) : "-"}
                              </Typography>
                            </StyledCell>
                            <StyledCell item xs={1}>
                              <Chip
                                color={
                                  survey.status === TransactionStatus.SENT ? "grey" : "primary"
                                }
                                label={
                                  survey.status === TransactionStatus.SENT ? "未回答" : "回答済"
                                }
                                bold
                              />
                            </StyledCell>
                            <Grid item xs={1}>
                              {survey.status === TransactionStatus.SENT && (
                                <SurveyTransactionMenu
                                  onClickOpenRemindModal={() =>
                                    onClickOpenRemindModal(survey.id, employee)
                                  }
                                />
                              )}
                            </Grid>
                          </StyledGrid>
                        </StyledLink>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </Paper>
    </TableContainer>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0 40px;
  }
`;

const StyledIconContainer = styled.div`
  display: inline-flex;
  align-items: end;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
`;

const StyledHeaderGrid = styled(Grid)`
  &.MuiGrid-root {
    padding: 16px 0;
  }
`;

const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    border-top: 1px solid ${(props) => props.theme.palette.grey[100]};
    padding: 24px 0;
  }
`;

const StyledCell = styled(Grid)`
  &.MuiGrid-root {
    padding-right: 16px;
  }
`;
