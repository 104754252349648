// eslint-disable-next-line import/no-cycle
import { Survey } from "@onn/common";

import { TemplateRepository } from "../../infrastructure/api/templateRepository";

export interface ITemplateRepository {
  findSurveys(tenantId: string): Promise<Survey[]>;
  findSurvey(tenantId: string, surveyId: string): Promise<Survey>;
  updateSurvey(survey: Survey): Promise<void>;
}

export const factory = {
  templateRepository: (): ITemplateRepository => {
    return new TemplateRepository();
  },
};
