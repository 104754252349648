import {
  Transaction,
  SurveyTransaction,
  TransactionDataType,
  MemoTransaction,
  Memo,
  TransactionStatus,
  AnswerItem,
} from "@onn/common";

// eslint-disable-next-line import/no-cycle
import {
  TransactionRepository,
  SurveyTransactionRepository,
  MemoTransactionRepository,
} from "../../infrastructure/api/transactionRepository";

export interface ITransactionRepository {
  findById(id: string): Promise<Transaction>;
  whereByDataTypes({
    employeeId,
    dataTypes,
  }: {
    employeeId: string;
    dataTypes: (keyof typeof TransactionDataType)[];
  }): Promise<Array<Transaction | SurveyTransaction | MemoTransaction>>;
  updateJoinDateType(tenantId: string, employeeId: string): Promise<void>;
  updateProfileType(tenantId: string, employeeId: string): Promise<void>;
}

export interface ISurveyTransactionRepository {
  find(id: string): Promise<SurveyTransaction>;
  updateAnswer(transactionId: string, answerItems: AnswerItem[]): Promise<void>;
  whereByEmployeeIds(employeeIds: string[]): Promise<SurveyTransaction[]>;
  whereBySendAt(sendAt: string, tenantId: string): Promise<SurveyTransaction[]>;
  whereByStatusAndTenantIdAndSortedTimestamp(
    status: TransactionStatus,
    tenantId: string,
    orderBy: "sendAt" | "answeredAt"
  ): Promise<SurveyTransaction[]>;
  whereByEmployeeId(employeeId: string): Promise<SurveyTransaction[]>;
}

export interface IMemoTransactionRepository {
  findMemo(id: string): Promise<MemoTransaction>;
  createMemo({
    tenantId,
    employeeId,
    title,
    text,
    createdEmployeeId,
  }: {
    tenantId: string;
    employeeId: string;
    title: string;
    text: string;
    createdEmployeeId: string;
  }): Promise<{ memoId: string }>;
  editMemo(id: string, memoContent: Memo): Promise<void>;
  deleteMemo(memoId: string): Promise<void>;
}

export const factory = {
  transactionRepository: (): ITransactionRepository => {
    return new TransactionRepository();
  },
  surveyTransactionRepository: (): ISurveyTransactionRepository => {
    return new SurveyTransactionRepository();
  },
  memoTransactionRepository: (): IMemoTransactionRepository => {
    return new MemoTransactionRepository();
  },
};
