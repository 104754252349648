import { removeUndefinedFromObject, SurveyAlert } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  Timestamp,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { ISurveyAlertRepository } from "~/service/repository/iSurveyAlertRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type SurveyAlertForDB = Omit<
  ConstructorParameters<typeof SurveyAlert>[0],
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "surveyAlerts";

export class SurveyAlertRepository implements ISurveyAlertRepository {
  async create(surveyAlert: SurveyAlert): Promise<void> {
    const ref = this.doc(surveyAlert.id);
    await setDoc(ref, this.convertToPlainObject(surveyAlert));
  }

  async update(newSurveyAlert: SurveyAlert): Promise<void> {
    const ref = this.doc(newSurveyAlert.id);
    await updateDoc(ref, this.convertToPlainObject(newSurveyAlert));
  }

  async whereByIsCompleted(tenantId: string, isCompleted: boolean): Promise<SurveyAlert[]> {
    return await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("isCompleted", "==", isCompleted)
      )
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => {
        return this.convertToSurveyAlert(doc.data());
      });
    });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private convertToPlainObject(surveyAlert: SurveyAlert): SurveyAlertForDB {
    return removeUndefinedFromObject({
      ...surveyAlert,
      createdAt: convertDateToTimestamp(surveyAlert.createdAt),
      updatedAt: convertDateToTimestamp(surveyAlert.updatedAt),
    });
  }

  private convertToSurveyAlert = (data: DocumentData): SurveyAlert =>
    new SurveyAlert({
      id: data.id,
      tenantId: data.tenantId,
      transactionId: data.transactionId,
      isCompleted: data.isCompleted,
      operatedEmployeeId: data.operatedEmployeeId,
      createdAt: convertTimestampToDate(data.createdAt),
      updatedAt: convertTimestampToDate(data.updatedAt),
    });
}
