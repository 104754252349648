import { AppBar, Box, Toolbar } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { useFileUrl } from "~/hooks/file";
import logo from "~/images/logo-sp.svg";

type Props = {
  currentUser?: Employee;
  onClickLogo?: () => void;
  isTenantLogoUsed?: boolean;
  drawerMenu?: JSX.Element;
};

export const Header: FC<Props> = ({
  currentUser,
  onClickLogo,
  isTenantLogoUsed = true,
  drawerMenu,
}) => {
  const { data: logoUrl, isValidating: isValidatingLogoUrl } = useFileUrl(
    currentUser && isTenantLogoUsed ? `public/uploads/logo/${currentUser.tenantId}` : undefined
  );

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        {!isValidatingLogoUrl && (
          <StyledImage src={logoUrl || logo} alt="ロゴ" onClick={onClickLogo} />
        )}
        <Box flexGrow={1} />
        {!!drawerMenu && drawerMenu}
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    height: ${(props) => props.theme.constants.HEADER_HEIGHT}px;
    filter: drop-shadow(0 0 10px #0000001a);
    padding: 0 16px 0;

    color: ${(props) => props.theme.palette.text.secondary};
    background-color: white;
  }
`;

const StyledImage = styled.img`
  max-height: 40px;
  max-width: calc(100% - 48px);
  ${(props) => props.onClick && `cursor: pointer;`}
`;

const StyledToolbar = styled(Toolbar)`
  &.MuiToolbar-root {
    padding-left: 0;
  }
`;
