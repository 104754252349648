import { Employee, Department } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import { useDepartmentsByTenantId } from "~/hooks/department";

/**
 * 部署のidをもとにEmployeeをフィルターするhooks
 */
export const useFilterEmployeesByDepartments = (tenantId: string) => {
  const { data: departments = [] } = useDepartmentsByTenantId(tenantId);

  /**
   * 部署のidをもとに入社者をフィルターする
   * @param {string[]} selectedDepartmentIds
   * @param {boolean} isSelectedNoDepartment 所属なしも含むかどうか
   * @param {<T extends Employee>[]} allNewcomers
   */
  const filterEmployeesByDepartments = useCallback(
    <T extends Employee>(
      selectedDepartmentIds: string[],
      isSelectedNoDepartment: boolean,
      allNewcomers: T[]
    ) => {
      // 何も選択されていないときはそのまま返す
      if (isEmpty(selectedDepartmentIds) && !isSelectedNoDepartment) return allNewcomers;

      const filteringDepartmentIds = Department.getChildIds(selectedDepartmentIds, departments);

      return allNewcomers.filter((newcomer) => {
        if (isSelectedNoDepartment && isEmpty(newcomer.departmentIds)) {
          return true;
        } else {
          return filteringDepartmentIds.some((departmentId) => {
            return newcomer.departmentIds.includes(departmentId);
          });
        }
      });
    },
    [departments]
  );

  return { filterEmployeesByDepartments };
};
