import { Employee, OnboardingExperience } from "@onn/common";

import { OnboardingExperienceRepository } from "~/infrastructure/api/onboardingExperienceRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";

const onboardingExperienceRepository = new OnboardingExperienceRepository();

export class UpdateOrderOfOnboardingExperienceUseCase {
  async execute({
    currentUser,
    onboardingExperiences,
  }: {
    currentUser: Employee;
    onboardingExperiences: OnboardingExperience[];
  }): Promise<void> {
    // indexを更新できるのは管理者のみ
    if (!currentUser.isAdmin()) {
      throw new Error("エクスペリエンスを更新する権限がありません");
    }

    const batch = queryOperator.batch();

    onboardingExperiences.forEach((experience, index) => {
      onboardingExperienceRepository.insertUpdateExperienceInBatch(batch, {
        id: experience.id,
        index,
      });
    });

    await queryOperator.commit(batch);
  }
}
