import { Employee, SlackUser } from "@onn/common";
import React, { FC, useEffect, useMemo, useState } from "react";

import { EmployeeList, EmployeeSearchForm } from "./parts";

import { SlackUserList, SlackUserSearchForm } from "~/components/domains/slackUsers";
import { useAllEmployees } from "~/hooks/employee";
import { useSlackUsers } from "~/hooks/slackUser";
import { useTenant } from "~/hooks/tenant";

type Props = {
  notAllowEmails: string[];
  selectedEmail?: string;
  onSelectEmail: (email: string) => void;
};

export const SelectSingleEmployeesFormByPlatform: FC<Props> = ({
  notAllowEmails,
  selectedEmail,
  onSelectEmail,
}) => {
  const { slackUsers, loadingSlackUsers } = useSlackUsers();
  const { tenant } = useTenant();
  const { allEmployees } = useAllEmployees();

  const employeesExceptNewGraduates = useMemo(
    () => allEmployees.filter((v) => !v.isNewGraduate),
    [allEmployees]
  );

  const employeesForSearch = useMemo(() => {
    return (tenant.isActiveNewGraduate ? employeesExceptNewGraduates : allEmployees) ?? [];
  }, [employeesExceptNewGraduates, allEmployees, tenant.isActiveNewGraduate]);

  /**
   * クライアント検索処理で再構成される表示用の配列
   */
  const [displaySlackUsers, setDisplaySlackUsers] = useState<SlackUser[]>([]);

  /**
   * クライアント検索処理で再構成される表示用の配列
   * 新卒向けテナントの場合はMEMBER権限のEmployee(入社者を含まない)、中途向けテナントの場合は全てのEmployee(入社者を含む)を対象とする
   */
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(
    (tenant.isActiveNewGraduate ? employeesExceptNewGraduates : allEmployees) ?? []
  );

  useEffect(() => {
    if (!tenant.isActiveNewGraduate)
      setDisplaySlackUsers(slackUsers.filter((v) => !notAllowEmails.includes(v.email)));
  }, [notAllowEmails, slackUsers, tenant.isActiveNewGraduate]);

  useEffect(() => {
    const employees = tenant.isActiveNewGraduate ? employeesExceptNewGraduates : allEmployees;
    setDisplayEmployees(employees ?? []);
  }, [employeesExceptNewGraduates, allEmployees, tenant.isActiveNewGraduate]);

  return (
    <>
      {tenant.isIntegratedSlack ? (
        <>
          <SlackUserSearchForm
            variant="standard"
            showPlaceholder
            slackUsers={slackUsers.filter((v) => !notAllowEmails.includes(v.email))}
            onSearch={(results) => setDisplaySlackUsers(results)}
          />
          <SlackUserList
            slackUsers={displaySlackUsers}
            selectedSlackUser={slackUsers.find((v) => selectedEmail === v.email)}
            onSelect={(slackUser) => onSelectEmail(slackUser.email)}
            isLoading={loadingSlackUsers}
            isMultiple={false}
          />
        </>
      ) : (
        <>
          <EmployeeSearchForm
            showPlaceholder
            employees={employeesForSearch}
            onSearch={(results) => setDisplayEmployees(results)}
          />
          <EmployeeList
            employees={displayEmployees.filter((v) => !notAllowEmails.includes(v.email))}
            selectedEmployee={employeesForSearch.find((v) => selectedEmail === v.email)}
            onSelect={(employee) => onSelectEmail(employee.email)}
            isMultiple={false}
          />
        </>
      )}
    </>
  );
};
