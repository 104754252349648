import { APISchema, SurveyTransaction, TransactionStatus } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const useSurveyTransactions = ({
  transactionStatus,
  sendAt,
  tenantId,
}: {
  transactionStatus?: TransactionStatus;
  sendAt?: string;
  tenantId: string;
}) => {
  const keyObj = {
    name: `get_surveys-${tenantId}`,
    transactionStatus,
    sendAt,
    tenantId,
  };

  return useSWR<SurveyTransaction[]>(keyObj, async () => {
    const queryParam: APISchema["/get_surveys"]["GET"]["queryParams"] = {
      transactionStatus,
      sendAt,
    };
    const response = await apiClient.get(`/get_surveys`, queryParam);
    return response.data.map((d) => SurveyTransaction.plainToInstance(d));
  });
};
