import { Box } from "@material-ui/core";
import { MessageContent } from "@onn/common";
import React, { FC } from "react";

import { Typography, UserIcon } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  inProgressAssignees: MessageContent[];

  onClickUserIcon?: () => void;
};

// ====================
// main
// ====================

export const InProgressAssigneeSection: FC<Props> = ({ inProgressAssignees, onClickUserIcon }) => {
  // ====================
  // jsx
  // ====================

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="caption" bold color="textPrimary">
        記入依頼中({inProgressAssignees.length})
      </Typography>
      <Box display="flex" gridGap="4px" flexWrap="wrap">
        {inProgressAssignees.map((assignee) => (
          <UserIcon
            key={assignee.id}
            username={assignee.name}
            profileIconImageUrl={assignee.iconPath}
            size="small"
            circular
            hover
            onClick={onClickUserIcon}
          />
        ))}
      </Box>
    </Box>
  );
};
