import { OnboardingExperience } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "../shared/useSnackbar";

import { useCurrentUser } from "~/hooks/employee";
import { UpdateOrderOfOnboardingExperienceUseCase } from "~/service/usecases/onboardingExperience";

const updateOrderOfOnboardingExperienceUseCase = new UpdateOrderOfOnboardingExperienceUseCase();

/**
 * onboardingExperienceの順番を更新する関数を提供する
 */
export const useUpdateOrderOfOnboardingExperience = (): {
  isLoading: boolean;
  updateOrderOfOnboardingExperience: (
    onboardingExperiences: OnboardingExperience[]
  ) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  /**
   * onboardingExperienceの順番を更新する関数
   * @param {string} onboardingExperiences 更新するonboardingExperiences
   */
  const updateOrderOfOnboardingExperience = useCallback(
    async (onboardingExperiences: OnboardingExperience[]) => {
      setIsLoading(true);
      await updateOrderOfOnboardingExperienceUseCase
        .execute({
          currentUser,
          onboardingExperiences,
        })
        .then(() => {
          enqueueSnackbar("エクスペリエンスの順番を更新しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw new Error(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar]
  );

  return { isLoading, updateOrderOfOnboardingExperience };
};
