import { SlackUser } from "@onn/common";
import { useContext } from "react";

import { SlackUsersContext } from "~/components/providers";

export const useSlackUsers = (): { slackUsers: SlackUser[]; loadingSlackUsers: boolean } => {
  const { slackUsers, loadingSlackUsers } = useContext(SlackUsersContext);

  if (slackUsers === undefined) {
    throw new Error("Slackからの情報取得に失敗しました");
  }

  return { slackUsers, loadingSlackUsers };
};
