import { Box } from "@material-ui/core";
import { SurveyTransaction, ChartItem, Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, ComponentProps, FC, useEffect } from "react";

import { CommentList } from "~/components/domains/comments";
import { AccordionAnswerDetails } from "~/components/domains/transactions";
import { SplineChart, NotFoundPaper, Loading } from "~/components/uiParts";

type Comments = ComponentProps<typeof AccordionAnswerDetails>["comments"];
type Reactions = ComponentProps<typeof AccordionAnswerDetails>["reactions"];

type TransactionCommentsMap = { [transactionId: string]: Comments };
type TransactionReactionsMap = { [transactionId: string]: Reactions };

type CommentListProps = ComponentProps<typeof CommentList>;

type Props = {
  surveyTransactions: SurveyTransaction[] | null;
  answerId: string | null;
  currentUserId: string;
  chartPointItems: ChartItem[];
  employee: Employee;
  transactionCommentsLoading: boolean;
  transactionCommentsMap: TransactionCommentsMap;
  commentFocused: boolean;
  transactionReactionsMap: TransactionReactionsMap;
} & Pick<CommentListProps, "suggestedMentionUsers" | "loadTransactionComments">;
export const SurveyTab: FC<Props> = ({
  surveyTransactions,
  answerId,
  currentUserId,
  chartPointItems,
  employee,
  transactionCommentsLoading,
  transactionCommentsMap,
  commentFocused,
  suggestedMentionUsers,
  transactionReactionsMap,
  loadTransactionComments,
}) => {
  const [isRenderingChart, setIsRenderingChart] = useState(false);
  useEffect(() => {
    // 即時レンダリングするとメモリリークが発生するために少し待機してからレンダリングを行う
    // TODO: MUIv5にバージョンアップすることで解決する https://app.clickup.com/t/864dpxfp7
    setTimeout(() => setIsRenderingChart(true), 300);
  });

  return (
    <Box pt={5} pb={5}>
      {!surveyTransactions || isEmpty(surveyTransactions) ? (
        <NotFoundPaper text="アンケートはまだ回答されていません。" />
      ) : (
        <>
          <Box pb={2} height="350px">
            {isRenderingChart ? (
              <SplineChart chartData={chartPointItems} />
            ) : (
              <Loading size="small" />
            )}
          </Box>
          <Box pt={3} pb={6}>
            {surveyTransactions.map((survey, i) => (
              // TODO: molecules/Accordionに置き換える
              <AccordionAnswerDetails
                _expanded={Boolean(answerId && answerId === survey.id)}
                surveyTransaction={survey}
                surveyTransactions={surveyTransactions}
                isScrollTarget={answerId === survey.id}
                employee={employee}
                key={i + "AccordionAnswerDetails"}
                currentUserId={currentUserId}
                comments={transactionCommentsMap[survey.id] ?? []}
                commentDisabled={transactionCommentsLoading}
                commentFocused={commentFocused}
                suggestedMentionUsers={suggestedMentionUsers}
                reactions={transactionReactionsMap[survey.id] ?? []}
                loadTransactionComments={loadTransactionComments}
                isActive={answerId === survey.id}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
