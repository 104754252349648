import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { QuestionTitle } from "~/components/uiParts/QuestionTitle";
import { SatisfactionWithChart } from "~/components/uiParts/SatisfactionWithChart";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  title: string;
  hrOnly?: boolean;
  latelySatisfaction: number;
  previousSatisfaction?: number;
  caption: string;
};

export const TransitionWithChart: FC<Props> = (props) => {
  const { title, hrOnly = false, latelySatisfaction, previousSatisfaction, caption } = props;
  return (
    <>
      <QuestionTitle title={title} hrOnly={hrOnly} bold />
      <Box height="8px" />
      <Box display="flex">
        <SatisfactionWithChart
          latelySatisfaction={latelySatisfaction}
          previousSatisfaction={previousSatisfaction}
        />
      </Box>
      <Typography variant="caption" color="textSecondary">
        {caption}
      </Typography>
    </>
  );
};
