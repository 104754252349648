import { APISchema, SurveyTransaction, TransactionStatus } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

/**
 * 指定されたsendAtをもとに該当のアンケート配信一覧を返すswr
 */
export const useSurveyTransactionsBySendAt = ({
  transactionStatus,
  sendAt,
  tenantId,
}: {
  transactionStatus?: TransactionStatus;
  sendAt?: string;
  tenantId: string;
}) => {
  const keyObj = {
    name: `get_surveys-${tenantId}`,
    transactionStatus,
    sendAt,
    tenantId,
  };

  // sendAtが指定されるまではfetchしない
  return useSWR<SurveyTransaction[]>(sendAt ? keyObj : null, async () => {
    const queryParam: APISchema["/get_surveys"]["GET"]["queryParams"] = {
      transactionStatus,
      sendAt,
    };
    const response = await apiClient.get(`/get_surveys`, queryParam);
    return response.data.map((d) => SurveyTransaction.plainToInstance(d));
  });
};
