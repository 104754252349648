import React, { FC, memo, useState, useCallback, useMemo } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

const REACTION_EMOJI_PATTERNS = ["👍", "👏", "👀", "🎉"];

type Props = {
  onClick: (emoji: string) => void;
};

export const EmojiButtonGroup: FC<Props> = memo(({ onClick }) => {
  const [clickedIndex, setClickedIndex] = useState<number>(-1); // 何も選択していないとき-1
  const isClickedAnyEmoji = useMemo(() => clickedIndex >= 0, [clickedIndex]);

  const handleClick = useCallback(
    (emoji: string, index: number) => {
      setClickedIndex(index);
      onClick(emoji);
    },
    [onClick]
  );

  return (
    <StyledList>
      {REACTION_EMOJI_PATTERNS.map((emoji, i) => {
        return (
          <StyledButton
            key={`EmojiButtonGroup-${i}`}
            $isClicked={i === clickedIndex}
            $isSiblingsClicked={i !== clickedIndex && isClickedAnyEmoji} // 自身以外の他のemojiがクリックされたとき
            onClick={!isClickedAnyEmoji ? () => handleClick(emoji, i) : undefined}
          >
            <Typography variant="body1">{emoji}</Typography>
          </StyledButton>
        );
      })}
    </StyledList>
  );
});

const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: space-evenly;
`;

const StyledButton = styled.button<{ $isClicked: boolean; $isSiblingsClicked: boolean }>`
  background-color: ${(props) =>
    props.$isClicked
      ? props.theme.palette.grey[100]
      : props.$isSiblingsClicked
      ? props.theme.palette.grey[50]
      : `white`};
  opacity: ${(props) => (props.$isSiblingsClicked ? 0.6 : 1)};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: ${(props) => props.theme.shadows[2]};
  border-radius: 9999px;
  height: 48px;
  width: 48px;
  cursor: ${(props) => (props.$isClicked || props.$isSiblingsClicked ? `default` : `pointer`)};
`;
