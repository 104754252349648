import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useCallback, FC } from "react";
import styled from "styled-components";

import { SelectEmployeesFormByPlatform } from "../../employees/SelectEmployeesFormByPlatform";

import { Button, Loading, Modal, Typography } from "~/components/uiParts";
import { useCurrentUser, useAllEmployeesWithDeleted } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { useSlackUsers } from "~/hooks/slackUser";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";
type Props = {
  open: boolean;
  onCancel: () => void;
  newHire?: Employee;
  followers: Employee[];
  onSubmit: (followerIds: string[], emailsWithoutOnnAccount: string[]) => Promise<void>;
};

export const AddFollowersModal: FC<Props> = ({ open, onCancel, newHire, followers, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { slackUsers } = useSlackUsers();
  const { tenant } = useTenant();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const { currentUser } = useCurrentUser();
  const { isLoading: isEmployeeLoading, data: allEmployeesWithDeleted = [] } =
    useAllEmployeesWithDeleted(currentUser.tenantId);
  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    const emailsWithoutOnnAccount: string[] = [];

    const existedAllEmployees = selectedEmails.flatMap((email) => {
      const slackUser = slackUsers.find((v) => v.email === email);
      // NOTE: Slack上にユーザーの情報が存在していた時には新規招待せずに紐づいたユーザーをアサインする
      const employee = allEmployeesWithDeleted.find((v) => {
        if (v.email === email) return true;
        if (!slackUser) return false;
        return v.slackUserId === slackUser.slackUserId;
      });
      if (employee) {
        return employee;
      }
      emailsWithoutOnnAccount.push(email);
      return [];
    });

    if (existedAllEmployees.some((existedEmployee) => existedEmployee.deleted)) {
      enqueueSnackbar("削除されたユーザーをフォロワーに追加することはできません", {
        variant: "error",
      });
      return setIsLoading(false);
    }

    onSubmit(
      [...followers.map((v) => v.id), ...existedAllEmployees.map((v) => v.id)],
      emailsWithoutOnnAccount
    )
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    selectedEmails,
    onSubmit,
    followers,
    slackUsers,
    allEmployeesWithDeleted,
    enqueueSnackbar,
    onCancel,
  ]);

  const Content = isEmployeeLoading ? (
    <Loading size="large" />
  ) : (
    <Box>
      <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="24px">
        <Box width="100%" display="inline-block">
          <SelectEmployeesFormByPlatform
            notAllowEmails={[newHire, ...followers].flatMap((v) => (v ? v.email : []))}
            selectedEmails={selectedEmails}
            onSelectEmails={setSelectedEmails}
          />
        </Box>
      </Box>
      {tenant && !tenant.isIntegratedSlack && (
        <Typography variant="caption" color="textSecondary" align="center" display="block">
          Onnに登録されていないメンバーを設定したい場合は、
          <StyledPointerTypography
            display="inline"
            variant="caption"
            bold
            color="primary"
            onClick={() => window.open("/settings/admin", "_blank")}
          >
            こちら
          </StyledPointerTypography>
          から招待を行ってください。
        </Typography>
      )}
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isEmpty(selectedEmails) || isLoading}
      >
        {!isLoading ? "追加する" : "追加中"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="フォロワー追加"
      content={Content}
      footer={footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
