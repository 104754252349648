import { Box, useMediaQuery } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography, Button, Paper, Modal, UserIconWithLabel } from "~/components/uiParts";
import theme from "~/config/theme";
import { mixin } from "~/util";

const exampleMessages = [
  `ご入社いただけるのを楽しみにしていました！🎉🎉

面接でお会いした時から、ご一緒できることを楽しみにしていました。✨✨
これからやりたいこと、やるべきことが、たくさんありますが是非一緒に協力しながら実現させていきましょう！！

なにか困ったことがあれば、気軽に質問したり、頼ってください。
忙しそうに見えるかも知れませんが、気にせず言ってくださいね。
これからよろしくお願いします！😄`,

  `はじめまして！！
ご入社おめでとうございます！🎉🎉

頼もしい方に入社頂けるので、気持ちが高まっていました🔥🔥
今後の成長のために、やるべきことが山積みです（笑）
ぜひ一緒に実現させていきましょう。

あと、ぜひ仲良くご一緒したいと思っています。
なにか共通の話題や趣味などもあれば、色々お話させてください。✨✨

入社まで不安な気持ちもあると思いますが、ぜひ肩の力を抜いて入社してください！
これからよろしくお願いします！😄`,

  `はじめまして！！
ご入社いただけるのを楽しみにしていました！🎉🎉

光栄なことにバディを担当させていただきます！
バディとは簡単に言うと、入社されてから早く会社に馴染んでもらうためにサポートする担当です。
業務のやり方だったり、ツールの使い方だったりを、1対1で並走させてください。
詳しいことは、入社後、たくさんお話させてください！✨✨

なにか困ったことがあれば、気軽に質問したり、頼ってください。
忙しそうに見えるかも知れませんが、気にせず言ってくださいね。
これからよろしくお願いします！😄`,
];

type Props = {
  open: boolean;
  onCopy: (message: string) => void;
  onClose: () => void;
};

export const ExampleModal: FC<Props> = ({ open, onCopy, onClose }) => {
  const isPc = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal
      title="メッセージ記入例"
      open={open}
      onCancel={onClose}
      content={
        <>
          {isPc
            ? exampleMessages.map((message, index) => (
                <StyledPaper border="primary" key={index}>
                  <UserIconWithLabel iconPath="" name="姓 名" />
                  <Box height="16px" />
                  <Typography variant="body2">{message}</Typography>
                  <StyledButtonContainer mt={4}>
                    <Button
                      fullWidth
                      borderRadius="circle"
                      variant="contained"
                      color="primary"
                      onClick={() => onCopy(message)}
                    >
                      この記入例を使う
                    </Button>
                  </StyledButtonContainer>
                </StyledPaper>
              ))
            : exampleMessages.map((message, index) => (
                <StyledBox py={4} px={2} key={index}>
                  <UserIconWithLabel iconPath="" name="姓 名" />
                  <Box height="16px" />
                  <Typography variant="body2">{message}</Typography>
                  <Box height="32px" />
                  <Button
                    borderRadius="circle"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => onCopy(message)}
                  >
                    この記入例を使う
                  </Button>
                </StyledBox>
              ))}
        </>
      }
    />
  );
};

const StyledBox = styled(Box)`
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root + &.MuiPaper-root {
    margin-top: 36px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
