import { Box } from "@material-ui/core";
import { Employee, OnboardingSimpleTask } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import {
  AssigneeSection,
  DeliveryDateSection,
  DescriptionSection,
  DueDateSection,
  FileSection,
  NewHireSection,
  StatusSection,
} from "../parts";

import { OnboardingSimpleTaskControl } from "./OnboardingSimpleTaskControl";

import { Divider } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  onboardingTask: OnboardingSimpleTask;
  newHire: Employee;
  assigneeEmployees: Employee[];
  isEnableNewHireLink?: boolean;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickChangeStatusButton: () => Promise<void>;
};

// ====================
// main
// ====================

export const OnboardingSimpleTaskSummarySidebar: FC<Props> = ({
  onboardingTask,
  newHire,
  assigneeEmployees,
  isEnableNewHireLink,
  onClickEditButton,
  onClickDeleteTaskButton,
  onClickChangeStatusButton,
}) => {
  // ====================
  // component
  // ====================

  return (
    <>
      <StyledBox display="flex" flexDirection="column" gridGap="32px" px="24px" py="32px">
        <DescriptionSection description={onboardingTask.body} />
        <FileSection filePaths={onboardingTask.filePaths as string[]} />

        <Divider />

        <NewHireSection isEnableNewHireLink={isEnableNewHireLink} newHire={newHire} />
        <AssigneeSection assigneeEmployees={assigneeEmployees} />
        {onboardingTask.deliveryDate && (
          <DeliveryDateSection deliveryDate={onboardingTask.deliveryDate} newHire={newHire} />
        )}
        <DueDateSection newHire={newHire} onboardingTask={onboardingTask} />
        <StatusSection newHire={newHire} onboardingTask={onboardingTask} />
      </StyledBox>

      <OnboardingSimpleTaskControl
        onboardingTask={onboardingTask}
        onClickEditButton={onClickEditButton}
        onClickDeleteTaskButton={onClickDeleteTaskButton}
        onClickChangeStatusButton={onClickChangeStatusButton}
      />
    </>
  );
};

// ====================
// style
// ====================

const HEADER_HEIGHT = 154;
const FOOTER_HEIGHT = 102;

const StyledBox = styled(Box)`
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
  overflow-y: auto;
`;
