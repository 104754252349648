import { createIMockSurveyTransaction } from "@onn/common";
import { addDays, format } from "date-fns";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { NotFound } from "../../NotFound";

import { SurveyLanding } from "~/components/domains/transactions";
import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSurveyTemplate } from "~/hooks/templates";

export const PreviewSurvey: FC = () => {
  const { currentUser } = useCurrentUser();
  const { id: surveyTemplateId } = useParams<"id">();

  const { data: surveyTemplate, isValidating: isValidatingSurvey } = useSurveyTemplate(
    currentUser.tenantId,
    surveyTemplateId
  );

  const surveyTransaction =
    surveyTemplate &&
    createIMockSurveyTransaction({
      ...surveyTemplate,
      remindDate: format(addDays(new Date(), 7), "yyyy-MM-dd"),
    });

  if (isValidatingSurvey) {
    return <Loading size="large" />;
  }

  if (!surveyTemplate || !surveyTransaction) {
    return <NotFound />;
  }

  return (
    <SurveyLanding
      survey={surveyTemplate}
      surveyTransaction={surveyTransaction}
      userName={currentUser.getName()}
      isPreview
    />
  );
};
