import { Box } from "@material-ui/core";
import { Message, EMPLOYEE_ACTION_TYPE, Employee, Role, createIMockEmployee } from "@onn/common";
import { addWeeks, format } from "date-fns";
import { isEmpty, shuffle } from "lodash";
import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { FrontPage as NewHireFrontPage } from "./NewHire/FrontPage";
import { PortalContent as NewHirePortalContent } from "./NewHire/PortalContent";

import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useLibraries } from "~/hooks/library";
import { useGetNewHireOnboardingTasks } from "~/hooks/portal";
import { useQuery, useSetQueryString } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";
import { useTenantSettings } from "~/hooks/tenantSetting/useTenantSettings";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { EmployeeActiveLogUseCase } from "~/service/usecases/employeeActiveLogUseCase";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

type LocationState = {
  isFirstLogin: boolean;
};

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

const employeeActiveLogUseCase = new EmployeeActiveLogUseCase();

export const PortalIndex: FC = () => {
  const { tenant } = useTenant();
  const { tenantSettings } = useTenantSettings();

  const locationState = useLocation().state as LocationState;
  const { query } = useQuery();
  const { setQueryString } = useSetQueryString();

  const locationQuery = query.get("location");
  const isPreview = query.get("preview") === "true";
  const isFirstLogin = locationState?.isFirstLogin;
  const shouldFetchAdmins = !tenant.isActiveNewGraduate;

  // プレビューモードのときはcurrentUserをmockする
  const currentUserOrigin = useCurrentUser().currentUser;
  const currentUser = !isPreview
    ? currentUserOrigin
    : createIMockEmployee({
        ...currentUserOrigin,
        assignedAsNewcomer: true,
        joinAt: format(addWeeks(new Date(), 1), "yyyy-MM-dd"), // mockのcurrentUserは入社1週間前
      });

  const { onboardingTasks, isLoading: isLoadingOnboardingTasks } = useGetNewHireOnboardingTasks(
    currentUser.id,
    currentUser.tenantId,
    isPreview
  );
  const showOnboardingTaskContent = useMemo(() => !isEmpty(onboardingTasks), [onboardingTasks]);

  const { data: libraries, isLoading: isLoadingLibraries } = useLibraries(tenant.tenantId);

  const [loadingAdmins, setLoadingAdmins] = useState(true);
  const [messageIconPath, setMessageIconPath] = useState("");
  const [admins, setAdmins] = useState<Employee[]>([]);
  const [showedFrontPage, setShowedFrontPage] = useState(false);

  const fetchAdmins = useCallback(async (tenantId: string) => {
    setLoadingAdmins(true);
    await EmployeeUseCase.findByRole(tenantId, Role.ADMIN)
      .then((res) => setAdmins(shuffle(res.filter((v) => v.profileIconImageUrl)).slice(0, 3))) // アイコン設定済みのランダムな3名の管理者
      .finally(() => setLoadingAdmins(false));
  }, []);

  useEffect(() => {
    if (shouldFetchAdmins) {
      fetchAdmins(currentUser.tenantId);
    } else {
      setLoadingAdmins(false);
    }
  }, [currentUser.tenantId, fetchAdmins, shouldFetchAdmins]);

  // v1ではメッセージが最大1件なので、暫定的にこれでアイコンのURLを取得する
  const fetchIcon = useCallback(async (message?: Message) => {
    if (!message) return;

    const url = await fileAPIAdapter.fetchUrl({ path: message.iconPath });
    url && setMessageIconPath(url);
  }, []);

  useEffect(() => {
    fetchIcon(tenantSettings.messages && tenantSettings.messages[0]);
  }, [fetchIcon, tenantSettings.messages]);

  const handleClickFrontPageButton = () => {
    setShowedFrontPage(true);
    setQueryString({ location: undefined });
  };

  const createReadMessageLog = async (message: Message) => {
    const activeLogs = await employeeActiveLogUseCase.findByEmployeeIdAndType(
      currentUser.id,
      currentUser.tenantId,
      EMPLOYEE_ACTION_TYPE.READ_MESSAGE
    );

    if (!isPreview && !activeLogs.some((log) => log.targetId === message.id)) {
      await employeeActiveLogUseCase.create({
        employeeId: currentUser.id,
        type: EMPLOYEE_ACTION_TYPE.READ_MESSAGE,
        tenantId: currentUser.tenantId,
        targetId: message.id,
      });
    }
  };

  if (
    loadingAdmins ||
    isLoadingOnboardingTasks ||
    (shouldFetchAdmins && !admins) ||
    isLoadingLibraries
  ) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  if (locationQuery === "welcome" && (isFirstLogin || isPreview) && !showedFrontPage) {
    return <NewHireFrontPage tenantName={tenant.tenantName} onClick={handleClickFrontPageButton} />;
  }

  return (
    <NewHirePortalContent
      newcomerEmployee={currentUser}
      admins={admins as Employee[]} // NOTE: shouldFetchAdmins && !admins でタイプガードが効かないため as している
      messageIconPath={messageIconPath}
      libraries={libraries || []}
      showOnboardingTaskContent={showOnboardingTaskContent}
      isPreview={isPreview}
      isFirstLogin={isFirstLogin}
      createReadMessageLog={createReadMessageLog}
    />
  );
};
