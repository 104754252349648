import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useMemo, useState, FC } from "react";

import { TimelineItem } from "./TimelineItem";

import { Typography, Button, Loading } from "~/components/uiParts";
import { useAllNewcomers, useCurrentUser } from "~/hooks/employee";
import { useCompletedSurveysOrderByAnsweredAt } from "~/hooks/transaction/useCompletedSurveysOrderByAnsweredAt";

export const TimelineIndex: FC = () => {
  const { currentUser } = useCurrentUser();
  const [displayCount, setDisplayCount] = useState(10);

  const { data: surveyTransactions = [], isValidating: isValidatingSurveyTransactions } =
    useCompletedSurveysOrderByAnsweredAt(currentUser.tenantId);

  const { data: newcomers = [] } = useAllNewcomers();
  const newHireMap = useMemo(() => {
    return newcomers.reduce((acc: Record<string, Employee>, newHire) => {
      return { ...acc, [newHire.id]: newHire };
    }, {});
  }, [newcomers]);

  const displaySurveyTransactions = useMemo(() => {
    return surveyTransactions.slice(0, displayCount);
  }, [displayCount, surveyTransactions]);

  return (
    <>
      <Box display="inline" pr="16px">
        <Typography variant="h4" bold display="inline">
          タイムライン
        </Typography>
      </Box>

      <Box mt="32px">
        {isValidatingSurveyTransactions ? (
          <Loading size="small" />
        ) : (
          <>
            {displaySurveyTransactions.map((surveyTransaction) => {
              const newHire = newHireMap[surveyTransaction.employeeId];
              if (!newHire) {
                return null;
              }

              return (
                <TimelineItem
                  key={surveyTransaction.id}
                  surveyTransactions={surveyTransactions}
                  surveyTransaction={surveyTransaction}
                  newHire={newHire}
                />
              );
            })}
          </>
        )}
      </Box>
      {!isValidatingSurveyTransactions &&
        surveyTransactions.length > displaySurveyTransactions.length && (
          <Box mt="32px" pr="32px" pl="64px">
            <Button
              onClick={() => setDisplayCount((prev) => prev + 10)}
              borderRadius="regular"
              variant="contained"
              color="primary"
              fullWidth
            >
              もっと見る
            </Button>
          </Box>
        )}
    </>
  );
};
