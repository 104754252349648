import React, { FC, ReactNode, useState, createContext, useCallback } from "react";

import { useLocalStorage, useQuery } from "~/hooks/shared";

const TENANT_ID_KEY = "tenantId";

export const CurrentTenantIdContext = createContext<{
  currentTenantId: string;
  updateCurrentTenantId: (tenantId: string) => void;
  resetCurrentTenantId: () => void;
}>({
  currentTenantId: "",
  updateCurrentTenantId: () => void 0,
  resetCurrentTenantId: () => void 0,
});

const useDefaultTenantId = () => {
  const { retrieveValue, storeValue } = useLocalStorage();
  const { query } = useQuery();

  const tenantIdFromURL = query.get("tenant-id");
  // NOTE: レンダリングを待たずに storage の値を更新したいためここで更新する
  if (tenantIdFromURL) {
    storeValue(TENANT_ID_KEY, tenantIdFromURL);
  }
  const tenantIdFromStorage = retrieveValue<string>(TENANT_ID_KEY);

  return tenantIdFromURL || tenantIdFromStorage;
};

export const CurrentTenantIdProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { storeValue, removeValue } = useLocalStorage();

  const defaultValue = useDefaultTenantId();
  const [currentTenantId, setTenantId] = useState<string>(defaultValue ?? "");

  const updateCurrentTenantId = useCallback(
    (tenantId: string) => {
      if (tenantId) {
        setTenantId(tenantId);
        storeValue(TENANT_ID_KEY, tenantId);
      }
    },
    [storeValue]
  );

  const resetCurrentTenantId = useCallback(() => {
    setTenantId("");
    removeValue(TENANT_ID_KEY);
  }, [removeValue]);

  return (
    <CurrentTenantIdContext.Provider
      value={{ currentTenantId, updateCurrentTenantId, resetCurrentTenantId }}
    >
      {children}
    </CurrentTenantIdContext.Provider>
  );
};
