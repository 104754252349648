import { v4 } from "uuid";

import { Employee } from "../../../domain/Employee";
import { Tenant, TenantWithSecret } from "../../../domain/Tenant";
import { ContactMessage } from "../ContactMessage";

export class ContactMessageWithCreator extends ContactMessage {
  creator: Employee;
  constructor(init: ExcludeMethods<ContactMessageWithCreator>) {
    super(init);
    this.creator = init.creator;
  }

  private getCreatorName(tenant: Tenant | TenantWithSecret): string {
    if (this.isSentByOfficialName) {
      return tenant.tenantName;
    }

    return this.creator.getName();
  }

  getCreatorImageUrl(): string | undefined {
    return this.creator.profileIconImageUrl;
  }

  getCreatorInfo(tenant: Tenant | TenantWithSecret): {
    creatorName: string;
    honorificCreatorName: string;
    creatorImageUrl?: string;
  } {
    const creatorName = this.getCreatorName(tenant);

    return {
      creatorName,
      honorificCreatorName: this.isSentByOfficialName ? creatorName : creatorName + "さん",
      creatorImageUrl: this.isSentByOfficialName
        ? `public/uploads/logo/${tenant.tenantId}`
        : this.getCreatorImageUrl(),
    };
  }

  public static create(
    params: Optional<ExcludeMethods<ContactMessageWithCreator>, "id">
  ): ContactMessageWithCreator {
    return new ContactMessageWithCreator({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static fromUser(message: ContactMessage, creator: Employee): ContactMessageWithCreator {
    return ContactMessageWithCreator.create({ ...message, creator });
  }

  public static fromUsers(
    message: ContactMessage,
    employees: Employee[]
  ): ContactMessageWithCreator | null {
    const creator = employees.find((v) => v.id === message.createdEmployeeId);
    if (!creator) return null;
    return ContactMessageWithCreator.create({ ...message, creator });
  }
}
