import { Employee } from "@onn/common";
import React, { FC, useMemo } from "react";

import { UserIconGroup } from "~/components/uiParts";
import { useCurrentUser, useAdmins } from "~/hooks/employee";
import { CustomOnboardingExperienceTaskTemplate } from "~/hooks/onboardingExperienceTaskTemplate";

type Props = {
  template: CustomOnboardingExperienceTaskTemplate;
};

export const EditableEmployees: FC<Props> = React.memo(({ template }) => {
  const { currentUser } = useCurrentUser();
  const { data: admins = [] } = useAdmins(currentUser.tenantId);

  const employees: Employee[] = useMemo(() => {
    const allEditableEmployees = [...template.editableEmployees, ...admins];

    if (allEditableEmployees.some((v) => v.id === currentUser.id)) {
      // 自身を先頭に表示する
      return [currentUser, ...allEditableEmployees.filter((e) => e.id !== currentUser.id)];
    }
    return allEditableEmployees;
  }, [currentUser, admins, template.editableEmployees]);

  return (
    <UserIconGroup
      tooltip
      usersInfo={employees.map((employee) => ({
        username: employee.getName(),
        profileIconImageUrl: employee.profileIconImageUrl,
      }))}
    />
  );
});
