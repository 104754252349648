import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnboardingMessageTask, Employee } from "@onn/common";
import React, { useCallback, FC, useState, MouseEvent, useMemo } from "react";

import { Button, IconButton, Typography } from "~/components/uiParts";
import { useIsMyDepartmentAdmin } from "~/hooks/department/useIsMyDepartmentAdmin";

// ====================
// magic number
// ====================

const REWARD_ID = "complete-onboarding-task";

// ====================
// props
// ====================

type Props = {
  currentUser: Employee;
  newHire: Employee;
  onboardingTask: OnboardingMessageTask;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickOpenManageMessageTask: () => void;
};

// ====================
// main
// ====================

export const OnboardingMessageTaskControl: FC<Props> = ({
  currentUser,
  newHire,
  onboardingTask,
  onClickEditButton,
  onClickDeleteTaskButton,
  onClickOpenManageMessageTask,
}) => {
  // ====================
  // state
  // ====================

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // ====================
  // variable
  // ====================

  const assignedContent = useMemo(
    () => onboardingTask.contents.find((v) => v.requestedEmployeeId === currentUser.id),
    [currentUser.id, onboardingTask.contents]
  );

  const canClick = useMemo(
    // 担当者 かつ メッセージコンテンツが作成可能の場合 操作可能
    () => {
      if (onboardingTask.status === "COMPLETED") return false;
      if (!onboardingTask.isEditableMessageContent(newHire)) return false;

      return assignedContent && !assignedContent.isCompleted;
    },
    [assignedContent, newHire, onboardingTask]
  );

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const { result: isMyDepartmentAdmin } = useIsMyDepartmentAdmin(newHire, currentUser);

  const navigateToMessageTask = useCallback(() => {
    if (!assignedContent) return;

    window.open(
      `/messages/${onboardingTask.id}/${assignedContent.id}`,
      "_blank",
      "noopener noreferrer"
    );
  }, [assignedContent, onboardingTask.id]);

  // ====================
  // jsx
  // ====================

  return (
    <Box p="24px" display="flex" alignItems="center" position="absolute" bottom="16px" width="100%">
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        disabled={!canClick}
        onClick={navigateToMessageTask}
        disableTouchRipple
      >
        <span id={REWARD_ID} />
        {onboardingTask.status === "COMPLETED" ? "対応完了済み" : "メッセージを書く"}
      </Button>
      {onboardingTask.status !== "COMPLETED" && <IconButton icon="menuVert" onClick={openMenu} />}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {(currentUser.isAdmin() || isMyDepartmentAdmin) && (
          <MenuItem
            onClick={() => {
              onClickOpenManageMessageTask();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">記入依頼設定</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onClickEditButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">タスク編集</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickDeleteTaskButton();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">タスク削除</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
