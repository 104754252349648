import { Box, Grid } from "@material-ui/core";
import { Employee, MessageContent, OnboardingMessageTask } from "@onn/common";
import React, { useRef, useMemo, FC } from "react";
import styled from "styled-components";

import { ActivityMessagePaper } from "./ActivityMessagePaper";

import { ActivityContentItem } from "~/components/uiParts";
import { useScrollToTargetElement } from "~/hooks/employee";

type Props = {
  newHire: Employee;
  messageTask?: OnboardingMessageTask;
  messageContent: MessageContent;
  isScrollTarget: boolean;
  isActive?: boolean;
};

export const ActivityContentForMessageContent: FC<Props> = React.memo(
  ({ newHire, messageTask, messageContent, isScrollTarget, isActive }) => {
    const rootRef = useRef<HTMLDivElement>(null);

    useScrollToTargetElement({
      enabled: isScrollTarget,
      targetRef: rootRef,
    });

    const captionText = useMemo(() => {
      if (messageTask?.status === "COMPLETED") {
        return `${messageTask.dueDate.getDisplayTextByEmployee(newHire, "yyyy/MM/dd HH:mm")} 完了`;
      } else {
        return `${messageTask?.dueDate.getDisplayTextByEmployee(newHire)} 配信予定`;
      }
    }, [newHire, messageTask?.dueDate, messageTask?.status]);

    return (
      <StyledListItem>
        <Box mr={2} display="flex" flexDirection="column" alignItems="center">
          <StyledCircle />
        </Box>
        <Box width="100%">
          <Grid container alignItems="flex-start">
            <Grid item sm={7}>
              <div ref={rootRef}>
                <ActivityContentItem
                  title={messageTask?.title || ""}
                  caption={captionText}
                  content={
                    <ActivityMessagePaper
                      newHireName={newHire.getName()}
                      newHireProfileIconImageUrl={newHire.profileIconImageUrl}
                      senderName={messageContent.name}
                      senderDepartmentName={messageContent.department}
                      senderProfileIconImageUrl={messageContent.iconPath || ""}
                      messageText={messageContent.message || ""}
                      isActive={!!isActive}
                    />
                  }
                  shareLink={`${location.origin}/employee/${newHire.id}?contentId=${messageContent.id}`}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </StyledListItem>
    );
  }
);

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledListItem = styled.li`
  padding: 0px 8px;
  list-style: none;
  display: flex;
  :not(:last-child) {
    & > .MuiBox-root:first-of-type {
      ::after {
        width: 3px;
        // 縦線が丸に対して右にずれないよう、縦線の太さに対してマイナスマージンをかける
        margin-left: calc(-3px / 2);
        height: 100%;
        content: "" "";
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
    & > .MuiBox-root:last-of-type {
      padding-bottom: ${(props) => props.theme.spacing(5)}px;
    }
  }
`;
