import { cloneDeep, isEmpty, uniq } from "lodash";
import { v4 } from "uuid";

import { GroupChannelType } from "../shared";

export class Department {
  id: string;
  name: string;
  layer: number;
  tenantId: string;
  parentDepartmentId?: string;
  children?: Department[];
  /**
   * この部署に関連する通知の配信先
   * undefinedのときは、親の配信先を参照する
   * さかのぼっても値が見つからないときは、テナントのisDefaultなslackチャンネルに配信する
   */
  slackChannelMap?: { [K in GroupChannelType]?: string };

  constructor({
    id,
    name,
    layer,
    tenantId,
    parentDepartmentId,
    children,
    slackChannelMap,
  }: Omit<Department, "flat" | "hasChildren">) {
    this.id = id;
    this.name = name;
    this.layer = layer;
    this.tenantId = tenantId;
    this.parentDepartmentId = parentDepartmentId;
    this.children = children;
    this.slackChannelMap = slackChannelMap;
  }

  /**
   * 与えられた引数から、Departmentインスタンスを返す
   * idが存在しない場合は、新たに計算する
   * @param params
   * @returns
   */
  public static create(params: Optional<Department, "id" | "flat" | "hasChildren">) {
    return new Department({
      ...params,
      id: params.id ?? v4(),
    });
  }

  /**
   * childrenに1つ以上の部署が入っているとき、trueを返す
   */
  public hasChildren(): boolean {
    return !isEmpty(this.children);
  }

  /**
   * フラットな構造に変換して返す
   * @returns 部署の配列
   */
  public flat(): Department[] {
    if (this.children) {
      return [this, ...this.children.map((child) => child.flat()).flat()];
    } else {
      return [this];
    }
  }

  /**
   * layerとparentDepartmentIdを元に、上位部署のchildrenに下位部署を追加して、ネスト構造になった部署を取得する
   * @param flatDepartments childrenを持たない部署の配列
   * @returns nest化された部署の配列
   */
  public static nest(flatDepartments: Department[]): Department[] {
    if (isEmpty(flatDepartments)) return flatDepartments;

    const results = cloneDeep(flatDepartments);
    // ユニークなlayerの降順(e.g. [4, 3, 2, 1])
    const layers = [...new Set(results.map((dep) => dep.layer))].sort().reverse();
    const eachLayerDepartments = layers.map((layer) =>
      results.filter((dep) => dep.layer === layer)
    );

    // layerごとの部署の配列に対し、後ろから処理する(つまり、最も下位である部署から)
    // 各ループで、その部署の親の部署をひとつ上のlayerの部署の中から見つけ、その部署のchildrenに挿入する
    // 挿入した下位部署は、resultsから除外し、挿入された上位部署は、resultsの挿入前のものと入れ替える
    // 親部署が見つからなくなるまで繰り返す
    for (let i = 0; i < layers.length - 1; i++) {
      const thisLayerDeps = eachLayerDepartments[i];
      const parentLayerDeps = eachLayerDepartments[i + 1];

      // 絶対存在するはずなのでcontinueにはならないが型エラーに対応するためチェックしている
      if (!thisLayerDeps || !parentLayerDeps) continue;

      thisLayerDeps.forEach((thisDep) => {
        const parentDep = parentLayerDeps.find(
          (parentDep) => parentDep.id === thisDep.parentDepartmentId
        );

        if (!parentDep) {
          return;
        }

        parentDep.children = parentDep.children ? [...parentDep.children, thisDep] : [thisDep];
        // それぞれのlayerの部署でnameの昇順を得る
        parentDep.children.sort((a, b) => (a.name > b.name ? 1 : -1));

        // このループで上位部署挿入した下位部署は、上位部署インスタンスに含まれるので、resultsでは不要なので除去する
        const thisIndexInResult = results.findIndex((r) => r.id === thisDep.id);
        results.splice(thisIndexInResult, 1);

        // このループで下位部署を挿入された上位部署は、resultsにすでに入っている上位部署と交換することで、childrenを持つ上位部署に置き換えられる
        const parentIndexInResult = results.findIndex((r) => r.id === parentDep.id);
        results.splice(parentIndexInResult, 1, parentDep);
      });
    }

    // 最上位のlayerの部署でnameの昇順を得る
    return results.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  /**
   * 部署名を取得する
   * layer2以上の部署は、 HR事業本部／開発部／Onn担当 のように `／` で区切る
   * @param departments
   * @param targetId
   * @returns 部署名
   */
  public static getFullName(departments: Department[], targetId: string) {
    const targetDepartment = departments.find((dep) => dep.id === targetId);

    if (!targetDepartment) {
      throw new Error("部署の構造に問題があります");
    }

    if (targetDepartment.layer === 1) {
      return targetDepartment.name;
    }

    const names: string[] = [targetDepartment.name];
    let thisTargetDep = targetDepartment;
    for (let i = 0; i < targetDepartment.layer - 1; i++) {
      const parentDep = departments.find((dep) => dep.id === thisTargetDep.parentDepartmentId);

      if (!parentDep) {
        throw new Error("部署の構造に問題があります");
      }

      names.push(parentDep.name);
      thisTargetDep = parentDep;
    }

    return names.reverse().join("／");
  }

  /**
   * 与えられたdepartmentIdsの下位部署のidをすべて取得します
   * @param departmentIds
   * @param departments 比較に利用するすべての部署
   */
  public static getChildIds(departmentIds: string[], departments: Department[]): string[] {
    return uniq(
      departmentIds
        .map((departmentId) => {
          const children = departments.filter((dep) => dep.parentDepartmentId === departmentId);

          if (isEmpty(children)) {
            return [departmentId];
          } else {
            return [
              departmentId,
              ...this.getChildIds(
                children.map((child) => child.id),
                departments
              ).flat(),
            ];
          }
        })
        .flat()
    );
  }

  /**
   * 与えられたdepartmentIdの上位部署のIDをすべて取得します
   * @param departmentId 上位部署を探すdepartmentのid
   * @param departments 探す対象となる部署の配列
   */
  public static getParentIds(departmentId: string, departments: Department[]): string[] {
    const thisDep = departments.find((dep) => dep.id === departmentId);

    if (!thisDep) throw new Error("部署の構造に問題があります");

    const parentDep = departments.find((dep) => dep.id === thisDep.parentDepartmentId);
    if (!parentDep) {
      return [thisDep.id];
    } else {
      return [thisDep.id, ...this.getParentIds(parentDep.id, departments)];
    }
  }

  /**
   * 管理対象になる部署（自身と、それ以下の部署）のidを取得します
   * @param departmentIds
   * @param departments 比較に利用するすべての部署
   */
  public static getAccessibleDepartmentIds(
    departmentIds: string[],
    departments: Department[]
  ): string[] {
    return uniq(
      departmentIds
        .map((departmentId) => {
          const children = departments.filter((dep) => dep.parentDepartmentId === departmentId);

          if (isEmpty(children)) {
            return [departmentId];
          } else {
            return [
              departmentId,
              ...this.getAccessibleDepartmentIds(
                children.map((child) => child.id),
                departments
              ).flat(),
            ];
          }
        })
        .flat()
    );
  }
}
