import { Survey } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { SurveyTemplateUseCase } from "~/service/usecases/surveyTemplateUseCase";

const surveyTemplateUseCase = new SurveyTemplateUseCase();

/**
 * tenantIdとsurveyIdをもとにsurveyのtemplateを返すswr
 * @param tenantId tenantのid
 */
export const useSurveyTemplate = (
  tenantId?: string,
  templateId?: string
): SWRResponse<Survey, Error> => {
  const keys = tenantId && templateId ? ["surveys", tenantId, templateId] : null;

  return useSWR(keys, ([_key, tenantId, templateId]: [string, string, string]) =>
    surveyTemplateUseCase.find(tenantId, templateId).then((res) => res)
  );
};
