import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";

import { UserIconWithLabel } from "~/components/uiParts";

type Props = {
  admins: Employee[];
};

export const AdminPanel: FC<Props> = ({ admins }) => {
  return (
    <Box display="flex" flexDirection="column" gridGap="16px">
      {admins.map((employee) => (
        <UserIconWithLabel
          key={employee.id}
          name={employee.getName()}
          secondaryText={employee.email}
          iconPath={employee.profileIconImageUrl}
          iconCircular
        />
      ))}
    </Box>
  );
};
