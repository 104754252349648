import { Box } from "@material-ui/core";
import {
  DEFAULT_MAX_FILE_SIZE_MB,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  ContactMessageDraft,
  convertStatusToString,
  AllContactRoom,
  ContactMessage,
} from "@onn/common";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import React, { useState, FC, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";

import { NewHireContactMessageItem } from "../../../contactMessage/contactMessageItem";

import { useSPEmployeeDetailModal } from "../../../employees/hooks/useSPEmployeeDetailModal";

import { ContactMessageTemplateMenu } from "../parts";
import { useContactMessageTemplatesMenuHandling } from "../parts/ContactMessageTemplateMenu/useContactMessageTemplatesMenuHandling";

import { useLatestAlreadyReadUsersInfoV2 } from "~/components/domains/contactRooms/hooks";
import {
  Button,
  Icon,
  Loading,
  Paper,
  TextareaAutosizeAttachableFile,
  Typography,
  UserIconWithLabel as CommonUserIconWithLabel,
  IconButton,
} from "~/components/uiParts";
import { useContactMessages, useOptimisticCreateMessage } from "~/hooks/contactMessage";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useUpdateReadLogOfContactRoom } from "~/hooks/contactRoom";
import { useDepartmentsByTenantId } from "~/hooks/department";
import { useEmployees, useCurrentUser, useEmployee } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useDebouncedCallback, useKeyboardHeight } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";

import { ContactMessageRepository } from "~/infrastructure/api/contactMessageRepository";
import { NotFound } from "~/pages/NotFound";

const contactMessageRepository = new ContactMessageRepository();
type Props = {
  contactRoomId: string;
  contactMessageDraft: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  onClickBack: () => void;
};

export const NewHireSPContactRoomItem: FC<Props> = (props) => {
  const contactRoomId = props.contactRoomId;
  const { currentUser } = useCurrentUser();
  const {
    data: contactRoomAndMessages = { contactRoom: undefined, messages: [] },
    isLoading: isLoadingContactRoomAndMessages,
    mutate,
  } = useContactMessages({
    tenantId: currentUser.tenantId,
    contactRoomId,
  });

  const { contactRoomsWithoutCurrentUser: contactRooms, isLoadingContactRooms } =
    useContactContext();

  // 対象のコンタクトルームのfirestore.contactRoomsの状態を監視し、変更があればmutateする
  useEffect(() => {
    const unsubscribeFunctions = contactMessageRepository.listenNewContactMessage(
      [contactRoomId],
      currentUser.tenantId,
      () => mutate()
    );
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoomId]);

  const contactRoom = contactRooms.find((cr) => cr.id === contactRoomId);
  if (isLoadingContactRoomAndMessages || isLoadingContactRooms)
    return <Loading size={"large"} fullHeight />;
  if (!contactRoomAndMessages.contactRoom || !contactRoom) return <NotFound />;
  return (
    <SPContactRoomItem
      {...props}
      contactRoom={contactRoom}
      contactMessages={
        contactRoomAndMessages.messages as (ContactMessage & {
          creatorName: string;
          creatorImageUrl: string | undefined;
        })[]
      }
    />
  );
};

type SPContactRoomItemProps = {
  contactRoom: AllContactRoom;
  contactMessages: (ContactMessage & {
    creatorName: string;
    creatorImageUrl: string | undefined;
  })[];
  contactMessageDraft: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  onClickBack: () => void;
};

const SPContactRoomItem: FC<SPContactRoomItemProps> = ({
  contactRoom,
  contactMessages,
  contactMessageDraft,
  saveContactMessageDraft,
  onClickBack,
}) => {
  const { keyboardHeight } = useKeyboardHeight();
  const { handleOpenSPEmployeeDetailModal } = useSPEmployeeDetailModal();

  const { currentUser } = useCurrentUser();
  const { tenantSettings } = useTenantSettings();

  const [newMessage, setNewMessage] = useState("");

  const { postMessage, isSending, isFileSending, messageId } = useOptimisticCreateMessage({
    tenantId: currentUser.tenantId,
    contactRoomId: contactRoom.id,
  });

  useEffect(() => {
    setNewMessage(contactMessageDraft.text);
  }, [contactMessageDraft]);

  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);

  const [messageListRef, setMessageListRef] = useState<HTMLDivElement>();

  const { data: followers = [] } = useEmployees(contactRoom.followerIds);
  const { data: assignee } = useEmployee(contactRoom.assigneeId);

  const { isLoading: isDataLoading3, data: departments = [] } = useDepartmentsByTenantId(
    currentUser.tenantId
  );

  const { data: newHire, isLoading: isDataLoading7 } = useEmployee(contactRoom.employeeId);

  const isDataLoading = isDataLoading3 || isDataLoading7;

  const { handleModal } = useModal();

  const { updateReadLogOfContactRoom } = useUpdateReadLogOfContactRoom();

  const { latestAlreadyReadUsersInfo } = useLatestAlreadyReadUsersInfoV2(
    contactRoom,
    currentUser.id,
    [...followers, ...(assignee ? [assignee] : [])],
    contactMessages.filter((m) => m instanceof ContactMessage) as ContactMessage[]
  );

  // コンタクトルームに紐付くユーザーが削除されているかどうか
  const isRoomTargetEmployeeDeleted = contactRoom.employee && contactRoom.employee.deleted;

  useEffect(() => {
    const fn = async () => {
      await updateReadLogOfContactRoom(currentUser.id, contactRoom.id);
    };

    fn();
    // ページアクセス時のみの実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, contactRoom.id]);

  useEffect(() => {
    if (!messageListRef) {
      return;
    }

    messageListRef.scrollTop = messageListRef.scrollHeight;
    // messageに変更があったときに一番したまでスクロールする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListRef, contactMessages]);

  const executePostMessages = useCallback(async () => {
    if (contactMessageDraft) {
      saveContactMessageDraft(new ContactMessageDraft({ ...contactMessageDraft, text: "" }));
    }
    await postMessage({
      newMessageText: newMessage,
      newMessageFiles,
      employee: currentUser,
    });
    setNewMessageFiles([]);
  }, [
    contactMessageDraft,
    currentUser,
    newMessage,
    newMessageFiles,
    postMessage,
    saveContactMessageDraft,
  ]);

  const handleSubmit = useCallback(async () => {
    if (tenantSettings.features.contactInitialDisplay || !isEmpty(contactMessages)) {
      return executePostMessages();
    }
    handleModal({
      name: "confirmPostMessageModal",
      args: {
        onSubmit: async () => {
          return executePostMessages();
        },
      },
    });
  }, [
    contactMessages,
    executePostMessages,
    handleModal,
    tenantSettings.features.contactInitialDisplay,
  ]);

  const messageListView = useMemo(() => {
    const messageItems = (
      <>
        {contactMessages.map((messageOrLog) => {
          const message: ContactMessage & {
            creatorName: string;
            creatorImageUrl: string | undefined;
          } = messageOrLog;
          const variant = message.isNewHireMessage ? "left" : "right";
          const isSendingItem = messageId === message.id;
          return (
            <NewHireContactMessageItem
              key={message.id}
              message={message}
              variant={variant}
              alreadyReadUsersInfo={latestAlreadyReadUsersInfo[message.id]}
              departments={departments}
              newHire={newHire}
              isFileSending={isSendingItem && isFileSending}
            />
          );
        })}
      </>
    );
    if (isRoomTargetEmployeeDeleted) {
      return (
        <>
          {messageItems}
          <StyledUnavailableMessagePaper elevation={0}>
            <Typography variant="body2">{`この入社者アカウントは削除されています。\nメッセージの送受信はできません。`}</Typography>
          </StyledUnavailableMessagePaper>
        </>
      );
    }

    if (contactRoom.isUnFollowByEmployee) {
      return (
        <>
          {messageItems}
          <StyledUnavailableMessagePaper elevation={0}>
            <Typography variant="body2">{`入社者にブロックされています。\nメッセージの送受信はできません。`}</Typography>
          </StyledUnavailableMessagePaper>
        </>
      );
    }

    if (isEmpty(contactMessages)) {
      return (
        <StyledPaper>
          <Typography variant="caption">
            {`入社前後の連絡や入社者のフォローアップなどのやり取りを行うことができます。\nメッセージを送信すると入社者のメールに通知され、やりとりが開始されます。`}
          </Typography>
        </StyledPaper>
      );
    }

    return messageItems;
  }, [
    contactMessages,
    isRoomTargetEmployeeDeleted,
    contactRoom.isUnFollowByEmployee,
    messageId,
    latestAlreadyReadUsersInfo,
    departments,
    newHire,
    isFileSending,
  ]);

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 500);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewMessage(e.target.value);
      handleDebounceCallback(() => {
        saveContactMessageDraft(
          new ContactMessageDraft({
            id: contactMessageDraft.id,
            contactRoomId: contactMessageDraft.contactRoomId,
            createdUserId: contactMessageDraft.createdUserId,
            text: e.target.value,
            tenantId: currentUser.tenantId,
          })
        );
      });
    },
    [contactMessageDraft, currentUser.tenantId, handleDebounceCallback, saveContactMessageDraft]
  );

  const userLableSecondaryText = useMemo(() => {
    if (newHire?.onboardingStatus) {
      return convertStatusToString(newHire.onboardingStatus);
    }
    if (newHire?.joinAt) {
      return `${format(new Date(newHire.joinAt), "yyyy年MM月dd日")} 入社`;
    }
    return "入社日未設定";
  }, [newHire]);

  const contactMessageTemplatesMenuHandling = useContactMessageTemplatesMenuHandling({
    contactMessageDraft,
    setNewMessage,
    saveContactMessageDraft,
    currentMessage: newMessage,
  });

  if (isDataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <StyledContainerBox
      width="100%"
      display="flex"
      flexDirection="column"
      $keyboardHeight={keyboardHeight}
    >
      <StyledHeaderBox p="8px 16px" bgcolor="white" zIndex={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gridGap={16}
        >
          <StyledBoxIconWrapper onClick={onClickBack}>
            <Icon icon="goBack" size="md" color="grey" />
          </StyledBoxIconWrapper>
          <Box flex="1 1 auto">
            <CommonUserIconWithLabel
              name={newHire?.getName() ?? ""}
              size={"small"}
              iconPath={newHire?.profileIconImageUrl ?? ""}
              secondaryText={userLableSecondaryText}
            ></CommonUserIconWithLabel>
          </Box>
          {newHire && (
            <StyledBoxIconWrapper
              onClick={() => newHire && handleOpenSPEmployeeDetailModal(newHire, contactRoom)}
            >
              <Icon icon="info" size="md" color="grey" />
            </StyledBoxIconWrapper>
          )}
        </Box>
      </StyledHeaderBox>

      <Box
        flex="1 1 auto"
        display="flex"
        flexDirection="column"
        gridGap="32px"
        paddingY="40px"
        paddingX="8px"
        overflow="scroll"
        // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
        // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
        {...{ ref: (node: HTMLDivElement) => setMessageListRef(node) }}
      >
        {messageListView}
      </Box>

      {!isRoomTargetEmployeeDeleted && !contactRoom.isUnFollowByEmployee && (
        <Box mt="auto" p="16px" bgcolor="white">
          <TextareaAutosizeAttachableFile
            ref={contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef}
            value={newMessage}
            fullWidth
            placeholder={`${contactRoom.getHonorificRoomName()}へのメッセージを入力してください`}
            onChange={onChange}
            minRows={1}
            maxRows={5}
            maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
            accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
            onChangeFiles={(newFiles: (File | Pick<File, "name">)[]) => {
              setNewMessageFiles(
                newFiles.filter((v): v is File => {
                  return v instanceof File;
                })
              );
            }}
            attachedFiles={newMessageFiles}
            footerLeftActions={
              <IconButton
                icon="document"
                size="sm"
                color="grey"
                borderRadius="regular"
                onClick={contactMessageTemplatesMenuHandling.handleOpen}
              />
            }
            footerButtons={[
              <Button
                key="submit"
                onClick={handleSubmit}
                borderRadius="regular"
                variant="contained"
                color="primary"
                disabled={!newMessage.trim() || isSending}
              >
                送信
              </Button>,
            ]}
          />
        </Box>
      )}
      <ContactMessageTemplateMenu
        menuStartAnchorEl={contactMessageTemplatesMenuHandling.anchorEl}
        closeMenu={contactMessageTemplatesMenuHandling.handleClose}
        reflectMessage={contactMessageTemplatesMenuHandling.handleReflectMessage}
      />
    </StyledContainerBox>
  );
};

const StyledContainerBox = styled(Box)<{ $keyboardHeight: number }>`
  position: absolute;
  top: 0;
  background-color: ${(props) => props.theme.palette.grey[50]};
  height: 100vh ${(props) => (props.$keyboardHeight > 0 ? `-${props.$keyboardHeight}px` : "")};
`;

const StyledPaper = styled(Paper)`
  text-align: center;
  padding: 16px;
  max-width: 480px;
  margin: 0 auto;
`;

const StyledBoxIconWrapper = styled(Box)`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`;

const StyledUnavailableMessagePaper = styled(Paper)`
  width: 80%;
  text-align: center;
  padding: 16px;
  margin: 8px auto 0 auto;
  &.MuiPaper-root {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &.MuiPaper-radius {
    border-radius: 10px;
  }
`;

const StyledHeaderBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;
