import { Box } from "@material-ui/core";
import {
  Employee,
  extractLatestStatus,
  convertStatusToString,
  Department,
  ContactRoom,
} from "@onn/common";
import React, { useMemo, FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { DepartmentNameText } from "~/components/domains/departments";
import { EmployeeDifferenceSatisfaction } from "~/components/domains/employees/EmployeeTable/TableRowItems/EmployeeDifferenceSatisfaction";
import { FILTER_STATUSES } from "~/components/domains/employees/TaskTab/useViewModel";
import {
  Button,
  Divider,
  Icon,
  Loading,
  Paper,
  Typography,
  UserIconWithLabel,
} from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";
import { colors } from "~/config/theme";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask";
import { FilterOptionType } from "~/pages/tasks/TaskListTab/useViewModel";

type Props = {
  employee: Employee;
  departments: Department[];
  shouldDisplayContactRoomButton?: boolean;
};

export const UserSummaryCard: FC<Props> = ({
  employee,
  departments,
  shouldDisplayContactRoomButton = true,
}) => {
  const { data: onboardingTasks, isValidating: isValidatingTasks } = useOnboardingTasksByEmployeeId(
    employee.id
  );
  const isLoading = isValidatingTasks;

  const { contactRooms, contactRoomNotificationCountList } = useContactContext();

  // NOTE: 1st版では1つの入社者につき1つのルームなので find で1つ目の値を用いる
  const contactRoom = useMemo(
    () => contactRooms.find((room) => room.employeeId === employee.id),
    [contactRooms, employee.id]
  );
  const unreadContactMessageCount = useMemo(() => {
    const targetContactRoom = contactRooms.find((room) => room.employeeId === employee.id);
    if (!targetContactRoom) return 0;
    return (
      contactRoomNotificationCountList.find((v) => v.contactRoomId === targetContactRoom.id)
        ?.unreadCount || 0
    );
  }, [contactRooms, contactRoomNotificationCountList, employee.id]);

  return (
    <UserSummaryCardMemo
      isLoading={isLoading}
      shouldDisplayContactRoomButton={shouldDisplayContactRoomButton}
      employee={employee}
      contactRoom={contactRoom}
      unreadContactMessageCount={unreadContactMessageCount}
      departments={departments}
      expiredTaskCount={
        onboardingTasks
          ? onboardingTasks?.filter(
              (task) =>
                task.status !== "COMPLETED" && employee && task.isExpiredByEmployee(employee)
            ).length
          : 0
      }
      notStartedTaskCount={
        onboardingTasks
          ? onboardingTasks?.filter(
              (task) =>
                task.status !== "COMPLETED" && employee && !task.isExpiredByEmployee(employee)
            ).length
          : 0
      }
    />
  );
};

const UserSummaryCardMemo = React.memo<{
  isLoading: boolean;
  shouldDisplayContactRoomButton: boolean;
  employee: Employee;
  contactRoom?: ContactRoom;
  unreadContactMessageCount: number;
  departments: Department[];
  expiredTaskCount: number;
  notStartedTaskCount: number;
}>(
  ({
    isLoading,
    employee,
    contactRoom,
    shouldDisplayContactRoomButton,
    unreadContactMessageCount,
    departments,
    expiredTaskCount,
    notStartedTaskCount,
  }) => {
    const taskOptions: FilterOptionType[] = [
      {
        title: "期限切れ",
        totalCount: expiredTaskCount,
        status: FILTER_STATUSES.EXPIRED,
      },
      {
        title: "未着手",
        totalCount: notStartedTaskCount,
        status: FILTER_STATUSES.NOT_STARTED,
      },
    ];

    return (
      <StyledPaper onClick={(e) => e.stopPropagation()}>
        {isLoading ? (
          <Loading size="small" />
        ) : (
          <Box width="288px">
            <Box display="flex" justifyContent="space-between">
              <UserIconWithLabel
                iconPath={employee.profileIconImageUrl || ""}
                name={employee.getName()}
                secondaryText={convertStatusToString(
                  extractLatestStatus(employee.onboardingStatuses || []),
                  employee.joinAt
                )}
                iconBadgeType={employee.slackUserId ? "slack" : undefined}
              />

              {contactRoom && shouldDisplayContactRoomButton && (
                <Box height="40px">
                  <Button color="primary" variant="text" borderRadius="regular">
                    <Link to={`/contact_rooms?contactRoomId=${contactRoom.id}`}>
                      <Badge
                        badgeContent={
                          unreadContactMessageCount > 0 ? (
                            <Typography bold variant="caption">
                              {unreadContactMessageCount}
                            </Typography>
                          ) : undefined
                        }
                        color="secondary"
                      >
                        <Icon icon="paperAirplane" color="primary" size="md" />
                      </Badge>
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>

            <Box my={2}>
              {/* 所属なしの場合は何も表示しない */}
              {employee.departmentIds.length !== 0 && (
                <DepartmentNameText
                  departments={departments}
                  targetDepartmentIds={employee.departmentIds}
                />
              )}
            </Box>

            <Box bgcolor={colors.grey[50]} display="flex" py={3}>
              <Box display="flex" width="33%" alignItems="center" flexDirection="column">
                <EmployeeDifferenceSatisfaction
                  key={`score-${employee.id}`}
                  employeeId={employee.id}
                  scoreVariant="h3"
                  arrowVariant="h4"
                />
                <Typography variant="caption">スコア</Typography>
              </Box>

              <Divider orientation="vertical" />

              {taskOptions.map((option, i) => (
                <Box key={`TaskBox__${i}`} width="33%">
                  <StyledTaskLink
                    to={`/employee/${employee.id}?selectedStatus=${option.status}#task`}
                  >
                    <Typography variant="h3" bold>
                      {`${option.totalCount}`}
                    </Typography>
                    <Typography variant="caption">{option.title}</Typography>
                  </StyledTaskLink>
                </Box>
              ))}
            </Box>

            <StyledDetailLink to={`/employee/${employee.id}`}>
              <Button color="primary" variant="text" borderRadius="regular" fullWidth>
                入社者詳細を見る
              </Button>
            </StyledDetailLink>
          </Box>
        )}
      </StyledPaper>
    );
  }
);

const StyledPaper = styled(Paper)`
  width: 320px;
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const StyledTaskLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: black;
`;

const StyledDetailLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: 16px;
`;
