import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { ContactRoomRepository } from "~/infrastructure/api/contactRoomRepository";
import { FindUnFollowedContactRoomsUseCase } from "~/service/usecases/contactRoom/FindUnFollowedContactRoomsUseCase";

const useCase = new FindUnFollowedContactRoomsUseCase(new ContactRoomRepository());

/**
 * LINEユーザーによってfollow解除されたコンタクトルームの一覧を取得する
 */
export const useUnFollowedContactRooms = () => {
  const { currentUser } = useCurrentUser();
  const keys = ["un-followed-contact-rooms", currentUser.tenantId];

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    useCase.execute(currentUser, tenantId)
  );
};
