import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { useSignOut } from "~/hooks/employee";

export const LogoutButton: FC = () => {
  const navigate = useNavigate();
  const { signOut } = useSignOut();

  const handleClickLogoutButton = useCallback(async () => {
    await signOut();
    navigate("/signin");
  }, [navigate, signOut]);

  return (
    <>
      <StyledBox width="100%" onClick={handleClickLogoutButton}>
        <Typography variant="body2" bold={true} color="secondary" noWrap>
          ログアウト
        </Typography>
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)`
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;
