import { Employee, OnboardingTaskType } from "@onn/common";

/**
 * アクセス可能かどうかを判定する関数を提供するhooks
 */
export const useGetIsAccessibleEmployeePage = () => {
  /**
   * アクセス可能かどうかを判定する関数を提供するhooks
   */
  const getIsAccessibleEmployeePage = (
    currentUser: Employee,
    newHire: Employee,
    onboardingTasks: OnboardingTaskType[],
    isEditable: (newHire: Employee) => boolean
  ) => {
    if (isEditable(newHire)) return true;
    if (newHire.mentorUserId === currentUser.id) return true;
    if (newHire.supportMemberEmployeeIds?.includes(currentUser.id)) return true;

    return onboardingTasks.some((onboardingTask) => {
      return onboardingTask.assigneeIds.includes(currentUser.id);
    });
  };

  return { getIsAccessibleEmployeePage };
};
