import { OnboardingMessageTask } from "@onn/common";
import { useState, useCallback } from "react";
import { useSWRConfig } from "swr";

import { generateUseOnboardingTask } from "../../useOnboardingTask";
import { generateUseOnboardingTasksKeys } from "../../useOnboardingTasks";
import { generateUseOnboardingTasksByEmployeeId } from "../../useOnboardingTasksByEmployeeId";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { CancelMessageRequestUseCase } from "~/service/usecases/onboardingTask/CancelMessageRequestUseCase";
import { captureException } from "~/util";

const cancelMessageRequestUseCase = new CancelMessageRequestUseCase();

/**
 * messageタスクのリクエストを取り消す関数を提供するhooks
 * @returns isLoading 処理中かどうか
 * @returns cancelMessageRequest 関数
 */
export const useCancelMessageRequest = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useSWRConfig();

  /**
   * messageタスクのリクエストを取り消す関数
   * @param {string} taskId メッセージタスクのid
   * @param {string} contentId メッセージタスクのうち依頼を取り消すコンテンツのid
   */
  const cancelMessageRequest = useCallback(
    async (taskId: string, contentId: string): Promise<OnboardingMessageTask | void> => {
      setIsLoading(true);
      return await cancelMessageRequestUseCase
        .execute(taskId, contentId, currentUser)
        .then((onboardingMessageTask: OnboardingMessageTask) => {
          enqueueSnackbar("メッセージの記入依頼を取り消しました", { variant: "success" });
          mutate(generateUseOnboardingTask(taskId));
          mutate(generateUseOnboardingTasksKeys(onboardingMessageTask.tenantId));
          mutate(generateUseOnboardingTasksByEmployeeId(onboardingMessageTask.employeeId));
          return onboardingMessageTask;
        })
        .catch((e) => {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
          captureException({
            error: e as Error,
            tags: {
              type: "useCancelMessageRequest:cancelMessageRequest",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar, mutate]
  );

  return { isLoading, cancelMessageRequest };
};
