import { Box } from "@material-ui/core";
import { Employee, OnboardingExperienceTaskType, SlackUser } from "@onn/common";
import { isEmpty } from "lodash";
import React, { forwardRef, MouseEvent } from "react";
import styled from "styled-components";

import { ProcessItemTemplate } from "../ProcessItemTemplate";

import { ProcessTaskItem } from "./Items/ProcessTaskItem";

import { Typography, DnDDraggable, DnDDroppable } from "~/components/uiParts";

type Props = {
  tasks: OnboardingExperienceTaskType[];
  assigneeRole: OnboardingExperienceTaskType["assigneeRole"];
  assigneeEmployees: Employee[];
  slackUsers: SlackUser[];
  allEmployeesWithDeleted: Employee[];
  editable: boolean;
  onClickEditTaskButton: (task: OnboardingExperienceTaskType) => void;
  onClickDeleteTaskButton: (task: OnboardingExperienceTaskType) => void;
  onClickAddTaskButton: () => void;
};

export const OnboardingExperienceProcessItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      tasks,
      assigneeRole,
      assigneeEmployees,
      slackUsers,
      allEmployeesWithDeleted,
      editable,
      onClickEditTaskButton,
      onClickDeleteTaskButton,
      onClickAddTaskButton,
    },
    ref
  ) => {
    /**
     * DnD操作時、親要素で指定している画面スクロールイベントをstop
     */
    const stopParentEvent = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
    };

    const tasksElement: JSX.Element = (
      <>
        <DnDDroppable
          droppableId={(tasks[0] as (typeof tasks)[number]).id}
          isDropDisabled={!editable}
        >
          {isEmpty(tasks) ? (
            // drop領域を確保するための要素。heightがないとdrag時に高さが調整されない
            <Box style={{ height: "1px" }}></Box>
          ) : (
            <Box onMouseDown={stopParentEvent}>
              {tasks.map((task) => {
                return (
                  <DnDDraggable
                    key={task.id}
                    draggableId={task.id}
                    index={task.index}
                    isDragDisabled={!editable}
                  >
                    <ProcessTaskItem
                      title={task.title}
                      editable={editable}
                      onClickEditButton={() => onClickEditTaskButton(task)}
                      onClickDeleteButton={() => onClickDeleteTaskButton(task)}
                      timing={task.dueDate.getDisplayTiming()}
                      taskType={task.type}
                    />
                  </DnDDraggable>
                );
              })}
            </Box>
          )}
        </DnDDroppable>
        {editable && (
          <StyledTypography
            $isEmpty={isEmpty(tasks)}
            variant="body1"
            color="primary"
            onClick={onClickAddTaskButton}
          >
            + タスクを追加
          </StyledTypography>
        )}
      </>
    );
    return (
      <ProcessItemTemplate
        tasks={tasks}
        assigneeRole={assigneeRole}
        assigneeEmployees={assigneeEmployees}
        slackUsers={slackUsers}
        allEmployeesWithDeleted={allEmployeesWithDeleted}
        editable={editable}
        menuItems={[]}
        tasksElement={tasksElement}
        ref={ref}
      />
    );
  }
);

const StyledTypography = styled(Typography)<{ $isEmpty: boolean }>`
  &.MuiTypography-root {
    margin-left: 24px;
    cursor: pointer;
    margin-top: ${({ $isEmpty }) => $isEmpty && "-24px"};
  }
`;
