import { MidCarrierPropertyForInvite } from "@onn/common";
import { isEmpty, uniqBy } from "lodash";
import { useCallback } from "react";

import { useAllNewcomers, useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";

export const useInviteNewHires = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();

  const inviteNewHires = useCallback(
    async (userDataArray: MidCarrierPropertyForInvite[]): Promise<void> => {
      const filteredUserDataArray = uniqBy(
        userDataArray.filter((userData) => userData.email !== ""),
        "email"
      );

      try {
        const { createdNewHires } = await AccountUseCase.newHireAccountCreate(
          filteredUserDataArray,
          currentUser.tenantId,
          currentUser.slackUserId || ""
        );

        if (!isEmpty(createdNewHires))
          enqueueSnackbar(`${createdNewHires.length}名の入社者が追加されました`, {
            variant: "success",
          });

        mutateAllNewcomers();
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
          throw new Error(e.message);
        }
      }
    },
    [currentUser.slackUserId, currentUser.tenantId, enqueueSnackbar, mutateAllNewcomers]
  );

  return { inviteNewHires };
};
