import useSWR from "swr";

import { apiClient } from "~/libs";

export const useCurrentPortalAccessibleTenants = (currentUid?: string) => {
  return useSWR(
    currentUid ? (["/get_portal_accessible_tenants"] as const) : null,
    async ([endpoint]) => {
      return apiClient.get(endpoint).then((res) => res.data.tenants);
    }
  );
};
