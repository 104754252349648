import { AllContactRoom, ContactRoomWithMessagesAndTarget } from "@onn/common";
import { useCallback } from "react";

import { SearchCondition } from "~/hooks/contactRoom/useIsCompletedFilterFormState";

/**
 * @deprecated
 * contactProviderを削除する際に削除する
 * 対応済みかどうかでコンタクトルームを絞り込む関数を返すhooks
 */
export const useFilterContactRoomsWithIsCompleted = () => {
  const filterContactRoomsWithIsCompleted = useCallback(
    (
      contactRooms: ContactRoomWithMessagesAndTarget[],
      condition: SearchCondition
    ): ContactRoomWithMessagesAndTarget[] => {
      if (condition === "all") return contactRooms;
      const conditionBoolean = condition === "isCompleted";
      return contactRooms.filter((room) => room.isCompleted === conditionBoolean);
    },
    []
  );

  return {
    filterContactRoomsWithIsCompleted,
  };
};

/**
 * contactProviderの解体に伴い、contactRoomの型がContactRoomWithMessagesAndTargetからAllContactRoomに変わったのでV2を作成をしています
 * 対応済みかどうかでコンタクトルームを絞り込む関数を返すhooks
 */
export const useFilterContactRoomsWithIsCompletedV2 = () => {
  const filterContactRoomsWithIsCompleted = useCallback(
    (contactRooms: AllContactRoom[], condition: SearchCondition): AllContactRoom[] => {
      if (condition === "all") return contactRooms;
      const conditionBoolean = condition === "isCompleted";
      return contactRooms.filter((room) => room.isCompleted === conditionBoolean);
    },
    []
  );

  return {
    filterContactRoomsWithIsCompleted,
  };
};
