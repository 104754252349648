import { Box } from "@material-ui/core";
import {
  DifferenceDate,
  OnboardingExperienceTask,
  OnboardingExperienceTaskType,
  OnboardingExperienceTaskFactory,
  displayTaskTypeMap,
  Employee,
  OnboardingExperienceMessageTask,
} from "@onn/common";
import React, { useCallback, useMemo, useState, FC, useEffect } from "react";
import styled from "styled-components";

import { MessageTask } from "./MessageTask";
import { SimpleTask } from "./SimpleTask";

import { Button, Icon, Modal, SelectForm, Tooltip, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnboardingExperienceTaskTemplates } from "~/hooks/onboardingExperienceTaskTemplate";
import { useSnackbar } from "~/hooks/shared";
import { useSlackUsers } from "~/hooks/slackUser";
import logoSquare from "~/images/logo-square.svg";

type Props = {
  open: boolean;
  onCancel: () => void;
  onboardingExperienceId: string;
  task?: OnboardingExperienceTaskType;
  associateTasks?: OnboardingExperienceTaskType[];
  editable?: boolean;
  onUpsertTask: (newTask: OnboardingExperienceTaskType) => void;
  onDeleteTask: (task: OnboardingExperienceTaskType) => void;
  allEmployeesWithDeleted: Employee[];
};

const newTemplateOptionValue = "new_template";

const getDefaultSlackUsers = (
  task: OnboardingExperienceTaskType,
  allEmployees: Employee[],
  emails: string[]
) => {
  const assigneeEmployees = allEmployees.filter((employee) =>
    task.assigneeIds.includes(employee.id)
  );

  return [
    ...assigneeEmployees.flatMap((assigneeEmployee) => {
      const email = emails.find((email) => email === assigneeEmployee.email);
      return email || [];
    }),
    ...(task.emailsWithoutOnnAccount ? task.emailsWithoutOnnAccount : []),
  ];
};

export const EditOnboardingExperienceTaskModal: FC<Props> = ({
  open,
  onCancel,
  task,
  associateTasks = [],
  onboardingExperienceId,
  editable = true,
  onUpsertTask,
  onDeleteTask,
  allEmployeesWithDeleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { slackUsers } = useSlackUsers();
  const { currentUser } = useCurrentUser();
  // NOTE: 1stリリースではテンプレートはメッセージのみなのでフィルタしない
  const { data: templates = [] } = useOnboardingExperienceTaskTemplates(currentUser.tenantId);

  const initTask: Omit<
    OnboardingExperienceTaskType,
    "id" | "type" | "memos" | "isRequired" | "status" | "updateForUpdatingTemplate"
  > = useMemo(() => {
    return {
      onboardingExperienceId: onboardingExperienceId,
      title: "",
      body: "",
      filePaths: [],
      assigneeRole: "NEW_HIRE",
      assigneeIds: [],
      deliveryDate: new DifferenceDate({ amount: 7, unit: "DAY", direction: "BEFORE" }),
      dueDate: new DifferenceDate({ deliveryTime: 9 }),
      index: 0,
      tenantId: currentUser.tenantId,
    };
  }, [currentUser.tenantId, onboardingExperienceId]);

  const [newTask, setNewTask] = useState<OnboardingExperienceTaskType>(
    OnboardingExperienceTaskFactory.createOnboardingTask({
      type: "SIMPLE_TASK",
      ...(task ?? initTask),
      memos: [],
      isRequired: true,
      status: "NOT_STARTED",
      emailsWithoutOnnAccount: task?.emailsWithoutOnnAccount || [],
    })
  );

  const [invalid, setInvalid] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState(task && task.id ? false : true);

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  useEffect(() => {
    setSelectedEmails(
      task
        ? getDefaultSlackUsers(
            task,
            allEmployeesWithDeleted,
            allEmployeesWithDeleted.map((v) => v.email)
          )
        : []
    );
  }, [allEmployeesWithDeleted, task]);

  const isNoChange = useMemo(() => {
    return (
      newTask.assigneeRole === task?.assigneeRole &&
      newTask.body === task?.body &&
      newTask.dueDate === task?.dueDate &&
      newTask.title === task?.title &&
      newTask.deliveryDate === task?.deliveryDate
    );
  }, [
    newTask.assigneeRole,
    newTask.body,
    newTask.dueDate,
    newTask.title,
    newTask.deliveryDate,
    task,
  ]);

  const handleChangeSettings = useCallback(
    <T extends OnboardingExperienceTaskType>(newObject: Partial<T>) => {
      setNewTask((prev: OnboardingExperienceTaskType) =>
        OnboardingExperienceTaskFactory.createOnboardingTask({ ...prev, ...newObject })
      );
    },
    []
  );

  const handleSubmit = useCallback(
    async (isContinue: boolean) => {
      const { existedEmployees, selectedEmailsWithoutOnnAccount } = selectedEmails.reduce(
        (acc, email) => {
          const slackUser = slackUsers.find((v) => v.email === email);
          // NOTE: Slack上にユーザーの情報が存在していた時には新規招待せずに紐づいたユーザーをアサインする
          const existedEmployee = allEmployeesWithDeleted.find((v) => {
            if (v.email === email) return true;
            if (!slackUser) return false;
            return v.slackUserId === slackUser.slackUserId;
          });
          if (!existedEmployee) {
            acc.selectedEmailsWithoutOnnAccount = [...acc.selectedEmailsWithoutOnnAccount, email];
          } else {
            acc.existedEmployees = [...acc.existedEmployees, existedEmployee];
          }
          return acc;
        },
        {
          existedEmployees: [] as Employee[],
          selectedEmailsWithoutOnnAccount: [] as string[],
        }
      );

      if (existedEmployees.some((existedEmployee) => existedEmployee.deleted)) {
        return enqueueSnackbar("削除されたユーザーにタスクを担当させることはできません", {
          variant: "error",
        });
      }

      if (newTask.type === "MESSAGE_TASK") {
        if (isCreate || task?.type !== "MESSAGE_TASK") {
          // 新規作成またはSIMPLE_TASKからMESSAGE_TASKに変更したとき
          // ロール毎にタスクを発行する
          newTask.requestedRoles.forEach((role, index) =>
            onUpsertTask(
              OnboardingExperienceTaskFactory.createOnboardingTask({
                ...newTask,
                // 2つ目以降はidを再発行して被らないようにする
                id: !index ? newTask.id : undefined,
                assigneeRole: role,
                assigneeIds: existedEmployees.map((v) => v.id),
                emailsWithoutOnnAccount: selectedEmailsWithoutOnnAccount,
              })
            )
          );
        } else {
          // 同じassociationIdのタスクを更新・作成する
          newTask.requestedRoles.forEach((role) => {
            const targetTask = associateTasks.find((task) => task.assigneeRole === role);
            onUpsertTask(
              OnboardingExperienceTaskFactory.createOnboardingTask({
                ...task,
                ...targetTask,
                ...newTask,
                id: targetTask?.id,
                assigneeRole: role,
                assigneeIds: existedEmployees.map((v) => v.id),
                emailsWithoutOnnAccount: selectedEmailsWithoutOnnAccount,
              })
            );
          });

          // ロールの選択数が減った場合は該当タスクを削除する
          associateTasks.forEach((task) => {
            if (newTask.requestedRoles.every((role) => role !== task.assigneeRole)) {
              onDeleteTask(task);
            }
          });
        }
      } else if (task?.type === "MESSAGE_TASK" && !isCreate) {
        // MESSAGE_TASKからSIMPLE_TASKに変更した時
        // 同じassociationIdのタスクを削除する
        associateTasks.forEach((task) => onDeleteTask(task));

        onUpsertTask(
          OnboardingExperienceTaskFactory.createOnboardingTask({
            ...task,
            ...newTask,
            assigneeIds: existedEmployees.map((v) => v.id),
            emailsWithoutOnnAccount: selectedEmailsWithoutOnnAccount,
          })
        );
      } else {
        onUpsertTask(
          OnboardingExperienceTaskFactory.createOnboardingTask({
            ...task,
            ...newTask,
            assigneeIds: existedEmployees.map((v) => v.id),
            emailsWithoutOnnAccount: selectedEmailsWithoutOnnAccount,
          })
        );
      }

      // 続けて更新しない時はModalを閉じる
      if (!isContinue) {
        return onCancel();
      }

      // 続けて更新する時は一部設定値を引き継いでstateを更新する
      switch (newTask.type) {
        case "MESSAGE_TASK": {
          const nextTask = {
            ...newTask,
            id: undefined,
            title: "",
            body: "",
            filePaths: [],
            index: (newTask?.index || 0) + 1,
            memos: [],
            assigneeIds: existedEmployees.map((v) => v.id),
            slackUsersWithoutOnnAccount: selectedEmailsWithoutOnnAccount,
            templateId: "",
          };
          setNewTask(OnboardingExperienceTaskFactory.createOnboardingTask(nextTask));
          break;
        }
        case "SIMPLE_TASK":
        default: {
          setNewTask(
            OnboardingExperienceTaskFactory.createOnboardingTask({
              ...newTask,
              id: undefined,
              title: "",
              body: "",
              filePaths: [],
              index: (newTask?.index || 0) + 1,
              memos: [],
              assigneeIds: existedEmployees.map((v) => v.id),
              emailsWithoutOnnAccount: selectedEmailsWithoutOnnAccount,
            })
          );
        }
      }

      setIsCreate(true);

      enqueueSnackbar("タスクを作成しました。", {
        variant: "success",
      });
    },
    [
      selectedEmails,
      newTask,
      task,
      isCreate,
      enqueueSnackbar,
      slackUsers,
      allEmployeesWithDeleted,
      onUpsertTask,
      associateTasks,
      onDeleteTask,
      onCancel,
    ]
  );

  const body = useMemo(() => {
    if (newTask.type === "MESSAGE_TASK") {
      return (
        <MessageTask
          onboardingExperienceId={onboardingExperienceId}
          newTask={newTask}
          editable={editable}
          setInvalid={setInvalid}
          setNewTask={setNewTask}
        />
      );
    } else if (newTask.type === "SIMPLE_TASK") {
      return (
        <SimpleTask
          onboardingExperienceId={onboardingExperienceId}
          newTask={newTask}
          editable={editable}
          selectedEmails={selectedEmails}
          setInvalid={setInvalid}
          setNewTask={setNewTask}
          setSelectedEmails={setSelectedEmails}
        />
      );
    }
  }, [editable, newTask, onboardingExperienceId, selectedEmails]);

  const content = useMemo(
    () => (
      <>
        <Box display="flex" gridGap="12px" alignItems="center">
          <Typography variant="body2" bold>
            タイプ
          </Typography>
          <Tooltip
            title="タスクの目的に適したタイプを選択することができます。「メンバーメッセージ（寄せ書き）」はあらかじめ作成したテンプレートから選択してください。"
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box my="16px" display="flex" gridGap="24px">
          <StyledSelectForm
            selected={newTask.type}
            onChange={(e) =>
              handleChangeSettings({
                // タイプを変更するときは初期化する
                ...initTask,
                type: e.target.value as OnboardingExperienceTask["type"],
              })
            }
            menuItems={Object.entries(displayTaskTypeMap).map((v) => {
              return { value: v[0], name: v[1] };
            })}
            readOnly={!editable}
          />
          {newTask instanceof OnboardingExperienceMessageTask &&
            newTask.type === "MESSAGE_TASK" && (
              <StyledMessageSelectForm
                selected={newTask.templateId}
                onChange={(e) => {
                  // NOTE: 新規作成ボタンのクリック動作をハンドリング
                  if (e.target.value === newTemplateOptionValue) {
                    window.open("/tools/templates/new", "_blank");
                  }

                  const template = templates.find((template) => template.id === e.target.value);
                  if (!template) return;

                  handleChangeSettings<OnboardingExperienceMessageTask>({
                    templateId: template.id,
                    title: template.title,
                    body: template.description,
                    assigneeIds: [],
                    emailsWithoutOnnAccount: [],
                  });
                }}
                menuItems={[
                  {
                    value: newTemplateOptionValue,
                    name: newTemplateOptionValue,
                    customItemElement: (
                      <StyledTypography variant="body2" color="textSecondary">
                        新規作成
                      </StyledTypography>
                    ),
                  },
                  ...templates.map((template) => {
                    return {
                      value: template.id,
                      name: template.title,
                      customItemElement: (
                        <StyledTypography variant="body2" color="textSecondary">
                          {template.title}
                          {template.isDefault && (
                            <StyledIcon height={14} width={14} src={logoSquare} alt="Onnアイコン" />
                          )}
                        </StyledTypography>
                      ),
                    };
                  }),
                ]}
                readOnly={!editable}
              />
            )}
        </Box>
        {body}
      </>
    ),
    [newTask, editable, templates, body, handleChangeSettings, initTask]
  );

  const footer = useMemo(
    () => (
      <Box display="flex" justifyContent="flex-end" gridGap="8px">
        <Button borderRadius="regular" variant="text" color="default" onClick={onCancel}>
          キャンセル
        </Button>
        <Button
          borderRadius="circle"
          variant="outlined"
          color="primary"
          onClick={() => handleSubmit(true)}
          disabled={invalid || !editable || isNoChange}
        >
          続けて追加
        </Button>
        <Button
          borderRadius="circle"
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(false)}
          disabled={invalid || !editable}
        >
          {task ? "保存" : "追加"}
        </Button>
      </Box>
    ),
    [onCancel, invalid, editable, isNoChange, task, handleSubmit]
  );

  return (
    <Modal
      title={`タスク${task ? "編集" : "追加"}`}
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
      disableBackdropModal
    />
  );
};

const StyledSelectForm = styled(SelectForm)`
  width: 240px;
`;

const StyledMessageSelectForm = styled(SelectForm)`
  flex: 1;
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.img`
  margin-left: 8px;
`;
