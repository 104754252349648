import { QuestionItem } from "./QuestionItem";

export enum QuestionMode {
  DEFAULT = "DEFAULT",
  ADDITION = "ADDITION",
}

export enum ItemType {
  SMALL_RATING = "SMALL_RATING",
  MEDIUM_RATING = "MEDIUM_RATING",
  LARGE_RATING = "LARGE_RATING",
  TEXT = "TEXT",
  SERIAL_NUMBER_TEXT = "SERIAL_NUMBER_TEXT",
  TITLE_TEXT = "TITLE_TEXT",
  CHECK_BOX = "CHECK_BOX",
  TITLE = "TITLE",
  CUSTOM_RADIO = "CUSTOM_RADIO",
  TITLE_CUSTOM_RADIO = "TITLE_CUSTOM_RADIO",
  TITLE_ICON_CUSTOM_RADIO = "TITLE_ICON_CUSTOM_RADIO",
  NPS = "NPS", // NPS(10段階評価)
}

export class Question {
  title: string;
  name: string; // 質問データで一意になる内部用の情報（Key情報のようなもの）
  type: ItemType;
  mode: QuestionMode; // MasterTemplateに用意してあるデフォルトのものか、ユーザが追加した質問か
  required: boolean;
  hrOnly?: boolean; // NOTE: HRはADMINにrenameされたが、アンケートは近いうちに再構成されるのでそのままにする
  placeholder?: string;
  questions: Question[]; // 質問リスト＊質問が入れ子になる場合、使用するフィールド
  questionItems: QuestionItem[]; // チェックボックスやラジオボタンの場合、選択項目
  section: number; // 質問セクション番号
  caption?: string | string[];
  showAppendText?: boolean; // 回答を共有する対象となるバディさん＋管理者数を計算する結果を表示するかどうか
  constructor(init: {
    title: string;
    name: string;
    type: ItemType;
    mode: QuestionMode;
    required: boolean;
    hrOnly?: boolean;
    placeholder?: string;
    questions: Question[];
    questionItems: QuestionItem[];
    section: number;
    caption?: string | string[];
    showAppendText?: boolean;
  }) {
    this.title = init.title;
    this.name = init.name;
    this.type = init.type;
    this.mode = init.mode;
    this.required = init.required;
    this.hrOnly = init.hrOnly;
    this.placeholder = init.placeholder;
    this.questions = init.questions;
    this.questionItems = init.questionItems;
    this.section = init.section;
    this.caption = init.caption;
    this.showAppendText = init.showAppendText;
  }

  static plainToInstance(init: DeepExcludeMethods<Question>): Question {
    return new Question({
      ...init,
      questions: init.questions.map((q) => Question.plainToInstance(q)),
      questionItems: init.questionItems.map((qi) => QuestionItem.plainToInstance(qi)),
    });
  }
}
