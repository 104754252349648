import { OnboardingExperienceTaskTemplate } from "@onn/common";
import { OnboardingExperienceTaskTemplateType } from "@onn/common/domain/OnboardingExperienceTaskTemplate/OnboardingExperienceTaskTemplateFactory/OnboardingExperienceTaskTemplateFactory";
import { WriteBatch } from "firebase/firestore";

import { OnboardingExperienceTaskTemplateRepository } from "~/infrastructure/api/onboardingExperienceTaskTemplateRepository";

export interface IOnboardingExperienceTaskTemplateRepository {
  create(onboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate): Promise<void>;
  insertUpdateTemplateInBatch(
    batch: WriteBatch,
    onboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate
  ): Promise<void>;
  insertDeleteByIdInBatch(batch: WriteBatch, id: string): Promise<void>;
  findAll(tenantId: string): Promise<OnboardingExperienceTaskTemplate[]>;
  findById(id: string): Promise<OnboardingExperienceTaskTemplateType>;
}

export const factory = {
  onboardingExperienceTaskTemplateRepository: (): IOnboardingExperienceTaskTemplateRepository => {
    return new OnboardingExperienceTaskTemplateRepository();
  },
};
