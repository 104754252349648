import { Employee, SurveyAlert, SurveyTransaction, TransactionStatus } from "@onn/common";

import { factory as employeeFactory } from "~/service/repository/iEmployeeRepository";
const employeeRepository = employeeFactory.employeeRepository();

import { factory } from "~/service/repository/iSurveyAlertRepository";
import { factory as transactionFactory } from "~/service/repository/iTransactionRepository";

const surveyAlertRepository = factory.surveyAlertRepository();
const surveyTransactionRepository = transactionFactory.surveyTransactionRepository();

export type CustomOnboardingSurveysForAlert = SurveyTransaction & {
  surveyAlert: SurveyAlert;
  score: number;
  hasHrOnly: boolean;
  newHire: Employee;
};

/**
 * アラート対象(scoreが2以下もしくは、人事向け回答を持つ)の表示用データを配信日順に返すUseCase
 */
export class FindUncompletedCustomSurveyAlertsUseCase {
  /**
   * アラート対象(scoreが2以下もしくは、人事向け回答を持つ)の表示用データを配信日順に返すUseCase
   * @param {string} tenantId
   * @returns SurveyAlert[]
   */
  async execute(tenantId: string): Promise<CustomOnboardingSurveysForAlert[]> {
    const surveyAlerts = await surveyAlertRepository.whereByIsCompleted(tenantId, false);
    const completedSurveyTransactions =
      await surveyTransactionRepository.whereByStatusAndTenantIdAndSortedTimestamp(
        TransactionStatus.DONE,
        tenantId,
        "sendAt"
      );
    const newHires = await employeeRepository.findAllWithDeleted();

    const results = surveyAlerts
      .map((surveyAlert: SurveyAlert) =>
        this.generateObject(surveyAlert, completedSurveyTransactions, newHires)
      )
      .filter(
        (
          onboardingSurveysForAlert: CustomOnboardingSurveysForAlert | undefined
        ): onboardingSurveysForAlert is Exclude<typeof onboardingSurveysForAlert, undefined> =>
          onboardingSurveysForAlert !== undefined
      )
      .sort((a, b) => (a.sendAt > b.sendAt ? 1 : -1));

    return results;
  }

  private generateObject(
    surveyAlert: SurveyAlert,
    completedSurveyTransactions: SurveyTransaction[],
    newHires: Employee[]
  ): CustomOnboardingSurveysForAlert | undefined {
    // CustomOnboardingSurveysForAlertに必要な情報が不足している場合は、undefinedとする
    const surveyTransaction = completedSurveyTransactions.find(
      (transaction) => surveyAlert.transactionId === transaction.id
    );
    if (!surveyTransaction) {
      return undefined;
    }
    const newHire = newHires.find((v) => v.id === surveyTransaction.employeeId);
    if (!newHire) {
      return undefined;
    }

    const customOnboardingSurveysForAlert = surveyTransaction as CustomOnboardingSurveysForAlert;

    const score = surveyTransaction.onboardingSurveyScore();
    if (!score) {
      return undefined;
    }
    customOnboardingSurveysForAlert.surveyAlert = surveyAlert;
    customOnboardingSurveysForAlert.score = score;
    customOnboardingSurveysForAlert.hasHrOnly = surveyTransaction.hasAnswerForHR();
    customOnboardingSurveysForAlert.newHire = newHire;

    return customOnboardingSurveysForAlert;
  }
}
