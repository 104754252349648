import { Box, Button } from "@material-ui/core";
import { OnboardingExperienceGeneralTask, Employee, SlackUser } from "@onn/common";
import React, { forwardRef } from "react";
import styled from "styled-components";

import { ProcessItemTemplate } from "../ProcessItemTemplate";

import { ProcessTaskItem } from "./Items/ProcessTaskItem";

import { Icon, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  generalTasks: OnboardingExperienceGeneralTask[];
  assigneeEmployees: Employee[];
  slackUsers: SlackUser[];
  allEmployeesWithDeleted: Employee[];
  editable: boolean;
  onClickViewTaskButton: (task: OnboardingExperienceGeneralTask) => void;
  onClickChangeAssigneeButton: () => void;
};

export const OnboardingExperienceGeneralProcessItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      generalTasks,
      assigneeEmployees,
      slackUsers,
      allEmployeesWithDeleted,
      editable,
      onClickViewTaskButton,
      onClickChangeAssigneeButton,
    },
    ref
  ) => {
    const tooltipText =
      "共通タスクとは全ての受け入れ体験共通で実行されるOnnのデフォルト機能です。入社者の招待をするとタスクの配信が対象者に対して自動的に行われます。" +
      "なお、管理者タスクのみ、担当者を「HR」または「特定のメンバーを指定」のいずれかに変更することが可能です。この担当者変更はOnnの導入範囲全体に対して共通で適応されます。";
    const menuItems = [
      {
        title: "担当者変更",
        onClick: () => onClickChangeAssigneeButton(),
      },
    ];
    const taskElement: JSX.Element = (
      <>
        <Box>
          {generalTasks.map((task: OnboardingExperienceGeneralTask, index: number) => {
            return (
              <ProcessTaskItem
                key={index}
                title={task.title}
                timing={task.dueDate.getDisplayTiming()}
                onClickEditButton={() => onClickViewTaskButton(task)}
              />
            );
          })}
        </Box>
        <StyledBox>
          <StyledTypography variant="caption">
            ※ Onnのデフォルト機能のため編集や削除はできません
            <br />※ 全ての受け入れ体験共通で適応されています
          </StyledTypography>
          <Tooltip title={tooltipText} placement="top-start">
            <StyledButton variant="outlined" disableRipple={true}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">共通タスク</Typography>
                <Icon icon="help" size="sm" color="grey" />
              </Box>
            </StyledButton>
          </Tooltip>
        </StyledBox>
      </>
    );
    return (
      <ProcessItemTemplate
        tasks={generalTasks}
        assigneeRole={(generalTasks[0] as (typeof generalTasks)[number]).assigneeRole}
        assigneeEmployees={assigneeEmployees}
        slackUsers={slackUsers}
        allEmployeesWithDeleted={allEmployeesWithDeleted}
        // 全て同じassigneeRoleとなるため1つ目のものをみる
        editable={
          (generalTasks[0] as (typeof generalTasks)[number]).assigneeRole == "NEW_HIRE"
            ? false
            : editable
        }
        menuItems={menuItems}
        tasksElement={taskElement}
        ref={ref}
      />
    );
  }
);

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const StyledButton = styled(Button)`
  width: 122px;

  &.MuiButtonBase-root {
    background-color: white;
    color: ${(props) => props.theme.palette.grey[300]};
    border: 1px solid ${(props) => props.theme.palette.grey[200]};
    border-radius: 8px;
    cursor: default;
  }
`;
