import { Employee, OnboardingExperienceTaskTemplate } from "@onn/common";

import { factory as employeeFactory } from "~/service/repository/iEmployeeRepository";

import { factory } from "~/service/repository/iOnboardingExperienceTaskTemplateRepository";

const onboardingExperienceTaskTemplateRepository =
  factory.onboardingExperienceTaskTemplateRepository();
const employeeRepository = employeeFactory.employeeRepository();

export type CustomOnboardingExperienceTaskTemplate = OnboardingExperienceTaskTemplate & {
  editableEmployees: Employee[];
  updatedEmployee?: Employee;
};

/**
 * タスクテンプレートの一覧を返すUseCase
 */
export class FindOnboardingExperienceTaskTemplatesUseCase {
  /**
   * タスクテンプレートの一覧を返すUseCase
   * @param {string} tenantId
   * @returns CustomOnboardingExperienceTaskTemplate[]
   */
  async execute(tenantId: string): Promise<CustomOnboardingExperienceTaskTemplate[]> {
    const templates = await onboardingExperienceTaskTemplateRepository.findAll(tenantId);
    const employees = await employeeRepository.findAllWithDeleted();

    const templatesWithEmployee = this.generateCustomOnboardingExperienceTaskTemplate(
      templates,
      employees
    );

    const userCustomTemplates = templatesWithEmployee.filter((t) => !t.isDefault);
    const defaultTemplates = templatesWithEmployee.filter((t) => t.isDefault);
    return [...defaultTemplates, ...userCustomTemplates];
  }

  private generateCustomOnboardingExperienceTaskTemplate(
    templates: OnboardingExperienceTaskTemplate[],
    employees: Employee[]
  ): CustomOnboardingExperienceTaskTemplate[] {
    return templates.map((template) => {
      // Employeeをセットするためにアサーションを使っている
      const returnTemplate = template as CustomOnboardingExperienceTaskTemplate;
      returnTemplate.editableEmployees = employees.filter((e) =>
        template.editableEmployeeIds.includes(e.id)
      );
      returnTemplate.updatedEmployee = employees.find((e) => e.id === template.updatedEmployeeId);

      return returnTemplate;
    });
  }
}
