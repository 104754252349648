import { OnboardingMessageTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { FindMessageTasksForNewHireUseCase } from "~/service/usecases/onboardingTask";

const findMessageTasksForNewHireUseCase = new FindMessageTasksForNewHireUseCase();

export const generateUseMessageTasksForNewHireKeys = (newHireId: string) => {
  return ["messageTasksForNewHire", newHireId];
};

/**
 * 入社者に表示するmessageTasksを返すswr
 */
export const useMessageTasksForNewHire = (): SWRResponse<OnboardingMessageTask[], Error> => {
  const { currentUser } = useCurrentUser();
  const keys = generateUseMessageTasksForNewHireKeys(currentUser.id);

  return useSWR(keys, ([_key]) => findMessageTasksForNewHireUseCase.execute(currentUser));
};
