import { OnboardingExperience, OnboardingExperienceTaskType } from "@onn/common";

import { OnboardingExperienceRepository } from "~/infrastructure/api/onboardingExperienceRepository";
import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";

const onboardingExperienceRepository = new OnboardingExperienceRepository();
const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();

export class CreateOnboardingExperienceUseCase {
  async execute(
    onboardingExperience: OnboardingExperience,
    onboardingExperienceTasks?: OnboardingExperienceTaskType[]
  ): Promise<OnboardingExperience> {
    const experiencesExistedAlready = await onboardingExperienceRepository.findByTenantId(
      onboardingExperience.tenantId
    );

    if (
      experiencesExistedAlready.some(
        (experience) => experience.title === onboardingExperience.title
      )
    ) {
      throw new Error("同じタイトルのエクスペリエンスがすでに存在しています。");
    }

    await onboardingExperienceRepository.create(onboardingExperience);

    if (onboardingExperienceTasks) {
      const batch = queryOperator.batch();

      onboardingExperienceTasks.forEach((task) => {
        onboardingExperienceTaskRepository.insertCreateTaskInBatch(batch, task);
      });

      await queryOperator.commit(batch);
    }

    return onboardingExperience;
  }
}
