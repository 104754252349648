import React, { FC } from "react";

export const Close: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g transform="translate(-1.999 -2)">
        <rect
          width="1.46"
          height="21.167"
          rx="0.73"
          transform="translate(16.967 2.001) rotate(45)"
          stroke="none"
        />
        <rect
          width="1.46"
          height="21.167"
          rx="0.73"
          transform="translate(17.999 16.967) rotate(135)"
          stroke="none"
        />
      </g>
    </svg>
  );
};
