import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import logoSquare from "~/images/logo-square.svg";
import { mixin } from "~/util";

type Props = {
  title: string;
  timing: string;
  onClickEditButton: () => void;
};

export const ProcessTaskItem: FC<Props> = ({ title, onClickEditButton, timing }) => {
  return (
    <StyledBox py="8px" px="16px" display="flex" gridGap="8px" onClick={onClickEditButton}>
      <StyledImage src={logoSquare} alt="Onnアイコン" />
      <Box display="grid" gridRowGap="4px" paddingLeft="16px">
        <StyledTypography variant="body1" noWrap>
          {title}
        </StyledTypography>
        <StyledTypography variant="caption" color="textSecondary" noWrap disablePreWrap>
          期日 : {timing}
        </StyledTypography>
      </Box>
    </StyledBox>
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-right: auto;
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  border-radius: 8px;
  .MuiButtonBase-root {
    padding: 4px;
    display: none;
  }
  &:hover {
    background-color: ${(props) =>
      `rgba(${mixin.generateRgbaFromColorCode(props.theme.palette.grey[300])}, 0.1)`};
    .MuiButtonBase-root {
      display: inline-block;
    }
  }
`;

const StyledImage = styled.img`
  width: 24px;
  height: 24px;
`;
