import { Box, Menu, MenuItem } from "@material-ui/core";

import { Employee, SurveyTransaction } from "@onn/common";
import { format } from "date-fns";
import React, { useCallback, useState, FC } from "react";
import styled from "styled-components";

import { SurveyResult } from "~/components/domains/transactions";
import { IconButton, Paper, Typography, UserIconWithLabel } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useClipboard } from "~/hooks/shared";

const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";

type Props = {
  surveyTransactions: SurveyTransaction[];
  surveyTransaction: SurveyTransaction;
  newHire: Employee;
};

export const TimelineItem: FC<Props> = ({ surveyTransactions, surveyTransaction, newHire }) => {
  const { currentUser } = useCurrentUser();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { handleCopy } = useClipboard();

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickCopyButton = useCallback(
    (employeeId: string, transactionId: string) => {
      handleCopy(`${location.origin}/employee/${employeeId}?contentId=${transactionId}`, "リンク");
      handleCloseMenu();
    },
    [handleCopy, handleCloseMenu]
  );

  return (
    <StyledListItem key={surveyTransaction.id}>
      <Box mr="16px" display="flex" flexDirection="column" alignItems="center">
        <StyledCircle />
      </Box>
      <Box width="100%">
        <Box display="flex" alignItems="center" mb="24px">
          <UserIconWithLabel
            iconPath={newHire.profileIconImageUrl}
            name={newHire.getName()}
            to={`/employee/${newHire.id}`}
          />
          <Box display="flex" ml="16px" mr="16px" maxWidth="65%">
            <StyledTitleTypography variant="body1" noWrap>
              {surveyTransaction.contents.title}
            </StyledTitleTypography>
          </Box>
          <Box display="flex" mr="auto">
            <Typography variant="caption" noWrap>
              {surveyTransaction.answeredAt
                ? `${format(surveyTransaction.answeredAt, TIMESTAMP_FORMAT)} 回答`
                : ""}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} icon="menuVert" />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                key="copy-share-link"
                onClick={() => handleClickCopyButton(newHire.id, surveyTransaction.id)}
              >
                <Typography variant="body2">共有リンクをコピー</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Paper>
          <SurveyResult
            surveyTransaction={surveyTransaction}
            currentUser={currentUser}
            newHire={newHire}
            surveyTransactions={surveyTransactions}
          />
        </Paper>
      </Box>
    </StyledListItem>
  );
};

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledListItem = styled.li`
  padding: 0px 8px;
  list-style: none;
  display: flex;

  &:first-child {
    & > .MuiBox-root:first-of-type {
      padding-top: 10px;
    }
  }

  :not(:first-child) {
    & > .MuiBox-root:first-of-type {
      ::before {
        width: 3px;
        // 縦線が丸に対して右にずれないよう、縦線の太さに対してマイナスマージンをかける
        margin-left: calc(-3px / 2);
        height: 10px;
        content: "" "";
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }

  :not(:last-child) {
    & > .MuiBox-root:first-of-type {
      ::after {
        width: 3px;
        // 縦線が丸に対して右にずれないよう、縦線の太さに対してマイナスマージンをかける
        margin-left: calc(-3px / 2);
        height: 100%;
        content: "" "";
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
    & > .MuiBox-root:last-of-type {
      padding-bottom: ${(props) => props.theme.spacing(5)}px;
    }
  }
`;

const StyledTitleTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 20px;
  }
`;
