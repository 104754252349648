import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../employees/hooks";

import { UserIconGroup, Button, Icon, Typography, Tooltip } from "~/components/uiParts";

type ReactionProps = {
  employeeId: string;
  name: string;
  profileIconImageUrl?: string;
};

type ReactionsProps = {
  transactionId: string;
  reactions: ReactionProps[];
  newHire: Employee;
  onClickReactionButton: (transactionId: string) => void;
};

export const ReactionContent: FC<ReactionsProps> = ({
  transactionId,
  reactions,
  newHire,
  onClickReactionButton,
}) => {
  const { getBorderColorById } = useGetBorderColorById();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" flex={1}>
        <Button
          variant="text"
          borderRadius="regular"
          color="default"
          startIcon={<Icon icon="send" size="md" color="primary" />}
          onClick={() => onClickReactionButton(transactionId)}
        >
          <Typography variant="caption" color="primary" display="inline">
            入社者へリアクションを送る
          </Typography>
        </Button>
        {reactions.length > 0 && (
          <StyledUserIconGroup
            usersInfo={reactions.map((reaction) => {
              return {
                username: reaction.name,
                profileIconImageUrl: reaction.profileIconImageUrl,
                borderColor: getBorderColorById(newHire, reaction.employeeId),
              };
            })}
            tooltip
          />
        )}
      </Box>
      <Tooltip title="リアクション機能とは、入社者のアンケート回答に対して、リアクションのメッセージを送信することができる機能です。アンケート回答に対する一次返信として活用することで、アンケート回答結果を見てくれていることを入社者に伝えることができます。リアクションはOnnのbotから入社者のSlackへ通知が送られます。">
        <Icon icon="help" size="md" color="grey" />
      </Tooltip>
    </Box>
  );
};

const StyledUserIconGroup = styled(UserIconGroup)`
  flex: 1;
  margin: 0 24px;
`;
