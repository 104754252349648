export abstract class EditableEntityBase {
  createdEmployeeId: string;
  updatedEmployeeId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    init: Pick<
      EditableEntityBase,
      "createdEmployeeId" | "updatedEmployeeId" | "createdAt" | "updatedAt"
    >
  ) {
    this.createdEmployeeId = init.createdEmployeeId;
    this.updatedEmployeeId = init.updatedEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  /**
   * 更新する
   * @param {string} employeeId 更新を実行するemployeeId
   * @returns
   */
  protected onUpdate(employeeId?: string): this {
    // システムが更新するときはemployeeIdは存在しない
    if (employeeId) {
      this.updatedEmployeeId = employeeId;
    }
    this.updatedAt = new Date();

    return this;
  }
}
