import { OnboardingStatus, Employee } from "@onn/common";
import { intersectionBy, isEmpty } from "lodash";
import { useCallback, useState, useMemo } from "react";

import { useFilterEmployeesByDepartments } from "../../../hooks/employee/useFilterEmployeesByDepartments";

import { OrderType, useGetEmployeesByStatus, useSortEmployeesByJoinAt } from "../hooks";

import { useAllNewcomers, useCurrentUser } from "~/hooks/employee";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";

type StatusType = OnboardingStatus | "ALL";

/**
 * ハッシュマップ (keyは選択されたstatus, valueは取得対象となるstatusの配列)
 */
const selectedStatusWithTargetsMap: { [key in string]: OnboardingStatus[] } = {
  // invited employees
  [OnboardingStatus.INVITED]: [OnboardingStatus.INVITED],
  // preboarding employees
  [OnboardingStatus.LOGGED_IN]: [
    OnboardingStatus.LOGGED_IN,
    OnboardingStatus.PROFILE_REGISTERED,
    OnboardingStatus.JOIN_DATE_REGISTERED,
    OnboardingStatus.MENTOR_REGISTERED,
  ],
  // onboarding employees
  [OnboardingStatus.ANSWERED]: [OnboardingStatus.JOINED, OnboardingStatus.ANSWERED],
  // completed employees
  [OnboardingStatus.ONBOARDING_COMPLETED]: [OnboardingStatus.ONBOARDING_COMPLETED],
};

/**
 * 取得したemployeeに基づき各種StatusのEmployeeをmemoするViewModel
 */
export const useViewModel = () => {
  const { currentUser } = useCurrentUser();
  const { data: allNewComers } = useAllNewcomers();
  const { getEmployeesByStatus } = useGetEmployeesByStatus();
  const { filterEmployeesByDepartments } = useFilterEmployeesByDepartments(currentUser.tenantId);
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();
  const { sortEmployeesByJoinAt } = useSortEmployeesByJoinAt();

  const [selectedStatus, setSelectedStatus] = useState<StatusType>("ALL");
  const [order, setOrder] = useState<OrderType>("desc");
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<string[]>([]);
  const [isSelectedNoDepartment, setIsSelectedNoDepartment] = useState(false);
  const [selectedJoinAt, setSelectedJoinAt] = useState<DateString[]>([]);

  const [searchValue, setSearchValue] = useState("");

  /**
   * 中途入社者
   */
  const allNewHires = useMemo(() => {
    if (!allNewComers) return [];
    return allNewComers.filter((v) => !v.isNewGraduate);
  }, [allNewComers]);

  /**
   * 部署の絞り込み結果
   */
  const employeesFilteredByDepartments = useMemo(() => {
    return filterEmployeesByDepartments(selectedDepartmentIds, isSelectedNoDepartment, allNewHires);
  }, [allNewHires, filterEmployeesByDepartments, isSelectedNoDepartment, selectedDepartmentIds]);

  /**
   * 入社日の絞り込み結果
   */
  const employeesFilteredByJoinAt = useMemo(() => {
    if (isEmpty(selectedJoinAt)) return allNewHires;

    return allNewHires.filter((newcomer) => {
      if (isEmpty(newcomer.joinAt)) {
        return false;
      } else {
        return selectedJoinAt.some((joinDate) => {
          return newcomer.joinAt === joinDate;
        });
      }
    });
  }, [allNewHires, selectedJoinAt]);

  /**
   * 選択可能な入社日
   */
  const selectableJoinAt = useMemo(() => {
    const joinAt = allNewHires
      .map((newcomer) => {
        return newcomer.joinAt;
      })
      .filter((joinAt): joinAt is DateString => joinAt != null);
    return Array.from(new Set(joinAt)).sort().reverse();
  }, [allNewHires]);

  /**
   * 名前・メアドの絞り込み結果
   */
  const employeesFilteredBySearchValue = useMemo(() => {
    return filterObjectByPartialMatch({
      objects: allNewHires,
      searchText: searchValue,
      getProperties: [
        (employee: Employee) => employee.getName(),
        (employee: Employee) => employee.email,
      ],
    });
  }, [allNewHires, searchValue, filterObjectByPartialMatch]);

  /**
   * 絞り込みの積集合
   */
  const filteredNewHiresForAllStatus: Employee[] = useMemo(() => {
    // 検索欄, 入社日, 部署の絞り込み
    return intersectionBy(
      employeesFilteredBySearchValue,
      employeesFilteredByDepartments,
      employeesFilteredByJoinAt
    );
  }, [employeesFilteredByDepartments, employeesFilteredBySearchValue, employeesFilteredByJoinAt]);

  /**
   * 選択されたステータスを元にemployeeを絞り込み
   */
  const filterEmployeesByStatus = useCallback(
    (selectedStatus: StatusType, employees: Employee[]) => {
      if (selectedStatus === "ALL") {
        return employees;
      } else {
        // ハッシュマップを元に、選択されたステータスのemployeeを取得
        return getEmployeesByStatus(
          selectedStatusWithTargetsMap[
            selectedStatus
          ] as (typeof selectedStatusWithTargetsMap)[StatusType],
          employees
        );
      }
    },
    [getEmployeesByStatus]
  );

  const filteredNewGraduatesByStatus = useMemo(() => {
    return filterEmployeesByStatus(selectedStatus, filteredNewHiresForAllStatus);
  }, [selectedStatus, filteredNewHiresForAllStatus, filterEmployeesByStatus]);

  const newHiresForTableView = useMemo(() => {
    return sortEmployeesByJoinAt(filteredNewGraduatesByStatus, order);
  }, [sortEmployeesByJoinAt, filteredNewGraduatesByStatus, order]);

  return {
    allNewHires,
    filteredNewHiresForAllStatus,
    newHiresForTableView,

    selectedStatus,
    setSelectedStatus,
    order,
    setOrder,

    setSearchValue,

    selectedDepartmentIds,
    setSelectedDepartmentIds,
    isSelectedNoDepartment,
    setIsSelectedNoDepartment,

    selectedJoinAt,
    setSelectedJoinAt,
    selectableJoinAt,

    invitedEmployees: getEmployeesByStatus(
      selectedStatusWithTargetsMap[
        OnboardingStatus.INVITED
      ] as (typeof selectedStatusWithTargetsMap)[StatusType],
      filteredNewHiresForAllStatus
    ),
    preboardingEmployees: getEmployeesByStatus(
      selectedStatusWithTargetsMap[
        OnboardingStatus.LOGGED_IN
      ] as (typeof selectedStatusWithTargetsMap)[StatusType],
      filteredNewHiresForAllStatus
    ),
    onboardingEmployees: getEmployeesByStatus(
      selectedStatusWithTargetsMap[
        OnboardingStatus.ANSWERED
      ] as (typeof selectedStatusWithTargetsMap)[StatusType],
      filteredNewHiresForAllStatus
    ),
    completedEmployees: getEmployeesByStatus(
      selectedStatusWithTargetsMap[
        OnboardingStatus.ONBOARDING_COMPLETED
      ] as (typeof selectedStatusWithTargetsMap)[StatusType],
      filteredNewHiresForAllStatus
    ),
  };
};
