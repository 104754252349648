import { Box, Grid } from "@material-ui/core";
import { Employee, OnboardingMessageTask } from "@onn/common";
import React, { useRef, FC, useMemo } from "react";
import styled from "styled-components";

import { ManageMessageTaskPaper } from "~/components/domains/onboardingTask/ManageMessageTaskPaper";
import { ActivityContentItem } from "~/components/uiParts";
import { useIsMyDepartmentAdmin } from "~/hooks/department/useIsMyDepartmentAdmin";
import { useCurrentUser, useScrollToTargetElement } from "~/hooks/employee";
import { useOnboardingTask } from "~/hooks/onboardingTask";
import { useHandleManageMessageTaskModal } from "~/hooks/onboardingTask/messageTask";

type Props = {
  onboardingMessageTask: OnboardingMessageTask;
  newHire: Employee;
  isScrollTarget: boolean;
  isActive?: boolean;
};

export const ActivityContentForMessage: FC<Props> = React.memo(
  ({ onboardingMessageTask, newHire, isScrollTarget, isActive = false }) => {
    const { data: onboardingMessageTaskData } = useOnboardingTask(onboardingMessageTask.id);
    const { currentUser } = useCurrentUser();
    const { handleOpenManageMessageTaskModal } = useHandleManageMessageTaskModal();

    const task = useMemo(
      () => onboardingMessageTaskData?.onboardingTask ?? onboardingMessageTask,
      [onboardingMessageTask, onboardingMessageTaskData?.onboardingTask]
    );

    const captionText = useMemo(() => {
      if (task.status === "COMPLETED") {
        return `${task.dueDate.getDisplayTextByEmployee(newHire, "yyyy/MM/dd HH:mm")} 完了`;
      } else {
        return `${task.dueDate.getDisplayTextByEmployee(newHire)} 配信予定`;
      }
    }, [newHire, task.dueDate, task.status]);

    const { result: isMyDepartmentAdmin } = useIsMyDepartmentAdmin(newHire, currentUser);

    const rootRef = useRef<HTMLDivElement>(null);

    useScrollToTargetElement({
      enabled: isScrollTarget,
      targetRef: rootRef,
    });

    if (task.type !== "MESSAGE_TASK") return null;

    return (
      <StyledListItem>
        <Box mr={2} display="flex" flexDirection="column" alignItems="center">
          <StyledCircle />
        </Box>
        <Box width="100%">
          <Grid container alignItems="flex-start">
            <Grid item sm={7}>
              <div ref={rootRef}>
                <ActivityContentItem
                  title={task.title}
                  caption={captionText}
                  content={
                    <ManageMessageTaskPaper
                      progressMessageContents={task.contents.filter(
                        (content) => !content.isCompleted
                      )}
                      completedMessageContents={task.contents.filter(
                        (content) => content.isCompleted
                      )}
                      newHire={newHire}
                      isActive={isActive}
                      onClickUserIconGroup={
                        currentUser.isAdmin()
                          ? () => handleOpenManageMessageTaskModal(task, newHire)
                          : () => void 0
                      }
                    />
                  }
                  shareLink={`${location.origin}/employee/${newHire.id}?contentId=${task.id}`}
                  onClickManageMessageButton={
                    currentUser.isAdmin() || isMyDepartmentAdmin
                      ? () => handleOpenManageMessageTaskModal(task, newHire)
                      : undefined
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </StyledListItem>
    );
  }
);

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledListItem = styled.li`
  padding: 0px 8px;
  list-style: none;
  display: flex;
  :not(:last-child) {
    & > .MuiBox-root:first-of-type {
      ::after {
        width: 3px;
        // 縦線が丸に対して右にずれないよう、縦線の太さに対してマイナスマージンをかける
        margin-left: calc(-3px / 2);
        height: 100%;
        content: "" "";
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
    & > .MuiBox-root:last-of-type {
      padding-bottom: ${(props) => props.theme.spacing(5)}px;
    }
  }
`;
