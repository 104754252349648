import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  onClickCloseButton: () => void;
};

// ====================
// main
// ====================

export const SidebarTopSection: FC<Props> = ({ onClickCloseButton }) => {
  // ====================
  // main component
  // ====================

  return (
    <Box p="16px" display="flex" alignItems="center" justifyContent="end">
      <StyledBox onClick={onClickCloseButton}>
        <Icon icon="close" size="md" color="grey" />
      </StyledBox>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledBox = styled(Box)`
  height: 24px;
  cursor: pointer;
`;
