import { Box, Drawer, IconButton } from "@material-ui/core";
import React, { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Header, Icon } from "~/components/uiParts";
import { LogoutButton } from "~/components/uiParts/GlobalNaviMenu/parts/LogoutButton";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";
import { mixin } from "~/util";

type Props = {
  isBackgroundImage?: boolean;
  children: ReactNode;
};

export const OnlyAuthenticationLayout: FC<Props> = ({ isBackgroundImage = false, children }) => {
  const navigate = useNavigate();
  const handleClickLogo = (): void => {
    navigate("/");
  };

  return (
    <StyledDivWrapper>
      <Header onClickLogo={handleClickLogo} drawerMenu={<DrawerMenu />} />
      <StyledBox display="flex" justifyContent="center" $isBackgroundImage={isBackgroundImage}>
        {children}
      </StyledBox>
    </StyledDivWrapper>
  );
};

const DrawerMenu = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const closeDrawer = () => setAnchorEl(false);
  const openDrawer = () => setAnchorEl(true);

  return (
    <>
      <IconButton onClick={openDrawer} edge="end" color="inherit" aria-label="menu">
        <Icon icon="menuHor" color="grey" size="md" />
      </IconButton>{" "}
      <StyledDrawer
        anchor="right"
        open={anchorEl}
        onClose={closeDrawer}
        BackdropProps={{ invisible: true }}
      >
        <div role="presentation" onClick={closeDrawer} onKeyDown={closeDrawer}>
          <StyledWrapperBox>
            <Box>
              <LogoutButton />
            </Box>
          </StyledWrapperBox>
        </div>
      </StyledDrawer>
    </>
  );
};

const StyledDivWrapper = styled(Box)`
  background-color: ${(props) => `${props.theme.palette.grey[50]}`};

  ${mixin.breakDown.sm`
    background-color: white;
  `};
`;

const StyledBox = styled(Box)<{ $isBackgroundImage: boolean }>`
  min-height: calc(100vh - ${(props) => props.theme.constants.HEADER_HEIGHT}px);

  ${(props) =>
    props.$isBackgroundImage &&
    mixin.breakUp.sm`
    background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
    background-position: right top, left bottom;
    background-repeat: no-repeat;
  `}
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 375px;
    opacity: 0.95;
  }
`;

const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 16px 0;
`;
