import { Box } from "@material-ui/core";
import { Employee, OnboardingMessageTask, MessageContent } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import {
  DeliveryDateSection,
  DescriptionSection,
  DueDateSection,
  FileSection,
  NewHireSection,
  StatusSection,
} from "../parts";

import { CompletedAssigneeSection } from "./CompletedAssigneeSection";
import { InProgressAssigneeSection } from "./InProgressAssigneeSection";
import { OnboardingMessageTaskControl } from "./OnboardingMessageTaskControl";

import { Divider } from "~/components/uiParts";
import { useHandleManageMessageTaskModal } from "~/hooks/onboardingTask/messageTask";

// ====================
// props
// ====================

type Props = {
  currentUser: Employee;
  onboardingTask: OnboardingMessageTask;
  newHire: Employee;
  isEnableNewHireLink?: boolean;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
};

// ====================
// main
// ====================

export const OnboardingMessageTaskSummarySidebar: FC<Props> = ({
  currentUser,
  onboardingTask,
  newHire,
  isEnableNewHireLink,
  onClickEditButton,
  onClickDeleteTaskButton,
}) => {
  // ====================
  // hooks
  // ====================

  const { handleOpenManageMessageTaskModal } = useHandleManageMessageTaskModal();

  // ====================
  // variable
  // ====================

  const inProgressAssignees: MessageContent[] = [];
  const completedAssignees: MessageContent[] = [];
  onboardingTask.contents.forEach((v) => {
    v.isCompleted ? completedAssignees.push(v) : inProgressAssignees.push(v);
  });

  // ====================
  // jsx
  // ====================

  return (
    <>
      <StyledBox display="flex" flexDirection="column" gridGap="32px" px="24px" py="32px">
        <DescriptionSection
          description={onboardingTask.getBodyTextReplacedWithNewHire(newHire.getName())}
        />
        <FileSection filePaths={onboardingTask.filePaths as string[]} />

        <Divider />

        <NewHireSection isEnableNewHireLink={isEnableNewHireLink} newHire={newHire} />
        <InProgressAssigneeSection
          inProgressAssignees={inProgressAssignees}
          onClickUserIcon={
            onboardingTask.status !== "COMPLETED" && currentUser.isAdmin()
              ? () => handleOpenManageMessageTaskModal(onboardingTask, newHire)
              : undefined
          }
        />
        <CompletedAssigneeSection
          completedAssignees={completedAssignees}
          onClickUserIcon={
            onboardingTask.status !== "COMPLETED" && currentUser.isAdmin()
              ? () => handleOpenManageMessageTaskModal(onboardingTask, newHire)
              : undefined
          }
        />
        {onboardingTask.deliveryDate && (
          <DeliveryDateSection deliveryDate={onboardingTask.deliveryDate} newHire={newHire} />
        )}
        <DueDateSection newHire={newHire} onboardingTask={onboardingTask} />
        <StatusSection newHire={newHire} onboardingTask={onboardingTask} />
      </StyledBox>

      <OnboardingMessageTaskControl
        currentUser={currentUser}
        newHire={newHire}
        onboardingTask={onboardingTask}
        onClickEditButton={onClickEditButton}
        onClickDeleteTaskButton={onClickDeleteTaskButton}
        onClickOpenManageMessageTask={() =>
          handleOpenManageMessageTaskModal(onboardingTask, newHire)
        }
      />
    </>
  );
};

// ====================
// style
// ====================

const HEADER_HEIGHT = 154;
const FOOTER_HEIGHT = 102;

const StyledBox = styled(Box)`
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
  overflow-y: auto;
`;
