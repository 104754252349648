import { useCallback, useMemo, useState } from "react";

export const filterFormFields = ["isInCompleted", "isCompleted"] as const;
type FormField = (typeof filterFormFields)[number];

export type FormState = { [key in FormField]: boolean };
export type SearchCondition = FormField | "all";
export type ToggleState = (field: FormField) => void;
export const formLabelTextMap: { [key in FormField]: string } = {
  isInCompleted: "未対応",
  isCompleted: "対応済",
};

/**
 * 対応済みフィルターの状態と振る舞いを扱うhooks
 */
export const useIsCompletedFilterFormState = () => {
  const [formState, setFormState] = useState<FormState>({
    isCompleted: false,
    isInCompleted: false,
  });

  const searchCondition: SearchCondition = useMemo(() => {
    if (formState.isCompleted === formState.isInCompleted) return "all";

    return formState.isCompleted ? "isCompleted" : "isInCompleted";
  }, [formState]);

  const formLabelText: string = useMemo(() => {
    switch (searchCondition) {
      case "all": {
        return Object.values(formLabelTextMap).join("・");
      }
      default: {
        return formState.isCompleted
          ? formLabelTextMap["isCompleted"]
          : formLabelTextMap["isInCompleted"];
      }
    }
  }, [formState, searchCondition]);

  const toggleState = useCallback(
    (isCompletedFormField: FormField) =>
      setFormState({
        ...formState,
        [isCompletedFormField]: !formState[isCompletedFormField],
      }),
    [formState]
  );

  return {
    formState,
    formLabelText,
    searchCondition,
    toggleState,
  };
};
