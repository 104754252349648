import React, { FC } from "react";

export const MenuVert: FC = () => {
  return (
    <svg id="Group_2245" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        id="path"
        d="M6,8A2,2,0,1,0,4,6,2.006,2.006,0,0,0,6,8Zm0,2a2,2,0,1,0,2,2,2.006,2.006,0,0,0-2-2Zm0,6a2,2,0,1,0,2,2,2.006,2.006,0,0,0-2-2Z"
        transform="translate(6)"
        stroke="none"
        opacity="0.54"
        fillRule="evenodd"
      />
    </svg>
  );
};
