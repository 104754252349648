import { Box } from "@material-ui/core";
import { WEEKDAY_FOR_SURVEY, WeekdayForSurvey, Survey } from "@onn/common";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { SettingsWrapper } from "../SettingsWrapper";

import { Icon, SelectForm, Typography } from "~/components/uiParts";

type Props = {
  oneMonthSurvey: Survey;
  editing: boolean;
  updating: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (newOneMonthSurvey: Survey) => void;
};

export const SettingsOptions = {
  SEND_WEEKDAY: "sendWeekday", // 何曜日に配信するか
  SEND_Hour: "sendHour", // 何時に配信するか
} as const;
type SettingsOptions = (typeof SettingsOptions)[keyof typeof SettingsOptions];

export const OneMonthSurveySettings: FC<Props> = (props) => {
  const { oneMonthSurvey, editing, onSubmit, ...rest } = props;
  const [newOneMonthSurvey, setNewOneMonthSurvey] = useState<Survey>(oneMonthSurvey);

  useEffect(() => {
    setNewOneMonthSurvey(oneMonthSurvey);
  }, [editing, oneMonthSurvey]);

  const handleChangeSettings = (newObject: Partial<Record<SettingsOptions, number>>) => {
    setNewOneMonthSurvey((prev) => ({ ...prev, ...newObject }));
  };

  const handleSubmitButton = () => {
    onSubmit(newOneMonthSurvey);
  };

  return (
    <SettingsWrapper {...rest} editing={editing} onSubmit={handleSubmitButton}>
      {editing ? (
        <>
          <Typography variant="caption">配信曜日(単一選択)</Typography>
          <Box height="16px" />
          <StyledSelectForm
            selected={newOneMonthSurvey.sendWeekday}
            onChange={(e) =>
              handleChangeSettings({ [SettingsOptions.SEND_WEEKDAY]: Number(e.target.value) })
            }
            menuItems={WeekdayForSurvey.getBusinessDays()}
          />
          {/* 一旦配信曜日だけ実装してリリース */}
          {/* <Box height="24px" />
          <Typography variant="caption">配信時間(単一選択)</Typography>
          <Box height="16px" />
          <StyledSelectForm
            selected={newOneMonthSurvey.sendHour}
            onChange={(e) =>
              handleChangeSettings({ [SettingsOptions.SEND_Hour]: Number(e.target.value) })
            }
            menuItems={HOUR_FOR_SURVEY}
          /> */}
        </>
      ) : (
        <StyledDiv>
          <StyledIcon icon="check" size="sm" color="primary" />
          <Typography variant="caption" display="block">
            配信曜日：
            {WEEKDAY_FOR_SURVEY.find((v) => v.value === oneMonthSurvey.sendWeekday)?.name}
          </Typography>
        </StyledDiv>
        // 一旦配信曜日だけ実装してリリース
        // <Box height="4px" />
        // <Typography variant="caption" display="block">
        //   <Icon icon="check" size="sm" color="primary" />
        //   <Box height="4px" />
        //   配信時間：
        //   {HOUR_FOR_SURVEY.find((v) => v.value === oneMonthSurvey.sendHour)?.name}
        // </Typography>
      )}
    </SettingsWrapper>
  );
};

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelectForm = styled(SelectForm)`
  width: 256px;
`;
