import { v4 } from "uuid";

import { OnboardingExperienceTaskTemplate } from "../OnboardingExperienceTaskTemplate";

export class MessageTemplate extends OnboardingExperienceTaskTemplate {
  readonly type = "MESSAGE";

  constructor(
    init: Pick<
      MessageTemplate,
      | "id"
      | "isDefault"
      | "tenantId"
      | "title"
      | "description"
      | "isPublic"
      | "editableEmployeeIds"
      | "createdEmployeeId"
      | "updatedEmployeeId"
      | "createdAt"
      | "updatedAt"
    >
  ) {
    super(init);
  }

  public static create(
    params: ExcludeMethods<
      Optional<
        Omit<MessageTemplate, "type">,
        "id" | "isDefault" | "isPublic" | "createdAt" | "updatedAt"
      >
    >
  ) {
    return new MessageTemplate({
      ...params,
      id: params.id ?? v4(),
      isDefault: params.isDefault ?? false,
      isPublic: params.isPublic ?? true,
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
      updatedEmployeeId: params.updatedEmployeeId ?? params.createdEmployeeId,
    });
  }

  public static createByDefault(
    params: Pick<MessageTemplate, "tenantId" | "title" | "description">
  ) {
    return new MessageTemplate({
      ...params,
      id: v4(),
      isDefault: true,
      isPublic: true,
      editableEmployeeIds: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(
    employeeId: string,
    params: Pick<MessageTemplate, "title" | "description">
  ): MessageTemplate {
    return new MessageTemplate({
      ...this,
      title: params.title,
      description: params.description,
      updatedEmployeeId: employeeId,
      updatedAt: new Date(),
    });
  }
}
