import { MessageContent, OnboardingTaskType } from "@onn/common";
import { useState, useCallback } from "react";
import { useSWRConfig } from "swr";

import { generateUseOnboardingTask } from "../../useOnboardingTask";

import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

/**
 * メッセージを記入するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns submitMessageTask
 */
export const useSubmitMessageContent = (): {
  isLoading: boolean;
  submitMessageContent: (
    onboardingTask: OnboardingTaskType,
    newMessageContent: MessageContent,
    isOnlyUpdateEmail: boolean,
    inputIconDataUrl?: string,
    uploadedIconFileName?: string
  ) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();

  const submitMessageContent = useCallback(
    async (
      onboardingTask: OnboardingTaskType,
      newMessageContent: MessageContent,
      isOnlyUpdateEmail: boolean,
      inputIconDataUrl?: string,
      uploadedIconFileName?: string
    ): Promise<void> => {
      setIsLoading(true);

      let iconFile: File | undefined;

      if (inputIconDataUrl && uploadedIconFileName && /data:image\//i.test(inputIconDataUrl)) {
        // dataUrl → File 変換
        const blob = await (await fetch(inputIconDataUrl)).blob();
        const regExpMatchArray = inputIconDataUrl.match(/:([a-z/-]+);/);
        const mimeType = regExpMatchArray ? regExpMatchArray[1] : "image/png";
        iconFile = new File([blob], uploadedIconFileName, {
          type: mimeType,
        });
      }

      const newMessageContentForSubmit = MessageContent.create({
        ...newMessageContent,
        iconPath: iconFile
          ? `tenants/${onboardingTask.tenantId}/messages/${newMessageContent.id}/${iconFile.name}`
          : newMessageContent.iconPath,
      });

      if (iconFile && newMessageContentForSubmit.iconPath) {
        await fileAPIAdapter.upload({
          path: newMessageContentForSubmit.iconPath,
          file: iconFile,
        });
      }

      return apiClient
        .post("/submit_message_content", {
          taskId: onboardingTask.id,
          newMessageContent,
          isOnlyUpdateEmail,
          iconFileName: iconFile?.name,
        })
        .then(() => {
          mutate(generateUseOnboardingTask(onboardingTask.id));
          enqueueSnackbar("メッセージを記入しました", { variant: "success" });
          return;
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useSubmitMessageContent:submitMessageContentUseCase",
            },
          });
          return;
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, mutate]
  );

  return { isLoading, submitMessageContent };
};
