import React, { useContext, type ReactNode, useCallback, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { AuthenticationContext } from "./AuthenticationProvider";

type Props = {
  children: ReactNode;
};

const useRedirectToSigninIfNoLiffState = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToSigninIfNoLiffState = useCallback(() => {
    const toUrl = new URL(`/signin?dest-path=${location.pathname}`, "http://dummy.example.com");
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.forEach((value, key) => {
      toUrl.searchParams.append(key, value);
    });

    navigate(`${toUrl.pathname}${toUrl.search}`);
  }, [location.pathname, location.search, navigate]);

  return { redirectToSigninIfNoLiffState };
};

export const AuthenticationGuard = ({ children }: Props) => {
  const { authUser, guardAuthUser, isReady } = useContext(AuthenticationContext);

  const { redirectToSigninIfNoLiffState } = useRedirectToSigninIfNoLiffState();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    if (!guardAuthUser(authUser)) {
      redirectToSigninIfNoLiffState();
    }
  }, [authUser, guardAuthUser, redirectToSigninIfNoLiffState, isReady]);

  if (!guardAuthUser(authUser)) {
    return <></>;
  }

  return <>{children}</>;
};
