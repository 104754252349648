import { Box } from "@material-ui/core";
import { displayAssigneeRoleMap, Employee, GeneralTaskSettings } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState, FC } from "react";
import styled from "styled-components";

import { SelectMenu as EmployeesSelectMenu } from "~/components/domains/employees";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Modal,
  SelectForm,
  Tooltip,
  Typography,
} from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import { captureException, mixin } from "~/util";

type SelectableAssigneeRole = GeneralTaskSettings["assigneeRole"];
const assigneeRoles: readonly SelectableAssigneeRole[] = ["ADMIN", "BY_NAME"] as const;

type Props = {
  open: boolean;
  onCancel: () => void;
  onUpsert: (generalTaskSetting: GeneralTaskSettings) => void;
  selectableEmployees: Employee[];
  generalTaskSettings: GeneralTaskSettings;
};

export const EditOnboardingExperienceGeneralProcessAssigneeModal: FC<Props> = ({
  open,
  onCancel,
  onUpsert,
  selectableEmployees,
  generalTaskSettings,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(
    selectableEmployees.filter((e) => generalTaskSettings.assigneeIds.includes(e.id))
  );

  const [newGeneralTaskSetting, setNewGeneralTaskSettings] =
    useState<GeneralTaskSettings>(generalTaskSettings);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const isInvalid = useMemo(
    () =>
      // BY_NAMEだが担当者の指定がされていないときは不正
      newGeneralTaskSetting.assigneeRole === "BY_NAME" &&
      isEmpty(newGeneralTaskSetting.assigneeIds),
    [newGeneralTaskSetting]
  );

  const handleChangeAssigneeRole = useCallback((newRole: SelectableAssigneeRole) => {
    setNewGeneralTaskSettings((prev) => ({
      ...prev,
      assigneeRole: newRole,
    }));
  }, []);

  const handleSelectEmployees = useCallback((employees: Employee[]) => {
    setSelectedEmployees(employees);
    setNewGeneralTaskSettings((prev) => ({ ...prev, assigneeIds: employees.map((e) => e.id) }));
  }, []);

  const handleCheck = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const handleSubmit = useCallback(() => {
    try {
      onUpsert({
        assigneeRole: newGeneralTaskSetting.assigneeRole,
        assigneeIds:
          newGeneralTaskSetting.assigneeRole !== "BY_NAME" ? [] : newGeneralTaskSetting.assigneeIds,

        actionIds: newGeneralTaskSetting.actionIds,
      });
      onCancel();
      enqueueSnackbar("管理者向け共通タスクの担当者が変更され、全体に適用されました。", {
        variant: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
      }
      captureException({
        error: e as Error,
        tags: { type: "EditOnboardingExperienceGeneralProcessAssigneeModal:handleSubmit" },
      });
    }
  }, [enqueueSnackbar, newGeneralTaskSetting, onCancel, onUpsert]);

  const content = useMemo(() => {
    const FORM_GRID_GAP = "24px";
    const FORM_WIDTH = `calc((100%-${FORM_GRID_GAP}) / 2)`;
    return (
      <>
        <StyledCenterBox mt="16px">
          <StyledTypography variant="body1">
            管理者向け共通タスクの担当者を変更すると、
            <br />
            全ての受け入れ体験に変更が反映され、
            <br />
            次回の入社者招待から変更後の設定が適用されます。
          </StyledTypography>
        </StyledCenterBox>
        <Box mt="24px" display="flex" gridGap="12px" alignItems="center">
          <Typography variant="body2" bold>
            担当者
          </Typography>
          <Tooltip
            title="「管理者」を選択すると、タスクの担当者がその時点の管理者に自動的にセットされます。「特定のメンバーを指定」を選択すると、管理者と部門管理者から担当者を選択可能です。"
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box mt="20px" display="flex" gridGap={FORM_GRID_GAP}>
          <StyledSelectForm
            selected={newGeneralTaskSetting.assigneeRole}
            onChange={(e) => handleChangeAssigneeRole(e.target.value as SelectableAssigneeRole)}
            menuItems={assigneeRoles.map((v) => {
              return { value: v, name: displayAssigneeRoleMap[v] };
            })}
            $width={FORM_WIDTH}
          />
          {newGeneralTaskSetting.assigneeRole === "BY_NAME" && (
            <EmployeesSelectMenu
              employees={selectableEmployees}
              selectedEmployees={selectedEmployees}
              selectEmployees={handleSelectEmployees}
              width={FORM_WIDTH}
            />
          )}
        </Box>
        <StyledCenterBox mt="24px">
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={() => handleCheck()} />}
            label={<StyledTypography variant="caption">変更を全体に反映する</StyledTypography>}
          />
        </StyledCenterBox>
      </>
    );
  }, [
    newGeneralTaskSetting.assigneeRole,
    selectableEmployees,
    selectedEmployees,
    handleSelectEmployees,
    isChecked,
    handleChangeAssigneeRole,
    handleCheck,
  ]);

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={isInvalid || !isChecked}
        >
          変更
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel, handleSubmit, isInvalid, isChecked]
  );

  return (
    <Modal title="担当者変更" open={open} onCancel={onCancel} content={content} footer={footer} />
  );
};

const StyledCenterBox = styled(Box)`
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    color: #5f5f5f;
  }
`;

const StyledSelectForm = styled(SelectForm)<{ $width: string }>`
  width: ${(props) => props.$width};
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
