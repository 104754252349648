import { Employee, OnboardingTaskWithNewHire, OnboardingTaskType } from "@onn/common";
import { useCallback, useState, useEffect } from "react";

import {
  useFilterTasksByStatus,
  useFilterTasksByAssigneeEmployeeIds,
  useSortOnboardingTasks,
} from "~/components/domains/onboardingTask/hooks";
import { useAllEmployees, useCurrentUser, useEmployees, useAdmins } from "~/hooks/employee";
import { useOnboardingTasksByEmployeeId } from "~/hooks/onboardingTask";
import { useQuery } from "~/hooks/shared";

export const FILTER_STATUSES = {
  EXPIRED: "EXPIRED",
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED",
  ALL: "ALL",
} as const;

export type FilterStatusType = (typeof FILTER_STATUSES)[keyof typeof FILTER_STATUSES];
const AllFilterStatusType = Object.values(FILTER_STATUSES);

export type FilterOptionType = {
  title: string;
  totalCount: number;
  status: FilterStatusType;
};

const getOnboardingTaskWithNewHire = (
  onboardingTasks: OnboardingTaskType[],
  newHires: Employee[]
) => {
  return onboardingTasks.map((v: OnboardingTaskWithNewHire) => {
    v.newHire = newHires.find((newHire) => newHire.id === v.employeeId);
    return v;
  });
};

export const useViewModel = (employee: Employee) => {
  const { currentUser } = useCurrentUser();
  const { allEmployees } = useAllEmployees();

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<FilterStatusType>("ALL");
  const [selectedOnboardingTaskIds, setSelectedOnboardingTaskIds] = useState<string[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const [targetTaskForSummary, setTargetTaskForSummary] = useState<OnboardingTaskType>();

  const {
    data: onboardingTasks,
    mutate: mutateOnboardingTasks,
    isValidating: isValidatingOnboardingTasks,
  } = useOnboardingTasksByEmployeeId(employee.id);

  const { data: newHires, isValidating: isValidatingNewHires } = useEmployees(
    onboardingTasks ? Array.from(new Set(onboardingTasks.map((v) => v.employeeId))) : undefined
  );

  const { data: admins = [] } = useAdmins(currentUser.tenantId);

  const { filterTasksByStatus } = useFilterTasksByStatus();
  const { filterTasksByAssigneeEmployeeIds } = useFilterTasksByAssigneeEmployeeIds();
  const { sortOnboardingTasks } = useSortOnboardingTasks();

  const { query } = useQuery();

  useEffect(() => {
    if (onboardingTasks) {
      const onboardingTaskId = query.get("onboardingTaskId");
      // サマリーを表示していたら更新しない
      setTargetTaskForSummary(
        (prev) => prev || onboardingTasks.find((task) => task.id === onboardingTaskId)
      );
    }
  }, [onboardingTasks, query]);

  useEffect(() => {
    const selectedStatus = query.get("selectedStatus") as FilterStatusType;
    // 不正な値の場合はフィルターしない
    if (!AllFilterStatusType.includes(selectedStatus)) {
      return;
    }
    setSelectedStatus(selectedStatus);
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allEmployeeIds = allEmployees.map((v) => v.id);
    const employeeIds = query.getAll("employeeIds");
    // 不正な値の場合はフィルターしない
    if (employeeIds.some((employeeId) => !allEmployeeIds.includes(employeeId))) {
      return;
    }
    setSelectedEmployeeIds(employeeIds);
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees]);

  const getFilteredAndSortedOnboardingTasks = useCallback(
    (
      onboardingTaskWithNewHire: OnboardingTaskWithNewHire[],
      selectedStatus: FilterStatusType,
      selectedEmployeeIds: string[]
    ) => {
      // タスクのステータスでフィルター
      const filteredTasksByStatus = filterTasksByStatus(onboardingTaskWithNewHire, selectedStatus);

      // タスクの担当者でフィルター
      const filteredTasksByAssigneeEmployeeIds = filterTasksByAssigneeEmployeeIds(
        filteredTasksByStatus,
        selectedEmployeeIds,
        admins.map((v) => v.id)
      );

      return sortOnboardingTasks(filteredTasksByAssigneeEmployeeIds);
    },
    [admins, filterTasksByAssigneeEmployeeIds, filterTasksByStatus, sortOnboardingTasks]
  );

  const filteredOnboardingTasksByStatus = (
    onboardingTasks: OnboardingTaskType[],
    status: FilterStatusType
  ) => {
    return onboardingTasks && newHires
      ? getFilteredAndSortedOnboardingTasks(
          getOnboardingTaskWithNewHire(onboardingTasks, newHires),
          status,
          selectedEmployeeIds
        )
      : [];
  };

  return {
    onboardingTasks,
    mutateOnboardingTasks,
    isValidatingOnboardingTasks,
    targetTaskForSummary,
    setTargetTaskForSummary,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectedStatus,
    setSelectedStatus,
    selectedOnboardingTaskIds,
    setSelectedOnboardingTaskIds,
    isSelectedAll,
    setIsSelectedAll,
    filteredOnboardingTasksByStatus,
    isValidatingNewHires,
  };
};
