import { OnboardingExperienceTaskTemplate } from "@onn/common";
import { useCallback, useState } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { CreateOnboardingExperienceTaskTemplateUseCase } from "~/service/usecases/onboardingExperienceTaskTemplate";
import { captureException } from "~/util";

const useCase = new CreateOnboardingExperienceTaskTemplateUseCase();

/**
 * onboardingExperienceTaskTemplate を作成する関数を提供する
 */
export const useCreateOnboardingExperienceTaskTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * onboardingExperienceTaskTemplate を作成する
   * @param {OnboardingExperienceTaskTemplate} newOnboardingExperienceTaskTemplate
   */
  const createOnboardingExperienceTaskTemplate = useCallback(
    async (newOnboardingExperienceTaskTemplate: OnboardingExperienceTaskTemplate) => {
      setIsLoading(true);
      await useCase
        .execute(currentUser, newOnboardingExperienceTaskTemplate)
        .then(() => enqueueSnackbar("テンプレートを作成しました", { variant: "success" }))
        .catch((_e) => {
          enqueueSnackbar("テンプレートを作成できませんでした", { variant: "error" });
          captureException({
            error: _e as Error,
            tags: {
              type: "useCreateOnboardingExperienceTaskTemplate:createOnboardingExperienceTaskTemplate",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar]
  );

  return { isLoading, createOnboardingExperienceTaskTemplate };
};
