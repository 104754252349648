import {
  Box,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Employee, SurveyTransaction } from "@onn/common";
import { format } from "date-fns";
import React, { useState, ComponentProps, useCallback, FC, useMemo } from "react";
import styled from "styled-components";

import { CommentList, DeleteCommentConfirmModal } from "~/components/domains/comments";
import { ReactionContent, SendReactionModal } from "~/components/domains/reactions";
import { SurveyResult } from "~/components/domains/transactions";

import { IconButton, Typography, Tooltip } from "~/components/uiParts";
import { useDeleteComment } from "~/hooks/comment/useDeleteComment";
import { useScrollToTargetElement } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar, useClipboard } from "~/hooks/shared";
import { ReactionUseCase } from "~/service/usecases/reactionUseCase";
import { captureException, mixin } from "~/util";

const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";

type CommentListProps = ComponentProps<typeof CommentList>;
type ReactionsProps = ComponentProps<typeof ReactionContent>;

type Props = {
  surveyTransaction: SurveyTransaction;
  surveyTransactions: SurveyTransaction[];
  isScrollTarget: boolean;
  employee: Employee;
  _expanded: boolean;
  commentFocused: boolean;
  isActive: boolean;
} & Pick<
  CommentListProps,
  | "comments"
  | "commentDisabled"
  | "currentUserId"
  | "suggestedMentionUsers"
  | "loadTransactionComments"
> &
  Pick<ReactionsProps, "reactions">;

export const AccordionAnswerDetails: FC<Props> = ({
  surveyTransaction,
  surveyTransactions,
  isScrollTarget,
  employee,
  _expanded,
  suggestedMentionUsers,
  currentUserId,
  comments,
  commentDisabled,
  commentFocused,
  reactions,
  loadTransactionComments,
  isActive,
}) => {
  const reactionUseCase = new ReactionUseCase();

  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { deleteComment } = useDeleteComment();
  const { handleCopy } = useClipboard();

  const [expanded, setExpanded] = useState(_expanded);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reactionModalOpen, setReactionModalOpen] = useState(false);
  const [deleteTargetCommentId, setDeleteTargetCommentId] = useState("");
  const deleteTargetComment = useMemo(
    () => comments.find(({ id }) => id === deleteTargetCommentId),
    [comments, deleteTargetCommentId]
  );
  const [submitCommentFormFocused, setSubmitCommentFormFocused] = useState(
    commentFocused && isScrollTarget
  );

  const fieldRef = React.useRef<HTMLInputElement>(null);

  const handleClickDeleteCommentButton = useCallback(
    async (commentId: string) => {
      setDeleteTargetCommentId("");
      await deleteComment(commentId, { transactionId: surveyTransaction.id });
      loadTransactionComments(surveyTransaction.id);
    },
    [deleteComment, loadTransactionComments, surveyTransaction.id]
  );
  const handleClickAddCommentButton = useCallback(() => {
    // Accordion が閉じている場合は開き切ってからスクロールする
    if (expanded) {
      setSubmitCommentFormFocused(true);
    } else {
      setExpanded(true);
      setTimeout(() => setSubmitCommentFormFocused(true), 300);
    }
    setAnchorEl(null);
  }, [expanded]);
  const handleBlurSubmitCommentForm = useCallback(() => setSubmitCommentFormFocused(false), []);

  const sendReaction = async (reactionComment: string) => {
    await reactionUseCase
      .send(reactionComment, surveyTransaction.id)
      .then(() => {
        reactions.push({
          employeeId: currentUser.id,
          name: currentUser.getName(),
          profileIconImageUrl: currentUser.profileIconImageUrl,
        });

        enqueueSnackbar("リアクションを送信しました", { variant: "success" });
      })
      .catch((e) => {
        enqueueSnackbar("リアクションを送信できませんでした", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "AccordionAnswerDetails:sendReaction" },
        });
      });
  };

  useScrollToTargetElement({
    enabled: isScrollTarget,
    targetRef: fieldRef,
  });

  // アコーディオンの行は開くときだけ反応する
  const handleClickAccordionRow = useCallback(() => {
    setExpanded(true);
  }, []);

  const handleClickMenuVert = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Accordion を開閉させない
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickExpandIcon = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Accordion を開閉させない
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCopyButton = () => {
    handleCopy(
      `${location.origin}/employee/${employee.id}?answerId=${surveyTransaction.id}`,
      "リンク"
    );
    setAnchorEl(null);
  };

  const handleOpenReactionModal = () => {
    setAnchorEl(null);
    setReactionModalOpen(true);
  };

  return (
    <div ref={fieldRef}>
      <Box my={2}>
        {surveyTransaction && (
          <StyledAccordion
            square
            key={surveyTransaction.id}
            expanded={expanded}
            onChange={handleClickAccordionRow}
            $isActive={isActive}
          >
            <AccordionSummary
              aria-label="Expand"
              aria-controls={`panel1a-content-${surveyTransaction.id}`}
              id={surveyTransaction.id}
              expandIcon={
                <Tooltip title={expanded ? "閉じる" : "開く"} placement="bottom">
                  <span>
                    <IconButton
                      icon="chevronDown"
                      size="sm"
                      onClick={(e) => handleClickExpandIcon(e)}
                    />
                  </span>
                </Tooltip>
              }
            >
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body1">{surveyTransaction.contents.title}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption">
                    {`${
                      surveyTransaction.answeredAt
                        ? format(surveyTransaction.answeredAt, TIMESTAMP_FORMAT)
                        : ""
                    } 回答済み`}
                  </Typography>
                </Grid>
                <Box ml="auto" mr={1.5}>
                  <IconButton icon="menuVert" onClick={(e) => handleClickMenuVert(e)} />
                </Box>
              </Grid>
            </AccordionSummary>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem key="send-reaction" onClick={handleOpenReactionModal}>
                <Typography variant="body2">入社者へリアクションを送る</Typography>
              </MenuItem>
              <MenuItem key="copy-share-link" onClick={handleClickCopyButton}>
                <Typography variant="body2">共有リンクをコピー</Typography>
              </MenuItem>
              <MenuItem key="add-comment" onClick={handleClickAddCommentButton}>
                <Typography variant="body2">コメントを追加</Typography>
              </MenuItem>
            </Menu>
            <Box pl={3} pr={3}>
              <Divider />
            </Box>
            <AccordionDetails>
              {expanded && (
                <Box width={1} py={3}>
                  <SurveyResult
                    surveyTransaction={surveyTransaction}
                    currentUser={currentUser}
                    newHire={employee}
                    surveyTransactions={surveyTransactions}
                    isSurveyTab
                  />
                  <Box mt={3} mb={5}>
                    <Divider />
                  </Box>
                  <Box display="flex" maxHeight={500}>
                    <CommentList
                      comments={comments}
                      currentUserId={currentUserId}
                      transactionId={surveyTransaction.id}
                      commentDisabled={commentDisabled}
                      submitCommentFormFocused={submitCommentFormFocused}
                      suggestedMentionUsers={suggestedMentionUsers}
                      isCloseGapNameAndDate
                      onBlurSubmitCommentForm={handleBlurSubmitCommentForm}
                      onClickDeleteCommentButton={(commentId) =>
                        setDeleteTargetCommentId(commentId)
                      }
                      loadTransactionComments={loadTransactionComments}
                    />
                  </Box>
                  <Box height="40px" />
                  <StyledDivider orientation="horizontal" />
                  <Box height="20px" />
                  <ReactionContent
                    transactionId={surveyTransaction.id}
                    reactions={reactions}
                    newHire={employee}
                    onClickReactionButton={() => setReactionModalOpen(true)}
                  />
                </Box>
              )}
            </AccordionDetails>
          </StyledAccordion>
        )}
        {deleteTargetComment && (
          <DeleteCommentConfirmModal
            open={!!deleteTargetCommentId}
            comment={deleteTargetComment}
            onCancel={() => setDeleteTargetCommentId("")}
            onAccept={handleClickDeleteCommentButton}
          />
        )}
        {reactionModalOpen && (
          <SendReactionModal
            open={reactionModalOpen}
            onSubmit={sendReaction}
            onCancel={() => setReactionModalOpen(false)}
          />
        )}
      </Box>
    </div>
  );
};

const StyledAccordion = styled(Accordion)<{ $isActive: boolean }>`
  border-radius: 16px;
  padding: 0 12px;

  ${(props) => props.$isActive && mixin.outlineAnimation()}

  .MuiAccordionSummary-root.Mui-expanded {
    cursor: default;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0 -40px !important;
`;
