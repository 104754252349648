import { Box } from "@material-ui/core";
import { DifferenceDate } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { DifferenceDateForm } from "~/components/domains/differenceDate";
import { DifferenceDateFormType } from "~/components/domains/differenceDate/DifferenceDateForm/DifferenceDateForm";
import { Typography } from "~/components/uiParts";

type Props = {
  dueDate: DifferenceDate;
  units: DifferenceDateFormType["unit"][];
  caption: string;
  readOnly?: boolean;
  isDeliveryTime?: boolean;
  setDueDate: (value: Optional<DifferenceDateFormType, "amount">) => void;
};
export const DueDateForm: FC<Props> = ({
  dueDate,
  units,
  caption,
  readOnly = false,
  isDeliveryTime,
  setDueDate,
}) => {
  return (
    <>
      <Box mt="36px" display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          期日
        </Typography>
      </Box>
      <Box mt="16px">
        <StyledMutedTypography variant="caption">{caption}</StyledMutedTypography>
      </Box>
      <Box mt="16px">
        <DifferenceDateForm
          differenceDate={dueDate}
          onChange={(dueDate) => setDueDate(dueDate)}
          readOnly={readOnly}
          units={units}
          isDeliveryTime={isDeliveryTime}
        />
      </Box>
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
