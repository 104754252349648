import { Box } from "@material-ui/core";
import { ChartItem } from "@onn/common";
import React, { FC, useState, useEffect, useMemo } from "react";

import { DifferenceSatisfaction, Loading } from "~/components/uiParts";
import { useSurveyTransactionScores } from "~/hooks/transaction";

type Props = {
  scoreVariant?: "body1" | "h3";
  arrowVariant?: "body2" | "h4";
  employeeId: string;
};

export const EmployeeDifferenceSatisfaction: FC<Props> = ({
  employeeId,
  scoreVariant = "body1",
  arrowVariant = "body2",
}) => {
  const { isLoading, data: surveyTransactionScores } = useSurveyTransactionScores({
    employeeIds: [employeeId],
  });
  const chartItems = useMemo(
    () => (surveyTransactionScores ? surveyTransactionScores[employeeId] : []),
    [employeeId, surveyTransactionScores]
  );

  const [latelySatisfaction, setLatelySatisfaction] = useState<number | undefined>(undefined);
  const [previousSatisfaction, setPreviousSatisfaction] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!chartItems) return;

    if (chartItems?.length > 0) {
      let lastPoint: ChartItem | null = null;
      chartItems
        .filter((p) => p.answer)
        .map((point) => {
          setLatelySatisfaction(point.answer);
          setPreviousSatisfaction(lastPoint?.answer);
          lastPoint = point;
        });
    }
  }, [chartItems]);

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {isLoading ? (
        <Loading size={"small"} />
      ) : (
        <DifferenceSatisfaction
          latelySatisfaction={latelySatisfaction}
          previousSatisfaction={previousSatisfaction}
          hasPrevious={false}
          scoreVariant={scoreVariant}
          arrowVariant={arrowVariant}
        />
      )}
    </Box>
  );
};
