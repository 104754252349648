import { OnboardingTaskWithNewHire } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { FindOnboardingTaskByTenantIdUseCase } from "~/service/usecases/onboardingTask";

const findOnboardingTaskByTenantIdUseCase = new FindOnboardingTaskByTenantIdUseCase();

export const generateUseOnboardingTasksKeys = (tenantId: string) => {
  return ["onboardingTask", tenantId];
};

/**
 * tenant毎にonboardingTasksを返すswr
 * @param {string} tenantId 対象のtenantのid
 */
export const useOnboardingTasks = (
  tenantId: string
): SWRResponse<OnboardingTaskWithNewHire[], Error> => {
  const { currentUser } = useCurrentUser();
  const keys = generateUseOnboardingTasksKeys(tenantId);

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    findOnboardingTaskByTenantIdUseCase.execute(tenantId, currentUser)
  );
};
