import { Employee, displayRecruitmentStatusMap } from "@onn/common";
import { useEffect, useMemo, useState } from "react";

import { useAllNewcomers } from "~/hooks/employee";

type UseParseCsvArgs = {
  currentSelectedNewGraduates: Employee[];
  registeredEmployeeIds: string[];
  file: File | undefined;
  canUseNotRegistered: boolean;
};

type UseParseCsvReturn =
  | {
      status: "success";
      result: { emails: string[] };
    }
  | {
      status: "error";
      errors: string[];
    }
  | {
      status: "none";
    };

export function useParseCsv({
  currentSelectedNewGraduates,
  registeredEmployeeIds,
  file,
  canUseNotRegistered,
}: UseParseCsvArgs): UseParseCsvReturn {
  const { data: allNewGraduates } = useAllNewcomers();

  const currentSelectedEmployeeIds = useMemo(
    () => new Set(currentSelectedNewGraduates.map((e) => e.id)),
    [currentSelectedNewGraduates]
  );

  const [result, setResult] = useState<UseParseCsvReturn>({ status: "none" });
  const reader = useMemo(() => new FileReader(), []);

  reader.onload = () => {
    if (typeof reader.result !== "string") {
      return;
    }
    const [headers, ...records] = reader.result
      .trimEnd()
      .split(/\r\n|\r|\n/)
      .map((row) => row.split(",").map((cell) => cell.replaceAll(/^"|"$/g, "")));

    if ((headers && headers.length !== 1) || (headers && headers[0] !== "メールアドレス")) {
      setResult({
        status: "error",
        errors: [
          "ヘッダーの形式が不正です。サンプルファイルを参照し、正しい形式のヘッダーを使用してください。",
        ],
      });
      return;
    }

    const errors = validateRecords({
      records,
      allNewGraduates,
      registeredEmployeeIds: new Set(registeredEmployeeIds),
      currentSelectedEmployeeIds,
      canUseNotRegistered,
    });
    if (errors.length > 0) {
      setResult({
        status: "error",
        errors: errors.map((e) => `${e.rowIndex + 1}行目: ${e.error}`),
      });
      return;
    }

    const emails = records.flatMap((r) => r[0]) as (typeof records)[number];
    setResult({ status: "success", result: { emails } });
  };

  useEffect(() => {
    if (file) {
      reader.readAsText(file);
    }
  }, [file, reader]);

  return result;
}

type RecordValidationError = {
  rowIndex: number;
  error: string;
};

function validateRecords({
  records,
  allNewGraduates,
  registeredEmployeeIds,
  currentSelectedEmployeeIds,
  canUseNotRegistered,
}: {
  records: string[][];
  allNewGraduates: Employee[] | undefined;
  registeredEmployeeIds: Set<string>;
  currentSelectedEmployeeIds: Set<string>;
  canUseNotRegistered: boolean;
}): RecordValidationError[] {
  const recordErrors: RecordValidationError[] = [];
  const emails: { rowIndex: number; email: string }[] = [];
  /** key: email, value: newGraduate */
  const newGraduateMap = new Map(allNewGraduates?.map((e) => [e.email, e]));

  records.forEach((r, index) => {
    if (r.length !== 1) {
      recordErrors.push({ rowIndex: index, error: "形式が不正です。" });
      return;
    }
    const email = r[0] as (typeof r)[number];
    if (!email.match(/.+@.+\..+/)) {
      recordErrors.push({ rowIndex: index, error: "メールアドレスの形式が不正です。" });
      return;
    }

    const duplicateEmail = emails.find((e) => e.email === email);
    if (duplicateEmail) {
      recordErrors.push({
        rowIndex: index,
        error: `${duplicateEmail.rowIndex + 1}行目と同じメールアドレスです。`,
      });
      return;
    }
    emails.push({ rowIndex: index, email });

    const newGraduate = newGraduateMap.get(email);
    if (!newGraduate) {
      recordErrors.push({
        rowIndex: index,
        error: "該当メールアドレスに一致する候補者が見つかりません。",
      });
      return;
    }
    if (!newGraduate.isRegistered() && !newGraduate.hasInvitedAt()) {
      recordErrors.push({
        rowIndex: index,
        error: "該当メールアドレスに一致する候補者は未招待です。",
      });
      return;
    }
    if (!canUseNotRegistered) {
      if (!newGraduate.isRegistered()) {
        recordErrors.push({
          rowIndex: index,
          error: "該当メールアドレスに一致する候補者はアカウント登録完了前です。",
        });
        return;
      }
    }
    if (newGraduate.deleted) {
      recordErrors.push({
        rowIndex: index,
        error: "該当メールアドレスに一致する候補者は削除されています。",
      });
      return;
    }
    if (newGraduate.recruitmentStatus === "rejected") {
      recordErrors.push({
        rowIndex: index,
        error: `該当メールアドレスに一致する候補者の選考ステータスは${displayRecruitmentStatusMap["rejected"]}です。`,
      });
      return;
    }
    if (newGraduate.recruitmentStatus === "withdrew") {
      recordErrors.push({
        rowIndex: index,
        error: `該当メールアドレスに一致する候補者の選考ステータスは${displayRecruitmentStatusMap["withdrew"]}です。`,
      });
      return;
    }
    if (registeredEmployeeIds.has(newGraduate.id)) {
      recordErrors.push({
        rowIndex: index,
        error: `該当メールアドレスに一致する候補者は既に登録されています。`,
      });
      return;
    }
    if (currentSelectedEmployeeIds.has(newGraduate.id)) {
      recordErrors.push({
        rowIndex: index,
        error: `該当メールアドレスに一致する候補者は既に選択されています。`,
      });
      return;
    }
  });

  return recordErrors;
}
