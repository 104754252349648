import { v4 } from "uuid";

import { OnboardingExperienceTaskTemplate } from "../../OnboardingExperienceTaskTemplate";
import { OnboardingTaskMemo } from "../../OnboardingTask";
import {
  OnboardingExperienceTask,
  taskTypes,
} from "../OnboardingExperienceTask/OnboardingExperienceTask";

const MAX_TITLE_NUM = 70;

export class OnboardingExperienceSimpleTask extends OnboardingExperienceTask {
  id: string;
  type = taskTypes.SIMPLE_TASK;
  filePaths: (File | string)[];
  memos: OnboardingTaskMemo[];

  static MAX_TITLE_NUM = MAX_TITLE_NUM;

  constructor(init: Omit<OnboardingExperienceSimpleTask, "updateForUpdatingTemplate">) {
    super(init);
    this.id = init.id;
    this.filePaths = init.filePaths;
    this.memos = init.memos;
  }

  updateForUpdatingTemplate(
    template: OnboardingExperienceTaskTemplate
  ): OnboardingExperienceSimpleTask {
    return new OnboardingExperienceSimpleTask({
      ...this,
      title: template.title,
      body: template.description,
    });
  }

  public static create(
    params: Optional<Omit<OnboardingExperienceSimpleTask, "updateForUpdatingTemplate">, "id">
  ) {
    return new OnboardingExperienceSimpleTask({
      ...params,
      id: params.id ?? v4(),
    });
  }
}
