import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Typography, Notice } from "~/components/uiParts";

type Props = {
  tenantName: string;
  onClick: () => void;
};

export const FrontPage: FC<Props> = ({ tenantName, onClick }) => (
  <StyledBox px={3} pt={5}>
    <Notice title="🎉Welcome!🎉" showCloseAnnotation={false}>
      <Typography variant="body1" color="textPrimary">
        {`ようこそ、${tenantName}へ！\n${tenantName}では新しいメンバーの入社体験を大事にしています。\n入社に関する不安を解消できるようサポートしていきますので、${tenantName}での最高の入社体験をスタートしましょう！`}
      </Typography>

      <Box height="24px" />

      <Button color="primary" variant="contained" borderRadius="circle" fullWidth onClick={onClick}>
        オンボーディングを始める
      </Button>
    </Notice>
  </StyledBox>
);

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;
