import { Box } from "@material-ui/core";
import { Employee, SlackUser } from "@onn/common";
import React, { FC, useCallback, useState, useEffect } from "react";
import styled from "styled-components";

import { SlackUserList, SlackUserSearchForm } from "~/components/domains/slackUsers";
import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { useSlackUsers } from "~/hooks/slackUser";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (selectedSlackUser: SlackUser) => Promise<void>;
  employee: Employee;
  currentUser: Employee;
};

export const IntegrateSlackModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  employee,
  currentUser,
}) => {
  const { slackUsers, loadingSlackUsers } = useSlackUsers();

  const [displaySlackUsers, setDisplaySlackUsers] = useState<SlackUser[]>([]); // クライアント検索処理で再構成される表示用の配列
  const [selectedSlackUser, setSelectedSlackUser] = useState<SlackUser>();
  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!selectedSlackUser) return;

    setSending(true);
    await onSubmit(selectedSlackUser).finally(() => {
      setSending(false);
      onCancel();
    });
  }, [onCancel, onSubmit, selectedSlackUser]);

  useEffect(() => {
    setDisplaySlackUsers(slackUsers);

    if (!employee.slackUserId) {
      return;
    }

    const selectedSlackUser = slackUsers.find((su) => su.slackUserId === employee.slackUserId);
    if (selectedSlackUser) setSelectedSlackUser(selectedSlackUser);
  }, [currentUser, employee, slackUsers]);

  return (
    <Modal
      open={open}
      title="Slackアカウント連携"
      content={
        <Box pr={3} pl={3} display="flex" alignItems="center">
          <StyledContainer>
            <Box textAlign="center">
              <UserIcon
                username={employee.getName()}
                profileIconImageUrl={employee.profileIconImageUrl}
                size="medium"
                borderColor="primary"
              />
              <Box pb={2} />
              <Typography variant="body2" align="center">
                {`${employee.getName()}さんのSlackアカウントを\n連携してください`}
              </Typography>
            </Box>
          </StyledContainer>

          <Box m={2} />

          <StyledContainer>
            <SlackUserSearchForm
              variant="standard"
              showPlaceholder
              slackUsers={slackUsers}
              onSearch={(results) => setDisplaySlackUsers(results)}
            />
            <SlackUserList
              slackUsers={displaySlackUsers}
              selectedSlackUser={selectedSlackUser}
              onSelect={setSelectedSlackUser}
              isLoading={loadingSlackUsers}
            />
          </StyledContainer>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedSlackUser || sending}
          >
            設定する
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledContainer = styled.div`
  width: 342px;
  display: inline-block;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
