import { Grid } from "@material-ui/core";
import { Employee, MessageContent } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../employees/hooks";

import { Paper, UserIconGroup, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  progressMessageContents: MessageContent[];
  completedMessageContents: MessageContent[];
  newHire: Employee;
  onClickUserIconGroup: () => void;
  isActive: boolean;
};

export const ManageMessageTaskPaper: FC<Props> = ({
  progressMessageContents,
  completedMessageContents,
  newHire,
  onClickUserIconGroup,
  isActive,
}) => {
  const { getBorderColorById } = useGetBorderColorById();

  return (
    <StyledPaper $isActive={isActive}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <StyledTypography variant="body2" bold color="textPrimary">
            記入依頼中({progressMessageContents.length})
          </StyledTypography>
          {progressMessageContents.length ? (
            <UserIconGroup
              usersInfo={progressMessageContents.map((u) => ({
                username: u.name,
                profileIconImageUrl: u.iconPath,
                borderColor: u.requestedEmployeeId
                  ? getBorderColorById(newHire, u.requestedEmployeeId)
                  : undefined,
              }))}
              max={9}
              tooltip
              onClick={onClickUserIconGroup}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              すべてのメンバーの記入が完了しました 🎉
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <StyledTypography variant="body2" bold color="textPrimary">
            記入済み({completedMessageContents.length})
          </StyledTypography>
          {completedMessageContents.length ? (
            <UserIconGroup
              usersInfo={completedMessageContents.map((u) => ({
                username: u.name,
                profileIconImageUrl: u.iconPath,
                borderColor: u.requestedEmployeeId
                  ? getBorderColorById(newHire, u.requestedEmployeeId)
                  : undefined,
              }))}
              max={9}
              tooltip
              onClick={onClickUserIconGroup}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              現在、記入済みのメンバーはいません。
            </Typography>
          )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: inline-block;
    margin-bottom: 16px;
  }
`;

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;
