import { useState, useCallback } from "react";
import { mutate } from "swr";

import { useSnackbar } from "../shared/useSnackbar";

import { generateUseOnboardingTask } from "./useOnboardingTask";

import { UpdateOnboardingTaskMemoUseCase } from "~/service/usecases/onboardingTask";
import { captureException } from "~/util";

const updateOnboardingTaskMemoUseCase = new UpdateOnboardingTaskMemoUseCase();
/**
 * onboardingTaskのMemoを更新する関するを提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns updateOnboardingTask
 */
export const useUpdateOnboardingTaskMemo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * onboardingTaskのMemoを更新する
   * @param {string} newOnboardingTaskId
   * @param {string} text
   * @param {string} employeeId
   */
  const updateOnboardingTaskMemo = useCallback(
    async (newOnboardingTaskId: string, text: string, employeeId: string) => {
      setIsLoading(true);
      await updateOnboardingTaskMemoUseCase
        .execute(newOnboardingTaskId, text, employeeId)
        .then(() => {
          mutate(generateUseOnboardingTask(newOnboardingTaskId));
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useUpdateOnboardingTaskMemo:updateOnboardingTaskMemo",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return { isLoading, updateOnboardingTaskMemo };
};
