import { MenuItem } from "@material-ui/core";
import { ContactMessageTemplate } from "@onn/common";

import React, { FC } from "react";
import styled from "styled-components";

import { Typography, IconButton, TooltipWhenTextTruncated } from "~/components/uiParts";

type Props = {
  contactMessageTemplate: ContactMessageTemplate;
  reflectMessage: (v: string) => void;
  closeMenu: () => void;
  openTemplateChangeMenu: (value: React.SetStateAction<HTMLElement | null>) => void;
};

export const TemplateMenuItem: FC<Props> = ({
  contactMessageTemplate,
  reflectMessage,
  closeMenu,
  openTemplateChangeMenu,
}) => {
  return (
    <>
      <StyledMenuItem
        onClick={() => {
          reflectMessage(contactMessageTemplate.text);
          closeMenu();
        }}
      >
        <TooltipWhenTextTruncated text={contactMessageTemplate.title}>
          {(ref) => (
            <>
              <Typography variant="body2" noWrap ref={ref}>
                {contactMessageTemplate.title}
              </Typography>
              <IconButton
                icon="menuVert"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openTemplateChangeMenu(e.currentTarget);
                }}
              />
            </>
          )}
        </TooltipWhenTextTruncated>
      </StyledMenuItem>
    </>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 2px;
    padding-bottom: 2px;

    // TooltipWhenTextTruncated を使用するために、root, span のそれぞれに flex style が必要
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
