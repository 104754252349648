import { ContactRoom } from "@onn/common";
import { useCallback, useState } from "react";

import { useCurrentUser } from "../employee";
import { useSnackbar } from "../shared";

import { ContactRoomRepository } from "~/infrastructure/api/contactRoomRepository";
import { UpdateIsCompletedUseCase } from "~/service/usecases/contactRoom";
import { captureException } from "~/util";

const updateIsCompletedUseCase = new UpdateIsCompletedUseCase(new ContactRoomRepository());

export const useUpdateIsCompleted = (
  onUpdateSuccess?: (contactRoomId: string) => Promise<void>
): {
  isLoading: boolean;
  updateIsCompleted: (contactRoomId: string, isCompleted: boolean) => Promise<ContactRoom | void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  /**
   * ルームを対応済みに更新するための関数
   */
  const updateIsCompleted = useCallback(
    async (contactRoomId: string, isCompleted: boolean) => {
      setIsLoading(true);
      try {
        await updateIsCompletedUseCase.execute(currentUser, contactRoomId, isCompleted);
        if (onUpdateSuccess) {
          await onUpdateSuccess(contactRoomId);
        }
        enqueueSnackbar("対応状況を更新しました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar((e as Error).message, { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useUpdateIsCompleted:updateIsCompleted" },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [currentUser, enqueueSnackbar, onUpdateSuccess]
  );

  return { isLoading, updateIsCompleted };
};
