import { ContactMessage, Employee, AllContactRoom } from "@onn/common";
import { ComponentProps, useMemo } from "react";

import { UserIcon } from "~/components/uiParts";

export type AlreadyReadUsersInfoType = {
  username: ComponentProps<typeof UserIcon>["username"];
  profileIconImageUrl?: ComponentProps<typeof UserIcon>["profileIconImageUrl"];
  borderColor?: ComponentProps<typeof UserIcon>["borderColor"];
};

/**
 * コンタクトルームの最新の既読メッセージに紐づくユーザー情報を返すhooks
 */
export const useLatestAlreadyReadUsersInfoV2 = (
  contactRoom: AllContactRoom,
  currentUserId: string,
  accessibleEmployees: Employee[],
  contactMessages: ContactMessage[]
) => {
  const latestAlreadyReadUsersInfo = useMemo<{
    [key: ContactMessage["id"]]: AlreadyReadUsersInfoType[];
  }>(() => {
    if (contactMessages.length === 0) return {};

    // const roomTarget = contactRoom.roomTarget;

    const roomTarget = contactRoom.employee;

    const readableUsers: Array<Employee> = [...accessibleEmployees];

    /**
     * 以下の処理では、入社者の情報をreadableUsersに追加している。
     * 既読バッジの表示には、accessibleEmployeesに加えて、入社者の情報も必要なため。
     */
    if (roomTarget instanceof Employee) {
      readableUsers.push(roomTarget);
    }

    return contactRoom.readLogs.reduce((returnValue, readLog) => {
      // 既読ユーザー情報を取得
      const alreadyReadUser = readableUsers.find((employee) => employee?.id === readLog.employeeId);

      if (!alreadyReadUser) return returnValue;

      const isNewHire = alreadyReadUser.id === roomTarget?.id;
      const alreadyUserInfo: AlreadyReadUsersInfoType = {
        username: alreadyReadUser.getName(),
        profileIconImageUrl: alreadyReadUser.profileIconImageUrl,

        // 入社者のみ枠線をつける
        borderColor: isNewHire ? "primary" : undefined,
      };

      // 既読ユーザーの最新既読メッセージIDを取得
      const copyMessages = [...contactMessages]; // reverse() は破壊的変更のためコピー
      const alreadyReadLatestMessageId = copyMessages
        .reverse()
        .find(
          (message) => message.createdAt < readLog.readAt && currentUserId !== alreadyReadUser.id
        )?.id;
      if (!alreadyReadLatestMessageId) return returnValue;

      // returnValueにメッセージに紐づくユーザー情報を追加する
      const returnValueByMessageId = returnValue[alreadyReadLatestMessageId];
      if (returnValueByMessageId) {
        // メッセージに紐づくデータがすでに存在すれば、配列にデータを追加。入社者の場合は先頭に追加する
        returnValue[alreadyReadLatestMessageId] = isNewHire
          ? [alreadyUserInfo, ...returnValueByMessageId]
          : [...returnValueByMessageId, alreadyUserInfo];
      } else {
        // メッセージに紐づくデータが存在しなければ、そのままデータを追加
        returnValue[alreadyReadLatestMessageId] = [alreadyUserInfo];
      }
      return returnValue;
    }, {} as { [key: ContactMessage["id"]]: AlreadyReadUsersInfoType[] });
  }, [contactMessages, accessibleEmployees, contactRoom, currentUserId]);

  return {
    latestAlreadyReadUsersInfo,
  };
};
