import { ContactRoom, Employee } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useSPEmployeeDetailModal = () => {
  const { handleModal } = useModal();

  const handleOpenSPEmployeeDetailModal = useCallback(
    (employee: Employee, contactRoom: ContactRoom) =>
      handleModal({
        name: "spEmployeeDetailModal",
        args: {
          employee,
          contactRoom,
        },
      }),
    [handleModal]
  );

  return {
    handleOpenSPEmployeeDetailModal,
  };
};
