import {
  OnboardingSimpleTask,
  DifferenceDate,
  removeUndefinedFromObject,
  OnboardingTaskFactory,
  OnboardingTaskType,
  OnboardingTaskMemo,
  OnboardingGeneralTask,
  MessageContent,
  OnboardingMessageTask,
} from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { chunk } from "lodash";

import { queryOperator } from "./queryOperator";

import { firestore } from "~/config/firebase";
import { IOnboardingTaskRepository } from "~/service/repository/iOnboardingTaskRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type DifferenceDateForDB = Pick<
  DifferenceDate,
  "deliveryTime" | "amount" | "unit" | "direction" | "referenceDateType"
>;

type OnboardingTaskMemoForDB = Omit<
  OnboardingTaskMemo,
  "update" | "getShouldNotify" | "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

type OnboardingGeneralTaskForDB = Omit<
  OnboardingGeneralTask,
  | "createdAt"
  | "updatedAt"
  | "deliveryDate"
  | "dueDate"
  | "memos"
  | "isExpiredByEmployee"
  | "shouldDelivery"
  | "shouldRemind"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deliveryDate?: DifferenceDateForDB;
  dueDate: DifferenceDateForDB;
  memos: OnboardingTaskMemoForDB[];
};

type OnboardingSimpleTaskForDB = Omit<
  OnboardingSimpleTask,
  | "createdAt"
  | "updatedAt"
  | "deliveryDate"
  | "dueDate"
  | "memos"
  | "isExpiredByEmployee"
  | "shouldDelivery"
  | "shouldRemind"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deliveryDate?: DifferenceDateForDB;
  dueDate: DifferenceDateForDB;
  memos: OnboardingTaskMemoForDB[];
};

type MessageContentForDB = Omit<
  ConstructorParameters<typeof MessageContent>[0],
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

type OnboardingMessageTaskForDB = Omit<
  ConstructorParameters<typeof OnboardingMessageTask>[0],
  | "createdAt"
  | "updatedAt"
  | "deliveryDate"
  | "dueDate"
  | "memos"
  | "isExpiredByEmployee"
  | "contents"
  | "shouldDelivery"
  | "shouldRemind"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deliveryDate?: DifferenceDateForDB;
  dueDate: DifferenceDateForDB;
  memos: OnboardingTaskMemoForDB[];
  contents: MessageContentForDB[];
};

type OnboardingTaskForDB =
  | OnboardingGeneralTaskForDB
  | OnboardingSimpleTaskForDB
  | OnboardingMessageTaskForDB;

const COLLECTION_NAME = "onboardingTasks";
const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10;

export class OnboardingTaskRepository implements IOnboardingTaskRepository {
  async findById(id: string): Promise<OnboardingTaskType> {
    const doc = await getDoc(this.doc(id));

    if (!doc.data()) {
      throw new Error("no onboardingTask found");
    }

    return this.dbToObject(doc.data() as OnboardingTaskForDB);
  }
  async findByTenantId(tenantId: string): Promise<OnboardingTaskType[]> {
    return await getDocs(query(this.collection(), where("tenantId", "==", tenantId))).then(
      async (snapshot) => {
        return snapshot.docs.map((doc) => {
          return this.dbToObject(doc.data() as OnboardingTaskForDB);
        });
      }
    );
  }

  async create(newOnboardingTask: OnboardingTaskType): Promise<OnboardingTaskType> {
    const ref = this.doc(newOnboardingTask.id);
    await setDoc(ref, this.convertToPlainObject(newOnboardingTask));

    return newOnboardingTask;
  }

  async completeOnboardingTasks(onboardingTaskIds: string[]): Promise<void> {
    const batch = queryOperator.batch();

    onboardingTaskIds.forEach((onboardingTaskId) => {
      batch.update(this.doc(onboardingTaskId), {
        status: "COMPLETED",
      });
    });

    return await batch.commit();
  }

  async findByEmployeeId(employeeId: string): Promise<OnboardingTaskType[]> {
    return await getDocs(query(this.collection(), where("employeeId", "==", employeeId))).then(
      async (activityLogSnapshot) => {
        return activityLogSnapshot.docs.flatMap((doc) => {
          return this.dbToObject(doc.data() as OnboardingSimpleTaskForDB);
        });
      }
    );
  }

  async findByEmployeeIds(employeeIds: string[]): Promise<OnboardingTaskType[]> {
    const onboardingTasks: OnboardingTaskType[] = [];

    await Promise.all(
      chunk(employeeIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map(async (chunkedIds) => {
        const { docs } = await getDocs(
          query(this.collection(), where("employeeId", "in", chunkedIds))
        );
        onboardingTasks.push(
          ...docs.map((doc) => this.dbToObject(doc.data() as OnboardingTaskForDB))
        );
      })
    );

    return onboardingTasks;
  }

  async update(onboardingTaskId: string, updates: OnboardingTaskType): Promise<void> {
    const ref = this.doc(onboardingTaskId);

    await updateDoc(ref, this.convertToPlainObject(updates));
  }

  async deleteById(onboardingTaskId: string): Promise<void> {
    return await deleteDoc(this.doc(onboardingTaskId));
  }

  async deleteByIds(onboardingTaskIds: string[]): Promise<void> {
    const batch = queryOperator.batch();

    onboardingTaskIds.forEach((onboardingTaskId) => {
      batch.delete(this.doc(onboardingTaskId));
    });

    return await batch.commit();
  }

  async whereByActionId(
    actionId: OnboardingGeneralTask["actionId"],
    employeeId: string
  ): Promise<OnboardingGeneralTask[]> {
    return await getDocs(
      query(
        this.collection(),
        where("actionId", "==", actionId),
        where("employeeId", "==", employeeId)
      )
    ).then(async (snapshot) => {
      return snapshot.docs.map((doc) => {
        // actionIdで検索しているためOnboardingGeneralTaskしか返ってこない
        return this.dbToObject(doc.data() as OnboardingTaskForDB) as OnboardingGeneralTask;
      });
    });
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private convertToPlainObject(onboardingTask: OnboardingTaskType): OnboardingTaskForDB {
    if (onboardingTask.type === "MESSAGE_TASK") {
      return removeUndefinedFromObject({
        ...onboardingTask,
        deliveryDate: onboardingTask.deliveryDate ? { ...onboardingTask.deliveryDate } : undefined,
        dueDate: { ...onboardingTask.dueDate },
        createdAt: convertDateToTimestamp(onboardingTask.createdAt),
        updatedAt: convertDateToTimestamp(onboardingTask.updatedAt),
        memos: onboardingTask.memos.map((memo) => {
          return {
            ...memo,
            createdAt: convertDateToTimestamp(memo.createdAt),
            updatedAt: convertDateToTimestamp(memo.updatedAt),
          };
        }),
        contents: onboardingTask.contents.map((content) => {
          return removeUndefinedFromObject({
            ...content,
            createdAt: convertDateToTimestamp(content.createdAt),
            updatedAt: convertDateToTimestamp(content.updatedAt),
          });
        }),
      });
    } else if (onboardingTask.type === "GENERAL_TASK") {
      return removeUndefinedFromObject({
        ...onboardingTask,
        deliveryDate: onboardingTask.deliveryDate ? { ...onboardingTask.deliveryDate } : undefined,
        dueDate: { ...onboardingTask.dueDate },
        createdAt: convertDateToTimestamp(onboardingTask.createdAt),
        updatedAt: convertDateToTimestamp(onboardingTask.updatedAt),
        memos: onboardingTask.memos.map((memo) => {
          return {
            ...memo,
            createdAt: convertDateToTimestamp(memo.createdAt),
            updatedAt: convertDateToTimestamp(memo.updatedAt),
          };
        }),
      });
    }
    return removeUndefinedFromObject({
      ...onboardingTask,
      deliveryDate: onboardingTask.deliveryDate ? { ...onboardingTask.deliveryDate } : undefined,
      dueDate: { ...onboardingTask.dueDate },
      createdAt: convertDateToTimestamp(onboardingTask.createdAt),
      updatedAt: convertDateToTimestamp(onboardingTask.updatedAt),
      memos: onboardingTask.memos.map((memo) => {
        return {
          ...memo,
          createdAt: convertDateToTimestamp(memo.createdAt),
          updatedAt: convertDateToTimestamp(memo.updatedAt),
        };
      }),
    });
  }

  private dbToObject(object: OnboardingTaskForDB) {
    if (object.type === "MESSAGE_TASK") {
      return OnboardingMessageTask.create({
        ...object,
        deliveryDate: object.deliveryDate ? new DifferenceDate(object.deliveryDate) : undefined,
        dueDate: new DifferenceDate(object.dueDate),
        createdAt: convertTimestampToDate(object.createdAt),
        updatedAt: convertTimestampToDate(object.updatedAt),
        memos: object.memos.map(
          (memo) =>
            new OnboardingTaskMemo({
              ...memo,
              createdAt: convertTimestampToDate(memo.createdAt),
              updatedAt: convertTimestampToDate(memo.updatedAt),
            })
        ),
        contents: object.contents.map((content) => {
          return new MessageContent({
            ...content,
            createdAt: convertTimestampToDate(content.createdAt),
            updatedAt: convertTimestampToDate(content.updatedAt),
          });
        }),
      });
    }

    return OnboardingTaskFactory.createOnboardingTask({
      ...object,
      deliveryDate: object.deliveryDate ? new DifferenceDate(object.deliveryDate) : undefined,
      dueDate: new DifferenceDate(object.dueDate),
      createdAt: convertTimestampToDate(object.createdAt),
      updatedAt: convertTimestampToDate(object.updatedAt),
      memos: object.memos.map(
        (memo) =>
          new OnboardingTaskMemo({
            ...memo,
            createdAt: convertTimestampToDate(memo.createdAt),
            updatedAt: convertTimestampToDate(memo.updatedAt),
          })
      ),
    });
  }
}
