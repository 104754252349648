import { Menu, MenuItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useState, useCallback, MouseEvent, useMemo } from "react";

import { IconButton, Typography } from "~/components/uiParts";

type Props = {
  newHire: Employee;
  onClickAddMentorButton: () => void;
  onClickAddSupportMemberButton: () => void;
  onClickChangeEmployeeNameButton: () => void;
  onClickChangeJoinDateButton: () => void;
  onClickDeleteAccountButton: () => void;
  onClickIntegrateSlackButton: () => void;
  onClickResendInvitationButton: () => void;
  onClickCopyRegisterAccountLinkButton: (transactionId: string) => void;
  onClickChangeDepartmentButton: () => void;
  onClickGenerateTasksFromExperienceButton: () => void;
  disabledGenerateTasksFromExperienceButton: boolean;
};

export const EmployeeManageMenu: FC<Props> = ({
  newHire,
  onClickAddMentorButton,
  onClickAddSupportMemberButton,
  onClickChangeEmployeeNameButton,
  onClickChangeJoinDateButton,
  onClickDeleteAccountButton,
  onClickIntegrateSlackButton,
  onClickResendInvitationButton,
  onClickCopyRegisterAccountLinkButton,
  onClickChangeDepartmentButton,
  onClickGenerateTasksFromExperienceButton,
  disabledGenerateTasksFromExperienceButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // 画面遷移を防ぐpreventDefaultとstopPropagation,メニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = (e: MouseEvent<HTMLElement>, callback: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    callback();
  };

  const menuOptions = useMemo(() => {
    const optionsForUnregisteredNewHire = [];
    if (!newHire.uid) {
      // アカウント未登録の場合
      optionsForUnregisteredNewHire.push({
        title: "招待メール再送",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => onClickResendInvitationButton()),
      });
    }

    if (!newHire.uid) {
      // newHireRegistrationTransaction が存在している場合のみ（newHireRegistrationTransaction取得に時間がかかるためそれまで表示しないようにしている）
      optionsForUnregisteredNewHire.push({
        title: "招待リンクをコピー",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => onClickCopyRegisterAccountLinkButton(newHire.invitationToken)),
      });
    }

    return [
      ...optionsForUnregisteredNewHire,
      {
        title: "氏名変更",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => onClickChangeEmployeeNameButton()),
      },
      {
        title: "入社日設定",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickChangeJoinDateButton()),
      },
      {
        title: "バディを追加",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickAddMentorButton()),
      },
      {
        title: "サポートメンバーを追加",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => onClickAddSupportMemberButton()),
      },
      {
        title: "受け入れ体験設定",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => onClickGenerateTasksFromExperienceButton()),
        disabled: disabledGenerateTasksFromExperienceButton,
      },
      {
        title: "Slackアカウント連携",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickIntegrateSlackButton()),
      },
      {
        title: "部署変更",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => onClickChangeDepartmentButton()),
      },
      {
        title: "アカウント削除",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickDeleteAccountButton()),
      },
    ];
  }, [
    newHire,
    onClickAddMentorButton,
    onClickAddSupportMemberButton,
    onClickChangeEmployeeNameButton,
    onClickChangeJoinDateButton,
    onClickDeleteAccountButton,
    onClickIntegrateSlackButton,
    onClickResendInvitationButton,
    onClickChangeDepartmentButton,
    onClickCopyRegisterAccountLinkButton,
    onClickGenerateTasksFromExperienceButton,
    disabledGenerateTasksFromExperienceButton,
  ]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton icon="menuVert" onClick={handleOpenMenu} />
      <Menu
        key={newHire.id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.title} onClick={option.func} disabled={option.disabled}>
            <Typography variant="body2">{option.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
