import { Employee } from "../domain/Employee/Employee";
import { OnboardingStatus } from "../domain/Employee/OnboardingStatus";
import { Role } from "../domain/Employee/Role";

import { LangType } from "../domain/shared/LangType";

const URL = "uploads/profile/onn-logo-dev.jpg";

export const createIMockEmployee = (mock: Partial<Employee> = {}): Employee => {
  return new Employee({
    id: mock.id || "uuid",
    tenantId: mock.tenantId || "1",
    spaceId: mock.spaceId || "space",
    email: mock.email || "sample@example.com",
    firstName: mock.firstName || "太郎",
    lastName: mock.lastName || "山田",
    invitedAt: mock.invitedAt,
    lastInvitedAt: mock.lastInvitedAt,
    invitationToken: mock.invitationToken || "token",
    onboardingStatus: mock.onboardingStatus || OnboardingStatus.INVITED,
    onboardingStatuses: mock.onboardingStatuses || [],
    accountCreateAt: mock.accountCreateAt || "2016-02-23",
    joinAt: mock.joinAt === "" ? undefined : mock.joinAt || "2016-02-23",
    role: mock.role || Role.MEMBER,
    assignedAsNewcomer: mock.assignedAsNewcomer === undefined ? true : mock.assignedAsNewcomer,
    uid: mock.uid || undefined,
    slackUserId: mock.slackUserId,
    lineUserId: mock.lineUserId,
    profileIconImageUrl:
      mock.profileIconImageUrl === "" ? undefined : mock.profileIconImageUrl || URL,
    profile: mock.profile,
    mentorUserId: mock.mentorUserId || undefined,
    supportMemberEmployeeIds: mock.supportMemberEmployeeIds || [],
    departmentIds: mock.departmentIds || ["1", "3", "4", "5"], // createIMockDepartmentに則っている
    deleted: mock.deleted ?? false,
    lastRefreshTime: mock.lastRefreshTime,
    isFollowedLineOfficialAccount: mock.isFollowedLineOfficialAccount ?? false,
    recruitmentStatus: mock.recruitmentStatus,
    selectedAuthenticationType: "email",
    currentAuthenticationType: mock.currentAuthenticationType ?? "email",
    isNewGraduate: mock.isNewGraduate,
    lang: mock.lang ?? LangType.JA,
  });
};
