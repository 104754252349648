import { Box } from "@material-ui/core";
import { Employee, OnboardingGeneralTask } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import {
  AssigneeSection,
  DeliveryDateSection,
  DescriptionSection,
  DueDateSection,
  FileSection,
  NewHireSection,
  StatusSection,
} from "../parts";

import { OnboardingGeneralTaskControl } from "./OnboardingGeneralTaskControl";

import { Divider } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  currentUser: Employee;
  onboardingTask: OnboardingGeneralTask;
  newHire: Employee;
  assigneeEmployees: Employee[];
  isEnableNewHireLink?: boolean;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickGeneralTaskButton: () => void;
};

// ====================
// main
// ====================

export const OnboardingGeneralTaskSummarySidebar: FC<Props> = ({
  currentUser,
  onboardingTask,
  newHire,
  assigneeEmployees,
  isEnableNewHireLink,
  onClickEditButton,
  onClickDeleteTaskButton,
  onClickGeneralTaskButton,
}) => {
  // ====================
  // component
  // ====================

  return (
    <>
      <StyledBox display="flex" flexDirection="column" gridGap="32px" px="24px" py="32px">
        <DescriptionSection description={onboardingTask.body} />
        <FileSection
          filePaths={onboardingTask.filePaths.filter((v): v is string => typeof v === "string")}
        />

        <Divider />

        <NewHireSection isEnableNewHireLink={isEnableNewHireLink} newHire={newHire} />
        <AssigneeSection assigneeEmployees={assigneeEmployees} />
        {onboardingTask.deliveryDate && (
          <DeliveryDateSection deliveryDate={onboardingTask.deliveryDate} newHire={newHire} />
        )}
        <DueDateSection newHire={newHire} onboardingTask={onboardingTask} />
        <StatusSection newHire={newHire} onboardingTask={onboardingTask} />
      </StyledBox>

      <OnboardingGeneralTaskControl
        onboardingTask={onboardingTask}
        currentUser={currentUser}
        newHire={newHire}
        onClickGeneralTaskButton={onClickGeneralTaskButton}
        onClickEditButton={onClickEditButton}
        onClickDeleteTaskButton={onClickDeleteTaskButton}
      />
    </>
  );
};

// ====================
// style
// ====================

const HEADER_HEIGHT = 154;
const FOOTER_HEIGHT = 102;

const StyledBox = styled(Box)`
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
  overflow-y: auto;
`;
