import { Employee } from "@onn/common";

import { OnboardingExperienceTaskRepository } from "~/infrastructure/api/onboardingExperienceTaskRepository";
import { queryOperator } from "~/infrastructure/api/queryOperator";
import { factory } from "~/service/repository/iOnboardingExperienceTaskTemplateRepository";

const onboardingExperienceTaskTemplateRepository =
  factory.onboardingExperienceTaskTemplateRepository();
const onboardingExperienceTaskRepository = new OnboardingExperienceTaskRepository();

export class UpdateOnboardingExperienceTaskTemplateUseCase {
  async execute(
    currentUser: Employee,
    onboardingExperienceTaskId: string,
    params: { title: string; description: string }
  ): Promise<void> {
    const template = await onboardingExperienceTaskTemplateRepository.findById(
      onboardingExperienceTaskId
    );

    if (!template.isEditable(currentUser)) {
      throw new Error("タスクテンプレートを更新する権限がありません。");
    }

    const batch = queryOperator.batch();

    const newTemplate = template.update(currentUser.id, {
      title: params.title,
      description: params.description,
    });
    await onboardingExperienceTaskTemplateRepository.insertUpdateTemplateInBatch(
      batch,
      newTemplate
    );

    const tasks = await onboardingExperienceTaskRepository.findByTemplateId(template.id);
    tasks.forEach((task) => {
      const newTask = task.updateForUpdatingTemplate(newTemplate);
      onboardingExperienceTaskRepository.insertUpdateTaskInBatch(batch, newTask);
    });

    await queryOperator.commit(batch);
  }
}
