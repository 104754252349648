import { ButtonBase } from "@material-ui/core";
import React, { FC, ComponentProps, ReactNode } from "react";
import styled from "styled-components";

import { Loading } from "../Loading";

import theme from "~/config/theme";
import { mixin } from "~/util";

type BorderRadius = "circle" | "regular";
/**
 * primary: 送信などの緑色
 * secondary: 削除などの赤色
 */
type Color = "default" | "primary" | "secondary";
type Variant = "text" | "outlined" | "contained" | "dashedOutlined";

type Props = Pick<
  ComponentProps<typeof ButtonBase>,
  "onClick" | "disabled" | "type" | "disableTouchRipple" | "children"
> & {
  /**
   * primary: 送信などの緑色
   * secondary: 削除などの赤色
   */
  color: Color;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  isLoading?: boolean;
} & (
    | {
        borderRadius: BorderRadius;
        variant: "text";
      }
    | {
        borderRadius: BorderRadius;
        variant: "outlined" | "contained" | "dashedOutlined";
      }
  );

export const Button: FC<Props> = ({
  onClick,
  borderRadius,
  color = "default",
  variant,
  startIcon,
  endIcon,
  fullWidth,
  fullHeight,
  children,
  type,
  isLoading,
  disabled,
}) => {
  return (
    <StyledButtonBase
      onClick={onClick}
      disableRipple={variant === "text"}
      disabled={disabled || isLoading}
      type={type}
      $borderRadius={borderRadius}
      $fullWidth={Boolean(fullWidth)}
      $fullHeight={Boolean(fullHeight)}
      disableTouchRipple
    >
      <StyledDiv
        $borderRadius={borderRadius}
        $color={color}
        $variant={variant}
        $fullWidth={Boolean(fullWidth)}
        $fullHeight={Boolean(fullHeight)}
        $disabled={Boolean(disabled)}
        $withIcon={Boolean(startIcon)}
      >
        {isLoading ? (
          <>
            <StyledCenter>
              <Loading color="grey" size="small" />
            </StyledCenter>
            <StyledHidden>
              {startIcon}
              {children}
            </StyledHidden>
          </>
        ) : (
          <>
            {startIcon}
            {children}
            {endIcon}
          </>
        )}
      </StyledDiv>
    </StyledButtonBase>
  );
};

const StyledButtonBase = styled(ButtonBase)<{
  $borderRadius: BorderRadius;
  $fullWidth: boolean;
  $fullHeight: boolean;
}>`
  &.MuiButtonBase-root {
    ${(props) => `border-radius: ${borderRadiusStyleObject[props.$borderRadius]};`}
    ${(props) => props.$fullWidth && `width: 100%;`}
    ${(props) => props.$fullHeight && `height: 100%;`}
  }
`;

const paddingStyleObject = {
  plain: {
    regular: "8px 16px",
    circle: "16px 24px",
  },
  withIcon: {
    regular: "8px 16px 8px 8px",
    circle: "16px 24px 16px 16px",
  },
};

const borderRadiusStyleObject = {
  regular: "8px",
  circle: "9999px", // circleの場合高さの50%にするための記述
};

const colorStyleObject = {
  text: {
    default: {
      color: theme.palette.grey[400],
      backgroundColor: "transparent",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])},0.1)`,
    },
    primary: {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])},0.1)`,
    },
    secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: "transparent",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])},0.1)`,
    },
  },
  outlined: {
    default: {
      color: theme.palette.grey[400],
      backgroundColor: "white",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])},0.1)`,
    },
    primary: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(
        theme.palette.primary.main
      )},0.2)`,
    },
    secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: "white",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(
        theme.palette.secondary.main
      )},0.2)`,
    },
  },
  dashedOutlined: {
    default: {
      color: theme.palette.grey[400],
      backgroundColor: "white",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])},0.1)`,
    },
    primary: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(
        theme.palette.primary.main
      )},0.2)`,
    },
    secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: "white",
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(
        theme.palette.secondary.main
      )},0.2)`,
    },
  },
  contained: {
    default: {
      color: "white",
      backgroundColor: theme.palette.grey[400],
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])},0.7)`,
    },
    primary: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(
        theme.palette.primary.main
      )},0.7)`,
    },
    secondary: {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
      hoverBackgroundColor: `rgba(${mixin.generateRgbaFromColorCode(
        theme.palette.secondary.main
      )},0.7)`,
    },
  },
};

const borderColorStyleObject = {
  default: theme.palette.grey[200],
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
};

const disabledColorStyleObject = {
  text: theme.palette.grey[300],
  outlined: theme.palette.grey[200],
  dashedOutlined: theme.palette.grey[200],
  contained: "white",
};

const StyledDiv = styled.div<{
  $borderRadius: BorderRadius;
  $color: Color;
  $variant: Variant;
  $fullWidth: boolean;
  $fullHeight: boolean;
  $disabled: boolean;
  $withIcon: boolean;
}>`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  white-space: nowrap;
  column-gap: 4px;
  transition: opacity 250ms;
  transition: background-color 250ms;
  color: ${(props) => props.theme.palette.grey[400]};
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  ${(props) =>
    `padding: ${paddingStyleObject[props.$withIcon ? "withIcon" : "plain"][props.$borderRadius]};`}
  ${(props) => `border-radius: ${borderRadiusStyleObject[props.$borderRadius]};`}
  ${(props) => `color: ${colorStyleObject[props.$variant][props.$color].color};`}
  ${(props) =>
    `background-color: ${colorStyleObject[props.$variant][props.$color].backgroundColor};`}
  ${(props) =>
    `:hover { background-color: ${
      colorStyleObject[props.$variant][props.$color].hoverBackgroundColor
    };}`}

  ${(props) =>
    props.$variant === "outlined" &&
    `border: solid ${props.$borderRadius === "circle" ? "2px" : "1px"} ${
      borderColorStyleObject[props.$color]
    };`}
  ${(props) =>
    props.$variant === "dashedOutlined" &&
    `border: dashed 1px ${borderColorStyleObject[props.$color]};`}


  ${(props) => props.$fullWidth && `width: 100%;`}
  ${(props) => props.$fullHeight && `height: 100%;`}


  ${(props) =>
    props.$disabled &&
    `
    color: ${disabledColorStyleObject[props.$variant]};
    ${props.$variant === "contained" ? `background-color: ${props.theme.palette.grey[200]};` : ""}
    ${props.$variant === "outlined" ? `border-color: ${props.theme.palette.grey[200]};` : ""}
    ${props.$variant === "dashedOutlined" ? `border-color: ${props.theme.palette.grey[200]};` : ""}
  `}
`;

const StyledCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledHidden = styled.div`
  visibility: hidden;
`;
