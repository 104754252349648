import { Box, Tab } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee, OnboardingTaskType, taskTypes } from "@onn/common";
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { OnboardingGeneralTaskSummarySidebar } from "./OnboardingGeneralTaskSummarySidebar";
import { OnboardingMessageTaskSummarySidebar } from "./OnboardingMessageTaskSummarySidebar";
import { OnboardingSimpleTaskSummarySidebar } from "./OnboardingSimpleTaskSummarySidebar";

import { OnboardingTaskMemos } from "./OnboardingTaskMemos";
import { TabValue } from "./hooks";
import { SidebarTopSection, TitleSection } from "./parts";

import { Divider } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnboardingTask } from "~/hooks/onboardingTask";

type Props = {
  onboardingTask: OnboardingTaskType;
  /**
   * サイドバーを開く際に初期状態のタブを指定する
   */
  defaultTab: TabValue;
  newHire: Employee;
  assigneeEmployees: Employee[];
  isEnableNewHireLink?: boolean;
  mutateOnboardingTasks: () => void;
  onClickCloseButton: () => void;
  onClickEditButton: () => void;
  onClickDeleteTaskButton: () => void;
  onClickChangeStatusButton: () => Promise<void>;
  onClickGeneralTaskButton: () => void;
};

const tabs = [
  {
    label: "タスク詳細",
    value: "taskDetail",
  },
  {
    // システム上は "memo" という命名だが、ユーザーには "コメント" として表記する
    label: "コメント",
    value: "taskMemos",
  },
];

export const OnboardingTaskSummarySidebar: FC<Props> = ({
  onboardingTask,
  defaultTab,
  ...props
}) => {
  const { currentUser } = useCurrentUser();
  // リレンダリング対策のために親から渡ってくる_onboardingTaskをそのまま使わない
  const { data: onboardingTaskData } = useOnboardingTask(onboardingTask.id, {
    fallbackData: onboardingTask,
    shouldAutoRevalidate: false,
  });
  const task = useMemo(
    () => onboardingTaskData?.onboardingTask ?? onboardingTask,
    [onboardingTask, onboardingTaskData?.onboardingTask]
  );

  const [activeTab, setActiveTab] = useState<TabValue>(defaultTab);

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    setActiveTab(newValue);
  };

  // ====================
  // task detail
  // ====================

  const renderTaskDetail = useCallback(
    (onboardingTask: OnboardingTaskType) => {
      switch (onboardingTask.type) {
        case taskTypes.GENERAL_TASK:
          return (
            <OnboardingGeneralTaskSummarySidebar
              currentUser={currentUser}
              onboardingTask={onboardingTask}
              {...props}
            />
          );
        case taskTypes.SIMPLE_TASK:
          return <OnboardingSimpleTaskSummarySidebar onboardingTask={onboardingTask} {...props} />;
        case taskTypes.MESSAGE_TASK:
          return (
            <OnboardingMessageTaskSummarySidebar
              currentUser={currentUser}
              onboardingTask={onboardingTask}
              {...props}
            />
          );
      }
    },
    [currentUser, props]
  );

  return (
    <Box
      position="fixed"
      right={0}
      top={0}
      bottom={0}
      height="100%"
      width="100%"
      onClick={props.onClickCloseButton}
      // NOTE: 入社詳細のサマリーより全面に表示するための指定
      zIndex={zIndex.appBar}
    >
      <StyledSidebarBox
        position="fixed"
        right={0}
        top={0}
        bottom={0}
        height="100%"
        width="376px"
        bgcolor="#fff"
        // NOTE: サイドバー上をクリックした時にサマリーが閉じないようにする処理
        onClick={(e) => e.stopPropagation()}
      >
        <SidebarTopSection onClickCloseButton={props.onClickCloseButton} />

        <Divider />

        <Box px="24px" py="12px">
          <TitleSection taskType={task.type} title={task.title} />
        </Box>

        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {/* TabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>

          <StyledTabPanel value="taskDetail">{renderTaskDetail(task)}</StyledTabPanel>
          <StyledTabPanel value="taskMemos">
            <OnboardingTaskMemos
              onboardingTask={task}
              currentUser={currentUser}
              mutateOnboardingTasks={props.mutateOnboardingTasks}
            />
          </StyledTabPanel>
        </TabContext>
      </StyledSidebarBox>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledSidebarBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    flex: 1;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
