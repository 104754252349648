import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography, UserIcon, Link } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  newHire: Employee;
  isEnableNewHireLink?: boolean;
};

// ====================
// main
// ====================

export const NewHireSection: FC<Props> = ({ newHire, isEnableNewHireLink }) => {
  // ====================
  // main component
  // ====================

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="caption" bold color="textPrimary">
        入社者
      </Typography>

      <StyledLink to={isEnableNewHireLink ? `/employee/${newHire.id}` : undefined}>
        <Box display="flex" alignItems="center" gridGap="8px">
          <UserIcon
            key={newHire.id}
            username={newHire.getName()}
            profileIconImageUrl={newHire.profileIconImageUrl}
            size="small"
            circular
          />
          <Typography variant="caption" color="textSecondary" display="block" noWrap disablePreWrap>
            {newHire.getName()}
          </Typography>
        </Box>
      </StyledLink>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledLink = styled(Link)`
  text-decoration: none;
`;
