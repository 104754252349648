import React, { FC } from "react";

import { Type, UpdatedContent, EditableEmployees, Status, Menu } from "./parts";

import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";
import {
  useOnboardingExperienceTaskTemplates,
  CustomOnboardingExperienceTaskTemplate,
} from "~/hooks/onboardingExperienceTaskTemplate";

export const TemplateTable: FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: templates = [], isValidating } = useOnboardingExperienceTaskTemplates(
    currentUser.tenantId
  );
  const onboardingExperienceTableHeight = 52;

  const headers: Array<{
    text: string;
    width: string;
  }> = [
    {
      text: "タイプ",
      width: "48%",
    },
    {
      text: "最終更新",
      width: "14%",
    },
    {
      text: "編集可能メンバー",
      width: "14%",
    },
    {
      text: "ステータス",
      width: "14%",
    },
    {
      text: "",
      width: "10%",
    },
  ];
  const widthOptions = headers.map((h) => h.width);

  const row = (props: { template: CustomOnboardingExperienceTaskTemplate }): JSX.Element[] => {
    const template = props.template;
    const id = template.id;
    // デフォルトテンプレートの場合、現在は表示するメニューアイテムがないため非表示にしている
    const isEditable = template.isEditable(currentUser) && !template.isDefault;
    return [
      <Type template={template} key={`type-${id}`} />,
      <UpdatedContent template={template} key={`update-content-${id}`} />,
      <EditableEmployees template={template} key={`editable-employees-${id}`} />,
      <Status template={template} key={`status-${id}`} />,
      isEditable ? <Menu template={template} key={`menu-${id}`} /> : <></>,
    ];
  };

  return (
    <VirtualizedTableV2<CustomOnboardingExperienceTaskTemplate>
      hover
      isLoading={isValidating}
      headers={headers}
      widthOptions={widthOptions}
      rows={templates}
      rowRenderer={({ key, index, style, rowData: template }) => {
        const contents = row({ template });
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/tools/templates/${template.id}/edit`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
      headerHeight={onboardingExperienceTableHeight} // タブ切り替え時に違和感がないように高さを揃える
    />
  );
};
