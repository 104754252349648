import { Box } from "@material-ui/core";
import { TransactionStatus, Employee, convertToYYMMDD } from "@onn/common";
import { format } from "date-fns";
import React, { FC, useState, useEffect, useMemo } from "react";

import { RemindModal } from "./RemindModal";
import { SurveyTransactionTable } from "./SurveyTransactionTable";

import { ContextMenu, SendAtsMenu } from "./components";

import { FilterButton, NotFoundPaper, Loading } from "~/components/uiParts";
import { useCurrentUser, useAllNewcomers } from "~/hooks/employee";
import { useQuery, useSnackbar, useSetQueryString } from "~/hooks/shared";
import { useSurveySendAts, useSurveyTransactionsBySendAt } from "~/hooks/transaction";
import { BulkRemindModal } from "~/pages/tasks/SurveyAnsweredTab/BulkRemindModal";
import { SurveyTransactionUseCase } from "~/service/usecases/transactionUseCase";
import { captureException } from "~/util";

type StatusType = TransactionStatus | "ALL";

type FilterOptionType = {
  title: string;
  totalCount: number;
  statuses: StatusType[];
};

export const SurveyAnsweredTab: FC = () => {
  const [currentSendAt, setCurrentSendAt] = useState<string | undefined>(undefined);

  const { currentUser } = useCurrentUser();
  const { data: allNewComers, isValidating: isValidatingAllNewComers } = useAllNewcomers();
  const { data: surveySendAts, isLoading: isLoadingSurveySendAts } = useSurveySendAts(
    currentUser.tenantId
  );

  const { data: surveyTransactions, isLoading: isLoadingSurveys } = useSurveyTransactionsBySendAt({
    tenantId: currentUser.tenantId,
    sendAt: currentSendAt,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [selectedStatus, setSelectedStatus] = useState<StatusType>("ALL");
  const [bulkRemindModalOpen, setBulkRemindModalOpen] = useState(false);
  const [remindModalOpen, setRemindModalOpen] = useState(false);

  const surveyTransactionsFilteredBySendAt = (surveyTransactions ?? [])
    .filter((v) => format(v.sendAt, "yyyy-MM-dd") === currentSendAt)
    .sort((a, b) => {
      if (typeof a.answeredAt === "undefined" && typeof b.answeredAt === "undefined") return 0;
      if (typeof a.answeredAt === "undefined") return 1;
      if (typeof b.answeredAt === "undefined") return -1;
      return a.answeredAt > b.answeredAt ? -1 : 1;
    });

  const [selectedSurvey, setSelectedSurvey] = useState<{ surveyId: string; employee: Employee }>();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { query } = useQuery();
  const { setQueryString } = useSetQueryString();
  const sendAtQuery = query.get("sendAt");

  const sentTransactions = useMemo(
    () =>
      surveyTransactionsFilteredBySendAt.filter(
        (transaction) => transaction.status === TransactionStatus.SENT
      ),
    [surveyTransactionsFilteredBySendAt]
  );

  const doneTransactions = useMemo(
    () =>
      surveyTransactionsFilteredBySendAt.filter(
        (transaction) => transaction.status === TransactionStatus.DONE
      ),
    [surveyTransactionsFilteredBySendAt]
  );

  const currentSurveys = useMemo(() => {
    switch (selectedStatus) {
      case "ALL":
        return surveyTransactionsFilteredBySendAt;
      case TransactionStatus.SENT:
        return sentTransactions;
      case TransactionStatus.DONE:
        return doneTransactions;
    }
  }, [doneTransactions, selectedStatus, sentTransactions, surveyTransactionsFilteredBySendAt]);

  const filterOptions: FilterOptionType[] = [
    {
      title: "All",
      totalCount: surveyTransactionsFilteredBySendAt.length,
      statuses: ["ALL"],
    },
    {
      title: "未回答",
      totalCount: sentTransactions.length,
      statuses: [TransactionStatus.SENT],
    },
    {
      title: "回答済み",
      totalCount: doneTransactions.length,
      statuses: [TransactionStatus.DONE],
    },
  ];

  const buildNotFoundText = (status: StatusType) => {
    switch (status) {
      case "ALL":
        return `アンケートがまだ配信されていません📭 \n最初の配信を楽しみに待ちましょう！`;
      case TransactionStatus.SENT:
        return `全てのアンケートが回答されました🎉\n次の配信を待ちましょう！`;
      case TransactionStatus.DONE:
        return `回答されたアンケートはまだありません🙆‍♂️`;
    }
  };

  useEffect(() => {
    if (isLoadingSurveys) return;

    const _surveySendAts = surveySendAts ?? [];

    if (sendAtQuery && _surveySendAts.includes(sendAtQuery)) {
      // クエリストリングで日付が指定されている場合はそれをset
      setCurrentSendAt(sendAtQuery);
      setQueryString({ sendAt: sendAtQuery });
    } else {
      setCurrentSendAt(_surveySendAts[0]);
      setQueryString({ sendAt: _surveySendAts[0] });
    }
  }, [isLoadingSurveys, sendAtQuery, setQueryString, surveySendAts]);

  const handleClickOpenRemindModal = (surveyId: string, employee: Employee) => {
    handleShowRemindModal();
    setSelectedSurvey({
      surveyId: surveyId,
      employee: employee,
    });
  };

  const handleShowBulkRemindModal = () => {
    setBulkRemindModalOpen(true);
  };

  const handleShowRemindModal = () => {
    setRemindModalOpen(true);
  };

  const sendBulkRemind = async () => {
    if (!currentSendAt) return;
    setButtonDisabled(true);
    await SurveyTransactionUseCase.bulkRemindSurveyTransaction(currentSendAt, currentUser.tenantId)
      .then(() => enqueueSnackbar("リマインドしました", { variant: "success" }))
      .catch((e) => {
        enqueueSnackbar("リマインドに失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "SurveyAnsweredTab:SurveyTransactionUseCase" },
        });
      })
      .finally(() => {
        setBulkRemindModalOpen(false);
        setButtonDisabled(false);
      });
  };

  const sendRemind = async (transactionId: string) => {
    setButtonDisabled(true);
    await SurveyTransactionUseCase.remindSurveyTransaction(transactionId)
      .then(() => enqueueSnackbar("リマインドしました", { variant: "success" }))
      .catch((e) => {
        enqueueSnackbar("リマインドに失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "SurveyAnsweredTab:sendRemind" },
        });
      })
      .finally(() => {
        setRemindModalOpen(false);
        setButtonDisabled(false);
      });
  };

  const checkSelectStatusEmployees = (statuses: StatusType[]): boolean =>
    statuses.some((status) => status === selectedStatus);

  const toggleShowSurveys = (statuses: StatusType[]): void => {
    const status = statuses.find((status) => selectedStatus !== status);
    if (status) {
      setSelectedStatus(status);
    }
  };

  const handleSelectSendAt = async (sendAt: string) => {
    setCurrentSendAt(sendAt);
    setQueryString({ sendAt });
  };

  if (isValidatingAllNewComers || isLoadingSurveySendAts) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" height={12} py={5}>
        <SendAtsMenu
          currentSendAt={`${!currentSendAt ? "----/--/--" : convertToYYMMDD(currentSendAt)}配信`}
          surveySendAts={surveySendAts ?? []}
          onClickSelectSendAt={(sendAt) => handleSelectSendAt(sendAt)}
        />
        {currentSurveys.some((survey) => survey.status === TransactionStatus.SENT) && (
          <ContextMenu onClickOpenBulkRemindModal={handleShowBulkRemindModal} />
        )}
      </Box>
      <Box pt={3} pb={5} display="flex" overflow="scroll">
        {filterOptions.map((option, i) => (
          <Box mr={i === 0 ? 5 : 2} key={`FilterButtonBox__${i}`}>
            <FilterButton
              loading={isLoadingSurveys}
              title={option.title}
              totalCount={option.totalCount}
              isSelected={checkSelectStatusEmployees(option.statuses)}
              onClick={() => toggleShowSurveys(option.statuses)}
            />
          </Box>
        ))}
      </Box>

      {!isLoadingSurveys && !currentSurveys.length && (
        <NotFoundPaper text={buildNotFoundText(selectedStatus)} />
      )}

      <SurveyTransactionTable
        loading={isLoadingSurveys}
        surveyTransactions={currentSurveys}
        employees={allNewComers || []}
        onClickOpenRemindModal={handleClickOpenRemindModal}
      />

      {bulkRemindModalOpen && currentSendAt && (
        <BulkRemindModal
          open={bulkRemindModalOpen}
          onSubmit={() => sendBulkRemind()}
          onCancel={() => setBulkRemindModalOpen(false)}
          buttonDisabled={buttonDisabled}
          sendAt={currentSendAt}
        />
      )}
      {remindModalOpen && selectedSurvey && (
        <RemindModal
          open={remindModalOpen}
          survey={selectedSurvey}
          onSubmit={() => sendRemind(selectedSurvey.surveyId)}
          onCancel={() => setRemindModalOpen(false)}
          buttonDisabled={buttonDisabled}
        />
      )}
    </>
  );
};
