import { Department } from "@onn/common";
import { useState, useCallback } from "react";

import { useSnackbar } from "~/hooks/shared";
import { DepartmentUseCase } from "~/service/usecases/departmentUseCase";
import { captureException } from "~/util";

const departmentUseCase = new DepartmentUseCase();

export const useUpdateDepartments = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const updateDepartments = useCallback(
    async (newObjects: { id: string; department: Department }[]) => {
      setIsLoading(true);
      await Promise.all(
        newObjects.map(({ id, department }) => {
          return departmentUseCase.update(id, department);
        })
      )
        .then(() => {
          enqueueSnackbar("部署設定を更新しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useUpdateDepartments:updateDepartments" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return { isLoading, updateDepartments };
};
