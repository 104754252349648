import { OnboardingTaskType } from "@onn/common";
import React, { ReactNode, useMemo } from "react";

import { Icon } from "~/components/uiParts";
import logoSquare from "~/images/logo-square.svg";

const iconMap: Record<OnboardingTaskType["type"], ReactNode> = {
  GENERAL_TASK: <img height={16} width={16} src={logoSquare} alt="Onnアイコン" />,
  SIMPLE_TASK: <Icon icon="line" size="sm" color="lightGrey" />,
  MESSAGE_TASK: <Icon icon="speechBalloon" size="sm" color="lightGrey" />,
};

/**
 * オンボーディングタスクのtypeに併せてアイコンを返すhooks
 * @param type OnboardingTaskTypeのtype
 * @returns ReactNode
 */
export const useOnboardingTaskIcon = (type: OnboardingTaskType["type"]) => {
  const icon: ReactNode = useMemo(() => {
    return iconMap[type];
  }, [type]);

  return { icon };
};
