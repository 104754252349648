import {
  Transaction,
  SurveyTransaction,
  MemoTransaction,
  TransactionDataType,
  TransactionStatus,
  AnswerItem,
  SurveyAlert,
} from "@onn/common";
import { HttpsCallableResult } from "firebase/functions";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { factory as surveyAlertFactory } from "~/service/repository/iSurveyAlertRepository";
import { factory } from "~/service/repository/iTransactionRepository";

// @FIXME Classで実装する
const transactionRepository = factory.transactionRepository();
const surveyAlertRepository = surveyAlertFactory.surveyAlertRepository();
export const TransactionUseCase = {
  whereByDataTypes: async ({
    employeeId,
    dataTypes,
  }: {
    employeeId: string;
    dataTypes: (keyof typeof TransactionDataType)[];
  }): Promise<Array<Transaction | SurveyTransaction | MemoTransaction>> => {
    return await transactionRepository.whereByDataTypes({ employeeId, dataTypes });
  },
  updateJoinDateType: async (tenantId: string, employeeId: string): Promise<void> => {
    await transactionRepository.updateJoinDateType(tenantId, employeeId);
  },
  updateProfileType: async (tenantId: string, employeeId: string): Promise<void> => {
    await transactionRepository.updateProfileType(tenantId, employeeId);
  },
};

const surveyTransactionRepository = factory.surveyTransactionRepository();
export const SurveyTransactionUseCase = {
  create: async (transactionId: string, answerItems: AnswerItem[]): Promise<void> => {
    await surveyTransactionRepository
      .updateAnswer(transactionId, answerItems)
      .then(() => functionOperator.httpsCall("notifySurvey", { transactionId }));
    const transaction = await surveyTransactionRepository.find(transactionId);
    if (transaction.shouldAlert()) {
      const surveyAlert = SurveyAlert.create({ transactionId, tenantId: transaction.tenantId });
      await surveyAlertRepository.create(surveyAlert);
    }
  },
  find: async (id: string): Promise<SurveyTransaction> => {
    return surveyTransactionRepository.find(id);
  },
  whereByStatusAndTenantId: async (
    status: TransactionStatus,
    tenantId: string,
    orderBy: "sendAt" | "answeredAt"
  ): Promise<SurveyTransaction[]> => {
    return surveyTransactionRepository.whereByStatusAndTenantIdAndSortedTimestamp(
      status,
      tenantId,
      orderBy
    );
  },
  whereByEmployeeId: async (employeeId: string): Promise<SurveyTransaction[]> => {
    return await surveyTransactionRepository.whereByEmployeeId(employeeId);
  },
  whereByEmployeeIds: async (employeeIds: string[]): Promise<SurveyTransaction[]> => {
    return await surveyTransactionRepository.whereByEmployeeIds(employeeIds);
  },
  remindSurveyTransaction: async (transactionId: string): Promise<HttpsCallableResult> => {
    return await functionOperator.httpsCall("remindSurveyTransaction", {
      transactionId: transactionId,
    });
  },
  bulkRemindSurveyTransaction: async (
    sendAt: string,
    tenantId: string
  ): Promise<HttpsCallableResult> => {
    return await functionOperator.httpsCall("bulkRemindSurveyTransaction", {
      sendAt: sendAt,
      tenantId: tenantId,
    });
  },
};

const memoTransactionRepository = factory.memoTransactionRepository();
export const memoTransactionUseCase = {
  find: async (id: string): Promise<MemoTransaction> => {
    return memoTransactionRepository.findMemo(id);
  },
  edit: async (id: string, { title, text }: { title: string; text: string }): Promise<void> => {
    const targetMemo = await memoTransactionRepository.findMemo(id);
    if (!targetMemo) {
      throw new Error("メモが見つかりませんでした");
    }

    await memoTransactionRepository.editMemo(id, { ...targetMemo.contents, title, text });
  },
  delete: async (id: string): Promise<void> => {
    await memoTransactionRepository.deleteMemo(id);
  },
};
