import { OnboardingTaskType } from "@onn/common";
import { useState, useCallback } from "react";

import { useNotifyOperationLog, useSnackbar } from "../shared";

import { useCurrentUser } from "~/hooks/employee";
import { OnboardingTaskUseCase } from "~/service/usecases/onboardingTaskUseCase";
import { captureException } from "~/util";

const onboardingTaskUseCase = new OnboardingTaskUseCase();
/**
 * onboardingTaskを複数完了するためのhooks
 * @returns isLoading データを処理中かどうか
 * @returns completeOnboardingTasks
 */
export const useCompleteOnboardingTasks = (): {
  isLoading: boolean;
  completeOnboardingTasks: (tasks: OnboardingTaskType[]) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { operationLog, notifyOperationLog } = useNotifyOperationLog();

  /**
   * @param {OnboardingTask[]} tasks
   * @param {Employee} employee
   */
  const completeOnboardingTasks = useCallback(
    async (tasks: OnboardingTaskType[]) => {
      setIsLoading(true);
      await onboardingTaskUseCase
        .completeOnboardingTasks(tasks.map((v) => v.id))
        .then(() =>
          enqueueSnackbar("オンボーディングタスクを一括完了しました", { variant: "success" })
        )
        .catch((e) => {
          enqueueSnackbar("オンボーディングタスクの一括完了に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useCompleteOnboardingTasks:completeOnboardingTasks",
            },
          });
        })
        .finally(() => setIsLoading(false));

      notifyOperationLog(operationLog.completeOnboardingTasks(currentUser, tasks.length));
    },
    [currentUser, enqueueSnackbar, notifyOperationLog, operationLog]
  );

  return { isLoading, completeOnboardingTasks };
};
