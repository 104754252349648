import { Menu as MuiMenu, MenuItem as MuiMenuItem } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  anchorEl: Element | null;
  menuItemOptions: {
    onClick: () => void;
    text: string;
  }[];
  handleClose: () => void;
  anchorOrigin: {
    vertical: number | "top" | "center" | "bottom";
    horizontal: number | "center" | "left" | "right";
  };
  transformOrigin: {
    vertical: number | "top" | "center" | "bottom";
    horizontal: number | "center" | "left" | "right";
  };
};

export const Menu: FC<Props> = ({
  anchorEl,
  menuItemOptions,
  handleClose,
  anchorOrigin,
  transformOrigin,
  ...props
}) => (
  <StyledMuiMenu
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
    getContentAnchorEl={null}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    {...props}
  >
    {menuItemOptions.map((menuItemOption, index) => {
      return (
        <StyledMuiMenuItem key={index} onClick={menuItemOption.onClick}>
          {menuItemOption.text}
        </StyledMuiMenuItem>
      );
    })}
  </StyledMuiMenu>
);

const StyledMuiMenu = styled(MuiMenu)`
  & .MuiList-padding {
    padding: 12px 0;
  }
`;

// theme オブジェクトの設定を override するために `important` を利用
const StyledMuiMenuItem = styled(MuiMenuItem)`
  padding: 12px 24px !important;
`;
