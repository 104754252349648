import { Box } from "@material-ui/core";
import { ItemType, Question, AnswerItem } from "@onn/common";
import { isArray } from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import { FormType } from "./FormType";

import { Typography } from "~/components/uiParts";

type Props = {
  question: Question;
  changeAnswer: (event: React.ChangeEvent<HTMLInputElement>) => void;
  index: number; // MEMO - タイトルの場合質問のインデックスを表示します
  isRead?: boolean;
  answerItems: AnswerItem[];
};

/**
 * 質問の名前でAnswerItemを取得する（キー）
 *
 * @param key
 * @param answer
 */
const getAnswerItemByKey = (key: string, answerItems: AnswerItem[]) => {
  return answerItems.find((v) => v.key === key);
};

export const SurveyForm: FC<Props> = ({ question, changeAnswer, index, isRead, answerItems }) => {
  const questionTitle = (question: Question, level?: number) => {
    const displayTitle = question.title.replace(/\[thisWeek\]/g, "（○月×日～○月×日）");
    const requiredSymbol = !isRead ? question.required : undefined;

    return (
      <StyledTitle variant="body2" appendRequiredSymbol={requiredSymbol}>
        {`${level === 1 ? `Q${index}. ` : ""}${displayTitle}`}
      </StyledTitle>
    );
  };

  return (
    <Box key={index}>
      <Box>{questionTitle(question, 1)}</Box>
      {question.caption && !isArray(question.caption) && (
        <Box mt={2}>
          <Typography variant="caption" color="textSecondary">
            {question.caption}
          </Typography>
        </Box>
      )}
      {!(question.type === ItemType.TITLE) && (
        <Box mt={3}>
          <FormType
            question={question}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            changeAnswer={changeAnswer}
            answerItem={getAnswerItemByKey(question.name, answerItems)}
            isRead={isRead}
            caption={question.caption}
          />
        </Box>
      )}
      {question.type === ItemType.TITLE &&
        question.questions.map((v, i) => {
          return (
            <Box pt={i == 0 ? 5 : 10} key={i}>
              {questionTitle(v)}
              <Box mt={3}>
                <FormType
                  question={v}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  changeAnswer={changeAnswer}
                  answerItem={getAnswerItemByKey(v.name, answerItems)}
                  isRead={isRead}
                  caption={v.caption}
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

const StyledTitle = styled(Typography)<{ bold?: boolean }>`
  ${(props) =>
    props.bold &&
    `
    font-size: 14px;`}
`;
