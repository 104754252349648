import { OnboardingTaskType, OnboardingTaskFactory, DifferenceDate } from "@onn/common";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useOnboardingTask, useUpdateOnboardingTask } from "~/hooks/onboardingTask";
import { useLocalStorage, useReward, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

const REWARD_ID = "complete-task";

/**
 * プレビューの場合：ローカルストレージの値を参照, 更新する
 * プレビューでない場合：api の値を参照, 更新する
 * @param isPreview
 * @param onboardingTaskId
 */
export const useGetOnboardingTask = (isPreview: boolean, onboardingTaskId?: string) => {
  // プレビューの場合に利用
  const { enqueueSnackbar } = useSnackbar();
  const { retrieveValue, storeValue } = useLocalStorage();
  const [isLoadingLocalStorage, setIsLoadingLocalStorage] = useState(true);
  const [onboardingTaskForPreview, setOnboardingTaskForPreview] = useState<
    OnboardingTaskType | undefined
  >();
  const localStorageTasks: OnboardingTaskType[] = useMemo(
    () =>
      retrieveValue<OnboardingTaskType[]>("OnboardingTaskForPreview", {
        func: (tasks) => {
          return tasks.map((task) =>
            OnboardingTaskFactory.createOnboardingTask({
              ...task,
              dueDate: new DifferenceDate(task.dueDate),
            })
          );
        },
        onError: () =>
          enqueueSnackbar("タスクの取得に失敗しました", {
            variant: "error",
          }),
        errorTypeName:
          "useGetNewHireOnboardingTasks.convertToOnboardingSimpleTaskFromExperienceSimpleTask",
      }) || [],
    [retrieveValue, enqueueSnackbar]
  );

  // プレビュー以外で利用
  const {
    data: onboardingTaskData,
    isLoading: isLoadingOnboardingTaskData,
    mutate: mutateOnboardingTaskData,
  } = useOnboardingTask(onboardingTaskId);
  const { reward } = useReward(REWARD_ID);
  const { isLoading: isLoadingUpdateOnboardingTask, updateOnboardingTask } =
    useUpdateOnboardingTask();

  const updateOnboardingTaskStatus = useCallback(
    async (taskId: string, taskStatus: OnboardingTaskType["status"]) => {
      const updatedStatus = taskStatus === "COMPLETED" ? "NOT_STARTED" : "COMPLETED";
      const snackbarMessage =
        taskStatus === "COMPLETED"
          ? "オンボーディングタスクの完了を取り消しました"
          : "オンボーディングタスクを完了にしました";

      if (isPreview) {
        /** プレビューの場合: localState と localStorage 両方の値を更新する */
        setOnboardingTaskForPreview((prevTask) => {
          if (prevTask === undefined) return undefined;

          const copy = cloneDeep(prevTask);
          copy.status = updatedStatus;

          storeValue(
            "OnboardingTaskForPreview",
            localStorageTasks.map((v) => {
              if (v.id === copy.id) {
                return copy;
              }
              return v;
            })
          );

          return copy;
        });

        enqueueSnackbar(snackbarMessage, { variant: "success" });
      } else {
        /** プレビューでない場合: api の値を更新する */
        if (taskStatus !== "COMPLETED") reward();

        // 完了しているときは未完了に戻し、未完了のときは完了にする
        const newTask = await updateOnboardingTask(
          taskId,
          {
            status: updatedStatus,
          },
          [],
          snackbarMessage
        );
        if (!newTask) return;
        mutateOnboardingTaskData(
          (currentData) => ({ ...currentData, onboardingTask: newTask }),
          false
        );
      }
    },
    [
      mutateOnboardingTaskData,
      isPreview,
      localStorageTasks,
      storeValue,
      updateOnboardingTask,
      reward,
      enqueueSnackbar,
    ]
  );

  useEffect(() => {
    if (!isPreview) {
      setIsLoadingLocalStorage(false);
      return;
    }

    const setLocalStorageTask = () => {
      /** プレビューの場合：ローカルストレージからタスクを取得する */
      try {
        const task = localStorageTasks.find((v) => v.id === onboardingTaskId);
        if (!task) throw new Error("No find task local storage");

        setOnboardingTaskForPreview(task);
      } catch (error) {
        enqueueSnackbar("タスクの取得に失敗しました", {
          variant: "error",
        });
        if (error instanceof Error) {
          captureException({
            error,
            tags: {
              type: "useGetNewHireOnboardingTasks.convertToOnboardingSimpleTaskFromExperienceSimpleTask",
            },
          });
        }
      }

      setIsLoadingLocalStorage(false);
    };

    setLocalStorageTask();
  }, [isPreview, localStorageTasks, enqueueSnackbar, onboardingTaskId]);

  return {
    onboardingTask: isPreview ? onboardingTaskForPreview : onboardingTaskData?.onboardingTask,
    isLoadingOnboardingTask: isPreview ? isLoadingLocalStorage : isLoadingOnboardingTaskData,
    updateOnboardingTaskStatus,
    isLoadingUpdateOnboardingTask,
  };
};
