import { Box } from "@material-ui/core";
import { Employee, SlackUser } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useCallback, useMemo, FC, useEffect } from "react";
import styled from "styled-components";

import { SlackUserList, SlackUserSearchForm } from "../../slackUsers";
import { SelectEmployeesFormByPlatform } from "../SelectEmployeesFormByPlatform";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { useAllEmployees } from "~/hooks/employee";
import { useSlackUsers } from "~/hooks/slackUser";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  newHire: Employee | null;
  mentor?: Employee;
  supportMembers?: Employee[];
  onSubmit: (emailsToInviteSupportMembers: string[], employeeIds: string[]) => Promise<void>;
};

export const AssignSupportMembersModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  newHire,
  mentor,
  supportMembers,
}) => {
  const { tenant } = useTenant();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [sending, setSending] = useState(false);

  const { slackUsers, loadingSlackUsers } = useSlackUsers();
  const { allEmployees } = useAllEmployees();

  const [displaySlackUsers, setDisplaySlackUsers] = useState<SlackUser[]>([]); // クライアント検索処理で再構成される表示用の配列
  const [selectedSlackUsers, setSelectedSlackUsers] = useState<SlackUser[]>([]);

  const filterSlackUser = useMemo(() => {
    if (!slackUsers || !newHire || !supportMembers) return [];

    // 自分自身、バディ、すでにアサインされているサポートメンバーのidから成る配列
    const notAllowAssignIds = [
      newHire.slackUserId,
      mentor?.slackUserId,
      ...supportMembers.map((sm) => sm.slackUserId),
    ];

    // 自分自身 and バディ and アサイン済みサポートメンバーは選択肢から除外する
    const notAssignedSlackUsers = slackUsers.filter(
      (slackUser) => !notAllowAssignIds.includes(slackUser.slackUserId)
    );

    return notAssignedSlackUsers;
  }, [newHire, mentor, slackUsers, supportMembers]);

  // 自分自身、バディ、すでにアサインされているサポートメンバーのemailから成る配列
  const notAllowEmails = useMemo(() => {
    if (!newHire || !supportMembers) return [];
    return [newHire.email, ...(mentor?.email || []), ...supportMembers.map((sm) => sm.email)];
  }, [newHire, mentor, supportMembers]);

  const handleSubmit = useCallback(async () => {
    const emailsToInviteSupportMembers: string[] = [];
    const employeeIds: string[] = [];

    if (tenant?.isIntegratedSlack) {
      selectedSlackUsers.map((slackUser) => {
        const employee = allEmployees.find((v) => v.slackUserId === slackUser.slackUserId);
        if (employee) {
          employeeIds.push(employee.id);
        } else {
          emailsToInviteSupportMembers.push(slackUser.email);
        }
      });
    } else {
      selectedEmails.map((email) => {
        const employee = allEmployees.find((v) => v.email === email);
        if (employee) {
          employeeIds.push(employee.id);
        } else {
          emailsToInviteSupportMembers.push(email);
        }
      });
    }

    setSending(true);
    await onSubmit(employeeIds, emailsToInviteSupportMembers)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [
    allEmployees,
    onCancel,
    onSubmit,
    selectedEmails,
    selectedSlackUsers,
    tenant?.isIntegratedSlack,
  ]);

  useEffect(() => {
    setDisplaySlackUsers(filterSlackUser);
  }, [filterSlackUser]);

  return (
    <Modal
      open={open}
      title="サポートメンバー追加"
      content={
        <Box>
          <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="24px">
            <Box textAlign="center" width="342px" display="inline-block">
              <UserIcon
                username={newHire?.getName() || ""}
                profileIconImageUrl={newHire?.profileIconImageUrl || ""}
                size="medium"
                borderColor="primary"
              />
              <Box pb={2} />
              <Typography variant="body2" align="center">
                {`${newHire?.getName() || ""}さんのサポートメンバーを\n追加してください`}
              </Typography>
            </Box>
            <Box width="342px" display="inline-block">
              {tenant && tenant?.isIntegratedSlack ? (
                <>
                  <SlackUserSearchForm
                    variant="standard"
                    showPlaceholder
                    slackUsers={filterSlackUser}
                    onSearch={(results) => setDisplaySlackUsers(results)}
                  />
                  <SlackUserList
                    slackUsers={displaySlackUsers}
                    selectedSlackUsers={selectedSlackUsers}
                    onSelect={(slackUsers) => setSelectedSlackUsers(slackUsers)}
                    isLoading={loadingSlackUsers}
                    isMultiple
                  />
                </>
              ) : (
                <SelectEmployeesFormByPlatform
                  notAllowEmails={notAllowEmails}
                  selectedEmails={selectedEmails}
                  onSelectEmails={setSelectedEmails}
                />
              )}
            </Box>
          </Box>
          {tenant && !tenant.isIntegratedSlack && (
            <Typography variant="caption" color="textSecondary" align="center" display="block">
              Onnに登録されていないメンバーを設定したい場合は、
              <StyledPointerTypography
                display="inline"
                variant="caption"
                bold
                color="primary"
                onClick={() => window.open("/settings/admin", "_blank")}
              >
                こちら
              </StyledPointerTypography>
              から招待を行ってください。
            </Typography>
          )}
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={(isEmpty(selectedEmails) && isEmpty(selectedSlackUsers)) || sending}
          >
            {!sending ? "追加する" : "追加中"}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
