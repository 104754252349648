import { Box } from "@material-ui/core";
import {
  Employee,
  DifferenceDate,
  OnboardingTaskWithNewHire,
  OnboardingTaskType,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { useSidebarTab } from "../../onboardingTask";

import { useViewModel, FILTER_STATUSES, FilterStatusType, FilterOptionType } from "./useViewModel";

import { EmployeeFilter } from "~/components/domains/employees";
import {
  OnboardingTaskSummarySidebar,
  OnboardingTaskTable,
} from "~/components/domains/onboardingTask";
import {
  useExtractAssigneeEmployeeIds,
  useGetEmployeesByAssigneeRole,
} from "~/components/domains/onboardingTask/hooks";
import { useFilterTasksByAssigneeEmployeeIds } from "~/components/domains/onboardingTask/hooks/useFilterTasksByAssigneeEmployeeIds";
import { useFilterTasksByStatus } from "~/components/domains/onboardingTask/hooks/useFilterTasksByStatus";
import { useSortOnboardingTasks } from "~/components/domains/onboardingTask/hooks/useSortOnboardingTasks";
import { Button, Typography, FilterButton, IconButton, Tooltip, Icon } from "~/components/uiParts";
import {
  useAllEmployeesWithDeleted,
  useCurrentUser,
  useEmployee,
  useEmployees,
  useAdmins,
} from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import {
  useCreateOnboardingTask,
  useDeleteOnboardingTask,
  useUpdateOnboardingTask,
  useDeleteOnboardingTasks,
  useCompleteOnboardingTasks,
} from "~/hooks/onboardingTask";
import { useHandleManageMessageTaskModal } from "~/hooks/onboardingTask/messageTask";
import { useSetQueryString } from "~/hooks/shared";
import { useNotifyOperationLog } from "~/hooks/shared/useNotifyOperationLog";

type Props = {
  employee: Employee;
};

export const TaskTab: FC<Props> = ({ employee }) => {
  const { currentUser } = useCurrentUser();
  const { data: allEmployeesWithDeleted = [], mutate: mutateAllEmployeesWithDeleted } =
    useAllEmployeesWithDeleted(currentUser.tenantId);

  const {
    onboardingTasks,
    selectedStatus,
    setSelectedStatus,
    selectedOnboardingTaskIds,
    setSelectedOnboardingTaskIds,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    isSelectedAll,
    setIsSelectedAll,
    filteredOnboardingTasksByStatus,
    targetTaskForSummary,
    setTargetTaskForSummary,
    mutateOnboardingTasks,
    isValidatingOnboardingTasks,
    isValidatingNewHires,
  } = useViewModel(employee);

  const { handleModal } = useModal();
  const { getEmployeesByAssigneeRole } = useGetEmployeesByAssigneeRole();

  const { createOnboardingTask } = useCreateOnboardingTask();
  const { completeOnboardingTasks } = useCompleteOnboardingTasks();
  const { deleteOnboardingTask } = useDeleteOnboardingTask();
  const { deleteOnboardingTasks } = useDeleteOnboardingTasks();
  const { updateOnboardingTask } = useUpdateOnboardingTask();

  const { notifyOperationLog, operationLog } = useNotifyOperationLog();

  const { filterTasksByStatus } = useFilterTasksByStatus();
  const { filterTasksByAssigneeEmployeeIds } = useFilterTasksByAssigneeEmployeeIds();
  const { sortOnboardingTasks } = useSortOnboardingTasks();

  const { data: mentor } = useEmployee(employee.mentorUserId);
  const { data: supportMembers = [] } = useEmployees(employee.supportMemberEmployeeIds);
  const { data: admins = [] } = useAdmins(employee.tenantId);
  const { data: specificEmployees = [] } = useEmployees(targetTaskForSummary?.assigneeIds);

  const { extractAssigneeEmployeeIds } = useExtractAssigneeEmployeeIds();
  const { setQueryString } = useSetQueryString();
  const { handleOpenManageMessageTaskModal } = useHandleManageMessageTaskModal();

  const { sidebarTab, setSidebarTab } = useSidebarTab();

  const getOnboardingTaskWithNewHire = useCallback(
    (onboardingTasks: OnboardingTaskType[]) => {
      return onboardingTasks.map((v: OnboardingTaskWithNewHire) => {
        v.newHire = employee;
        return v;
      });
    },
    [employee]
  );

  const getFilteredAndSortedOnboardingTasks = useCallback(
    (onboardingTasks: OnboardingTaskType[], status: FilterStatusType) => {
      const filteredTasksByStatus = filterTasksByStatus(onboardingTasks, status);
      const filteredTasksByAssigneeEmployeeIds = filterTasksByAssigneeEmployeeIds(
        filteredTasksByStatus,
        selectedEmployeeIds,
        admins.map((v) => v.id)
      );
      return sortOnboardingTasks(filteredTasksByAssigneeEmployeeIds);
    },
    [
      filterTasksByAssigneeEmployeeIds,
      filterTasksByStatus,
      admins,
      selectedEmployeeIds,
      sortOnboardingTasks,
    ]
  );

  const onboardingTasksWithNewHire = onboardingTasks
    ? getFilteredAndSortedOnboardingTasks(
        getOnboardingTaskWithNewHire(onboardingTasks),
        selectedStatus
      )
    : [];

  // ステータスと入社日でフィルターした中から担当者のidを取得する
  const selectableEmployeeIds = onboardingTasks
    ? extractAssigneeEmployeeIds(
        filterTasksByStatus(getOnboardingTaskWithNewHire(onboardingTasks), selectedStatus),
        admins
      )
    : [];

  const filterOptions: FilterOptionType[] = [
    {
      title: "全て",
      totalCount: filteredOnboardingTasksByStatus(onboardingTasks || [], FILTER_STATUSES.ALL)
        .length,
      status: FILTER_STATUSES.ALL,
    },
    {
      title: "期限切れ",
      totalCount: filteredOnboardingTasksByStatus(onboardingTasks || [], FILTER_STATUSES.EXPIRED)
        .length,
      status: FILTER_STATUSES.EXPIRED,
    },
    {
      title: "未着手",
      totalCount: filteredOnboardingTasksByStatus(
        onboardingTasks || [],
        FILTER_STATUSES.NOT_STARTED
      ).length,
      status: FILTER_STATUSES.NOT_STARTED,
    },
    {
      title: "完了",
      totalCount: filteredOnboardingTasksByStatus(onboardingTasks || [], FILTER_STATUSES.COMPLETED)
        .length,
      status: FILTER_STATUSES.COMPLETED,
    },
  ];

  const handleOpenEditTaskModal = useCallback(
    (task?: OnboardingTaskType) => {
      handleModal({
        name: "editTaskModal",
        args: {
          employee,
          task,
          allEmployeesWithDeleted,
          onSubmit: async (newTask: OnboardingTaskType, emailsWithoutOnnAccount: string[]) => {
            if (task) {
              await updateOnboardingTask(task.id, newTask, emailsWithoutOnnAccount);
            } else {
              await createOnboardingTask(newTask, employee, emailsWithoutOnnAccount);
            }
            mutateOnboardingTasks();
            mutateAllEmployeesWithDeleted();
          },
        },
      });
    },
    [
      allEmployeesWithDeleted,
      createOnboardingTask,
      employee,
      handleModal,
      mutateAllEmployeesWithDeleted,
      mutateOnboardingTasks,
      updateOnboardingTask,
    ]
  );

  const handleClickCompleteMultipleTasks = useCallback(
    async (targetOnboardingTasks: OnboardingTaskWithNewHire[]) => {
      await completeOnboardingTasks(targetOnboardingTasks);
      mutateOnboardingTasks();
      setSelectedOnboardingTaskIds([]);
      setIsSelectedAll(false);
    },
    [completeOnboardingTasks, mutateOnboardingTasks, setIsSelectedAll, setSelectedOnboardingTaskIds]
  );

  const handleDeleteMultipleTasksModal = useCallback(
    (targetOnboardingTasks: OnboardingTaskWithNewHire[]) => {
      handleModal({
        name: "deleteMultipleTasksModal",
        args: {
          onSubmit: async () => {
            await deleteOnboardingTasks(targetOnboardingTasks);
            mutateOnboardingTasks();
            setSelectedOnboardingTaskIds([]);
            setIsSelectedAll(false);
          },
          count: targetOnboardingTasks.length,
        },
      });
    },
    [
      deleteOnboardingTasks,
      handleModal,
      mutateOnboardingTasks,
      setIsSelectedAll,
      setSelectedOnboardingTaskIds,
    ]
  );

  const handleOpenEditDueDateModal = useCallback(
    (task: OnboardingTaskType) => {
      handleModal({
        name: "editDueDateModal",
        args: {
          task: task,
          newHire: employee,
          onSubmit: async (newDueDate: DifferenceDate) => {
            const newTask = await updateOnboardingTask(task.id, { dueDate: newDueDate }, []);
            if (!newTask) return;
            mutateOnboardingTasks();
            notifyOperationLog(
              operationLog.updateOnboardingTaskDueDate(currentUser, newTask, employee, newDueDate)
            );
          },
        },
      });
    },
    [
      currentUser,
      employee,
      handleModal,
      mutateOnboardingTasks,
      notifyOperationLog,
      operationLog,
      updateOnboardingTask,
    ]
  );

  const handleClickDeleteTaskButton = useCallback(
    (task: OnboardingTaskType) => {
      handleModal({
        name: "deleteTaskModal",
        args: {
          username: employee.getName(),
          profileIconImageUrl: employee.profileIconImageUrl,
          taskName: task.title,
          onSubmit: async () => {
            await deleteOnboardingTask(task, employee);
            mutateOnboardingTasks();
          },
        },
      });
    },
    [deleteOnboardingTask, employee, handleModal, mutateOnboardingTasks]
  );

  const handleClickChangeStatusButton = useCallback(
    async (task: OnboardingTaskType) => {
      const newTask = await updateOnboardingTask(
        task.id,
        {
          status: task.status === "COMPLETED" ? "NOT_STARTED" : "COMPLETED",
        },
        []
      );
      if (!newTask) return;
      mutateOnboardingTasks();
      // サマリーを表示していた場合は表示を更新する
      setTargetTaskForSummary((prev) => (prev ? newTask : undefined));
    },
    [updateOnboardingTask, mutateOnboardingTasks, setTargetTaskForSummary]
  );

  return (
    <>
      <StyledBox py={5}>
        {filterOptions.map((option: FilterOptionType, i) => (
          <Box mr={i === 0 ? 5 : 2} key={`FilterButtonBox__${i}`}>
            <FilterButton
              loading={(isValidatingOnboardingTasks && !onboardingTasks) || isValidatingNewHires}
              title={option.title}
              totalCount={option.totalCount}
              isSelected={option.status === selectedStatus}
              onClick={() => {
                setQueryString({ selectedStatus: option.status });
                setSelectedStatus(option.status);
              }}
            />
          </Box>
        ))}
      </StyledBox>

      <Box pb={5}>
        <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb="24px">
          <Box display="flex" alignItems="center" gridGap="24px">
            <Box display="flex" flexDirection="column" gridRowGap="16px" width="240px">
              <Typography variant="body2" bold color="textSecondary">
                担当者
              </Typography>
              <EmployeeFilter
                currentUser={currentUser}
                selectableEmployeeIds={[currentUser.id, ...selectableEmployeeIds]}
                selectedEmployeeIds={selectedEmployeeIds}
                onChange={(selectedEmployeeIds: string[]) => {
                  setQueryString({ employeeIds: selectedEmployeeIds });
                  setSelectedEmployeeIds(selectedEmployeeIds);
                }}
              />
            </Box>
          </Box>
          <Box display="flex" gridGap="16px">
            {isEmpty(
              onboardingTasksWithNewHire.filter((v) => selectedOnboardingTaskIds.includes(v.id))
            ) ? (
              <Button
                onClick={() => handleOpenEditTaskModal()}
                borderRadius="regular"
                variant="outlined"
                color="primary"
                startIcon={<Icon icon="add" color="primary" size="md" />}
              >
                タスク追加
              </Button>
            ) : (
              <>
                <Button
                  onClick={() =>
                    handleClickCompleteMultipleTasks(
                      onboardingTasksWithNewHire.filter((v) =>
                        selectedOnboardingTaskIds.includes(v.id)
                      )
                    )
                  }
                  borderRadius="regular"
                  variant="outlined"
                  color="primary"
                >
                  一括完了
                </Button>
                <Tooltip title="一括削除">
                  <IconButton
                    onClick={() =>
                      handleDeleteMultipleTasksModal(
                        onboardingTasksWithNewHire.filter((v) =>
                          selectedOnboardingTaskIds.includes(v.id)
                        )
                      )
                    }
                    icon="trash"
                    color="grey"
                  />
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
        <OnboardingTaskTable
          // 初回だけローディングするためにonboardingTasksがあればローディングしない
          isLoading={isValidatingOnboardingTasks && !onboardingTasks}
          onboardingTasksWithNewHire={onboardingTasksWithNewHire}
          selectedTaskStatus={selectedStatus}
          selectedOnboardingTaskIds={selectedOnboardingTaskIds}
          setSelectedOnboardingTaskIds={(ids: string[]) => setSelectedOnboardingTaskIds(ids)}
          isSelectedAll={isSelectedAll}
          setIsSelectedAll={setIsSelectedAll}
          onClickEditTaskButton={(task) => handleOpenEditTaskModal(task)}
          onClickDeleteTaskButton={handleClickDeleteTaskButton}
          onClickSwitchStatusButton={handleClickChangeStatusButton}
          onClickManageWelcomeMessageButton={handleOpenManageMessageTaskModal}
          onClickTableRow={(task) => {
            setQueryString({ onboardingTaskId: task.id });
            setTargetTaskForSummary(task);
            setSidebarTab("taskDetail");
          }}
          onClickDueDateStatus={(task) =>
            task.type !== "GENERAL_TASK" && task.status !== "COMPLETED"
              ? handleOpenEditDueDateModal(task)
              : void 0
          }
          onClickOpenSidebarComments={(task) => {
            setQueryString({ onboardingTaskId: task.id });
            setTargetTaskForSummary(task);
            setSidebarTab("taskMemos");
          }}
        />
        {targetTaskForSummary && (
          <OnboardingTaskSummarySidebar
            onboardingTask={targetTaskForSummary}
            defaultTab={sidebarTab}
            newHire={employee}
            assigneeEmployees={getEmployeesByAssigneeRole({
              onboardingTask: targetTaskForSummary,
              newHire: employee,
              mentor,
              supportMembers,
              specificEmployees,
              admins,
            })}
            mutateOnboardingTasks={mutateOnboardingTasks}
            onClickCloseButton={() => {
              setQueryString({ onboardingTaskId: undefined });
              setTargetTaskForSummary(undefined);
            }}
            onClickEditButton={() => {
              setQueryString({ onboardingTaskId: undefined });
              handleOpenEditTaskModal(targetTaskForSummary);
              setTargetTaskForSummary(undefined);
            }}
            onClickDeleteTaskButton={() => {
              setQueryString({ onboardingTaskId: undefined });
              handleClickDeleteTaskButton(targetTaskForSummary);
              setTargetTaskForSummary(undefined);
            }}
            onClickChangeStatusButton={() => handleClickChangeStatusButton(targetTaskForSummary)}
            onClickGeneralTaskButton={() => {
              setQueryString({ onboardingTaskId: undefined });
              setTargetTaskForSummary(undefined);
            }}
          />
        )}
      </Box>
    </>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  overflow-x: scroll;
`;
