import { Box } from "@material-ui/core";
import { Department } from "@onn/common";
import React, { Fragment, useCallback, useMemo, FC } from "react";
import styled from "styled-components";

import { DepartmentFormControlLabel } from "./DepartmentFormControlLabel";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  departments: Department[];
  selectedDepartmentIds: string[];
  onChange: (selectedDepartmentIds: string[]) => void;
  /**
   * indeterminateを表示する方向を示す
   * parentなら上位方向にさかのぼり、childなら下位方向にくだっていく
   */
  displayIndeterminateDirection: "parent" | "child";
  isSelectedNoDepartment?: boolean;
  /**
   * 所属なしを選択肢に入れるかどうか
   */
  isShowNoDepartment?: boolean;
  onChangeNoDepartmentCheckbox?: () => void;
  maxSelectCount?: number;
};

export const DepartmentSelectMenu: FC<Props> = ({
  departments,
  selectedDepartmentIds,
  onChange,
  displayIndeterminateDirection,
  isSelectedNoDepartment,
  isShowNoDepartment,
  onChangeNoDepartmentCheckbox,
  maxSelectCount,
}) => {
  const nestedDepartments = useMemo(() => Department.nest(departments), [departments]);

  const handleChange = useCallback(
    (currentSelected: Department) => {
      const parentAndChildDepartmentIds = [
        // currentSelectedのchildren
        ...Department.getChildIds([currentSelected.id], departments),
        // currentSelectedのparents
        ...Department.getParentIds(currentSelected.id, departments),
      ]
        // currentSelected自身を除外
        .filter((id) => id !== currentSelected.id);

      let newSelectedIds = [];

      if (selectedDepartmentIds.includes(currentSelected.id)) {
        newSelectedIds = selectedDepartmentIds.filter(
          (id) => id !== currentSelected.id && !parentAndChildDepartmentIds.includes(id)
        );
      } else {
        newSelectedIds = [
          currentSelected.id,
          ...selectedDepartmentIds.filter((id) => !parentAndChildDepartmentIds.includes(id)),
        ];
      }

      return onChange(newSelectedIds);
    },
    [departments, onChange, selectedDepartmentIds]
  );

  /**
   * determinate( `-` )を表示するかどうかを取得する
   */
  const getIsDisplayIndeterminate = useCallback(
    (department: Department) => {
      // indeterminateを表示するべき部署のidの配列
      // directionがchildの場合は、departmentのchildren(department自身を除く)
      // directionがparentの場合は、departmentのparents(department自身を除く)
      const displayIndeterminateIds = (
        displayIndeterminateDirection === "child"
          ? Department.getParentIds(department.id, departments)
          : Department.getChildIds([department.id], departments)
      ).filter((id) => id !== department.id);

      return selectedDepartmentIds.some((selectedId) => {
        return displayIndeterminateIds.map((dep) => dep).includes(selectedId);
      });
    },
    [departments, displayIndeterminateDirection, selectedDepartmentIds]
  );

  const renderChildren = (children: Department[]): JSX.Element[] =>
    children.map((child) => (
      <StyledChildContainer key={child.id}>
        <DepartmentFormControlLabel
          department={child}
          checked={selectedDepartmentIds.includes(child.id)}
          checkedChild={getIsDisplayIndeterminate(child)}
          disabled={maxSelectCount ? selectedDepartmentIds.length >= maxSelectCount : false}
          onChange={() => handleChange(child)}
        />
        {child.children && renderChildren(child.children)}
      </StyledChildContainer>
    ));

  return (
    <Box py="16px" width="318px" display="flex" flexDirection="column">
      <Box px="24px" maxHeight={300} overflow="auto" display="flex" flexDirection="column">
        {nestedDepartments.map((department) => (
          <Fragment key={department.id}>
            <DepartmentFormControlLabel
              department={department}
              checked={selectedDepartmentIds.includes(department.id)}
              checkedChild={getIsDisplayIndeterminate(department)}
              disabled={maxSelectCount ? selectedDepartmentIds.length >= maxSelectCount : false}
              onChange={() => handleChange(department)}
            />
            {department.children && renderChildren(department.children)}
          </Fragment>
        ))}
        {isShowNoDepartment && onChangeNoDepartmentCheckbox && (
          <StyledFormControlLabel
            onChange={() => onChangeNoDepartmentCheckbox()}
            control={
              <Checkbox checked={isSelectedNoDepartment} name="所属なし" value="independent" />
            }
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <StyledTypography variant="body2" color="textSecondary" noWrap>
                  所属なし
                </StyledTypography>
              </Box>
            }
          />
        )}
      </Box>
    </Box>
  );
};

const StyledChildContainer = styled(Box)`
  margin-left: 20px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
  }
`;
