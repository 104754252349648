import { ContactRoom } from "@onn/common";
import { useState, useCallback } from "react";

import { useCurrentUser } from "../employee";
import { useSnackbar } from "../shared";

import { ContactRoomRepository } from "~/infrastructure/api/contactRoomRepository";
import { CreateContactMessageUseCase } from "~/service/usecases/contactMessage";
import { UpdateIsCompletedUseCase } from "~/service/usecases/contactRoom";
import { captureException } from "~/util";

const updateIsCompletedUseCase = new UpdateIsCompletedUseCase(new ContactRoomRepository());
const createContactMessageUseCase = new CreateContactMessageUseCase();
/**
 * 入社者がmessageを作成するための関数を提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns createContactMessage
 */
export const useCreateContactMessageByNewHire = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  /**
   * messageを作成するための関数
   */
  const createContactMessage = useCallback(
    async (
      text: string,
      tenantId: string,
      employeeId: string,
      contactRoom: ContactRoom,
      newMessageFiles: File[]
    ) => {
      setIsLoading(true);
      // コンタクトメッセージをlistenして、データが追加されるごとに変更されるUIがある。
      // そのため、コンタクトメッセージ追加にともなう変更はメッセージ追加前に行う必要がある。
      if (contactRoom.checkIsNewHire(currentUser.id)) {
        await updateIsCompletedUseCase.execute(currentUser, contactRoom.id, false);
      }
      return await createContactMessageUseCase
        .execute({
          text,
          tenantId,
          createdEmployeeId: employeeId,
          contactRoomId: contactRoom.id,
          newMessageFiles,
        })
        .catch((e) => {
          enqueueSnackbar(
            "メッセージの送信が失敗しました。Onnサポートチームまでお問い合わせください。",
            { variant: "error" }
          );
          captureException({
            error: e as Error,
            tags: { type: "useCreateContactMessageByNewHire:createContactMessage" },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar]
  );

  return { isLoading, createContactMessage };
};
