import { Grid, Box } from "@material-ui/core";
import React, { FC, useEffect, useRef, useState, RefObject, useCallback } from "react";
import styled from "styled-components";

import { Typography, Paper, TextContext, Button, AdminContentWrapper } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { useQuery, useScrollToTargetElement } from "~/hooks/shared";
import { useSegregateSlack } from "~/hooks/tenant";
import { useTenant } from "~/hooks/tenant/useTenant";

const AUTO_SCROLL_TO_CONTENT_Y_OFFSET = 20;

export const ExternalToolTab: FC = () => {
  const integrationRef = useRef<HTMLDivElement>(null);
  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();

  const { query } = useQuery();
  const { handleModal } = useModal();
  const { segregateSlack } = useSegregateSlack();
  const { tenant, fetchTenant } = useTenant();

  const target = query.get("target");

  useEffect(() => {
    switch (target) {
      case "invitationMessage": {
        setScrollTargetRef(integrationRef);
        break;
      }
    }
  }, [target]);

  useScrollToTargetElement({
    enabled: !!scrollTargetRef,
    targetRef: scrollTargetRef,
    scrollYOffset: AUTO_SCROLL_TO_CONTENT_Y_OFFSET,
  });

  const handleClickIntegrateButton = useCallback(() => {
    const url =
      process.env.NODE_ENV === "production"
        ? "https://slack.com/oauth/v2/authorize?client_id=410787757378.1268713247458&scope=channels:read,channels:join,channels:manage,groups:read,chat:write,chat:write.customize,groups:write,users:read,users:read.email&user_scope=groups:write,users:read,users:read.email,channels:write"
        : process.env.NODE_ENV === "staging"
        ? "https://slack.com/oauth/v2/authorize?client_id=410787757378.1157862937269&scope=channels:read,channels:join,channels:manage,groups:read,chat:write,chat:write.customize,groups:write,users:read,users:read.email&user_scope=groups:write,users:read,users:read.email,channels:write"
        : "https://slack.com/oauth/v2/authorize?client_id=410787757378.4665215661383&scope=channels:read,channels:join,channels:manage,groups:read,chat:write,chat:write.customize,groups:write,users:read,users:read.email&user_scope=groups:write,users:read,users:read.email,channels:write";
    window.open(url, "_blank", "noopener noreferrer");
  }, []);

  const handleClickSegregationButton = useCallback(() => {
    handleModal({
      name: "segregateSlackModal",
      args: {
        onSubmit: async () => {
          await segregateSlack();
          fetchTenant();
        },
      },
    });
  }, [handleModal, segregateSlack, fetchTenant]);

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={3}>
            <Box position="sticky" top="20px">
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(integrationRef)}
                $isActive={scrollTargetRef === integrationRef}
              >
                外部ツール連携
              </StyledSideTypography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} ref={integrationRef}>
              <StyledTextContext padding={2} isShowBg bold>
                外部ツール連携
              </StyledTextContext>
            </Grid>
            <Box px={2} pt={4}>
              <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="16px">
                <Box>
                  <Typography variant="body2" bold>
                    Slack連携
                  </Typography>
                  <StyledTypography variant="caption">
                    {`連携すると、Slackへの通知をしたりSlackユーザーをOnnで選択し担当者に据えることができます。`}
                  </StyledTypography>
                </Box>
                {tenant && !tenant.isIntegratedSlack && (
                  <Button
                    onClick={handleClickIntegrateButton}
                    borderRadius="regular"
                    variant="contained"
                    color="primary"
                  >
                    接続
                  </Button>
                )}
              </Box>
            </Box>
            {tenant && tenant?.isIntegratedSlack && (
              <Box px={2} mt={4}>
                <Box display="flex" alignItems="center" gridGap="40px">
                  <Typography color="primary" variant="body1" bold>
                    接続中
                  </Typography>
                  <Button
                    onClick={handleClickSegregationButton}
                    borderRadius="regular"
                    variant="contained"
                    color="secondary"
                  >
                    解除
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </AdminContentWrapper>
  );
};

const StyledSideTypography = styled(Typography)<{ $isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.$isActive &&
    `&.MuiTypography-root {
        color: ${props.theme.palette.primary.main};
        font-weight: bold;
    }`}
`;

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
