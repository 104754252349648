import {
  DifferenceDate,
  OnboardingExperienceTaskType,
  OnboardingExperienceTaskFactory,
  OnboardingExperienceTask,
  OnboardingExperienceMessageTask,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { useCallback, FC, useEffect } from "react";
import styled from "styled-components";

import { AssigneeRoleForm } from "../shared/AssigneeRoleForm";
import { BodyForm } from "../shared/BodyForm";
import { DeliveryDateForm } from "../shared/DeliveryDateForm";
import { DueDateForm } from "../shared/DueDateForm";

import { Typography } from "~/components/uiParts";

type Props = {
  onboardingExperienceId: string;
  newTask: OnboardingExperienceMessageTask;
  editable?: boolean;
  setInvalid: (invalid: boolean) => void;
  setNewTask: (newTask: React.SetStateAction<OnboardingExperienceTaskType>) => void;
};

export const MessageTask: FC<Props> = ({ newTask, editable = true, setInvalid, setNewTask }) => {
  useEffect(() => {
    setInvalid(
      isEmpty(newTask.requestedRoles) ||
        !newTask.templateId ||
        !newTask.body.trim() ||
        !newTask.dueDate.amount ||
        newTask.dueDate.amount <= 0
    );
  }, [
    newTask.templateId,
    newTask.requestedRoles,
    newTask.body,
    newTask.dueDate.amount,
    setInvalid,
  ]);

  const handleChangeSettings = useCallback(
    <T extends OnboardingExperienceTaskType>(newObject: Partial<T>) =>
      setNewTask((prev: OnboardingExperienceTaskType) =>
        OnboardingExperienceTaskFactory.createOnboardingTask({ ...prev, ...newObject })
      ),
    [setNewTask]
  );

  const handleChangeAttachedFiles = useCallback(
    (newFiles: (File | Pick<File, "name">)[]) => {
      handleChangeSettings({
        filePaths: newFiles.map((v) => {
          return v instanceof File ? v : v.name;
        }),
      });
    },
    [handleChangeSettings]
  );

  const handleSelectAssigneeRoles = useCallback(
    (role: Exclude<OnboardingExperienceTask["assigneeRole"], "BY_NAME">) => {
      if (role === "NOT_SET") {
        handleChangeSettings<OnboardingExperienceMessageTask>({
          requestedRoles: newTask.requestedRoles.includes("NOT_SET") ? [] : ["NOT_SET"],
          assigneeIds: [],
          emailsWithoutOnnAccount: [],
        });
      } else {
        handleChangeSettings<OnboardingExperienceMessageTask>({
          requestedRoles: newTask.requestedRoles.includes(role)
            ? newTask.requestedRoles.filter((v) => v !== role)
            : [...newTask.requestedRoles.filter((v) => v !== "NOT_SET"), role],
          assigneeIds: [],
          emailsWithoutOnnAccount: [],
        });
      }
    },
    [handleChangeSettings, newTask]
  );

  return (
    <>
      <StyledMutedTypography variant="caption">
        指定したメンバーに記入依頼が送信され、期日までに集まったメッセージを入社者に対して自動で送信します。
      </StyledMutedTypography>
      <AssigneeRoleForm
        isSelectableUser={false}
        isMultiple={true}
        tooltipText="「管理者、バディ、サポートメンバー」を選択すると、該当のロールに設定されたメンバーが自動的にアサインされます。"
        caption="記入依頼を必ず送る対象を指定してください。後から入社者ごとに入社者詳細で個別に依頼者を追加することもできます。"
        selectedAssigneeRoles={newTask.requestedRoles}
        assignableRoles={["MENTOR", "SUPPORT_MEMBER", "ADMIN", "NOT_SET"]}
        setAssigneeRole={(value) => handleSelectAssigneeRoles(value)}
        readOnly={!editable}
      />
      <BodyForm
        title="依頼文"
        caption={`担当・依頼対象となるメンバーに届く通知に添えられる文章です。 \n依頼背景や目的、どのようなメッセージを記載してほしいのかを伝えましょう。`}
        placeholder={`例：\n入社される方の不安を取り除き歓迎の気持ちを伝えるための寄せ書きを、採用を担当したメンバーや配属先の一部メンバーの方々にお願いしています。簡単な自己紹介を添えて、リモートでは伝わりづらい歓迎の気持ちをめいいっぱい込めたメッセージをお願いします！`}
        body={newTask.body}
        filePaths={newTask.filePaths}
        readOnly={!editable}
        setBody={(value) => handleChangeSettings({ body: value })}
        setFiles={handleChangeAttachedFiles}
      />
      <DueDateForm
        dueDate={newTask.dueDate}
        units={
          newTask.type === "MESSAGE_TASK"
            ? ["DAY", "BUSINESS_DAY", "MONTH"]
            : ["DAY", "BUSINESS_DAY"]
        }
        caption="期日までに集まったメッセージは、期日の時間に入社者に対して自動で送信されます。"
        readOnly={!editable}
        setDueDate={(value) =>
          handleChangeSettings({
            dueDate: new DifferenceDate({ ...newTask.dueDate, ...value }),
          })
        }
        isDeliveryTime
      />
      <DeliveryDateForm
        deliveryDate={newTask.deliveryDate}
        setDeliveryDate={(value) =>
          handleChangeSettings({
            deliveryDate: new DifferenceDate({ ...newTask.deliveryDate, ...value }),
          })
        }
        readOnly={!editable}
      />
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
