import { Box, ListItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { UserIcon, Typography, FormControlLabel, Checkbox } from "~/components/uiParts";

type Props = ListRowProps & {
  employees: Employee[];
  selectedEmployees: Employee[];
  onSelect: (employees: Employee) => void;
};

export const Row: FC<Props> = ({ employees, selectedEmployees, onSelect, ...props }) => {
  const employee = employees[props.index] as (typeof employees)[number];

  return (
    <Box
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
    >
      <ListItem alignItems="flex-start">
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={selectedEmployees.includes(employee)}
              onChange={() => onSelect(employee)}
              name={employee.id}
              value={employee.id}
            />
          }
          label={
            <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
              <UserIcon
                username={employee.getName()}
                profileIconImageUrl={employee.profileIconImageUrl}
                size="extraSmall"
                circular
              />
              <Typography variant="caption" color="textSecondary" noWrap>
                {employee.getName()}
              </Typography>
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
