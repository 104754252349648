import { useCallback } from "react";

type EventName =
  | "click_first_month_enps_button"
  | "click_third_month_enps_button"
  | "click_survey_alert_item";

/**
 * Google アナリティクスのイベントを測定する関数を提供するhooks
 */
export const useSendGAEvent = () => {
  /**
   * Google アナリティクスのイベントを測定する関数
   * @param {string} eventName 該当のイベントの名前
   * @param {string} currentUserId 該当のイベントを実行したユーザーのid
   */
  const sendGAEvent = useCallback((eventName: EventName, currentUserId: string) => {
    window.gtag &&
      window.gtag("event", eventName, {
        page_path: location.pathname,
        event_label: currentUserId,
      });
  }, []);

  return { sendGAEvent };
};
