import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts/Typography";

type Props = {
  latelySatisfaction?: number;
  previousSatisfaction?: number;
  hasPrevious?: boolean;
  scoreVariant?: "body1" | "h3";
  arrowVariant?: "body2" | "h4";
};

/**
 * 2つの値を元に変化しているかどうかを返す。
 * @param {number} latelySatisfaction
 * @param {number} previousSatisfaction
 * @return {number} 同値の場合 0, 値が増加している場合 1, 減少している場合 -1 を返す。
 */
const calcIncrease = (latelySatisfaction: number, previousSatisfaction: number): number => {
  if (latelySatisfaction === previousSatisfaction) {
    return 0;
  }

  return latelySatisfaction > previousSatisfaction ? 1 : -1;
};

/**
 * 2つの値を元に矢印を返す。
 * @param {number} increase
 * @return {string} ↗︎ or ↘︎ or →
 */
const createArrow = (increase: number): string => {
  if (increase > 0) {
    return "↗︎";
  } else if (increase < 0) {
    return "↘︎";
  } else {
    return "→";
  }
};

export const DifferenceSatisfaction: FC<Props> = ({
  latelySatisfaction,
  previousSatisfaction,
  hasPrevious = true, // デフォルトで前回満足度を表示する
  scoreVariant = "body1",
  arrowVariant = "body2",
}) => {
  const displayArrow = () => {
    // どちらかが null の時は 空文字を表示する
    if (latelySatisfaction == null || previousSatisfaction == null) {
      return "";
    }
    const increase = calcIncrease(latelySatisfaction, previousSatisfaction);
    return createArrow(increase);
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography variant={scoreVariant} bold>{`${latelySatisfaction || "-"}`}</Typography>
      <Typography variant={arrowVariant} bold>
        {displayArrow()}
      </Typography>
      {hasPrevious && (
        <Box ml={1}>
          <Typography variant="caption">{`(前回${previousSatisfaction || "-"})`}</Typography>
        </Box>
      )}
    </Box>
  );
};
