import { Box, Typography } from "@material-ui/core";
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from "react";
import {
  AutoSizer,
  TableRowProps,
  Table as VirtualScrollableTable,
  WindowScroller,
} from "react-virtualized";
import styled from "styled-components";

import { Loading } from "../Loading";

import { TableHeaderRow } from "./TableHeaderRow";

import { constants } from "~/config/theme";

type Props<T> = {
  headers: ComponentProps<typeof TableHeaderRow>["headers"];
  // %で指定
  widthOptions: string[];
  isLoading?: boolean;
  emptyText?: string;
  rows: T[];
  headerHeight?: number;
  rowHeight?: number;
  hover?: boolean;
  rowRenderer: (props: Omit<TableRowProps, "rowData"> & { rowData: T }) => React.ReactNode;
};

export const VirtualizedTableV2 = <T,>({
  headers,
  widthOptions,
  isLoading,
  emptyText = "結果が見つかりませんでした。",
  rows,
  rowHeight = 80,
  headerHeight = 40,
  rowRenderer,
}: Props<T>) => {
  const [prevScrollTop, setPrevScrollTop] = useState<number>(0);

  const tableTopY = useMemo(() => headerHeight, [headerHeight]);
  const tableBottomY = useMemo(
    () => rowHeight * rows.length + headerHeight - window.innerHeight,
    [headerHeight, rowHeight, rows.length]
  );

  // NOTE: 自動スクロールしてしまうのを打ち消す際に使う一つ前の高さを保持する関数
  const storeScrollY = useCallback(() => {
    setPrevScrollTop(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => storeScrollY());

    return window.removeEventListener("scroll", () => storeScrollY());
  }, [storeScrollY]);

  /**
   * テーブル行をクリックしてモーダルを開いて閉じようとしたとき、以下の不具合が発生することを防ぐために、強引だが特定条件でスクロールをキャンセルしている
   * * ウィンドウのY方向がテーブルより上を描画しているとき、モーダルとをじるとウィンドウ最上部とテーブル最上部を一致させるようにウィンドウ位置が移動する
   * * ウィンドウのY方向がテーブルより下を描画しているとき、モーダルとをじるとウィンドウ最下部とテーブル最下部を一致させるようにウィンドウ位置が移動する
   * @see TODO: ここにgithubのissueを貼る
   */
  const handleScroll = useCallback(
    (prevScrollTop: number, scrollTop: number) => {
      const isScrollTopAtTableTopOrBottom = [tableTopY, tableBottomY].includes(
        Math.ceil(scrollTop) // 上部の要素で 1px以下の幅の影響を受け scrollTop が 39.XXX になるため
      );

      // ウィンドウ位置がテーブル上部または下部と一致しているとき、その箇所に不正にスクロールしようとしたとみなし、強制的にprevScrollTopにスクロールすることでキャンセルする
      if (isScrollTopAtTableTopOrBottom) {
        window.scrollTo({
          top: prevScrollTop,
        });
      } else {
        return;
      }
    },
    [tableBottomY, tableTopY]
  );

  return (
    <WindowScroller onScroll={({ scrollTop }) => handleScroll(prevScrollTop, scrollTop)}>
      {({ height, scrollTop }) => (
        <StyledBox $tableMinWidth={constants.ADMIN_CONTENT_MIN_WIDTH}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualScrollableTable
                autoHeight
                scrollTop={scrollTop}
                width={
                  constants.ADMIN_CONTENT_MIN_WIDTH < width
                    ? width
                    : constants.ADMIN_CONTENT_MIN_WIDTH
                }
                height={height}
                headerHeight={headerHeight}
                rowHeight={rowHeight}
                overscanRowCount={10}
                noRowsRenderer={() => {
                  return (
                    <Box height="240px" display="flex" alignItems="center" justifyContent="center">
                      {isLoading ? (
                        <Loading size="small" />
                      ) : (
                        <Typography variant="body2" align="center" color="textSecondary">
                          {emptyText}
                        </Typography>
                      )}
                    </Box>
                  );
                }}
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
                headerRowRenderer={(props) => {
                  return (
                    <Box {...props}>
                      <TableHeaderRow headers={headers} widthOptions={widthOptions} />;
                    </Box>
                  );
                }}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        </StyledBox>
      )}
    </WindowScroller>
  );
};

const StyledBox = styled(Box)<{ $tableMinWidth: number }>`
  min-width: ${(props) => props.$tableMinWidth}px;
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
`;
