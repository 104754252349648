import { Box } from "@material-ui/core";
import { MessageTemplate, OnboardingExperienceTaskTemplate } from "@onn/common";
import React, { FC, useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  // Divider,
  // Icon,
  Paper,
  TextareaAutosize,
  TextField,
  Typography,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import {
  useCreateOnboardingExperienceTaskTemplate,
  useOnboardingExperienceTaskTemplate,
  useUpdateOnboardingExperienceTaskTemplate,
} from "~/hooks/onboardingExperienceTaskTemplate";

export const TemplateEdit: FC = () => {
  const { currentUser } = useCurrentUser();
  const [newTemplate, setNewTemplate] = useState<OnboardingExperienceTaskTemplate>(
    MessageTemplate.create({
      tenantId: currentUser.tenantId,
      title: "",
      description: "",
      editableEmployeeIds: [],
      createdEmployeeId: currentUser.id,
    })
  );
  const navigate = useNavigate();
  const { createOnboardingExperienceTaskTemplate } = useCreateOnboardingExperienceTaskTemplate();
  const { updateOnboardingExperienceTaskTemplate } = useUpdateOnboardingExperienceTaskTemplate();
  const { id } = useParams<"id">();

  const { data: template } = useOnboardingExperienceTaskTemplate(id);
  const isEditable = useMemo(() => {
    return newTemplate.isEditable(currentUser) && !newTemplate.isDefault;
  }, [currentUser, newTemplate]);

  useEffect(() => {
    if (template) {
      setNewTemplate(template);
    }
  }, [template]);

  // const handleClickOpenPreviewButton = useCallback(() => {}, []);

  const isInvalidTemplate = useMemo(
    () => !newTemplate.title.trim() || !newTemplate.description.trim(),
    [newTemplate.description, newTemplate.title]
  );

  const handleSubmit = () => {
    if (template) {
      updateOnboardingExperienceTaskTemplate(template.id, {
        title: newTemplate.title,
        description: newTemplate.description,
      });
    } else {
      createOnboardingExperienceTaskTemplate(newTemplate);
    }

    navigate("/tools#templates");
  };

  return (
    <>
      <StyledBox py="48px" px="40px">
        <Typography variant="h4" bold color="textPrimary">
          メンバーメッセージ（寄せ書き）｜テンプレート作成
        </Typography>
        <StyledPaper>
          <TextField
            fullWidth
            autoFocus
            onChange={(e) =>
              setNewTemplate((prev) =>
                MessageTemplate.create({
                  ...prev,
                  title: e.target.value,
                })
              )
            }
            placeholder="タイトル"
            inputProps={{ style: { fontSize: 24 }, readOnly: !isEditable }}
            value={newTemplate.title}
          />
          <StyledTextarea
            minRows={3}
            fullWidth
            placeholder="メンバーメッセージ（寄せ書き）の説明／依頼文"
            onChange={(e) =>
              setNewTemplate((prev) =>
                MessageTemplate.create({
                  ...prev,
                  description: e.target.value,
                })
              )
            }
            readOnly={!isEditable}
            value={newTemplate.description}
          />
          <Typography variant="caption" color="textSecondary">
            ※メンバーメッセージ（寄せ書き）への記入依頼をした方へ通知・表示される文章です。記入依頼されたメンバーに目的や意図が伝わるような説明や依頼文を入力しましょう。
          </Typography>
        </StyledPaper>
        <Typography color="textPrimary">アンケート内容(編集不可)</Typography>
        <StyledPaper>
          <StyledTemplateBox>
            <Box>
              <Typography variant="body1" color="textPrimary">
                氏名
              </Typography>
              <Box height="8px" />
              <Typography variant="body2" color="textSecondary">
                ※回答者には、テキスト欄が表示され自由記述での回答を行うことができます。
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                メールアドレス（任意）
              </Typography>
              <Box height="8px" />
              <Typography variant="body2" color="textSecondary">
                ※回答者には、テキスト欄が表示され自由記述での回答を行うことができます。
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                部署（任意）
              </Typography>
              <Box height="8px" />
              <Typography variant="body2" color="textSecondary">
                ※回答者には、テキスト欄が表示され自由記述での回答を行うことができます。
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                アイコン写真
              </Typography>
              <Box height="8px" />
              <Typography variant="body2" color="textSecondary">
                ※回答者には、画像アップロード機能が表示されます
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                メッセージ
              </Typography>
              <Box height="8px" />
              <Typography variant="body2" color="textSecondary">
                ※回答者には、テキスト欄が表示され自由記述での回答を行うことができます。
              </Typography>
            </Box>
          </StyledTemplateBox>
        </StyledPaper>
      </StyledBox>
      <StyledFooter
        position="fixed"
        gridGap="24px"
        padding="16px 24px"
        bottom={0}
        left={0}
        width="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <>
          {/* <Button
            variant="text"
            borderRadius="regular"
            color="primary"
            onClick={handleClickOpenPreviewButton}
          >
            <Icon size="md" icon="eye" color="primary" />
            <Typography variant="button">プレビュー</Typography>
          </Button>
          <Divider orientation="vertical" /> */}
          <Button
            variant="text"
            borderRadius="regular"
            color="default"
            onClick={() => navigate("/tools#templates")}
          >
            キャンセル
          </Button>
          {/* <Button variant="outlined" borderRadius="circle" color="default" onClick={() => {}}>
            下書き保存
          </Button> */}
          <Button
            variant="contained"
            borderRadius="circle"
            color="primary"
            onClick={handleSubmit}
            disabled={!isEditable || isInvalidTemplate}
          >
            保存
          </Button>
        </>
      </StyledFooter>
    </>
  );
};

const FOOTER_HEIGHT = 78;

const StyledBox = styled(Box)`
  position: relative;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledPaper = styled(Paper)`
  margin: 32px 0 40px;
`;

const StyledTextarea = styled(TextareaAutosize)`
  margin: 32px 0 16px;
`;

const StyledTemplateBox = styled(Box)`
  div + div {
    margin-top: 56px;
  }
`;

const StyledFooter = styled(Box)`
  height: ${FOOTER_HEIGHT}px;
  background-color: white;
  border-top: solid 1px ${(props) => props.theme.palette.grey[100]};
`;
