import { Box, Typography } from "@material-ui/core";
import { DifferenceDate, Employee, OnboardingTaskType, taskTypes } from "@onn/common";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { DifferenceDateForm } from "../../differenceDate";

import { Button, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (dueDate: DifferenceDate) => Promise<void>;
  newHire: Employee;
  task: OnboardingTaskType;
};

export const EditDueDateModal: FC<Props> = ({ open, onCancel, onSubmit, task }) => {
  const [newDueDate, setNewDueDate] = useState<DifferenceDate>(task.dueDate);
  const [isSending, setIsSending] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    setIsSending(true);
    await onSubmit(newDueDate)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsSending(false);
      });
  }, [newDueDate, onCancel, onSubmit]);

  const Content = (
    <>
      <Typography variant="caption">タスクの完了期日を設定してください。</Typography>

      <Box mt="16px">
        <DifferenceDateForm
          differenceDate={newDueDate}
          onChange={(selectedDate) =>
            setNewDueDate(new DifferenceDate({ ...newDueDate, ...selectedDate }))
          }
          units={
            task.type === "MESSAGE_TASK"
              ? ["DAY", "BUSINESS_DAY", "MONTH"]
              : ["DAY", "BUSINESS_DAY"]
          }
          isDeliveryTime={task.type === taskTypes.MESSAGE_TASK}
        />
      </Box>
    </>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        disabled={isSending}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        保存
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="期日設定" content={Content} footer={Footer} onCancel={onCancel} />
  );
};

const StyledButtonContainer = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.default};
  ${mixin.fixedWidthButtonContainer}
`;
