import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  profileIconImageUrl: string;
  username: string;
};

export const ChangeMentorConfirmModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  profileIconImageUrl,
  username,
}) => {
  const contents = (
    <Box pt={2} textAlign="center">
      <UserIcon
        profileIconImageUrl={profileIconImageUrl}
        username={username}
        size="medium"
        borderColor="primary"
      />
      <Box height="8px" />
      <Typography variant="body2" bold noWrap>
        {username}
      </Typography>
      <Box mt={3}>
        <Typography variant="body1">このアカウントをバディから外しますか？</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="caption">
          バディを解除すると対象メンバーのオンボーディング情報が閲覧できなくなります。
          <br />
          バディを解除する場合、他のバディを設定してください。
        </Typography>
      </Box>
    </Box>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        バディ選択へ
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="バディ変更" content={contents} footer={Footer} onCancel={onCancel} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
